/* tslint:disable */
/* eslint-disable */
/**
 * MarketX Backend
 * MarketX Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: metalx.dev@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AboutResponse
 */
export interface AboutResponse {
  /**
   *
   * @type {string}
   * @memberof AboutResponse
   */
  buildTime?: string;
  /**
   *
   * @type {string}
   * @memberof AboutResponse
   */
  env?: string;
  /**
   *
   * @type {string}
   * @memberof AboutResponse
   */
  lifetime?: string;
}
/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  addressFull?: string;
  /**
   * Код филиала
   * @type {string}
   * @memberof Address
   */
  branchOfficeCode?: string | null;
  /**
   * Название филиала
   * @type {string}
   * @memberof Address
   */
  branchOfficeName?: string | null;
  /**
   * Код города (MSK, SPB, NSK)
   * @type {string}
   * @memberof Address
   */
  cityCode?: string | null;
  /**
   * Название города
   * @type {string}
   * @memberof Address
   */
  cityName?: string | null;
  /**
   * Уникальный идентификатор адреса.
   * @type {string}
   * @memberof Address
   */
  code?: string;
  /**
   * Использование в документах
   * @type {Array<AddressInDocument>}
   * @memberof Address
   */
  documents?: Array<AddressInDocument>;
  /**
   * Доступ на редактирование
   * @type {boolean}
   * @memberof Address
   */
  editingEnabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  extraInfo?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  fiasCode?: string;
  /**
   * Номер офиса или квартиры
   * @type {string}
   * @memberof Address
   */
  flatNumber?: string | null;
  /**
   * Номер дома
   * @type {string}
   * @memberof Address
   */
  houseNumber?: string | null;
  /**
   *
   * @type {number}
   * @memberof Address
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof Address
   */
  longitude?: number;
  /**
   * Почтовый индекс
   * @type {string}
   * @memberof Address
   */
  postcode?: string;
  /**
   * Идентификатор адреса, сохраняющийся при редактировании
   * @type {string}
   * @memberof Address
   */
  publicCode?: string;
  /**
   * Улица
   * @type {string}
   * @memberof Address
   */
  street?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  type?: AddressTypeEnum;
}

export const AddressTypeEnum = {
  Legal: 'legal',
  Actual: 'actual',
  Delivery: 'delivery',
  Consignee: 'consignee',
} as const;

export type AddressTypeEnum = (typeof AddressTypeEnum)[keyof typeof AddressTypeEnum];

/**
 *
 * @export
 * @interface AddressGeoSuggest
 */
export interface AddressGeoSuggest {
  /**
   * Адрес строкой
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  addressFull?: string;
  /**
   * КЛАДР-код города
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  cityKladrCode?: string;
  /**
   * Название города
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  cityName?: string;
  /**
   * Код страны (RU, BY, KZ)
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  countryCode?: string;
  /**
   * Название страны
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  countryName?: string;
  /**
   * Номер дома
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  houseNumber?: string;
  /**
   *
   * @type {number}
   * @memberof AddressGeoSuggest
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof AddressGeoSuggest
   */
  longitude?: number;
  /**
   * Почтовый индекс
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  postcode?: string;
  /**
   * Код региона (RU-MOS, RU-MOW, RU-SPE)
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  regionCode?: string;
  /**
   * КЛАДР-код региона
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  regionKladrCode?: string;
  /**
   * Название региона
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  regionName?: string;
  /**
   * Улица
   * @type {string}
   * @memberof AddressGeoSuggest
   */
  street?: string;
}
/**
 *
 * @export
 * @interface AddressGeoSuggestResponse
 */
export interface AddressGeoSuggestResponse {
  /**
   *
   * @type {Array<AddressGeoSuggest>}
   * @memberof AddressGeoSuggestResponse
   */
  suggests?: Array<AddressGeoSuggest>;
}
/**
 *
 * @export
 * @interface AddressGeocodingDecodeResponse
 */
export interface AddressGeocodingDecodeResponse {
  /**
   *
   * @type {Array<AddressSearchVariant>}
   * @memberof AddressGeocodingDecodeResponse
   */
  addresses?: Array<AddressSearchVariant>;
}
/**
 *
 * @export
 * @interface AddressGeocodingSearchResponse
 */
export interface AddressGeocodingSearchResponse {
  /**
   *
   * @type {Array<AddressSearchVariant>}
   * @memberof AddressGeocodingSearchResponse
   */
  addresses?: Array<AddressSearchVariant>;
}
/**
 *
 * @export
 * @interface AddressInDocument
 */
export interface AddressInDocument {
  /**
   * Код документа
   * @type {string}
   * @memberof AddressInDocument
   */
  code?: string;
  /**
   * Время документа
   * @type {string}
   * @memberof AddressInDocument
   */
  documentDate?: string;
  /**
   * Номер документа
   * @type {string}
   * @memberof AddressInDocument
   */
  documentNumber?: string;
  /**
   *
   * @type {string}
   * @memberof AddressInDocument
   */
  documentType?: AddressInDocumentDocumentTypeEnum;
}

export const AddressInDocumentDocumentTypeEnum = {
  Deal: 'deal',
  Bill: 'bill',
} as const;

export type AddressInDocumentDocumentTypeEnum = (typeof AddressInDocumentDocumentTypeEnum)[keyof typeof AddressInDocumentDocumentTypeEnum];

/**
 *
 * @export
 * @interface AddressListResponse
 */
export interface AddressListResponse {
  /**
   *
   * @type {Array<Address>}
   * @memberof AddressListResponse
   */
  addresses?: Array<Address>;
}
/**
 *
 * @export
 * @interface AddressSaveRequest
 */
export interface AddressSaveRequest {
  /**
   * Адрес строкой
   * @type {string}
   * @memberof AddressSaveRequest
   */
  addressFull?: string;
  /**
   * Код города (MSK, SPB, NSK)
   * @type {string}
   * @memberof AddressSaveRequest
   */
  cityCode?: string | null;
  /**
   * КЛАДР-код города
   * @type {string}
   * @memberof AddressSaveRequest
   */
  cityKladrCode?: string | null;
  /**
   * Название города
   * @type {string}
   * @memberof AddressSaveRequest
   */
  cityName?: string | null;
  /**
   * Код адреса
   * @type {string}
   * @memberof AddressSaveRequest
   */
  code?: string | null;
  /**
   * Код страны (RU, BY, KZ)
   * @type {string}
   * @memberof AddressSaveRequest
   */
  countryCode?: string | null;
  /**
   * Название страны
   * @type {string}
   * @memberof AddressSaveRequest
   */
  countryName?: string | null;
  /**
   * Клиент-покупатель
   * @type {string}
   * @memberof AddressSaveRequest
   */
  customerCode?: string;
  /**
   * Дополнительная информация адреса
   * @type {string}
   * @memberof AddressSaveRequest
   */
  extraInfo?: string | null;
  /**
   * Номер офиса или квартиры
   * @type {string}
   * @memberof AddressSaveRequest
   */
  flatNumber?: string | null;
  /**
   * Номер дома
   * @type {string}
   * @memberof AddressSaveRequest
   */
  houseNumber?: string | null;
  /**
   *
   * @type {number}
   * @memberof AddressSaveRequest
   */
  latitude?: number | null;
  /**
   *
   * @type {number}
   * @memberof AddressSaveRequest
   */
  longitude?: number | null;
  /**
   * Почтовый индекс
   * @type {string}
   * @memberof AddressSaveRequest
   */
  postcode?: string | null;
  /**
   * Код региона (RU-MOS, RU-MOW, RU-SPE)
   * @type {string}
   * @memberof AddressSaveRequest
   */
  regionCode?: string | null;
  /**
   * КЛАДР-код региона
   * @type {string}
   * @memberof AddressSaveRequest
   */
  regionKladrCode?: string | null;
  /**
   * Название региона
   * @type {string}
   * @memberof AddressSaveRequest
   */
  regionName?: string | null;
  /**
   * Улица
   * @type {string}
   * @memberof AddressSaveRequest
   */
  street?: string | null;
}
/**
 *
 * @export
 * @interface AddressSaveResponse
 */
export interface AddressSaveResponse {
  /**
   *
   * @type {Address}
   * @memberof AddressSaveResponse
   */
  address?: Address;
}
/**
 *
 * @export
 * @interface AddressSearchVariant
 */
export interface AddressSearchVariant {
  /**
   *
   * @type {string}
   * @memberof AddressSearchVariant
   */
  cityCode?: string;
  /**
   *
   * @type {string}
   * @memberof AddressSearchVariant
   */
  cityName?: string;
  /**
   * Номер дома
   * @type {string}
   * @memberof AddressSearchVariant
   */
  houseNumber?: string;
  /**
   *
   * @type {number}
   * @memberof AddressSearchVariant
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof AddressSearchVariant
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof AddressSearchVariant
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof AddressSearchVariant
   */
  street?: string;
}
/**
 *
 * @export
 * @interface AddressUpdates
 */
export interface AddressUpdates {
  /**
   * Код города (MSK, SPB, NSK, guid)
   * @type {string}
   * @memberof AddressUpdates
   */
  cityCode?: string | null;
  /**
   * Название города
   * @type {string}
   * @memberof AddressUpdates
   */
  cityName?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressUpdates
   */
  fiasCode?: string | null;
  /**
   * Номер офиса или квартиры
   * @type {string}
   * @memberof AddressUpdates
   */
  flatNumber?: string | null;
  /**
   * Номер дома
   * @type {string}
   * @memberof AddressUpdates
   */
  houseNumber?: string | null;
  /**
   *
   * @type {number}
   * @memberof AddressUpdates
   */
  latitude?: number | null;
  /**
   *
   * @type {number}
   * @memberof AddressUpdates
   */
  longitude?: number | null;
  /**
   * Почтовый индекс
   * @type {string}
   * @memberof AddressUpdates
   */
  postcode?: string | null;
  /**
   * Улица
   * @type {string}
   * @memberof AddressUpdates
   */
  street?: string | null;
}
/**
 *
 * @export
 * @interface AgreementBranchOffice
 */
export interface AgreementBranchOffice {
  /**
   * Код филиала
   * @type {string}
   * @memberof AgreementBranchOffice
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof AgreementBranchOffice
   */
  name?: string;
}
/**
 *
 * @export
 * @interface AgreementUpdates
 */
export interface AgreementUpdates {
  /**
   * Комментарий для коммерческого предложения
   * @type {string}
   * @memberof AgreementUpdates
   */
  comment?: string | null;
  /**
   * Комментарий для согласования
   * @type {string}
   * @memberof AgreementUpdates
   */
  commentForApprove?: string | null;
  /**
   * Договор, в рамках которого оформляется соглашение
   * @type {string}
   * @memberof AgreementUpdates
   */
  contractCode?: string | null;
  /**
   * Клиент-покупатель
   * @type {string}
   * @memberof AgreementUpdates
   */
  customerCode?: string | null;
  /**
   * Реализатор
   * @type {string}
   * @memberof AgreementUpdates
   */
  distributorCode?: string | null;
  /**
   *
   * @type {Array<AgreementUpdatesLeadPosition>}
   * @memberof AgreementUpdates
   */
  leadPositions?: Array<AgreementUpdatesLeadPosition> | null;
  /**
   * Заданная стоимость доставки
   * @type {number}
   * @memberof AgreementUpdates
   */
  manualDeliveryCost?: number | null;
  /**
   * Заданное допустимое отклонение от рыночной цены
   * @type {number}
   * @memberof AgreementUpdates
   */
  manualPermissibleMarketDeviationPct?: number | null;
  /**
   *
   * @type {string}
   * @memberof AgreementUpdates
   */
  name?: string | null;
  /**
   *
   * @type {Array<AgreementUpdatesPosition>}
   * @memberof AgreementUpdates
   */
  positions?: Array<AgreementUpdatesPosition> | null;
  /**
   *
   * @type {number}
   * @memberof AgreementUpdates
   */
  postpayDays?: number | null;
  /**
   *
   * @type {number}
   * @memberof AgreementUpdates
   */
  prepayPct?: number | null;
  /**
   * Использовать заданную стоимость доставки вместо расчетной
   * @type {boolean}
   * @memberof AgreementUpdates
   */
  useManualDeliveryCost?: boolean | null;
  /**
   * Использовать заданное вручную допустимое отклонение от рыночной цены
   * @type {boolean}
   * @memberof AgreementUpdates
   */
  useManualPermissibleMarketDeviationPct?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof AgreementUpdates
   */
  validFrom?: string | null;
  /**
   *
   * @type {string}
   * @memberof AgreementUpdates
   */
  validUntil?: string | null;
}
/**
 * ЛИД-товар обновляемого соглашения
 * @export
 * @interface AgreementUpdatesLeadPosition
 */
export interface AgreementUpdatesLeadPosition {
  /**
   * Наименование лид-товара
   * @type {string}
   * @memberof AgreementUpdatesLeadPosition
   */
  title?: string;
}
/**
 * Позиция обновляемого соглашения
 * @export
 * @interface AgreementUpdatesPosition
 */
export interface AgreementUpdatesPosition {
  /**
   * Установка голой цены товара
   * @type {number}
   * @memberof AgreementUpdatesPosition
   */
  bareUnitCost?: number | null;
  /**
   * Код обновляемой позиции. Для новой позиции code должен быть пустой и следует передавать frontCode.
   * @type {string}
   * @memberof AgreementUpdatesPosition
   */
  code?: string | null;
  /**
   * Комментарий для коммерческого предложения
   * @type {string}
   * @memberof AgreementUpdatesPosition
   */
  comment?: string | null;
  /**
   * Комментарий для согласования
   * @type {string}
   * @memberof AgreementUpdatesPosition
   */
  commentForApprove?: string | null;
  /**
   * Учитывать кредит в стоимости позиции
   * @type {boolean}
   * @memberof AgreementUpdatesPosition
   */
  distributeCredit?: boolean | null;
  /**
   * Код элемента на фронта для предотвращения дублирования. Обязательно передавать при добавлении новой позиции. При обновлении передавать не нужно, следует указывать поле code.
   * @type {string}
   * @memberof AgreementUpdatesPosition
   */
  frontCode?: string | null;
  /**
   * Удаление позиции
   * @type {boolean}
   * @memberof AgreementUpdatesPosition
   */
  isArchive?: boolean | null;
  /**
   * Отключить контроль остатков
   * @type {boolean}
   * @memberof AgreementUpdatesPosition
   */
  isStocksControlDisabled?: boolean | null;
  /**
   * Стоимость доставки для позиции
   * @type {number}
   * @memberof AgreementUpdatesPosition
   */
  manualDeliveryTotalCost?: number | null;
  /**
   * Заданное вручную МРЦ.
   * @type {number}
   * @memberof AgreementUpdatesPosition
   */
  manualMrcUnitCost?: number | null;
  /**
   * Стоимость услуг для позиции
   * @type {number}
   * @memberof AgreementUpdatesPosition
   */
  manualServicesTotalCost?: number | null;
  /**
   * Артикул товара. Обязателен при создании позиции
   * @type {string}
   * @memberof AgreementUpdatesPosition
   */
  productCode?: string | null;
  /**
   * Обновление наименования лида товара
   * @type {string}
   * @memberof AgreementUpdatesPosition
   */
  productLeadTitle?: string | null;
  /**
   * Стоимость акции на продажную единицу
   * @type {number}
   * @memberof AgreementUpdatesPosition
   */
  psUnitCost?: number | null;
  /**
   * Лимит количества для продажи.
   * @type {number}
   * @memberof AgreementUpdatesPosition
   */
  quantity?: number | null;
  /**
   * Установка тендерной цены товара.
   * @type {number}
   * @memberof AgreementUpdatesPosition
   */
  tenderUnitCost?: number | null;
  /**
   * Код ЕИ
   * @type {string}
   * @memberof AgreementUpdatesPosition
   */
  unitCode?: string | null;
  /**
   * Цена, которую следует использовать в качестве исходной для расчетов - голавя или финальная.
   * @type {string}
   * @memberof AgreementUpdatesPosition
   */
  unitCostType?: AgreementUpdatesPositionUnitCostTypeEnum;
  /**
   * Использовать для всех артикулов этой номенклатуры
   * @type {boolean}
   * @memberof AgreementUpdatesPosition
   */
  useForNomenclature?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof AgreementUpdatesPosition
   */
  useManualDeliveryTotalCost?: boolean | null;
  /**
   * Использовать заданную вручную МРЦ
   * @type {boolean}
   * @memberof AgreementUpdatesPosition
   */
  useManualMrcUnitCost?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof AgreementUpdatesPosition
   */
  useManualServicesTotalCost?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof AgreementUpdatesPosition
   */
  usePsUnitCost?: boolean | null;
}

export const AgreementUpdatesPositionUnitCostTypeEnum = {
  Bare: 'bare',
  Tender: 'tender',
} as const;

export type AgreementUpdatesPositionUnitCostTypeEnum =
  (typeof AgreementUpdatesPositionUnitCostTypeEnum)[keyof typeof AgreementUpdatesPositionUnitCostTypeEnum];

/**
 *
 * @export
 * @interface AgreementsReport
 */
export interface AgreementsReport {
  /**
   * Количество активных соглашений
   * @type {number}
   * @memberof AgreementsReport
   */
  activeAgreementsCount?: number;
  /**
   * Общий тоннаж отгруженного по всем активным соглашениям, т
   * @type {number}
   * @memberof AgreementsReport
   */
  activeFactQuantity?: number;
  /**
   * Общий плановый тоннаж по всем активным соглашениям, т
   * @type {number}
   * @memberof AgreementsReport
   */
  activePlanQuantity?: number;
  /**
   * Активные неисполненные, т. План - факт по активным неисполненным соглашениям.
   * @type {number}
   * @memberof AgreementsReport
   */
  activeRestQuantity?: number;
  /**
   * Количество активных неисполненных соглашений
   * @type {number}
   * @memberof AgreementsReport
   */
  activeUnfulfilledCount?: number;
  /**
   * Количество неисполненных соглашений
   * @type {number}
   * @memberof AgreementsReport
   */
  badlyFinishedCount?: number;
  /**
   * Дата начала отчетного периода
   * @type {string}
   * @memberof AgreementsReport
   */
  beginDate?: string;
  /**
   *
   * @type {Array<AgreementsReportRow>}
   * @memberof AgreementsReport
   */
  details?: Array<AgreementsReportRow>;
  /**
   *
   * @type {number}
   * @memberof AgreementsReport
   */
  factQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof AgreementsReport
   */
  planQuantity?: number;
}
/**
 * Строка отчета маржинальности (по бюджетной группе)
 * @export
 * @interface AgreementsReportRow
 */
export interface AgreementsReportRow {
  /**
   * Общий тоннаж отгруженного по всем активным соглашениям, т
   * @type {number}
   * @memberof AgreementsReportRow
   */
  activeFactQuantity?: number;
  /**
   * Общий плановый тоннаж по всем активным соглашениям, т
   * @type {number}
   * @memberof AgreementsReportRow
   */
  activePlanQuantity?: number;
  /**
   * Активные неисполненные, т. План - факт по активным неисполненным соглашениям.
   * @type {number}
   * @memberof AgreementsReportRow
   */
  activeRestQuantity?: number;
  /**
   * Код сортаментной группы
   * @type {string}
   * @memberof AgreementsReportRow
   */
  budgetGroupCode?: string;
  /**
   * К исполнению в текущем месяце, т
   * @type {number}
   * @memberof AgreementsReportRow
   */
  currentMonthRestQuantity?: number;
  /**
   * Истекшие неисполненные, т
   * @type {number}
   * @memberof AgreementsReportRow
   */
  expiredRestQuantity?: number;
  /**
   * Процент выполнения
   * @type {number}
   * @memberof AgreementsReportRow
   */
  factExecutedPct?: number;
  /**
   * Количество исполненное, т
   * @type {number}
   * @memberof AgreementsReportRow
   */
  factQuantity?: number;
  /**
   * К исполнению в следующем месяце, т
   * @type {number}
   * @memberof AgreementsReportRow
   */
  nextMonthRestQuantity?: number;
  /**
   * Количество общее, т
   * @type {number}
   * @memberof AgreementsReportRow
   */
  planQuantity?: number;
}
/**
 *
 * @export
 * @interface ApprovalObject
 */
export interface ApprovalObject {
  /**
   *
   * @type {ContractCostAgreement}
   * @memberof ApprovalObject
   */
  agreement?: ContractCostAgreement;
  /**
   *
   * @type {Deal}
   * @memberof ApprovalObject
   */
  deal?: Deal;
  /**
   *
   * @type {string}
   * @memberof ApprovalObject
   */
  kind?: string;
}
/**
 *
 * @export
 * @interface AuthLogoutResponse
 */
export interface AuthLogoutResponse {
  /**
   *
   * @type {string}
   * @memberof AuthLogoutResponse
   */
  logoutTime?: string;
  /**
   *
   * @type {UserProfile}
   * @memberof AuthLogoutResponse
   */
  profile?: UserProfile;
}
/**
 *
 * @export
 * @interface AvailableCatalogBaseSize
 */
export interface AvailableCatalogBaseSize {
  /**
   * Код группы
   * @type {string}
   * @memberof AvailableCatalogBaseSize
   */
  groupCode?: string;
  /**
   * Название группы
   * @type {string}
   * @memberof AvailableCatalogBaseSize
   */
  groupName?: string;
  /**
   * Родительская группа
   * @type {string}
   * @memberof AvailableCatalogBaseSize
   */
  parentCode?: string;
  /**
   *
   * @type {string}
   * @memberof AvailableCatalogBaseSize
   */
  parentName?: string;
  /**
   *
   * @type {Array<AvailableCatalogBaseSizeValue>}
   * @memberof AvailableCatalogBaseSize
   */
  values?: Array<AvailableCatalogBaseSizeValue>;
}
/**
 *
 * @export
 * @interface AvailableCatalogBaseSizeValue
 */
export interface AvailableCatalogBaseSizeValue {
  /**
   * Код значения
   * @type {string}
   * @memberof AvailableCatalogBaseSizeValue
   */
  code?: string;
  /**
   * Количество артикулов с таким базовым размером. Возможно приблизительное значение.
   * @type {number}
   * @memberof AvailableCatalogBaseSizeValue
   */
  productsQuantity?: number;
  /**
   * Значение (для отображения)
   * @type {string}
   * @memberof AvailableCatalogBaseSizeValue
   */
  value?: string;
}
/**
 *
 * @export
 * @interface AvailableCatalogManufacturer
 */
export interface AvailableCatalogManufacturer {
  /**
   *
   * @type {string}
   * @memberof AvailableCatalogManufacturer
   */
  manufacturerCode?: string;
  /**
   *
   * @type {string}
   * @memberof AvailableCatalogManufacturer
   */
  manufacturerTitle?: string;
  /**
   * Количество товаров от этого производителя
   * @type {number}
   * @memberof AvailableCatalogManufacturer
   */
  productsQuantity?: number;
}
/**
 *
 * @export
 * @interface AvailableCatalogProductType
 */
export interface AvailableCatalogProductType {
  /**
   * Код значения
   * @type {string}
   * @memberof AvailableCatalogProductType
   */
  code?: string;
  /**
   * Количество артикулов с таким видом товара. Возможно приблизительное значение.
   * @type {number}
   * @memberof AvailableCatalogProductType
   */
  productsQuantity?: number;
  /**
   * Значение (для отображения)
   * @type {string}
   * @memberof AvailableCatalogProductType
   */
  value?: string;
}
/**
 *
 * @export
 * @interface AvailableProductManufacturer
 */
export interface AvailableProductManufacturer {
  /**
   * Партии
   * @type {Array<BatchForAlternative>}
   * @memberof AvailableProductManufacturer
   */
  batches?: Array<BatchForAlternative>;
  /**
   * Есть доступ на просмотр цены закупки
   * @type {boolean}
   * @memberof AvailableProductManufacturer
   */
  canViewPurchasePrice?: boolean | null;
  /**
   * Найден по номенклатуре
   * @type {boolean}
   * @memberof AvailableProductManufacturer
   */
  foundByNomenclature?: boolean;
  /**
   * Найден по группе сегментации
   * @type {boolean}
   * @memberof AvailableProductManufacturer
   */
  foundBySegmentationGroup?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AvailableProductManufacturer
   */
  hasCustody?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AvailableProductManufacturer
   */
  hasIlliquid?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AvailableProductManufacturer
   */
  hasSelfPurchase?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AvailableProductManufacturer
   */
  hasTodhDiscount?: boolean;
  /**
   * Остаток, включая резервы
   * @type {number}
   * @memberof AvailableProductManufacturer
   */
  inStock?: number;
  /**
   * Остаток, доступный для продажи
   * @type {number}
   * @memberof AvailableProductManufacturer
   */
  inStockForSale?: number;
  /**
   * Флаг означает, что альтернатива содержит самую свежую партию поставки из всех альтернативных партий
   * @type {boolean}
   * @memberof AvailableProductManufacturer
   */
  isNew?: boolean;
  /**
   * Флаг означает, что альтернатива содержит самую старую партию поставки из всех альтернативных партий
   * @type {boolean}
   * @memberof AvailableProductManufacturer
   */
  isOld?: boolean;
  /**
   * Код производителя
   * @type {string}
   * @memberof AvailableProductManufacturer
   */
  manufacturerCode?: string;
  /**
   * Название производителя
   * @type {string}
   * @memberof AvailableProductManufacturer
   */
  manufacturerTitle?: string;
  /**
   * Минимальная розничная цена с учетом группы сегментации
   * @type {number}
   * @memberof AvailableProductManufacturer
   */
  minRetailUnitCost?: number;
  /**
   * Самая новая дата поставки партии товара-альтернативы
   * @type {string}
   * @memberof AvailableProductManufacturer
   */
  newReceiptDate?: string;
  /**
   * Самая малый срок хранения партии товара-альтернативы
   * @type {number}
   * @memberof AvailableProductManufacturer
   */
  newShelfLife?: number;
  /**
   *
   * @type {string}
   * @memberof AvailableProductManufacturer
   */
  nomenclatureCode?: string;
  /**
   *
   * @type {string}
   * @memberof AvailableProductManufacturer
   */
  nomenclatureName?: string;
  /**
   * Самая старая партия поставки партии товара-альтернативы
   * @type {string}
   * @memberof AvailableProductManufacturer
   */
  oldReceiptDate?: string;
  /**
   * Самый большой срок хранения партии товара-альтернативы
   * @type {number}
   * @memberof AvailableProductManufacturer
   */
  oldShelfLife?: number;
  /**
   * Артикул товара
   * @type {string}
   * @memberof AvailableProductManufacturer
   */
  productCode?: string;
  /**
   * Наименование товара
   * @type {string}
   * @memberof AvailableProductManufacturer
   */
  productTitle?: string;
  /**
   * Средневзвешенная цена закупки по партиям
   * @type {number}
   * @memberof AvailableProductManufacturer
   */
  purchasePrice?: number;
  /**
   *
   * @type {string}
   * @memberof AvailableProductManufacturer
   */
  segmentationGroupMdmKey?: string;
  /**
   *
   * @type {string}
   * @memberof AvailableProductManufacturer
   */
  segmentationGroupTitle?: string;
}
/**
 *
 * @export
 * @interface AvailableProductService
 */
export interface AvailableProductService {
  /**
   *
   * @type {string}
   * @memberof AvailableProductService
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof AvailableProductService
   */
  cost?: number;
  /**
   *
   * @type {string}
   * @memberof AvailableProductService
   */
  title?: string;
}
/**
 *
 * @export
 * @interface Batch
 */
export interface Batch {
  /**
   * Название файла сертификата
   * @type {string}
   * @memberof Batch
   */
  certificateName?: string;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  code?: string;
  /**
   * Дата создания партии
   * @type {string}
   * @memberof Batch
   */
  createdDate?: string;
  /**
   * Не продавать партию
   * @type {boolean}
   * @memberof Batch
   */
  doNotSell?: boolean;
  /**
   * Финансовые затраты
   * @type {number}
   * @memberof Batch
   */
  financialUnitCost?: number;
  /**
   * Код внешнего дефекта
   * @type {string}
   * @memberof Batch
   */
  illiquidDefectCode?: string;
  /**
   * Название вида дефектов
   * @type {string}
   * @memberof Batch
   */
  illiquidDefectTitle?: string;
  /**
   * Размер скидки для ржавых и дефектных партий
   * @type {number}
   * @memberof Batch
   */
  illiquidDiscountPct?: number;
  /**
   * Код степени ржавчины
   * @type {string}
   * @memberof Batch
   */
  illiquidRustCode?: string;
  /**
   * Название степени ржавчины
   * @type {string}
   * @memberof Batch
   */
  illiquidRustTitle?: string;
  /**
   * Остаток партии на складе
   * @type {number}
   * @memberof Batch
   */
  inStock?: number;
  /**
   * Единицы измерения остатка партии
   * @type {string}
   * @memberof Batch
   */
  inStockUnitCode?: string;
  /**
   * Единицы измерения остатка партии
   * @type {string}
   * @memberof Batch
   */
  inStockUnitShort?: string;
  /**
   * Партия на ответственном хранении
   * @type {boolean}
   * @memberof Batch
   */
  isSafeCustody?: boolean;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  lastStockDate?: string | null;
  /**
   * Логистические затраты склада
   * @type {number}
   * @memberof Batch
   */
  lwhUnitCost?: number;
  /**
   * Код производителя
   * @type {string}
   * @memberof Batch
   */
  manufacturerCode?: string;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  manufacturerTitle?: string;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  productCode?: string;
  /**
   * Цена закупки
   * @type {number}
   * @memberof Batch
   */
  purchaseUnitCost?: number;
  /**
   * Полное количество в партии
   * @type {number}
   * @memberof Batch
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  quantityUnitCode?: string;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  quantityUnitShort?: string;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  receiptDate?: string;
  /**
   * Самозакуп
   * @type {boolean}
   * @memberof Batch
   */
  selfPurchase?: boolean;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  sourceCode?: string;
  /**
   * Источник прихода партии на склад
   * @type {string}
   * @memberof Batch
   */
  sourceTitle?: string;
  /**
   * Скидка по неликвиду обнулена скидкой по некондиции
   * @type {boolean}
   * @memberof Batch
   */
  todhDiscountIlliquidBlocked?: boolean;
  /**
   * Размер скидки по неликвиду
   * @type {number}
   * @memberof Batch
   */
  todhDiscountPct?: number;
  /**
   * ТЗР
   * @type {number}
   * @memberof Batch
   */
  tpcUnitCost?: number;
  /**
   * Номер вагона
   * @type {string}
   * @memberof Batch
   */
  wagonNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  warehouseCode?: string;
  /**
   *
   * @type {string}
   * @memberof Batch
   */
  warehouseName?: string;
  /**
   * Полный вес партии, т
   * @type {number}
   * @memberof Batch
   */
  weight?: number;
}
/**
 *
 * @export
 * @interface BatchCertificate
 */
export interface BatchCertificate {
  /**
   *
   * @type {string}
   * @memberof BatchCertificate
   */
  batchCode?: string;
  /**
   * Ссылка для скачивания (если доступно)
   * @type {string}
   * @memberof BatchCertificate
   */
  downloadHref?: string;
  /**
   * Ошибка, если возникла при загрузке
   * @type {string}
   * @memberof BatchCertificate
   */
  error?: string;
  /**
   *
   * @type {string}
   * @memberof BatchCertificate
   */
  filename?: string;
  /**
   * Размер файла (если известен)
   * @type {number}
   * @memberof BatchCertificate
   */
  filesize?: number;
  /**
   * GUID загрузки, если запущена загрузка
   * @type {string}
   * @memberof BatchCertificate
   */
  loadingGuid?: string;
  /**
   * Состояние загрузки сертификата
   * @type {string}
   * @memberof BatchCertificate
   */
  stateCode?: string;
  /**
   *
   * @type {string}
   * @memberof BatchCertificate
   */
  stateTitle?: string;
}
/**
 *
 * @export
 * @interface BatchForAlternative
 */
export interface BatchForAlternative {
  /**
   *
   * @type {string}
   * @memberof BatchForAlternative
   */
  code?: string;
  /**
   *
   * @type {boolean}
   * @memberof BatchForAlternative
   */
  hasCustody?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BatchForAlternative
   */
  hasIlliquid?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BatchForAlternative
   */
  hasSelfPurchase?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BatchForAlternative
   */
  hasTodhDiscount?: boolean;
  /**
   * Остаток
   * @type {number}
   * @memberof BatchForAlternative
   */
  inStock?: number;
  /**
   * Средневзвешенная цена закупки
   * @type {number}
   * @memberof BatchForAlternative
   */
  purchasePrice?: number;
  /**
   * Дата поступления партии
   * @type {string}
   * @memberof BatchForAlternative
   */
  receiptDate?: string;
  /**
   * Срок хранения в днях
   * @type {number}
   * @memberof BatchForAlternative
   */
  shelfLife?: number;
}
/**
 *
 * @export
 * @interface BatchForReport
 */
export interface BatchForReport {
  /**
   *
   * @type {string}
   * @memberof BatchForReport
   */
  code?: string;
  /**
   *
   * @type {boolean}
   * @memberof BatchForReport
   */
  hasCustody?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BatchForReport
   */
  hasIlliquid?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BatchForReport
   */
  hasSelfPurchase?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BatchForReport
   */
  hasTodhDiscount?: boolean;
  /**
   * Остаток
   * @type {number}
   * @memberof BatchForReport
   */
  inStock?: number;
  /**
   * Средневзвешенная цена закупки
   * @type {number}
   * @memberof BatchForReport
   */
  purchasePrice?: number;
  /**
   * Дата поступления партии
   * @type {string}
   * @memberof BatchForReport
   */
  receiptDate?: string;
  /**
   * Срок хранения в днях
   * @type {number}
   * @memberof BatchForReport
   */
  shelfLife?: number;
  /**
   *
   * @type {string}
   * @memberof BatchForReport
   */
  unitCode?: string;
  /**
   *
   * @type {string}
   * @memberof BatchForReport
   */
  unitShortName?: string;
  /**
   * Номер вагона
   * @type {string}
   * @memberof BatchForReport
   */
  wagonNumber?: string;
}
/**
 * Пачка из партии на складе
 * @export
 * @interface BatchPack
 */
export interface BatchPack {
  /**
   * Код-идентификатор партии. В перспективе может отличаться от batchNumber.
   * @type {string}
   * @memberof BatchPack
   */
  batchCode?: string;
  /**
   * Номер упаковки
   * @type {string}
   * @memberof BatchPack
   */
  boxingNumber?: string;
  /**
   * Кассета - место хранения на складе
   * @type {string}
   * @memberof BatchPack
   */
  cassette?: string;
  /**
   * ВесПачкиПоСертификату
   * @type {number}
   * @memberof BatchPack
   */
  certificatePackWeight?: number;
  /**
   * Остаток на складе без учета резервов
   * @type {number}
   * @memberof BatchPack
   */
  inStock?: number;
  /**
   * Единицы измерения остатка пачки
   * @type {string}
   * @memberof BatchPack
   */
  inStockUnitCode?: string;
  /**
   * Единицы измерения остатка пачки
   * @type {string}
   * @memberof BatchPack
   */
  inStockUnitShort?: string;
  /**
   * Номер пачки
   * @type {string}
   * @memberof BatchPack
   */
  number?: string;
  /**
   * Номер в сертификате
   * @type {string}
   * @memberof BatchPack
   */
  numberInCertificate?: string;
  /**
   *
   * @type {string}
   * @memberof BatchPack
   */
  parameter?: string;
  /**
   * Количество
   * @type {number}
   * @memberof BatchPack
   */
  quantity?: number;
  /**
   * Единица измерения количества
   * @type {string}
   * @memberof BatchPack
   */
  quantityUnitCode?: string;
  /**
   * Единица измерения количества
   * @type {string}
   * @memberof BatchPack
   */
  quantityUnitShort?: string;
  /**
   * deprecated - Вес пачки
   * @type {number}
   * @memberof BatchPack
   */
  weight?: number;
}
/**
 *
 * @export
 * @interface BatchesBranchOffice
 */
export interface BatchesBranchOffice {
  /**
   * Код филиала
   * @type {string}
   * @memberof BatchesBranchOffice
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof BatchesBranchOffice
   */
  name?: string;
}
/**
 *
 * @export
 * @interface BatchesRequest
 */
export interface BatchesRequest {
  /**
   * Коды партий
   * @type {Array<string>}
   * @memberof BatchesRequest
   */
  batchesCodes?: Array<string> | null;
  /**
   * Коды филиалов
   * @type {Array<string>}
   * @memberof BatchesRequest
   */
  branchOfficeCodes?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof BatchesRequest
   */
  count?: number | null;
  /**
   * Дата от
   * @type {string}
   * @memberof BatchesRequest
   */
  dateFrom?: string | null;
  /**
   * Дата до
   * @type {string}
   * @memberof BatchesRequest
   */
  dateTo?: string | null;
  /**
   *
   * @type {number}
   * @memberof BatchesRequest
   */
  page?: number | null;
  /**
   * filter for batch
   * @type {string}
   * @memberof BatchesRequest
   */
  query?: string | null;
  /**
   * Коды складов
   * @type {Array<string>}
   * @memberof BatchesRequest
   */
  warehouseCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface BatchesResponse
 */
export interface BatchesResponse {
  /**
   * Найденные партии
   * @type {Array<Batch>}
   * @memberof BatchesResponse
   */
  batches?: Array<Batch>;
  /**
   *
   * @type {Array<BatchesBranchOffice>}
   * @memberof BatchesResponse
   */
  batchesBranchOffices?: Array<BatchesBranchOffice>;
  /**
   *
   * @type {Array<BatchPack>}
   * @memberof BatchesResponse
   */
  packs?: Array<BatchPack>;
  /**
   *
   * @type {Array<Product>}
   * @memberof BatchesResponse
   */
  products?: Array<Product>;
}
/**
 *
 * @export
 * @interface Bill
 */
export interface Bill {
  /**
   *
   * @type {Array<BillActionLog>}
   * @memberof Bill
   */
  actionLogs?: Array<BillActionLog>;
  /**
   * Код филиала
   * @type {string}
   * @memberof Bill
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  branchOfficeName?: string;
  /**
   * Доступна активация
   * @type {boolean}
   * @memberof Bill
   */
  canActivate?: boolean;
  /**
   * Доступна деактивация
   * @type {boolean}
   * @memberof Bill
   */
  canDeactivate?: boolean;
  /**
   * Доступно изменение наполнения счёта
   * @type {boolean}
   * @memberof Bill
   */
  canEdit?: boolean;
  /**
   * Доступно продление
   * @type {boolean}
   * @memberof Bill
   */
  canProlong?: boolean;
  /**
   * Доступно фактурирование
   * @type {boolean}
   * @memberof Bill
   */
  canReconcile?: boolean;
  /**
   * Можно снять флаг наличия подчиненных документов
   * @type {boolean}
   * @memberof Bill
   */
  canRemoveSubordinateDoc?: boolean;
  /**
   * Можно обновить комментарии для склада
   * @type {boolean}
   * @memberof Bill
   */
  canUpdateStoreComments?: boolean;
  /**
   * Доступно снятие резервов
   * @type {boolean}
   * @memberof Bill
   */
  canWithdrawSoftReserve?: boolean;
  /**
   * Уникальный код счета
   * @type {string}
   * @memberof Bill
   */
  code?: string;
  /**
   * Список комментариев счета
   * @type {Array<BillComment>}
   * @memberof Bill
   */
  comments?: Array<BillComment>;
  /**
   *
   * @type {Consignee}
   * @memberof Bill
   */
  consignee?: Consignee;
  /**
   *
   * @type {ClientContract}
   * @memberof Bill
   */
  contract?: ClientContract;
  /**
   *
   * @type {Customer}
   * @memberof Bill
   */
  customer?: Customer;
  /**
   * Код клиента
   * @type {string}
   * @memberof Bill
   */
  customerCode?: string;
  /**
   * Код сделки (опционально)
   * @type {string}
   * @memberof Bill
   */
  dealCode?: string;
  /**
   * Дата создания запрос на сделку
   * @type {string}
   * @memberof Bill
   */
  dealCreatedAt?: string;
  /**
   * Срок действия заявки
   * @type {string}
   * @memberof Bill
   */
  dealValidUntil?: string;
  /**
   * Стоимость доставки
   * @type {number}
   * @memberof Bill
   */
  deliveryTotalCost?: number | null;
  /**
   *
   * @type {DeliveryType}
   * @memberof Bill
   */
  deliveryType?: DeliveryType;
  /**
   *
   * @type {Employee}
   * @memberof Bill
   */
  distributorEmployee?: Employee;
  /**
   * Код сотрудника-реализатора
   * @type {string}
   * @memberof Bill
   */
  distributorEmployeeCode?: string;
  /**
   * Время создания счета
   * @type {string}
   * @memberof Bill
   */
  documentDate?: string;
  /**
   * Номер документа счета
   * @type {string}
   * @memberof Bill
   */
  documentNumber?: string;
  /**
   * У счета есть подчиненный документ. Подчиненный документ - оплата по счету.
   * @type {boolean}
   * @memberof Bill
   */
  hasSubordinateDoc?: boolean;
  /**
   * Есть доступ на обновление
   * @type {boolean}
   * @memberof Bill
   */
  hasUpdateAccess?: boolean;
  /**
   * Документ в статусе удален
   * @type {boolean}
   * @memberof Bill
   */
  isDeleted?: boolean;
  /**
   * MX является источником появления счета
   * @type {boolean}
   * @memberof Bill
   */
  isMX?: boolean | null;
  /**
   *
   * @type {Employee}
   * @memberof Bill
   */
  motivationEmployee?: Employee;
  /**
   *
   * @type {BillPartners}
   * @memberof Bill
   */
  partners?: BillPartners;
  /**
   *
   * @type {Participant}
   * @memberof Bill
   */
  payer?: Participant;
  /**
   *
   * @type {BillPaymentSummary}
   * @memberof Bill
   */
  paymentSummary?: BillPaymentSummary;
  /**
   * Остаток по оплатам, руб (заполняется только в цепочке документов)
   * @type {number}
   * @memberof Bill
   */
  paymentsRestCost?: number | null;
  /**
   * Итого по оплатам, руб (заполняется только в цепочке документов)
   * @type {number}
   * @memberof Bill
   */
  paymentsTotalCost?: number | null;
  /**
   *
   * @type {Array<BillPosition>}
   * @memberof Bill
   */
  positions?: Array<BillPosition>;
  /**
   * Общий вес позиций, кг
   * @type {number}
   * @memberof Bill
   */
  positionsTotalWeight?: number;
  /**
   * Выбрано дней отсрочки платежа
   * @type {number}
   * @memberof Bill
   */
  postpayDays?: number;
  /**
   *
   * @type {number}
   * @memberof Bill
   */
  prepayPct?: number;
  /**
   *
   * @type {BillRank}
   * @memberof Bill
   */
  rank?: BillRank;
  /**
   * Время фактурирования счёта
   * @type {string}
   * @memberof Bill
   */
  reconciledAt?: string | null;
  /**
   * Итого по возвратам, руб.
   * @type {number}
   * @memberof Bill
   */
  returnsTotalCost?: number | null;
  /**
   * Итого по возвратам, кг
   * @type {number}
   * @memberof Bill
   */
  returnsTotalWeight?: number | null;
  /**
   * Общая стоимость услуг
   * @type {number}
   * @memberof Bill
   */
  servicesTotalCost?: number | null;
  /**
   * Остаток по отгрузкам, руб. (заполняется только в цепочке документов)
   * @type {number}
   * @memberof Bill
   */
  shipmentsRestCost?: number | null;
  /**
   * Остаток по отгрузкам, кг (заполняется только в цепочке документов)
   * @type {number}
   * @memberof Bill
   */
  shipmentsRestWeight?: number | null;
  /**
   *
   * @type {BillShipmentsState}
   * @memberof Bill
   */
  shipmentsState?: BillShipmentsState;
  /**
   * Итого по отгрузкам, руб.
   * @type {number}
   * @memberof Bill
   */
  shipmentsTotalCost?: number | null;
  /**
   * Итого по отгрузкам, кг
   * @type {number}
   * @memberof Bill
   */
  shipmentsTotalWeight?: number | null;
  /**
   * Отобразить подсказку о том, что по одной или нескольким позициям счёта имеется незакрытая доставка покупателю
   * @type {boolean}
   * @memberof Bill
   */
  showReconciliationHasActiveDpShipmentWarning?: boolean;
  /**
   * Отобразить подсказку о том, что в счете есть товары, отгруженные менее порога, и поэтому фактурирование по всему счёту сразу недоступно
   * @type {boolean}
   * @memberof Bill
   */
  showReconciliationThresholdHint?: boolean;
  /**
   *
   * @type {BillState}
   * @memberof Bill
   */
  state?: BillState;
  /**
   * Подчинённые документы
   * @type {Array<BillSubordinateDoc>}
   * @memberof Bill
   */
  subordinateDocs?: Array<BillSubordinateDoc>;
  /**
   * Стоимость по счету
   * @type {number}
   * @memberof Bill
   */
  totalCost?: number;
  /**
   * Стоимость по счету без НДС
   * @type {number}
   * @memberof Bill
   */
  totalCostWoVat?: number;
  /**
   *
   * @type {string}
   * @memberof Bill
   */
  validUntil?: string;
  /**
   *
   * @type {VatRate}
   * @memberof Bill
   */
  vatRate?: VatRate;
  /**
   * Сумма НДС по счёту
   * @type {number}
   * @memberof Bill
   */
  vatTotalCost?: number;
}
/**
 *
 * @export
 * @interface BillActionLog
 */
export interface BillActionLog {
  /**
   *
   * @type {string}
   * @memberof BillActionLog
   */
  createdAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof BillActionLog
   */
  initiatorEmployee?: Employee;
  /**
   *
   * @type {BillActionLogData}
   * @memberof BillActionLog
   */
  logData?: BillActionLogData;
  /**
   *
   * @type {BillActionLogType}
   * @memberof BillActionLog
   */
  logType?: BillActionLogType;
}
/**
 * Детальная информация из лога действий по счёту. Может быть заполнено только одно поле или не заполнено вообще ни одного (зависит от типа лога)
 * @export
 * @interface BillActionLogData
 */
export interface BillActionLogData {
  /**
   *
   * @type {BillActionLogDataAutoReserveSettingUpdate}
   * @memberof BillActionLogData
   */
  autoReserveSettingUpdate?: BillActionLogDataAutoReserveSettingUpdate;
  /**
   *
   * @type {BillActionLogDataPositionProductEdit}
   * @memberof BillActionLogData
   */
  positionProductEdit?: BillActionLogDataPositionProductEdit;
  /**
   *
   * @type {BillActionLogDataPositionQuantityEdit}
   * @memberof BillActionLogData
   */
  positionQuantityEdit?: BillActionLogDataPositionQuantityEdit;
  /**
   *
   * @type {BillActionLogDataServiceEdit}
   * @memberof BillActionLogData
   */
  positionServiceEdit?: BillActionLogDataServiceEdit;
  /**
   *
   * @type {BillActionLogDataPositionSplitAdd}
   * @memberof BillActionLogData
   */
  positionSplitAdd?: BillActionLogDataPositionSplitAdd;
  /**
   *
   * @type {BillActionLogDataPositionUnitCostEdit}
   * @memberof BillActionLogData
   */
  positionUnitCostEdit?: BillActionLogDataPositionUnitCostEdit;
  /**
   *
   * @type {BillActionLogDataProlongation}
   * @memberof BillActionLogData
   */
  prolongation?: BillActionLogDataProlongation;
  /**
   *
   * @type {BillActionLogDataReconciliation}
   * @memberof BillActionLogData
   */
  reconciliation?: BillActionLogDataReconciliation;
  /**
   *
   * @type {BillActionLogDataReservationRequest}
   * @memberof BillActionLogData
   */
  reservationRequest?: BillActionLogDataReservationRequest;
  /**
   *
   * @type {BillActionLogDataReservationWithdraw}
   * @memberof BillActionLogData
   */
  reservationWithdraw?: BillActionLogDataReservationWithdraw;
  /**
   *
   * @type {BillActionLogDataStateUpdate}
   * @memberof BillActionLogData
   */
  stateUpdate?: BillActionLogDataStateUpdate;
}
/**
 * Заполняется либо from и to либо positions (в зависимости от того, изменена была настройка в шапке или в позиции)
 * @export
 * @interface BillActionLogDataAutoReserveSettingUpdate
 */
export interface BillActionLogDataAutoReserveSettingUpdate {
  /**
   * Состояние настройки авто-резерва - было
   * @type {string}
   * @memberof BillActionLogDataAutoReserveSettingUpdate
   */
  from?: BillActionLogDataAutoReserveSettingUpdateFromEnum;
  /**
   * Изменение настройки в позициях
   * @type {Array<BillActionLogDataAutoReserveSettingUpdatePosition>}
   * @memberof BillActionLogDataAutoReserveSettingUpdate
   */
  positions?: Array<BillActionLogDataAutoReserveSettingUpdatePosition>;
  /**
   * Состояние настройки авто-резерва - стало
   * @type {string}
   * @memberof BillActionLogDataAutoReserveSettingUpdate
   */
  to?: BillActionLogDataAutoReserveSettingUpdateToEnum;
}

export const BillActionLogDataAutoReserveSettingUpdateFromEnum = {
  Disabled: 'disabled',
  Enabled: 'enabled',
  Intermediate: 'intermediate',
} as const;

export type BillActionLogDataAutoReserveSettingUpdateFromEnum =
  (typeof BillActionLogDataAutoReserveSettingUpdateFromEnum)[keyof typeof BillActionLogDataAutoReserveSettingUpdateFromEnum];
export const BillActionLogDataAutoReserveSettingUpdateToEnum = {
  Disabled: 'disabled',
  Enabled: 'enabled',
  Intermediate: 'intermediate',
} as const;

export type BillActionLogDataAutoReserveSettingUpdateToEnum =
  (typeof BillActionLogDataAutoReserveSettingUpdateToEnum)[keyof typeof BillActionLogDataAutoReserveSettingUpdateToEnum];

/**
 *
 * @export
 * @interface BillActionLogDataAutoReserveSettingUpdatePosition
 */
export interface BillActionLogDataAutoReserveSettingUpdatePosition {
  /**
   * Авто-резерв включён - было
   * @type {boolean}
   * @memberof BillActionLogDataAutoReserveSettingUpdatePosition
   */
  from?: boolean;
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillActionLogDataAutoReserveSettingUpdatePosition
   */
  position?: number;
  /**
   * Код продукта
   * @type {string}
   * @memberof BillActionLogDataAutoReserveSettingUpdatePosition
   */
  productCode?: string;
  /**
   * Авто-резерв включён - стало
   * @type {boolean}
   * @memberof BillActionLogDataAutoReserveSettingUpdatePosition
   */
  to?: boolean;
  /**
   * Код склада
   * @type {string}
   * @memberof BillActionLogDataAutoReserveSettingUpdatePosition
   */
  warehouseCode?: string;
}
/**
 *
 * @export
 * @interface BillActionLogDataPositionProductEdit
 */
export interface BillActionLogDataPositionProductEdit {
  /**
   * Замена товара в позиции
   * @type {Array<BillActionLogDataPositionProductEditPosition>}
   * @memberof BillActionLogDataPositionProductEdit
   */
  positions?: Array<BillActionLogDataPositionProductEditPosition>;
}
/**
 *
 * @export
 * @interface BillActionLogDataPositionProductEditPosition
 */
export interface BillActionLogDataPositionProductEditPosition {
  /**
   * Код позиции в счёте
   * @type {string}
   * @memberof BillActionLogDataPositionProductEditPosition
   */
  billProductCode?: string;
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillActionLogDataPositionProductEditPosition
   */
  position?: number;
  /**
   * Код товара - было
   * @type {string}
   * @memberof BillActionLogDataPositionProductEditPosition
   */
  productCodeFrom?: string;
  /**
   * Код товара - стало
   * @type {string}
   * @memberof BillActionLogDataPositionProductEditPosition
   */
  productCodeTo?: string;
  /**
   * Название товара - было
   * @type {string}
   * @memberof BillActionLogDataPositionProductEditPosition
   */
  productTitleFrom?: string;
  /**
   * Название товара - стало
   * @type {string}
   * @memberof BillActionLogDataPositionProductEditPosition
   */
  productTitleTo?: string;
}
/**
 *
 * @export
 * @interface BillActionLogDataPositionQuantityEdit
 */
export interface BillActionLogDataPositionQuantityEdit {
  /**
   * Изменение количества в позиции
   * @type {Array<BillActionLogDataPositionQuantityEditPosition>}
   * @memberof BillActionLogDataPositionQuantityEdit
   */
  positions?: Array<BillActionLogDataPositionQuantityEditPosition>;
}
/**
 *
 * @export
 * @interface BillActionLogDataPositionQuantityEditPosition
 */
export interface BillActionLogDataPositionQuantityEditPosition {
  /**
   * Код позиции в счёте
   * @type {string}
   * @memberof BillActionLogDataPositionQuantityEditPosition
   */
  billProductCode?: string;
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillActionLogDataPositionQuantityEditPosition
   */
  position?: number;
  /**
   * Количество в БЕИ - было
   * @type {number}
   * @memberof BillActionLogDataPositionQuantityEditPosition
   */
  quantityFrom?: number;
  /**
   * Количество в БЕИ - стало
   * @type {number}
   * @memberof BillActionLogDataPositionQuantityEditPosition
   */
  quantityTo?: number;
  /**
   * Количество в ПЕИ - было
   * @type {number}
   * @memberof BillActionLogDataPositionQuantityEditPosition
   */
  quantityUnitFrom?: number;
  /**
   * Количество в ПЕИ - стало
   * @type {number}
   * @memberof BillActionLogDataPositionQuantityEditPosition
   */
  quantityUnitTo?: number;
  /**
   *
   * @type {string}
   * @memberof BillActionLogDataPositionQuantityEditPosition
   */
  unitCode?: string;
  /**
   *
   * @type {string}
   * @memberof BillActionLogDataPositionQuantityEditPosition
   */
  unitShort?: string;
}
/**
 *
 * @export
 * @interface BillActionLogDataPositionSplitAdd
 */
export interface BillActionLogDataPositionSplitAdd {
  /**
   * Добавление сплита позиции (новые позиции)
   * @type {Array<BillActionLogDataPositionSplitAddPosition>}
   * @memberof BillActionLogDataPositionSplitAdd
   */
  positions?: Array<BillActionLogDataPositionSplitAddPosition>;
}
/**
 *
 * @export
 * @interface BillActionLogDataPositionSplitAddPosition
 */
export interface BillActionLogDataPositionSplitAddPosition {
  /**
   * Код позиции в счёте
   * @type {string}
   * @memberof BillActionLogDataPositionSplitAddPosition
   */
  billProductCode?: string;
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillActionLogDataPositionSplitAddPosition
   */
  position?: number;
  /**
   * Название товара
   * @type {string}
   * @memberof BillActionLogDataPositionSplitAddPosition
   */
  productTitle?: string;
  /**
   * Количество в БЕИ - стало
   * @type {number}
   * @memberof BillActionLogDataPositionSplitAddPosition
   */
  quantityTo?: number;
  /**
   * Количество в ПЕИ - стало
   * @type {number}
   * @memberof BillActionLogDataPositionSplitAddPosition
   */
  quantityUnitTo?: number;
  /**
   *
   * @type {string}
   * @memberof BillActionLogDataPositionSplitAddPosition
   */
  unitCode?: string;
  /**
   *
   * @type {string}
   * @memberof BillActionLogDataPositionSplitAddPosition
   */
  unitShort?: string;
}
/**
 *
 * @export
 * @interface BillActionLogDataPositionUnitCostEdit
 */
export interface BillActionLogDataPositionUnitCostEdit {
  /**
   * Изменение цены позиции
   * @type {Array<BillActionLogDataPositionUnitCostEditPosition>}
   * @memberof BillActionLogDataPositionUnitCostEdit
   */
  positions?: Array<BillActionLogDataPositionUnitCostEditPosition>;
}
/**
 *
 * @export
 * @interface BillActionLogDataPositionUnitCostEditPosition
 */
export interface BillActionLogDataPositionUnitCostEditPosition {
  /**
   * Код позиции в счёте
   * @type {string}
   * @memberof BillActionLogDataPositionUnitCostEditPosition
   */
  billProductCode?: string;
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillActionLogDataPositionUnitCostEditPosition
   */
  position?: number;
  /**
   * Цена товара - было
   * @type {number}
   * @memberof BillActionLogDataPositionUnitCostEditPosition
   */
  unitCostFrom?: number;
  /**
   * Цена товара - стало
   * @type {number}
   * @memberof BillActionLogDataPositionUnitCostEditPosition
   */
  unitCostTo?: number;
}
/**
 *
 * @export
 * @interface BillActionLogDataProlongation
 */
export interface BillActionLogDataProlongation {
  /**
   * Дата действия счёта - было
   * @type {string}
   * @memberof BillActionLogDataProlongation
   */
  validUntilFrom?: string;
  /**
   * Дата действия счёта - стало
   * @type {string}
   * @memberof BillActionLogDataProlongation
   */
  validUntilTo?: string;
}
/**
 * Заполняется либо fullReconciled либо positions (в зависимости от того было ли применено фактурирование в шапке или в позициях)
 * @export
 * @interface BillActionLogDataReconciliation
 */
export interface BillActionLogDataReconciliation {
  /**
   * Счёт фактурирован целиком
   * @type {boolean}
   * @memberof BillActionLogDataReconciliation
   */
  fullReconciled?: boolean;
  /**
   * Информация по фактурированию конкретных позиций
   * @type {Array<BillActionLogDataReconciliationPosition>}
   * @memberof BillActionLogDataReconciliation
   */
  positions?: Array<BillActionLogDataReconciliationPosition>;
}
/**
 *
 * @export
 * @interface BillActionLogDataReconciliationPosition
 */
export interface BillActionLogDataReconciliationPosition {
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillActionLogDataReconciliationPosition
   */
  position?: number;
  /**
   * Код продукта
   * @type {string}
   * @memberof BillActionLogDataReconciliationPosition
   */
  productCode?: string;
  /**
   * Количество в БЕИ - было
   * @type {number}
   * @memberof BillActionLogDataReconciliationPosition
   */
  quantityFrom?: number;
  /**
   * Количество в БЕИ - стало
   * @type {number}
   * @memberof BillActionLogDataReconciliationPosition
   */
  quantityTo?: number;
  /**
   * Количество в ПЕИ - было
   * @type {number}
   * @memberof BillActionLogDataReconciliationPosition
   */
  quantityUnitFrom?: number;
  /**
   * Количество в ПЕИ - стало
   * @type {number}
   * @memberof BillActionLogDataReconciliationPosition
   */
  quantityUnitTo?: number;
  /**
   * Стоимость - было
   * @type {number}
   * @memberof BillActionLogDataReconciliationPosition
   */
  totalCostFrom?: number | null;
  /**
   * Стоимость - стало
   * @type {number}
   * @memberof BillActionLogDataReconciliationPosition
   */
  totalCostTo?: number | null;
  /**
   *
   * @type {string}
   * @memberof BillActionLogDataReconciliationPosition
   */
  unitCode?: string;
  /**
   *
   * @type {string}
   * @memberof BillActionLogDataReconciliationPosition
   */
  unitShort?: string;
  /**
   * Код склада
   * @type {string}
   * @memberof BillActionLogDataReconciliationPosition
   */
  warehouseCode?: string;
}
/**
 * Заполняется либо full либо positions
 * @export
 * @interface BillActionLogDataReservationRequest
 */
export interface BillActionLogDataReservationRequest {
  /**
   * Запрос на резервирование по всему счёту
   * @type {boolean}
   * @memberof BillActionLogDataReservationRequest
   */
  full?: boolean;
  /**
   * Позиции, по которым был запрос на резервирование
   * @type {Array<BillActionLogDataReservationRequestPosition>}
   * @memberof BillActionLogDataReservationRequest
   */
  positions?: Array<BillActionLogDataReservationRequestPosition>;
}
/**
 *
 * @export
 * @interface BillActionLogDataReservationRequestPosition
 */
export interface BillActionLogDataReservationRequestPosition {
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillActionLogDataReservationRequestPosition
   */
  position?: number;
  /**
   * Код продукта
   * @type {string}
   * @memberof BillActionLogDataReservationRequestPosition
   */
  productCode?: string;
  /**
   * Код склада
   * @type {string}
   * @memberof BillActionLogDataReservationRequestPosition
   */
  warehouseCode?: string;
}
/**
 * Заполняется либо full либо positions
 * @export
 * @interface BillActionLogDataReservationWithdraw
 */
export interface BillActionLogDataReservationWithdraw {
  /**
   * Снятие резерва по всему счёту
   * @type {boolean}
   * @memberof BillActionLogDataReservationWithdraw
   */
  full?: boolean;
  /**
   * Позиции, по которым был снят резерв
   * @type {Array<BillActionLogDataReservationWithdrawPosition>}
   * @memberof BillActionLogDataReservationWithdraw
   */
  positions?: Array<BillActionLogDataReservationWithdrawPosition>;
}
/**
 *
 * @export
 * @interface BillActionLogDataReservationWithdrawPosition
 */
export interface BillActionLogDataReservationWithdrawPosition {
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillActionLogDataReservationWithdrawPosition
   */
  position?: number;
  /**
   * Код продукта
   * @type {string}
   * @memberof BillActionLogDataReservationWithdrawPosition
   */
  productCode?: string;
  /**
   * Код склада
   * @type {string}
   * @memberof BillActionLogDataReservationWithdrawPosition
   */
  warehouseCode?: string;
}
/**
 *
 * @export
 * @interface BillActionLogDataServiceEdit
 */
export interface BillActionLogDataServiceEdit {
  /**
   * Изменение услуг позиции
   * @type {Array<BillActionLogDataServiceEditPosition>}
   * @memberof BillActionLogDataServiceEdit
   */
  positions?: Array<BillActionLogDataServiceEditPosition>;
}
/**
 *
 * @export
 * @interface BillActionLogDataServiceEditPosition
 */
export interface BillActionLogDataServiceEditPosition {
  /**
   * Код позиции в счёте
   * @type {string}
   * @memberof BillActionLogDataServiceEditPosition
   */
  billProductCode?: string;
  /**
   * Стоимость услуги - было
   * @type {number}
   * @memberof BillActionLogDataServiceEditPosition
   */
  manualTotalCostFrom?: number;
  /**
   * Стоимость услуги - стало
   * @type {number}
   * @memberof BillActionLogDataServiceEditPosition
   */
  manualTotalCostTo?: number;
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillActionLogDataServiceEditPosition
   */
  position?: number;
  /**
   * Название услуги
   * @type {string}
   * @memberof BillActionLogDataServiceEditPosition
   */
  serviceTitle?: string;
}
/**
 *
 * @export
 * @interface BillActionLogDataStateUpdate
 */
export interface BillActionLogDataStateUpdate {
  /**
   *
   * @type {BillState}
   * @memberof BillActionLogDataStateUpdate
   */
  fromState?: BillState;
  /**
   *
   * @type {BillState}
   * @memberof BillActionLogDataStateUpdate
   */
  toState?: BillState;
}
/**
 *
 * @export
 * @interface BillActionLogType
 */
export interface BillActionLogType {
  /**
   *
   * @type {string}
   * @memberof BillActionLogType
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof BillActionLogType
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof BillActionLogType
   */
  title?: string;
}
/**
 *
 * @export
 * @interface BillBranchOffice
 */
export interface BillBranchOffice {
  /**
   * Код филиала
   * @type {string}
   * @memberof BillBranchOffice
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof BillBranchOffice
   */
  name?: string;
}
/**
 *
 * @export
 * @interface BillComment
 */
export interface BillComment {
  /**
   * Комментарий счёта
   * @type {string}
   * @memberof BillComment
   */
  comment?: string;
  /**
   * Код типа комментария счёта
   * @type {string}
   * @memberof BillComment
   */
  typeCode: string;
  /**
   * Заголовок типа комментария счёта
   * @type {string}
   * @memberof BillComment
   */
  typeTitle?: string;
}
/**
 *
 * @export
 * @interface BillPartners
 */
export interface BillPartners {
  /**
   *
   * @type {ParticipantPartner}
   * @memberof BillPartners
   */
  consignee?: ParticipantPartner;
  /**
   *
   * @type {ParticipantPartner}
   * @memberof BillPartners
   */
  payer?: ParticipantPartner;
}
/**
 * Сводка по оплате счёта
 * @export
 * @interface BillPaymentSummary
 */
export interface BillPaymentSummary {
  /**
   * Списание долгов
   * @type {number}
   * @memberof BillPaymentSummary
   */
  debtReliefAmount?: number;
  /**
   * Просрочено
   * @type {number}
   * @memberof BillPaymentSummary
   */
  overdueReceivablesAmount?: number;
  /**
   * Оплачено (включая списание долгов)
   * @type {number}
   * @memberof BillPaymentSummary
   */
  paidAmount?: number;
  /**
   *
   * @type {string}
   * @memberof BillPaymentSummary
   */
  paymentStateCode?: string;
  /**
   *
   * @type {string}
   * @memberof BillPaymentSummary
   */
  paymentStateTitle?: string;
  /**
   * Дебиторская задолженность
   * @type {number}
   * @memberof BillPaymentSummary
   */
  receivablesAmount?: number;
  /**
   *
   * @type {string}
   * @memberof BillPaymentSummary
   */
  updatedAt?: string;
}
/**
 *
 * @export
 * @interface BillPosition
 */
export interface BillPosition {
  /**
   * Количество в базовой единице
   * @type {number}
   * @memberof BillPosition
   */
  baseQuantity?: number;
  /**
   * Код базовой единицы
   * @type {string}
   * @memberof BillPosition
   */
  baseUnitCode?: string;
  /**
   * Короткое имя базовой единицы
   * @type {string}
   * @memberof BillPosition
   */
  baseUnitShort?: string;
  /**
   * Доступно фактурирование
   * @type {boolean}
   * @memberof BillPosition
   */
  canReconcile?: boolean;
  /**
   * Уникальный код
   * @type {string}
   * @memberof BillPosition
   */
  code?: string;
  /**
   * Список комментариев к позиции счёта
   * @type {Array<BillPositionComment>}
   * @memberof BillPosition
   */
  comments?: Array<BillPositionComment>;
  /**
   * Номер позиции в заявке
   * @type {number}
   * @memberof BillPosition
   */
  dealLineNumber?: number;
  /**
   * Долг
   * @type {number}
   * @memberof BillPosition
   */
  dept?: number;
  /**
   * Скидка
   * @type {number}
   * @memberof BillPosition
   */
  discountUnitCost?: number;
  /**
   * Есть дочерние позиции в сплите
   * @type {boolean}
   * @memberof BillPosition
   */
  hasChildSplitPositions?: boolean;
  /**
   * Признак архивной позиции
   * @type {boolean}
   * @memberof BillPosition
   */
  isArchive?: boolean;
  /**
   * Прайсовая цена
   * @type {boolean}
   * @memberof BillPosition
   */
  isPriceListCost?: boolean;
  /**
   * Позиция является услугой
   * @type {boolean}
   * @memberof BillPosition
   */
  isService?: boolean;
  /**
   * Наценка
   * @type {number}
   * @memberof BillPosition
   */
  markupUnitCost?: number;
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillPosition
   */
  position?: number;
  /**
   *
   * @type {Product}
   * @memberof BillPosition
   */
  product?: Product;
  /**
   * Название товара контрагента
   * @type {string}
   * @memberof BillPosition
   */
  productParticipantName?: string;
  /**
   *
   * @type {number}
   * @memberof BillPosition
   */
  psUnitCost?: number;
  /**
   * Количество в unitShort ЕИ
   * @type {number}
   * @memberof BillPosition
   */
  quantity?: number;
  /**
   * Время фактурирования позиции счёта
   * @type {string}
   * @memberof BillPosition
   */
  reconciledAt?: string | null;
  /**
   *
   * @type {ReservationSummary}
   * @memberof BillPosition
   */
  reservationSummary?: ReservationSummary;
  /**
   * Стоимость услуг (включая доставку)
   * @type {number}
   * @memberof BillPosition
   */
  serviceCost?: number;
  /**
   * Услуги
   * @type {Array<BillPositionService>}
   * @memberof BillPosition
   */
  services?: Array<BillPositionService>;
  /**
   * Отгруженное количество в БЕИ
   * @type {number}
   * @memberof BillPosition
   */
  shippedQuantity?: number | null;
  /**
   * Отобразить подсказку о том, что по данному товару имеется незакрытая доставка покупателю
   * @type {boolean}
   * @memberof BillPosition
   */
  showReconciliationHasActiveDpShipmentWarning?: boolean;
  /**
   * Отобразить подсказку о том, что отгружено менее порога фактурирования, поэтому функция доступна только менеджерам резерва
   * @type {boolean}
   * @memberof BillPosition
   */
  showReconciliationThresholdHint?: boolean;
  /**
   * При попытке фактурирования отобразить предупреждение о том, что отгружено менее порога
   * @type {boolean}
   * @memberof BillPosition
   */
  showReconciliationThresholdWarning?: boolean;
  /**
   * Код родительской позиции сплита
   * @type {string}
   * @memberof BillPosition
   */
  splitParentPositionCode?: string | null;
  /**
   * Суммарное количество в сплите (в ПЕИ - unitShort)
   * @type {number}
   * @memberof BillPosition
   */
  splitQuantityUnit?: number | null;
  /**
   * Стоимость позиции
   * @type {number}
   * @memberof BillPosition
   */
  sumCost?: number | null;
  /**
   * Стоимость позиции без НДС
   * @type {number}
   * @memberof BillPosition
   */
  sumCostWoVat?: number;
  /**
   *
   * @type {string}
   * @memberof BillPosition
   */
  unitCode?: string;
  /**
   *
   * @type {number}
   * @memberof BillPosition
   */
  unitCost?: number;
  /**
   * Цена финальная
   * @type {number}
   * @memberof BillPosition
   */
  unitCostFinal?: number | null;
  /**
   * Цена финальная без НДС
   * @type {number}
   * @memberof BillPosition
   */
  unitCostWoVat?: number;
  /**
   *
   * @type {string}
   * @memberof BillPosition
   */
  unitShort?: string;
  /**
   * Сумма НДС по позиции
   * @type {number}
   * @memberof BillPosition
   */
  vatSumCost?: number;
  /**
   *
   * @type {number}
   * @memberof BillPosition
   */
  vatTotalCost?: number;
  /**
   * Цена НДС от цены финальной
   * @type {number}
   * @memberof BillPosition
   */
  vatUnitCost?: number;
  /**
   *
   * @type {Warehouse}
   * @memberof BillPosition
   */
  warehouse?: Warehouse;
  /**
   * Вес, т
   * @type {number}
   * @memberof BillPosition
   */
  weight?: number;
}
/**
 *
 * @export
 * @interface BillPositionComment
 */
export interface BillPositionComment {
  /**
   * Комментарий к позиции счёта
   * @type {string}
   * @memberof BillPositionComment
   */
  comment?: string;
  /**
   * Код типа комментария к позиции счёта
   * @type {string}
   * @memberof BillPositionComment
   */
  typeCode: string;
  /**
   * Заголовок типа комментария к позиции счёта
   * @type {string}
   * @memberof BillPositionComment
   */
  typeTitle?: string;
}
/**
 *
 * @export
 * @interface BillPositionService
 */
export interface BillPositionService {
  /**
   * Услуга действует, т.е. стоимость услуги влияет на стоимость позиции. Если нет, то услугу следует выводить перечеркнутой.
   * @type {boolean}
   * @memberof BillPositionService
   */
  active?: boolean;
  /**
   *
   * @type {string}
   * @memberof BillPositionService
   */
  code?: string;
  /**
   * Стоимость услуги на единицу
   * @type {number}
   * @memberof BillPositionService
   */
  cost?: number | null;
  /**
   *
   * @type {string}
   * @memberof BillPositionService
   */
  title?: string;
  /**
   * Суммарная стоимость
   * @type {number}
   * @memberof BillPositionService
   */
  totalCost?: number | null;
}
/**
 *
 * @export
 * @interface BillProductSoftReserveSetting
 */
export interface BillProductSoftReserveSetting {
  /**
   *
   * @type {boolean}
   * @memberof BillProductSoftReserveSetting
   */
  autoReserveEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof BillProductSoftReserveSetting
   */
  productCode?: string;
  /**
   *
   * @type {string}
   * @memberof BillProductSoftReserveSetting
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof BillProductSoftReserveSetting
   */
  warehouseCode?: string;
}
/**
 *
 * @export
 * @interface BillProductSoftReserveSettingUpdate
 */
export interface BillProductSoftReserveSettingUpdate {
  /**
   *
   * @type {boolean}
   * @memberof BillProductSoftReserveSettingUpdate
   */
  autoReserveEnabled?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof BillProductSoftReserveSettingUpdate
   */
  productCode: string;
  /**
   *
   * @type {string}
   * @memberof BillProductSoftReserveSettingUpdate
   */
  warehouseCode: string;
}
/**
 *
 * @export
 * @interface BillRank
 */
export interface BillRank {
  /**
   * Когда изменён приоритет
   * @type {string}
   * @memberof BillRank
   */
  prioritizedAt?: string | null;
  /**
   *
   * @type {Employee}
   * @memberof BillRank
   */
  prioritizedByEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof BillRank
   */
  rank?: string;
}
/**
 *
 * @export
 * @interface BillShipmentsState
 */
export interface BillShipmentsState {
  /**
   *
   * @type {string}
   * @memberof BillShipmentsState
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof BillShipmentsState
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof BillShipmentsState
   */
  title?: string;
}
/**
 *
 * @export
 * @interface BillSoftReserveSettingsResponse
 */
export interface BillSoftReserveSettingsResponse {
  /**
   * deprecated. use autoReserveState
   * @type {boolean}
   * @memberof BillSoftReserveSettingsResponse
   */
  autoReserveEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof BillSoftReserveSettingsResponse
   */
  autoReserveState?: BillSoftReserveSettingsResponseAutoReserveStateEnum;
  /**
   *
   * @type {Array<BillProductSoftReserveSetting>}
   * @memberof BillSoftReserveSettingsResponse
   */
  productSettings?: Array<BillProductSoftReserveSetting>;
  /**
   *
   * @type {string}
   * @memberof BillSoftReserveSettingsResponse
   */
  updatedAt?: string;
}

export const BillSoftReserveSettingsResponseAutoReserveStateEnum = {
  Disabled: 'disabled',
  Enabled: 'enabled',
  Intermediate: 'intermediate',
} as const;

export type BillSoftReserveSettingsResponseAutoReserveStateEnum =
  (typeof BillSoftReserveSettingsResponseAutoReserveStateEnum)[keyof typeof BillSoftReserveSettingsResponseAutoReserveStateEnum];

/**
 *
 * @export
 * @interface BillSoftReserveSettingsUpdateRequest
 */
export interface BillSoftReserveSettingsUpdateRequest {
  /**
   *
   * @type {boolean}
   * @memberof BillSoftReserveSettingsUpdateRequest
   */
  autoReserveEnabled?: boolean | null;
  /**
   *
   * @type {Array<BillProductSoftReserveSettingUpdate>}
   * @memberof BillSoftReserveSettingsUpdateRequest
   */
  productSettings?: Array<BillProductSoftReserveSettingUpdate>;
}
/**
 *
 * @export
 * @interface BillSource
 */
export interface BillSource {
  /**
   *
   * @type {string}
   * @memberof BillSource
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof BillSource
   */
  title?: string;
}
/**
 *
 * @export
 * @interface BillState
 */
export interface BillState {
  /**
   *
   * @type {string}
   * @memberof BillState
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof BillState
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof BillState
   */
  title?: string;
}
/**
 *
 * @export
 * @interface BillSubordinateDoc
 */
export interface BillSubordinateDoc {
  /**
   *
   * @type {string}
   * @memberof BillSubordinateDoc
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof BillSubordinateDoc
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof BillSubordinateDoc
   */
  documentNumber?: string;
  /**
   *
   * @type {string}
   * @memberof BillSubordinateDoc
   */
  typeCode?: BillSubordinateDocTypeCodeEnum;
  /**
   *
   * @type {string}
   * @memberof BillSubordinateDoc
   */
  typeName?: string;
}

export const BillSubordinateDocTypeCodeEnum = {
  Shipment: 'shipment',
  ShipmentReturn: 'shipmentReturn',
  PaymentTransaction: 'paymentTransaction',
  Postponement: 'postponement',
  SalesInvoice: 'salesInvoice',
} as const;

export type BillSubordinateDocTypeCodeEnum = (typeof BillSubordinateDocTypeCodeEnum)[keyof typeof BillSubordinateDocTypeCodeEnum];

/**
 *
 * @export
 * @interface BillsItemEditPersistRequest
 */
export interface BillsItemEditPersistRequest {
  /**
   *
   * @type {Array<BillsItemEditPosition>}
   * @memberof BillsItemEditPersistRequest
   */
  positions?: Array<BillsItemEditPosition>;
}
/**
 *
 * @export
 * @interface BillsItemEditPosition
 */
export interface BillsItemEditPosition {
  /**
   * Код позиции
   * @type {string}
   * @memberof BillsItemEditPosition
   */
  code?: string;
  /**
   * Код продукта, если хотим заменить на альтернативный
   * @type {string}
   * @memberof BillsItemEditPosition
   */
  productCode?: string | null;
  /**
   * Количество в ПЕИ, если хотим изменить количество
   * @type {number}
   * @memberof BillsItemEditPosition
   */
  quantityUnit?: number | null;
  /**
   *
   * @type {Array<BillsItemEditPositionService>}
   * @memberof BillsItemEditPosition
   */
  services?: Array<BillsItemEditPositionService>;
  /**
   * Код позиции, от которой хотим отсплитоваться (если хотим)
   * @type {string}
   * @memberof BillsItemEditPosition
   */
  splitFromPositionCode?: string | null;
  /**
   * Цена финальная, можно установить только для услуг
   * @type {number}
   * @memberof BillsItemEditPosition
   */
  unitCostFinal?: number | null;
}
/**
 *
 * @export
 * @interface BillsItemEditPositionService
 */
export interface BillsItemEditPositionService {
  /**
   *
   * @type {number}
   * @memberof BillsItemEditPositionService
   */
  manualTotalCost?: number;
  /**
   *
   * @type {string}
   * @memberof BillsItemEditPositionService
   */
  serviceCode?: string;
}
/**
 *
 * @export
 * @interface BillsItemEditPreviewNotice
 */
export interface BillsItemEditPreviewNotice {
  /**
   * Содержимое предупреждения
   * @type {string}
   * @memberof BillsItemEditPreviewNotice
   */
  content?: string;
  /**
   * Блокирующее предупреждение?
   * @type {boolean}
   * @memberof BillsItemEditPreviewNotice
   */
  isCritical?: boolean;
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillsItemEditPreviewNotice
   */
  position?: number;
  /**
   * Код позиции, если можно проассоциировать
   * @type {string}
   * @memberof BillsItemEditPreviewNotice
   */
  positionCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof BillsItemEditPreviewNotice
   */
  typeCode?: string;
}
/**
 *
 * @export
 * @interface BillsItemEditPreviewRequest
 */
export interface BillsItemEditPreviewRequest {
  /**
   *
   * @type {Array<BillsItemEditPosition>}
   * @memberof BillsItemEditPreviewRequest
   */
  positions?: Array<BillsItemEditPosition>;
}
/**
 *
 * @export
 * @interface BillsItemEditPreviewResponse
 */
export interface BillsItemEditPreviewResponse {
  /**
   *
   * @type {Bill}
   * @memberof BillsItemEditPreviewResponse
   */
  bill?: Bill;
  /**
   *
   * @type {Array<BillsItemEditPreviewNotice>}
   * @memberof BillsItemEditPreviewResponse
   */
  notices?: Array<BillsItemEditPreviewNotice>;
  /**
   *
   * @type {Array<BillsItemEditPreviewResponsePosition>}
   * @memberof BillsItemEditPreviewResponse
   */
  positions?: Array<BillsItemEditPreviewResponsePosition>;
}
/**
 *
 * @export
 * @interface BillsItemEditPreviewResponsePosition
 */
export interface BillsItemEditPreviewResponsePosition {
  /**
   * Уникальный код
   * @type {string}
   * @memberof BillsItemEditPreviewResponsePosition
   */
  code?: string;
  /**
   * Номер позиции в счете
   * @type {number}
   * @memberof BillsItemEditPreviewResponsePosition
   */
  position?: number;
  /**
   * Текущее количество в счёте по номеру позиции в заявке
   * @type {number}
   * @memberof BillsItemEditPreviewResponsePosition
   */
  quantityUnitInDeal?: number;
  /**
   * Расчётное количество в счёте по номеру позиции в заявке
   * @type {number}
   * @memberof BillsItemEditPreviewResponsePosition
   */
  quantityUnitInDealRuntime?: number;
  /**
   *
   * @type {Array<BillsItemEditPreviewResponsePositionService>}
   * @memberof BillsItemEditPreviewResponsePosition
   */
  services?: Array<BillsItemEditPreviewResponsePositionService>;
}
/**
 *
 * @export
 * @interface BillsItemEditPreviewResponsePositionService
 */
export interface BillsItemEditPreviewResponsePositionService {
  /**
   * Можно установить ручную стоимость услуги
   * @type {boolean}
   * @memberof BillsItemEditPreviewResponsePositionService
   */
  canSetManualTotalCost?: boolean;
  /**
   * Код услуги
   * @type {string}
   * @memberof BillsItemEditPreviewResponsePositionService
   */
  serviceCode?: string;
  /**
   * Стоимость услуги товара в заявке
   * @type {number}
   * @memberof BillsItemEditPreviewResponsePositionService
   */
  totalCostInDeal?: number;
  /**
   * Стоимость услуги товара в сплите
   * @type {number}
   * @memberof BillsItemEditPreviewResponsePositionService
   */
  totalCostInSplit?: number;
}
/**
 *
 * @export
 * @interface BillsItemInvoice
 */
export interface BillsItemInvoice {
  /**
   * У счёта есть подчинённые документы
   * @type {boolean}
   * @memberof BillsItemInvoice
   */
  billHasSubordinateDoc?: boolean;
  /**
   *
   * @type {BillState}
   * @memberof BillsItemInvoice
   */
  billState?: BillState;
  /**
   * Можно поставить печать и подпись
   * @type {boolean}
   * @memberof BillsItemInvoice
   */
  canStampAndSign?: boolean;
  /**
   * Можно обновить
   * @type {boolean}
   * @memberof BillsItemInvoice
   */
  canUpdate?: boolean;
  /**
   * Представители покупателя
   * @type {Array<Representative>}
   * @memberof BillsItemInvoice
   */
  representativesCustomer?: Array<Representative>;
  /**
   * Представители продавца
   * @type {Array<Representative>}
   * @memberof BillsItemInvoice
   */
  representativesSeller?: Array<Representative>;
}
/**
 *
 * @export
 * @interface BillsItemInvoiceUpdateRequest
 */
export interface BillsItemInvoiceUpdateRequest {
  /**
   * Код подписанта со стороны покупателя
   * @type {string}
   * @memberof BillsItemInvoiceUpdateRequest
   */
  representativeCustomerCode?: string;
  /**
   * Код сотрудника-подписанта
   * @type {string}
   * @memberof BillsItemInvoiceUpdateRequest
   */
  signerEmployeeCode?: string;
  /**
   *
   * @type {Array<PdfText>}
   * @memberof BillsItemInvoiceUpdateRequest
   */
  texts?: Array<PdfText>;
}
/**
 *
 * @export
 * @interface BillsItemPartnersResponse
 */
export interface BillsItemPartnersResponse {
  /**
   *
   * @type {Array<ParticipantPartner>}
   * @memberof BillsItemPartnersResponse
   */
  partners?: Array<ParticipantPartner>;
}
/**
 *
 * @export
 * @interface BillsItemReconcileRequest
 */
export interface BillsItemReconcileRequest {
  /**
   * Фактурировать сразу весь счёт. В таком случае productCode и warehouseCode можно не заполнять
   * @type {boolean}
   * @memberof BillsItemReconcileRequest
   */
  full?: boolean;
  /**
   * Код продукта позиции
   * @type {string}
   * @memberof BillsItemReconcileRequest
   */
  productCode?: string | null;
  /**
   * Код склада позиции
   * @type {string}
   * @memberof BillsItemReconcileRequest
   */
  warehouseCode?: string | null;
}
/**
 *
 * @export
 * @interface BillsItemSpecification
 */
export interface BillsItemSpecification {
  /**
   * У счёта есть подчинённые документы
   * @type {boolean}
   * @memberof BillsItemSpecification
   */
  billHasSubordinateDoc?: boolean;
  /**
   *
   * @type {BillState}
   * @memberof BillsItemSpecification
   */
  billState?: BillState;
  /**
   * Можно поставить печать и подпись
   * @type {boolean}
   * @memberof BillsItemSpecification
   */
  canStampAndSign?: boolean;
  /**
   * Можно обновить
   * @type {boolean}
   * @memberof BillsItemSpecification
   */
  canUpdate?: boolean;
  /**
   * Представители покупателя
   * @type {Array<Representative>}
   * @memberof BillsItemSpecification
   */
  representativesCustomer?: Array<Representative>;
  /**
   * Представители продавца
   * @type {Array<Representative>}
   * @memberof BillsItemSpecification
   */
  representativesSeller?: Array<Representative>;
}
/**
 *
 * @export
 * @interface BillsItemSpecificationUpdateRequest
 */
export interface BillsItemSpecificationUpdateRequest {
  /**
   * Код подписанта со стороны покупателя
   * @type {string}
   * @memberof BillsItemSpecificationUpdateRequest
   */
  representativeCustomerCode?: string;
  /**
   * Код сотрудника-подписанта
   * @type {string}
   * @memberof BillsItemSpecificationUpdateRequest
   */
  signerEmployeeCode?: string;
  /**
   *
   * @type {Array<PdfText>}
   * @memberof BillsItemSpecificationUpdateRequest
   */
  texts?: Array<PdfText>;
}
/**
 *
 * @export
 * @interface BillsItemUpdateCommentsRequest
 */
export interface BillsItemUpdateCommentsRequest {
  /**
   *
   * @type {Array<BillsItemUpdateCommentsRequestComment>}
   * @memberof BillsItemUpdateCommentsRequest
   */
  comments?: Array<BillsItemUpdateCommentsRequestComment>;
}
/**
 *
 * @export
 * @interface BillsItemUpdateCommentsRequestComment
 */
export interface BillsItemUpdateCommentsRequestComment {
  /**
   * Комментарий счёта
   * @type {string}
   * @memberof BillsItemUpdateCommentsRequestComment
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof BillsItemUpdateCommentsRequestComment
   */
  positionCode?: string | null;
  /**
   * Код типа комментария счёта
   * @type {string}
   * @memberof BillsItemUpdateCommentsRequestComment
   */
  typeCode: BillsItemUpdateCommentsRequestCommentTypeCodeEnum;
}

export const BillsItemUpdateCommentsRequestCommentTypeCodeEnum = {
  Store: 'store',
} as const;

export type BillsItemUpdateCommentsRequestCommentTypeCodeEnum =
  (typeof BillsItemUpdateCommentsRequestCommentTypeCodeEnum)[keyof typeof BillsItemUpdateCommentsRequestCommentTypeCodeEnum];

/**
 *
 * @export
 * @interface BillsItemUpdatePartnerAddressRequest
 */
export interface BillsItemUpdatePartnerAddressRequest {
  /**
   *
   * @type {string}
   * @memberof BillsItemUpdatePartnerAddressRequest
   */
  addressCode?: string;
  /**
   * Код партнера
   * @type {string}
   * @memberof BillsItemUpdatePartnerAddressRequest
   */
  participantCode?: string;
  /**
   *
   * @type {string}
   * @memberof BillsItemUpdatePartnerAddressRequest
   */
  type?: BillsItemUpdatePartnerAddressRequestTypeEnum;
}

export const BillsItemUpdatePartnerAddressRequestTypeEnum = {
  Consignee: 'consignee',
} as const;

export type BillsItemUpdatePartnerAddressRequestTypeEnum =
  (typeof BillsItemUpdatePartnerAddressRequestTypeEnum)[keyof typeof BillsItemUpdatePartnerAddressRequestTypeEnum];

/**
 *
 * @export
 * @interface BillsItemUpdatePartnerRequest
 */
export interface BillsItemUpdatePartnerRequest {
  /**
   * Код партнера
   * @type {string}
   * @memberof BillsItemUpdatePartnerRequest
   */
  participantCode?: string;
  /**
   *
   * @type {string}
   * @memberof BillsItemUpdatePartnerRequest
   */
  type?: BillsItemUpdatePartnerRequestTypeEnum;
}

export const BillsItemUpdatePartnerRequestTypeEnum = {
  Consignee: 'consignee',
  Payer: 'payer',
} as const;

export type BillsItemUpdatePartnerRequestTypeEnum =
  (typeof BillsItemUpdatePartnerRequestTypeEnum)[keyof typeof BillsItemUpdatePartnerRequestTypeEnum];

/**
 *
 * @export
 * @interface BillsItemUpdatePayerRequest
 */
export interface BillsItemUpdatePayerRequest {
  /**
   * Код плательщика
   * @type {string}
   * @memberof BillsItemUpdatePayerRequest
   */
  code?: string;
}
/**
 *
 * @export
 * @interface BillsRequest
 */
export interface BillsRequest {
  /**
   * Коды филиалов
   * @type {Array<string>}
   * @memberof BillsRequest
   */
  branchOfficeCodes?: Array<string> | null;
  /**
   * Код счета
   * @type {string}
   * @memberof BillsRequest
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof BillsRequest
   */
  count?: number | null;
  /**
   * deprecated. use customerCodes
   * @type {string}
   * @memberof BillsRequest
   */
  customerCode?: string;
  /**
   * Коды клиентов
   * @type {Array<string>}
   * @memberof BillsRequest
   */
  customerCodes?: Array<string>;
  /**
   * Дата от
   * @type {string}
   * @memberof BillsRequest
   */
  dateFrom?: string | null;
  /**
   * Дата до
   * @type {string}
   * @memberof BillsRequest
   */
  dateTo?: string | null;
  /**
   * Код заявки
   * @type {string}
   * @memberof BillsRequest
   */
  dealCode?: string;
  /**
   *
   * @type {string}
   * @memberof BillsRequest
   */
  documentNumber?: string;
  /**
   * Код сотрудника или группы
   * @type {string}
   * @memberof BillsRequest
   */
  employeeSet?: string | null;
  /**
   * Загрузить лог действий по счёту
   * @type {boolean}
   * @memberof BillsRequest
   */
  enrichActionLogs?: boolean;
  /**
   * Загрузить данные о клиенте
   * @type {boolean}
   * @memberof BillsRequest
   */
  enrichCustomers?: boolean;
  /**
   * Загрузить список подчинённых документов
   * @type {boolean}
   * @memberof BillsRequest
   */
  enrichSubordinateDocs?: boolean;
  /**
   * В наличии документ Отгрузка
   * @type {boolean}
   * @memberof BillsRequest
   */
  hasShipments?: boolean;
  /**
   * Год и месяц
   * @type {string}
   * @memberof BillsRequest
   */
  month?: string | null;
  /**
   *
   * @type {number}
   * @memberof BillsRequest
   */
  page?: number | null;
  /**
   * Коды статусов оплаты
   * @type {Array<string>}
   * @memberof BillsRequest
   */
  paymentStateCodes?: Array<string> | null;
  /**
   * filter for bills
   * @type {string}
   * @memberof BillsRequest
   */
  query?: string | null;
  /**
   * Коды статуса отгрузки
   * @type {Array<string>}
   * @memberof BillsRequest
   */
  shipmentStateCodes?: Array<string> | null;
  /**
   * Коды источника счета
   * @type {Array<string>}
   * @memberof BillsRequest
   */
  sourceCodes?: Array<string> | null;
  /**
   * Коды статусов
   * @type {Array<string>}
   * @memberof BillsRequest
   */
  stateCodes?: Array<string> | null;
  /**
   * Включая архивные позиции
   * @type {boolean}
   * @memberof BillsRequest
   */
  withArchive?: boolean;
}
/**
 *
 * @export
 * @interface BillsResponse
 */
export interface BillsResponse {
  /**
   *
   * @type {Array<Bill>}
   * @memberof BillsResponse
   */
  bills?: Array<Bill>;
  /**
   *
   * @type {Array<BillBranchOffice>}
   * @memberof BillsResponse
   */
  billsBranchOffices?: Array<BillBranchOffice>;
  /**
   * Общая сумма, руб.
   * @type {number}
   * @memberof BillsResponse
   */
  billsTotalCost?: number;
  /**
   *
   * @type {number}
   * @memberof BillsResponse
   */
  billsTotalCount?: number;
  /**
   * Общий вес, кг.
   * @type {number}
   * @memberof BillsResponse
   */
  billsTotalWeight?: number;
}
/**
 *
 * @export
 * @interface BillsShipmentStatesResponse
 */
export interface BillsShipmentStatesResponse {
  /**
   *
   * @type {Array<BillShipmentsState>}
   * @memberof BillsShipmentStatesResponse
   */
  billShipmentsStates?: Array<BillShipmentsState>;
}
/**
 *
 * @export
 * @interface BillsSoftReserveCalculateV2Request
 */
export interface BillsSoftReserveCalculateV2Request {
  /**
   * Полная попытка резервирования по всему счёту
   * @type {boolean}
   * @memberof BillsSoftReserveCalculateV2Request
   */
  full?: boolean;
  /**
   *
   * @type {string}
   * @memberof BillsSoftReserveCalculateV2Request
   */
  productCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof BillsSoftReserveCalculateV2Request
   */
  warehouseCode?: string | null;
}
/**
 *
 * @export
 * @interface BillsSoftReserveWithdrawV2Request
 */
export interface BillsSoftReserveWithdrawV2Request {
  /**
   * Полное снятие резерва по всему счёту
   * @type {boolean}
   * @memberof BillsSoftReserveWithdrawV2Request
   */
  full?: boolean;
  /**
   *
   * @type {string}
   * @memberof BillsSoftReserveWithdrawV2Request
   */
  productCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof BillsSoftReserveWithdrawV2Request
   */
  warehouseCode?: string | null;
}
/**
 *
 * @export
 * @interface BillsSourcesResponse
 */
export interface BillsSourcesResponse {
  /**
   *
   * @type {Array<BillSource>}
   * @memberof BillsSourcesResponse
   */
  sources?: Array<BillSource>;
}
/**
 *
 * @export
 * @interface BillsStatesResponse
 */
export interface BillsStatesResponse {
  /**
   *
   * @type {Array<BillState>}
   * @memberof BillsStatesResponse
   */
  states?: Array<BillState>;
}
/**
 *
 * @export
 * @interface BillsThreadRequest
 */
export interface BillsThreadRequest {
  /**
   *
   * @type {string}
   * @memberof BillsThreadRequest
   */
  billCode?: string;
  /**
   *
   * @type {string}
   * @memberof BillsThreadRequest
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface BillsThreadResponse
 */
export interface BillsThreadResponse {
  /**
   *
   * @type {DocumentsThread}
   * @memberof BillsThreadResponse
   */
  documentsThread?: DocumentsThread;
}
/**
 *
 * @export
 * @interface BillsUpdateFromDealRequest
 */
export interface BillsUpdateFromDealRequest {
  /**
   * Код заявки
   * @type {string}
   * @memberof BillsUpdateFromDealRequest
   */
  dealCode?: string;
}
/**
 *
 * @export
 * @interface BillsUpdateFromDealResponse
 */
export interface BillsUpdateFromDealResponse {
  /**
   *
   * @type {Bill}
   * @memberof BillsUpdateFromDealResponse
   */
  bill?: Bill;
}
/**
 *
 * @export
 * @interface BillsVisualizeEmulatedWithdrawRequest
 */
export interface BillsVisualizeEmulatedWithdrawRequest {
  /**
   *
   * @type {string}
   * @memberof BillsVisualizeEmulatedWithdrawRequest
   */
  productCode: string;
  /**
   *
   * @type {string}
   * @memberof BillsVisualizeEmulatedWithdrawRequest
   */
  warehouseCode: string;
}
/**
 *
 * @export
 * @interface BillsVisualizeEmulatedWithdrawResponse
 */
export interface BillsVisualizeEmulatedWithdrawResponse {
  /**
   *
   * @type {Array<BillsVisualizeEmulatedWithdrawResponseDiffItem>}
   * @memberof BillsVisualizeEmulatedWithdrawResponse
   */
  diff?: Array<BillsVisualizeEmulatedWithdrawResponseDiffItem>;
}
/**
 *
 * @export
 * @interface BillsVisualizeEmulatedWithdrawResponseDiffItem
 */
export interface BillsVisualizeEmulatedWithdrawResponseDiffItem {
  /**
   *
   * @type {ProductItemReserve}
   * @memberof BillsVisualizeEmulatedWithdrawResponseDiffItem
   */
  after?: ProductItemReserve;
  /**
   *
   * @type {ProductItemReserve}
   * @memberof BillsVisualizeEmulatedWithdrawResponseDiffItem
   */
  before?: ProductItemReserve;
}
/**
 *
 * @export
 * @interface BranchOffice
 */
export interface BranchOffice {
  /**
   *
   * @type {string}
   * @memberof BranchOffice
   */
  cityName?: string;
  /**
   *
   * @type {string}
   * @memberof BranchOffice
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof BranchOffice
   */
  divisionCode?: string;
  /**
   *
   * @type {string}
   * @memberof BranchOffice
   */
  divisionTitle?: string;
  /**
   *
   * @type {string}
   * @memberof BranchOffice
   */
  name?: string;
  /**
   *
   * @type {Array<Warehouse>}
   * @memberof BranchOffice
   */
  warehouses?: Array<Warehouse>;
}
/**
 *
 * @export
 * @interface BranchOfficeAgreementsReport
 */
export interface BranchOfficeAgreementsReport {
  /**
   *
   * @type {string}
   * @memberof BranchOfficeAgreementsReport
   */
  officeCode?: string;
  /**
   *
   * @type {AgreementsReport}
   * @memberof BranchOfficeAgreementsReport
   */
  report?: AgreementsReport;
}
/**
 *
 * @export
 * @interface BranchOfficeDivision
 */
export interface BranchOfficeDivision {
  /**
   *
   * @type {string}
   * @memberof BranchOfficeDivision
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof BranchOfficeDivision
   */
  title?: string;
}
/**
 *
 * @export
 * @interface BranchOfficeMarginalityReport
 */
export interface BranchOfficeMarginalityReport {
  /**
   *
   * @type {string}
   * @memberof BranchOfficeMarginalityReport
   */
  officeCode?: string;
  /**
   *
   * @type {MarginalityReport}
   * @memberof BranchOfficeMarginalityReport
   */
  report?: MarginalityReport;
}
/**
 * Месячная статистика по премиям филиала
 * @export
 * @interface BranchOfficeMotivationReport
 */
export interface BranchOfficeMotivationReport {
  /**
   *
   * @type {string}
   * @memberof BranchOfficeMotivationReport
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {MotivationReport}
   * @memberof BranchOfficeMotivationReport
   */
  report?: MotivationReport;
}
/**
 *
 * @export
 * @interface BranchOfficeSegmentHistory
 */
export interface BranchOfficeSegmentHistory {
  /**
   * Код филиала
   * @type {string}
   * @memberof BranchOfficeSegmentHistory
   */
  branchOfficeCode?: string;
  /**
   * Дата создания
   * @type {string}
   * @memberof BranchOfficeSegmentHistory
   */
  created_at?: string;
  /**
   * Новый код сегмента отгрузки
   * @type {string}
   * @memberof BranchOfficeSegmentHistory
   */
  newSegmentCode?: string;
  /**
   * Старый код сегмента отгрузки
   * @type {string}
   * @memberof BranchOfficeSegmentHistory
   */
  oldSegmentCode?: string | null;
  /**
   * Суммарный вес отгрузки
   * @type {number}
   * @memberof BranchOfficeSegmentHistory
   */
  weight?: number;
}
/**
 *
 * @export
 * @interface BudgetGroup
 */
export interface BudgetGroup {
  /**
   *
   * @type {string}
   * @memberof BudgetGroup
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof BudgetGroup
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BudgetGroup
   */
  shortName?: string;
}
/**
 *
 * @export
 * @interface CatalogBatchCertificatesResponse
 */
export interface CatalogBatchCertificatesResponse {
  /**
   *
   * @type {Array<BatchCertificate>}
   * @memberof CatalogBatchCertificatesResponse
   */
  certificates?: Array<BatchCertificate>;
  /**
   *
   * @type {string}
   * @memberof CatalogBatchCertificatesResponse
   */
  zipHref?: string | null;
}
/**
 *
 * @export
 * @interface CatalogCategoriesItemViewResponse
 */
export interface CatalogCategoriesItemViewResponse {
  /**
   *
   * @type {Array<AvailableCatalogBaseSize>}
   * @memberof CatalogCategoriesItemViewResponse
   */
  baseSizes?: Array<AvailableCatalogBaseSize>;
  /**
   * Дочерние разделы, текущий раздел, все разделы до корня
   * @type {Array<Category>}
   * @memberof CatalogCategoriesItemViewResponse
   */
  categories?: Array<Category>;
  /**
   *
   * @type {Array<AvailableCatalogManufacturer>}
   * @memberof CatalogCategoriesItemViewResponse
   */
  manufacturers?: Array<AvailableCatalogManufacturer>;
  /**
   * Вид товара
   * @type {Array<AvailableCatalogProductType>}
   * @memberof CatalogCategoriesItemViewResponse
   */
  productTypes?: Array<AvailableCatalogProductType>;
  /**
   * Товары
   * @type {Array<Product>}
   * @memberof CatalogCategoriesItemViewResponse
   */
  products?: Array<Product>;
  /**
   *
   * @type {number}
   * @memberof CatalogCategoriesItemViewResponse
   */
  productsTotalCount?: number;
  /**
   *
   * @type {Array<ProductProperty>}
   * @memberof CatalogCategoriesItemViewResponse
   */
  properties?: Array<ProductProperty>;
  /**
   * Метка поиска (использованный индекс, идентификатор) для загрузки вторых страниц результата и фильтров
   * @type {string}
   * @memberof CatalogCategoriesItemViewResponse
   */
  searchMarker?: string;
}
/**
 *
 * @export
 * @interface CatalogCategoriesRequest
 */
export interface CatalogCategoriesRequest {
  /**
   * Код филиала для подключения цен
   * @type {string}
   * @memberof CatalogCategoriesRequest
   */
  branchOffice?: string;
  /**
   * Код раздела
   * @type {string}
   * @memberof CatalogCategoriesRequest
   */
  category?: string;
  /**
   * Только с товары «В резерве»
   * @type {string}
   * @memberof CatalogCategoriesRequest
   */
  inReserveRequired?: string;
  /**
   * Только с товары «В пути»
   * @type {string}
   * @memberof CatalogCategoriesRequest
   */
  inTransitRequired?: string;
  /**
   * Строка для поиска товара
   * @type {string}
   * @memberof CatalogCategoriesRequest
   */
  query?: string;
  /**
   *
   * @type {string}
   * @memberof CatalogCategoriesRequest
   */
  searchMarker?: string;
  /**
   * Только с остатками
   * @type {string}
   * @memberof CatalogCategoriesRequest
   */
  stockRequired?: string;
  /**
   * Склад отгрузки
   * @type {string}
   * @memberof CatalogCategoriesRequest
   */
  warehouse?: string;
}
/**
 *
 * @export
 * @interface CatalogCategoriesResponse
 */
export interface CatalogCategoriesResponse {
  /**
   * Дочерние разделы, текущий раздел, все разделы до корня
   * @type {Array<CategoryListItem>}
   * @memberof CatalogCategoriesResponse
   */
  categories?: Array<CategoryListItem>;
}
/**
 *
 * @export
 * @interface CatalogFiltersRequest
 */
export interface CatalogFiltersRequest {
  /**
   * Фильтр по базовому размеру
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  baseSize?: string;
  /**
   * Код филиала для подключения цен
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  branchOffice?: string;
  /**
   * Категория
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  category?: string;
  /**
   * Фильтрация для продуктов. См. getCatalogProducts
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  filters?: string;
  /**
   * Только с товары «В резерве»
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  inReserveRequired?: string;
  /**
   * Только с товары «В пути»
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  inTransitRequired?: string;
  /**
   * Фильтр по производителю
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  manufacturer?: string;
  /**
   * Фильтр по виду товара
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  productType?: string;
  /**
   * Поисковая строка, которая использовалась при поиске товаров
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  query?: string;
  /**
   *
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  searchMarker?: string;
  /**
   * Только с остатками
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  stockRequired?: string;
  /**
   * Склад отгрузки
   * @type {string}
   * @memberof CatalogFiltersRequest
   */
  warehouse?: string;
}
/**
 *
 * @export
 * @interface CatalogFiltersResponse
 */
export interface CatalogFiltersResponse {
  /**
   *
   * @type {Array<AvailableCatalogBaseSize>}
   * @memberof CatalogFiltersResponse
   */
  baseSizes?: Array<AvailableCatalogBaseSize>;
  /**
   *
   * @type {Array<AvailableCatalogManufacturer>}
   * @memberof CatalogFiltersResponse
   */
  manufacturers?: Array<AvailableCatalogManufacturer>;
  /**
   * Вид товара
   * @type {Array<AvailableCatalogProductType>}
   * @memberof CatalogFiltersResponse
   */
  productTypes?: Array<AvailableCatalogProductType>;
  /**
   *
   * @type {Array<ProductProperty>}
   * @memberof CatalogFiltersResponse
   */
  properties?: Array<ProductProperty>;
}
/**
 *
 * @export
 * @interface CatalogProductAccordionNomenclatureRequest
 */
export interface CatalogProductAccordionNomenclatureRequest {
  /**
   * Код товара
   * @type {string}
   * @memberof CatalogProductAccordionNomenclatureRequest
   */
  productCode?: string;
  /**
   * Код склада
   * @type {string}
   * @memberof CatalogProductAccordionNomenclatureRequest
   */
  warehouseCode?: string;
}
/**
 *
 * @export
 * @interface CatalogProductAccordionNomenclatureResponse
 */
export interface CatalogProductAccordionNomenclatureResponse {
  /**
   *
   * @type {string}
   * @memberof CatalogProductAccordionNomenclatureResponse
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof CatalogProductAccordionNomenclatureResponse
   */
  distributedQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof CatalogProductAccordionNomenclatureResponse
   */
  inReserve?: number;
  /**
   * Общий остаток по номенклатуре на складе
   * @type {number}
   * @memberof CatalogProductAccordionNomenclatureResponse
   */
  inStock?: number;
  /**
   *
   * @type {number}
   * @memberof CatalogProductAccordionNomenclatureResponse
   */
  inStockForSale?: number;
  /**
   *
   * @type {number}
   * @memberof CatalogProductAccordionNomenclatureResponse
   */
  inTransitQuantity?: number;
  /**
   *
   * @type {string}
   * @memberof CatalogProductAccordionNomenclatureResponse
   */
  mdmKey?: string;
  /**
   *
   * @type {string}
   * @memberof CatalogProductAccordionNomenclatureResponse
   */
  name?: string;
}
/**
 *
 * @export
 * @interface CatalogProductReservesRequest
 */
export interface CatalogProductReservesRequest {
  /**
   * Код филиала
   * @type {string}
   * @memberof CatalogProductReservesRequest
   */
  branchOfficeCode?: string;
  /**
   * Код товара
   * @type {string}
   * @memberof CatalogProductReservesRequest
   */
  productCode?: string;
}
/**
 *
 * @export
 * @interface CatalogProductReservesResponse
 */
export interface CatalogProductReservesResponse {
  /**
   *
   * @type {Array<ProductItemReserve>}
   * @memberof CatalogProductReservesResponse
   */
  reserves?: Array<ProductItemReserve>;
}
/**
 *
 * @export
 * @interface CatalogProductReservesStatResponse
 */
export interface CatalogProductReservesStatResponse {
  /**
   * Стоимость в МРЦ
   * @type {number}
   * @memberof CatalogProductReservesStatResponse
   */
  mrcTotalCost?: number;
  /**
   * Стоимость в цене с услугами
   * @type {number}
   * @memberof CatalogProductReservesStatResponse
   */
  productTotalCost?: number;
  /**
   * Стоимость в цене закупки
   * @type {number}
   * @memberof CatalogProductReservesStatResponse
   */
  purchaseTotalCost?: number;
  /**
   * Количество дней резерва ОТ
   * @type {number}
   * @memberof CatalogProductReservesStatResponse
   */
  reserveDaysFrom?: number;
  /**
   * Количество дней резерва ДО
   * @type {number}
   * @memberof CatalogProductReservesStatResponse
   */
  reserveDaysTo?: number;
  /**
   *
   * @type {number}
   * @memberof CatalogProductReservesStatResponse
   */
  totalCount?: number;
  /**
   *
   * @type {number}
   * @memberof CatalogProductReservesStatResponse
   */
  totalQuantity?: number;
  /**
   * Единица измерения
   * @type {string}
   * @memberof CatalogProductReservesStatResponse
   */
  unit?: string;
  /**
   * Код единицы измерения
   * @type {string}
   * @memberof CatalogProductReservesStatResponse
   */
  unitCode?: string;
}
/**
 *
 * @export
 * @interface CatalogProductReservesV2Response
 */
export interface CatalogProductReservesV2Response {
  /**
   *
   * @type {Array<ProductItemReserve>}
   * @memberof CatalogProductReservesV2Response
   */
  reserves?: Array<ProductItemReserve>;
  /**
   *
   * @type {number}
   * @memberof CatalogProductReservesV2Response
   */
  reservesTotalCount?: number;
}
/**
 *
 * @export
 * @interface CatalogProductStocksRequest
 */
export interface CatalogProductStocksRequest {
  /**
   * Код филиала
   * @type {string}
   * @memberof CatalogProductStocksRequest
   */
  branchOfficeCode?: string;
  /**
   * Код товара
   * @type {string}
   * @memberof CatalogProductStocksRequest
   */
  productCode?: string;
}
/**
 *
 * @export
 * @interface CatalogProductStocksResponse
 */
export interface CatalogProductStocksResponse {
  /**
   *
   * @type {Array<ProductItemStock>}
   * @memberof CatalogProductStocksResponse
   */
  stocks?: Array<ProductItemStock>;
}
/**
 *
 * @export
 * @interface CatalogProductsAlternativesNomenclature
 */
export interface CatalogProductsAlternativesNomenclature {
  /**
   *
   * @type {string}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  distributedQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  inReserve?: number;
  /**
   * Остаток, включая резервы
   * @type {number}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  inStock?: number;
  /**
   * Остаток, доступный для продажи
   * @type {number}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  inStockForSale?: number;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  inTransitQuantity?: number;
  /**
   * Средневзвешенная цена по номенклатуре
   * @type {number}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  minRetailUnitCost?: number;
  /**
   *
   * @type {string}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  name?: string;
  /**
   * Срок самой новой партии
   * @type {number}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  newShelfLife?: number;
  /**
   * Срок самой старой партии
   * @type {number}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  oldShelfLife?: number;
  /**
   *
   * @type {Array<CatalogProductsAlternativesPositionProduct>}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  products?: Array<CatalogProductsAlternativesPositionProduct>;
  /**
   * Средневзвешенная цена по номенклатуре
   * @type {number}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  purchasePrice?: number;
  /**
   * Код единицы измерения остатка
   * @type {string}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  stockUnitCode?: string;
  /**
   * Единица измерения остатка
   * @type {string}
   * @memberof CatalogProductsAlternativesNomenclature
   */
  stockUnitShortName?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsAlternativesPosition
 */
export interface CatalogProductsAlternativesPosition {
  /**
   *
   * @type {CatalogProductsAlternativesNomenclature}
   * @memberof CatalogProductsAlternativesPosition
   */
  nomenclature?: CatalogProductsAlternativesNomenclature;
  /**
   * Код исходной позиции сделки, к которой подобраны альтернативы
   * @type {string}
   * @memberof CatalogProductsAlternativesPosition
   */
  positionCode?: string;
  /**
   *
   * @type {CatalogProductsAlternativesSegmentationGroup}
   * @memberof CatalogProductsAlternativesPosition
   */
  segmentationGroup?: CatalogProductsAlternativesSegmentationGroup;
}
/**
 *
 * @export
 * @interface CatalogProductsAlternativesPositionProduct
 */
export interface CatalogProductsAlternativesPositionProduct {
  /**
   * Партии
   * @type {Array<BatchForAlternative>}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  batches?: Array<BatchForAlternative>;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  distributedQuantity?: number;
  /**
   *
   * @type {boolean}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  hasCustody?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  hasIlliquid?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  hasSelfPurchase?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  hasTodhDiscount?: boolean;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  inReserve?: number;
  /**
   * Остаток, включая резервы
   * @type {number}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  inStock?: number;
  /**
   * Остаток, доступный для продажи
   * @type {number}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  inStockForSale?: number;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  inTransitQuantity?: number;
  /**
   * Флаг означает, что альтернатива содержит самую свежую партию поставки из всех альтернативных партий
   * @type {boolean}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  isNew?: boolean;
  /**
   * Флаг означает, что альтернатива содержит самую старую партию поставки из всех альтернативных партий
   * @type {boolean}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  isOld?: boolean;
  /**
   * Код производителя
   * @type {string}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  manufacturerCode?: string;
  /**
   * Название производителя
   * @type {string}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  manufacturerTitle?: string;
  /**
   * Минимальная розничная цена с учетом группы сегментации
   * @type {number}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  minRetailUnitCost?: number;
  /**
   * Самая новая дата поставки партии товара-альтернативы
   * @type {string}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  newReceiptDate?: string;
  /**
   * Срок самой новой партии
   * @type {number}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  newShelfLife?: number;
  /**
   * Самая старая партия поставки партии товара-альтернативы
   * @type {string}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  oldReceiptDate?: string;
  /**
   * Срок самой старой партии
   * @type {number}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  oldShelfLife?: number;
  /**
   * Артикул товара
   * @type {string}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  productCode?: string;
  /**
   * Наименование товара
   * @type {string}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  productTitle?: string;
  /**
   * Средневзвешенная цена закупки по партиям
   * @type {number}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  purchasePrice?: number;
  /**
   * Код единицы измерения остатка
   * @type {string}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  stockUnitCode?: string;
  /**
   * Единица измерения остатка
   * @type {string}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  stockUnitShortName?: string;
  /**
   * Единицы измерения
   * @type {Array<ProductUnit>}
   * @memberof CatalogProductsAlternativesPositionProduct
   */
  units?: Array<ProductUnit>;
}
/**
 *
 * @export
 * @interface CatalogProductsAlternativesRequest
 */
export interface CatalogProductsAlternativesRequest {
  /**
   *
   * @type {string}
   * @memberof CatalogProductsAlternativesRequest
   */
  agreementCode?: string;
  /**
   *
   * @type {string}
   * @memberof CatalogProductsAlternativesRequest
   */
  dealCode?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogProductsAlternativesRequest
   */
  positionCodes?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof CatalogProductsAlternativesRequest
   */
  stockRequired?: boolean;
  /**
   *
   * @type {string}
   * @memberof CatalogProductsAlternativesRequest
   */
  warehouseCode?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsAlternativesResponse
 */
export interface CatalogProductsAlternativesResponse {
  /**
   * Есть доступ на просмотр цены закупки
   * @type {boolean}
   * @memberof CatalogProductsAlternativesResponse
   */
  canViewPurchasePrice?: boolean | null;
  /**
   *
   * @type {Array<CatalogProductsAlternativesPosition>}
   * @memberof CatalogProductsAlternativesResponse
   */
  positions?: Array<CatalogProductsAlternativesPosition>;
}
/**
 *
 * @export
 * @interface CatalogProductsAlternativesSegmentationGroup
 */
export interface CatalogProductsAlternativesSegmentationGroup {
  /**
   *
   * @type {number}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  distributedQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  inReserve?: number;
  /**
   * Остаток, включая резервы
   * @type {number}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  inStock?: number;
  /**
   * Остаток, доступный для продажи
   * @type {number}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  inStockForSale?: number;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  inTransitQuantity?: number;
  /**
   *
   * @type {string}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  mdmKey?: string;
  /**
   * Средневзвешенная цена по номенклатуре
   * @type {number}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  minRetailUnitCost?: number;
  /**
   * Срок самой новой партии
   * @type {number}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  newShelfLife?: number;
  /**
   * Срок самой старой партии
   * @type {number}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  oldShelfLife?: number;
  /**
   *
   * @type {Array<CatalogProductsAlternativesPositionProduct>}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  products?: Array<CatalogProductsAlternativesPositionProduct>;
  /**
   * Средневзвешенная цена по номенклатуре
   * @type {number}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  purchasePrice?: number;
  /**
   * Код единицы измерения остатка
   * @type {string}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  stockUnitCode?: string;
  /**
   * Единица измерения остатка
   * @type {string}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  stockUnitShortName?: string;
  /**
   *
   * @type {string}
   * @memberof CatalogProductsAlternativesSegmentationGroup
   */
  title?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsExtensionsRequest
 */
export interface CatalogProductsExtensionsRequest {
  /**
   *
   * @type {string}
   * @memberof CatalogProductsExtensionsRequest
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogProductsExtensionsRequest
   */
  productsCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CatalogProductsExtensionsRequest
   */
  warehouseCode?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsExtensionsResponse
 */
export interface CatalogProductsExtensionsResponse {
  /**
   *
   * @type {Array<ProductExtension>}
   * @memberof CatalogProductsExtensionsResponse
   */
  productsExtensions?: Array<ProductExtension>;
}
/**
 *
 * @export
 * @interface CatalogProductsFastSearchRequest
 */
export interface CatalogProductsFastSearchRequest {
  /**
   * Код филиала для подключения цен
   * @type {string}
   * @memberof CatalogProductsFastSearchRequest
   */
  branchOffice?: string;
  /**
   * Код раздела
   * @type {string}
   * @memberof CatalogProductsFastSearchRequest
   */
  category?: string;
  /**
   * Исключить услуги
   * @type {boolean}
   * @memberof CatalogProductsFastSearchRequest
   */
  excludeServices?: boolean;
  /**
   * Максимальное количество товаров
   * @type {number}
   * @memberof CatalogProductsFastSearchRequest
   */
  limit?: number;
  /**
   * Строка для поиска товара
   * @type {string}
   * @memberof CatalogProductsFastSearchRequest
   */
  query?: string;
  /**
   * Только с остатками
   * @type {string}
   * @memberof CatalogProductsFastSearchRequest
   */
  stockRequired?: string;
  /**
   * Склад отгрузки
   * @type {string}
   * @memberof CatalogProductsFastSearchRequest
   */
  warehouse?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsFastSearchResponse
 */
export interface CatalogProductsFastSearchResponse {
  /**
   *
   * @type {Array<ProductForFastSearch>}
   * @memberof CatalogProductsFastSearchResponse
   */
  products?: Array<ProductForFastSearch>;
  /**
   * Итоговое количество найденных товаров, сверх лимита limit
   * @type {number}
   * @memberof CatalogProductsFastSearchResponse
   */
  total?: number;
}
/**
 *
 * @export
 * @interface CatalogProductsItemBranchOfficeItemMrcResponse
 */
export interface CatalogProductsItemBranchOfficeItemMrcResponse {
  /**
   * МРЦ товара
   * @type {number}
   * @memberof CatalogProductsItemBranchOfficeItemMrcResponse
   */
  cost?: number;
  /**
   * Дата МРЦ
   * @type {string}
   * @memberof CatalogProductsItemBranchOfficeItemMrcResponse
   */
  costDate?: string;
  /**
   * Значение МРЦ по KVI
   * @type {number}
   * @memberof CatalogProductsItemBranchOfficeItemMrcResponse
   */
  kviCost?: number;
  /**
   * Название KVI
   * @type {string}
   * @memberof CatalogProductsItemBranchOfficeItemMrcResponse
   */
  kviName?: string;
  /**
   * Список наценок на свойства по KVI
   * @type {Array<ProductItemBranchOfficeItemMrcKviProperty>}
   * @memberof CatalogProductsItemBranchOfficeItemMrcResponse
   */
  kviProperties?: Array<ProductItemBranchOfficeItemMrcKviProperty>;
}
/**
 *
 * @export
 * @interface CatalogProductsItemImagesItemUploadResponse
 */
export interface CatalogProductsItemImagesItemUploadResponse {
  /**
   *
   * @type {Array<ProductImage>}
   * @memberof CatalogProductsItemImagesItemUploadResponse
   */
  images?: Array<ProductImage>;
  /**
   *
   * @type {string}
   * @memberof CatalogProductsItemImagesItemUploadResponse
   */
  newImageKey?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsItemManufacturersResponse
 */
export interface CatalogProductsItemManufacturersResponse {
  /**
   *
   * @type {Array<AvailableProductManufacturer>}
   * @memberof CatalogProductsItemManufacturersResponse
   */
  manufacturers?: Array<AvailableProductManufacturer>;
}
/**
 *
 * @export
 * @interface CatalogProductsItemPacksResponse
 */
export interface CatalogProductsItemPacksResponse {
  /**
   * Видимость стоимости партий
   * @type {boolean}
   * @memberof CatalogProductsItemPacksResponse
   */
  hasBatchCostAccess?: boolean;
  /**
   *
   * @type {Array<ProductItemPack>}
   * @memberof CatalogProductsItemPacksResponse
   */
  packs?: Array<ProductItemPack>;
}
/**
 *
 * @export
 * @interface CatalogProductsItemResponse
 */
export interface CatalogProductsItemResponse {
  /**
   * Разделы товаров и все родительские разделы до корня
   * @type {Array<Category>}
   * @memberof CatalogProductsItemResponse
   */
  categories?: Array<Category>;
  /**
   *
   * @type {Product}
   * @memberof CatalogProductsItemResponse
   */
  product?: Product;
}
/**
 *
 * @export
 * @interface CatalogProductsItemServicesResponse
 */
export interface CatalogProductsItemServicesResponse {
  /**
   *
   * @type {Array<AvailableProductService>}
   * @memberof CatalogProductsItemServicesResponse
   */
  services?: Array<AvailableProductService>;
}
/**
 *
 * @export
 * @interface CatalogProductsItemShipmentsResponse
 */
export interface CatalogProductsItemShipmentsResponse {
  /**
   *
   * @type {Array<SaleShipment>}
   * @memberof CatalogProductsItemShipmentsResponse
   */
  shipments?: Array<SaleShipment>;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsItemShipmentsResponse
   */
  shipmentsTotalCount?: number;
}
/**
 *
 * @export
 * @interface CatalogProductsListRequest
 */
export interface CatalogProductsListRequest {
  /**
   * Код соглашения
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  agreementCode?: string;
  /**
   * Фильтр по базовому размеру
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  baseSize?: string;
  /**
   * Код филиала для подключения цен
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  branchOffice?: string;
  /**
   * Код раздела
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  category?: string;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsListRequest
   */
  count?: number;
  /**
   * Код сделки
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  dealCode?: string;
  /**
   * Фильтрация для продуктов. См. getCatalogProducts
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  filters?: string;
  /**
   * Только с товары «В пути»
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  inTransitRequired?: string;
  /**
   * Фильтр по производителю
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  manufacturer?: string;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsListRequest
   */
  page?: number;
  /**
   * Фильтр по виду товара
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  productType?: string;
  /**
   * Строка для поиска товара
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  query?: string;
  /**
   *
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  searchMarker?: string;
  /**
   * Правило сортировка для товаров. См. ProductSorting
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  sort?: string;
  /**
   * Только с остатками
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  stockRequired?: string;
  /**
   * Склад отгрузки
   * @type {string}
   * @memberof CatalogProductsListRequest
   */
  warehouse?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsListResponse
 */
export interface CatalogProductsListResponse {
  /**
   *
   * @type {Array<Product>}
   * @memberof CatalogProductsListResponse
   */
  products?: Array<Product>;
  /**
   * Метка поиска (использованный индекс, идентификатор) для загрузки вторых страниц результата и фильтров
   * @type {string}
   * @memberof CatalogProductsListResponse
   */
  searchMarker?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsReportRequest
 */
export interface CatalogProductsReportRequest {
  /**
   * Фильтр по базовому размеру
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  baseSize?: string;
  /**
   * Код филиала для подключения цен
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  branchOffice?: string;
  /**
   * Код раздела
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  category?: string;
  /**
   * Фильтрация для продуктов. См. getCatalogProducts
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  filters?: string;
  /**
   * Только с товары «В резерве»
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  inReserveRequired?: string;
  /**
   * Только с товары «В пути»
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  inTransitRequired?: string;
  /**
   * Минимальный для вывода лимит товаров на страницу
   * @type {number}
   * @memberof CatalogProductsReportRequest
   */
  limit?: number;
  /**
   * Фильтр по производителю
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  manufacturer?: string;
  /**
   * МДМКей номенклатуры
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  nomenclatureMdmKey?: string;
  /**
   * Для следующего вывода
   * @type {number}
   * @memberof CatalogProductsReportRequest
   */
  offset?: number;
  /**
   * Фильтр по виду товара
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  productType?: string;
  /**
   * Строка для поиска товара
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  query?: string;
  /**
   * Количество дней резерва ОТ
   * @type {number}
   * @memberof CatalogProductsReportRequest
   */
  reserveDaysFrom?: number | null;
  /**
   * Количество дней резерва ДО
   * @type {number}
   * @memberof CatalogProductsReportRequest
   */
  reserveDaysTo?: number | null;
  /**
   *
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  searchMarker?: string;
  /**
   * Только с остатками
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  stockRequired?: string;
  /**
   * Склад отгрузки
   * @type {string}
   * @memberof CatalogProductsReportRequest
   */
  warehouse?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsReportResponse
 */
export interface CatalogProductsReportResponse {
  /**
   * Смещение по товарам для загрузки следующей страницы
   * @type {number}
   * @memberof CatalogProductsReportResponse
   */
  nextOffset?: number;
  /**
   *
   * @type {Array<NomenclatureForReport>}
   * @memberof CatalogProductsReportResponse
   */
  nomenclatures?: Array<NomenclatureForReport>;
  /**
   * Список смещений (аналог постраничной навигации)
   * @type {Array<number>}
   * @memberof CatalogProductsReportResponse
   */
  offsets?: Array<number>;
  /**
   * Метка поиска (использованный индекс, идентификатор) для загрузки вторых страниц результата и фильтров
   * @type {string}
   * @memberof CatalogProductsReportResponse
   */
  searchMarker?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsReportXlsxRequest
 */
export interface CatalogProductsReportXlsxRequest {
  /**
   * Фильтр по базовому размеру
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  baseSize?: string;
  /**
   * Код филиала для подключения цен
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  branchOffice?: string;
  /**
   * Код раздела
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  category?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CatalogProductsReportXlsxRequest
   */
  columns?: Array<CatalogProductsReportXlsxRequestColumnsEnum>;
  /**
   * Фильтрация для продуктов. См. getCatalogProducts
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  filters?: string;
  /**
   * Только с товары «В резерве»
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  inReserveRequired?: string;
  /**
   * Только с товары «В пути»
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  inTransitRequired?: string;
  /**
   * Фильтр по производителю
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  manufacturer?: string;
  /**
   * МДМКей номенклатуры
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  nomenclatureMdmKey?: string;
  /**
   * Фильтр по виду товара
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  productType?: string;
  /**
   * Строка для поиска товара
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  query?: string;
  /**
   * Количество дней резерва ОТ
   * @type {number}
   * @memberof CatalogProductsReportXlsxRequest
   */
  reserveDaysFrom?: number | null;
  /**
   * Количество дней резерва ДО
   * @type {number}
   * @memberof CatalogProductsReportXlsxRequest
   */
  reserveDaysTo?: number | null;
  /**
   * Только с остатками
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  stockRequired?: string;
  /**
   * Склад отгрузки
   * @type {string}
   * @memberof CatalogProductsReportXlsxRequest
   */
  warehouse?: string;
}

export const CatalogProductsReportXlsxRequestColumnsEnum = {
  InStock: 'inStock',
  InStockForSale: 'inStockForSale',
  InReserve: 'inReserve',
  InConsignment: 'inConsignment',
  InTransitQuantity: 'inTransitQuantity',
  InAwaitingReserve: 'inAwaitingReserve',
  MinRetailUnitCost: 'minRetailUnitCost',
  PurchasePrice: 'purchasePrice',
  ShelfLife: 'shelfLife',
  Warehouse: 'warehouse',
  Units: 'units',
  SellerUnitCost: 'sellerUnitCost',
  SellerChiefUnitCost: 'sellerChiefUnitCost',
  OfficeChiefUnitCost: 'officeChiefUnitCost',
  DivisionChiefUnitCost: 'divisionChiefUnitCost',
  ReservedPurchaseTotalCost: 'reservedPurchaseTotalCost',
  ReservedMrcTotalCost: 'reservedMrcTotalCost',
  ReserveDays: 'reserveDays',
  PriceMotivation: 'priceMotivation',
} as const;

export type CatalogProductsReportXlsxRequestColumnsEnum =
  (typeof CatalogProductsReportXlsxRequestColumnsEnum)[keyof typeof CatalogProductsReportXlsxRequestColumnsEnum];

/**
 *
 * @export
 * @interface CatalogProductsResponse
 */
export interface CatalogProductsResponse {
  /**
   *
   * @type {Array<AvailableCatalogBaseSize>}
   * @memberof CatalogProductsResponse
   */
  baseSizes?: Array<AvailableCatalogBaseSize>;
  /**
   * Разделы товаров и все родительские разделы до корня
   * @type {Array<Category>}
   * @memberof CatalogProductsResponse
   */
  categories?: Array<Category>;
  /**
   *
   * @type {Array<AvailableCatalogManufacturer>}
   * @memberof CatalogProductsResponse
   */
  manufacturers?: Array<AvailableCatalogManufacturer>;
  /**
   * Вид товара
   * @type {Array<AvailableCatalogProductType>}
   * @memberof CatalogProductsResponse
   */
  productTypes?: Array<AvailableCatalogProductType>;
  /**
   *
   * @type {Array<Product>}
   * @memberof CatalogProductsResponse
   */
  products?: Array<Product>;
  /**
   *
   * @type {number}
   * @memberof CatalogProductsResponse
   */
  productsTotalCount?: number;
  /**
   *
   * @type {Array<ProductProperty>}
   * @memberof CatalogProductsResponse
   */
  properties?: Array<ProductProperty>;
  /**
   * Метка поиска (использованный индекс, идентификатор) для загрузки вторых страниц результата и фильтров
   * @type {string}
   * @memberof CatalogProductsResponse
   */
  searchMarker?: string;
}
/**
 *
 * @export
 * @interface CatalogProductsSearchFilterResponse
 */
export interface CatalogProductsSearchFilterResponse {
  /**
   * Базовые размеры
   * @type {Array<AvailableCatalogBaseSize>}
   * @memberof CatalogProductsSearchFilterResponse
   */
  baseSizes?: Array<AvailableCatalogBaseSize>;
  /**
   * Разделы товаров и все родительские разделы до корня
   * @type {Array<Category>}
   * @memberof CatalogProductsSearchFilterResponse
   */
  categories?: Array<Category>;
  /**
   * Производители
   * @type {Array<AvailableCatalogManufacturer>}
   * @memberof CatalogProductsSearchFilterResponse
   */
  manufacturers?: Array<AvailableCatalogManufacturer>;
  /**
   *
   * @type {Array<ProductProperty>}
   * @memberof CatalogProductsSearchFilterResponse
   */
  properties?: Array<ProductProperty>;
}
/**
 *
 * @export
 * @interface CatalogProductsStatsCompetitorsPlotResponse
 */
export interface CatalogProductsStatsCompetitorsPlotResponse {
  /**
   *
   * @type {Array<Competitor>}
   * @memberof CatalogProductsStatsCompetitorsPlotResponse
   */
  competitors?: Array<Competitor>;
  /**
   *
   * @type {Array<ProductStatsPlot>}
   * @memberof CatalogProductsStatsCompetitorsPlotResponse
   */
  products?: Array<ProductStatsPlot>;
}
/**
 *
 * @export
 * @interface CatalogProductsStatsPlotResponse
 */
export interface CatalogProductsStatsPlotResponse {
  /**
   *
   * @type {Array<ProductStatsPlot>}
   * @memberof CatalogProductsStatsPlotResponse
   */
  products?: Array<ProductStatsPlot>;
}
/**
 *
 * @export
 * @interface CatalogRootResponse
 */
export interface CatalogRootResponse {
  /**
   * Дочерние разделы
   * @type {Array<Category>}
   * @memberof CatalogRootResponse
   */
  categories?: Array<Category>;
}
/**
 *
 * @export
 * @interface CatalogWarehousesResponse
 */
export interface CatalogWarehousesResponse {
  /**
   *
   * @type {Array<Warehouse>}
   * @memberof CatalogWarehousesResponse
   */
  warehouses?: Array<Warehouse>;
}
/**
 *
 * @export
 * @interface Category
 */
export interface Category {
  /**
   *
   * @type {string}
   * @memberof Category
   */
  code?: string;
  /**
   * Количество товаров в разделе
   * @type {number}
   * @memberof Category
   */
  goodsCount?: number;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  parentCode?: string;
  /**
   *
   * @type {Array<CategoryPriceRange>}
   * @memberof Category
   */
  priceRanges?: Array<CategoryPriceRange> | null;
  /**
   * Количество товаров в разделе, у которых есть цены
   * @type {number}
   * @memberof Category
   */
  pricesCount?: number;
  /**
   * Количество товаров в разделе, у которых есть остатки
   * @type {number}
   * @memberof Category
   */
  stocksCount?: number;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  title?: string;
  /**
   * Ссылка на раздел
   * @type {string}
   * @memberof Category
   */
  url?: string;
}
/**
 *
 * @export
 * @interface CategoryListItem
 */
export interface CategoryListItem {
  /**
   *
   * @type {string}
   * @memberof CategoryListItem
   */
  code?: string;
  /**
   * Количество товаров в разделе
   * @type {number}
   * @memberof CategoryListItem
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof CategoryListItem
   */
  parentCode?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryListItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface CategoryPriceRange
 */
export interface CategoryPriceRange {
  /**
   * Уникальный код шкалы
   * @type {string}
   * @memberof CategoryPriceRange
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof CategoryPriceRange
   */
  maxCount?: number;
  /**
   *
   * @type {number}
   * @memberof CategoryPriceRange
   */
  minCount?: number;
  /**
   * Код шкалы, который можно использовать для группировки цен в столбцы
   * @type {string}
   * @memberof CategoryPriceRange
   */
  rangeCode?: string;
  /**
   *
   * @type {string}
   * @memberof CategoryPriceRange
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientActivityStatus
 */
export interface ClientActivityStatus {
  /**
   *
   * @type {string}
   * @memberof ClientActivityStatus
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ClientActivityStatus
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ClientActivityStatus
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientActivityStatusesResponse
 */
export interface ClientActivityStatusesResponse {
  /**
   *
   * @type {Array<ClientActivityStatus>}
   * @memberof ClientActivityStatusesResponse
   */
  activityStatuses?: Array<ClientActivityStatus>;
}
/**
 *
 * @export
 * @interface ClientContract
 */
export interface ClientContract {
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  agreementTypeCode?: string;
  /**
   * Вид соглашения договора
   * @type {string}
   * @memberof ClientContract
   */
  agreementTypeTitle?: string;
  /**
   *
   * @type {ClientContract}
   * @memberof ClientContract
   */
  baseContract?: ClientContract;
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  branchOfficeName?: string;
  /**
   * Признак возможности отправки файлов на пересогласование
   * @type {boolean}
   * @memberof ClientContract
   */
  canFileResending?: boolean;
  /**
   * Уникальный код документа
   * @type {string}
   * @memberof ClientContract
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  comment?: string;
  /**
   *
   * @type {CompanyPaymentDetails}
   * @memberof ClientContract
   */
  companyPaymentDetails?: CompanyPaymentDetails;
  /**
   * Сумма договора
   * @type {number}
   * @memberof ClientContract
   */
  contractSum?: number;
  /**
   * Сумма договора с НДС
   * @type {number}
   * @memberof ClientContract
   */
  contractTotalSum?: number;
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  contractTypeCode?: string;
  /**
   * Вид договора (Договор на реализацию металла с отсрочкой и тд)
   * @type {string}
   * @memberof ClientContract
   */
  contractTypeTitle?: string;
  /**
   * deprecated. use currencyTitle. Валюта
   * @type {string}
   * @memberof ClientContract
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  currencyCode?: string;
  /**
   * Валюта
   * @type {string}
   * @memberof ClientContract
   */
  currencyTitle?: string;
  /**
   *
   * @type {Customer}
   * @memberof ClientContract
   */
  customer?: Customer;
  /**
   * Кредитное состояние клиента - разрешённый лимит по деньгам
   * @type {number}
   * @memberof ClientContract
   */
  customerCreditStateAllowedSum?: number;
  /**
   * Кредитное состояние клиента - максимальный срок отсрочки
   * @type {number}
   * @memberof ClientContract
   */
  customerCreditStateDelayDays?: number;
  /**
   * Дата заведения договора
   * @type {string}
   * @memberof ClientContract
   */
  date?: string | null;
  /**
   * Описание договора
   * @type {string}
   * @memberof ClientContract
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  formCode?: string;
  /**
   * Форма договора
   * @type {string}
   * @memberof ClientContract
   */
  formTitle?: string;
  /**
   * Государственный контракт
   * @type {string}
   * @memberof ClientContract
   */
  governmentContract?: string;
  /**
   *
   * @type {Employee}
   * @memberof ClientContract
   */
  initiatorEmployee?: Employee;
  /**
   * Документ в статусе удален
   * @type {boolean}
   * @memberof ClientContract
   */
  isDeleted?: boolean;
  /**
   * Признак госконтракта
   * @type {boolean}
   * @memberof ClientContract
   */
  isGovernment?: boolean;
  /**
   * Бессрочный договор
   * @type {boolean}
   * @memberof ClientContract
   */
  isPerpetual?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  kindCode?: string;
  /**
   * Вид договора (с покупателем, с поставщиком, прочее)
   * @type {string}
   * @memberof ClientContract
   */
  kindName?: string;
  /**
   * Код договора в ERP системе
   * @type {string}
   * @memberof ClientContract
   */
  mdmKey?: string;
  /**
   * Номер договора
   * @type {string}
   * @memberof ClientContract
   */
  number?: string;
  /**
   * Дата, от которой считается просрочка
   * @type {string}
   * @memberof ClientContract
   */
  overdueDate?: string | null;
  /**
   * Количество дней просрочки
   * @type {number}
   * @memberof ClientContract
   */
  overdueDays?: number;
  /**
   * Сумма просрочено (ПДЗ) по счетам
   * @type {number}
   * @memberof ClientContract
   */
  overdueSum?: number;
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  renewalTypeCode?: string;
  /**
   * Тип пролонгации договора
   * @type {string}
   * @memberof ClientContract
   */
  renewalTypeTitle?: string;
  /**
   *
   * @type {Array<ClientContractSending>}
   * @memberof ClientContract
   */
  sendings?: Array<ClientContractSending>;
  /**
   *
   * @type {Contact}
   * @memberof ClientContract
   */
  signerContact?: Contact;
  /**
   * Дата документа подписанта
   * @type {string}
   * @memberof ClientContract
   */
  signerDocumentDate?: string | null;
  /**
   * Номер документа подписанта
   * @type {string}
   * @memberof ClientContract
   */
  signerDocumentNumber?: string | null;
  /**
   * Код типа документа подписанта
   * @type {string}
   * @memberof ClientContract
   */
  signerDocumentTypeCode?: string | null;
  /**
   * Заголовок типа документа подписанта
   * @type {string}
   * @memberof ClientContract
   */
  signerDocumentTypeTitle?: string | null;
  /**
   * Должность
   * @type {string}
   * @memberof ClientContract
   */
  signerPostName?: string;
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  statusCode?: string;
  /**
   * Статус договора
   * @type {string}
   * @memberof ClientContract
   */
  statusTitle?: string;
  /**
   * Нераспределенные средства
   * @type {number}
   * @memberof ClientContract
   */
  undistributedSum?: number;
  /**
   * Сумма не оплачено по счетам
   * @type {number}
   * @memberof ClientContract
   */
  unpaidSum?: number;
  /**
   * Дата начала действия договора
   * @type {string}
   * @memberof ClientContract
   */
  validFrom?: string | null;
  /**
   * Дата окончания действия договора
   * @type {string}
   * @memberof ClientContract
   */
  validUntil?: string | null;
  /**
   * НДС включена в сумму
   * @type {boolean}
   * @memberof ClientContract
   */
  vatIsIncluded?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientContract
   */
  vatRateCode?: string;
  /**
   * НДС
   * @type {string}
   * @memberof ClientContract
   */
  vatRateTitle?: string;
  /**
   *
   * @type {number}
   * @memberof ClientContract
   */
  vatRateValue?: number;
  /**
   * Сумма НДС
   * @type {number}
   * @memberof ClientContract
   */
  vatSum?: number;
}
/**
 * Файл договора
 * @export
 * @interface ClientContractFileItem
 */
export interface ClientContractFileItem {
  /**
   * Разрешено редактирование файла
   * @type {boolean}
   * @memberof ClientContractFileItem
   */
  canEdit?: boolean;
  /**
   * Код файла договора
   * @type {string}
   * @memberof ClientContractFileItem
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ClientContractFileItem
   */
  createdAt?: string;
  /**
   * Описание
   * @type {string}
   * @memberof ClientContractFileItem
   */
  description?: string;
  /**
   * Код типа файла
   * @type {string}
   * @memberof ClientContractFileItem
   */
  fileTypeCode?: string;
  /**
   * Заголовок типа файла
   * @type {string}
   * @memberof ClientContractFileItem
   */
  fileTypeTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ClientContractFileItem
   */
  filename?: string;
  /**
   * Размер файла (если известен)
   * @type {number}
   * @memberof ClientContractFileItem
   */
  filesize?: number;
  /**
   * Оригинал документа
   * @type {boolean}
   * @memberof ClientContractFileItem
   */
  isFileOriginal?: boolean;
  /**
   * Подписанный документ
   * @type {boolean}
   * @memberof ClientContractFileItem
   */
  isFileSign?: boolean;
  /**
   *
   * @type {Array<ClientContractSendingFileLog>}
   * @memberof ClientContractFileItem
   */
  logs?: Array<ClientContractSendingFileLog>;
  /**
   *
   * @type {ClientContractResendingFileState}
   * @memberof ClientContractFileItem
   */
  resendState?: ClientContractResendingFileState;
  /**
   *
   * @type {ClientContractSendingFileStatus}
   * @memberof ClientContractFileItem
   */
  status?: ClientContractSendingFileStatus;
  /**
   * Заголовок
   * @type {string}
   * @memberof ClientContractFileItem
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ClientContractFileItem
   */
  url?: string;
  /**
   * Код версии файла договора
   * @type {string}
   * @memberof ClientContractFileItem
   */
  versionCode?: string;
  /**
   * Ссылка на зип архив из внешней системы СЭД
   * @type {string}
   * @memberof ClientContractFileItem
   */
  zipUrl?: string;
}
/**
 *
 * @export
 * @interface ClientContractResending
 */
export interface ClientContractResending {
  /**
   * Уникальный код переотправки
   * @type {string}
   * @memberof ClientContractResending
   */
  code?: string;
  /**
   * Дата отправки
   * @type {string}
   * @memberof ClientContractResending
   */
  createdAt?: string;
  /**
   * Код статуса переотправки
   * @type {string}
   * @memberof ClientContractResending
   */
  stateCode?: string;
  /**
   * Статус переотправки
   * @type {string}
   * @memberof ClientContractResending
   */
  stateTitle?: string;
}
/**
 *
 * @export
 * @interface ClientContractResendingFileState
 */
export interface ClientContractResendingFileState {
  /**
   * Уникальный код статуса пересогласования файла
   * @type {string}
   * @memberof ClientContractResendingFileState
   */
  code?: string;
  /**
   * Статус пересогласования файла
   * @type {string}
   * @memberof ClientContractResendingFileState
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientContractSending
 */
export interface ClientContractSending {
  /**
   * Уникальный код отправки
   * @type {string}
   * @memberof ClientContractSending
   */
  code?: string;
  /**
   * Дата отправки
   * @type {string}
   * @memberof ClientContractSending
   */
  createdAt?: string;
  /**
   *
   * @type {Array<ClientContractSendingLog>}
   * @memberof ClientContractSending
   */
  logs?: Array<ClientContractSendingLog>;
  /**
   * Код статуса отправки
   * @type {string}
   * @memberof ClientContractSending
   */
  stateCode?: string;
  /**
   * Статус отправки
   * @type {string}
   * @memberof ClientContractSending
   */
  stateTitle?: string;
}
/**
 *
 * @export
 * @interface ClientContractSendingFileLog
 */
export interface ClientContractSendingFileLog {
  /**
   * Уникальный код лога
   * @type {string}
   * @memberof ClientContractSendingFileLog
   */
  code?: string;
  /**
   * Дата лога
   * @type {string}
   * @memberof ClientContractSendingFileLog
   */
  createdAt?: string;
  /**
   * Код статуса отправки
   * @type {string}
   * @memberof ClientContractSendingFileLog
   */
  statusCode?: string;
  /**
   * Комментарий к состоянию
   * @type {string}
   * @memberof ClientContractSendingFileLog
   */
  statusComment?: string;
  /**
   * Статус отправки
   * @type {string}
   * @memberof ClientContractSendingFileLog
   */
  statusTitle?: string;
}
/**
 *
 * @export
 * @interface ClientContractSendingFileStatus
 */
export interface ClientContractSendingFileStatus {
  /**
   * Уникальный код статуса отправки файла
   * @type {string}
   * @memberof ClientContractSendingFileStatus
   */
  code?: string;
  /**
   * Статус отправки файла
   * @type {string}
   * @memberof ClientContractSendingFileStatus
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientContractSendingLog
 */
export interface ClientContractSendingLog {
  /**
   * Уникальный код лога
   * @type {string}
   * @memberof ClientContractSendingLog
   */
  code?: string;
  /**
   * Дата лога
   * @type {string}
   * @memberof ClientContractSendingLog
   */
  createdAt?: string;
  /**
   * ссылка на документ в СЭД
   * @type {string}
   * @memberof ClientContractSendingLog
   */
  onesSedDocumentUrl?: string;
  /**
   * ссылка на задачу в СЭД
   * @type {string}
   * @memberof ClientContractSendingLog
   */
  onesSedTaskUrl?: string;
  /**
   * Код статуса отправки
   * @type {string}
   * @memberof ClientContractSendingLog
   */
  stateCode?: string;
  /**
   * Комментарии к состоянию
   * @type {Array<string>}
   * @memberof ClientContractSendingLog
   */
  stateComments?: Array<string>;
  /**
   * Статус отправки
   * @type {string}
   * @memberof ClientContractSendingLog
   */
  stateTitle?: string;
}
/**
 *
 * @export
 * @interface ClientDocFlowState
 */
export interface ClientDocFlowState {
  /**
   *
   * @type {string}
   * @memberof ClientDocFlowState
   */
  code?: ClientDocFlowStateCodeEnum;
  /**
   *
   * @type {string}
   * @memberof ClientDocFlowState
   */
  title?: string;
}

export const ClientDocFlowStateCodeEnum = {
  Inactive: 'inactive',
  Active: 'active',
  Invited: 'invited',
} as const;

export type ClientDocFlowStateCodeEnum = (typeof ClientDocFlowStateCodeEnum)[keyof typeof ClientDocFlowStateCodeEnum];

/**
 *
 * @export
 * @interface ClientDocFlowStatesResponse
 */
export interface ClientDocFlowStatesResponse {
  /**
   *
   * @type {Array<ClientDocFlowState>}
   * @memberof ClientDocFlowStatesResponse
   */
  states?: Array<ClientDocFlowState>;
}
/**
 *
 * @export
 * @interface ClientLeadsCreateRequest
 */
export interface ClientLeadsCreateRequest {
  /**
   * Двухбуквенный код страны
   * @type {string}
   * @memberof ClientLeadsCreateRequest
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsCreateRequest
   */
  inn?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsCreateRequest
   */
  kpp?: string;
  /**
   * Не резидент
   * @type {boolean}
   * @memberof ClientLeadsCreateRequest
   */
  nonResident?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsCreateRequest
   */
  ogrn?: string;
  /**
   * Код типа контрагента
   * @type {string}
   * @memberof ClientLeadsCreateRequest
   */
  participantTypeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsCreateRequest
   */
  shortTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsCreateRequest
   */
  sourceCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsCreateRequest
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientLeadsDecodeResult
 */
export interface ClientLeadsDecodeResult {
  /**
   *
   * @type {string}
   * @memberof ClientLeadsDecodeResult
   */
  address?: string;
  /**
   * Подразделения
   * @type {Array<ClientLeadsDecodeResultBranch>}
   * @memberof ClientLeadsDecodeResult
   */
  branches?: Array<ClientLeadsDecodeResultBranch>;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsDecodeResult
   */
  inn?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsDecodeResult
   */
  kpp?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsDecodeResult
   */
  ogrn?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsDecodeResult
   */
  shortTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsDecodeResult
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientLeadsDecodeResultBranch
 */
export interface ClientLeadsDecodeResultBranch {
  /**
   *
   * @type {string}
   * @memberof ClientLeadsDecodeResultBranch
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsDecodeResultBranch
   */
  kpp?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsDecodeResultBranch
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientLeadsItemSendingCreateResponse
 */
export interface ClientLeadsItemSendingCreateResponse {
  /**
   *
   * @type {ClientLeadsSendingItem}
   * @memberof ClientLeadsItemSendingCreateResponse
   */
  sending?: ClientLeadsSendingItem;
}
/**
 *
 * @export
 * @interface ClientLeadsItemSendingListResponse
 */
export interface ClientLeadsItemSendingListResponse {
  /**
   *
   * @type {Array<ClientLeadsSendingItem>}
   * @memberof ClientLeadsItemSendingListResponse
   */
  sendings?: Array<ClientLeadsSendingItem>;
}
/**
 *
 * @export
 * @interface ClientLeadsLookupRequest
 */
export interface ClientLeadsLookupRequest {
  /**
   *
   * @type {string}
   * @memberof ClientLeadsLookupRequest
   */
  inn?: string;
  /**
   * Не резидент
   * @type {boolean}
   * @memberof ClientLeadsLookupRequest
   */
  nonResident?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsLookupRequest
   */
  ogrn?: string;
}
/**
 *
 * @export
 * @interface ClientLeadsLookupResponse
 */
export interface ClientLeadsLookupResponse {
  /**
   * Уникальный код декодирования
   * @type {string}
   * @memberof ClientLeadsLookupResponse
   */
  decodeCode?: string;
  /**
   *
   * @type {ClientLeadsDecodeResult}
   * @memberof ClientLeadsLookupResponse
   */
  decodeResult?: ClientLeadsDecodeResult;
  /**
   * Состояние декодирования
   * @type {string}
   * @memberof ClientLeadsLookupResponse
   */
  decodeState?: ClientLeadsLookupResponseDecodeStateEnum;
  /**
   * Клиенты, которые уже были найдены по этому ИНН
   * @type {Array<Customer>}
   * @memberof ClientLeadsLookupResponse
   */
  foundCustomers?: Array<Customer>;
  /**
   * Код типа контрагента
   * @type {string}
   * @memberof ClientLeadsLookupResponse
   */
  participantTypeCode?: string | null;
}

export const ClientLeadsLookupResponseDecodeStateEnum = {
  Failed: 'failed',
  Ok: 'ok',
  Awaiting: 'awaiting',
  Canceled: 'canceled',
} as const;

export type ClientLeadsLookupResponseDecodeStateEnum =
  (typeof ClientLeadsLookupResponseDecodeStateEnum)[keyof typeof ClientLeadsLookupResponseDecodeStateEnum];

/**
 * Отправка заявки на создание клиента
 * @export
 * @interface ClientLeadsSendingItem
 */
export interface ClientLeadsSendingItem {
  /**
   *
   * @type {BranchOffice}
   * @memberof ClientLeadsSendingItem
   */
  branchOffice?: BranchOffice;
  /**
   * Код отправки
   * @type {string}
   * @memberof ClientLeadsSendingItem
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsSendingItem
   */
  createdAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof ClientLeadsSendingItem
   */
  initiatorEmployee?: Employee;
  /**
   *
   * @type {Array<ClientLeadsSendingLog>}
   * @memberof ClientLeadsSendingItem
   */
  logs?: Array<ClientLeadsSendingLog>;
  /**
   * Код состояния отправки
   * @type {string}
   * @memberof ClientLeadsSendingItem
   */
  stateCode?: string;
  /**
   * Комментарий к состоянию
   * @type {string}
   * @memberof ClientLeadsSendingItem
   */
  stateComment?: string;
  /**
   * Состояние отправки
   * @type {string}
   * @memberof ClientLeadsSendingItem
   */
  stateTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ClientLeadsSendingItem
   */
  updatedAt?: string;
}
/**
 *
 * @export
 * @interface ClientLeadsSendingLog
 */
export interface ClientLeadsSendingLog {
  /**
   * Уникальный код лога
   * @type {string}
   * @memberof ClientLeadsSendingLog
   */
  code?: string;
  /**
   * Дата лога
   * @type {string}
   * @memberof ClientLeadsSendingLog
   */
  createdAt?: string;
  /**
   * Код статуса отправки
   * @type {string}
   * @memberof ClientLeadsSendingLog
   */
  stateCode?: string;
  /**
   * Комментарий к состоянию
   * @type {string}
   * @memberof ClientLeadsSendingLog
   */
  stateComment?: string;
  /**
   * Статус отправки
   * @type {string}
   * @memberof ClientLeadsSendingLog
   */
  stateTitle?: string;
}
/**
 *
 * @export
 * @interface ClientOccupation
 */
export interface ClientOccupation {
  /**
   *
   * @type {string}
   * @memberof ClientOccupation
   */
  code?: string;
  /**
   *
   * @type {Array<ClientOccupationSpecified>}
   * @memberof ClientOccupation
   */
  occupationsSpecified?: Array<ClientOccupationSpecified>;
  /**
   *
   * @type {string}
   * @memberof ClientOccupation
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientOccupationSpecified
 */
export interface ClientOccupationSpecified {
  /**
   *
   * @type {string}
   * @memberof ClientOccupationSpecified
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ClientOccupationSpecified
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientOccupationsResponse
 */
export interface ClientOccupationsResponse {
  /**
   *
   * @type {Array<ClientOccupation>}
   * @memberof ClientOccupationsResponse
   */
  occupations?: Array<ClientOccupation>;
}
/**
 *
 * @export
 * @interface ClientOccupationsSpecifiedResponse
 */
export interface ClientOccupationsSpecifiedResponse {
  /**
   *
   * @type {Array<ClientOccupation>}
   * @memberof ClientOccupationsSpecifiedResponse
   */
  occupations?: Array<ClientOccupation>;
}
/**
 *
 * @export
 * @interface ClientOfficeSegments
 */
export interface ClientOfficeSegments {
  /**
   * Код филиала
   * @type {string}
   * @memberof ClientOfficeSegments
   */
  branchOfficeCode?: string;
  /**
   * Наименование филиала
   * @type {string}
   * @memberof ClientOfficeSegments
   */
  branchOfficeName?: string;
  /**
   * Код сегмента по частоте отгрузки
   * @type {string}
   * @memberof ClientOfficeSegments
   */
  frequencySegmentCode?: string;
  /**
   * Код сегмента по доходности
   * @type {string}
   * @memberof ClientOfficeSegments
   */
  monetarySegmentCode?: string;
  /**
   * Код сегмента по давности последней отгрузки
   * @type {string}
   * @memberof ClientOfficeSegments
   */
  recencySegmentCode?: string;
  /**
   * Код сегмента по отгрузкам
   * @type {string}
   * @memberof ClientOfficeSegments
   */
  shipmentSegmentCode?: string;
}
/**
 *
 * @export
 * @interface ClientOfficeSegmentsRequest
 */
export interface ClientOfficeSegmentsRequest {
  /**
   * Код клиента
   * @type {string}
   * @memberof ClientOfficeSegmentsRequest
   */
  customerCode: string;
}
/**
 *
 * @export
 * @interface ClientOfficeSegmentsResponse
 */
export interface ClientOfficeSegmentsResponse {
  /**
   *
   * @type {Array<ClientOfficeSegments>}
   * @memberof ClientOfficeSegmentsResponse
   */
  segments?: Array<ClientOfficeSegments>;
}
/**
 *
 * @export
 * @interface ClientSegment
 */
export interface ClientSegment {
  /**
   *
   * @type {string}
   * @memberof ClientSegment
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ClientSegment
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientSegmentsResponse
 */
export interface ClientSegmentsResponse {
  /**
   *
   * @type {Array<ClientSegment>}
   * @memberof ClientSegmentsResponse
   */
  segments?: Array<ClientSegment>;
}
/**
 *
 * @export
 * @interface ClientShipmentSegment
 */
export interface ClientShipmentSegment {
  /**
   * Код филиала
   * @type {string}
   * @memberof ClientShipmentSegment
   */
  branchOfficeCode?: string;
  /**
   * Наименование филиала
   * @type {string}
   * @memberof ClientShipmentSegment
   */
  branchOfficeName?: string;
  /**
   * Код сегмента отгрузки
   * @type {string}
   * @memberof ClientShipmentSegment
   */
  shipmentSegmentCode?: string;
}
/**
 *
 * @export
 * @interface ClientShipmentSegmentsHistoryRequest
 */
export interface ClientShipmentSegmentsHistoryRequest {
  /**
   * Код филиала
   * @type {string}
   * @memberof ClientShipmentSegmentsHistoryRequest
   */
  branchOfficeCode?: string | null;
  /**
   * Код клиента
   * @type {string}
   * @memberof ClientShipmentSegmentsHistoryRequest
   */
  customerCode: string;
  /**
   *
   * @type {string}
   * @memberof ClientShipmentSegmentsHistoryRequest
   */
  dateFrom: string;
  /**
   *
   * @type {string}
   * @memberof ClientShipmentSegmentsHistoryRequest
   */
  dateTo: string;
}
/**
 *
 * @export
 * @interface ClientShipmentSegmentsHistoryResponse
 */
export interface ClientShipmentSegmentsHistoryResponse {
  /**
   *
   * @type {Array<BranchOfficeSegmentHistory>}
   * @memberof ClientShipmentSegmentsHistoryResponse
   */
  branchOfficeSegmentsHistory?: Array<BranchOfficeSegmentHistory>;
}
/**
 *
 * @export
 * @interface ClientShipmentSegmentsRequest
 */
export interface ClientShipmentSegmentsRequest {
  /**
   * Код клиента
   * @type {string}
   * @memberof ClientShipmentSegmentsRequest
   */
  customerCode: string;
}
/**
 *
 * @export
 * @interface ClientShipmentSegmentsResponse
 */
export interface ClientShipmentSegmentsResponse {
  /**
   *
   * @type {Array<ClientShipmentSegment>}
   * @memberof ClientShipmentSegmentsResponse
   */
  segments?: Array<ClientShipmentSegment>;
}
/**
 *
 * @export
 * @interface ClientsAttentionsItemSaveRequest
 */
export interface ClientsAttentionsItemSaveRequest {
  /**
   * Код причины напоминания
   * @type {string}
   * @memberof ClientsAttentionsItemSaveRequest
   */
  reasonCode?: string;
  /**
   * Дата до которой напоминание активно
   * @type {string}
   * @memberof ClientsAttentionsItemSaveRequest
   */
  validUntil?: string;
}
/**
 *
 * @export
 * @interface ClientsAttentionsItemSaveResponse
 */
export interface ClientsAttentionsItemSaveResponse {
  /**
   *
   * @type {CustomerAttention}
   * @memberof ClientsAttentionsItemSaveResponse
   */
  attention?: CustomerAttention;
}
/**
 *
 * @export
 * @interface ClientsAttentionsReasonItem
 */
export interface ClientsAttentionsReasonItem {
  /**
   * Код причины
   * @type {string}
   * @memberof ClientsAttentionsReasonItem
   */
  code?: string;
  /**
   * Заголовок причины
   * @type {string}
   * @memberof ClientsAttentionsReasonItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ClientsAttentionsReasonsResponse
 */
export interface ClientsAttentionsReasonsResponse {
  /**
   *
   * @type {Array<ClientsAttentionsReasonItem>}
   * @memberof ClientsAttentionsReasonsResponse
   */
  reasons?: Array<ClientsAttentionsReasonItem>;
}
/**
 *
 * @export
 * @interface ClientsChangeRequest
 */
export interface ClientsChangeRequest {
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  appliedAt?: string | null;
  /**
   *
   * @type {Employee}
   * @memberof ClientsChangeRequest
   */
  appliedByEmployee?: Employee;
  /**
   *
   * @type {Array<ClientsChangeRequestApproval>}
   * @memberof ClientsChangeRequest
   */
  approvals?: Array<ClientsChangeRequestApproval>;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  authorNote?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  createdAt?: string;
  /**
   *
   * @type {Array<ClientsChangeRequestField>}
   * @memberof ClientsChangeRequest
   */
  fields?: Array<ClientsChangeRequestField>;
  /**
   *
   * @type {Employee}
   * @memberof ClientsChangeRequest
   */
  initiatorEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  kindCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  kindTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  rejectedAt?: string | null;
  /**
   *
   * @type {Employee}
   * @memberof ClientsChangeRequest
   */
  rejectedByEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  reviewerNote?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  stateCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequest
   */
  stateTitle?: string;
}
/**
 *
 * @export
 * @interface ClientsChangeRequestApproval
 */
export interface ClientsChangeRequestApproval {
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestApproval
   */
  createdAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof ClientsChangeRequestApproval
   */
  initiatorEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestApproval
   */
  roleCode?: string;
}
/**
 *
 * @export
 * @interface ClientsChangeRequestCreateRequest
 */
export interface ClientsChangeRequestCreateRequest {
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestCreateRequest
   */
  authorNote?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestCreateRequest
   */
  customerCode?: string;
  /**
   *
   * @type {Array<ClientsChangeRequestField>}
   * @memberof ClientsChangeRequestCreateRequest
   */
  fields?: Array<ClientsChangeRequestField>;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestCreateRequest
   */
  kindCode?: string;
}
/**
 *
 * @export
 * @interface ClientsChangeRequestField
 */
export interface ClientsChangeRequestField {
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestField
   */
  authorNote?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestField
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestField
   */
  reviewerNote?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestField
   */
  value?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestField
   */
  valueCurrent?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestField
   */
  valueCurrentDisplay?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestField
   */
  valueDisplay?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestField
   */
  valueOld?: string;
  /**
   *
   * @type {string}
   * @memberof ClientsChangeRequestField
   */
  valueOldDisplay?: string;
}
/**
 *
 * @export
 * @interface ClientsChangeRequestsResponse
 */
export interface ClientsChangeRequestsResponse {
  /**
   *
   * @type {Array<ClientsChangeRequest>}
   * @memberof ClientsChangeRequestsResponse
   */
  changeRequests?: Array<ClientsChangeRequest>;
  /**
   *
   * @type {number}
   * @memberof ClientsChangeRequestsResponse
   */
  totalCount?: number;
}
/**
 *
 * @export
 * @interface ClientsCloseOfficeAccessRequest
 */
export interface ClientsCloseOfficeAccessRequest {
  /**
   *
   * @type {string}
   * @memberof ClientsCloseOfficeAccessRequest
   */
  branchOfficeCode: string;
}
/**
 *
 * @export
 * @interface ClientsCreditLimitDocumentsResponse
 */
export interface ClientsCreditLimitDocumentsResponse {
  /**
   *
   * @type {Array<CreditLimitDocument>}
   * @memberof ClientsCreditLimitDocumentsResponse
   */
  documents?: Array<CreditLimitDocument>;
  /**
   *
   * @type {number}
   * @memberof ClientsCreditLimitDocumentsResponse
   */
  documentsTotalCount?: number;
}
/**
 *
 * @export
 * @interface ClientsForFilterRequest
 */
export interface ClientsForFilterRequest {
  /**
   * Код соглашения
   * @type {string}
   * @memberof ClientsForFilterRequest
   */
  agreementCode?: string;
  /**
   * Идентификаторы статусов соглашений
   * @type {Array<number>}
   * @memberof ClientsForFilterRequest
   */
  agreementStateIds?: Array<number>;
  /**
   * Коды статусов согласования документов
   * @type {Array<string>}
   * @memberof ClientsForFilterRequest
   */
  approvalStateCodes?: Array<string>;
  /**
   * Коды статусов отгрузки
   * @type {Array<string>}
   * @memberof ClientsForFilterRequest
   */
  billShipmentStateCodes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClientsForFilterRequest
   */
  billSourceCodes?: Array<string>;
  /**
   * Коды статусов счетов
   * @type {Array<string>}
   * @memberof ClientsForFilterRequest
   */
  billStateCodes?: Array<string>;
  /**
   * Коды филиалов
   * @type {Array<string>}
   * @memberof ClientsForFilterRequest
   */
  branchOfficeCodes?: Array<string>;
  /**
   * Содержит вложения
   * @type {boolean}
   * @memberof ClientsForFilterRequest
   */
  containsAttachments?: boolean;
  /**
   *
   * @type {number}
   * @memberof ClientsForFilterRequest
   */
  count?: number | null;
  /**
   * Дата от
   * @type {string}
   * @memberof ClientsForFilterRequest
   */
  dateFrom?: string | null;
  /**
   * Дата до
   * @type {string}
   * @memberof ClientsForFilterRequest
   */
  dateTo?: string | null;
  /**
   * Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
   * @type {string}
   * @memberof ClientsForFilterRequest
   */
  dealListType?: string;
  /**
   * Коды статусов заявок
   * @type {Array<string>}
   * @memberof ClientsForFilterRequest
   */
  dealStateCodes?: Array<string>;
  /**
   * Тип документа (deal, agreement, bill, shipment, и т.д.)
   * @type {string}
   * @memberof ClientsForFilterRequest
   */
  documentType?: ClientsForFilterRequestDocumentTypeEnum;
  /**
   * Коды типов прикрепленных документов
   * @type {Array<string>}
   * @memberof ClientsForFilterRequest
   */
  documentTypeCodes?: Array<string>;
  /**
   * Код сотрудника или группы
   * @type {string}
   * @memberof ClientsForFilterRequest
   */
  employeeSet?: string;
  /**
   * В наличии документ Счет
   * @type {boolean}
   * @memberof ClientsForFilterRequest
   */
  hasBills?: boolean;
  /**
   * Имеется сотрудник по замещению
   * @type {boolean}
   * @memberof ClientsForFilterRequest
   */
  hasEmployeeDeputy?: boolean;
  /**
   * Есть ПС (акция)
   * @type {boolean}
   * @memberof ClientsForFilterRequest
   */
  hasPS?: boolean;
  /**
   * В наличии документ Отгрузка
   * @type {boolean}
   * @memberof ClientsForFilterRequest
   */
  hasShipments?: boolean;
  /**
   * Уровень отклонения цены от рыночной
   * @type {Array<number>}
   * @memberof ClientsForFilterRequest
   */
  marketDeviationLevel?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof ClientsForFilterRequest
   */
  page?: number | null;
  /**
   * Коды статусов оплаты
   * @type {Array<string>}
   * @memberof ClientsForFilterRequest
   */
  paymentStateCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClientsForFilterRequest
   */
  queryCustomers?: string;
  /**
   * filter for documents
   * @type {string}
   * @memberof ClientsForFilterRequest
   */
  queryDocuments?: string;
  /**
   * Коды клиентов, обязательных к выводу
   * @type {Array<string>}
   * @memberof ClientsForFilterRequest
   */
  requiredCustomerCodes?: Array<string>;
  /**
   * Код типа отгрузки
   * @type {string}
   * @memberof ClientsForFilterRequest
   */
  shipmentTypeCode?: string;
  /**
   * Коды складов
   * @type {Array<string>}
   * @memberof ClientsForFilterRequest
   */
  warehousesCodes?: Array<string>;
}

export const ClientsForFilterRequestDocumentTypeEnum = {
  Deal: 'deal',
  Agreement: 'agreement',
  Bill: 'bill',
  Shipment: 'shipment',
} as const;

export type ClientsForFilterRequestDocumentTypeEnum =
  (typeof ClientsForFilterRequestDocumentTypeEnum)[keyof typeof ClientsForFilterRequestDocumentTypeEnum];

/**
 *
 * @export
 * @interface ClientsForFilterResponse
 */
export interface ClientsForFilterResponse {
  /**
   *
   * @type {Array<CustomerAsFilter>}
   * @memberof ClientsForFilterResponse
   */
  customers?: Array<CustomerAsFilter>;
}
/**
 *
 * @export
 * @interface ClientsItemAddressesAddResponse
 */
export interface ClientsItemAddressesAddResponse {
  /**
   * Код для нового адреса
   * @type {string}
   * @memberof ClientsItemAddressesAddResponse
   */
  code?: string;
}
/**
 *
 * @export
 * @interface ClientsItemAddressesItemSaveRequest
 */
export interface ClientsItemAddressesItemSaveRequest {
  /**
   * Код адреса
   * @type {string}
   * @memberof ClientsItemAddressesItemSaveRequest
   */
  code?: string;
  /**
   * Код грузополучателя, кому сохраняется адрес
   * @type {string}
   * @memberof ClientsItemAddressesItemSaveRequest
   */
  consigneeCode?: string | null;
  /**
   *
   * @type {AddressUpdates}
   * @memberof ClientsItemAddressesItemSaveRequest
   */
  updates?: AddressUpdates;
}
/**
 *
 * @export
 * @interface ClientsItemAddressesItemSaveResponse
 */
export interface ClientsItemAddressesItemSaveResponse {
  /**
   *
   * @type {Address}
   * @memberof ClientsItemAddressesItemSaveResponse
   */
  address?: Address;
}
/**
 *
 * @export
 * @interface ClientsItemAddressesResponse
 */
export interface ClientsItemAddressesResponse {
  /**
   *
   * @type {Array<Address>}
   * @memberof ClientsItemAddressesResponse
   */
  addresses?: Array<Address>;
}
/**
 *
 * @export
 * @interface ClientsItemAttentionsCreateRequest
 */
export interface ClientsItemAttentionsCreateRequest {
  /**
   * Код причины напоминания
   * @type {string}
   * @memberof ClientsItemAttentionsCreateRequest
   */
  reasonCode: string;
  /**
   * Дата до которой напоминание активно
   * @type {string}
   * @memberof ClientsItemAttentionsCreateRequest
   */
  validUntil: string;
}
/**
 *
 * @export
 * @interface ClientsItemAttentionsCreateResponse
 */
export interface ClientsItemAttentionsCreateResponse {
  /**
   *
   * @type {CustomerAttention}
   * @memberof ClientsItemAttentionsCreateResponse
   */
  attention?: CustomerAttention;
}
/**
 *
 * @export
 * @interface ClientsItemAttentionsHistoryResponse
 */
export interface ClientsItemAttentionsHistoryResponse {
  /**
   *
   * @type {Array<CustomerAttentionLog>}
   * @memberof ClientsItemAttentionsHistoryResponse
   */
  log?: Array<CustomerAttentionLog>;
}
/**
 *
 * @export
 * @interface ClientsItemAttentionsResponse
 */
export interface ClientsItemAttentionsResponse {
  /**
   *
   * @type {Array<CustomerAttention>}
   * @memberof ClientsItemAttentionsResponse
   */
  attentions?: Array<CustomerAttention>;
}
/**
 *
 * @export
 * @interface ClientsItemConsigneesResponse
 */
export interface ClientsItemConsigneesResponse {
  /**
   *
   * @type {Array<Consignee>}
   * @memberof ClientsItemConsigneesResponse
   */
  consignees?: Array<Consignee>;
  /**
   *
   * @type {number}
   * @memberof ClientsItemConsigneesResponse
   */
  consigneesTotalCount?: number;
}
/**
 *
 * @export
 * @interface ClientsItemContractsResponse
 */
export interface ClientsItemContractsResponse {
  /**
   *
   * @type {Array<ClientContract>}
   * @memberof ClientsItemContractsResponse
   */
  contracts?: Array<ClientContract>;
}
/**
 *
 * @export
 * @interface ClientsItemCreditStateResponse
 */
export interface ClientsItemCreditStateResponse {
  /**
   *
   * @type {CreditState}
   * @memberof ClientsItemCreditStateResponse
   */
  creditState?: CreditState;
  /**
   * GUID запроса во внешнюю систему (если выполняется)
   * @type {string}
   * @memberof ClientsItemCreditStateResponse
   */
  esrGuid?: string;
}
/**
 *
 * @export
 * @interface ClientsItemDealsResponse
 */
export interface ClientsItemDealsResponse {
  /**
   *
   * @type {Array<Deal>}
   * @memberof ClientsItemDealsResponse
   */
  deals?: Array<Deal>;
  /**
   *
   * @type {Array<DealBranchOffice>}
   * @memberof ClientsItemDealsResponse
   */
  dealsBranchOffices?: Array<DealBranchOffice>;
  /**
   *
   * @type {number}
   * @memberof ClientsItemDealsResponse
   */
  dealsTotalCount?: number;
}
/**
 *
 * @export
 * @interface ClientsItemMarginalityPlotResponse
 */
export interface ClientsItemMarginalityPlotResponse {
  /**
   *
   * @type {Plot}
   * @memberof ClientsItemMarginalityPlotResponse
   */
  marginalityPlot?: Plot;
}
/**
 *
 * @export
 * @interface ClientsItemMarginalityStatResponse
 */
export interface ClientsItemMarginalityStatResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof ClientsItemMarginalityStatResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<Customer>}
   * @memberof ClientsItemMarginalityStatResponse
   */
  customers?: Array<Customer>;
  /**
   * Реализаторы клиентов
   * @type {Array<Employee>}
   * @memberof ClientsItemMarginalityStatResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof ClientsItemMarginalityStatResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<EmployeeCustomerMarginalityReport>}
   * @memberof ClientsItemMarginalityStatResponse
   */
  stats?: Array<EmployeeCustomerMarginalityReport>;
}
/**
 *
 * @export
 * @interface ClientsItemOrdersResponse
 */
export interface ClientsItemOrdersResponse {
  /**
   *
   * @type {Array<Deal>}
   * @memberof ClientsItemOrdersResponse
   */
  orders?: Array<Deal>;
  /**
   *
   * @type {number}
   * @memberof ClientsItemOrdersResponse
   */
  ordersTotalCount?: number;
}
/**
 *
 * @export
 * @interface ClientsItemResponse
 */
export interface ClientsItemResponse {
  /**
   *
   * @type {Customer}
   * @memberof ClientsItemResponse
   */
  customer?: Customer;
}
/**
 *
 * @export
 * @interface ClientsItemSaveRequest
 */
export interface ClientsItemSaveRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof ClientsItemSaveRequest
   */
  addCompetitorCodes?: Array<string> | null;
  /**
   * Платёжные реквизиты по-умолчанию
   * @type {string}
   * @memberof ClientsItemSaveRequest
   */
  defaultPaymentDetailsCode?: string | null;
  /**
   * КПП контрагента, изменение возможно только в статусе Создан Контакт (лид)
   * @type {string}
   * @memberof ClientsItemSaveRequest
   */
  kpp?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ClientsItemSaveRequest
   */
  removeCompetitorCodes?: Array<string> | null;
  /**
   * Сбросить источник информации о клиенте
   * @type {boolean}
   * @memberof ClientsItemSaveRequest
   */
  resetSourceCode?: boolean;
  /**
   * Источник информации о клиенте
   * @type {string}
   * @memberof ClientsItemSaveRequest
   */
  sourceCode?: string | null;
}
/**
 *
 * @export
 * @interface ClientsRemoveOfficeRelationshipsRequest
 */
export interface ClientsRemoveOfficeRelationshipsRequest {
  /**
   *
   * @type {string}
   * @memberof ClientsRemoveOfficeRelationshipsRequest
   */
  branchOfficeCode: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ClientsRemoveOfficeRelationshipsRequest
   */
  relationshipTypeCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface ClientsResponse
 */
export interface ClientsResponse {
  /**
   *
   * @type {Array<Customer>}
   * @memberof ClientsResponse
   */
  customers?: Array<Customer>;
}
/**
 *
 * @export
 * @interface ClientsShipmentsThreadRequest
 */
export interface ClientsShipmentsThreadRequest {
  /**
   * Код документа отгрузки
   * @type {string}
   * @memberof ClientsShipmentsThreadRequest
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface ClientsShipmentsThreadResponse
 */
export interface ClientsShipmentsThreadResponse {
  /**
   *
   * @type {DocumentsThread}
   * @memberof ClientsShipmentsThreadResponse
   */
  documentsThread?: DocumentsThread;
}
/**
 *
 * @export
 * @interface CommonError
 */
export interface CommonError {
  /**
   * any additional data
   * @type {object}
   * @memberof CommonError
   */
  data?: object | null;
  /**
   * human-readable error message
   * @type {string}
   * @memberof CommonError
   */
  message?: string;
  /**
   * error type code
   * @type {string}
   * @memberof CommonError
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof CommonError
   */
  traceId?: string;
}
/**
 *
 * @export
 * @interface CompanyEmail
 */
export interface CompanyEmail {
  /**
   *
   * @type {string}
   * @memberof CompanyEmail
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyEmail
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyEmail
   */
  email?: string;
}
/**
 *
 * @export
 * @interface CompanyPaymentDetails
 */
export interface CompanyPaymentDetails {
  /**
   * р/с компании
   * @type {string}
   * @memberof CompanyPaymentDetails
   */
  accountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyPaymentDetails
   */
  bankTitle?: string;
  /**
   * БИК банка
   * @type {string}
   * @memberof CompanyPaymentDetails
   */
  bik?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyPaymentDetails
   */
  code?: string;
  /**
   * к/с банка
   * @type {string}
   * @memberof CompanyPaymentDetails
   */
  correspondentAccount?: string;
  /**
   * Платёжные реквизиты являются реквизитами по-умолчанию для компании
   * @type {boolean}
   * @memberof CompanyPaymentDetails
   */
  isCompanyDefault?: boolean;
}
/**
 *
 * @export
 * @interface CompanyPhone
 */
export interface CompanyPhone {
  /**
   *
   * @type {string}
   * @memberof CompanyPhone
   */
  additionalNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyPhone
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyPhone
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyPhone
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyPhone
   */
  typeCode?: CompanyPhoneTypeCodeEnum;
}

export const CompanyPhoneTypeCodeEnum = {
  City: 'city',
  Mobile: 'mobile',
} as const;

export type CompanyPhoneTypeCodeEnum = (typeof CompanyPhoneTypeCodeEnum)[keyof typeof CompanyPhoneTypeCodeEnum];

/**
 *
 * @export
 * @interface CompanyPublicOfficesResponse
 */
export interface CompanyPublicOfficesResponse {
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof CompanyPublicOfficesResponse
   */
  offices?: Array<BranchOffice>;
}
/**
 *
 * @export
 * @interface CompanySite
 */
export interface CompanySite {
  /**
   *
   * @type {string}
   * @memberof CompanySite
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySite
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof CompanySite
   */
  url?: string;
}
/**
 *
 * @export
 * @interface CompanySubordinatesResponse
 */
export interface CompanySubordinatesResponse {
  /**
   *
   * @type {Array<Subordinate>}
   * @memberof CompanySubordinatesResponse
   */
  subordinates?: Array<Subordinate>;
}
/**
 *
 * @export
 * @interface Competitor
 */
export interface Competitor {
  /**
   *
   * @type {string}
   * @memberof Competitor
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Competitor
   */
  title?: string;
}
/**
 *
 * @export
 * @interface CompetitorsResponse
 */
export interface CompetitorsResponse {
  /**
   *
   * @type {Array<Competitor>}
   * @memberof CompetitorsResponse
   */
  posts?: Array<Competitor>;
  /**
   *
   * @type {number}
   * @memberof CompetitorsResponse
   */
  total?: number;
}
/**
 * Грузополучатель
 * @export
 * @interface Consignee
 */
export interface Consignee {
  /**
   *
   * @type {string}
   * @memberof Consignee
   */
  code?: string;
  /**
   *
   * @type {boolean}
   * @memberof Consignee
   */
  isDeleted?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Consignee
   */
  mailingAddress?: string;
  /**
   *
   * @type {string}
   * @memberof Consignee
   */
  title?: string;
}
/**
 *
 * @export
 * @interface Contact
 */
export interface Contact {
  /**
   * Действует на основании
   * @type {string}
   * @memberof Contact
   */
  actsOnTheBasis?: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  birthDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  customerCode?: string;
  /**
   * Коды филиалов, в которых сотрудник станет подписантов по-умолчанию
   * @type {Array<string>}
   * @memberof Contact
   */
  defaultSignerForOfficeCodes?: Array<string>;
  /**
   *
   * @type {Array<CompanyEmail>}
   * @memberof Contact
   */
  emails?: Array<CompanyEmail>;
  /**
   * Когда добавили в избранное
   * @type {string}
   * @memberof Contact
   */
  favoriteAt?: string | null;
  /**
   * Код сотрудника, который добавил контакт в избранное
   * @type {string}
   * @memberof Contact
   */
  favoriteInitiatorEmployeeCode?: string;
  /**
   * ФИО сотрудника, который добавил контакт в избранное
   * @type {string}
   * @memberof Contact
   */
  favoriteInitiatorEmployeeTitle?: string;
  /**
   * ФИО в родительном падеже
   * @type {string}
   * @memberof Contact
   */
  fioGenitive?: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  gender?: ContactGenderEnum;
  /**
   *
   * @type {Array<ContactHobby>}
   * @memberof Contact
   */
  hobbies?: Array<ContactHobby>;
  /**
   * Является общим контактом компании
   * @type {boolean}
   * @memberof Contact
   */
  isCompany?: boolean;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof Contact
   */
  nonRemovable?: boolean;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  participantCode?: string;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  patronymic?: string;
  /**
   *
   * @type {Array<CompanyPhone>}
   * @memberof Contact
   */
  phones?: Array<CompanyPhone>;
  /**
   * Позиция для документов
   * @type {string}
   * @memberof Contact
   */
  positionForDocument?: string;
  /**
   *
   * @type {ContactPost}
   * @memberof Contact
   */
  post?: ContactPost;
  /**
   *
   * @type {Array<CompanySite>}
   * @memberof Contact
   */
  sites?: Array<CompanySite>;
  /**
   *
   * @type {string}
   * @memberof Contact
   */
  surname?: string;
}

export const ContactGenderEnum = {
  M: 'm',
  W: 'w',
} as const;

export type ContactGenderEnum = (typeof ContactGenderEnum)[keyof typeof ContactGenderEnum];

/**
 *
 * @export
 * @interface ContactHobbiesResponse
 */
export interface ContactHobbiesResponse {
  /**
   *
   * @type {Array<ContactHobby>}
   * @memberof ContactHobbiesResponse
   */
  posts?: Array<ContactHobby>;
  /**
   *
   * @type {number}
   * @memberof ContactHobbiesResponse
   */
  total?: number;
}
/**
 *
 * @export
 * @interface ContactHobby
 */
export interface ContactHobby {
  /**
   *
   * @type {string}
   * @memberof ContactHobby
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ContactHobby
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof ContactHobby
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContactPost
 */
export interface ContactPost {
  /**
   *
   * @type {string}
   * @memberof ContactPost
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPost
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPost
   */
  groupCode?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPost
   */
  groupTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ContactPost
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContactPostsResponse
 */
export interface ContactPostsResponse {
  /**
   *
   * @type {Array<ContactPost>}
   * @memberof ContactPostsResponse
   */
  posts?: Array<ContactPost>;
  /**
   *
   * @type {number}
   * @memberof ContactPostsResponse
   */
  total?: number;
}
/**
 *
 * @export
 * @interface ContactSaveRequest
 */
export interface ContactSaveRequest {
  /**
   *
   * @type {string}
   * @memberof ContactSaveRequest
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ContactSaveRequest
   */
  customerCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof ContactSaveRequest
   */
  isCompany?: boolean;
  /**
   *
   * @type {string}
   * @memberof ContactSaveRequest
   */
  participantCode?: string;
  /**
   *
   * @type {ContactSaveUpdates}
   * @memberof ContactSaveRequest
   */
  updates: ContactSaveUpdates;
}
/**
 *
 * @export
 * @interface ContactSaveUpdates
 */
export interface ContactSaveUpdates {
  /**
   * Действует на основании. Отправлять, если изменилось.
   * @type {string}
   * @memberof ContactSaveUpdates
   */
  actsOnTheBasis?: string | null;
  /**
   * Дата рождения. Отправлять, если изменилось.
   * @type {string}
   * @memberof ContactSaveUpdates
   */
  birthDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof ContactSaveUpdates
   */
  comment?: string | null;
  /**
   * Коды филиалов, в которых сотрудник станет подписантов по-умолчанию
   * @type {Array<string>}
   * @memberof ContactSaveUpdates
   */
  defaultSignerForOfficeCodes?: Array<string>;
  /**
   * Является избранным в филиале инициатора запроса
   * @type {boolean}
   * @memberof ContactSaveUpdates
   */
  favorite?: boolean | null;
  /**
   * ФИО в родительном падеже. Отправлять, если изменилось.
   * @type {string}
   * @memberof ContactSaveUpdates
   */
  fioGenitive?: string | null;
  /**
   * Пол. Отправлять, если изменилось.
   * @type {string}
   * @memberof ContactSaveUpdates
   */
  gender?: ContactSaveUpdatesGenderEnum;
  /**
   * Имя. Отправлять, если изменилось.
   * @type {string}
   * @memberof ContactSaveUpdates
   */
  name?: string | null;
  /**
   * Отчество. Отправлять, если изменилось.
   * @type {string}
   * @memberof ContactSaveUpdates
   */
  patronymic?: string | null;
  /**
   * Позиция для документов. Отправлять, если изменилось.
   * @type {string}
   * @memberof ContactSaveUpdates
   */
  positionForDocument?: string | null;
  /**
   * Должность. Отправлять, если изменилось.
   * @type {string}
   * @memberof ContactSaveUpdates
   */
  postCode?: string | null;
  /**
   * Коды email-ов к удалению
   * @type {Array<string>}
   * @memberof ContactSaveUpdates
   */
  removeEmailCodes?: Array<string>;
  /**
   * Коды хобби к удалению
   * @type {Array<string>}
   * @memberof ContactSaveUpdates
   */
  removeHobbyCodes?: Array<string>;
  /**
   * Коды телефонов к удалению
   * @type {Array<string>}
   * @memberof ContactSaveUpdates
   */
  removePhoneCodes?: Array<string>;
  /**
   * Коды сайтов к удалению
   * @type {Array<string>}
   * @memberof ContactSaveUpdates
   */
  removeSiteCodes?: Array<string>;
  /**
   * Сбросить дату рождения
   * @type {boolean}
   * @memberof ContactSaveUpdates
   */
  resetBirthDate?: boolean;
  /**
   * Сбросить пол
   * @type {boolean}
   * @memberof ContactSaveUpdates
   */
  resetGender?: boolean;
  /**
   * Сбросить отчество
   * @type {boolean}
   * @memberof ContactSaveUpdates
   */
  resetPatronymic?: boolean;
  /**
   * Сбросить должность
   * @type {boolean}
   * @memberof ContactSaveUpdates
   */
  resetPost?: boolean;
  /**
   * Новые или обновлённые email-ы. Передавать все поля вложенных объектов, включая не обновлённые.
   * @type {Array<CompanyEmail>}
   * @memberof ContactSaveUpdates
   */
  saveEmails?: Array<CompanyEmail>;
  /**
   * Новые или обновлённые хобби. Передавать все поля вложенных объектов, включая не обновлённые.
   * @type {Array<ContactHobby>}
   * @memberof ContactSaveUpdates
   */
  saveHobbies?: Array<ContactHobby>;
  /**
   * Новые или обновлённые телефоны. Передавать все поля вложенных объектов, включая не обновлённые.
   * @type {Array<CompanyPhone>}
   * @memberof ContactSaveUpdates
   */
  savePhones?: Array<CompanyPhone>;
  /**
   * Новые или обновлённые сайты. Передавать все поля вложенных объектов, включая не обновлённые.
   * @type {Array<CompanySite>}
   * @memberof ContactSaveUpdates
   */
  saveSites?: Array<CompanySite>;
  /**
   * Фамилия. Отправлять, если изменилось.
   * @type {string}
   * @memberof ContactSaveUpdates
   */
  surname?: string | null;
}

export const ContactSaveUpdatesGenderEnum = {
  M: 'm',
  W: 'w',
} as const;

export type ContactSaveUpdatesGenderEnum = (typeof ContactSaveUpdatesGenderEnum)[keyof typeof ContactSaveUpdatesGenderEnum];

/**
 *
 * @export
 * @interface ContactsGetHistoryItem
 */
export interface ContactsGetHistoryItem {
  /**
   *
   * @type {string}
   * @memberof ContactsGetHistoryItem
   */
  contactCode?: string;
  /**
   *
   * @type {string}
   * @memberof ContactsGetHistoryItem
   */
  contactCreatedAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof ContactsGetHistoryItem
   */
  contactCreatorEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof ContactsGetHistoryItem
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ContactsGetHistoryItem
   */
  initiatorEmployeeTitle?: string;
  /**
   *
   * @type {boolean}
   * @memberof ContactsGetHistoryItem
   */
  isCompany?: boolean;
  /**
   * Удалённые email-ы
   * @type {Array<CompanyEmail>}
   * @memberof ContactsGetHistoryItem
   */
  removeEmails?: Array<CompanyEmail>;
  /**
   * Удалённые хобби
   * @type {Array<ContactHobby>}
   * @memberof ContactsGetHistoryItem
   */
  removeHobbies?: Array<ContactHobby>;
  /**
   * Удалённые телефоны
   * @type {Array<CompanyPhone>}
   * @memberof ContactsGetHistoryItem
   */
  removePhones?: Array<CompanyPhone>;
  /**
   * Удалённые сайты
   * @type {Array<CompanySite>}
   * @memberof ContactsGetHistoryItem
   */
  removeSites?: Array<CompanySite>;
  /**
   *
   * @type {ContactSaveUpdates}
   * @memberof ContactsGetHistoryItem
   */
  updates?: ContactSaveUpdates;
}
/**
 *
 * @export
 * @interface ContactsGetHistoryResponse
 */
export interface ContactsGetHistoryResponse {
  /**
   *
   * @type {Array<ContactsGetHistoryItem>}
   * @memberof ContactsGetHistoryResponse
   */
  changes?: Array<ContactsGetHistoryItem>;
}
/**
 *
 * @export
 * @interface ContactsResponse
 */
export interface ContactsResponse {
  /**
   *
   * @type {Array<Contact>}
   * @memberof ContactsResponse
   */
  contacts?: Array<Contact>;
  /**
   *
   * @type {Array<Customer>}
   * @memberof ContactsResponse
   */
  customers?: Array<Customer>;
  /**
   * Доступна функция \"избранные контакты\"
   * @type {boolean}
   * @memberof ContactsResponse
   */
  featureFavoriteEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ContactsResponse
   */
  hasWriteAccess?: boolean;
}
/**
 *
 * @export
 * @interface ContractAgreementTypeItem
 */
export interface ContractAgreementTypeItem {
  /**
   * Код
   * @type {string}
   * @memberof ContractAgreementTypeItem
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof ContractAgreementTypeItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContractAgreementTypesResponse
 */
export interface ContractAgreementTypesResponse {
  /**
   *
   * @type {Array<ContractAgreementTypeItem>}
   * @memberof ContractAgreementTypesResponse
   */
  types?: Array<ContractAgreementTypeItem>;
}
/**
 *
 * @export
 * @interface ContractCostAgreement
 */
export interface ContractCostAgreement {
  /**
   * Уровень отклонения цены (от -2 - хуже максимального отклонения для филиала до 2)
   * @type {number}
   * @memberof ContractCostAgreement
   */
  actualMarketDeviationLevel?: number;
  /**
   * Текущее отклонение от рыночной цены
   * @type {number}
   * @memberof ContractCostAgreement
   */
  actualMarketDeviationPct?: number;
  /**
   * Используемое допустимое отклонение от рыночной цены
   * @type {number}
   * @memberof ContractCostAgreement
   */
  actualPermissibleMarketDeviationPct?: number;
  /**
   *
   * @type {DocumentAgreementApproval}
   * @memberof ContractCostAgreement
   */
  approval?: DocumentAgreementApproval;
  /**
   * Код офиса, где оформлена заявка
   * @type {string}
   * @memberof ContractCostAgreement
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreement
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreement
   */
  code?: string;
  /**
   * Комментарий для коммерческого предложения
   * @type {string}
   * @memberof ContractCostAgreement
   */
  comment?: string;
  /**
   * Комментарий для согласования
   * @type {string}
   * @memberof ContractCostAgreement
   */
  commentForApprove?: string;
  /**
   *
   * @type {ClientContract}
   * @memberof ContractCostAgreement
   */
  contract?: ClientContract;
  /**
   * Время создания соглашения
   * @type {string}
   * @memberof ContractCostAgreement
   */
  createdAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof ContractCostAgreement
   */
  creator?: Employee;
  /**
   * Стоимость отсрочки платежа. Сумма, на которую увеличится стоимость соглашения за отсрочку оплаты.
   * @type {number}
   * @memberof ContractCostAgreement
   */
  creditCost?: number;
  /**
   *
   * @type {Customer}
   * @memberof ContractCostAgreement
   */
  customer?: Customer;
  /**
   * Род деятельности клиента на момент создания соглашения
   * @type {string}
   * @memberof ContractCostAgreement
   */
  customerOccupationCode?: string;
  /**
   * Род деятельности клиента на момент создания соглашения
   * @type {string}
   * @memberof ContractCostAgreement
   */
  customerOccupationTitle?: string;
  /**
   * Сегмент клиента на момент создания соглашения
   * @type {string}
   * @memberof ContractCostAgreement
   */
  customerSegmentCode?: string;
  /**
   * Допустимое отклонение от рыночной цены по умолчанию
   * @type {number}
   * @memberof ContractCostAgreement
   */
  defaultPermissibleMarketDeviationPct?: number;
  /**
   *
   * @type {Employee}
   * @memberof ContractCostAgreement
   */
  distributor?: Employee;
  /**
   *
   * @type {Array<DealDocument>}
   * @memberof ContractCostAgreement
   */
  documents?: Array<DealDocument>;
  /**
   *
   * @type {Array<ContractCostAgreementEditingDisableReason>}
   * @memberof ContractCostAgreement
   */
  editingDisableReasons?: Array<ContractCostAgreementEditingDisableReason>;
  /**
   *
   * @type {boolean}
   * @memberof ContractCostAgreement
   */
  editingEnabled?: boolean;
  /**
   * Документ в статусе удален
   * @type {boolean}
   * @memberof ContractCostAgreement
   */
  isDeleted?: boolean;
  /**
   * Установить стоимость доставки
   * @type {number}
   * @memberof ContractCostAgreement
   */
  manualDeliveryCost?: number;
  /**
   * Заданное допустимое отклонение от рыночной цены
   * @type {number}
   * @memberof ContractCostAgreement
   */
  manualPermissibleMarketDeviationPct?: number | null;
  /**
   * Маржинальность цен соглашения к МРЦ, руб.
   * @type {number}
   * @memberof ContractCostAgreement
   */
  marginality?: number;
  /**
   * Маржинальность к МРЦ, %
   * @type {number}
   * @memberof ContractCostAgreement
   */
  marginalityPct?: number;
  /**
   * Максимально допустимое отклонение от рыночной цены по умолчанию
   * @type {number}
   * @memberof ContractCostAgreement
   */
  maxPermissibleMarketDeviationPct?: number;
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreement
   */
  name?: string;
  /**
   *
   * @type {DealPayment}
   * @memberof ContractCostAgreement
   */
  payment?: DealPayment;
  /**
   * Маржинальность к ПлС, %.
   * @type {number}
   * @memberof ContractCostAgreement
   */
  plsMarginFullCostPct?: number;
  /**
   * Маржинальность цен соглашения к ПлС, руб.
   * @type {number}
   * @memberof ContractCostAgreement
   */
  plsMarginTotalCost?: number;
  /**
   *
   * @type {Array<ContractCostPosition>}
   * @memberof ContractCostAgreement
   */
  positions?: Array<ContractCostPosition>;
  /**
   * Маржинальность к ценам прайс-листа, %.
   * @type {number}
   * @memberof ContractCostAgreement
   */
  priceListMarginFullCostPct?: number;
  /**
   * Маржинальность к ценам прайс-листа, руб.
   * @type {number}
   * @memberof ContractCostAgreement
   */
  priceListMarginTotalCost?: number;
  /**
   * Маржинальность к цене закупки, %.
   * @type {number}
   * @memberof ContractCostAgreement
   */
  purchaseMarginFullCostPct?: number;
  /**
   * Маржинальность цен соглашения к ценам закупки, руб.
   * @type {number}
   * @memberof ContractCostAgreement
   */
  purchaseMarginTotalCost?: number;
  /**
   *
   * @type {ContractCostSimplifiedState}
   * @memberof ContractCostAgreement
   */
  simplifiedState?: ContractCostSimplifiedState;
  /**
   * Общая стоимость сделки
   * @type {number}
   * @memberof ContractCostAgreement
   */
  totalCost?: number;
  /**
   * Общий вес, кг
   * @type {number}
   * @memberof ContractCostAgreement
   */
  totalWeight?: number;
  /**
   * Использовать заданную стоимость доставки
   * @type {boolean}
   * @memberof ContractCostAgreement
   */
  useManualDeliveryCost?: boolean;
  /**
   * Использовать заданное вручную допустимое отклонение от рыночной цены
   * @type {boolean}
   * @memberof ContractCostAgreement
   */
  useManualPermissibleMarketDeviationPct?: boolean;
  /**
   * Дата начала действия договора
   * @type {string}
   * @memberof ContractCostAgreement
   */
  validFrom?: string;
  /**
   * Дата окончания действия договора
   * @type {string}
   * @memberof ContractCostAgreement
   */
  validUntil?: string;
}
/**
 *
 * @export
 * @interface ContractCostAgreementEditingDisableReason
 */
export interface ContractCostAgreementEditingDisableReason {
  /**
   * locked, approved, etc.
   * @type {string}
   * @memberof ContractCostAgreementEditingDisableReason
   */
  code?: string;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsApprovalConfirmRequest
 */
export interface ContractCostAgreementsApprovalConfirmRequest {
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsApprovalConfirmRequest
   */
  agreementCode?: string;
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsApprovalConfirmRequest
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsApprovalConfirmResponse
 */
export interface ContractCostAgreementsApprovalConfirmResponse {
  /**
   *
   * @type {ContractCostAgreement}
   * @memberof ContractCostAgreementsApprovalConfirmResponse
   */
  agreement?: ContractCostAgreement;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsApprovalDeclineRequest
 */
export interface ContractCostAgreementsApprovalDeclineRequest {
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsApprovalDeclineRequest
   */
  agreementCode?: string;
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsApprovalDeclineRequest
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsApprovalDeclineResponse
 */
export interface ContractCostAgreementsApprovalDeclineResponse {
  /**
   *
   * @type {ContractCostAgreement}
   * @memberof ContractCostAgreementsApprovalDeclineResponse
   */
  agreement?: ContractCostAgreement;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsApprovalHistoryResponse
 */
export interface ContractCostAgreementsApprovalHistoryResponse {
  /**
   *
   * @type {DocumentApprovalHistory}
   * @memberof ContractCostAgreementsApprovalHistoryResponse
   */
  history?: DocumentApprovalHistory;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsApprovalWithdrawRequest
 */
export interface ContractCostAgreementsApprovalWithdrawRequest {
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsApprovalWithdrawRequest
   */
  agreementCode?: string;
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsApprovalWithdrawRequest
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsApprovalWithdrawResponse
 */
export interface ContractCostAgreementsApprovalWithdrawResponse {
  /**
   *
   * @type {ContractCostAgreement}
   * @memberof ContractCostAgreementsApprovalWithdrawResponse
   */
  agreement?: ContractCostAgreement;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsCloneRequest
 */
export interface ContractCostAgreementsCloneRequest {
  /**
   * Ключ для создания нового объекта.
   * @type {string}
   * @memberof ContractCostAgreementsCloneRequest
   */
  frontCode?: string;
  /**
   * Код клонируемого соглашения
   * @type {string}
   * @memberof ContractCostAgreementsCloneRequest
   */
  sourceCode?: string;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsCloneResponse
 */
export interface ContractCostAgreementsCloneResponse {
  /**
   *
   * @type {ContractCostAgreement}
   * @memberof ContractCostAgreementsCloneResponse
   */
  agreement?: ContractCostAgreement;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsCreateRequest
 */
export interface ContractCostAgreementsCreateRequest {
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsCreateRequest
   */
  customerCode?: string;
  /**
   * Ключ для создания нового объекта.
   * @type {string}
   * @memberof ContractCostAgreementsCreateRequest
   */
  frontCode?: string;
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsCreateRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsCreateRequest
   */
  validFrom?: string;
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsCreateRequest
   */
  validUntil?: string;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsCreateResponse
 */
export interface ContractCostAgreementsCreateResponse {
  /**
   *
   * @type {ContractCostAgreement}
   * @memberof ContractCostAgreementsCreateResponse
   */
  agreement?: ContractCostAgreement;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsItemAlternativesRequest
 */
export interface ContractCostAgreementsItemAlternativesRequest {
  /**
   * Искать альтернативы по номенклатуре
   * @type {boolean}
   * @memberof ContractCostAgreementsItemAlternativesRequest
   */
  findByNomenclature?: boolean;
  /**
   * Искать альтернативы по группе сегментации
   * @type {boolean}
   * @memberof ContractCostAgreementsItemAlternativesRequest
   */
  findBySegmentationGroup?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ContractCostAgreementsItemAlternativesRequest
   */
  positionCodes?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ContractCostAgreementsItemAlternativesRequest
   */
  stockRequired?: boolean;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsItemAlternativesResponse
 */
export interface ContractCostAgreementsItemAlternativesResponse {
  /**
   *
   * @type {Array<ContractCostPositionAlternative>}
   * @memberof ContractCostAgreementsItemAlternativesResponse
   */
  positions?: Array<ContractCostPositionAlternative>;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsItemDistributorsResponse
 */
export interface ContractCostAgreementsItemDistributorsResponse {
  /**
   *
   * @type {Array<EmployeeSet>}
   * @memberof ContractCostAgreementsItemDistributorsResponse
   */
  distributors?: Array<EmployeeSet>;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsItemPdfResponse
 */
export interface ContractCostAgreementsItemPdfResponse {
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsItemPdfResponse
   */
  status?: string;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsItemResponse
 */
export interface ContractCostAgreementsItemResponse {
  /**
   *
   * @type {ContractCostAgreement}
   * @memberof ContractCostAgreementsItemResponse
   */
  agreement?: ContractCostAgreement;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsItemSaveRequest
 */
export interface ContractCostAgreementsItemSaveRequest {
  /**
   *
   * @type {AgreementUpdates}
   * @memberof ContractCostAgreementsItemSaveRequest
   */
  updates?: AgreementUpdates;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsItemSaveResponse
 */
export interface ContractCostAgreementsItemSaveResponse {
  /**
   *
   * @type {ContractCostAgreement}
   * @memberof ContractCostAgreementsItemSaveResponse
   */
  agreement?: ContractCostAgreement;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsItemSpecification
 */
export interface ContractCostAgreementsItemSpecification {
  /**
   * Можно поставить печать и подпись
   * @type {boolean}
   * @memberof ContractCostAgreementsItemSpecification
   */
  canStampAndSign?: boolean;
  /**
   * Можно обновить
   * @type {boolean}
   * @memberof ContractCostAgreementsItemSpecification
   */
  canUpdate?: boolean;
  /**
   * Представители покупателя
   * @type {Array<Representative>}
   * @memberof ContractCostAgreementsItemSpecification
   */
  representativesCustomer?: Array<Representative>;
  /**
   * Представители продавца
   * @type {Array<Representative>}
   * @memberof ContractCostAgreementsItemSpecification
   */
  representativesSeller?: Array<Representative>;
  /**
   *
   * @type {ContractCostSimplifiedState}
   * @memberof ContractCostAgreementsItemSpecification
   */
  simplifiedState?: ContractCostSimplifiedState;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsItemSpecificationUpdateRequest
 */
export interface ContractCostAgreementsItemSpecificationUpdateRequest {
  /**
   * Код подписанта со стороны покупателя
   * @type {string}
   * @memberof ContractCostAgreementsItemSpecificationUpdateRequest
   */
  representativeCustomerCode?: string;
  /**
   * Код сотрудника-подписанта
   * @type {string}
   * @memberof ContractCostAgreementsItemSpecificationUpdateRequest
   */
  signerEmployeeCode?: string;
  /**
   *
   * @type {Array<PdfText>}
   * @memberof ContractCostAgreementsItemSpecificationUpdateRequest
   */
  texts?: Array<PdfText>;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsPositionsMoveRequest
 */
export interface ContractCostAgreementsPositionsMoveRequest {
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsPositionsMoveRequest
   */
  agreementCode?: string;
  /**
   *
   * @type {Array<ContractCostAgreementsPositionsMoveRequestPosition>}
   * @memberof ContractCostAgreementsPositionsMoveRequest
   */
  positions?: Array<ContractCostAgreementsPositionsMoveRequestPosition>;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsPositionsMoveRequestPosition
 */
export interface ContractCostAgreementsPositionsMoveRequestPosition {
  /**
   * Код позиции соглашения
   * @type {string}
   * @memberof ContractCostAgreementsPositionsMoveRequestPosition
   */
  code?: string;
  /**
   * Новый номер по порядку
   * @type {number}
   * @memberof ContractCostAgreementsPositionsMoveRequestPosition
   */
  lineNumber?: number;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsPositionsMoveResponse
 */
export interface ContractCostAgreementsPositionsMoveResponse {
  /**
   *
   * @type {ContractCostAgreement}
   * @memberof ContractCostAgreementsPositionsMoveResponse
   */
  agreement?: ContractCostAgreement;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsResponse
 */
export interface ContractCostAgreementsResponse {
  /**
   *
   * @type {Array<ContractCostAgreement>}
   * @memberof ContractCostAgreementsResponse
   */
  agreements?: Array<ContractCostAgreement>;
  /**
   *
   * @type {Array<AgreementBranchOffice>}
   * @memberof ContractCostAgreementsResponse
   */
  agreementsBranchOffices?: Array<AgreementBranchOffice>;
  /**
   * Общая сумма, руб.
   * @type {number}
   * @memberof ContractCostAgreementsResponse
   */
  agreementsTotalCost?: number;
  /**
   *
   * @type {number}
   * @memberof ContractCostAgreementsResponse
   */
  agreementsTotalCount?: number;
  /**
   * Общий вес, кг.
   * @type {number}
   * @memberof ContractCostAgreementsResponse
   */
  agreementsTotalWeight?: number;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsThreadRequest
 */
export interface ContractCostAgreementsThreadRequest {
  /**
   *
   * @type {string}
   * @memberof ContractCostAgreementsThreadRequest
   */
  agreementCode?: string;
}
/**
 *
 * @export
 * @interface ContractCostAgreementsThreadResponse
 */
export interface ContractCostAgreementsThreadResponse {
  /**
   *
   * @type {DocumentsThread}
   * @memberof ContractCostAgreementsThreadResponse
   */
  documentsThread?: DocumentsThread;
}
/**
 *
 * @export
 * @interface ContractCostPosition
 */
export interface ContractCostPosition {
  /**
   * Текущее отклонение от рыночной цены
   * @type {number}
   * @memberof ContractCostPosition
   */
  actualMarketDeviationPct?: number;
  /**
   * Текущая плановая себестоимость
   * @type {number}
   * @memberof ContractCostPosition
   */
  actualPrimeUnitCost?: number;
  /**
   * Текущая средневзвешенная цена закупки
   * @type {number}
   * @memberof ContractCostPosition
   */
  actualPurchaseUnitCost?: number;
  /**
   * Установленная голая цена товара
   * @type {number}
   * @memberof ContractCostPosition
   */
  bareUnitCost?: number;
  /**
   * Базовая МРЦ
   * @type {number}
   * @memberof ContractCostPosition
   */
  baseMinRetailUnitCost?: number;
  /**
   * Результирующее количество (обычно в тоннах)
   * @type {number}
   * @memberof ContractCostPosition
   */
  baseQuantity?: number;
  /**
   * Результирующая ЕИ (обычно тонны)
   * @type {string}
   * @memberof ContractCostPosition
   */
  baseUnit?: string;
  /**
   * Код результирующей ЕИ (TNE)
   * @type {string}
   * @memberof ContractCostPosition
   */
  baseUnitCode?: string;
  /**
   * Расчетная голая цена товара. Имеет смысл, если задается тендерная цена.
   * @type {number}
   * @memberof ContractCostPosition
   */
  calculatedBareUnitCost?: number;
  /**
   * Уникальный код позиции соглашения
   * @type {string}
   * @memberof ContractCostPosition
   */
  code?: string;
  /**
   * Комментарий для коммерческого предложения
   * @type {string}
   * @memberof ContractCostPosition
   */
  comment?: string;
  /**
   * Комментарий для согласования
   * @type {string}
   * @memberof ContractCostPosition
   */
  commentForApprove?: string;
  /**
   * Стоимость кредита н позицию
   * @type {number}
   * @memberof ContractCostPosition
   */
  creditTotalCost?: number;
  /**
   * Цена кредита на единицу
   * @type {number}
   * @memberof ContractCostPosition
   */
  creditUnitCost?: number;
  /**
   * Учитывать кредит в стоимости позиции
   * @type {boolean}
   * @memberof ContractCostPosition
   */
  distributeCredit?: boolean;
  /**
   * Фактическая общая стоимость включенной доставки
   * @type {number}
   * @memberof ContractCostPosition
   */
  distributeDeliveryTotalCost?: number;
  /**
   * Фактическая стоимость включенной доставки за тонну
   * @type {number}
   * @memberof ContractCostPosition
   */
  distributeDeliveryUnitCost?: number;
  /**
   * (Цена МОП + доставка + услуги + кредит) * Количество
   * @type {number}
   * @memberof ContractCostPosition
   */
  fullTotalCost?: number;
  /**
   * Цена МОП + доставка + услуги + кредит
   * @type {number}
   * @memberof ContractCostPosition
   */
  fullUnitCost?: number;
  /**
   *
   * @type {string}
   * @memberof ContractCostPosition
   */
  historyDate?: string;
  /**
   * Рекомендованная цена рынка (с учетом доставки и услуг)
   * @type {number}
   * @memberof ContractCostPosition
   */
  historyGlobalMarketRecommendedUnitCost?: number;
  /**
   * Цена рынка
   * @type {number}
   * @memberof ContractCostPosition
   */
  historyGlobalMarketUnitCost?: number;
  /**
   * Историческая МРЦ на начало действия соглашения
   * @type {number}
   * @memberof ContractCostPosition
   */
  historyMrcUnitCost?: number;
  /**
   * Историческая себестоимость на начало действия соглашения
   * @type {number}
   * @memberof ContractCostPosition
   */
  historyPrimeUnitCost?: number;
  /**
   * Историческая средневзвешенная цена закупки на начало действия соглашения
   * @type {number}
   * @memberof ContractCostPosition
   */
  historyPurchaseUnitCost?: number;
  /**
   * Рекомендованная цена (цена прайслиста)
   * @type {number}
   * @memberof ContractCostPosition
   */
  historyRecommendedUnitCost?: number;
  /**
   * Сегмент товара на момент формирования соглашения
   * @type {string}
   * @memberof ContractCostPosition
   */
  historySegmentCode?: string;
  /**
   * Коэффициент товарного запаса на момент формирования соглашения
   * @type {number}
   * @memberof ContractCostPosition
   */
  historyStockCoefficient?: number;
  /**
   * Историческая цена+
   * @type {number}
   * @memberof ContractCostPosition
   */
  historyTopScaleUnitCost?: number;
  /**
   * Признак архивной позиции
   * @type {boolean}
   * @memberof ContractCostPosition
   */
  isArchive?: boolean;
  /**
   * Позиция является услугой
   * @type {boolean}
   * @memberof ContractCostPosition
   */
  isService?: boolean;
  /**
   * Отключить контроль остатков
   * @type {boolean}
   * @memberof ContractCostPosition
   */
  isStocksControlDisabled?: boolean;
  /**
   * Номер позиции в соглашении
   * @type {number}
   * @memberof ContractCostPosition
   */
  lineNumber?: number;
  /**
   * Заданная стоимость доставки для позиции
   * @type {number}
   * @memberof ContractCostPosition
   */
  manualDeliveryTotalCost?: number;
  /**
   * Заданная МРЦ
   * @type {number}
   * @memberof ContractCostPosition
   */
  manualMrcUnitCost?: number;
  /**
   * Необходимо вручную задать МРЦ (отсутствует историческая)
   * @type {boolean}
   * @memberof ContractCostPosition
   */
  manualMrcUnitCostRequired?: boolean;
  /**
   * Заданная вручную МРЦ была использована
   * @type {boolean}
   * @memberof ContractCostPosition
   */
  manualMrcUnitCostUsed?: boolean;
  /**
   * Заданная вручную стоимость услуг для позиции
   * @type {number}
   * @memberof ContractCostPosition
   */
  manualServicesTotalCost?: number;
  /**
   *
   * @type {number}
   * @memberof ContractCostPosition
   */
  marginalityPct?: number;
  /**
   * Использованное значение МРЦ
   * @type {number}
   * @memberof ContractCostPosition
   */
  mrcUnitCost?: number;
  /**
   *
   * @type {Array<DealPositionMrcUserGroup>}
   * @memberof ContractCostPosition
   */
  mrcUserGroups?: Array<DealPositionMrcUserGroup>;
  /**
   * Маржинальность к ПлС на позицию соглашения, %.
   * @type {number}
   * @memberof ContractCostPosition
   */
  plsMarginFullCostPct?: number;
  /**
   * Маржинальность к ПлС на позицию соглашения, руб.
   * @type {number}
   * @memberof ContractCostPosition
   */
  plsMarginTotalCost?: number;
  /**
   * Маржинальность к ПлС на единицу, руб.
   * @type {number}
   * @memberof ContractCostPosition
   */
  plsMarginUnitCost?: number;
  /**
   * Маржинальность к цене прайс-листа на позицию соглашения, %.
   * @type {number}
   * @memberof ContractCostPosition
   */
  priceListMarginFullCostPct?: number;
  /**
   * Маржинальность к цене прайс-листа на позицию соглашения, руб.
   * @type {number}
   * @memberof ContractCostPosition
   */
  priceListMarginTotalCost?: number;
  /**
   * Маржинальность к прайс-листа на единицу, руб.
   * @type {number}
   * @memberof ContractCostPosition
   */
  priceListMarginUnitCost?: number;
  /**
   *
   * @type {Product}
   * @memberof ContractCostPosition
   */
  product?: Product;
  /**
   * Артикул
   * @type {string}
   * @memberof ContractCostPosition
   */
  productCode?: string;
  /**
   * Название товара контрагента
   * @type {string}
   * @memberof ContractCostPosition
   */
  productParticipantName?: string;
  /**
   * Стоимость акции на позицию
   * @type {number}
   * @memberof ContractCostPosition
   */
  psTotalCost?: number;
  /**
   * Стоимость акции на продажную единицу
   * @type {number}
   * @memberof ContractCostPosition
   */
  psUnitCost?: number;
  /**
   * Маржинальность к цене закупки на позицию соглашения, %.
   * @type {number}
   * @memberof ContractCostPosition
   */
  purchaseMarginFullCostPct?: number;
  /**
   * Маржинальность к цене закупки на позицию соглашения, руб.
   * @type {number}
   * @memberof ContractCostPosition
   */
  purchaseMarginTotalCost?: number;
  /**
   * Маржинальность к цене закупки на единицу, руб.
   * @type {number}
   * @memberof ContractCostPosition
   */
  purchaseMarginUnitCost?: number;
  /**
   *
   * @type {number}
   * @memberof ContractCostPosition
   */
  quantity?: number;
  /**
   *
   * @type {ContractCostPositionReservation}
   * @memberof ContractCostPosition
   */
  reservation?: ContractCostPositionReservation;
  /**
   * Цена за всю позицию
   * @type {number}
   * @memberof ContractCostPosition
   */
  saleTotalCost?: number;
  /**
   * Стоимость услуг для позиции
   * @type {number}
   * @memberof ContractCostPosition
   */
  servicesTotalCost?: number;
  /**
   * Стоимость услуг на тонну
   * @type {number}
   * @memberof ContractCostPosition
   */
  servicesUnitCost?: number;
  /**
   * Установленная тендерная цена товара
   * @type {number}
   * @memberof ContractCostPosition
   */
  tenderUnitCost?: number;
  /**
   * Маржинальность к МРЦ на позицию соглашения, руб.
   * @type {number}
   * @memberof ContractCostPosition
   */
  totalCostMargin?: number;
  /**
   * Код ЕИ
   * @type {string}
   * @memberof ContractCostPosition
   */
  unitCode?: string;
  /**
   * Маржинальность к МРЦ на единицу, руб.
   * @type {number}
   * @memberof ContractCostPosition
   */
  unitCostMargin?: number;
  /**
   * Цена, которую следует использовать в качестве исходной для расчетов - голая или тендерная.
   * @type {string}
   * @memberof ContractCostPosition
   */
  unitCostType?: ContractCostPositionUnitCostTypeEnum;
  /**
   * Единица измерения
   * @type {string}
   * @memberof ContractCostPosition
   */
  unitShortName?: string;
  /**
   *
   * @type {boolean}
   * @memberof ContractCostPosition
   */
  useManualDeliveryTotalCost?: boolean;
  /**
   * Использовать заданную вручную МРЦ
   * @type {boolean}
   * @memberof ContractCostPosition
   */
  useManualMrcUnitCost?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ContractCostPosition
   */
  useManualServicesTotalCost?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ContractCostPosition
   */
  usePsUnitCost?: boolean;
  /**
   * Код НДС
   * @type {string}
   * @memberof ContractCostPosition
   */
  vatRateCode?: string;
  /**
   * Размер НДС в процентах, действующего на позицию
   * @type {number}
   * @memberof ContractCostPosition
   */
  vatRateValue?: number;
}

export const ContractCostPositionUnitCostTypeEnum = {
  Bare: 'bare',
  Tender: 'tender',
} as const;

export type ContractCostPositionUnitCostTypeEnum =
  (typeof ContractCostPositionUnitCostTypeEnum)[keyof typeof ContractCostPositionUnitCostTypeEnum];

/**
 *
 * @export
 * @interface ContractCostPositionAlternative
 */
export interface ContractCostPositionAlternative {
  /**
   *
   * @type {Array<AvailableProductManufacturer>}
   * @memberof ContractCostPositionAlternative
   */
  manufacturers?: Array<AvailableProductManufacturer>;
  /**
   * Код исходной позиции соглашения, к которой подобраны альтернативы
   * @type {string}
   * @memberof ContractCostPositionAlternative
   */
  positionCode?: string;
}
/**
 *
 * @export
 * @interface ContractCostPositionReservation
 */
export interface ContractCostPositionReservation {
  /**
   * Резерв сформированных счетов по позиции соглашения
   * @type {number}
   * @memberof ContractCostPositionReservation
   */
  billsQuantity?: number;
  /**
   * Резерв оформленных заявок по позиции соглашения
   * @type {number}
   * @memberof ContractCostPositionReservation
   */
  dealsQuantity?: number;
  /**
   * Резерв отгрузок по позиции соглашения
   * @type {number}
   * @memberof ContractCostPositionReservation
   */
  shippedQuantity?: number;
  /**
   * Суммарный резерв по позиции соглашения
   * @type {number}
   * @memberof ContractCostPositionReservation
   */
  usedQuantity?: number;
}
/**
 *
 * @export
 * @interface ContractCostSimplifiedState
 */
export interface ContractCostSimplifiedState {
  /**
   * Уникальный код
   * @type {string}
   * @memberof ContractCostSimplifiedState
   */
  code?: string;
  /**
   * Название
   * @type {string}
   * @memberof ContractCostSimplifiedState
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContractFileTypeItem
 */
export interface ContractFileTypeItem {
  /**
   * Код
   * @type {string}
   * @memberof ContractFileTypeItem
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof ContractFileTypeItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContractFileTypesResponse
 */
export interface ContractFileTypesResponse {
  /**
   *
   * @type {Array<ContractFileTypeItem>}
   * @memberof ContractFileTypesResponse
   */
  types?: Array<ContractFileTypeItem>;
}
/**
 *
 * @export
 * @interface ContractFilesResponse
 */
export interface ContractFilesResponse {
  /**
   *
   * @type {Array<ClientContractFileItem>}
   * @memberof ContractFilesResponse
   */
  files?: Array<ClientContractFileItem>;
}
/**
 *
 * @export
 * @interface ContractFormItem
 */
export interface ContractFormItem {
  /**
   * Код
   * @type {string}
   * @memberof ContractFormItem
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof ContractFormItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContractFormsResponse
 */
export interface ContractFormsResponse {
  /**
   *
   * @type {Array<ContractFormItem>}
   * @memberof ContractFormsResponse
   */
  forms?: Array<ContractFormItem>;
}
/**
 *
 * @export
 * @interface ContractItemFilesUploadResponse
 */
export interface ContractItemFilesUploadResponse {
  /**
   *
   * @type {Array<ClientContractFileItem>}
   * @memberof ContractItemFilesUploadResponse
   */
  files?: Array<ClientContractFileItem>;
}
/**
 *
 * @export
 * @interface ContractItemResendingCreateResponse
 */
export interface ContractItemResendingCreateResponse {
  /**
   *
   * @type {ClientContractResending}
   * @memberof ContractItemResendingCreateResponse
   */
  resending?: ClientContractResending;
}
/**
 *
 * @export
 * @interface ContractKindItem
 */
export interface ContractKindItem {
  /**
   * Код
   * @type {string}
   * @memberof ContractKindItem
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof ContractKindItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContractKindsResponse
 */
export interface ContractKindsResponse {
  /**
   *
   * @type {Array<ContractKindItem>}
   * @memberof ContractKindsResponse
   */
  kinds?: Array<ContractKindItem>;
}
/**
 *
 * @export
 * @interface ContractRenewalTypeItem
 */
export interface ContractRenewalTypeItem {
  /**
   * Код
   * @type {string}
   * @memberof ContractRenewalTypeItem
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof ContractRenewalTypeItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContractRenewalTypesResponse
 */
export interface ContractRenewalTypesResponse {
  /**
   *
   * @type {Array<ContractRenewalTypeItem>}
   * @memberof ContractRenewalTypesResponse
   */
  types?: Array<ContractRenewalTypeItem>;
}
/**
 *
 * @export
 * @interface ContractSignerDocumentTypeItem
 */
export interface ContractSignerDocumentTypeItem {
  /**
   * Код
   * @type {string}
   * @memberof ContractSignerDocumentTypeItem
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof ContractSignerDocumentTypeItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContractSignerDocumentTypesResponse
 */
export interface ContractSignerDocumentTypesResponse {
  /**
   *
   * @type {Array<ContractSignerDocumentTypeItem>}
   * @memberof ContractSignerDocumentTypesResponse
   */
  types?: Array<ContractSignerDocumentTypeItem>;
}
/**
 *
 * @export
 * @interface ContractSignerTypeItem
 */
export interface ContractSignerTypeItem {
  /**
   * Код
   * @type {string}
   * @memberof ContractSignerTypeItem
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof ContractSignerTypeItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContractSignerTypesResponse
 */
export interface ContractSignerTypesResponse {
  /**
   *
   * @type {Array<ContractSignerTypeItem>}
   * @memberof ContractSignerTypesResponse
   */
  types?: Array<ContractSignerTypeItem>;
}
/**
 *
 * @export
 * @interface ContractStatusItem
 */
export interface ContractStatusItem {
  /**
   * Код
   * @type {string}
   * @memberof ContractStatusItem
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof ContractStatusItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContractStatusesResponse
 */
export interface ContractStatusesResponse {
  /**
   *
   * @type {Array<ContractStatusItem>}
   * @memberof ContractStatusesResponse
   */
  statuses?: Array<ContractStatusItem>;
}
/**
 *
 * @export
 * @interface ContractTypeItem
 */
export interface ContractTypeItem {
  /**
   * Код
   * @type {string}
   * @memberof ContractTypeItem
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof ContractTypeItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ContractTypesResponse
 */
export interface ContractTypesResponse {
  /**
   *
   * @type {Array<ContractTypeItem>}
   * @memberof ContractTypesResponse
   */
  types?: Array<ContractTypeItem>;
}
/**
 *
 * @export
 * @interface ContractsItemDraftCreateResponse
 */
export interface ContractsItemDraftCreateResponse {
  /**
   *
   * @type {ClientContract}
   * @memberof ContractsItemDraftCreateResponse
   */
  contract?: ClientContract;
}
/**
 *
 * @export
 * @interface ContractsItemDraftLookupResponse
 */
export interface ContractsItemDraftLookupResponse {
  /**
   *
   * @type {ClientContract}
   * @memberof ContractsItemDraftLookupResponse
   */
  contract?: ClientContract;
}
/**
 *
 * @export
 * @interface ContractsItemDraftSendingCreateResponse
 */
export interface ContractsItemDraftSendingCreateResponse {
  /**
   *
   * @type {ClientContractSending}
   * @memberof ContractsItemDraftSendingCreateResponse
   */
  sending?: ClientContractSending;
}
/**
 *
 * @export
 * @interface ContractsItemDraftSendingListResponse
 */
export interface ContractsItemDraftSendingListResponse {
  /**
   *
   * @type {Array<ClientContractSending>}
   * @memberof ContractsItemDraftSendingListResponse
   */
  sendings?: Array<ClientContractSending>;
}
/**
 *
 * @export
 * @interface ContractsItemDraftUpdateRequest
 */
export interface ContractsItemDraftUpdateRequest {
  /**
   * Вид соглашения договора
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  agreementTypeCode?: string | null;
  /**
   * На основании договора
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  baseContractCode?: string | null;
  /**
   * Комментарий договора
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  comment?: string | null;
  /**
   * Код банковского реквизита компании
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  companyPaymentDetailCode?: string | null;
  /**
   * Сумма договора
   * @type {number}
   * @memberof ContractsItemDraftUpdateRequest
   */
  contractSum?: number | null;
  /**
   * deprecated. тип договора должен задаваться при создании черновика. Вид договора (Договор на реализацию металла с отсрочкой и тд)
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  contractTypeCode?: string | null;
  /**
   * Код валюты
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  currencyCode?: string | null;
  /**
   * Дата заведения договора
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  date?: string | null;
  /**
   * Описание договора
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  description?: string | null;
  /**
   * Форма договора
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  formCode?: string | null;
  /**
   * Признак госконтракта
   * @type {boolean}
   * @memberof ContractsItemDraftUpdateRequest
   */
  isGovernment?: boolean | null;
  /**
   * Бессрочный договор
   * @type {boolean}
   * @memberof ContractsItemDraftUpdateRequest
   */
  isPerpetual?: boolean | null;
  /**
   * Вид договора (с покупателем, с поставщиком, прочее)
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  kindCode?: string | null;
  /**
   * Номер договора
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  number?: string | null;
  /**
   * Тип пролонгации договора
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  renewalTypeCode?: string | null;
  /**
   * Сбросить дату заведения договора
   * @type {boolean}
   * @memberof ContractsItemDraftUpdateRequest
   */
  resetDate?: boolean | null;
  /**
   * Код подписанта
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  signerContactCode?: string | null;
  /**
   * Дата документа подписанта
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  signerDocumentDate?: string | null;
  /**
   * Номер документа подписанта
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  signerDocumentNumber?: string | null;
  /**
   * Код типа документа подписанта
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  signerDocumentTypeCode?: string | null;
  /**
   * Должность подписанта
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  signerPostName?: string | null;
  /**
   * Дата начала действия договора
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  validFrom?: string | null;
  /**
   * Дата окончания действия договора
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  validUntil?: string | null;
  /**
   * НДС включена в сумму
   * @type {boolean}
   * @memberof ContractsItemDraftUpdateRequest
   */
  vatIsIncluded?: boolean | null;
  /**
   * НДС
   * @type {string}
   * @memberof ContractsItemDraftUpdateRequest
   */
  vatRateCode?: string | null;
}
/**
 *
 * @export
 * @interface ContractsItemDraftUpdateResponse
 */
export interface ContractsItemDraftUpdateResponse {
  /**
   *
   * @type {ClientContract}
   * @memberof ContractsItemDraftUpdateResponse
   */
  contract?: ClientContract;
}
/**
 *
 * @export
 * @interface ContractsResponse
 */
export interface ContractsResponse {
  /**
   *
   * @type {Array<ClientContract>}
   * @memberof ContractsResponse
   */
  contracts?: Array<ClientContract>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof ContractsResponse
   */
  contractsBranchOffices?: Array<BranchOffice>;
  /**
   *
   * @type {number}
   * @memberof ContractsResponse
   */
  contractsTotalCount?: number;
}
/**
 * Корректировка по отгрузке
 * @export
 * @interface CorrectionShipment
 */
export interface CorrectionShipment {
  /**
   * УПД - универсальный передаточный документ
   * @type {string}
   * @memberof CorrectionShipment
   */
  bbNumber?: string;
  /**
   * Дата формирования счета
   * @type {string}
   * @memberof CorrectionShipment
   */
  billDocumentDate?: string;
  /**
   * Код счета, если известен
   * @type {string}
   * @memberof CorrectionShipment
   */
  billDocumentNumber?: string;
  /**
   * Код филиала
   * @type {string}
   * @memberof CorrectionShipment
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipment
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipment
   */
  correctionCode?: string;
  /**
   * Код сделки, если известен
   * @type {string}
   * @memberof CorrectionShipment
   */
  dealCode?: string;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipment
   */
  dealCreatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipment
   */
  dealSubmittedAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof CorrectionShipment
   */
  distributorEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipment
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipment
   */
  documentNumber?: string;
  /**
   * Документ в статусе удален
   * @type {boolean}
   * @memberof CorrectionShipment
   */
  isDeleted?: boolean;
  /**
   *
   * @type {Array<CorrectionShipmentPosition>}
   * @memberof CorrectionShipment
   */
  positions?: Array<CorrectionShipmentPosition>;
  /**
   * Общая стоимость позиций
   * @type {number}
   * @memberof CorrectionShipment
   */
  positionsTotalCost?: number;
  /**
   * Общий вес позиций, кг
   * @type {number}
   * @memberof CorrectionShipment
   */
  positionsTotalWeight?: number;
  /**
   *
   * @type {Customer}
   * @memberof CorrectionShipment
   */
  recipientCustomer?: Customer;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipment
   */
  shipmentCode?: string;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipment
   */
  shipmentDate?: string;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipment
   */
  shipmentDocumentNumber?: string;
  /**
   * Время отгрузки, если известно
   * @type {string}
   * @memberof CorrectionShipment
   */
  shipmentTime?: string;
  /**
   *
   * @type {Warehouse}
   * @memberof CorrectionShipment
   */
  warehouse?: Warehouse;
}
/**
 *
 * @export
 * @interface CorrectionShipmentBranchOffice
 */
export interface CorrectionShipmentBranchOffice {
  /**
   * Код филиала
   * @type {string}
   * @memberof CorrectionShipmentBranchOffice
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipmentBranchOffice
   */
  name?: string;
}
/**
 * Позиция отгрузки
 * @export
 * @interface CorrectionShipmentPosition
 */
export interface CorrectionShipmentPosition {
  /**
   * Карточка (код партии)
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  card?: string;
  /**
   * Кассета
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  cassette?: string;
  /**
   * Название файла сертификата
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  certificateName?: string;
  /**
   * Стоимость доставки
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  deliveryCost?: number;
  /**
   * Код внешнего дефекта
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  illiquidDefectCode?: string;
  /**
   * Название вида дефектов
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  illiquidDefectTitle?: string;
  /**
   * Код степени ржавчины
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  illiquidRustCode?: string;
  /**
   * Название степени ржавчины
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  illiquidRustTitle?: string;
  /**
   *
   * @type {boolean}
   * @memberof CorrectionShipmentPosition
   */
  isService?: boolean;
  /**
   * Код номенклатуры
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  nomenclatureCode?: string;
  /**
   *
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  number?: number;
  /**
   * Пачка
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  packNumber?: string;
  /**
   *
   * @type {Product}
   * @memberof CorrectionShipmentPosition
   */
  product?: Product;
  /**
   * Артикул
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  productCode?: string;
  /**
   * Название товара контрагента
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  productParticipantName?: string;
  /**
   * Количество в указанных единицах измерения
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  quantityOld?: number | null;
  /**
   * Вес в тоннах
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  quantityTne?: number;
  /**
   * Стоимость услуг (включая доставку)
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  serviceCost?: number;
  /**
   *
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  title?: string;
  /**
   * Цена за всю позицию
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  totalCost?: number;
  /**
   *
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  totalCostOld?: number | null;
  /**
   * Код единицы измерения
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  unitCode?: string;
  /**
   * Цена за единицу
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  unitCost?: number;
  /**
   *
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  unitCostOld?: number | null;
  /**
   * Единица измерения
   * @type {string}
   * @memberof CorrectionShipmentPosition
   */
  unitShortName?: string;
  /**
   * НДС, руб
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  vatCost?: number;
  /**
   *
   * @type {number}
   * @memberof CorrectionShipmentPosition
   */
  vatCostOld?: number | null;
}
/**
 *
 * @export
 * @interface CorrectionShipmentsRequest
 */
export interface CorrectionShipmentsRequest {
  /**
   * Код счета
   * @type {string}
   * @memberof CorrectionShipmentsRequest
   */
  billCode?: string;
  /**
   * Коды филиалов
   * @type {Array<string>}
   * @memberof CorrectionShipmentsRequest
   */
  branchOfficeCodes?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof CorrectionShipmentsRequest
   */
  count?: number;
  /**
   * Коды клиентов
   * @type {Array<string>}
   * @memberof CorrectionShipmentsRequest
   */
  customerCodes?: Array<string>;
  /**
   * Дата от
   * @type {string}
   * @memberof CorrectionShipmentsRequest
   */
  dateFrom?: string | null;
  /**
   * Дата до
   * @type {string}
   * @memberof CorrectionShipmentsRequest
   */
  dateTo?: string | null;
  /**
   * Код заявки на счет
   * @type {string}
   * @memberof CorrectionShipmentsRequest
   */
  dealCode?: string;
  /**
   * Номер документа
   * @type {string}
   * @memberof CorrectionShipmentsRequest
   */
  documentNumber?: string;
  /**
   * Код сотрудника или группы
   * @type {string}
   * @memberof CorrectionShipmentsRequest
   */
  employeeSet?: string | null;
  /**
   * Год и месяц
   * @type {string}
   * @memberof CorrectionShipmentsRequest
   */
  month?: string | null;
  /**
   *
   * @type {number}
   * @memberof CorrectionShipmentsRequest
   */
  page?: number;
  /**
   * filter for correction shipments
   * @type {string}
   * @memberof CorrectionShipmentsRequest
   */
  query?: string | null;
  /**
   * Код отгрузки
   * @type {string}
   * @memberof CorrectionShipmentsRequest
   */
  shipmentCode?: string;
}
/**
 *
 * @export
 * @interface CorrectionShipmentsResponse
 */
export interface CorrectionShipmentsResponse {
  /**
   *
   * @type {Array<CorrectionShipment>}
   * @memberof CorrectionShipmentsResponse
   */
  corrections?: Array<CorrectionShipment>;
  /**
   *
   * @type {Array<CorrectionShipmentBranchOffice>}
   * @memberof CorrectionShipmentsResponse
   */
  correctionsBranchOffices?: Array<CorrectionShipmentBranchOffice>;
  /**
   * Общая сумма, руб.
   * @type {number}
   * @memberof CorrectionShipmentsResponse
   */
  correctionsTotalCost?: number;
  /**
   *
   * @type {number}
   * @memberof CorrectionShipmentsResponse
   */
  correctionsTotalCount?: number;
  /**
   * Общий вес, кг.
   * @type {number}
   * @memberof CorrectionShipmentsResponse
   */
  correctionsTotalWeight?: number;
}
/**
 *
 * @export
 * @interface CountriesResponse
 */
export interface CountriesResponse {
  /**
   *
   * @type {Array<Country>}
   * @memberof CountriesResponse
   */
  countries?: Array<Country>;
}
/**
 *
 * @export
 * @interface Country
 */
export interface Country {
  /**
   * Двухбуквенный код
   * @type {string}
   * @memberof Country
   */
  code?: string;
  /**
   * Трёхбуквенный код по ОКСМ
   * @type {string}
   * @memberof Country
   */
  code3?: string;
  /**
   * Название
   * @type {string}
   * @memberof Country
   */
  name?: string;
  /**
   * Короткое название
   * @type {string}
   * @memberof Country
   */
  shortName?: string;
}
/**
 *
 * @export
 * @interface CreditLimitDocument
 */
export interface CreditLimitDocument {
  /**
   * Разрешенный лимит
   * @type {number}
   * @memberof CreditLimitDocument
   */
  allowedSum?: number;
  /**
   *
   * @type {string}
   * @memberof CreditLimitDocument
   */
  approvalDate?: string;
  /**
   *
   * @type {string}
   * @memberof CreditLimitDocument
   */
  customerCode?: string;
  /**
   * Срок отсрочки
   * @type {number}
   * @memberof CreditLimitDocument
   */
  delayDays?: number;
  /**
   *
   * @type {string}
   * @memberof CreditLimitDocument
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof CreditLimitDocument
   */
  validUntil?: string;
}
/**
 *
 * @export
 * @interface CreditState
 */
export interface CreditState {
  /**
   * Разрешенный лимит
   * @type {number}
   * @memberof CreditState
   */
  allowedSum?: number;
  /**
   * Дата подтверждения кредитного лимита
   * @type {string}
   * @memberof CreditState
   */
  approvalDate?: string | null;
  /**
   * Свободный остаток лимита
   * @type {number}
   * @memberof CreditState
   */
  availableSum?: number;
  /**
   *
   * @type {string}
   * @memberof CreditState
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof CreditState
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof CreditState
   */
  customerCode?: string;
  /**
   * Срок отсрочки
   * @type {number}
   * @memberof CreditState
   */
  delayDays?: number;
  /**
   * Номер действующего документа лимит-кредит
   * @type {string}
   * @memberof CreditState
   */
  limitDocNumber?: string;
  /**
   * Дата, от которой считается просрочка
   * @type {string}
   * @memberof CreditState
   */
  overdueDate?: string | null;
  /**
   * Количество дней просрочки
   * @type {number}
   * @memberof CreditState
   */
  overdueDays?: number;
  /**
   * Сумма просрочено (ПДЗ)
   * @type {number}
   * @memberof CreditState
   */
  overdueSum?: number;
  /**
   * Сумма не оплачено
   * @type {number}
   * @memberof CreditState
   */
  unpaidSum?: number;
  /**
   * Дата актуализации состояния
   * @type {string}
   * @memberof CreditState
   */
  updatedAt?: string | null;
  /**
   * Дата завершения действия документа лимит-кредит
   * @type {string}
   * @memberof CreditState
   */
  validUntil?: string | null;
}
/**
 *
 * @export
 * @interface Currencies
 */
export interface Currencies {
  /**
   * Код
   * @type {string}
   * @memberof Currencies
   */
  code?: string;
  /**
   * Название
   * @type {string}
   * @memberof Currencies
   */
  name?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof Currencies
   */
  title?: string;
}
/**
 *
 * @export
 * @interface CurrenciesResponse
 */
export interface CurrenciesResponse {
  /**
   *
   * @type {Array<Currencies>}
   * @memberof CurrenciesResponse
   */
  currencies?: Array<Currencies>;
}
/**
 * Клиент-покупатель
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  activityStatusCode?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  activityStatusTitle?: string;
  /**
   *
   * @type {Array<Address>}
   * @memberof Customer
   */
  addresses?: Array<Address>;
  /**
   *
   * @type {Array<CustomerAttention>}
   * @memberof Customer
   */
  attentions?: Array<CustomerAttention>;
  /**
   *
   * @type {Array<CustomerBranchOfficeManager>}
   * @memberof Customer
   */
  branchOfficeManagers?: Array<CustomerBranchOfficeManager>;
  /**
   * Возможно создание договора по клиенту
   * @type {boolean}
   * @memberof Customer
   */
  canSaveContract?: boolean;
  /**
   * Возможно создание заявки по клиенту
   * @type {boolean}
   * @memberof Customer
   */
  canSaveDeal?: boolean;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  code?: string;
  /**
   *
   * @type {Array<CompanyEmail>}
   * @memberof Customer
   */
  companyEmails?: Array<CompanyEmail>;
  /**
   * Дата основания компании
   * @type {string}
   * @memberof Customer
   */
  companyFoundedDate?: string | null;
  /**
   * КПП
   * @type {string}
   * @memberof Customer
   */
  companyKpp?: string;
  /**
   * Дата ликвидации компании
   * @type {string}
   * @memberof Customer
   */
  companyLiquidatedDate?: string | null;
  /**
   * ОГРН
   * @type {string}
   * @memberof Customer
   */
  companyOgrn?: string;
  /**
   * ОКОПФ
   * @type {string}
   * @memberof Customer
   */
  companyOkopf?: string;
  /**
   * ОКПО
   * @type {string}
   * @memberof Customer
   */
  companyOkpo?: string;
  /**
   * ОКВЭД
   * @type {Array<string>}
   * @memberof Customer
   */
  companyOkved?: Array<string>;
  /**
   *
   * @type {Array<CompanyPaymentDetails>}
   * @memberof Customer
   */
  companyPaymentDetails?: Array<CompanyPaymentDetails>;
  /**
   *
   * @type {Array<CompanyPhone>}
   * @memberof Customer
   */
  companyPhones?: Array<CompanyPhone>;
  /**
   *
   * @type {Array<CompanySite>}
   * @memberof Customer
   */
  companySites?: Array<CompanySite>;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  contactEmail?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  contactPersonName?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  contactPersonPatronymic?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  contactPersonSurname?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  contactPhone?: string;
  /**
   * Валюта в которой работает покупатель
   * @type {string}
   * @memberof Customer
   */
  contractCurrency?: string;
  /**
   * Двухбуквенный код страны
   * @type {string}
   * @memberof Customer
   */
  countryCode?: string;
  /**
   * Страна
   * @type {string}
   * @memberof Customer
   */
  countryName?: string;
  /**
   * Короткое название страны
   * @type {string}
   * @memberof Customer
   */
  countryShortName?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  createdAt?: string;
  /**
   *
   * @type {CreditState}
   * @memberof Customer
   */
  creditState?: CreditState;
  /**
   *
   * @type {Array<CustomerCompetitor>}
   * @memberof Customer
   */
  customerCompetitors?: Array<CustomerCompetitor>;
  /**
   * Статус участия во внешней системе СЭД (deprecated use docFlowState.Code)
   * @type {string}
   * @memberof Customer
   */
  docFlowCounterAgentState?: CustomerDocFlowCounterAgentStateEnum;
  /**
   *
   * @type {ClientDocFlowState}
   * @memberof Customer
   */
  docFlowState?: ClientDocFlowState;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  frequencySegmentCode?: string;
  /**
   * Есть write-доступ к клиенту
   * @type {boolean}
   * @memberof Customer
   */
  hasWriteAccess?: boolean;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  inn?: string;
  /**
   * Дата последнего взаимодействия
   * @type {string}
   * @memberof Customer
   */
  lastInteractionDate?: string | null;
  /**
   * Дата последней заявки
   * @type {string}
   * @memberof Customer
   */
  lastOrderDate?: string | null;
  /**
   * Статус последней активной заявки (код)
   * @type {string}
   * @memberof Customer
   */
  lastSendingStatusCode?: string;
  /**
   * Статус последней активной заявки (комментарий к статусу)
   * @type {string}
   * @memberof Customer
   */
  lastSendingStatusComment?: string;
  /**
   * Статус последней активной заявки (название)
   * @type {string}
   * @memberof Customer
   */
  lastSendingStatusTitle?: string;
  /**
   * Дата последней отгрузки
   * @type {string}
   * @memberof Customer
   */
  lastShipmentDate?: string | null;
  /**
   * Дата последней отгрузки в филиале
   * @type {string}
   * @memberof Customer
   */
  lastShipmentDateBranchOffice?: string | null;
  /**
   *
   * @type {Employee}
   * @memberof Customer
   */
  mainEmployee?: Employee;
  /**
   * Дата прикрепления клиента к текущему менеджеру
   * @type {string}
   * @memberof Customer
   */
  mainEmployeeJoinDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  monetarySegmentCode?: string;
  /**
   *
   * @type {Employee}
   * @memberof Customer
   */
  monthEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  monthEmployeeJoinDate?: string | null;
  /**
   * Не резидент
   * @type {boolean}
   * @memberof Customer
   */
  nonResident?: boolean;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  occupationCode?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  occupationSpecifiedCode?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  occupationSpecifiedTitle?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  occupationTitle?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  participantTypeCode?: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  participantTypeName?: string;
  /**
   * Общий вес согласованных заявок за 30 дней
   * @type {number}
   * @memberof Customer
   */
  prevAgreedTotalWeight?: number;
  /**
   * ЕИ общего веса
   * @type {string}
   * @memberof Customer
   */
  prevAgreedTotalWeightUnit?: string;
  /**
   * Позиция в рейтинге по потреблению (общее)
   * @type {number}
   * @memberof Customer
   */
  ratingPositionConsumption?: number | null;
  /**
   * Позиция в рейтинге по потреблению (в филиале инициатора)
   * @type {number}
   * @memberof Customer
   */
  ratingPositionConsumptionBranchOffice?: number | null;
  /**
   * Позиция в рейтинге по продажам (общее)
   * @type {number}
   * @memberof Customer
   */
  ratingPositionSale?: number | null;
  /**
   * Позиция в рейтинге по продажам (в филиале инициатора)
   * @type {number}
   * @memberof Customer
   */
  ratingPositionSaleBranchOffice?: number | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  recencySegmentCode?: string;
  /**
   *
   * @type {Array<ParticipantRelationship>}
   * @memberof Customer
   */
  relationships?: Array<ParticipantRelationship>;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  segmentCode?: string;
  /**
   *
   * @type {Array<ClientLeadsSendingItem>}
   * @memberof Customer
   */
  sendings?: Array<ClientLeadsSendingItem>;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  shipmentSegmentCode?: string;
  /**
   * Краткое наименование покупателя
   * @type {string}
   * @memberof Customer
   */
  shortTitle?: string;
  /**
   * Источник информации о клиенте (код)
   * @type {string}
   * @memberof Customer
   */
  sourceCode?: string;
  /**
   * Источник информации о клиенте нельзя изменить
   * @type {boolean}
   * @memberof Customer
   */
  sourceReadOnly?: boolean;
  /**
   * Источник информации о клиенте (название)
   * @type {string}
   * @memberof Customer
   */
  sourceTitle?: string;
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  statAbsoluteMargin?: number;
  /**
   * Количество активных сделок в текущем периоде
   * @type {number}
   * @memberof Customer
   */
  statActiveDealsCount?: number;
  /**
   * Количество активных заявок в текущем периоде
   * @type {number}
   * @memberof Customer
   */
  statActiveOrdersCount?: number;
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  statAvgAbsoluteMargin?: number;
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  statAvgWeight?: number;
  /**
   * Количество сделок в текущем периоде
   * @type {number}
   * @memberof Customer
   */
  statCurrentDealsCount?: number;
  /**
   * Количество заявок в текущем периоде
   * @type {number}
   * @memberof Customer
   */
  statCurrentOrdersCount?: number;
  /**
   * Рейтинг клиента по цвету за период
   * @type {string}
   * @memberof Customer
   */
  statCustomerRating?: string;
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  statMarginalityPct?: number;
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  statTotalWeight?: number;
  /**
   * ЕИ веса
   * @type {string}
   * @memberof Customer
   */
  statWeightUnit?: string;
  /**
   * Полное наименование покупателя
   * @type {string}
   * @memberof Customer
   */
  title?: string;
}

export const CustomerDocFlowCounterAgentStateEnum = {
  Inactive: 'inactive',
  Active: 'active',
  Invited: 'invited',
} as const;

export type CustomerDocFlowCounterAgentStateEnum =
  (typeof CustomerDocFlowCounterAgentStateEnum)[keyof typeof CustomerDocFlowCounterAgentStateEnum];

/**
 * Месячная статистика по клиенту
 * @export
 * @interface CustomerAgreementsReport
 */
export interface CustomerAgreementsReport {
  /**
   *
   * @type {string}
   * @memberof CustomerAgreementsReport
   */
  customerCode?: string;
  /**
   *
   * @type {AgreementsReport}
   * @memberof CustomerAgreementsReport
   */
  report?: AgreementsReport;
}
/**
 * Покупатель в качестве фильтра
 * @export
 * @interface CustomerAsFilter
 */
export interface CustomerAsFilter {
  /**
   * Код покупателя
   * @type {string}
   * @memberof CustomerAsFilter
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerAsFilter
   */
  title?: string;
}
/**
 * Напоминание по клиенту
 * @export
 * @interface CustomerAttention
 */
export interface CustomerAttention {
  /**
   * Код напоминания по клиенту
   * @type {string}
   * @memberof CustomerAttention
   */
  code?: string;
  /**
   * Дата создания
   * @type {string}
   * @memberof CustomerAttention
   */
  createdAt?: string;
  /**
   *
   * @type {Array<CustomerAttentionLog>}
   * @memberof CustomerAttention
   */
  logs?: Array<CustomerAttentionLog>;
  /**
   * Код причины напоминания
   * @type {string}
   * @memberof CustomerAttention
   */
  reasonCode?: string;
  /**
   * Причина напоминания
   * @type {string}
   * @memberof CustomerAttention
   */
  reasonTitle?: string;
  /**
   * Дата изменения
   * @type {string}
   * @memberof CustomerAttention
   */
  updatedAt?: string;
  /**
   * Дата до которой напоминание активно
   * @type {string}
   * @memberof CustomerAttention
   */
  validUntil?: string;
}
/**
 * Лог изменения напоминания по клиенту
 * @export
 * @interface CustomerAttentionLog
 */
export interface CustomerAttentionLog {
  /**
   * Код лога
   * @type {string}
   * @memberof CustomerAttentionLog
   */
  code?: string;
  /**
   * Дата создания
   * @type {string}
   * @memberof CustomerAttentionLog
   */
  createdAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof CustomerAttentionLog
   */
  initiatorEmployee?: Employee;
  /**
   *
   * @type {ClientsAttentionsReasonItem}
   * @memberof CustomerAttentionLog
   */
  newReason?: ClientsAttentionsReasonItem;
  /**
   * Дата до которой напоминание активно
   * @type {string}
   * @memberof CustomerAttentionLog
   */
  newValidUntil?: string | null;
  /**
   *
   * @type {ClientsAttentionsReasonItem}
   * @memberof CustomerAttentionLog
   */
  oldReason?: ClientsAttentionsReasonItem;
  /**
   * Прошлая дата до которой напоминание активно
   * @type {string}
   * @memberof CustomerAttentionLog
   */
  oldValidUntil?: string | null;
  /**
   * Код типа лога
   * @type {string}
   * @memberof CustomerAttentionLog
   */
  typeCode?: string;
  /**
   * Заголовок типа лога
   * @type {string}
   * @memberof CustomerAttentionLog
   */
  typeTitle?: string;
}
/**
 *
 * @export
 * @interface CustomerBranchOfficeManager
 */
export interface CustomerBranchOfficeManager {
  /**
   *
   * @type {string}
   * @memberof CustomerBranchOfficeManager
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerBranchOfficeManager
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerBranchOfficeManager
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerBranchOfficeManager
   */
  employeeTitle?: string;
}
/**
 *
 * @export
 * @interface CustomerCompetitor
 */
export interface CustomerCompetitor {
  /**
   *
   * @type {string}
   * @memberof CustomerCompetitor
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerCompetitor
   */
  joinedAt?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerCompetitor
   */
  title?: string;
}
/**
 * Месячная статистика по клиенту
 * @export
 * @interface CustomerMarginalityReport
 */
export interface CustomerMarginalityReport {
  /**
   *
   * @type {string}
   * @memberof CustomerMarginalityReport
   */
  customerCode?: string;
  /**
   *
   * @type {MarginalityReport}
   * @memberof CustomerMarginalityReport
   */
  report?: MarginalityReport;
}
/**
 * Месячная статистика по клиенту сотрудника
 * @export
 * @interface CustomerMotivationReport
 */
export interface CustomerMotivationReport {
  /**
   *
   * @type {string}
   * @memberof CustomerMotivationReport
   */
  customerCode?: string;
  /**
   *
   * @type {MotivationReport}
   * @memberof CustomerMotivationReport
   */
  report?: MotivationReport;
}
/**
 *
 * @export
 * @interface CustomerNotDistributedStatItem
 */
export interface CustomerNotDistributedStatItem {
  /**
   *
   * @type {string}
   * @memberof CustomerNotDistributedStatItem
   */
  customerCode?: string;
  /**
   *
   * @type {number}
   * @memberof CustomerNotDistributedStatItem
   */
  notDistributedAmount?: number;
}
/**
 *
 * @export
 * @interface Deal
 */
export interface Deal {
  /**
   *
   * @type {DealApproval}
   * @memberof Deal
   */
  approval?: DealApproval;
  /**
   * Доступные ставки НДС
   * @type {Array<VatRate>}
   * @memberof Deal
   */
  availableVatRates?: Array<VatRate>;
  /**
   * Доступно создание счёта по сделке (определяется в том числе настройками филиала).
   * @type {boolean}
   * @memberof Deal
   */
  billCreateFromDealEnabled?: boolean;
  /**
   * Дата документа счёта (при наличии)
   * @type {string}
   * @memberof Deal
   */
  billDocumentDate?: string;
  /**
   * Номер документа счёта (при наличии)
   * @type {string}
   * @memberof Deal
   */
  billDocumentNumber?: string;
  /**
   * Существует счет с подчиненным документом. Это блокирует согласование.
   * @type {boolean}
   * @memberof Deal
   */
  billHasSubordinateDoc?: boolean;
  /**
   * Счёт действителен до (при наличии)
   * @type {string}
   * @memberof Deal
   */
  billValidUntil?: string;
  /**
   * Код офиса, где оформлена заявка
   * @type {string}
   * @memberof Deal
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  code?: string;
  /**
   * Список комментариев сделки
   * @type {Array<DealComment>}
   * @memberof Deal
   */
  comments?: Array<DealComment>;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  consigneeCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  consigneeTitle?: string | null;
  /**
   *
   * @type {ClientContract}
   * @memberof Deal
   */
  contract?: ClientContract;
  /**
   *
   * @type {Array<ContractCostAgreement>}
   * @memberof Deal
   */
  contractCostAgreements?: Array<ContractCostAgreement>;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  createdAt?: string;
  /**
   *
   * @type {DealDistributor}
   * @memberof Deal
   */
  creator?: DealDistributor;
  /**
   * Стоимость отсрочки платежа. Сумма, на которую увеличится стоимость сделки за отсрочку оплаты.
   * @type {number}
   * @memberof Deal
   */
  creditCost?: number;
  /**
   * Валюта
   * @type {string}
   * @memberof Deal
   */
  currency?: string;
  /**
   *
   * @type {Customer}
   * @memberof Deal
   */
  customer?: Customer;
  /**
   * Количество дней резерва остатков по-умолчанию
   * @type {number}
   * @memberof Deal
   */
  defaultBillValidDays?: number;
  /**
   *
   * @type {Address}
   * @memberof Deal
   */
  deliveryAddress?: Address;
  /**
   * Дополнительная наценка на доставку, за количество позиций
   * @type {number}
   * @memberof Deal
   */
  deliveryMarkup?: number;
  /**
   * Код типа доставки
   * @type {string}
   * @memberof Deal
   */
  deliveryType?: string;
  /**
   * Название типа доставки
   * @type {string}
   * @memberof Deal
   */
  deliveryTypeName?: string;
  /**
   *
   * @type {DealDistributor}
   * @memberof Deal
   */
  distributor?: DealDistributor;
  /**
   *
   * @type {Array<DealDocument>}
   * @memberof Deal
   */
  documents?: Array<DealDocument>;
  /**
   *
   * @type {Array<DealEditingDisableReason>}
   * @memberof Deal
   */
  editingDisableReasons?: Array<DealEditingDisableReason>;
  /**
   *
   * @type {boolean}
   * @memberof Deal
   */
  editingEnabled?: boolean;
  /**
   * Маржинальность по рыночным ценам, руб.
   * @type {number}
   * @memberof Deal
   */
  globalMarketMarginality?: number;
  /**
   * Маржинальность по рыночным ценам, %
   * @type {number}
   * @memberof Deal
   */
  globalMarketMarginalityPct?: number;
  /**
   * Документ в статусе удален
   * @type {boolean}
   * @memberof Deal
   */
  isDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  lastSubmittedAt?: string | null;
  /**
   *
   * @type {DealLifting}
   * @memberof Deal
   */
  lifting?: DealLifting;
  /**
   * Доход МОП от маржи
   * @type {number}
   * @memberof Deal
   */
  managerProfitMarga?: number;
  /**
   * Доход МОП от объема
   * @type {number}
   * @memberof Deal
   */
  managerProfitVolume?: number;
  /**
   * Установленное вручную количество дней резерва остатков
   * @type {number}
   * @memberof Deal
   */
  manualBillValidDays?: number;
  /**
   * Установить стоимость кредита
   * @type {number}
   * @memberof Deal
   */
  manualCreditCost?: number;
  /**
   * Установить стоимость доставки
   * @type {number}
   * @memberof Deal
   */
  manualDeliveryCost?: number;
  /**
   * Заданное расстояние для расчета доставки
   * @type {number}
   * @memberof Deal
   */
  manualDeliveryDistanceKm?: number;
  /**
   * Заданное количество транспортных единиц
   * @type {number}
   * @memberof Deal
   */
  manualDeliveryUnitsCount?: number;
  /**
   * Заданная стоимость кредита
   * @type {number}
   * @memberof Deal
   */
  manualServicesCost?: number;
  /**
   * Маржинальность к ценам заявки, руб.
   * @type {number}
   * @memberof Deal
   */
  marginality?: number;
  /**
   * Маржинальность к ценам заявки, %
   * @type {number}
   * @memberof Deal
   */
  marginalityPct?: number;
  /**
   *
   * @type {DealMotivation}
   * @memberof Deal
   */
  motivation?: DealMotivation;
  /**
   *
   * @type {Employee}
   * @memberof Deal
   */
  motivationEmployee?: Employee;
  /**
   * Счёт будет создан на MX
   * @type {boolean}
   * @memberof Deal
   */
  mxBillStrategy?: boolean;
  /**
   *
   * @type {DealPartners}
   * @memberof Deal
   */
  partners?: DealPartners;
  /**
   *
   * @type {DealPayment}
   * @memberof Deal
   */
  payment?: DealPayment;
  /**
   * Код схемы сделки (если определен)
   * @type {string}
   * @memberof Deal
   */
  paymentSchemaCode?: string | null;
  /**
   * Доступно формирование счета по сделке (определяется настройками филиала).
   * @type {boolean}
   * @memberof Deal
   */
  pdfGenerationEnabled?: boolean;
  /**
   *
   * @type {Array<DealPosition>}
   * @memberof Deal
   */
  positions?: Array<DealPosition> | null;
  /**
   * Сумма для постоплаты
   * @type {number}
   * @memberof Deal
   */
  postpayCost?: number;
  /**
   * Абсолютная маржа итоговой цены к цене прайс-листа за продажную единицу
   * @type {number}
   * @memberof Deal
   */
  priceListCostMargin?: number;
  /**
   * Относительная маржинальность итоговой цены к цене прайс-листа
   * @type {number}
   * @memberof Deal
   */
  priceListCostMarginPct?: number;
  /**
   * Абсолютная маржа к плановой себестоимости
   * @type {number}
   * @memberof Deal
   */
  primeCostMargin?: number;
  /**
   * Относительная маржинальность к плановой себестоимости
   * @type {number}
   * @memberof Deal
   */
  primeCostMarginPct?: number;
  /**
   * Общая стоимость товаров
   * @type {number}
   * @memberof Deal
   */
  productsPrice?: number;
  /**
   * Сумма ПС
   * @type {number}
   * @memberof Deal
   */
  psTotalCost?: number;
  /**
   * Абсолютная маржа к средневзвешенной цене закупки
   * @type {number}
   * @memberof Deal
   */
  purchaseCostMargin?: number;
  /**
   * Относительная маржинальность к средневзвешенной цене закупки
   * @type {number}
   * @memberof Deal
   */
  purchaseCostMarginPct?: number;
  /**
   * Маржинальность по рекомендованной цене, руб.
   * @type {number}
   * @memberof Deal
   */
  recommendedCostMarginality?: number;
  /**
   * Маржинальность по рекомендованной цене, %
   * @type {number}
   * @memberof Deal
   */
  recommendedCostMarginalityPct?: number;
  /**
   * Общая стоимость округления цен позиций
   * @type {number}
   * @memberof Deal
   */
  roundingTotalCost?: number;
  /**
   * Общая стоимость услуг
   * @type {number}
   * @memberof Deal
   */
  servicesPrice?: number;
  /**
   * Итого по отгрузкам, руб.
   * @type {number}
   * @memberof Deal
   */
  shipmentsTotalCost?: number | null;
  /**
   * Итого по отгрузкам, кг
   * @type {number}
   * @memberof Deal
   */
  shipmentsTotalWeight?: number | null;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  stateTitle?: string;
  /**
   *
   * @type {Array<DocumentNotice>}
   * @memberof Deal
   */
  submitNotices?: Array<DocumentNotice>;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  submittedAt?: string;
  /**
   * Общая стоимость сделки
   * @type {number}
   * @memberof Deal
   */
  totalPrice?: number;
  /**
   * Общая стоимость сделки (без НДС)
   * @type {number}
   * @memberof Deal
   */
  totalPriceWoVat?: number;
  /**
   * Общий объем
   * @type {number}
   * @memberof Deal
   */
  totalVolume?: number;
  /**
   * Общий вес, кг
   * @type {number}
   * @memberof Deal
   */
  totalWeight?: number;
  /**
   * ЕИ общего веса
   * @type {string}
   * @memberof Deal
   */
  totalWeightUnit?: string;
  /**
   *
   * @type {DealTransport}
   * @memberof Deal
   */
  transport?: DealTransport;
  /**
   *
   * @type {DealUpdateLog}
   * @memberof Deal
   */
  updateLog?: DealUpdateLog;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  updatedAt?: string;
  /**
   * Использовать установленное вручную количество дней резерва остатков
   * @type {boolean}
   * @memberof Deal
   */
  useManualBillValidDays?: boolean;
  /**
   * Использовать заданную стоимость кредита
   * @type {boolean}
   * @memberof Deal
   */
  useManualCreditCost?: boolean;
  /**
   * Использовать заданную стоимость доставки
   * @type {boolean}
   * @memberof Deal
   */
  useManualDeliveryCost?: boolean;
  /**
   * Использовать заданное расстояние для расчета доставки
   * @type {boolean}
   * @memberof Deal
   */
  useManualDeliveryDistance?: boolean;
  /**
   * Использовать заданное количество транспортных единиц
   * @type {boolean}
   * @memberof Deal
   */
  useManualDeliveryUnits?: boolean;
  /**
   * Использовать заданную стоимость услуг вместо расчетной
   * @type {boolean}
   * @memberof Deal
   */
  useManualServicesCost?: boolean;
  /**
   *
   * @type {string}
   * @memberof Deal
   */
  validUntil?: string;
  /**
   * Код НДС
   * @type {string}
   * @memberof Deal
   */
  vatRateCode?: string;
  /**
   * Размер НДС в процентах, определенного для сделки
   * @type {number}
   * @memberof Deal
   */
  vatRateValue?: number;
  /**
   * Сумма НДС
   * @type {number}
   * @memberof Deal
   */
  vatTotalPrice?: number;
  /**
   *
   * @type {Address}
   * @memberof Deal
   */
  warehouseAddress?: Address;
  /**
   * Склад отгрузки
   * @type {string}
   * @memberof Deal
   */
  warehouseCode?: string;
  /**
   * Название склада отгрузки
   * @type {string}
   * @memberof Deal
   */
  warehouseName?: string;
}
/**
 * Все поля кроме state могут быть пустыми, если заявка подтверждена в другой системе.
 * @export
 * @interface DealApproval
 */
export interface DealApproval {
  /**
   * Код запроса на подтверждение.
   * @type {string}
   * @memberof DealApproval
   */
  askCode?: string;
  /**
   * Дата отправки на согласования.
   * @type {string}
   * @memberof DealApproval
   */
  askDate?: string;
  /**
   * Видимость согласования. Отключено для заявок не с платформы.
   * @type {boolean}
   * @memberof DealApproval
   */
  controlVisible?: boolean;
  /**
   * Группа пользователя, к которой относится текущий пользователь относительно сделки. Может быть не заполнено, если пользователь не имеет никакого доступа.
   * @type {string}
   * @memberof DealApproval
   */
  currentAccessGroup?: string;
  /**
   * Что мешает именно текущему пользователю согласовать заявку
   * @type {Array<DealApprovalBlockReason>}
   * @memberof DealApproval
   */
  currentBlockReasons?: Array<DealApprovalBlockReason>;
  /**
   * Разрешено ли текущему пользователю выносить резолюцию
   * @type {boolean}
   * @memberof DealApproval
   */
  resolutionAccess?: boolean;
  /**
   * Комментарий к резолюции
   * @type {string}
   * @memberof DealApproval
   */
  resolutionComment?: string;
  /**
   * Дата-время установки резолюции. Может быть не заполнено, если резолюция еще не выносилась, или заявка пришла из 1С.
   * @type {string}
   * @memberof DealApproval
   */
  resolutionDate?: string;
  /**
   *
   * @type {string}
   * @memberof DealApproval
   */
  resolutionEmployeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof DealApproval
   */
  resolutionEmployeeName?: string;
  /**
   *
   * @type {string}
   * @memberof DealApproval
   */
  resolutionEmployeePatronymic?: string;
  /**
   *
   * @type {string}
   * @memberof DealApproval
   */
  resolutionEmployeeSurname?: string;
  /**
   * Список групп необходимых для согласования заявки и причины появления группы
   * @type {Array<DealApprovalResolutionGroupReason>}
   * @memberof DealApproval
   */
  resolutionGroupReasons?: Array<DealApprovalResolutionGroupReason>;
  /**
   * Минимально необходимая группа для согласования заявки
   * @type {string}
   * @memberof DealApproval
   */
  resolutionMinimumGroup?: string;
  /**
   * Список групп, которые могут согласовать заявку
   * @type {Array<string>}
   * @memberof DealApproval
   */
  resolutionPossibleGroups?: Array<string>;
  /**
   * Причины запрета самоподписания
   * @type {Array<DealApprovalBlockReason>}
   * @memberof DealApproval
   */
  selfBlockReasons?: Array<DealApprovalBlockReason>;
  /**
   * Возможно подтвердить самому, если нет блокирующих условий
   * @type {boolean}
   * @memberof DealApproval
   */
  selfPossible?: boolean;
  /**
   * Состояние подтверждения
   * @type {string}
   * @memberof DealApproval
   */
  state?: DealApprovalStateEnum;
  /**
   * Состояние подтверждения для отображения
   * @type {string}
   * @memberof DealApproval
   */
  stateTitle?: string;
}

export const DealApprovalStateEnum = {
  Awaiting: 'awaiting',
  Approved: 'approved',
  Declined: 'declined',
  Signed: 'signed',
  AwaitingDepartment: 'awaiting_department',
  AwaitingOffice: 'awaiting_office',
  AwaitingDivision: 'awaiting_division',
  AwaitingCompany: 'awaiting_company',
  SelfApproved: 'self_approved',
} as const;

export type DealApprovalStateEnum = (typeof DealApprovalStateEnum)[keyof typeof DealApprovalStateEnum];

/**
 * Причина блокировки подписания заявки
 * @export
 * @interface DealApprovalBlockReason
 */
export interface DealApprovalBlockReason {
  /**
   * Код типа блокировки
   * @type {string}
   * @memberof DealApprovalBlockReason
   */
  code?: string;
  /**
   * Дополнительные данные для кастомизации сообщения о блокировке
   * @type {object}
   * @memberof DealApprovalBlockReason
   */
  data?: object;
  /**
   * Сообщение блокировки
   * @type {string}
   * @memberof DealApprovalBlockReason
   */
  message?: string;
  /**
   * Код позиции сделки (если проблема в позиции)
   * @type {string}
   * @memberof DealApprovalBlockReason
   */
  positionCode?: string;
  /**
   * Код товара
   * @type {string}
   * @memberof DealApprovalBlockReason
   */
  productCode?: string;
}
/**
 *
 * @export
 * @interface DealApprovalResolutionGroupReason
 */
export interface DealApprovalResolutionGroupReason {
  /**
   *
   * @type {string}
   * @memberof DealApprovalResolutionGroupReason
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof DealApprovalResolutionGroupReason
   */
  userGroupCode?: string;
}
/**
 *
 * @export
 * @interface DealBranchOffice
 */
export interface DealBranchOffice {
  /**
   * Код филиала
   * @type {string}
   * @memberof DealBranchOffice
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DealBranchOffice
   */
  name?: string;
}
/**
 *
 * @export
 * @interface DealComment
 */
export interface DealComment {
  /**
   * Комментарий сделки
   * @type {string}
   * @memberof DealComment
   */
  comment?: string;
  /**
   * Код типа комментария сделки
   * @type {string}
   * @memberof DealComment
   */
  typeCode: string;
  /**
   * Заголовок типа комментария сделки
   * @type {string}
   * @memberof DealComment
   */
  typeTitle?: string;
}
/**
 *
 * @export
 * @interface DealDeliveryType
 */
export interface DealDeliveryType {
  /**
   * Код типа доставки
   * @type {string}
   * @memberof DealDeliveryType
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DealDeliveryType
   */
  name?: string;
  /**
   * Плановая дата отгрузки
   * @type {string}
   * @memberof DealDeliveryType
   */
  plannedShipmentDate?: string;
  /**
   * Стоимость доставки за тонну
   * @type {number}
   * @memberof DealDeliveryType
   */
  priceBu?: number;
  /**
   * Общая стоимость доставки этим методом
   * @type {number}
   * @memberof DealDeliveryType
   */
  priceTotal?: number;
}
/**
 *
 * @export
 * @interface DealDistributor
 */
export interface DealDistributor {
  /**
   *
   * @type {string}
   * @memberof DealDistributor
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof DealDistributor
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DealDistributor
   */
  patronymic?: string;
  /**
   *
   * @type {string}
   * @memberof DealDistributor
   */
  surname?: string;
}
/**
 *
 * @export
 * @interface DealDocument
 */
export interface DealDocument {
  /**
   *
   * @type {string}
   * @memberof DealDocument
   */
  code?: string;
  /**
   * Описание-заметка
   * @type {string}
   * @memberof DealDocument
   */
  description?: string;
  /**
   * Имя файла
   * @type {string}
   * @memberof DealDocument
   */
  filename?: string;
  /**
   * Размер файла
   * @type {number}
   * @memberof DealDocument
   */
  filesize?: number;
  /**
   * Internet Media Type
   * @type {string}
   * @memberof DealDocument
   */
  mime?: string;
  /**
   * Позиция сделки
   * @type {string}
   * @memberof DealDocument
   */
  positionCode?: string;
  /**
   * Код типа документа
   * @type {string}
   * @memberof DealDocument
   */
  typeCode?: string;
  /**
   * Название типа документа
   * @type {string}
   * @memberof DealDocument
   */
  typeName?: string;
  /**
   *
   * @type {string}
   * @memberof DealDocument
   */
  uploadDate?: string;
  /**
   *
   * @type {string}
   * @memberof DealDocument
   */
  url?: string;
}
/**
 *
 * @export
 * @interface DealEditingDisableReason
 */
export interface DealEditingDisableReason {
  /**
   * locked, approved, etc.
   * @type {string}
   * @memberof DealEditingDisableReason
   */
  code?: string;
}
/**
 *
 * @export
 * @interface DealHistory
 */
export interface DealHistory {
  /**
   *
   * @type {Array<DealHistoryEvent>}
   * @memberof DealHistory
   */
  events?: Array<DealHistoryEvent>;
}
/**
 *
 * @export
 * @interface DealHistoryEvent
 */
export interface DealHistoryEvent {
  /**
   *
   * @type {string}
   * @memberof DealHistoryEvent
   */
  actionCode?: string;
  /**
   *
   * @type {string}
   * @memberof DealHistoryEvent
   */
  actionTitle?: string;
  /**
   * Комментарий инициатора действия
   * @type {string}
   * @memberof DealHistoryEvent
   */
  comment?: string;
  /**
   *
   * @type {Array<DealUpdateLogChange>}
   * @memberof DealHistoryEvent
   */
  dealUpdateLogChanges?: Array<DealUpdateLogChange>;
  /**
   *
   * @type {Employee}
   * @memberof DealHistoryEvent
   */
  employee?: Employee;
  /**
   *
   * @type {string}
   * @memberof DealHistoryEvent
   */
  newStateCode?: string;
  /**
   *
   * @type {string}
   * @memberof DealHistoryEvent
   */
  newStateTitle?: string;
  /**
   *
   * @type {string}
   * @memberof DealHistoryEvent
   */
  oldStateCode?: string;
  /**
   *
   * @type {string}
   * @memberof DealHistoryEvent
   */
  oldStateTitle?: string;
  /**
   *
   * @type {string}
   * @memberof DealHistoryEvent
   */
  userGroupCode?: string;
  /**
   *
   * @type {string}
   * @memberof DealHistoryEvent
   */
  userGroupName?: string;
  /**
   *
   * @type {string}
   * @memberof DealHistoryEvent
   */
  when?: string;
}
/**
 *
 * @export
 * @interface DealLifting
 */
export interface DealLifting {
  /**
   * Время на сборку в секундах
   * @type {number}
   * @memberof DealLifting
   */
  timeSeconds?: number;
  /**
   * Общая стоимость сборки
   * @type {number}
   * @memberof DealLifting
   */
  totalCost?: number;
}
/**
 *
 * @export
 * @interface DealMotivation
 */
export interface DealMotivation {
  /**
   * Премия БСЦ
   * @type {number}
   * @memberof DealMotivation
   */
  centralPurchaseAmount?: number;
  /**
   * Премия П1
   * @type {number}
   * @memberof DealMotivation
   */
  p1Amount?: number;
  /**
   * Премия П2
   * @type {number}
   * @memberof DealMotivation
   */
  p2Amount?: number;
  /**
   * Премия БСС
   * @type {number}
   * @memberof DealMotivation
   */
  selfPurchaseAmount?: number;
  /**
   * Итоговая премия менеджера
   * @type {number}
   * @memberof DealMotivation
   */
  totalPremiumAmount?: number;
}
/**
 *
 * @export
 * @interface DealPartners
 */
export interface DealPartners {
  /**
   *
   * @type {ParticipantPartner}
   * @memberof DealPartners
   */
  consignee?: ParticipantPartner;
  /**
   *
   * @type {ParticipantPartner}
   * @memberof DealPartners
   */
  payer?: ParticipantPartner;
}
/**
 *
 * @export
 * @interface DealPayment
 */
export interface DealPayment {
  /**
   * Максимальное количество дней кредитования, разрешенное по офису
   * @type {number}
   * @memberof DealPayment
   */
  officeMaxPostpayDays?: number;
  /**
   * Код разрешенных процентов для отсрочки платежа. Сейчас [0,100] или [0,50,100]
   * @type {string}
   * @memberof DealPayment
   */
  officePrepayPercents?: string;
  /**
   * Выбрано дней отсрочки платежа
   * @type {number}
   * @memberof DealPayment
   */
  postpayDays?: number;
  /**
   *
   * @type {number}
   * @memberof DealPayment
   */
  prepayPct?: number;
}
/**
 *
 * @export
 * @interface DealPosition
 */
export interface DealPosition {
  /**
   * Код позиции соглашения по ценам
   * @type {string}
   * @memberof DealPosition
   */
  agreementPositionCode?: string;
  /**
   * Количество для продажи
   * @type {number}
   * @memberof DealPosition
   */
  amount?: number;
  /**
   * Установленная голая цена товара
   * @type {number}
   * @memberof DealPosition
   */
  bareUnitCost?: number;
  /**
   * Результирующая цена за ЕИ (обычно за тонну) с услугами
   * @type {number}
   * @memberof DealPosition
   */
  baseFullUnitCost?: number;
  /**
   * Базовая МРЦ
   * @type {number}
   * @memberof DealPosition
   */
  baseMinRetailUnitCost?: number | null;
  /**
   * Результирующее количество (обычно в тоннах)
   * @type {number}
   * @memberof DealPosition
   */
  baseQuantity?: number;
  /**
   * Результирующая ЕИ (обычно тонны)
   * @type {string}
   * @memberof DealPosition
   */
  baseUnit?: string;
  /**
   * Код результирующей ЕИ (TNE)
   * @type {string}
   * @memberof DealPosition
   */
  baseUnitCode?: string;
  /**
   * Результирующая цена за ЕИ (обычно за тонну)
   * @type {number}
   * @memberof DealPosition
   */
  baseUnitCost?: number;
  /**
   * Расчетная голая цена товара. Имеет смысл, если задается тендерная цена.
   * @type {number}
   * @memberof DealPosition
   */
  calculatedBareUnitCost?: number;
  /**
   * Уникальный код позиции
   * @type {string}
   * @memberof DealPosition
   */
  code?: string;
  /**
   * Список комментариев к позиции сделки
   * @type {Array<DealPositionComment>}
   * @memberof DealPosition
   */
  comments?: Array<DealPositionComment>;
  /**
   * Средневзвешенная цена по рынку (конкурентам)
   * @type {number}
   * @memberof DealPosition
   */
  competitorAvgUnitCost?: number;
  /**
   * Итоговая цена кредита размазанная по позиции
   * @type {number}
   * @memberof DealPosition
   */
  creditTotalCost?: number | null;
  /**
   * Цена МОП + доставка + услуги + кредит
   * @type {number}
   * @memberof DealPosition
   */
  creditUnitCost?: number | null;
  /**
   * Валюта
   * @type {string}
   * @memberof DealPosition
   */
  currency?: string;
  /**
   * Средневзвешенная цена партий ОХ
   * @type {number}
   * @memberof DealPosition
   */
  custodyAvgUnitCost?: number;
  /**
   *
   * @type {string}
   * @memberof DealPosition
   */
  demandDescription?: string;
  /**
   * Ссылка на пример желаемого товара
   * @type {string}
   * @memberof DealPosition
   */
  demandLink?: string;
  /**
   * Распределить кредит по позиции
   * @type {boolean}
   * @memberof DealPosition
   */
  distributeCredit?: boolean;
  /**
   * Стоимость доставки включается в стоимость позиции
   * @type {boolean}
   * @memberof DealPosition
   */
  distributeDelivery?: boolean;
  /**
   * Стоимость включенной доставки за тонну
   * @type {number}
   * @memberof DealPosition
   */
  distributeDeliveryCost?: number;
  /**
   * Общая стоимость включенной доставки
   * @type {number}
   * @memberof DealPosition
   */
  distributeDeliverySum?: number;
  /**
   * (Цена МОП + доставка + услуги + кредит) * Количество
   * @type {number}
   * @memberof DealPosition
   */
  fullTotalCost?: number | null;
  /**
   * (Цена МОП + доставка + услуги + кредит) * Количество (без НДС)
   * @type {number}
   * @memberof DealPosition
   */
  fullTotalCostWoVat?: number | null;
  /**
   * Цена МОП + доставка + услуги + кредит
   * @type {number}
   * @memberof DealPosition
   */
  fullUnitCost?: number | null;
  /**
   * Цена МОП + доставка + услуги + кредит (без НДС)
   * @type {number}
   * @memberof DealPosition
   */
  fullUnitCostWoVat?: number | null;
  /**
   * Цена рынка
   * @type {number}
   * @memberof DealPosition
   */
  globalMarketCost?: number | null;
  /**
   * Маржинальность по рыночным ценам, %
   * @type {number}
   * @memberof DealPosition
   */
  globalMarketMarginality?: number;
  /**
   * Маржинальность по рыночным ценам, %
   * @type {number}
   * @memberof DealPosition
   */
  globalMarketMarginalityPct?: number;
  /**
   * Рекомендованная цена рынка (с учетом доставки и услуг)
   * @type {number}
   * @memberof DealPosition
   */
  globalMarketRecommendedCost?: number | null;
  /**
   * Признак архивной позиции
   * @type {boolean}
   * @memberof DealPosition
   */
  isArchive?: boolean;
  /**
   * Позиция является услугой
   * @type {boolean}
   * @memberof DealPosition
   */
  isService?: boolean;
  /**
   *
   * @type {DealPositionLifting}
   * @memberof DealPosition
   */
  lifting?: DealPositionLifting;
  /**
   * Номер позиции по порядку. Определяет порядок отображения позиций.
   * @type {number}
   * @memberof DealPosition
   */
  lineNumber?: number;
  /**
   * Абсолютная маржинальность МОП
   * @type {number}
   * @memberof DealPosition
   */
  managerFullCostMargin?: number;
  /**
   * Стоимость услуг для позиции, установленная вручную
   * @type {number}
   * @memberof DealPosition
   */
  manualServicesCost?: number;
  /**
   * Максимальная наценка, %
   * @type {number}
   * @memberof DealPosition
   */
  manufacturerMarkupLimitFactor?: number;
  /**
   * Максимальная цена по производителю и бюджетной группе
   * @type {number}
   * @memberof DealPosition
   */
  manufacturerMarkupLimitUnitCost?: number;
  /**
   * Маржинальность от минимальной цены, руб.
   * @type {number}
   * @memberof DealPosition
   */
  marginality?: number;
  /**
   * Маржинальность от минимальной цены, %
   * @type {number}
   * @memberof DealPosition
   */
  marginalityPct?: number;
  /**
   * Минимальная розничная цена
   * @type {number}
   * @memberof DealPosition
   */
  minRetailUnitCost?: number | null;
  /**
   * МРЦ с полномочиями
   * @type {number}
   * @memberof DealPosition
   */
  minRetailUnitCostWithPowers?: number | null;
  /**
   *
   * @type {DealPositionMotivation}
   * @memberof DealPosition
   */
  motivation?: DealPositionMotivation;
  /**
   *
   * @type {Array<DealPositionMrcUserGroup>}
   * @memberof DealPosition
   */
  mrcUserGroups?: Array<DealPositionMrcUserGroup>;
  /**
   * Стоимость заказа, покрываемая кредитом
   * @type {number}
   * @memberof DealPosition
   */
  postpayCost?: number | null;
  /**
   * Выбрано дней отсрочки платежа
   * @type {number}
   * @memberof DealPosition
   */
  postpayDays?: number | null;
  /**
   *
   * @type {number}
   * @memberof DealPosition
   */
  prepayPct?: number | null;
  /**
   *
   * @type {DealPositionPrevious}
   * @memberof DealPosition
   */
  previous?: DealPositionPrevious;
  /**
   * Абсолютная маржа итоговой цены к цене прайс-листа за продажную единицу
   * @type {number}
   * @memberof DealPosition
   */
  priceListCostMargin?: number;
  /**
   * Относительная маржинальность итоговой цены к цене прайс-листа
   * @type {number}
   * @memberof DealPosition
   */
  priceListCostMarginPct?: number;
  /**
   * Абсолютная маржа к плановой себестоимости за продажную единицу
   * @type {number}
   * @memberof DealPosition
   */
  primeCostMargin?: number;
  /**
   * Относительная маржинальность к плановой себестоимости
   * @type {number}
   * @memberof DealPosition
   */
  primeCostMarginPct?: number;
  /**
   *
   * @type {Product}
   * @memberof DealPosition
   */
  product?: Product;
  /**
   * Артикул
   * @type {string}
   * @memberof DealPosition
   */
  productCode?: string;
  /**
   * Название товара контрагента
   * @type {string}
   * @memberof DealPosition
   */
  productParticipantName?: string;
  /**
   * ПС на всю позицию
   * @type {number}
   * @memberof DealPosition
   */
  psTotalCost?: number;
  /**
   * ПС на единицу
   * @type {number}
   * @memberof DealPosition
   */
  psUnitCost?: number;
  /**
   * Абсолютная маржа к средневзвешенной цене закупки за продажную единицу
   * @type {number}
   * @memberof DealPosition
   */
  purchaseCostMargin?: number;
  /**
   * Относительная маржинальность к средневзвешенной цене закупки
   * @type {number}
   * @memberof DealPosition
   */
  purchaseCostMarginPct?: number;
  /**
   * Маржинальность по рекомендованной цене, руб.
   * @type {number}
   * @memberof DealPosition
   */
  recommendedCostMarginality?: number;
  /**
   * Маржинальность по рекомендованной цене, %
   * @type {number}
   * @memberof DealPosition
   */
  recommendedCostMarginalityPct?: number;
  /**
   * Рекомендованная цена
   * @type {number}
   * @memberof DealPosition
   */
  recommendedUnitCost?: number | null;
  /**
   *
   * @type {ReservationSummary}
   * @memberof DealPosition
   */
  reservationSummary?: ReservationSummary;
  /**
   * Итоговая округления итоговой цены позиции
   * @type {number}
   * @memberof DealPosition
   */
  roundingTotalCost?: number;
  /**
   * Цена округления итоговой цены позиции
   * @type {number}
   * @memberof DealPosition
   */
  roundingUnitCost?: number;
  /**
   * Сегмент товара
   * @type {string}
   * @memberof DealPosition
   */
  segment?: string;
  /**
   * Наценка филиала или директорская наценка
   * @type {number}
   * @memberof DealPosition
   */
  segmentationGroupMrcMarkupAmount?: number;
  /**
   * Стоимость услуг для позиции (без учета доставки и кредита)
   * @type {number}
   * @memberof DealPosition
   */
  serviceTotalCost?: number;
  /**
   *
   * @type {Array<DealPositionService>}
   * @memberof DealPosition
   */
  services?: Array<DealPositionService>;
  /**
   * Коэффициент товарного запаса
   * @type {number}
   * @memberof DealPosition
   */
  stockCoefficient?: number;
  /**
   * Установленная тендерная цена товара
   * @type {number}
   * @memberof DealPosition
   */
  tenderUnitCost?: number;
  /**
   *
   * @type {string}
   * @memberof DealPosition
   */
  title?: string;
  /**
   * Итоговая стоимость позиции (с учетом количества, доставки, услуг и т.д.)
   * @type {number}
   * @memberof DealPosition
   */
  totalCost?: number;
  /**
   * Единица измерения, с которое работает клиент
   * @type {string}
   * @memberof DealPosition
   */
  unit?: string;
  /**
   * Код текущей ЕИ
   * @type {string}
   * @memberof DealPosition
   */
  unitCode?: string;
  /**
   * Цена, которую следует использовать в качестве исходной для расчетов - голая или тендерная.
   * @type {string}
   * @memberof DealPosition
   */
  unitCostType?: DealPositionUnitCostTypeEnum;
  /**
   *
   * @type {Array<DealPositionUnit>}
   * @memberof DealPosition
   */
  units?: Array<DealPositionUnit>;
  /**
   * Использовать заданную вручную стоимость услуг
   * @type {boolean}
   * @memberof DealPosition
   */
  useManualServicesCost?: boolean;
  /**
   * Использовать ПС
   * @type {boolean}
   * @memberof DealPosition
   */
  usePsUnitCost?: boolean;
  /**
   * Код НДС
   * @type {string}
   * @memberof DealPosition
   */
  vatRateCode?: string;
  /**
   * Размер НДС в процентах, действующего на позицию
   * @type {number}
   * @memberof DealPosition
   */
  vatRateValue?: number;
  /**
   * Сумма НДС
   * @type {number}
   * @memberof DealPosition
   */
  vatTotalCost?: number;
  /**
   * Цена НДС
   * @type {number}
   * @memberof DealPosition
   */
  vatUnitCost?: number;
  /**
   * Склад отгрузки товара
   * @type {string}
   * @memberof DealPosition
   */
  warehouseCode?: string;
  /**
   * Склад отгрузки товара (ключ 1С)
   * @type {string}
   * @memberof DealPosition
   */
  warehouseMdmKey?: string;
  /**
   * Название склада отгрузки
   * @type {string}
   * @memberof DealPosition
   */
  warehouseName?: string;
  /**
   * Вес позиции, кг
   * @type {number}
   * @memberof DealPosition
   */
  weight?: number;
  /**
   * Средневзвешенная цена по филиалу = плановая себестоимость
   * @type {number}
   * @memberof DealPosition
   */
  weightedAvgUnitCost?: number | null;
}

export const DealPositionUnitCostTypeEnum = {
  Bare: 'bare',
  Tender: 'tender',
} as const;

export type DealPositionUnitCostTypeEnum = (typeof DealPositionUnitCostTypeEnum)[keyof typeof DealPositionUnitCostTypeEnum];

/**
 *
 * @export
 * @interface DealPositionAvailableManufacturer
 */
export interface DealPositionAvailableManufacturer {
  /**
   *
   * @type {Array<AvailableProductManufacturer>}
   * @memberof DealPositionAvailableManufacturer
   */
  manufacturers?: Array<AvailableProductManufacturer>;
  /**
   * Код исходной позиции сделки, к которой подобраны альтернативы
   * @type {string}
   * @memberof DealPositionAvailableManufacturer
   */
  positionCode?: string;
}
/**
 *
 * @export
 * @interface DealPositionComment
 */
export interface DealPositionComment {
  /**
   * Комментарий к позиции сделки
   * @type {string}
   * @memberof DealPositionComment
   */
  comment?: string;
  /**
   * Код типа комментария к позиции сделки
   * @type {string}
   * @memberof DealPositionComment
   */
  typeCode: string;
  /**
   * Заголовок типа комментария к позиции сделки
   * @type {string}
   * @memberof DealPositionComment
   */
  typeTitle?: string;
}
/**
 *
 * @export
 * @interface DealPositionLifting
 */
export interface DealPositionLifting {
  /**
   * Средний вес пачки на складе
   * @type {number}
   * @memberof DealPositionLifting
   */
  avgPackWeight?: number;
  /**
   * Количество целых пачек
   * @type {number}
   * @memberof DealPositionLifting
   */
  fullPackCount?: number;
  /**
   * Стоимость разбиения пачки
   * @type {number}
   * @memberof DealPositionLifting
   */
  partialCost?: number;
  /**
   * Потребуется ли разбиения пачки
   * @type {number}
   * @memberof DealPositionLifting
   */
  partialCount?: number;
  /**
   * Время на сборку в секундах
   * @type {number}
   * @memberof DealPositionLifting
   */
  timeSeconds?: number;
  /**
   * Стоимость сборки
   * @type {number}
   * @memberof DealPositionLifting
   */
  totalCost?: number;
}
/**
 *
 * @export
 * @interface DealPositionMotivation
 */
export interface DealPositionMotivation {
  /**
   * Базовый маржинальный доход
   * @type {number}
   * @memberof DealPositionMotivation
   */
  baseMarginIncome?: number;
  /**
   * МРЦ
   * @type {number}
   * @memberof DealPositionMotivation
   */
  minRetailUnitCost?: number;
  /**
   * База мотивационной маржинальности (МРЦ с полномочиями директора филиала)
   * @type {number}
   * @memberof DealPositionMotivation
   */
  mmbUnitCost?: number;
  /**
   * Превышение МРЦ
   * @type {number}
   * @memberof DealPositionMotivation
   */
  mrcExceedAmount?: number;
  /**
   * Первая шкала превышения МРЦ
   * @type {number}
   * @memberof DealPositionMotivation
   */
  mrcExceedAmount1?: number;
  /**
   * Вторая шкала превышения МРЦ
   * @type {number}
   * @memberof DealPositionMotivation
   */
  mrcExceedAmount2?: number;
  /**
   * Третья шкала превышения МРЦ
   * @type {number}
   * @memberof DealPositionMotivation
   */
  mrcExceedAmount3?: number;
  /**
   * Четвертая шкала превышения МРЦ
   * @type {number}
   * @memberof DealPositionMotivation
   */
  mrcExceedAmount4?: number;
  /**
   * Премия П1
   * @type {number}
   * @memberof DealPositionMotivation
   */
  p1Amount?: number;
  /**
   * Ставка бюджетной группы для расчета П1
   * @type {number}
   * @memberof DealPositionMotivation
   */
  p1Rate?: number;
  /**
   * Премия П2
   * @type {number}
   * @memberof DealPositionMotivation
   */
  p2Amount?: number;
  /**
   * Процентная ставка для расчета П2 (если имеет место превышение МРЦ, то эта ставка используется для первой шкалы)
   * @type {number}
   * @memberof DealPositionMotivation
   */
  p2Pct?: number;
  /**
   * Процентная ставка для расчета П2, для второй шкалы превышения МРЦ
   * @type {number}
   * @memberof DealPositionMotivation
   */
  p2Pct2?: number;
  /**
   * Процентная ставка для расчета П2, для третьей шкалы превышения МРЦ
   * @type {number}
   * @memberof DealPositionMotivation
   */
  p2Pct3?: number;
  /**
   * Процентная ставка для расчета П2, для четвертой шкалы превышения МРЦ
   * @type {number}
   * @memberof DealPositionMotivation
   */
  p2Pct4?: number;
  /**
   * Премия П3
   * @type {number}
   * @memberof DealPositionMotivation
   */
  p3Amount?: number;
  /**
   * Процентная ставка по самостоятельным закупкам
   * @type {number}
   * @memberof DealPositionMotivation
   */
  p3Pct?: number;
  /**
   * Количество
   * @type {number}
   * @memberof DealPositionMotivation
   */
  quantity?: number;
  /**
   * Цена продажи товара, без услуг
   * @type {number}
   * @memberof DealPositionMotivation
   */
  saleUnitCost?: number;
  /**
   * Итоговая премия позиции
   * @type {number}
   * @memberof DealPositionMotivation
   */
  totalPremiumAmount?: number;
  /**
   * Пользовательская группа полномочий МРЦ
   * @type {string}
   * @memberof DealPositionMotivation
   */
  userGroupCode?: string;
}
/**
 *
 * @export
 * @interface DealPositionMrcUserGroup
 */
export interface DealPositionMrcUserGroup {
  /**
   *
   * @type {string}
   * @memberof DealPositionMrcUserGroup
   */
  groupCode?: string;
  /**
   *
   * @type {string}
   * @memberof DealPositionMrcUserGroup
   */
  groupShort?: string;
  /**
   *
   * @type {string}
   * @memberof DealPositionMrcUserGroup
   */
  groupTitle?: string;
  /**
   * МРЦ соответствуем настройкам группы
   * @type {boolean}
   * @memberof DealPositionMrcUserGroup
   */
  isMRCFine?: boolean;
  /**
   * МРЦ для группы
   * @type {number}
   * @memberof DealPositionMrcUserGroup
   */
  mrcUnitCost?: number;
  /**
   * Размер учтенной наценки филиала в МРЦ группы
   * @type {number}
   * @memberof DealPositionMrcUserGroup
   */
  sgMarkupAmount?: number;
}
/**
 *
 * @export
 * @interface DealPositionPrevious
 */
export interface DealPositionPrevious {
  /**
   * Валюта
   * @type {string}
   * @memberof DealPositionPrevious
   */
  currency?: string;
  /**
   * Код сделки
   * @type {string}
   * @memberof DealPositionPrevious
   */
  dealCode?: string;
  /**
   * Дата сделки
   * @type {string}
   * @memberof DealPositionPrevious
   */
  dealCreatedAt?: string;
  /**
   * Итоговая стоимость позиции
   * @type {number}
   * @memberof DealPositionPrevious
   */
  fullTotalCost?: number;
  /**
   * Цена МОП + доставка + услуги + кредит
   * @type {number}
   * @memberof DealPositionPrevious
   */
  fullUnitCost?: number;
  /**
   * абсолютная маржинальность МОП по позиции
   * @type {number}
   * @memberof DealPositionPrevious
   */
  managerFullCostMargin?: number;
  /**
   * маржинальность позиции
   * @type {number}
   * @memberof DealPositionPrevious
   */
  managerFullCostMarginPct?: number;
  /**
   * Количество купленного товара
   * @type {number}
   * @memberof DealPositionPrevious
   */
  quantity?: number;
  /**
   * Единица измерения
   * @type {string}
   * @memberof DealPositionPrevious
   */
  unit?: string;
  /**
   * Цена МОП
   * @type {number}
   * @memberof DealPositionPrevious
   */
  unitCost?: number;
}
/**
 *
 * @export
 * @interface DealPositionService
 */
export interface DealPositionService {
  /**
   * Услуга действует, т.е. стоимость услуги влияет на стоимость позиции. Если нет, то услугу следует выводить перечеркнутой.
   * @type {boolean}
   * @memberof DealPositionService
   */
  active?: boolean;
  /**
   * Безовая единица
   * @type {string}
   * @memberof DealPositionService
   */
  baseUnit?: string;
  /**
   * Стоимость услуги на базовую единицу (тонну)
   * @type {number}
   * @memberof DealPositionService
   */
  baseUnitCost?: number;
  /**
   * Возможно установить значение totalCost вручную
   * @type {boolean}
   * @memberof DealPositionService
   */
  canSetManualTotalCost?: boolean;
  /**
   *
   * @type {string}
   * @memberof DealPositionService
   */
  code?: string;
  /**
   * Стоимость услуги на единицу
   * @type {number}
   * @memberof DealPositionService
   */
  cost?: number | null;
  /**
   * Валюта
   * @type {string}
   * @memberof DealPositionService
   */
  currency?: string;
  /**
   * Услуга включена для позиции
   * @type {boolean}
   * @memberof DealPositionService
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof DealPositionService
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof DealPositionService
   */
  totalCost?: number | null;
  /**
   * Единица измерения
   * @type {string}
   * @memberof DealPositionService
   */
  unit?: string;
}
/**
 *
 * @export
 * @interface DealPositionUnit
 */
export interface DealPositionUnit {
  /**
   * Код ЕИ
   * @type {string}
   * @memberof DealPositionUnit
   */
  code?: string;
  /**
   * Выбранная ЕИ
   * @type {boolean}
   * @memberof DealPositionUnit
   */
  isCurrent?: boolean | null;
  /**
   * Заголовок ЕИ
   * @type {string}
   * @memberof DealPositionUnit
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof DealPositionUnit
   */
  weight?: number;
}
/**
 *
 * @export
 * @interface DealState
 */
export interface DealState {
  /**
   * Код статуса
   * @type {string}
   * @memberof DealState
   */
  code?: string;
  /**
   * Заголовок статуса
   * @type {string}
   * @memberof DealState
   */
  title?: string;
}
/**
 *
 * @export
 * @interface DealTransport
 */
export interface DealTransport {
  /**
   * Расстояние до адреса доставки, км
   * @type {number}
   * @memberof DealTransport
   */
  distance?: number;
  /**
   * Эффективность использования (заполнения) машин
   * @type {number}
   * @memberof DealTransport
   */
  efficiency?: number;
  /**
   * Надбавка за срочность доставки
   * @type {number}
   * @memberof DealTransport
   */
  expressDeliveryCost?: number;
  /**
   *
   * @type {Array<DealTransportItem>}
   * @memberof DealTransport
   */
  items?: Array<DealTransportItem>;
  /**
   * Рассчетная стоимость доставки
   * @type {number}
   * @memberof DealTransport
   */
  totalCost?: number;
  /**
   * Чистая стоимость транспорта
   * @type {number}
   * @memberof DealTransport
   */
  unitsAppraiseCost?: number;
  /**
   * Формула транспорта одной строкой
   * @type {string}
   * @memberof DealTransport
   */
  unitsCode?: string;
}
/**
 *
 * @export
 * @interface DealTransportItem
 */
export interface DealTransportItem {
  /**
   * Необходимое количество единиц этого типа
   * @type {number}
   * @memberof DealTransportItem
   */
  count?: number;
  /**
   * Условное название типа транспортной единицы (3,5т, 7т, 20т)
   * @type {string}
   * @memberof DealTransportItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface DealUpdateLog
 */
export interface DealUpdateLog {
  /**
   *
   * @type {Array<DealUpdateLogChange>}
   * @memberof DealUpdateLog
   */
  allChanges?: Array<DealUpdateLogChange>;
  /**
   *
   * @type {DealUpdateLogChange}
   * @memberof DealUpdateLog
   */
  changed?: DealUpdateLogChange;
}
/**
 *
 * @export
 * @interface DealUpdateLogChange
 */
export interface DealUpdateLogChange {
  /**
   *
   * @type {string}
   * @memberof DealUpdateLogChange
   */
  createdAt?: string | null;
  /**
   * стоимость доставки
   * @type {number}
   * @memberof DealUpdateLogChange
   */
  deliveryCost?: number | null;
  /**
   * тип доставки
   * @type {string}
   * @memberof DealUpdateLogChange
   */
  deliveryTypeCode?: string | null;
  /**
   *
   * @type {Employee}
   * @memberof DealUpdateLogChange
   */
  initiatorEmployee?: Employee;
  /**
   * предыдущая стоимость доставки
   * @type {number}
   * @memberof DealUpdateLogChange
   */
  oldDeliveryCost?: number | null;
  /**
   * предыдущий тип доставки
   * @type {string}
   * @memberof DealUpdateLogChange
   */
  oldDeliveryTypeCode?: string | null;
  /**
   * предыдущее количество дней отсрочки
   * @type {number}
   * @memberof DealUpdateLogChange
   */
  oldPostpayDays?: number | null;
  /**
   * предыдущий процент предоплаты
   * @type {number}
   * @memberof DealUpdateLogChange
   */
  oldPrepayPct?: number | null;
  /**
   *
   * @type {Array<DealUpdateLogChangedPosition>}
   * @memberof DealUpdateLogChange
   */
  positions?: Array<DealUpdateLogChangedPosition>;
  /**
   * количество дней отсрочки
   * @type {number}
   * @memberof DealUpdateLogChange
   */
  postpayDays?: number | null;
  /**
   * процент предоплаты
   * @type {number}
   * @memberof DealUpdateLogChange
   */
  prepayPct?: number | null;
  /**
   * Код группы
   * @type {string}
   * @memberof DealUpdateLogChange
   */
  userGroupCode?: string | null;
}
/**
 *
 * @export
 * @interface DealUpdateLogChangedPosition
 */
export interface DealUpdateLogChangedPosition {
  /**
   * Уникальный код позиции
   * @type {string}
   * @memberof DealUpdateLogChangedPosition
   */
  code?: string;
  /**
   * Добавлена новая позиция
   * @type {boolean}
   * @memberof DealUpdateLogChangedPosition
   */
  isNew?: boolean;
  /**
   * Количество товара (предыдущее)
   * @type {number}
   * @memberof DealUpdateLogChangedPosition
   */
  oldQuantity?: number | null;
  /**
   * Голая цена товара (предыдущая)
   * @type {number}
   * @memberof DealUpdateLogChangedPosition
   */
  oldUnitCost?: number | null;
  /**
   * Количество товара
   * @type {number}
   * @memberof DealUpdateLogChangedPosition
   */
  quantity?: number | null;
  /**
   * Голая цена товара
   * @type {number}
   * @memberof DealUpdateLogChangedPosition
   */
  unitCost?: number | null;
}
/**
 *
 * @export
 * @interface DealUpdates
 */
export interface DealUpdates {
  /**
   *
   * @type {string}
   * @memberof DealUpdates
   */
  addressCode?: string | null;
  /**
   * Привязка заявки к соглашению по ценам.
   * @type {string}
   * @memberof DealUpdates
   */
  agreementCode?: string | null;
  /**
   * Флаг для отвязки соглашения.
   * @type {boolean}
   * @memberof DealUpdates
   */
  agreementReset?: boolean | null;
  /**
   * Список комментариев сделки
   * @type {Array<DealComment>}
   * @memberof DealUpdates
   */
  comments?: Array<DealComment> | null;
  /**
   * Грузополучатель
   * @type {string}
   * @memberof DealUpdates
   */
  consigneeCode?: string | null;
  /**
   * Договор, в рамках которого оформляется сделка
   * @type {string}
   * @memberof DealUpdates
   */
  contractCode?: string | null;
  /**
   * Клиент-покупатель
   * @type {string}
   * @memberof DealUpdates
   */
  customerCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof DealUpdates
   */
  deliveryType?: string | null;
  /**
   * Реализатор
   * @type {string}
   * @memberof DealUpdates
   */
  distributorCode?: string | null;
  /**
   *
   * @type {Array<DealUpdatesLeadPosition>}
   * @memberof DealUpdates
   */
  leadPositions?: Array<DealUpdatesLeadPosition> | null;
  /**
   * Установленное вручную количество дней резерва остатков
   * @type {number}
   * @memberof DealUpdates
   */
  manualBillValidDays?: number | null;
  /**
   * Заданная стоимость кредита
   * @type {number}
   * @memberof DealUpdates
   */
  manualCreditCost?: number | null;
  /**
   * Заданная стоимость доставки
   * @type {number}
   * @memberof DealUpdates
   */
  manualDeliveryCost?: number | null;
  /**
   * Заданное расстояние для расчета доставки
   * @type {number}
   * @memberof DealUpdates
   */
  manualDeliveryDistanceKm?: number | null;
  /**
   * Заданное количество транспортных единиц
   * @type {number}
   * @memberof DealUpdates
   */
  manualDeliveryUnitsCount?: number | null;
  /**
   * Заданная стоимость кредита
   * @type {number}
   * @memberof DealUpdates
   */
  manualServicesCost?: number | null;
  /**
   *
   * @type {Array<DealUpdatesPosition>}
   * @memberof DealUpdates
   */
  positions?: Array<DealUpdatesPosition> | null;
  /**
   *
   * @type {number}
   * @memberof DealUpdates
   */
  postpayDays?: number | null;
  /**
   *
   * @type {number}
   * @memberof DealUpdates
   */
  prepayPct?: number | null;
  /**
   * Использовать установленное вручную количество дней резерва остатков
   * @type {boolean}
   * @memberof DealUpdates
   */
  useManualBillValidDays?: boolean | null;
  /**
   * Использовать заданную стоимость кредита вместо расчетной
   * @type {boolean}
   * @memberof DealUpdates
   */
  useManualCreditCost?: boolean | null;
  /**
   * Использовать заданную стоимость доставки вместо расчетной
   * @type {boolean}
   * @memberof DealUpdates
   */
  useManualDeliveryCost?: boolean | null;
  /**
   * Использовать заданное расстояние для расчета доставки
   * @type {boolean}
   * @memberof DealUpdates
   */
  useManualDeliveryDistance?: boolean | null;
  /**
   * Использовать заданное количество транспортных единиц
   * @type {boolean}
   * @memberof DealUpdates
   */
  useManualDeliveryUnits?: boolean | null;
  /**
   * Использовать заданную стоимость услуг вместо расчетной
   * @type {boolean}
   * @memberof DealUpdates
   */
  useManualServicesCost?: boolean | null;
  /**
   * Ставка НДС
   * @type {string}
   * @memberof DealUpdates
   */
  vatRateCode?: string | null;
  /**
   * Склад отгрузки
   * @type {string}
   * @memberof DealUpdates
   */
  warehouseCode?: string | null;
}
/**
 * ЛИД-товар обновляемой сделки
 * @export
 * @interface DealUpdatesLeadPosition
 */
export interface DealUpdatesLeadPosition {
  /**
   * ГОСТ лид-товара
   * @type {string}
   * @memberof DealUpdatesLeadPosition
   */
  gost?: string | null;
  /**
   * Наименование лид-товара
   * @type {string}
   * @memberof DealUpdatesLeadPosition
   */
  title?: string;
}
/**
 * Позиция обновляемой сделки
 * @export
 * @interface DealUpdatesPosition
 */
export interface DealUpdatesPosition {
  /**
   * Привязка позиции к строке соглашения.
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  agreementPositionCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof DealUpdatesPosition
   */
  amount?: number | null;
  /**
   * Установка голой цены товара
   * @type {number}
   * @memberof DealUpdatesPosition
   */
  bareUnitCost?: number | null;
  /**
   * Код обновляемой позиции. Для новой позиции должен быть пустой и следует передавать frontCode.
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  code?: string | null;
  /**
   * Список комментариев к позиции сделки
   * @type {Array<DealPositionComment>}
   * @memberof DealUpdatesPosition
   */
  comments?: Array<DealPositionComment> | null;
  /**
   *
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  deliveryType?: string | null;
  /**
   * Описание к пожеланию
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  demandDescription?: string | null;
  /**
   * Ссылка на пример желаемого товара
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  demandLink?: string | null;
  /**
   * Наименование желаемого товара
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  demandTitle?: string | null;
  /**
   * Распределить кредит по позиции
   * @type {boolean}
   * @memberof DealUpdatesPosition
   */
  distributeCredit?: boolean | null;
  /**
   * Включать стоимость доставки в стоимость позиции
   * @type {boolean}
   * @memberof DealUpdatesPosition
   */
  distributeDelivery?: boolean | null;
  /**
   * Код элемента на фронта для предотвращения дублирования. Обязательно передавать при добавлении новой позиции. При обновлении следует указывать code.
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  frontCode?: string | null;
  /**
   * Удаление или восстановление позиции
   * @type {boolean}
   * @memberof DealUpdatesPosition
   */
  isArchive?: boolean | null;
  /**
   * Стоимость услуг для позиции
   * @type {number}
   * @memberof DealUpdatesPosition
   */
  manualServicesCost?: number | null;
  /**
   * Стоимость отсрочки платежа
   * @type {number}
   * @memberof DealUpdatesPosition
   */
  postpayCost?: number | null;
  /**
   * Выбрано дней отсрочки платежа
   * @type {number}
   * @memberof DealUpdatesPosition
   */
  postpayDays?: number | null;
  /**
   * Процент предоплаты
   * @type {number}
   * @memberof DealUpdatesPosition
   */
  prepayPct?: number | null;
  /**
   * Артикул товара. Обязателен при создании позиции
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  productCode?: string | null;
  /**
   * Обновление наименования лида товара
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  productLeadTitle?: string | null;
  /**
   * ПС на единицу
   * @type {number}
   * @memberof DealUpdatesPosition
   */
  psUnitCost?: number | null;
  /**
   *
   * @type {Array<DealUpdatesPositionService>}
   * @memberof DealUpdatesPosition
   */
  services?: Array<DealUpdatesPositionService> | null;
  /**
   * Установка тендерной цены товара.
   * @type {number}
   * @memberof DealUpdatesPosition
   */
  tenderUnitCost?: number | null;
  /**
   * Код ЕИ
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  unitCode?: string | null;
  /**
   * Цена, которую следует использовать в качестве исходной для расчетов - голавя или финальная.
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  unitCostType?: DealUpdatesPositionUnitCostTypeEnum;
  /**
   * Использовать заданную вручную стоимость услуг
   * @type {boolean}
   * @memberof DealUpdatesPosition
   */
  useManualServicesCost?: boolean | null;
  /**
   * Использовать ПС
   * @type {boolean}
   * @memberof DealUpdatesPosition
   */
  usePsUnitCost?: boolean | null;
  /**
   * Склад отгрузки товара
   * @type {string}
   * @memberof DealUpdatesPosition
   */
  warehouseCode?: string | null;
}

export const DealUpdatesPositionUnitCostTypeEnum = {
  Bare: 'bare',
  Tender: 'tender',
} as const;

export type DealUpdatesPositionUnitCostTypeEnum =
  (typeof DealUpdatesPositionUnitCostTypeEnum)[keyof typeof DealUpdatesPositionUnitCostTypeEnum];

/**
 *
 * @export
 * @interface DealUpdatesPositionService
 */
export interface DealUpdatesPositionService {
  /**
   *
   * @type {string}
   * @memberof DealUpdatesPositionService
   */
  code?: string;
  /**
   *
   * @type {boolean}
   * @memberof DealUpdatesPositionService
   */
  enabled?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof DealUpdatesPositionService
   */
  manualTotalCost?: number | null;
}
/**
 *
 * @export
 * @interface DealsCreateRequest
 */
export interface DealsCreateRequest {
  /**
   *
   * @type {string}
   * @memberof DealsCreateRequest
   */
  frontCode?: string;
  /**
   *
   * @type {EventSource}
   * @memberof DealsCreateRequest
   */
  source?: EventSource;
  /**
   *
   * @type {DealUpdates}
   * @memberof DealsCreateRequest
   */
  updates?: DealUpdates;
}
/**
 *
 * @export
 * @interface DealsCreateResponse
 */
export interface DealsCreateResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsCreateResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemAlternativesRequest
 */
export interface DealsItemAlternativesRequest {
  /**
   * Искать альтернативы по номенклатуре
   * @type {boolean}
   * @memberof DealsItemAlternativesRequest
   */
  findByNomenclature?: boolean;
  /**
   * Искать альтернативы по группе сегментации
   * @type {boolean}
   * @memberof DealsItemAlternativesRequest
   */
  findBySegmentationGroup?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof DealsItemAlternativesRequest
   */
  positionCodes?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof DealsItemAlternativesRequest
   */
  stockRequired?: boolean;
}
/**
 *
 * @export
 * @interface DealsItemAlternativesResponse
 */
export interface DealsItemAlternativesResponse {
  /**
   *
   * @type {Array<DealPositionAvailableManufacturer>}
   * @memberof DealsItemAlternativesResponse
   */
  positions?: Array<DealPositionAvailableManufacturer>;
}
/**
 *
 * @export
 * @interface DealsItemApprovalConfirmRequest
 */
export interface DealsItemApprovalConfirmRequest {
  /**
   * Комментарий к подтверждению
   * @type {string}
   * @memberof DealsItemApprovalConfirmRequest
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface DealsItemApprovalConfirmResponse
 */
export interface DealsItemApprovalConfirmResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsItemApprovalConfirmResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemApprovalDeclineRequest
 */
export interface DealsItemApprovalDeclineRequest {
  /**
   * Комментарий к отмене
   * @type {string}
   * @memberof DealsItemApprovalDeclineRequest
   */
  comment?: string;
  /**
   * Код заявки
   * @type {string}
   * @memberof DealsItemApprovalDeclineRequest
   */
  dealCode?: string;
}
/**
 *
 * @export
 * @interface DealsItemApprovalDeclineResponse
 */
export interface DealsItemApprovalDeclineResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsItemApprovalDeclineResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemApprovalHistoryResponse
 */
export interface DealsItemApprovalHistoryResponse {
  /**
   *
   * @type {DocumentApprovalHistory}
   * @memberof DealsItemApprovalHistoryResponse
   */
  history?: DocumentApprovalHistory;
}
/**
 *
 * @export
 * @interface DealsItemApprovalWithdrawResponse
 */
export interface DealsItemApprovalWithdrawResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsItemApprovalWithdrawResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemBillPdfResponse
 */
export interface DealsItemBillPdfResponse {
  /**
   *
   * @type {string}
   * @memberof DealsItemBillPdfResponse
   */
  status?: string;
}
/**
 *
 * @export
 * @interface DealsItemCloneRequest
 */
export interface DealsItemCloneRequest {
  /**
   * Код запроса во избежании дублирования заявок в случае дублирования запроса
   * @type {string}
   * @memberof DealsItemCloneRequest
   */
  frontCode?: string;
  /**
   * Скопировать продукты
   * @type {boolean}
   * @memberof DealsItemCloneRequest
   */
  products?: boolean;
  /**
   * Код исходной заявки, откуда копируются товары
   * @type {string}
   * @memberof DealsItemCloneRequest
   */
  sourceDealCode?: string;
}
/**
 *
 * @export
 * @interface DealsItemCloneResponse
 */
export interface DealsItemCloneResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsItemCloneResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemConsigneesResponse
 */
export interface DealsItemConsigneesResponse {
  /**
   *
   * @type {Array<Consignee>}
   * @memberof DealsItemConsigneesResponse
   */
  consignees?: Array<Consignee>;
}
/**
 *
 * @export
 * @interface DealsItemCreateAgreementRequest
 */
export interface DealsItemCreateAgreementRequest {
  /**
   * Код для создания соглашения
   * @type {string}
   * @memberof DealsItemCreateAgreementRequest
   */
  frontCode?: string;
}
/**
 *
 * @export
 * @interface DealsItemCreateAgreementResponse
 */
export interface DealsItemCreateAgreementResponse {
  /**
   *
   * @type {string}
   * @memberof DealsItemCreateAgreementResponse
   */
  agreementCode?: string;
}
/**
 *
 * @export
 * @interface DealsItemDeliveryTypesResponse
 */
export interface DealsItemDeliveryTypesResponse {
  /**
   *
   * @type {Array<DealDeliveryType>}
   * @memberof DealsItemDeliveryTypesResponse
   */
  deliveryTypes?: Array<DealDeliveryType>;
}
/**
 *
 * @export
 * @interface DealsItemDistributorsResponse
 */
export interface DealsItemDistributorsResponse {
  /**
   *
   * @type {Array<EmployeeSet>}
   * @memberof DealsItemDistributorsResponse
   */
  distributors?: Array<EmployeeSet>;
}
/**
 *
 * @export
 * @interface DealsItemHistoryResponse
 */
export interface DealsItemHistoryResponse {
  /**
   *
   * @type {DealHistory}
   * @memberof DealsItemHistoryResponse
   */
  history?: DealHistory;
}
/**
 *
 * @export
 * @interface DealsItemManufacturersResponse
 */
export interface DealsItemManufacturersResponse {
  /**
   *
   * @type {Array<DealPositionAvailableManufacturer>}
   * @memberof DealsItemManufacturersResponse
   */
  positions?: Array<DealPositionAvailableManufacturer>;
}
/**
 *
 * @export
 * @interface DealsItemPartnersResponse
 */
export interface DealsItemPartnersResponse {
  /**
   *
   * @type {Array<ParticipantPartner>}
   * @memberof DealsItemPartnersResponse
   */
  partners?: Array<ParticipantPartner>;
}
/**
 *
 * @export
 * @interface DealsItemPositionsItemDeleteResponse
 */
export interface DealsItemPositionsItemDeleteResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsItemPositionsItemDeleteResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemPositionsItemMoveRequest
 */
export interface DealsItemPositionsItemMoveRequest {
  /**
   *
   * @type {number}
   * @memberof DealsItemPositionsItemMoveRequest
   */
  position?: number;
}
/**
 *
 * @export
 * @interface DealsItemPositionsItemMoveResponse
 */
export interface DealsItemPositionsItemMoveResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsItemPositionsItemMoveResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemPositionsItemReplaceRequest
 */
export interface DealsItemPositionsItemReplaceRequest {
  /**
   * Новый товар, который ставим вместо текущего
   * @type {string}
   * @memberof DealsItemPositionsItemReplaceRequest
   */
  productCode?: string;
}
/**
 *
 * @export
 * @interface DealsItemPositionsItemReplaceResponse
 */
export interface DealsItemPositionsItemReplaceResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsItemPositionsItemReplaceResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemProlongResponse
 */
export interface DealsItemProlongResponse {
  /**
   *
   * @type {string}
   * @memberof DealsItemProlongResponse
   */
  dealCode?: string;
  /**
   *
   * @type {string}
   * @memberof DealsItemProlongResponse
   */
  validUntil?: string;
}
/**
 *
 * @export
 * @interface DealsItemRecalculateMotivationRequest
 */
export interface DealsItemRecalculateMotivationRequest {
  /**
   * Пересчитать заявку
   * @type {boolean}
   * @memberof DealsItemRecalculateMotivationRequest
   */
  recalculateDeal?: boolean;
  /**
   * Пересчитать отгрузки
   * @type {boolean}
   * @memberof DealsItemRecalculateMotivationRequest
   */
  recalculateShipment?: boolean;
}
/**
 *
 * @export
 * @interface DealsItemResponse
 */
export interface DealsItemResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsItemResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemSaveRequest
 */
export interface DealsItemSaveRequest {
  /**
   *
   * @type {string}
   * @memberof DealsItemSaveRequest
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DealsItemSaveRequest
   */
  frontCode?: string;
  /**
   *
   * @type {EventSource}
   * @memberof DealsItemSaveRequest
   */
  source?: EventSource;
  /**
   *
   * @type {DealUpdates}
   * @memberof DealsItemSaveRequest
   */
  updates?: DealUpdates;
}
/**
 *
 * @export
 * @interface DealsItemSaveResponse
 */
export interface DealsItemSaveResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsItemSaveResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemSpecificationPdfRequest
 */
export interface DealsItemSpecificationPdfRequest {
  /**
   * Код подписанта со стороны покупателя
   * @type {string}
   * @memberof DealsItemSpecificationPdfRequest
   */
  representativeCustomerCode?: string;
  /**
   * Код сотрудника-подписанта
   * @type {string}
   * @memberof DealsItemSpecificationPdfRequest
   */
  signerEmployeeCode?: string;
  /**
   *
   * @type {Array<PdfText>}
   * @memberof DealsItemSpecificationPdfRequest
   */
  texts?: Array<PdfText>;
}
/**
 *
 * @export
 * @interface DealsItemSpecificationPdfResponse
 */
export interface DealsItemSpecificationPdfResponse {
  /**
   *
   * @type {Array<PdfText>}
   * @memberof DealsItemSpecificationPdfResponse
   */
  texts?: Array<PdfText>;
}
/**
 *
 * @export
 * @interface DealsItemSpecificationResponse
 */
export interface DealsItemSpecificationResponse {
  /**
   * Представители продавца (ДФ)
   * @type {Array<Representative>}
   * @memberof DealsItemSpecificationResponse
   */
  officeChiefs?: Array<Representative>;
  /**
   *
   * @type {Array<PdfPosition>}
   * @memberof DealsItemSpecificationResponse
   */
  positions?: Array<PdfPosition>;
  /**
   * Представители покупателя
   * @type {Array<Representative>}
   * @memberof DealsItemSpecificationResponse
   */
  representativesCustomer?: Array<Representative>;
  /**
   * Путь к изображению печати поставщика
   * @type {string}
   * @memberof DealsItemSpecificationResponse
   */
  supplierStamp?: string;
  /**
   *
   * @type {Array<PdfText>}
   * @memberof DealsItemSpecificationResponse
   */
  texts?: Array<PdfText>;
}
/**
 *
 * @export
 * @interface DealsItemSubmitResponse
 */
export interface DealsItemSubmitResponse {
  /**
   *
   * @type {Deal}
   * @memberof DealsItemSubmitResponse
   */
  deal?: Deal;
}
/**
 *
 * @export
 * @interface DealsItemUpdatePartnerAddressRequest
 */
export interface DealsItemUpdatePartnerAddressRequest {
  /**
   *
   * @type {string}
   * @memberof DealsItemUpdatePartnerAddressRequest
   */
  addressCode?: string;
  /**
   * Код партнера
   * @type {string}
   * @memberof DealsItemUpdatePartnerAddressRequest
   */
  participantCode?: string;
  /**
   *
   * @type {string}
   * @memberof DealsItemUpdatePartnerAddressRequest
   */
  type?: DealsItemUpdatePartnerAddressRequestTypeEnum;
}

export const DealsItemUpdatePartnerAddressRequestTypeEnum = {
  Consignee: 'consignee',
} as const;

export type DealsItemUpdatePartnerAddressRequestTypeEnum =
  (typeof DealsItemUpdatePartnerAddressRequestTypeEnum)[keyof typeof DealsItemUpdatePartnerAddressRequestTypeEnum];

/**
 *
 * @export
 * @interface DealsItemUpdatePartnerRequest
 */
export interface DealsItemUpdatePartnerRequest {
  /**
   * Код партнера
   * @type {string}
   * @memberof DealsItemUpdatePartnerRequest
   */
  participantCode?: string;
  /**
   *
   * @type {string}
   * @memberof DealsItemUpdatePartnerRequest
   */
  type?: DealsItemUpdatePartnerRequestTypeEnum;
}

export const DealsItemUpdatePartnerRequestTypeEnum = {
  Consignee: 'consignee',
  Payer: 'payer',
} as const;

export type DealsItemUpdatePartnerRequestTypeEnum =
  (typeof DealsItemUpdatePartnerRequestTypeEnum)[keyof typeof DealsItemUpdatePartnerRequestTypeEnum];

/**
 *
 * @export
 * @interface DealsItemWarehousesResponse
 */
export interface DealsItemWarehousesResponse {
  /**
   *
   * @type {Array<Warehouse>}
   * @memberof DealsItemWarehousesResponse
   */
  warehouses?: Array<Warehouse>;
}
/**
 *
 * @export
 * @interface DealsListResponse
 */
export interface DealsListResponse {
  /**
   *
   * @type {Array<Deal>}
   * @memberof DealsListResponse
   */
  deals?: Array<Deal>;
  /**
   *
   * @type {Array<DealBranchOffice>}
   * @memberof DealsListResponse
   */
  dealsBranchOffices?: Array<DealBranchOffice>;
  /**
   * Общая сумма, руб.
   * @type {number}
   * @memberof DealsListResponse
   */
  dealsTotalCost?: number;
  /**
   *
   * @type {number}
   * @memberof DealsListResponse
   */
  dealsTotalCount?: number;
  /**
   * Общий вес, кг.
   * @type {number}
   * @memberof DealsListResponse
   */
  dealsTotalWeight?: number;
}
/**
 *
 * @export
 * @interface DealsStatesResponse
 */
export interface DealsStatesResponse {
  /**
   *
   * @type {Array<DealState>}
   * @memberof DealsStatesResponse
   */
  states?: Array<DealState>;
}
/**
 *
 * @export
 * @interface DealsThreadRequest
 */
export interface DealsThreadRequest {
  /**
   *
   * @type {string}
   * @memberof DealsThreadRequest
   */
  dealCode?: string;
}
/**
 *
 * @export
 * @interface DealsThreadResponse
 */
export interface DealsThreadResponse {
  /**
   *
   * @type {DocumentsThread}
   * @memberof DealsThreadResponse
   */
  documentsThread?: DocumentsThread;
}
/**
 *
 * @export
 * @interface DebtRelief
 */
export interface DebtRelief {
  /**
   *
   * @type {number}
   * @memberof DebtRelief
   */
  amount?: number;
  /**
   * Код филиала
   * @type {string}
   * @memberof DebtRelief
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof DebtRelief
   */
  branchOfficeName?: string;
  /**
   * Код
   * @type {string}
   * @memberof DebtRelief
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DebtRelief
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof DebtRelief
   */
  currencyCode?: string;
  /**
   * Валюта
   * @type {string}
   * @memberof DebtRelief
   */
  currencyTitle?: string;
  /**
   *
   * @type {string}
   * @memberof DebtRelief
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof DebtRelief
   */
  documentNumber?: string;
  /**
   * Документ в статусе удален
   * @type {boolean}
   * @memberof DebtRelief
   */
  isDeleted?: boolean;
  /**
   *
   * @type {Array<DebtReliefLine>}
   * @memberof DebtRelief
   */
  lines?: Array<DebtReliefLine>;
  /**
   *
   * @type {string}
   * @memberof DebtRelief
   */
  updatedAt?: string;
}
/**
 *
 * @export
 * @interface DebtReliefLine
 */
export interface DebtReliefLine {
  /**
   *
   * @type {Bill}
   * @memberof DebtReliefLine
   */
  bill?: Bill;
  /**
   *
   * @type {number}
   * @memberof DebtReliefLine
   */
  billDebt?: number;
  /**
   *
   * @type {string}
   * @memberof DebtReliefLine
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof DebtReliefLine
   */
  holdingDate?: string;
  /**
   *
   * @type {number}
   * @memberof DebtReliefLine
   */
  paidAmount?: number;
  /**
   *
   * @type {number}
   * @memberof DebtReliefLine
   */
  positionNumber?: number;
  /**
   *
   * @type {string}
   * @memberof DebtReliefLine
   */
  updatedAt?: string;
  /**
   *
   * @type {number}
   * @memberof DebtReliefLine
   */
  withdrawalAmount?: number;
  /**
   *
   * @type {string}
   * @memberof DebtReliefLine
   */
  withdrawalDate?: string;
}
/**
 *
 * @export
 * @interface DebtReliefRequest
 */
export interface DebtReliefRequest {
  /**
   * Уникальный код счета
   * @type {string}
   * @memberof DebtReliefRequest
   */
  billCode?: string;
  /**
   * Коды филиалов
   * @type {Array<string>}
   * @memberof DebtReliefRequest
   */
  branchOfficeCodes?: Array<string> | null;
  /**
   * Код
   * @type {string}
   * @memberof DebtReliefRequest
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof DebtReliefRequest
   */
  count?: number | null;
  /**
   * Код клиента
   * @type {string}
   * @memberof DebtReliefRequest
   */
  customerCode?: string;
  /**
   * Дата от
   * @type {string}
   * @memberof DebtReliefRequest
   */
  dateFrom?: string | null;
  /**
   * Дата до
   * @type {string}
   * @memberof DebtReliefRequest
   */
  dateTo?: string | null;
  /**
   * Код сотрудника или группы
   * @type {string}
   * @memberof DebtReliefRequest
   */
  employeeSet?: string | null;
  /**
   *
   * @type {number}
   * @memberof DebtReliefRequest
   */
  page?: number | null;
  /**
   * Фильтр
   * @type {string}
   * @memberof DebtReliefRequest
   */
  query?: string | null;
}
/**
 *
 * @export
 * @interface DebtReliefResponse
 */
export interface DebtReliefResponse {
  /**
   *
   * @type {Array<DebtRelief>}
   * @memberof DebtReliefResponse
   */
  debtReliefs?: Array<DebtRelief>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof DebtReliefResponse
   */
  debtReliefsBranchOffices?: Array<BranchOffice>;
  /**
   *
   * @type {number}
   * @memberof DebtReliefResponse
   */
  debtReliefsTotalCount?: number;
}
/**
 *
 * @export
 * @interface DebtReliefThreadRequest
 */
export interface DebtReliefThreadRequest {
  /**
   * Код
   * @type {string}
   * @memberof DebtReliefThreadRequest
   */
  code?: string;
}
/**
 *
 * @export
 * @interface DebtReliefThreadResponse
 */
export interface DebtReliefThreadResponse {
  /**
   *
   * @type {DocumentsThread}
   * @memberof DebtReliefThreadResponse
   */
  documentsThread?: DocumentsThread;
}
/**
 * Тип доставки
 * @export
 * @interface DeliveryType
 */
export interface DeliveryType {
  /**
   * Код типа доставки
   * @type {string}
   * @memberof DeliveryType
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryType
   */
  name?: string;
}
/**
 *
 * @export
 * @interface DeliveryTypesResponse
 */
export interface DeliveryTypesResponse {
  /**
   *
   * @type {Array<DeliveryType>}
   * @memberof DeliveryTypesResponse
   */
  deliveryTypes?: Array<DeliveryType>;
}
/**
 *
 * @export
 * @interface DepartmentMarginalityReport
 */
export interface DepartmentMarginalityReport {
  /**
   *
   * @type {string}
   * @memberof DepartmentMarginalityReport
   */
  departmentCode?: string;
  /**
   *
   * @type {MarginalityReportMotivation}
   * @memberof DepartmentMarginalityReport
   */
  motivationReport?: MarginalityReportMotivation;
  /**
   *
   * @type {MarginalityReport}
   * @memberof DepartmentMarginalityReport
   */
  report?: MarginalityReport;
}
/**
 * Месячная статистика по премиям департамента
 * @export
 * @interface DepartmentMotivationReport
 */
export interface DepartmentMotivationReport {
  /**
   *
   * @type {string}
   * @memberof DepartmentMotivationReport
   */
  departmentCode?: string;
  /**
   *
   * @type {MotivationReport}
   * @memberof DepartmentMotivationReport
   */
  report?: MotivationReport;
}
/**
 *
 * @export
 * @interface DivisionAgreementsReport
 */
export interface DivisionAgreementsReport {
  /**
   *
   * @type {string}
   * @memberof DivisionAgreementsReport
   */
  divisionCode?: string;
  /**
   *
   * @type {AgreementsReport}
   * @memberof DivisionAgreementsReport
   */
  report?: AgreementsReport;
}
/**
 *
 * @export
 * @interface DivisionMarginalityReport
 */
export interface DivisionMarginalityReport {
  /**
   *
   * @type {string}
   * @memberof DivisionMarginalityReport
   */
  divisionCode?: string;
  /**
   *
   * @type {MarginalityReport}
   * @memberof DivisionMarginalityReport
   */
  report?: MarginalityReport;
}
/**
 * Месячная статистика по премиям дивизиона
 * @export
 * @interface DivisionMotivationReport
 */
export interface DivisionMotivationReport {
  /**
   *
   * @type {string}
   * @memberof DivisionMotivationReport
   */
  divisionCode?: string;
  /**
   *
   * @type {MotivationReport}
   * @memberof DivisionMotivationReport
   */
  report?: MotivationReport;
}
/**
 *
 * @export
 * @interface DocFlowContractSendListResponse
 */
export interface DocFlowContractSendListResponse {
  /**
   * Можно отправлять договор в систему СЭД
   * @type {boolean}
   * @memberof DocFlowContractSendListResponse
   */
  canSend?: boolean;
  /**
   *
   * @type {Array<DocFlowContractSending>}
   * @memberof DocFlowContractSendListResponse
   */
  sendings?: Array<DocFlowContractSending>;
}
/**
 *
 * @export
 * @interface DocFlowContractSending
 */
export interface DocFlowContractSending {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowContractSending
   */
  code?: string;
  /**
   *
   * @type {ClientContract}
   * @memberof DocFlowContractSending
   */
  contract?: ClientContract;
  /**
   *
   * @type {string}
   * @memberof DocFlowContractSending
   */
  createdAt?: string;
  /**
   *
   * @type {Array<ClientContractFileItem>}
   * @memberof DocFlowContractSending
   */
  files?: Array<ClientContractFileItem>;
  /**
   *
   * @type {Employee}
   * @memberof DocFlowContractSending
   */
  initiatorEmployee?: Employee;
  /**
   *
   * @type {Array<DocFlowContractSendingLog>}
   * @memberof DocFlowContractSending
   */
  logs?: Array<DocFlowContractSendingLog>;
  /**
   *
   * @type {DocFlowContractSendingStatus}
   * @memberof DocFlowContractSending
   */
  status?: DocFlowContractSendingStatus;
  /**
   *
   * @type {string}
   * @memberof DocFlowContractSending
   */
  updatedAt?: string;
  /**
   * Ссылка на документ в системе ЭДО
   * @type {string}
   * @memberof DocFlowContractSending
   */
  url?: string;
}
/**
 *
 * @export
 * @interface DocFlowContractSendingListResponse
 */
export interface DocFlowContractSendingListResponse {
  /**
   *
   * @type {Array<DocFlowContractSending>}
   * @memberof DocFlowContractSendingListResponse
   */
  sendings?: Array<DocFlowContractSending>;
  /**
   *
   * @type {number}
   * @memberof DocFlowContractSendingListResponse
   */
  sendingsTotalCount?: number;
}
/**
 *
 * @export
 * @interface DocFlowContractSendingLog
 */
export interface DocFlowContractSendingLog {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowContractSendingLog
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocFlowContractSendingLog
   */
  createdAt?: string;
  /**
   *
   * @type {DocFlowContractSendingStatus}
   * @memberof DocFlowContractSendingLog
   */
  status?: DocFlowContractSendingStatus;
  /**
   * Комментарий статуса
   * @type {string}
   * @memberof DocFlowContractSendingLog
   */
  statusComment?: string;
}
/**
 *
 * @export
 * @interface DocFlowContractSendingStatus
 */
export interface DocFlowContractSendingStatus {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowContractSendingStatus
   */
  code?: string;
  /**
   * Описание статуса
   * @type {string}
   * @memberof DocFlowContractSendingStatus
   */
  description?: string;
  /**
   * Наименование статуса
   * @type {string}
   * @memberof DocFlowContractSendingStatus
   */
  title?: string;
}
/**
 *
 * @export
 * @interface DocFlowCounterAgentOperator
 */
export interface DocFlowCounterAgentOperator {
  /**
   * Код оператора внешней системы ЭДО
   * @type {string}
   * @memberof DocFlowCounterAgentOperator
   */
  code?: string;
  /**
   * Наименование оператора внешней системы ЭДО
   * @type {string}
   * @memberof DocFlowCounterAgentOperator
   */
  title?: string;
}
/**
 *
 * @export
 * @interface DocFlowCounteragent
 */
export interface DocFlowCounteragent {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowCounteragent
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocFlowCounteragent
   */
  createdAt?: string;
  /**
   * Организация работает через роуминг
   * @type {boolean}
   * @memberof DocFlowCounteragent
   */
  isRoaming?: boolean;
  /**
   *
   * @type {DocFlowCounterAgentOperator}
   * @memberof DocFlowCounteragent
   */
  operator?: DocFlowCounterAgentOperator;
  /**
   * ИД контрагента в системе ЭДО
   * @type {string}
   * @memberof DocFlowCounteragent
   */
  orgId?: string;
  /**
   *
   * @type {DocFlowCounteragentStatus}
   * @memberof DocFlowCounteragent
   */
  status?: DocFlowCounteragentStatus;
  /**
   *
   * @type {string}
   * @memberof DocFlowCounteragent
   */
  updatedAt?: string;
}
/**
 *
 * @export
 * @interface DocFlowCounteragentStatus
 */
export interface DocFlowCounteragentStatus {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowCounteragentStatus
   */
  code?: string;
  /**
   * Описание статуса
   * @type {string}
   * @memberof DocFlowCounteragentStatus
   */
  description?: string;
  /**
   * Наименование статуса
   * @type {string}
   * @memberof DocFlowCounteragentStatus
   */
  title?: string;
}
/**
 *
 * @export
 * @interface DocFlowCounteragentsRefreshRequest
 */
export interface DocFlowCounteragentsRefreshRequest {
  /**
   * Код клиента
   * @type {string}
   * @memberof DocFlowCounteragentsRefreshRequest
   */
  customerCode: string;
}
/**
 *
 * @export
 * @interface DocFlowCounteragentsRefreshResponse
 */
export interface DocFlowCounteragentsRefreshResponse {
  /**
   *
   * @type {Array<DocFlowCounteragent>}
   * @memberof DocFlowCounteragentsRefreshResponse
   */
  counteragents?: Array<DocFlowCounteragent>;
}
/**
 *
 * @export
 * @interface DocFlowCounteragentsResponse
 */
export interface DocFlowCounteragentsResponse {
  /**
   *
   * @type {Array<DocFlowCounteragent>}
   * @memberof DocFlowCounteragentsResponse
   */
  counteragents?: Array<DocFlowCounteragent>;
}
/**
 *
 * @export
 * @interface DocFlowExchange
 */
export interface DocFlowExchange {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowExchange
   */
  code?: string;
  /**
   *
   * @type {ClientContract}
   * @memberof DocFlowExchange
   */
  contract?: ClientContract;
  /**
   *
   * @type {string}
   * @memberof DocFlowExchange
   */
  createdAt?: string;
  /**
   *
   * @type {Customer}
   * @memberof DocFlowExchange
   */
  customer?: Customer;
  /**
   *
   * @type {Array<DocFlowExchangeFile>}
   * @memberof DocFlowExchange
   */
  files?: Array<DocFlowExchangeFile>;
  /**
   *
   * @type {Employee}
   * @memberof DocFlowExchange
   */
  initiatorEmployee?: Employee;
  /**
   *
   * @type {Array<DocFlowExchangeLog>}
   * @memberof DocFlowExchange
   */
  logs?: Array<DocFlowExchangeLog>;
  /**
   *
   * @type {SaleShipment}
   * @memberof DocFlowExchange
   */
  shipment?: SaleShipment;
  /**
   *
   * @type {DocFlowExchangeState}
   * @memberof DocFlowExchange
   */
  state?: DocFlowExchangeState;
  /**
   *
   * @type {DocFlowExchangeType}
   * @memberof DocFlowExchange
   */
  type?: DocFlowExchangeType;
  /**
   *
   * @type {string}
   * @memberof DocFlowExchange
   */
  updatedAt?: string;
  /**
   * Ссылка на документ в системе ЭДО
   * @type {string}
   * @memberof DocFlowExchange
   */
  url?: string;
}
/**
 *
 * @export
 * @interface DocFlowExchangeFile
 */
export interface DocFlowExchangeFile {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowExchangeFile
   */
  code?: string;
  /**
   * Наименование файла
   * @type {string}
   * @memberof DocFlowExchangeFile
   */
  filename?: string;
  /**
   *
   * @type {Array<DocFlowExchangeFileLog>}
   * @memberof DocFlowExchangeFile
   */
  logs?: Array<DocFlowExchangeFileLog>;
  /**
   *
   * @type {DocFlowExchangeFileState}
   * @memberof DocFlowExchangeFile
   */
  state?: DocFlowExchangeFileState;
  /**
   * Ссылка на файл
   * @type {string}
   * @memberof DocFlowExchangeFile
   */
  url?: string;
  /**
   * Ссылка на зип архив из внешней системы СЭД
   * @type {string}
   * @memberof DocFlowExchangeFile
   */
  zipUrl?: string;
}
/**
 *
 * @export
 * @interface DocFlowExchangeFileLog
 */
export interface DocFlowExchangeFileLog {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowExchangeFileLog
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocFlowExchangeFileLog
   */
  createdAt?: string;
  /**
   *
   * @type {DocFlowExchangeFileState}
   * @memberof DocFlowExchangeFileLog
   */
  state?: DocFlowExchangeFileState;
  /**
   * Комментарий статуса
   * @type {string}
   * @memberof DocFlowExchangeFileLog
   */
  stateComment?: string;
}
/**
 *
 * @export
 * @interface DocFlowExchangeFileState
 */
export interface DocFlowExchangeFileState {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowExchangeFileState
   */
  code?: string;
  /**
   * Описание статуса
   * @type {string}
   * @memberof DocFlowExchangeFileState
   */
  description?: string;
  /**
   * Наименование статуса
   * @type {string}
   * @memberof DocFlowExchangeFileState
   */
  title?: string;
}
/**
 *
 * @export
 * @interface DocFlowExchangeListResponse
 */
export interface DocFlowExchangeListResponse {
  /**
   *
   * @type {Array<DocFlowExchange>}
   * @memberof DocFlowExchangeListResponse
   */
  exchanges?: Array<DocFlowExchange>;
  /**
   *
   * @type {number}
   * @memberof DocFlowExchangeListResponse
   */
  exchangesTotalCount?: number;
}
/**
 *
 * @export
 * @interface DocFlowExchangeLog
 */
export interface DocFlowExchangeLog {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowExchangeLog
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocFlowExchangeLog
   */
  createdAt?: string;
  /**
   *
   * @type {DocFlowExchangeState}
   * @memberof DocFlowExchangeLog
   */
  state?: DocFlowExchangeState;
  /**
   * Комментарий статуса
   * @type {string}
   * @memberof DocFlowExchangeLog
   */
  statusComment?: string;
}
/**
 *
 * @export
 * @interface DocFlowExchangeState
 */
export interface DocFlowExchangeState {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowExchangeState
   */
  code?: string;
  /**
   * Описание статуса
   * @type {string}
   * @memberof DocFlowExchangeState
   */
  description?: string;
  /**
   * Наименование статуса
   * @type {string}
   * @memberof DocFlowExchangeState
   */
  title?: string;
}
/**
 *
 * @export
 * @interface DocFlowExchangeType
 */
export interface DocFlowExchangeType {
  /**
   * Код
   * @type {string}
   * @memberof DocFlowExchangeType
   */
  code?: string;
  /**
   * Описание типа
   * @type {string}
   * @memberof DocFlowExchangeType
   */
  description?: string;
  /**
   * Наименование типа
   * @type {string}
   * @memberof DocFlowExchangeType
   */
  title?: string;
}
/**
 *
 * @export
 * @interface DocFlowExchangeTypeListResponse
 */
export interface DocFlowExchangeTypeListResponse {
  /**
   *
   * @type {Array<DocFlowExchangeType>}
   * @memberof DocFlowExchangeTypeListResponse
   */
  types?: Array<DocFlowExchangeType>;
}
/**
 * Согласование документа
 * @export
 * @interface DocumentAgreementApproval
 */
export interface DocumentAgreementApproval {
  /**
   * Код запроса согласования.
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  askCode?: string;
  /**
   * Дата отправки на согласования.
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  askDate?: string | null;
  /**
   *
   * @type {Array<DocumentNotice>}
   * @memberof DocumentAgreementApproval
   */
  confirmNotices?: Array<DocumentNotice>;
  /**
   * Видимость согласования.
   * @type {boolean}
   * @memberof DocumentAgreementApproval
   */
  controlVisible?: boolean;
  /**
   *
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  currentAccessGroup?: string;
  /**
   * for debug only
   * @type {object}
   * @memberof DocumentAgreementApproval
   */
  extra?: object;
  /**
   * Комментарий к резолюции
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  resolutionComment?: string;
  /**
   * Дата-время установки резолюции. Может быть не заполнено.
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  resolutionDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  resolutionEmployeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  resolutionEmployeeName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  resolutionEmployeePatronymic?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  resolutionEmployeeSurname?: string;
  /**
   * Список группы необходимых для согласования соглашения и причины появления группы
   * @type {Array<DocumentApprovalResolutionGroupReason>}
   * @memberof DocumentAgreementApproval
   */
  resolutionGroupReasons?: Array<DocumentApprovalResolutionGroupReason>;
  /**
   * Минимально необходимая группа для согласования заявки
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  resolutionMinimumGroup?: string;
  /**
   * Список групп, которые могут согласовать заявку
   * @type {Array<string>}
   * @memberof DocumentAgreementApproval
   */
  resolutionPossibleGroups?: Array<string>;
  /**
   * Состояние подтверждения
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  stateCode?: DocumentAgreementApprovalStateCodeEnum;
  /**
   *
   * @type {string}
   * @memberof DocumentAgreementApproval
   */
  stateTitle?: string;
}

export const DocumentAgreementApprovalStateCodeEnum = {
  Awaiting: 'awaiting',
  Approved: 'approved',
  Declined: 'declined',
  Signed: 'signed',
  AwaitingDepartment: 'awaiting_department',
  AwaitingOffice: 'awaiting_office',
  AwaitingDivision: 'awaiting_division',
  AwaitingCompany: 'awaiting_company',
  SelfApproved: 'self_approved',
} as const;

export type DocumentAgreementApprovalStateCodeEnum =
  (typeof DocumentAgreementApprovalStateCodeEnum)[keyof typeof DocumentAgreementApprovalStateCodeEnum];

/**
 *
 * @export
 * @interface DocumentApprovalHistory
 */
export interface DocumentApprovalHistory {
  /**
   *
   * @type {Array<DocumentApprovalHistoryEvent>}
   * @memberof DocumentApprovalHistory
   */
  logs?: Array<DocumentApprovalHistoryEvent>;
}
/**
 *
 * @export
 * @interface DocumentApprovalHistoryEvent
 */
export interface DocumentApprovalHistoryEvent {
  /**
   *
   * @type {string}
   * @memberof DocumentApprovalHistoryEvent
   */
  actionCode?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentApprovalHistoryEvent
   */
  actionTitle?: string;
  /**
   * Комментарий инициатора действия
   * @type {string}
   * @memberof DocumentApprovalHistoryEvent
   */
  comment?: string;
  /**
   *
   * @type {Array<DealUpdateLogChange>}
   * @memberof DocumentApprovalHistoryEvent
   */
  dealUpdateLogChanges?: Array<DealUpdateLogChange>;
  /**
   *
   * @type {Employee}
   * @memberof DocumentApprovalHistoryEvent
   */
  employee?: Employee;
  /**
   *
   * @type {string}
   * @memberof DocumentApprovalHistoryEvent
   */
  newStateCode?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentApprovalHistoryEvent
   */
  newStateTitle?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentApprovalHistoryEvent
   */
  userGroupCode?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentApprovalHistoryEvent
   */
  userGroupName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentApprovalHistoryEvent
   */
  when?: string;
}
/**
 *
 * @export
 * @interface DocumentApprovalResolutionGroupReason
 */
export interface DocumentApprovalResolutionGroupReason {
  /**
   *
   * @type {string}
   * @memberof DocumentApprovalResolutionGroupReason
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentApprovalResolutionGroupReason
   */
  userGroupCode?: string;
}
/**
 *
 * @export
 * @interface DocumentApprovalState
 */
export interface DocumentApprovalState {
  /**
   * Код статуса
   * @type {string}
   * @memberof DocumentApprovalState
   */
  code?: string;
  /**
   * Заголовок статуса
   * @type {string}
   * @memberof DocumentApprovalState
   */
  title?: string;
}
/**
 * Ошибки или замечания к документу
 * @export
 * @interface DocumentNotice
 */
export interface DocumentNotice {
  /**
   * Код типа сообщения для кастомизации обработки
   * @type {string}
   * @memberof DocumentNotice
   */
  code?: string;
  /**
   * Дополнительные данные для кастомизации сообщения
   * @type {object}
   * @memberof DocumentNotice
   */
  data?: object;
  /**
   * Подготовленное сообщение для отображения пользователю
   * @type {string}
   * @memberof DocumentNotice
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentNotice
   */
  type?: DocumentNoticeTypeEnum;
}

export const DocumentNoticeTypeEnum = {
  Info: 'info',
  Warning: 'warning',
  Error: 'error',
} as const;

export type DocumentNoticeTypeEnum = (typeof DocumentNoticeTypeEnum)[keyof typeof DocumentNoticeTypeEnum];

/**
 *
 * @export
 * @interface DocumentType
 */
export interface DocumentType {
  /**
   * Код типа документа
   * @type {string}
   * @memberof DocumentType
   */
  code?: string;
  /**
   * Название типа документа
   * @type {string}
   * @memberof DocumentType
   */
  name?: string;
}
/**
 *
 * @export
 * @interface DocumentsApprovalStatesResponse
 */
export interface DocumentsApprovalStatesResponse {
  /**
   *
   * @type {Array<DocumentApprovalState>}
   * @memberof DocumentsApprovalStatesResponse
   */
  states?: Array<DocumentApprovalState>;
}
/**
 *
 * @export
 * @interface DocumentsSearchAgreement
 */
export interface DocumentsSearchAgreement {
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchAgreement
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchAgreement
   */
  companyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchAgreement
   */
  validFrom?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchAgreement
   */
  validUntil?: string;
}
/**
 *
 * @export
 * @interface DocumentsSearchApproval
 */
export interface DocumentsSearchApproval {
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchApproval
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchApproval
   */
  companyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchApproval
   */
  documentDate?: string;
}
/**
 *
 * @export
 * @interface DocumentsSearchBill
 */
export interface DocumentsSearchBill {
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchBill
   */
  companyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchBill
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchBill
   */
  mdmCode?: string;
}
/**
 *
 * @export
 * @interface DocumentsSearchContract
 */
export interface DocumentsSearchContract {
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchContract
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchContract
   */
  companyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchContract
   */
  contractDate?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchContract
   */
  contractNumber?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchContract
   */
  participantCode?: string;
}
/**
 *
 * @export
 * @interface DocumentsSearchDeal
 */
export interface DocumentsSearchDeal {
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchDeal
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchDeal
   */
  companyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchDeal
   */
  documentDate?: string;
}
/**
 *
 * @export
 * @interface DocumentsSearchDebt
 */
export interface DocumentsSearchDebt {
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchDebt
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchDebt
   */
  companyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchDebt
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchDebt
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface DocumentsSearchPayment
 */
export interface DocumentsSearchPayment {
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchPayment
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchPayment
   */
  companyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchPayment
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchPayment
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface DocumentsSearchResponse
 */
export interface DocumentsSearchResponse {
  /**
   *
   * @type {Array<DocumentsSearchAgreement>}
   * @memberof DocumentsSearchResponse
   */
  agreements?: Array<DocumentsSearchAgreement>;
  /**
   *
   * @type {Array<DocumentsSearchApproval>}
   * @memberof DocumentsSearchResponse
   */
  approvals?: Array<DocumentsSearchApproval>;
  /**
   *
   * @type {Array<DocumentsSearchBill>}
   * @memberof DocumentsSearchResponse
   */
  bills?: Array<DocumentsSearchBill>;
  /**
   *
   * @type {Array<DocumentsSearchContract>}
   * @memberof DocumentsSearchResponse
   */
  contracts?: Array<DocumentsSearchContract>;
  /**
   *
   * @type {Array<DocumentsSearchDeal>}
   * @memberof DocumentsSearchResponse
   */
  deals?: Array<DocumentsSearchDeal>;
  /**
   *
   * @type {Array<DocumentsSearchDebt>}
   * @memberof DocumentsSearchResponse
   */
  debts?: Array<DocumentsSearchDebt>;
  /**
   *
   * @type {Array<DocumentsSearchPayment>}
   * @memberof DocumentsSearchResponse
   */
  payments?: Array<DocumentsSearchPayment>;
  /**
   *
   * @type {Array<DocumentsSearchReturn>}
   * @memberof DocumentsSearchResponse
   */
  returns?: Array<DocumentsSearchReturn>;
  /**
   *
   * @type {Array<DocumentsSearchSalesInvoice>}
   * @memberof DocumentsSearchResponse
   */
  salesInvoices?: Array<DocumentsSearchSalesInvoice>;
  /**
   *
   * @type {Array<DocumentsSearchShipment>}
   * @memberof DocumentsSearchResponse
   */
  shipments?: Array<DocumentsSearchShipment>;
}
/**
 *
 * @export
 * @interface DocumentsSearchReturn
 */
export interface DocumentsSearchReturn {
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchReturn
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchReturn
   */
  companyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchReturn
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchReturn
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface DocumentsSearchSalesInvoice
 */
export interface DocumentsSearchSalesInvoice {
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchSalesInvoice
   */
  companyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchSalesInvoice
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchSalesInvoice
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface DocumentsSearchShipment
 */
export interface DocumentsSearchShipment {
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchShipment
   */
  companyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchShipment
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsSearchShipment
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface DocumentsThread
 */
export interface DocumentsThread {
  /**
   *
   * @type {ContractCostAgreement}
   * @memberof DocumentsThread
   */
  agreement?: ContractCostAgreement;
  /**
   *
   * @type {Array<Bill>}
   * @memberof DocumentsThread
   */
  bills?: Array<Bill>;
  /**
   *
   * @type {ClientContract}
   * @memberof DocumentsThread
   */
  clientContract?: ClientContract;
  /**
   *
   * @type {Customer}
   * @memberof DocumentsThread
   */
  customer?: Customer;
  /**
   *
   * @type {Array<Deal>}
   * @memberof DocumentsThread
   */
  deals?: Array<Deal>;
  /**
   *
   * @type {Array<DebtRelief>}
   * @memberof DocumentsThread
   */
  debtReliefs?: Array<DebtRelief>;
  /**
   *
   * @type {Array<PaymentTransaction>}
   * @memberof DocumentsThread
   */
  paymentTransactions?: Array<PaymentTransaction>;
  /**
   * Остаток по оплатам, руб.
   * @type {number}
   * @memberof DocumentsThread
   */
  paymentsRestCost?: number;
  /**
   * Итого по оплатам, руб.
   * @type {number}
   * @memberof DocumentsThread
   */
  paymentsTotalCost?: number;
  /**
   *
   * @type {Array<ShipmentReturn>}
   * @memberof DocumentsThread
   */
  returns?: Array<ShipmentReturn>;
  /**
   *
   * @type {Array<SaleShipment>}
   * @memberof DocumentsThread
   */
  saleShipments?: Array<SaleShipment>;
  /**
   * Остаток по отгрузкам, руб.
   * @type {number}
   * @memberof DocumentsThread
   */
  saleShipmentsRestCost?: number;
  /**
   * Остаток по отгрузкам, кг
   * @type {number}
   * @memberof DocumentsThread
   */
  saleShipmentsRestWeight?: number;
  /**
   *
   * @type {number}
   * @memberof DocumentsThread
   */
  saleShipmentsTotalCost?: number;
  /**
   * Итого по отгрузкам, кг
   * @type {number}
   * @memberof DocumentsThread
   */
  saleShipmentsTotalWeight?: number;
}
/**
 *
 * @export
 * @interface DocumentsTypesResponse
 */
export interface DocumentsTypesResponse {
  /**
   *
   * @type {Array<DocumentType>}
   * @memberof DocumentsTypesResponse
   */
  types?: Array<DocumentType>;
}
/**
 * Информация о сотруднике компании
 * @export
 * @interface Employee
 */
export interface Employee {
  /**
   *
   * @type {string}
   * @memberof Employee
   */
  avatarUrl?: string;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof Employee
   */
  branchOffices?: Array<BranchOffice>;
  /**
   *
   * @type {string}
   * @memberof Employee
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Employee
   */
  departmentCode?: string;
  /**
   *
   * @type {string}
   * @memberof Employee
   */
  departmentTitle?: string;
  /**
   * Замещающие сотрудники
   * @type {Array<EmployeeDeputy>}
   * @memberof Employee
   */
  deputies?: Array<EmployeeDeputy>;
  /**
   *
   * @type {string}
   * @memberof Employee
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Employee
   */
  patronymic?: string;
  /**
   *
   * @type {string}
   * @memberof Employee
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof Employee
   */
  title?: string;
}
/**
 * Месячная статистика по клиенту
 * @export
 * @interface EmployeeCustomerMarginalityReport
 */
export interface EmployeeCustomerMarginalityReport {
  /**
   *
   * @type {string}
   * @memberof EmployeeCustomerMarginalityReport
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeCustomerMarginalityReport
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeCustomerMarginalityReport
   */
  officeCode?: string;
  /**
   *
   * @type {MarginalityReport}
   * @memberof EmployeeCustomerMarginalityReport
   */
  report?: MarginalityReport;
}
/**
 *
 * @export
 * @interface EmployeeDepartment
 */
export interface EmployeeDepartment {
  /**
   *
   * @type {string}
   * @memberof EmployeeDepartment
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeDepartment
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeDepartment
   */
  title?: string;
}
/**
 * Замещающий сотрудник
 * @export
 * @interface EmployeeDeputy
 */
export interface EmployeeDeputy {
  /**
   *
   * @type {string}
   * @memberof EmployeeDeputy
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeDeputy
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeDeputy
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeDeputy
   */
  patronymic?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeDeputy
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeDeputy
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeDeputy
   */
  until?: string;
}
/**
 * Месячная статистика по сотруднику
 * @export
 * @interface EmployeeMarginalityReport
 */
export interface EmployeeMarginalityReport {
  /**
   *
   * @type {string}
   * @memberof EmployeeMarginalityReport
   */
  employeeCode?: string;
  /**
   *
   * @type {MarginalityReportMotivation}
   * @memberof EmployeeMarginalityReport
   */
  motivationReport?: MarginalityReportMotivation;
  /**
   *
   * @type {MarginalityReport}
   * @memberof EmployeeMarginalityReport
   */
  report?: MarginalityReport;
}
/**
 *
 * @export
 * @interface EmployeeMotivationItem
 */
export interface EmployeeMotivationItem {
  /**
   * Премия П3 (Без склада по центрозакупу)
   * @type {number}
   * @memberof EmployeeMotivationItem
   */
  centralPurchaseAmount?: number;
  /**
   *
   * @type {string}
   * @memberof EmployeeMotivationItem
   */
  descriptin?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeMotivationItem
   */
  motivationDate?: string;
  /**
   * Премия П1
   * @type {number}
   * @memberof EmployeeMotivationItem
   */
  p1amount?: number;
  /**
   * Премия П2
   * @type {number}
   * @memberof EmployeeMotivationItem
   */
  p2amount?: number;
  /**
   * Премия П3 (Без склада по самозакупу)
   * @type {number}
   * @memberof EmployeeMotivationItem
   */
  selfPurchaseAmount?: number;
  /**
   * Итоговая премия
   * @type {number}
   * @memberof EmployeeMotivationItem
   */
  totalAmount?: number;
  /**
   *
   * @type {string}
   * @memberof EmployeeMotivationItem
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeMotivationItem
   */
  typeName?: string;
}
/**
 * Месячная статистика по премиям сотрудника
 * @export
 * @interface EmployeeMotivationReport
 */
export interface EmployeeMotivationReport {
  /**
   *
   * @type {string}
   * @memberof EmployeeMotivationReport
   */
  employeeCode?: string;
  /**
   *
   * @type {MotivationReport}
   * @memberof EmployeeMotivationReport
   */
  report?: MotivationReport;
}
/**
 *
 * @export
 * @interface EmployeeSalePlanApproval
 */
export interface EmployeeSalePlanApproval {
  /**
   *
   * @type {string}
   * @memberof EmployeeSalePlanApproval
   */
  approvedAt?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeSalePlanApproval
   */
  customerCode?: string;
}
/**
 *
 * @export
 * @interface EmployeeSalePlanApproveRequest
 */
export interface EmployeeSalePlanApproveRequest {
  /**
   *
   * @type {string}
   * @memberof EmployeeSalePlanApproveRequest
   */
  month: string;
}
/**
 *
 * @export
 * @interface EmployeeSalePlanStatus
 */
export interface EmployeeSalePlanStatus {
  /**
   *
   * @type {Array<EmployeeSalePlanApproval>}
   * @memberof EmployeeSalePlanStatus
   */
  approvals?: Array<EmployeeSalePlanApproval>;
  /**
   *
   * @type {Array<string>}
   * @memberof EmployeeSalePlanStatus
   */
  awaitingApprovalCustomerCodes?: Array<string>;
}
/**
 * Сотрудник или группа сотрудников. Сущность используется там, где возможно указать или одного сотрудника, или группу, или критерий отбора.
 * @export
 * @interface EmployeeSet
 */
export interface EmployeeSet {
  /**
   * Код, указывающий на группу или человека
   * @type {string}
   * @memberof EmployeeSet
   */
  code?: string;
  /**
   *
   * @type {Employee}
   * @memberof EmployeeSet
   */
  employee?: Employee;
  /**
   *
   * @type {boolean}
   * @memberof EmployeeSet
   */
  isGroup?: boolean;
  /**
   * Название группы или ФИО сотрудника
   * @type {string}
   * @memberof EmployeeSet
   */
  title?: string;
}
/**
 *
 * @export
 * @interface EmployeesItemMotivationResponse
 */
export interface EmployeesItemMotivationResponse {
  /**
   * Премия П3 (Без склада по центрозакупу)
   * @type {number}
   * @memberof EmployeesItemMotivationResponse
   */
  centralPurchaseAmount?: number;
  /**
   *
   * @type {string}
   * @memberof EmployeesItemMotivationResponse
   */
  dateBegin?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeesItemMotivationResponse
   */
  dateEnd?: string;
  /**
   *
   * @type {Array<EmployeeMotivationItem>}
   * @memberof EmployeesItemMotivationResponse
   */
  items?: Array<EmployeeMotivationItem>;
  /**
   * Премия П1
   * @type {number}
   * @memberof EmployeesItemMotivationResponse
   */
  p1amount?: number;
  /**
   * Премия П2
   * @type {number}
   * @memberof EmployeesItemMotivationResponse
   */
  p2amount?: number;
  /**
   * Премия П3 (Без склада по самозакупу)
   * @type {number}
   * @memberof EmployeesItemMotivationResponse
   */
  selfPurchaseAmount?: number;
  /**
   * Итоговая премия
   * @type {number}
   * @memberof EmployeesItemMotivationResponse
   */
  totalAmount?: number;
}
/**
 *
 * @export
 * @interface EmployeesResponse
 */
export interface EmployeesResponse {
  /**
   *
   * @type {Array<Employee>}
   * @memberof EmployeesResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {number}
   * @memberof EmployeesResponse
   */
  total?: number;
}
/**
 *
 * @export
 * @interface EmployeesSearchRequest
 */
export interface EmployeesSearchRequest {
  /**
   *
   * @type {number}
   * @memberof EmployeesSearchRequest
   */
  count?: number | null;
  /**
   *
   * @type {EmployeesSearchRequestFilter}
   * @memberof EmployeesSearchRequest
   */
  filter?: EmployeesSearchRequestFilter;
  /**
   *
   * @type {number}
   * @memberof EmployeesSearchRequest
   */
  page?: number | null;
}
/**
 *
 * @export
 * @interface EmployeesSearchRequestFilter
 */
export interface EmployeesSearchRequestFilter {
  /**
   *
   * @type {string}
   * @memberof EmployeesSearchRequestFilter
   */
  branchOfficeCode?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof EmployeesSearchRequestFilter
   */
  hasUser?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof EmployeesSearchRequestFilter
   */
  query?: string | null;
  /**
   * Действие, для которого нужен список сотрудников. Помогает использовать более сложные фильтры, которые уже преднастроены с backend-а.
   * @type {string}
   * @memberof EmployeesSearchRequestFilter
   */
  specialAction?: EmployeesSearchRequestFilterSpecialActionEnum;
  /**
   * Ключ к объекту, к которому относится действие. Например, для task - это taskCode.
   * @type {string}
   * @memberof EmployeesSearchRequestFilter
   */
  specialActionObjectKey?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof EmployeesSearchRequestFilter
   */
  userGroupCodes?: Array<string> | null;
}

export const EmployeesSearchRequestFilterSpecialActionEnum = {
  CreateTask: 'createTask',
  UpdateTask: 'updateTask',
  CreateInteraction: 'createInteraction',
  UpdateInteraction: 'updateInteraction',
} as const;

export type EmployeesSearchRequestFilterSpecialActionEnum =
  (typeof EmployeesSearchRequestFilterSpecialActionEnum)[keyof typeof EmployeesSearchRequestFilterSpecialActionEnum];

/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {CommonError}
   * @memberof ErrorResponse
   */
  error?: CommonError;
}
/**
 * Источник события
 * @export
 * @interface EventSource
 */
export interface EventSource {
  /**
   * Код сущности (сделки, раздела каталога, соглашения и т.п.)
   * @type {string}
   * @memberof EventSource
   */
  itemCode?: string;
  /**
   * Тип элемента - источника события: страница, блок на странице, модальное окно и т.п. * `agreement` - страница соглашения по ценам * `catalog_category` - раздел каталога * `catalog_product` - карточка товара * `customers` - список клиентов * `customer` - страница клиента * `deals` - список заявок на сделку * `deal` - страница заявки на сделку
   * @type {string}
   * @memberof EventSource
   */
  kind?: string;
}
/**
 *
 * @export
 * @interface FaqItem
 */
export interface FaqItem {
  /**
   *
   * @type {string}
   * @memberof FaqItem
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof FaqItem
   */
  solution?: string;
  /**
   *
   * @type {string}
   * @memberof FaqItem
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof FaqItem
   */
  video_url?: string;
}
/**
 *
 * @export
 * @interface FaqResponse
 */
export interface FaqResponse {
  /**
   *
   * @type {Array<FaqItem>}
   * @memberof FaqResponse
   */
  faqs?: Array<FaqItem>;
}
/**
 *
 * @export
 * @interface FeedbackFormOpenRequest
 */
export interface FeedbackFormOpenRequest {
  /**
   * Текущее время на клиенте
   * @type {string}
   * @memberof FeedbackFormOpenRequest
   */
  now?: string;
  /**
   * Прочие данные для отладки
   * @type {object}
   * @memberof FeedbackFormOpenRequest
   */
  other?: object;
  /**
   * Текущая страница
   * @type {string}
   * @memberof FeedbackFormOpenRequest
   */
  page?: string;
}
/**
 *
 * @export
 * @interface FeedbackFormOpenResponse
 */
export interface FeedbackFormOpenResponse {
  /**
   * Идентификатор открытой формы
   * @type {string}
   * @memberof FeedbackFormOpenResponse
   */
  formGuid?: string;
  /**
   * Время отправки последнего обращения
   * @type {string}
   * @memberof FeedbackFormOpenResponse
   */
  lastSendTime?: string | null;
}
/**
 *
 * @export
 * @interface FeedbackFormSendRequest
 */
export interface FeedbackFormSendRequest {
  /**
   * Идентификаторы вложений (файлов) формы
   * @type {Array<string>}
   * @memberof FeedbackFormSendRequest
   */
  attachments?: Array<string>;
  /**
   * Емейл для связи
   * @type {string}
   * @memberof FeedbackFormSendRequest
   */
  authorEmail?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackFormSendRequest
   */
  authorName?: string;
  /**
   * Телефон для связи
   * @type {string}
   * @memberof FeedbackFormSendRequest
   */
  authorPhone?: string;
  /**
   * Идентификатор открытой формы
   * @type {string}
   * @memberof FeedbackFormSendRequest
   */
  formGuid?: string;
  /**
   * Сообщение от пользователя
   * @type {string}
   * @memberof FeedbackFormSendRequest
   */
  message?: string;
  /**
   * Текущее время на клиенте
   * @type {string}
   * @memberof FeedbackFormSendRequest
   */
  now?: string;
  /**
   * Прочие данные для отладки
   * @type {object}
   * @memberof FeedbackFormSendRequest
   */
  other?: object;
  /**
   * Текущая страница
   * @type {string}
   * @memberof FeedbackFormSendRequest
   */
  page?: string;
}
/**
 *
 * @export
 * @interface FeedbackFormSendResponse
 */
export interface FeedbackFormSendResponse {
  /**
   * Время отправки последнего обращения
   * @type {string}
   * @memberof FeedbackFormSendResponse
   */
  lastSendTime?: string;
  /**
   * Номер зарегистрированного обращения
   * @type {string}
   * @memberof FeedbackFormSendResponse
   */
  messageNumber?: string;
}
/**
 *
 * @export
 * @interface FeedbackUploadAttachmentResponse
 */
export interface FeedbackUploadAttachmentResponse {
  /**
   * Идентификатор файла
   * @type {string}
   * @memberof FeedbackUploadAttachmentResponse
   */
  attachmentCode?: string;
}
/**
 * Прогноз показателя выполнения плана продаж на конец периода
 * @export
 * @interface Forecast
 */
export interface Forecast {
  /**
   *
   * @type {string}
   * @memberof Forecast
   */
  level?: ForecastLevelEnum;
  /**
   *
   * @type {number}
   * @memberof Forecast
   */
  value?: number;
}

export const ForecastLevelEnum = {
  Red: 'red',
  Yellow: 'yellow',
  Green: 'green',
} as const;

export type ForecastLevelEnum = (typeof ForecastLevelEnum)[keyof typeof ForecastLevelEnum];

/**
 *
 * @export
 * @interface Freeze
 */
export interface Freeze {
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  approvedAt?: string | null;
  /**
   *
   * @type {Employee}
   * @memberof Freeze
   */
  approvedByEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  authorComment?: string;
  /**
   *
   * @type {Employee}
   * @memberof Freeze
   */
  authorEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  branchOfficeName?: string;
  /**
   * Можно подтвердить
   * @type {boolean}
   * @memberof Freeze
   */
  canApprove?: boolean;
  /**
   * Можно обновить
   * @type {boolean}
   * @memberof Freeze
   */
  canUpdate?: boolean;
  /**
   * Можно снять резерв
   * @type {boolean}
   * @memberof Freeze
   */
  canWithdraw?: boolean;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  documentNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  freezeUntil?: string;
  /**
   * Сумма МРЦ на момент обновления
   * @type {number}
   * @memberof Freeze
   */
  mrcTotalCost?: number;
  /**
   *
   * @type {Array<FreezePosition>}
   * @memberof Freeze
   */
  positions?: Array<FreezePosition>;
  /**
   * Сумма МРЦ заморожено
   * @type {number}
   * @memberof Freeze
   */
  reservedMrcTotalCost?: number;
  /**
   * Общий вес замороженный
   * @type {number}
   * @memberof Freeze
   */
  reservedTotalWeight?: number;
  /**
   * Сумма МРЦ на момент запроса
   * @type {number}
   * @memberof Freeze
   */
  runtimeMrcTotalCost?: number;
  /**
   * Общиё вес на момент запроса
   * @type {number}
   * @memberof Freeze
   */
  runtimeTotalWeight?: number;
  /**
   * Общий вес на момент обновления
   * @type {number}
   * @memberof Freeze
   */
  totalWeight?: number;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  warehouseCode?: string;
  /**
   *
   * @type {string}
   * @memberof Freeze
   */
  warehouseName?: string;
  /**
   * Общий вес размороженный
   * @type {number}
   * @memberof Freeze
   */
  withdrawnTotalWeight?: number;
}
/**
 *
 * @export
 * @interface FreezePosition
 */
export interface FreezePosition {
  /**
   * Можно передать в счёт
   * @type {boolean}
   * @memberof FreezePosition
   */
  canGiveToBill?: boolean;
  /**
   * Можно снять резерв
   * @type {boolean}
   * @memberof FreezePosition
   */
  canWithdraw?: boolean;
  /**
   *
   * @type {string}
   * @memberof FreezePosition
   */
  code?: string;
  /**
   * Количество, доступное для передачи в счёт
   * @type {number}
   * @memberof FreezePosition
   */
  giveToBillAvailableQuantity?: number;
  /**
   * Позиция в архиве
   * @type {boolean}
   * @memberof FreezePosition
   */
  isArchive?: boolean;
  /**
   * Сумма МРЦ на момент обновления
   * @type {number}
   * @memberof FreezePosition
   */
  mrcTotalCost?: number;
  /**
   * Цена МРЦ на момент обновления
   * @type {number}
   * @memberof FreezePosition
   */
  mrcUnitCost?: number;
  /**
   *
   * @type {number}
   * @memberof FreezePosition
   */
  positionNumber?: number;
  /**
   *
   * @type {Product}
   * @memberof FreezePosition
   */
  product?: Product;
  /**
   * Количество в БЕИ
   * @type {number}
   * @memberof FreezePosition
   */
  quantity?: number;
  /**
   * Сумма МРЦ заморожено
   * @type {number}
   * @memberof FreezePosition
   */
  reservedMrcTotalCost?: number;
  /**
   * Зарезервированное количество в БЕИ
   * @type {number}
   * @memberof FreezePosition
   */
  reservedQuantity?: number;
  /**
   * Сумма МРЦ на момент запроса
   * @type {number}
   * @memberof FreezePosition
   */
  runtimeMrcTotalCost?: number;
  /**
   * Цена МРЦ на момент запроса
   * @type {number}
   * @memberof FreezePosition
   */
  runtimeMrcUnitCost?: number;
  /**
   * Вес на момент запроса
   * @type {number}
   * @memberof FreezePosition
   */
  runtimeWeight?: number;
  /**
   * Вес на момент обновления
   * @type {number}
   * @memberof FreezePosition
   */
  weight?: number;
  /**
   * Количество, доступное для снятия с резерва
   * @type {number}
   * @memberof FreezePosition
   */
  withdrawAvailableQuantity?: number;
  /**
   * Количество в БЕИ, снятое с резерва (или переданное в счета)
   * @type {number}
   * @memberof FreezePosition
   */
  withdrawnQuantity?: number;
}
/**
 *
 * @export
 * @interface FreezeUpdates
 */
export interface FreezeUpdates {
  /**
   *
   * @type {string}
   * @memberof FreezeUpdates
   */
  authorComment?: string | null;
  /**
   *
   * @type {string}
   * @memberof FreezeUpdates
   */
  freezeUntil?: string | null;
  /**
   *
   * @type {Array<FreezeUpdatesPosition>}
   * @memberof FreezeUpdates
   */
  positions?: Array<FreezeUpdatesPosition>;
  /**
   *
   * @type {string}
   * @memberof FreezeUpdates
   */
  warehouseCode?: string | null;
}
/**
 *
 * @export
 * @interface FreezeUpdatesPosition
 */
export interface FreezeUpdatesPosition {
  /**
   * Код позиции - можно отправить пустым, чтобы добавить новую позицию. Но лучше всего сгенерировать временный uuid на фронте для избежания дублирования.
   * @type {string}
   * @memberof FreezeUpdatesPosition
   */
  code: string | null;
  /**
   * Удаление или восстановление позиции
   * @type {boolean}
   * @memberof FreezeUpdatesPosition
   */
  isArchive?: boolean | null;
  /**
   * Артикул товара. Обязателен при создании позиции
   * @type {string}
   * @memberof FreezeUpdatesPosition
   */
  productCode?: string | null;
  /**
   *
   * @type {number}
   * @memberof FreezeUpdatesPosition
   */
  quantity?: number | null;
}
/**
 *
 * @export
 * @interface FreezesApproveRequest
 */
export interface FreezesApproveRequest {
  /**
   * Код фриза
   * @type {string}
   * @memberof FreezesApproveRequest
   */
  code: string;
}
/**
 *
 * @export
 * @interface FreezesRequest
 */
export interface FreezesRequest {
  /**
   * Код фриза
   * @type {string}
   * @memberof FreezesRequest
   */
  code?: string | null;
  /**
   *
   * @type {number}
   * @memberof FreezesRequest
   */
  count?: number | null;
  /**
   * Дата от
   * @type {string}
   * @memberof FreezesRequest
   */
  dateFrom?: string | null;
  /**
   * Дата до
   * @type {string}
   * @memberof FreezesRequest
   */
  dateTo?: string | null;
  /**
   *
   * @type {string}
   * @memberof FreezesRequest
   */
  documentNumber?: string | null;
  /**
   *
   * @type {number}
   * @memberof FreezesRequest
   */
  page?: number | null;
  /**
   * filter for freezes
   * @type {string}
   * @memberof FreezesRequest
   */
  query?: string | null;
  /**
   * Коды складов
   * @type {Array<string>}
   * @memberof FreezesRequest
   */
  warehouseCodes?: Array<string> | null;
}
/**
 *
 * @export
 * @interface FreezesResponse
 */
export interface FreezesResponse {
  /**
   *
   * @type {Array<Freeze>}
   * @memberof FreezesResponse
   */
  freezes?: Array<Freeze>;
  /**
   *
   * @type {number}
   * @memberof FreezesResponse
   */
  freezesMrcTotalCost?: number;
  /**
   * Сумма МРЦ заморожено
   * @type {number}
   * @memberof FreezesResponse
   */
  freezesReservedMrcTotalCost?: number;
  /**
   * Общий вес замороженный
   * @type {number}
   * @memberof FreezesResponse
   */
  freezesReservedTotalWeight?: number;
  /**
   *
   * @type {number}
   * @memberof FreezesResponse
   */
  freezesTotalCount?: number;
  /**
   *
   * @type {number}
   * @memberof FreezesResponse
   */
  freezesTotalWeight?: number;
}
/**
 *
 * @export
 * @interface FreezesSaveRequest
 */
export interface FreezesSaveRequest {
  /**
   * Код фриза - можно отправить пустым, чтобы создать новый документ
   * @type {string}
   * @memberof FreezesSaveRequest
   */
  code: string;
  /**
   *
   * @type {FreezeUpdates}
   * @memberof FreezesSaveRequest
   */
  updates: FreezeUpdates;
}
/**
 *
 * @export
 * @interface FreezesSaveResponse
 */
export interface FreezesSaveResponse {
  /**
   *
   * @type {Freeze}
   * @memberof FreezesSaveResponse
   */
  freeze?: Freeze;
}
/**
 *
 * @export
 * @interface FreezesWithdrawRequest
 */
export interface FreezesWithdrawRequest {
  /**
   * Код фриза
   * @type {string}
   * @memberof FreezesWithdrawRequest
   */
  freezeCode: string;
  /**
   * Код позиции (опционально, по-умолчанию все позиции)
   * @type {string}
   * @memberof FreezesWithdrawRequest
   */
  freezeProductCode?: string | null;
  /**
   * Количество, которое требуется снять с резерва
   * @type {number}
   * @memberof FreezesWithdrawRequest
   */
  withdrawQuantity?: number | null;
}
/**
 *
 * @export
 * @interface GetSalePlanCustomersHistoryRequest
 */
export interface GetSalePlanCustomersHistoryRequest {
  /**
   *
   * @type {string}
   * @memberof GetSalePlanCustomersHistoryRequest
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSalePlanCustomersHistoryRequest
   */
  customerCodes: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetSalePlanCustomersHistoryRequest
   */
  from?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GetSalePlanCustomersHistoryRequest
   */
  months?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof GetSalePlanCustomersHistoryRequest
   */
  to?: string;
}
/**
 *
 * @export
 * @interface GetSalePlanCustomersHistoryResponse
 */
export interface GetSalePlanCustomersHistoryResponse {
  /**
   *
   * @type {Array<SalePlanCustomersHistoryItem>}
   * @memberof GetSalePlanCustomersHistoryResponse
   */
  changes?: Array<SalePlanCustomersHistoryItem>;
}
/**
 *
 * @export
 * @interface HeadCompany
 */
export interface HeadCompany {
  /**
   *
   * @type {string}
   * @memberof HeadCompany
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof HeadCompany
   */
  title?: string;
}
/**
 * Месячная статистика по компании
 * @export
 * @interface HeadCompanyAgreementsReport
 */
export interface HeadCompanyAgreementsReport {
  /**
   *
   * @type {string}
   * @memberof HeadCompanyAgreementsReport
   */
  headCompanyCode?: string;
  /**
   *
   * @type {AgreementsReport}
   * @memberof HeadCompanyAgreementsReport
   */
  report?: AgreementsReport;
}
/**
 * Месячная статистика по компании
 * @export
 * @interface HeadCompanyMarginalityReport
 */
export interface HeadCompanyMarginalityReport {
  /**
   *
   * @type {string}
   * @memberof HeadCompanyMarginalityReport
   */
  headCompanyCode?: string;
  /**
   *
   * @type {MarginalityReport}
   * @memberof HeadCompanyMarginalityReport
   */
  report?: MarginalityReport;
}
/**
 * Месячная статистика по премиям компании
 * @export
 * @interface HeadCompanyMotivationReport
 */
export interface HeadCompanyMotivationReport {
  /**
   *
   * @type {string}
   * @memberof HeadCompanyMotivationReport
   */
  headCompanyCode?: string;
  /**
   *
   * @type {MotivationReport}
   * @memberof HeadCompanyMotivationReport
   */
  report?: MotivationReport;
}
/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  documentNumber?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  documentType?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  problemCode?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  srcFile?: string;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {Array<ClientSegment>}
   * @memberof InlineResponse200
   */
  segments?: Array<ClientSegment>;
}
/**
 *
 * @export
 * @interface MarginalityReport
 */
export interface MarginalityReport {
  /**
   *
   * @type {string}
   * @memberof MarginalityReport
   */
  beginDate?: string;
  /**
   *
   * @type {Array<MarginalityReportRow>}
   * @memberof MarginalityReport
   */
  details?: Array<MarginalityReportRow>;
  /**
   * Суммарная скидка по некондиции
   * @type {number}
   * @memberof MarginalityReport
   */
  factIlliquidDiscountSum?: number;
  /**
   * Полная фактическая маржинальность (с некондицией и неликвидом)
   * @type {number}
   * @memberof MarginalityReport
   */
  factMarginality?: number;
  /**
   * Чистая фактическая маржинальность
   * @type {number}
   * @memberof MarginalityReport
   */
  factMarginalityClear?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReport
   */
  factQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReport
   */
  factQuantityPlanned?: number | null;
  /**
   * Суммарная компенсация за самозакуп
   * @type {number}
   * @memberof MarginalityReport
   */
  factSelfPurchaseAwardSum?: number;
  /**
   * Суммарная скидка по неликвиду
   * @type {number}
   * @memberof MarginalityReport
   */
  factTodhDiscountSum?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReport
   */
  marginalityAchievement?: number;
  /**
   *
   * @type {Forecast}
   * @memberof MarginalityReport
   */
  marginalityForecast?: Forecast;
  /**
   *
   * @type {number}
   * @memberof MarginalityReport
   */
  overallAchievement?: number | null;
  /**
   *
   * @type {Forecast}
   * @memberof MarginalityReport
   */
  overallForecast?: Forecast;
  /**
   *
   * @type {OverallWeight}
   * @memberof MarginalityReport
   */
  overallWeight?: OverallWeight;
  /**
   *
   * @type {number}
   * @memberof MarginalityReport
   */
  planMarginality?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReport
   */
  planQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReport
   */
  quantityAchievement?: number;
  /**
   *
   * @type {Forecast}
   * @memberof MarginalityReport
   */
  quantityForecast?: Forecast;
}
/**
 *
 * @export
 * @interface MarginalityReportMotivation
 */
export interface MarginalityReportMotivation {
  /**
   * Пороги динамической шкалы
   * @type {Array<number>}
   * @memberof MarginalityReportMotivation
   */
  dynamicScaleThresholds?: Array<number>;
  /**
   * Мотивационная оценка с учетом веса по МД
   * @type {number}
   * @memberof MarginalityReportMotivation
   */
  marginCompletionFinalPct?: number;
  /**
   * Факт / план, % по МД
   * @type {number}
   * @memberof MarginalityReportMotivation
   */
  marginCompletionPct?: number;
  /**
   * Мотивационная оценка с учетом динамической шкалы по МД
   * @type {number}
   * @memberof MarginalityReportMotivation
   */
  marginCompletionScore?: number;
  /**
   * Факт по МД с учетом коэффициента по предоплате
   * @type {number}
   * @memberof MarginalityReportMotivation
   */
  marginPrepayFinalPct?: number;
  /**
   * Мотивационная оценка с учетом веса по Т
   * @type {number}
   * @memberof MarginalityReportMotivation
   */
  quantityCompletionFinalPct?: number;
  /**
   * Факт / план, % по Т
   * @type {number}
   * @memberof MarginalityReportMotivation
   */
  quantityCompletionPct?: number;
  /**
   * Мотивационная оценка с учетом динамической шкалы по Т
   * @type {number}
   * @memberof MarginalityReportMotivation
   */
  quantityCompletionScore?: number;
  /**
   * Факт по Т с учетом коэффициента по предоплате
   * @type {number}
   * @memberof MarginalityReportMotivation
   */
  quantityPrepayFinalPct?: number;
  /**
   * Итоговая оценка по всем КПЭ
   * @type {number}
   * @memberof MarginalityReportMotivation
   */
  totalCompletionPct?: number;
}
/**
 * Строка отчета маржинальности (по бюджетной группе)
 * @export
 * @interface MarginalityReportRow
 */
export interface MarginalityReportRow {
  /**
   *
   * @type {string}
   * @memberof MarginalityReportRow
   */
  beginDate?: string;
  /**
   *
   * @type {string}
   * @memberof MarginalityReportRow
   */
  budgetGroupCode?: string;
  /**
   * Суммарная скидка по некондиции
   * @type {number}
   * @memberof MarginalityReportRow
   */
  factIlliquidDiscountSum?: number;
  /**
   * Полная фактическая маржинальность (с некондицией и неликвидом)
   * @type {number}
   * @memberof MarginalityReportRow
   */
  factMarginality?: number;
  /**
   * Чистая фактическая маржинальность
   * @type {number}
   * @memberof MarginalityReportRow
   */
  factMarginalityClear?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReportRow
   */
  factQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReportRow
   */
  factQuantityPlanned?: number | null;
  /**
   * Суммарная компенсация за самозакуп
   * @type {number}
   * @memberof MarginalityReportRow
   */
  factSelfPurchaseAwardSum?: number;
  /**
   * Суммарная скидка по неликвиду
   * @type {number}
   * @memberof MarginalityReportRow
   */
  factTodhDiscountSum?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReportRow
   */
  planMarginality?: number;
  /**
   * Плановая наценка
   * @type {number}
   * @memberof MarginalityReportRow
   */
  planMarkupAmount?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReportRow
   */
  planQuantity?: number;
  /**
   *
   * @type {Array<MarginalityReportSegmentRow>}
   * @memberof MarginalityReportRow
   */
  segments?: Array<MarginalityReportSegmentRow>;
}
/**
 * Строка отчета маржинальности
 * @export
 * @interface MarginalityReportSegmentRow
 */
export interface MarginalityReportSegmentRow {
  /**
   *
   * @type {string}
   * @memberof MarginalityReportSegmentRow
   */
  customerSegmentCode?: string;
  /**
   * Суммарная скидка по некондиции
   * @type {number}
   * @memberof MarginalityReportSegmentRow
   */
  factIlliquidDiscountSum?: number;
  /**
   * Полная маржинальность (с компенсацией)
   * @type {number}
   * @memberof MarginalityReportSegmentRow
   */
  factMarginality?: number;
  /**
   * Чистая маржинальность
   * @type {number}
   * @memberof MarginalityReportSegmentRow
   */
  factMarginalityClear?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReportSegmentRow
   */
  factQuantity?: number;
  /**
   * Суммарная компенсация за самозакуп
   * @type {number}
   * @memberof MarginalityReportSegmentRow
   */
  factSelfPurchaseAwardSum?: number;
  /**
   * Суммарная скидка по неликвиду
   * @type {number}
   * @memberof MarginalityReportSegmentRow
   */
  factTodhDiscountSum?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReportSegmentRow
   */
  planMarginality?: number;
  /**
   * Плановая наценка
   * @type {number}
   * @memberof MarginalityReportSegmentRow
   */
  planMarkupAmount?: number;
  /**
   *
   * @type {number}
   * @memberof MarginalityReportSegmentRow
   */
  planQuantity?: number;
}
/**
 *
 * @export
 * @interface MotivationReport
 */
export interface MotivationReport {
  /**
   * Итоговая премия
   * @type {number}
   * @memberof MotivationReport
   */
  amount?: number;
  /**
   * Премия на тонну
   * @type {number}
   * @memberof MotivationReport
   */
  amountTon?: number;
  /**
   * Бюджет на тонну
   * @type {number}
   * @memberof MotivationReport
   */
  budgetPerTon?: number;
  /**
   * Процент бюджета на тонну
   * @type {number}
   * @memberof MotivationReport
   */
  budgetPerTonPct?: number;
  /**
   * Премия по центрозакупу
   * @type {number}
   * @memberof MotivationReport
   */
  centralPurchaseAmount?: number;
  /**
   *
   * @type {Array<MotivationReportRow>}
   * @memberof MotivationReport
   */
  details?: Array<MotivationReportRow>;
  /**
   *
   * @type {MotivationReportTotal}
   * @memberof MotivationReport
   */
  detailsTotal?: MotivationReportTotal;
  /**
   * Максимальная премия БСЦ на тонну
   * @type {number}
   * @memberof MotivationReport
   */
  maxCentralPurchaseAmountTon?: number;
  /**
   * Максимальная премия П2 на тонну
   * @type {number}
   * @memberof MotivationReport
   */
  maxP2AmountTon?: number;
  /**
   * Максимальная премия БСС на тонну
   * @type {number}
   * @memberof MotivationReport
   */
  maxSelfPurchaseAmountTon?: number;
  /**
   * Премия П1
   * @type {number}
   * @memberof MotivationReport
   */
  p1Amount?: number;
  /**
   * Премия П2
   * @type {number}
   * @memberof MotivationReport
   */
  p2Amount?: number;
  /**
   * Премия по самозакупу
   * @type {number}
   * @memberof MotivationReport
   */
  selfPurchaseAmount?: number;
  /**
   * Общий вес в тоннах
   * @type {number}
   * @memberof MotivationReport
   */
  weightTon?: number;
}
/**
 *
 * @export
 * @interface MotivationReportRow
 */
export interface MotivationReportRow {
  /**
   * Итоговая премия
   * @type {number}
   * @memberof MotivationReportRow
   */
  amount?: number;
  /**
   * Премия на тонну
   * @type {number}
   * @memberof MotivationReportRow
   */
  amountTon?: number;
  /**
   * Средняя наценка от базового маржинального дохода
   * @type {number}
   * @memberof MotivationReportRow
   */
  averageMargin?: number;
  /**
   * Базовый маржинальный доход
   * @type {number}
   * @memberof MotivationReportRow
   */
  baseMarginIncome?: number;
  /**
   * Бюджетная группа
   * @type {string}
   * @memberof MotivationReportRow
   */
  budgetGroupCode?: string;
  /**
   * Премия по центрозакупу
   * @type {number}
   * @memberof MotivationReportRow
   */
  centralPurchaseAmount?: number;
  /**
   * Факт, т
   * @type {number}
   * @memberof MotivationReportRow
   */
  factQuantity?: number;
  /**
   * Максимальная премия БСЦ на тонну
   * @type {number}
   * @memberof MotivationReportRow
   */
  maxCentralPurchaseAmountTon?: number;
  /**
   * Максимальная премия П2 на тонну
   * @type {number}
   * @memberof MotivationReportRow
   */
  maxP2AmountTon?: number;
  /**
   * Максимальная премия БСС на тонну
   * @type {number}
   * @memberof MotivationReportRow
   */
  maxSelfPurchaseAmountTon?: number;
  /**
   * Премия П1
   * @type {number}
   * @memberof MotivationReportRow
   */
  p1Amount?: number;
  /**
   * Премия П1 на тонну
   * @type {number}
   * @memberof MotivationReportRow
   */
  p1AmountTon?: number;
  /**
   * Премия П2
   * @type {number}
   * @memberof MotivationReportRow
   */
  p2Amount?: number;
  /**
   * Премия П2+П3 на тонну
   * @type {number}
   * @memberof MotivationReportRow
   */
  p2p3AmountTon?: number;
  /**
   * План, т
   * @type {number}
   * @memberof MotivationReportRow
   */
  planQuantity?: number;
  /**
   * Премия по самозакупу
   * @type {number}
   * @memberof MotivationReportRow
   */
  selfPurchaseAmount?: number;
  /**
   * Вес в тоннах, который посчитан в мотивации
   * @type {number}
   * @memberof MotivationReportRow
   */
  weightTon?: number;
}
/**
 *
 * @export
 * @interface MotivationReportTotal
 */
export interface MotivationReportTotal {
  /**
   * Итоговая премия
   * @type {number}
   * @memberof MotivationReportTotal
   */
  amount?: number;
  /**
   * Премия на тонну
   * @type {number}
   * @memberof MotivationReportTotal
   */
  amountTon?: number;
  /**
   * Средняя наценка от базового маржинального дохода
   * @type {number}
   * @memberof MotivationReportTotal
   */
  averageMargin?: number;
  /**
   * Базовый маржинальный доход
   * @type {number}
   * @memberof MotivationReportTotal
   */
  baseMarginIncome?: number;
  /**
   * Премия по центрозакупу
   * @type {number}
   * @memberof MotivationReportTotal
   */
  centralPurchaseAmount?: number;
  /**
   * Факт, т
   * @type {number}
   * @memberof MotivationReportTotal
   */
  factQuantity?: number;
  /**
   * Максимальная премия БСЦ на тонну
   * @type {number}
   * @memberof MotivationReportTotal
   */
  maxCentralPurchaseAmountTon?: number;
  /**
   * Максимальная премия П2 на тонну
   * @type {number}
   * @memberof MotivationReportTotal
   */
  maxP2AmountTon?: number;
  /**
   * Максимальная премия БСС на тонну
   * @type {number}
   * @memberof MotivationReportTotal
   */
  maxSelfPurchaseAmountTon?: number;
  /**
   * Премия П1
   * @type {number}
   * @memberof MotivationReportTotal
   */
  p1Amount?: number;
  /**
   * Премия П1 на тонну
   * @type {number}
   * @memberof MotivationReportTotal
   */
  p1AmountTon?: number;
  /**
   * Премия П2
   * @type {number}
   * @memberof MotivationReportTotal
   */
  p2Amount?: number;
  /**
   * Премия П2+П3 на тонну
   * @type {number}
   * @memberof MotivationReportTotal
   */
  p2p3AmountTon?: number;
  /**
   * План, т
   * @type {number}
   * @memberof MotivationReportTotal
   */
  planQuantity?: number;
  /**
   * Премия по самозакупу
   * @type {number}
   * @memberof MotivationReportTotal
   */
  selfPurchaseAmount?: number;
  /**
   * Вес в тоннах, который посчитан в мотивации
   * @type {number}
   * @memberof MotivationReportTotal
   */
  weightTon?: number;
}
/**
 *
 * @export
 * @interface MyApprovalsAskingResponse
 */
export interface MyApprovalsAskingResponse {
  /**
   *
   * @type {Array<EmployeeSet>}
   * @memberof MyApprovalsAskingResponse
   */
  asking?: Array<EmployeeSet>;
}
/**
 *
 * @export
 * @interface MyApprovalsObjectsResponse
 */
export interface MyApprovalsObjectsResponse {
  /**
   *
   * @type {number}
   * @memberof MyApprovalsObjectsResponse
   */
  approvalsTotalCount?: number;
  /**
   *
   * @type {Array<ApprovalObject>}
   * @memberof MyApprovalsObjectsResponse
   */
  objects?: Array<ApprovalObject>;
}
/**
 *
 * @export
 * @interface MyBranchOfficesResponse
 */
export interface MyBranchOfficesResponse {
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof MyBranchOfficesResponse
   */
  branchOffices?: Array<BranchOffice>;
}
/**
 *
 * @export
 * @interface MyClientsCustomersResponse
 */
export interface MyClientsCustomersResponse {
  /**
   *
   * @type {Array<Customer>}
   * @memberof MyClientsCustomersResponse
   */
  customers?: Array<Customer>;
  /**
   *
   * @type {string}
   * @memberof MyClientsCustomersResponse
   */
  query?: string;
  /**
   *
   * @type {number}
   * @memberof MyClientsCustomersResponse
   */
  totalCount?: number;
}
/**
 *
 * @export
 * @interface MyClientsFiltersResponse
 */
export interface MyClientsFiltersResponse {
  /**
   * Выбранный фильтр по умолчанию
   * @type {string}
   * @memberof MyClientsFiltersResponse
   */
  defaultEmployee?: string;
  /**
   *
   * @type {Array<EmployeeSet>}
   * @memberof MyClientsFiltersResponse
   */
  employees?: Array<EmployeeSet>;
}
/**
 *
 * @export
 * @interface MyDealsResponse
 */
export interface MyDealsResponse {
  /**
   *
   * @type {Array<Deal>}
   * @memberof MyDealsResponse
   */
  deals?: Array<Deal>;
  /**
   *
   * @type {Array<DealBranchOffice>}
   * @memberof MyDealsResponse
   */
  dealsBranchOffices?: Array<DealBranchOffice>;
  /**
   *
   * @type {number}
   * @memberof MyDealsResponse
   */
  dealsTotalCount?: number;
}
/**
 *
 * @export
 * @interface MyDealsShortResponse
 */
export interface MyDealsShortResponse {
  /**
   *
   * @type {Array<Deal>}
   * @memberof MyDealsShortResponse
   */
  deals?: Array<Deal>;
  /**
   *
   * @type {Array<DealBranchOffice>}
   * @memberof MyDealsShortResponse
   */
  dealsBranchOffices?: Array<DealBranchOffice>;
}
/**
 * Товар для отчета
 * @export
 * @interface NomenclatureForReport
 */
export interface NomenclatureForReport {
  /**
   *
   * @type {string}
   * @memberof NomenclatureForReport
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof NomenclatureForReport
   */
  distributedQuantity?: number;
  /**
   * МРЦ с полномочиями директора дивизиона
   * @type {number}
   * @memberof NomenclatureForReport
   */
  divisionChiefUnitCost?: number;
  /**
   * В ожидании резерва
   * @type {number}
   * @memberof NomenclatureForReport
   */
  inAwaitingReserve?: number;
  /**
   * В накладных
   * @type {number}
   * @memberof NomenclatureForReport
   */
  inConsignment?: number;
  /**
   *
   * @type {number}
   * @memberof NomenclatureForReport
   */
  inFreeze?: number;
  /**
   *
   * @type {number}
   * @memberof NomenclatureForReport
   */
  inReserve?: number;
  /**
   *
   * @type {number}
   * @memberof NomenclatureForReport
   */
  inSoftReserve?: number;
  /**
   * Остаток, включая резервы
   * @type {number}
   * @memberof NomenclatureForReport
   */
  inStock?: number;
  /**
   * Остаток, доступный для продажи
   * @type {number}
   * @memberof NomenclatureForReport
   */
  inStockForSale?: number;
  /**
   *
   * @type {number}
   * @memberof NomenclatureForReport
   */
  inTransitQuantity?: number;
  /**
   * Дней в резерве ДО
   * @type {number}
   * @memberof NomenclatureForReport
   */
  maxReserveDays?: number;
  /**
   *
   * @type {string}
   * @memberof NomenclatureForReport
   */
  mdmKey?: string;
  /**
   * Дней в резерве ОТ
   * @type {number}
   * @memberof NomenclatureForReport
   */
  minReserveDays?: number;
  /**
   * Средневзвешенная цена по номенклатуре
   * @type {number}
   * @memberof NomenclatureForReport
   */
  minRetailUnitCost?: number;
  /**
   *
   * @type {string}
   * @memberof NomenclatureForReport
   */
  name?: string;
  /**
   * Срок самой новой партии
   * @type {number}
   * @memberof NomenclatureForReport
   */
  newShelfLife?: number | null;
  /**
   * Дельта между МРЦ с полномочиями филиала и средневзвешенной цены закупки
   * @type {number}
   * @memberof NomenclatureForReport
   */
  officeChiefPurchaseDeltaUnitCost?: number;
  /**
   * МРЦ с полномочиями директора филиала
   * @type {number}
   * @memberof NomenclatureForReport
   */
  officeChiefUnitCost?: number;
  /**
   * Срок самой старой партии
   * @type {number}
   * @memberof NomenclatureForReport
   */
  oldShelfLife?: number | null;
  /**
   * Цена будущей закупки
   * @type {number}
   * @memberof NomenclatureForReport
   */
  priceMotivation?: number;
  /**
   *
   * @type {Array<ProductForReport>}
   * @memberof NomenclatureForReport
   */
  products?: Array<ProductForReport>;
  /**
   * Средневзвешенная цена по номенклатуре
   * @type {number}
   * @memberof NomenclatureForReport
   */
  purchasePrice?: number;
  /**
   * Сумма зарезервированного в МРЦ по номенклатуре
   * @type {number}
   * @memberof NomenclatureForReport
   */
  reservedMrcTotalCost?: number;
  /**
   * Сумма зарезервированного в ценах закупки по номенклатуре
   * @type {number}
   * @memberof NomenclatureForReport
   */
  reservedPurchaseTotalCost?: number;
  /**
   * МРЦ с полномочиями руководителя отдела продаж
   * @type {number}
   * @memberof NomenclatureForReport
   */
  sellerChiefUnitCost?: number;
  /**
   * МРЦ с полномочиями менеджера по продажам
   * @type {number}
   * @memberof NomenclatureForReport
   */
  sellerUnitCost?: number;
  /**
   *
   * @type {string}
   * @memberof NomenclatureForReport
   */
  unitCode?: string;
  /**
   *
   * @type {string}
   * @memberof NomenclatureForReport
   */
  unitShortName?: string;
}
/**
 * Товар, который входит в номенклатуру
 * @export
 * @interface NomenclatureReservesProduct
 */
export interface NomenclatureReservesProduct {
  /**
   * Код товара
   * @type {string}
   * @memberof NomenclatureReservesProduct
   */
  code?: string;
  /**
   * Текущий товар
   * @type {boolean}
   * @memberof NomenclatureReservesProduct
   */
  isCurrent?: boolean;
  /**
   *
   * @type {Array<ProductItemReserve>}
   * @memberof NomenclatureReservesProduct
   */
  reserves?: Array<ProductItemReserve>;
  /**
   * Наименование товара
   * @type {string}
   * @memberof NomenclatureReservesProduct
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ObjectsPrepareRequest
 */
export interface ObjectsPrepareRequest {
  /**
   *
   * @type {string}
   * @memberof ObjectsPrepareRequest
   */
  frontCode?: string;
  /**
   *
   * @type {string}
   * @memberof ObjectsPrepareRequest
   */
  objectType?: string;
}
/**
 *
 * @export
 * @interface ObjectsPrepareResponse
 */
export interface ObjectsPrepareResponse {
  /**
   *
   * @type {string}
   * @memberof ObjectsPrepareResponse
   */
  code?: string;
}
/**
 *
 * @export
 * @interface OnesBillReconcilePostRequest
 */
export interface OnesBillReconcilePostRequest {
  /**
   * номер документа счет, в нумерации MX
   * @type {string}
   * @memberof OnesBillReconcilePostRequest
   */
  billnumber?: string;
}
/**
 *
 * @export
 * @interface OnesBillReservePostRequest
 */
export interface OnesBillReservePostRequest {
  /**
   * номер документа счет, в нумерации MX
   * @type {string}
   * @memberof OnesBillReservePostRequest
   */
  billnumber?: string;
  /**
   * каталог базы данных 1С, из которой сделан запрос
   * @type {string}
   * @memberof OnesBillReservePostRequest
   */
  catalogBase?: string;
  /**
   *
   * @type {Array<OnesBillReservePostRequestPosition>}
   * @memberof OnesBillReservePostRequest
   */
  positions?: Array<OnesBillReservePostRequestPosition>;
}
/**
 *
 * @export
 * @interface OnesBillReservePostRequestPosition
 */
export interface OnesBillReservePostRequestPosition {
  /**
   * номер позиции
   * @type {string}
   * @memberof OnesBillReservePostRequestPosition
   */
  line_number?: string;
  /**
   * mdm_key характеристики
   * @type {string}
   * @memberof OnesBillReservePostRequestPosition
   */
  product?: string;
  /**
   * количество в позиции в базовых ЕИ
   * @type {string}
   * @memberof OnesBillReservePostRequestPosition
   */
  quantity?: string;
  /**
   * количество, которое удалось зарезервировать в базовых ЕИ
   * @type {string}
   * @memberof OnesBillReservePostRequestPosition
   */
  reserve?: string;
  /**
   * свободный остаток по данному товару в базовых ЕИ (при reserve = 0)
   * @type {string}
   * @memberof OnesBillReservePostRequestPosition
   */
  stock_for_sale?: string;
  /**
   * mdm_key склада, на котором сформирован резерв
   * @type {string}
   * @memberof OnesBillReservePostRequestPosition
   */
  warehouse?: string;
}
/**
 *
 * @export
 * @interface OnesBillReservePostResponse
 */
export interface OnesBillReservePostResponse {
  /**
   * Хэш по информации MX
   * @type {string}
   * @memberof OnesBillReservePostResponse
   */
  currentHash?: string;
  /**
   * Признак несовпадения контуров работы 1С и МХ
   * @type {boolean}
   * @memberof OnesBillReservePostResponse
   */
  envSkipped?: boolean;
  /**
   * Хэш совпадает, сообщение было обработано одним из сценариев
   * @type {boolean}
   * @memberof OnesBillReservePostResponse
   */
  matchHash?: boolean;
  /**
   *
   * @type {string}
   * @memberof OnesBillReservePostResponse
   */
  processedScenario?: OnesBillReservePostResponseProcessedScenarioEnum;
  /**
   * Хэш переданной информации
   * @type {string}
   * @memberof OnesBillReservePostResponse
   */
  providedHash?: string;
}

export const OnesBillReservePostResponseProcessedScenarioEnum = {
  Positive: 'positive',
  Negative: 'negative',
} as const;

export type OnesBillReservePostResponseProcessedScenarioEnum =
  (typeof OnesBillReservePostResponseProcessedScenarioEnum)[keyof typeof OnesBillReservePostResponseProcessedScenarioEnum];

/**
 *
 * @export
 * @interface OnesReceiptPriceQueryPostRequest
 */
export interface OnesReceiptPriceQueryPostRequest {
  /**
   * mdmkey филиала
   * @type {string}
   * @memberof OnesReceiptPriceQueryPostRequest
   */
  branchOfficeMdmkey?: string;
  /**
   * артикул товара, по которому запрашивается цена
   * @type {string}
   * @memberof OnesReceiptPriceQueryPostRequest
   */
  productCode?: string;
}
/**
 *
 * @export
 * @interface OnesReceiptPriceQueryPostResponse
 */
export interface OnesReceiptPriceQueryPostResponse {
  /**
   *
   * @type {number}
   * @memberof OnesReceiptPriceQueryPostResponse
   */
  price?: number;
}
/**
 * Веса факторов для подсчёта общего показателя выполнения плана продаж
 * @export
 * @interface OverallWeight
 */
export interface OverallWeight {
  /**
   *
   * @type {number}
   * @memberof OverallWeight
   */
  margin?: number;
  /**
   *
   * @type {number}
   * @memberof OverallWeight
   */
  quantity?: number;
}
/**
 *
 * @export
 * @interface Participant
 */
export interface Participant {
  /**
   *
   * @type {string}
   * @memberof Participant
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Participant
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof Participant
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ParticipantAccessRequest
 */
export interface ParticipantAccessRequest {
  /**
   *
   * @type {string}
   * @memberof ParticipantAccessRequest
   */
  Code?: string;
}
/**
 *
 * @export
 * @interface ParticipantAccessRequestCreateRequest
 */
export interface ParticipantAccessRequestCreateRequest {
  /**
   * Коды филиалов, к которым надо привязать клиента
   * @type {Array<string>}
   * @memberof ParticipantAccessRequestCreateRequest
   */
  branchOfficeCodes: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ParticipantAccessRequestCreateRequest
   */
  customerCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParticipantAccessRequestCreateRequest
   */
  participantCode?: string | null;
}
/**
 *
 * @export
 * @interface ParticipantAccessRequestCreateResponse
 */
export interface ParticipantAccessRequestCreateResponse {
  /**
   *
   * @type {ParticipantAccessRequest}
   * @memberof ParticipantAccessRequestCreateResponse
   */
  ParticipantAccessRequest?: ParticipantAccessRequest;
}
/**
 *
 * @export
 * @interface ParticipantAccessRequestListResponse
 */
export interface ParticipantAccessRequestListResponse {
  /**
   *
   * @type {Array<ParticipantAccessRequest>}
   * @memberof ParticipantAccessRequestListResponse
   */
  ParticipantAccessRequests?: Array<ParticipantAccessRequest>;
}
/**
 *
 * @export
 * @interface ParticipantDocument
 */
export interface ParticipantDocument {
  /**
   * Код документа
   * @type {string}
   * @memberof ParticipantDocument
   */
  code?: string;
  /**
   * Дата создания
   * @type {string}
   * @memberof ParticipantDocument
   */
  createdAt?: string;
  /**
   * Описание документа
   * @type {string}
   * @memberof ParticipantDocument
   */
  description?: string;
  /**
   * Наименование файла
   * @type {string}
   * @memberof ParticipantDocument
   */
  filename?: string;
  /**
   * Размер файла
   * @type {number}
   * @memberof ParticipantDocument
   */
  filesize?: number;
  /**
   *
   * @type {Employee}
   * @memberof ParticipantDocument
   */
  initiatorEmployee?: Employee;
  /**
   *
   * @type {Participant}
   * @memberof ParticipantDocument
   */
  participant?: Participant;
  /**
   * Наименование документа
   * @type {string}
   * @memberof ParticipantDocument
   */
  title?: string;
  /**
   *
   * @type {ParticipantDocumentType}
   * @memberof ParticipantDocument
   */
  type?: ParticipantDocumentType;
  /**
   * Дата обновления
   * @type {string}
   * @memberof ParticipantDocument
   */
  updatedAt?: string;
  /**
   * Ссылка на файл
   * @type {string}
   * @memberof ParticipantDocument
   */
  url?: string;
}
/**
 *
 * @export
 * @interface ParticipantDocumentSaveResponse
 */
export interface ParticipantDocumentSaveResponse {
  /**
   *
   * @type {ParticipantDocument}
   * @memberof ParticipantDocumentSaveResponse
   */
  ParticipantDocument?: ParticipantDocument;
}
/**
 *
 * @export
 * @interface ParticipantDocumentType
 */
export interface ParticipantDocumentType {
  /**
   * Код типа документа
   * @type {string}
   * @memberof ParticipantDocumentType
   */
  code?: string;
  /**
   * Описание типа документа
   * @type {string}
   * @memberof ParticipantDocumentType
   */
  description?: string;
  /**
   * Наименование типа документа
   * @type {string}
   * @memberof ParticipantDocumentType
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ParticipantDocumentsResponse
 */
export interface ParticipantDocumentsResponse {
  /**
   *
   * @type {Array<ParticipantDocument>}
   * @memberof ParticipantDocumentsResponse
   */
  ParticipantDocuments?: Array<ParticipantDocument>;
}
/**
 *
 * @export
 * @interface ParticipantDocumentsTypesResponse
 */
export interface ParticipantDocumentsTypesResponse {
  /**
   *
   * @type {Array<ParticipantDocumentType>}
   * @memberof ParticipantDocumentsTypesResponse
   */
  ParticipantDocumentTypes?: Array<ParticipantDocumentType>;
}
/**
 *
 * @export
 * @interface ParticipantHistoricalInteraction
 */
export interface ParticipantHistoricalInteraction {
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  employeeTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  entryDate?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  groupCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  inn?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  mdmKey?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  participantTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  resultCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  resultContent?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteraction
   */
  subgroupCode?: string;
}
/**
 *
 * @export
 * @interface ParticipantHistoricalInteractionGroup
 */
export interface ParticipantHistoricalInteractionGroup {
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteractionGroup
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteractionGroup
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ParticipantHistoricalInteractionResult
 */
export interface ParticipantHistoricalInteractionResult {
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteractionResult
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteractionResult
   */
  subgroupCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteractionResult
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ParticipantHistoricalInteractionSubgroup
 */
export interface ParticipantHistoricalInteractionSubgroup {
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteractionSubgroup
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteractionSubgroup
   */
  groupCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantHistoricalInteractionSubgroup
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ParticipantHistoricalInteractionsResponse
 */
export interface ParticipantHistoricalInteractionsResponse {
  /**
   *
   * @type {Array<ParticipantHistoricalInteractionGroup>}
   * @memberof ParticipantHistoricalInteractionsResponse
   */
  groups?: Array<ParticipantHistoricalInteractionGroup>;
  /**
   *
   * @type {Array<ParticipantHistoricalInteraction>}
   * @memberof ParticipantHistoricalInteractionsResponse
   */
  interactions?: Array<ParticipantHistoricalInteraction>;
  /**
   *
   * @type {Array<ParticipantHistoricalInteractionResult>}
   * @memberof ParticipantHistoricalInteractionsResponse
   */
  results?: Array<ParticipantHistoricalInteractionResult>;
  /**
   *
   * @type {Array<ParticipantHistoricalInteractionSubgroup>}
   * @memberof ParticipantHistoricalInteractionsResponse
   */
  subgroups?: Array<ParticipantHistoricalInteractionSubgroup>;
}
/**
 *
 * @export
 * @interface ParticipantInteraction
 */
export interface ParticipantInteraction {
  /**
   *
   * @type {string}
   * @memberof ParticipantInteraction
   */
  code?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ParticipantInteraction
   */
  companyContactMemberCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteraction
   */
  createdAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof ParticipantInteraction
   */
  creatorEmployee?: Employee;
  /**
   *
   * @type {Array<Employee>}
   * @memberof ParticipantInteraction
   */
  employeeMembers?: Array<Employee>;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteraction
   */
  interactedAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof ParticipantInteraction
   */
  isIncoming?: boolean;
  /**
   *
   * @type {ParticipantInteractionReferences}
   * @memberof ParticipantInteraction
   */
  references?: ParticipantInteractionReferences;
  /**
   *
   * @type {Array<ParticipantInteractionResult>}
   * @memberof ParticipantInteraction
   */
  results?: Array<ParticipantInteractionResult>;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteraction
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteraction
   */
  typeTitle?: string;
}
/**
 *
 * @export
 * @interface ParticipantInteractionBill
 */
export interface ParticipantInteractionBill {
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionBill
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionBill
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionBill
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionBill
   */
  mdmCode?: string;
}
/**
 *
 * @export
 * @interface ParticipantInteractionDeal
 */
export interface ParticipantInteractionDeal {
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionDeal
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionDeal
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionDeal
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionDeal
   */
  documentDate?: string;
}
/**
 *
 * @export
 * @interface ParticipantInteractionPaymentTransaction
 */
export interface ParticipantInteractionPaymentTransaction {
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionPaymentTransaction
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionPaymentTransaction
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionPaymentTransaction
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionPaymentTransaction
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionPaymentTransaction
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface ParticipantInteractionReferences
 */
export interface ParticipantInteractionReferences {
  /**
   *
   * @type {ParticipantInteractionBill}
   * @memberof ParticipantInteractionReferences
   */
  bill?: ParticipantInteractionBill;
  /**
   *
   * @type {ParticipantInteractionDeal}
   * @memberof ParticipantInteractionReferences
   */
  deal?: ParticipantInteractionDeal;
  /**
   *
   * @type {ParticipantInteractionPaymentTransaction}
   * @memberof ParticipantInteractionReferences
   */
  paymentTransaction?: ParticipantInteractionPaymentTransaction;
  /**
   *
   * @type {ParticipantInteractionSaleShipment}
   * @memberof ParticipantInteractionReferences
   */
  saleShipment?: ParticipantInteractionSaleShipment;
}
/**
 *
 * @export
 * @interface ParticipantInteractionResult
 */
export interface ParticipantInteractionResult {
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResult
   */
  childResultTypeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResult
   */
  childResultTypeTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResult
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResult
   */
  resultTypeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResult
   */
  resultTypeTitle?: string;
}
/**
 *
 * @export
 * @interface ParticipantInteractionResultType
 */
export interface ParticipantInteractionResultType {
  /**
   *
   * @type {boolean}
   * @memberof ParticipantInteractionResultType
   */
  canCreateManually?: boolean;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResultType
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResultType
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResultType
   */
  groupCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResultType
   */
  groupTitle?: string;
  /**
   *
   * @type {boolean}
   * @memberof ParticipantInteractionResultType
   */
  isChildRequired?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParticipantInteractionResultType
   */
  isCommentRequired?: boolean;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResultType
   */
  parentCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionResultType
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ParticipantInteractionResultTypesResponse
 */
export interface ParticipantInteractionResultTypesResponse {
  /**
   *
   * @type {Array<ParticipantInteractionResultType>}
   * @memberof ParticipantInteractionResultTypesResponse
   */
  interactionResultTypes?: Array<ParticipantInteractionResultType>;
}
/**
 *
 * @export
 * @interface ParticipantInteractionSaleShipment
 */
export interface ParticipantInteractionSaleShipment {
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionSaleShipment
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionSaleShipment
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionSaleShipment
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionSaleShipment
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface ParticipantInteractionType
 */
export interface ParticipantInteractionType {
  /**
   *
   * @type {boolean}
   * @memberof ParticipantInteractionType
   */
  canCreateManually?: boolean;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionType
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionType
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionType
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ParticipantInteractionTypesResponse
 */
export interface ParticipantInteractionTypesResponse {
  /**
   *
   * @type {Array<ParticipantInteractionType>}
   * @memberof ParticipantInteractionTypesResponse
   */
  interactionTypes?: Array<ParticipantInteractionType>;
}
/**
 *
 * @export
 * @interface ParticipantInteractionsGetHistoryItem
 */
export interface ParticipantInteractionsGetHistoryItem {
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionsGetHistoryItem
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionsGetHistoryItem
   */
  initiatorEmployeeTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionsGetHistoryItem
   */
  interactionCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionsGetHistoryItem
   */
  interactionCreatedAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof ParticipantInteractionsGetHistoryItem
   */
  interactionCreatorEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionsGetHistoryItem
   */
  interactionTypeTitle?: string;
  /**
   * Взаимодействие является автоматически созданным
   * @type {boolean}
   * @memberof ParticipantInteractionsGetHistoryItem
   */
  isAutoInteraction?: boolean;
  /**
   *
   * @type {Array<Employee>}
   * @memberof ParticipantInteractionsGetHistoryItem
   */
  removeEmployeeMembers?: Array<Employee>;
  /**
   *
   * @type {Array<Employee>}
   * @memberof ParticipantInteractionsGetHistoryItem
   */
  saveEmployeeMembers?: Array<Employee>;
  /**
   *
   * @type {ParticipantInteractionsSaveUpdates}
   * @memberof ParticipantInteractionsGetHistoryItem
   */
  updates?: ParticipantInteractionsSaveUpdates;
}
/**
 *
 * @export
 * @interface ParticipantInteractionsGetHistoryResponse
 */
export interface ParticipantInteractionsGetHistoryResponse {
  /**
   *
   * @type {Array<ParticipantInteractionsGetHistoryItem>}
   * @memberof ParticipantInteractionsGetHistoryResponse
   */
  changes?: Array<ParticipantInteractionsGetHistoryItem>;
}
/**
 *
 * @export
 * @interface ParticipantInteractionsResponse
 */
export interface ParticipantInteractionsResponse {
  /**
   *
   * @type {boolean}
   * @memberof ParticipantInteractionsResponse
   */
  hasCreateAccess?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParticipantInteractionsResponse
   */
  hasEditAccess?: boolean;
  /**
   *
   * @type {Array<ParticipantInteraction>}
   * @memberof ParticipantInteractionsResponse
   */
  interactions?: Array<ParticipantInteraction>;
  /**
   *
   * @type {number}
   * @memberof ParticipantInteractionsResponse
   */
  interactionsTotalCount?: number;
}
/**
 *
 * @export
 * @interface ParticipantInteractionsSaveRequest
 */
export interface ParticipantInteractionsSaveRequest {
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionsSaveRequest
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionsSaveRequest
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionsSaveRequest
   */
  participantCode?: string;
  /**
   *
   * @type {ParticipantInteractionsSaveUpdates}
   * @memberof ParticipantInteractionsSaveRequest
   */
  updates: ParticipantInteractionsSaveUpdates;
}
/**
 *
 * @export
 * @interface ParticipantInteractionsSaveUpdates
 */
export interface ParticipantInteractionsSaveUpdates {
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionsSaveUpdates
   */
  interactedAt?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ParticipantInteractionsSaveUpdates
   */
  isIncoming?: boolean | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParticipantInteractionsSaveUpdates
   */
  removeCompanyContactMemberCodes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ParticipantInteractionsSaveUpdates
   */
  removeEmployeeMemberCodes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ParticipantInteractionsSaveUpdates
   */
  removeResultTypeCodes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ParticipantInteractionsSaveUpdates
   */
  saveCompanyContactMemberCodes?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ParticipantInteractionsSaveUpdates
   */
  saveEmployeeMemberCodes?: Array<string>;
  /**
   *
   * @type {Array<ParticipantInteractionResult>}
   * @memberof ParticipantInteractionsSaveUpdates
   */
  saveResults?: Array<ParticipantInteractionResult>;
  /**
   *
   * @type {string}
   * @memberof ParticipantInteractionsSaveUpdates
   */
  typeCode?: string | null;
}
/**
 *
 * @export
 * @interface ParticipantItemAddressesResponse
 */
export interface ParticipantItemAddressesResponse {
  /**
   *
   * @type {Array<Address>}
   * @memberof ParticipantItemAddressesResponse
   */
  addresses?: Array<Address>;
}
/**
 *
 * @export
 * @interface ParticipantPartner
 */
export interface ParticipantPartner {
  /**
   *
   * @type {Address}
   * @memberof ParticipantPartner
   */
  address?: Address;
  /**
   *
   * @type {string}
   * @memberof ParticipantPartner
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantPartner
   */
  customerCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof ParticipantPartner
   */
  isDefault?: boolean;
  /**
   *
   * @type {string}
   * @memberof ParticipantPartner
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantPartner
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantPartner
   */
  typeTitle?: string;
}
/**
 *
 * @export
 * @interface ParticipantRelationship
 */
export interface ParticipantRelationship {
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationship
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationship
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationship
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationship
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationship
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ParticipantRelationshipPartner
 */
export interface ParticipantRelationshipPartner {
  /**
   * Код филиала
   * @type {string}
   * @memberof ParticipantRelationshipPartner
   */
  branchOfficeCode?: string;
  /**
   * Наименование филиала
   * @type {string}
   * @memberof ParticipantRelationshipPartner
   */
  branchOfficeName?: string;
  /**
   *
   * @type {boolean}
   * @memberof ParticipantRelationshipPartner
   */
  canDelete?: boolean;
  /**
   * Код взаимоотношения
   * @type {string}
   * @memberof ParticipantRelationshipPartner
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationshipPartner
   */
  createdAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof ParticipantRelationshipPartner
   */
  isDefault?: boolean;
  /**
   *
   * @type {Participant}
   * @memberof ParticipantRelationshipPartner
   */
  participant?: Participant;
  /**
   *
   * @type {Participant}
   * @memberof ParticipantRelationshipPartner
   */
  partnerParticipant?: Participant;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationshipPartner
   */
  relationshipTypeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationshipPartner
   */
  relationshipTypeTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationshipPartner
   */
  updatedAt?: string;
}
/**
 *
 * @export
 * @interface ParticipantRelationshipPartnerSaveRequest
 */
export interface ParticipantRelationshipPartnerSaveRequest {
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationshipPartnerSaveRequest
   */
  branchOfficeCode: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationshipPartnerSaveRequest
   */
  customerCode?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ParticipantRelationshipPartnerSaveRequest
   */
  isDefault?: boolean;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationshipPartnerSaveRequest
   */
  participantCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationshipPartnerSaveRequest
   */
  partnerCustomerCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParticipantRelationshipPartnerSaveRequest
   */
  partnerParticipantCode?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof ParticipantRelationshipPartnerSaveRequest
   */
  relationshipTypeCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface ParticipantRelationshipPartnersResponse
 */
export interface ParticipantRelationshipPartnersResponse {
  /**
   *
   * @type {Array<ParticipantRelationshipPartner>}
   * @memberof ParticipantRelationshipPartnersResponse
   */
  partners?: Array<ParticipantRelationshipPartner>;
}
/**
 *
 * @export
 * @interface ParticipantRelationshipTypesResponse
 */
export interface ParticipantRelationshipTypesResponse {
  /**
   *
   * @type {Array<ParticipantRelationship>}
   * @memberof ParticipantRelationshipTypesResponse
   */
  relationshipTypes?: Array<ParticipantRelationship>;
}
/**
 *
 * @export
 * @interface ParticipantSource
 */
export interface ParticipantSource {
  /**
   *
   * @type {string}
   * @memberof ParticipantSource
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantSource
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantSource
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ParticipantSourcesResponse
 */
export interface ParticipantSourcesResponse {
  /**
   *
   * @type {Array<ParticipantSource>}
   * @memberof ParticipantSourcesResponse
   */
  sources?: Array<ParticipantSource>;
}
/**
 *
 * @export
 * @interface ParticipantTimePoint
 */
export interface ParticipantTimePoint {
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePoint
   */
  at?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePoint
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePoint
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePoint
   */
  contactCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePoint
   */
  createdAt?: string;
}
/**
 *
 * @export
 * @interface ParticipantTimePointsResponse
 */
export interface ParticipantTimePointsResponse {
  /**
   *
   * @type {boolean}
   * @memberof ParticipantTimePointsResponse
   */
  hasWriteAccess?: boolean;
  /**
   *
   * @type {Array<ParticipantTimePoint>}
   * @memberof ParticipantTimePointsResponse
   */
  timePoints?: Array<ParticipantTimePoint>;
}
/**
 *
 * @export
 * @interface ParticipantTimePointsSaveGetHistoryItem
 */
export interface ParticipantTimePointsSaveGetHistoryItem {
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveGetHistoryItem
   */
  at?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveGetHistoryItem
   */
  comment?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveGetHistoryItem
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveGetHistoryItem
   */
  initiatorEmployeeTitle?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveGetHistoryItem
   */
  timePointCode?: string;
}
/**
 *
 * @export
 * @interface ParticipantTimePointsSaveGetHistoryResponse
 */
export interface ParticipantTimePointsSaveGetHistoryResponse {
  /**
   *
   * @type {Array<ParticipantTimePointsSaveGetHistoryItem>}
   * @memberof ParticipantTimePointsSaveGetHistoryResponse
   */
  changes?: Array<ParticipantTimePointsSaveGetHistoryItem>;
}
/**
 *
 * @export
 * @interface ParticipantTimePointsSaveRequest
 */
export interface ParticipantTimePointsSaveRequest {
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveRequest
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveRequest
   */
  contactCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveRequest
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveRequest
   */
  participantCode?: string;
  /**
   *
   * @type {ParticipantTimePointsSaveUpdates}
   * @memberof ParticipantTimePointsSaveRequest
   */
  updates: ParticipantTimePointsSaveUpdates;
}
/**
 *
 * @export
 * @interface ParticipantTimePointsSaveUpdates
 */
export interface ParticipantTimePointsSaveUpdates {
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveUpdates
   */
  at?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParticipantTimePointsSaveUpdates
   */
  comment?: string | null;
}
/**
 *
 * @export
 * @interface PaymentBranchOffice
 */
export interface PaymentBranchOffice {
  /**
   * Код филиала
   * @type {string}
   * @memberof PaymentBranchOffice
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentBranchOffice
   */
  name?: string;
}
/**
 *
 * @export
 * @interface PaymentExpectation
 */
export interface PaymentExpectation {
  /**
   * Ожидание
   * @type {number}
   * @memberof PaymentExpectation
   */
  awaitingAmount?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentExpectation
   */
  billDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentExpectation
   */
  billMdmCode?: string;
  /**
   * Код филиала
   * @type {string}
   * @memberof PaymentExpectation
   */
  branchOfficeCode?: string;
  /**
   * Наименование филиала
   * @type {string}
   * @memberof PaymentExpectation
   */
  branchOfficeName?: string;
  /**
   * Код договора
   * @type {string}
   * @memberof PaymentExpectation
   */
  contractCode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentExpectation
   */
  customerCode?: string;
  /**
   * Количество дней до оплаты
   * @type {number}
   * @memberof PaymentExpectation
   */
  daysLeft?: number | null;
  /**
   * Просрочено дней
   * @type {number}
   * @memberof PaymentExpectation
   */
  daysOverdue?: number | null;
  /**
   * Стоимость
   * @type {number}
   * @memberof PaymentExpectation
   */
  loanAmount?: number;
  /**
   * Максимальная годовая процентная ставка по филиалу
   * @type {number}
   * @memberof PaymentExpectation
   */
  maxInterestRatePct?: number;
  /**
   * Просрочено
   * @type {number}
   * @memberof PaymentExpectation
   */
  overdueAmount?: number;
  /**
   * Оплачено
   * @type {number}
   * @memberof PaymentExpectation
   */
  paidAmount?: number;
  /**
   * Плановая дата
   * @type {string}
   * @memberof PaymentExpectation
   */
  plannedDate?: string | null;
  /**
   * Схема сделки - количество дней отсрочки
   * @type {number}
   * @memberof PaymentExpectation
   */
  postpayDays?: number;
  /**
   * Схема сделки - процент предоплаты
   * @type {number}
   * @memberof PaymentExpectation
   */
  prepayPct?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentExpectation
   */
  shipmentDocumentDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentExpectation
   */
  shipmentDocumentNumber?: string;
}
/**
 * Схема для настройки оплаты
 * @export
 * @interface PaymentSchema
 */
export interface PaymentSchema {
  /**
   *
   * @type {string}
   * @memberof PaymentSchema
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentSchema
   */
  name?: string;
  /**
   * Дней отсрочки
   * @type {number}
   * @memberof PaymentSchema
   */
  postpayDays?: number;
  /**
   * Проценд предоплаты
   * @type {number}
   * @memberof PaymentSchema
   */
  prepayPct?: number;
}
/**
 * Статус оплаты
 * @export
 * @interface PaymentState
 */
export interface PaymentState {
  /**
   *
   * @type {string}
   * @memberof PaymentState
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentState
   */
  title?: string;
}
/**
 *
 * @export
 * @interface PaymentStatesResponse
 */
export interface PaymentStatesResponse {
  /**
   *
   * @type {Array<PaymentState>}
   * @memberof PaymentStatesResponse
   */
  paymentStates?: Array<PaymentState>;
}
/**
 *
 * @export
 * @interface PaymentThreadRequest
 */
export interface PaymentThreadRequest {
  /**
   * Уникальный код оплаты
   * @type {string}
   * @memberof PaymentThreadRequest
   */
  code?: string;
  /**
   * Код документа оплаты
   * @type {string}
   * @memberof PaymentThreadRequest
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface PaymentThreadResponse
 */
export interface PaymentThreadResponse {
  /**
   *
   * @type {DocumentsThread}
   * @memberof PaymentThreadResponse
   */
  documentsThread?: DocumentsThread;
}
/**
 *
 * @export
 * @interface PaymentTransaction
 */
export interface PaymentTransaction {
  /**
   * Код филиала
   * @type {string}
   * @memberof PaymentTransaction
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  code?: string;
  /**
   *
   * @type {ClientContract}
   * @memberof PaymentTransaction
   */
  contract?: ClientContract;
  /**
   *
   * @type {Customer}
   * @memberof PaymentTransaction
   */
  customer?: Customer;
  /**
   *
   * @type {Array<PaymentTransactionDistributionShipment>}
   * @memberof PaymentTransaction
   */
  distributionShipments?: Array<PaymentTransactionDistributionShipment>;
  /**
   *
   * @type {Array<PaymentTransactionDistribution>}
   * @memberof PaymentTransaction
   */
  distributions?: Array<PaymentTransactionDistribution>;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  documentNumber?: string;
  /**
   *
   * @type {Customer}
   * @memberof PaymentTransaction
   */
  factorCustomer?: Customer;
  /**
   * Документ в статусе удален
   * @type {boolean}
   * @memberof PaymentTransaction
   */
  isDeleted?: boolean;
  /**
   * Сумма не разнесенных средств
   * @type {number}
   * @memberof PaymentTransaction
   */
  notDistributedSum?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  paymentDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  paymentDocNum?: string;
  /**
   * Сумма платежа
   * @type {number}
   * @memberof PaymentTransaction
   */
  paymentSum?: number;
}
/**
 *
 * @export
 * @interface PaymentTransactionDistribution
 */
export interface PaymentTransactionDistribution {
  /**
   *
   * @type {Bill}
   * @memberof PaymentTransactionDistribution
   */
  bill?: Bill;
  /**
   *
   * @type {Deal}
   * @memberof PaymentTransactionDistribution
   */
  deal?: Deal;
  /**
   *
   * @type {number}
   * @memberof PaymentTransactionDistribution
   */
  distributedSum?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentTransactionDistribution
   */
  position?: number;
}
/**
 *
 * @export
 * @interface PaymentTransactionDistributionShipment
 */
export interface PaymentTransactionDistributionShipment {
  /**
   *
   * @type {number}
   * @memberof PaymentTransactionDistributionShipment
   */
  distributedSum?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionDistributionShipment
   */
  distributionType?: PaymentTransactionDistributionShipmentDistributionTypeEnum;
  /**
   *
   * @type {SaleShipment}
   * @memberof PaymentTransactionDistributionShipment
   */
  shipment?: SaleShipment;
}

export const PaymentTransactionDistributionShipmentDistributionTypeEnum = {
  PrepayPart: 'shipment_prepay_part',
  PostpayPart: 'shipment_postpay_part',
} as const;

export type PaymentTransactionDistributionShipmentDistributionTypeEnum =
  (typeof PaymentTransactionDistributionShipmentDistributionTypeEnum)[keyof typeof PaymentTransactionDistributionShipmentDistributionTypeEnum];

/**
 *
 * @export
 * @interface PaymentTransactionsRequest
 */
export interface PaymentTransactionsRequest {
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  billCode?: string;
  /**
   * Коды филиалов
   * @type {Array<string>}
   * @memberof PaymentTransactionsRequest
   */
  branchOfficeCodes?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof PaymentTransactionsRequest
   */
  count?: number | null;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  dateFrom?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  dateTimeFrom?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  dateTimeTo?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  dateTo?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  dealCode?: string;
  /**
   * Коды отделов
   * @type {Array<string>}
   * @memberof PaymentTransactionsRequest
   */
  departmentCodes?: Array<string>;
  /**
   * Период привязки сотрудника к отделу
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  departmentPeriod?: string | null;
  /**
   * Коды дивизионов
   * @type {Array<string>}
   * @memberof PaymentTransactionsRequest
   */
  divisionCodes?: Array<string>;
  /**
   * Коды сотрудников
   * @type {Array<string>}
   * @memberof PaymentTransactionsRequest
   */
  employeeCodes?: Array<string>;
  /**
   * Период привязки клиента к сотруднику
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  employeePeriod?: string | null;
  /**
   * Код сотрудника или группы
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  employeeSet?: string | null;
  /**
   * Фильтр по наличию не разнесенных средств
   * @type {boolean}
   * @memberof PaymentTransactionsRequest
   */
  isNotDistributedSum?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof PaymentTransactionsRequest
   */
  page?: number | null;
  /**
   *
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  participantCode?: string;
  /**
   * filter for payments
   * @type {string}
   * @memberof PaymentTransactionsRequest
   */
  query?: string | null;
}
/**
 *
 * @export
 * @interface PaymentTransactionsResponse
 */
export interface PaymentTransactionsResponse {
  /**
   * Оплаты
   * @type {Array<PaymentTransaction>}
   * @memberof PaymentTransactionsResponse
   */
  transactions?: Array<PaymentTransaction>;
  /**
   *
   * @type {Array<PaymentBranchOffice>}
   * @memberof PaymentTransactionsResponse
   */
  transactionsBranchOffices?: Array<PaymentBranchOffice>;
  /**
   * Общая сумма, руб.
   * @type {number}
   * @memberof PaymentTransactionsResponse
   */
  transactionsTotalCost?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentTransactionsResponse
   */
  transactionsTotalCount?: number;
}
/**
 * Тип оплаты для сделки
 * @export
 * @interface PaymentType
 */
export interface PaymentType {
  /**
   *
   * @type {string}
   * @memberof PaymentType
   */
  code?: string;
  /**
   * Стоимость (только если в запросе передана сделка и заданы параметры)
   * @type {number}
   * @memberof PaymentType
   */
  price?: number | null;
  /**
   *
   * @type {string}
   * @memberof PaymentType
   */
  title?: string;
}
/**
 *
 * @export
 * @interface PaymentTypesResponse
 */
export interface PaymentTypesResponse {
  /**
   * Доступные схемы оплаты
   * @type {Array<PaymentSchema>}
   * @memberof PaymentTypesResponse
   */
  paymentSchemas?: Array<PaymentSchema>;
  /**
   * Типы оплаты
   * @type {Array<PaymentType>}
   * @memberof PaymentTypesResponse
   */
  paymentTypes?: Array<PaymentType>;
}
/**
 *
 * @export
 * @interface PdfPosition
 */
export interface PdfPosition {
  /**
   *
   * @type {string}
   * @memberof PdfPosition
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof PdfPosition
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PdfPosition
   */
  number?: number;
  /**
   * Базовая МРЦ
   * @type {number}
   * @memberof PdfPosition
   */
  quantity?: number;
  /**
   * Базовая МРЦ
   * @type {number}
   * @memberof PdfPosition
   */
  totalCost?: number;
  /**
   * Базовая МРЦ
   * @type {number}
   * @memberof PdfPosition
   */
  totalCostNoVat?: number;
  /**
   *
   * @type {string}
   * @memberof PdfPosition
   */
  unit?: string;
  /**
   * Базовая МРЦ
   * @type {number}
   * @memberof PdfPosition
   */
  unitCost?: number;
  /**
   * Базовая МРЦ
   * @type {number}
   * @memberof PdfPosition
   */
  unitCostNoVat?: number;
  /**
   * Базовая МРЦ
   * @type {number}
   * @memberof PdfPosition
   */
  vatAmount?: number;
  /**
   * Базовая МРЦ
   * @type {number}
   * @memberof PdfPosition
   */
  vatRate?: number;
  /**
   *
   * @type {string}
   * @memberof PdfPosition
   */
  warehouse?: string;
}
/**
 *
 * @export
 * @interface PdfText
 */
export interface PdfText {
  /**
   * Код текста
   * @type {string}
   * @memberof PdfText
   */
  code?: string;
  /**
   * Текст
   * @type {string}
   * @memberof PdfText
   */
  text?: string;
}
/**
 * График
 * @export
 * @interface Plot
 */
export interface Plot {
  /**
   * Данные для построения
   * @type {Array<PlotSeries>}
   * @memberof Plot
   */
  series?: Array<PlotSeries>;
  /**
   *
   * @type {string}
   * @memberof Plot
   */
  title?: string;
  /**
   *
   * @type {PlotAxis}
   * @memberof Plot
   */
  xaxis?: PlotAxis;
  /**
   *
   * @type {PlotAxis}
   * @memberof Plot
   */
  yaxis?: PlotAxis;
}
/**
 * Ось или шкала графика
 * @export
 * @interface PlotAxis
 */
export interface PlotAxis {
  /**
   * Максимальное значение, которое должно отображаться на шкале
   * @type {number}
   * @memberof PlotAxis
   */
  maxTicks?: number | null;
  /**
   * Минимальное значение, которое должно отображаться на шкале
   * @type {number}
   * @memberof PlotAxis
   */
  minTicks?: number | null;
  /**
   *
   * @type {string}
   * @memberof PlotAxis
   */
  title?: string;
}
/**
 * Разница между последним и первым значениями
 * @export
 * @interface PlotChangeInValue
 */
export interface PlotChangeInValue {
  /**
   *
   * @type {string}
   * @memberof PlotChangeInValue
   */
  unit?: string;
  /**
   *
   * @type {number}
   * @memberof PlotChangeInValue
   */
  value?: number | null;
}
/**
 * Одна линия на графике
 * @export
 * @interface PlotSeries
 */
export interface PlotSeries {
  /**
   *
   * @type {PlotChangeInValue}
   * @memberof PlotSeries
   */
  changeInValue?: PlotChangeInValue;
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof PlotSeries
   */
  data?: Array<Array<number>>;
  /**
   *
   * @type {string}
   * @memberof PlotSeries
   */
  label?: string;
}
/**
 * Товар из каталога
 * @export
 * @interface Product
 */
export interface Product {
  /**
   * Базовая минимальная розничная цена
   * @type {number}
   * @memberof Product
   */
  baseMinRetailUnitCost?: number | null;
  /**
   *
   * @type {ProductBaseSize}
   * @memberof Product
   */
  baseSize?: ProductBaseSize;
  /**
   * Код базовой единицы
   * @type {string}
   * @memberof Product
   */
  baseUnitCode?: string;
  /**
   * Короткое имя базовой единицы
   * @type {string}
   * @memberof Product
   */
  baseUnitShort?: string;
  /**
   * Код сортаментной группы
   * @type {string}
   * @memberof Product
   */
  budgetGroupCode?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  budgetGroupName?: string;
  /**
   * Краткое название сортаментной группы
   * @type {string}
   * @memberof Product
   */
  budgetGroupShort?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  categoryCode?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  categoryName?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  code?: string;
  /**
   * Остатки на складах филиалов компании
   * @type {Array<ProductItemBranchOfficeStock>}
   * @memberof Product
   */
  companyStocks?: Array<ProductItemBranchOfficeStock>;
  /**
   * Средневзвешенная цена партий ОХ
   * @type {number}
   * @memberof Product
   */
  custodyAvgUnitCost?: number;
  /**
   * Остатки по партиям ОХ, т
   * @type {number}
   * @memberof Product
   */
  custodyTotalWeight?: number;
  /**
   * Информация об ОХ на складах
   * @type {Array<ProductCustodyWarehouse>}
   * @memberof Product
   */
  custodyWarehouses?: Array<ProductCustodyWarehouse>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  deletedAt?: string | null;
  /**
   * Количество распределенного товара
   * @type {number}
   * @memberof Product
   */
  distributedQuantity?: number;
  /**
   * Прогноз выполнения плана по группе сегментации на конец месяца, на момент расчета КТЗ
   * @type {number}
   * @memberof Product
   */
  forecastCompletionWtPct?: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  globalMarketCost?: number | null;
  /**
   *
   * @type {Array<string>}
   * @memberof Product
   */
  groups?: Array<string>;
  /**
   * ОХ, Есть пачки на ответственном хранении
   * @type {boolean}
   * @memberof Product
   */
  hasCustody?: boolean;
  /**
   * НК, Есть пачки с некондицией
   * @type {boolean}
   * @memberof Product
   */
  hasIlliquid?: boolean;
  /**
   * Есть резервы товаров входящих в номенклатуру
   * @type {boolean}
   * @memberof Product
   */
  hasNomenclatureReserves?: boolean;
  /**
   * Есть резервы товара
   * @type {boolean}
   * @memberof Product
   */
  hasReserves?: boolean;
  /**
   * СЗ, Есть пачки замозакупа
   * @type {boolean}
   * @memberof Product
   */
  hasSelfPurchase?: boolean;
  /**
   * НЛ, Есть пачки с неликвидом и скидками
   * @type {boolean}
   * @memberof Product
   */
  hasTodhDiscount?: boolean;
  /**
   * Есть пачки с неликвидом
   * @type {boolean}
   * @memberof Product
   */
  hasTodhExcess?: boolean;
  /**
   * Во фризах
   * @type {number}
   * @memberof Product
   */
  inFreeze?: number;
  /**
   * В жёстком резерве
   * @type {number}
   * @memberof Product
   */
  inReserve?: number;
  /**
   * В мягком резерве
   * @type {number}
   * @memberof Product
   */
  inSoftReserve?: number;
  /**
   * Остатков на складе
   * @type {number}
   * @memberof Product
   */
  inStock?: number;
  /**
   * Свободный остаток
   * @type {number}
   * @memberof Product
   */
  inStockForSale?: number;
  /**
   * Количество товара в пути
   * @type {number}
   * @memberof Product
   */
  inTransitQuantity?: number;
  /**
   * У товара есть самая новая партия в номенклатуре
   * @type {boolean}
   * @memberof Product
   */
  isNewestInNomenclature?: boolean;
  /**
   * У товара есть самая старая партия в номенклатуре
   * @type {boolean}
   * @memberof Product
   */
  isOldestInNomenclature?: boolean;
  /**
   * KVI МДМ Кей
   * @type {string}
   * @memberof Product
   */
  kviMdmKey?: string;
  /**
   * KVI название
   * @type {string}
   * @memberof Product
   */
  kviName?: string;
  /**
   * Код производителя
   * @type {string}
   * @memberof Product
   */
  manufacturerCode?: string;
  /**
   * Максимальная наценка, %
   * @type {number}
   * @memberof Product
   */
  manufacturerMarkupLimitFactor?: number;
  /**
   * Максимальная цена по производителю и бюджетной группе
   * @type {number}
   * @memberof Product
   */
  manufacturerMarkupLimitUnitCost?: number;
  /**
   * Назваине производителя
   * @type {string}
   * @memberof Product
   */
  manufacturerTitle?: string;
  /**
   * MDMKey характеристики
   * @type {string}
   * @memberof Product
   */
  mdmKey?: string;
  /**
   * Минимальная розничная цена с учетом группы сегментации
   * @type {number}
   * @memberof Product
   */
  minRetailUnitCost?: number | null;
  /**
   * Кратность. Значение 0 следует игнорировать
   * @type {number}
   * @memberof Product
   */
  multiplicity?: number;
  /**
   * Код единицы измерения для кратности (штука, погонный метр, квадратный метр)
   * @type {string}
   * @memberof Product
   */
  multiplicityUnitCode?: string;
  /**
   * Цена за единицу для которой указана кратность (шт, метры и пр.)
   * @type {number}
   * @memberof Product
   */
  multiplicityUnitCost?: number;
  /**
   * Единица измерения для кратности (штука, погонный метр, квадратный метр)
   * @type {string}
   * @memberof Product
   */
  multiplicityUnitShort?: string;
  /**
   * Единица измерения кратности для отображения (т/шт, т/пог.м, т/кв.м)
   * @type {string}
   * @memberof Product
   */
  multiplicityUnitTitle?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  nomenclatureCode?: string;
  /**
   * Остатки номенклатуры по филиалам компании
   * @type {Array<ProductItemBranchOfficeStock>}
   * @memberof Product
   */
  nomenclatureCompanyStocks?: Array<ProductItemBranchOfficeStock>;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  nomenclatureDistributedQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  nomenclatureInReserve?: number;
  /**
   * Общий остаток по номенклатуре на складе
   * @type {number}
   * @memberof Product
   */
  nomenclatureInStock?: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  nomenclatureInStockForSale?: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  nomenclatureInTransitQuantity?: number;
  /**
   * MDMkey номенклатуры
   * @type {string}
   * @memberof Product
   */
  nomenclatureMdmKey?: string;
  /**
   * Название номенклатуры
   * @type {string}
   * @memberof Product
   */
  nomenclatureName?: string;
  /**
   * Резервы товаров входящих в номенклатуру (deprecated - use CatalogProductReservesV2)
   * @type {Array<NomenclatureReservesProduct>}
   * @memberof Product
   */
  nomenclatureReserves?: Array<NomenclatureReservesProduct>;
  /**
   * Остатки на складах
   * @type {Array<ProductItemStock>}
   * @memberof Product
   */
  nomenclatureStocks?: Array<ProductItemStock>;
  /**
   * Код продажной единицы измерения по умолчанию для филиала
   * @type {string}
   * @memberof Product
   */
  officeDefaultSaleUnitCode?: string;
  /**
   * Плановая себестоимость (средневзвешенная)
   * @type {number}
   * @memberof Product
   */
  planPriceUnitCost?: number;
  /**
   * Дата формирования цены
   * @type {string}
   * @memberof Product
   */
  priceDate?: string;
  /**
   * Код группы с одинаковым набором цен.
   * @type {string}
   * @memberof Product
   */
  priceGroup?: string;
  /**
   * Цены по шкалам наценок
   * @type {Array<ProductItemPrice>}
   * @memberof Product
   */
  prices?: Array<ProductItemPrice>;
  /**
   *
   * @type {Array<ProductItemProperty>}
   * @memberof Product
   */
  properties?: Array<ProductItemProperty>;
  /**
   * Средневзвешенная цена закупки
   * @type {number}
   * @memberof Product
   */
  purchaseUnitCost?: number;
  /**
   * Известные резервы товаров (deprecated - use CatalogProductReservesV2)
   * @type {Array<ProductItemReserve>}
   * @memberof Product
   */
  reserves?: Array<ProductItemReserve>;
  /**
   * Код продажной единицы измерения
   * @type {string}
   * @memberof Product
   */
  saleUnitCode?: string;
  /**
   * Продажная единица измерения
   * @type {string}
   * @memberof Product
   */
  saleUnitShort?: string;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  saleUnitWeight?: number;
  /**
   * Товары в пути, на момент расчета КТЗ
   * @type {number}
   * @memberof Product
   */
  scInTransitQuantity?: number;
  /**
   * Сегмент товара
   * @type {string}
   * @memberof Product
   */
  segmentCode?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  segmentTitle?: string;
  /**
   * МДККей группы сегментации
   * @type {string}
   * @memberof Product
   */
  segmentationGroupMdmKey?: string;
  /**
   * Наценка филиала или директорская наценка
   * @type {number}
   * @memberof Product
   */
  segmentationGroupMrcMarkupAmount?: number;
  /**
   * Название группы сегментации
   * @type {string}
   * @memberof Product
   */
  segmentationGroupTitle?: string;
  /**
   * Коэффициент товарного запаса
   * @type {number}
   * @memberof Product
   */
  stockCoefficient?: number;
  /**
   * Остатки на складах
   * @type {Array<ProductItemStock>}
   * @memberof Product
   */
  stocks?: Array<ProductItemStock>;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  titleSite?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  tnvedCode?: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  tnvedNameFull?: string;
  /**
   * Цена при максимальном объеме закупки (от 20 тонн)
   * @type {number}
   * @memberof Product
   */
  topCostValue?: number;
  /**
   * Тип товара
   * @type {string}
   * @memberof Product
   */
  typeCode?: string;
  /**
   *
   * @type {Array<ProductUnit>}
   * @memberof Product
   */
  units?: Array<ProductUnit>;
  /**
   * Ссылка на товар
   * @type {string}
   * @memberof Product
   */
  url?: string;
}
/**
 * Базовые размеры
 * @export
 * @interface ProductBaseSize
 */
export interface ProductBaseSize {
  /**
   *
   * @type {string}
   * @memberof ProductBaseSize
   */
  groupCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductBaseSize
   */
  groupName?: string;
  /**
   * Код значения
   * @type {string}
   * @memberof ProductBaseSize
   */
  valueCode?: string;
  /**
   * Отображаемое значение
   * @type {string}
   * @memberof ProductBaseSize
   */
  valueText?: string;
}
/**
 * ОХ на складе
 * @export
 * @interface ProductCustodyWarehouse
 */
export interface ProductCustodyWarehouse {
  /**
   * Остаток на момент расчета
   * @type {number}
   * @memberof ProductCustodyWarehouse
   */
  inStock?: number;
  /**
   * Средневзвешенная цена остатков
   * @type {number}
   * @memberof ProductCustodyWarehouse
   */
  unitCost?: number;
  /**
   * Код склада
   * @type {string}
   * @memberof ProductCustodyWarehouse
   */
  warehouseCode?: string;
  /**
   * Склад является группой (все склады)
   * @type {boolean}
   * @memberof ProductCustodyWarehouse
   */
  warehouseIsGroup?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductCustodyWarehouse
   */
  warehouseName?: string;
}
/**
 *
 * @export
 * @interface ProductExtension
 */
export interface ProductExtension {
  /**
   *
   * @type {string}
   * @memberof ProductExtension
   */
  code?: string;
  /**
   * Есть пачки на ответственном хранении
   * @type {boolean}
   * @memberof ProductExtension
   */
  hasCustodyBatches?: boolean;
  /**
   * Есть пачки с некондицией
   * @type {boolean}
   * @memberof ProductExtension
   */
  hasIlliquidBatches?: boolean;
  /**
   * Есть пачки замозакупа
   * @type {boolean}
   * @memberof ProductExtension
   */
  hasSelfPurchase?: boolean;
  /**
   * Есть пачки с неликвидом
   * @type {boolean}
   * @memberof ProductExtension
   */
  hasTodhBatches?: boolean;
  /**
   * Есть пачки с неликвидом и скидками
   * @type {boolean}
   * @memberof ProductExtension
   */
  hasTodhDiscounts?: boolean;
}
/**
 * Товар для быстрого поиска
 * @export
 * @interface ProductForFastSearch
 */
export interface ProductForFastSearch {
  /**
   *
   * @type {string}
   * @memberof ProductForFastSearch
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof ProductForFastSearch
   */
  custodyStockQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ProductForFastSearch
   */
  distributedQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof ProductForFastSearch
   */
  inConsignment?: number;
  /**
   *
   * @type {number}
   * @memberof ProductForFastSearch
   */
  inReserve?: number;
  /**
   *
   * @type {number}
   * @memberof ProductForFastSearch
   */
  inSoftReserve?: number;
  /**
   * Количество товара на складе
   * @type {number}
   * @memberof ProductForFastSearch
   */
  inStock?: number;
  /**
   *
   * @type {number}
   * @memberof ProductForFastSearch
   */
  inStockForSale?: number;
  /**
   *
   * @type {number}
   * @memberof ProductForFastSearch
   */
  inTransitQuantity?: number;
  /**
   * MDMKey характеристики
   * @type {string}
   * @memberof ProductForFastSearch
   */
  mdmKey?: string;
  /**
   *
   * @type {string}
   * @memberof ProductForFastSearch
   */
  title?: string;
  /**
   * ЕИ товара для добавления в заявку
   * @type {string}
   * @memberof ProductForFastSearch
   */
  unitCode?: string;
  /**
   * Цена товара для добавления в заявку
   * @type {object}
   * @memberof ProductForFastSearch
   */
  unitPrice?: object;
}
/**
 *
 * @export
 * @interface ProductForReport
 */
export interface ProductForReport {
  /**
   *
   * @type {Array<BatchForReport>}
   * @memberof ProductForReport
   */
  batches?: Array<BatchForReport>;
  /**
   *
   * @type {string}
   * @memberof ProductForReport
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof ProductForReport
   */
  distributedQuantity?: number;
  /**
   * МРЦ с полномочиями директора дивизиона
   * @type {number}
   * @memberof ProductForReport
   */
  divisionChiefUnitCost?: number;
  /**
   *
   * @type {boolean}
   * @memberof ProductForReport
   */
  hasCustody?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductForReport
   */
  hasIlliquid?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductForReport
   */
  hasSelfPurchase?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductForReport
   */
  hasTodhDiscount?: boolean;
  /**
   * В ожидании резерва
   * @type {number}
   * @memberof ProductForReport
   */
  inAwaitingReserve?: number;
  /**
   * В накладных
   * @type {number}
   * @memberof ProductForReport
   */
  inConsignment?: number;
  /**
   * Во фризах
   * @type {number}
   * @memberof ProductForReport
   */
  inFreeze?: number;
  /**
   * В жёстком резерве
   * @type {number}
   * @memberof ProductForReport
   */
  inReserve?: number;
  /**
   * В мягком резерве
   * @type {number}
   * @memberof ProductForReport
   */
  inSoftReserve?: number;
  /**
   * Остаток, включая резервы
   * @type {number}
   * @memberof ProductForReport
   */
  inStock?: number;
  /**
   * Остаток, доступный для продажи
   * @type {number}
   * @memberof ProductForReport
   */
  inStockForSale?: number;
  /**
   *
   * @type {number}
   * @memberof ProductForReport
   */
  inTransitQuantity?: number;
  /**
   *
   * @type {boolean}
   * @memberof ProductForReport
   */
  isNew?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProductForReport
   */
  isOld?: boolean;
  /**
   * Дней в резерве ДО
   * @type {number}
   * @memberof ProductForReport
   */
  maxReserveDays?: number;
  /**
   *
   * @type {string}
   * @memberof ProductForReport
   */
  mdmKey?: string;
  /**
   * Дней в резерве ОТ
   * @type {number}
   * @memberof ProductForReport
   */
  minReserveDays?: number;
  /**
   * Минимальная розничная цена с учетом группы сегментации
   * @type {number}
   * @memberof ProductForReport
   */
  minRetailUnitCost?: number;
  /**
   * Самая новая дата поставки партии товара-альтернативы
   * @type {string}
   * @memberof ProductForReport
   */
  newReceiptDate?: string;
  /**
   * Срок самой новой партии
   * @type {number}
   * @memberof ProductForReport
   */
  newShelfLife?: number | null;
  /**
   * Дельта между МРЦ с полномочиями филиала и средневзвешенной цены закупки
   * @type {number}
   * @memberof ProductForReport
   */
  officeChiefPurchaseDeltaUnitCost?: number;
  /**
   * МРЦ с полномочиями директора филиала
   * @type {number}
   * @memberof ProductForReport
   */
  officeChiefUnitCost?: number;
  /**
   * Самая старая партия поставки партии товара-альтернативы
   * @type {string}
   * @memberof ProductForReport
   */
  oldReceiptDate?: string;
  /**
   * Срок самой старой партии
   * @type {number}
   * @memberof ProductForReport
   */
  oldShelfLife?: number | null;
  /**
   * Цена будущей закупки
   * @type {number}
   * @memberof ProductForReport
   */
  priceMotivation?: number;
  /**
   * Средневзвешенная цена закупки по партиям
   * @type {number}
   * @memberof ProductForReport
   */
  purchasePrice?: number;
  /**
   * Сумма зарезервированного в МРЦ
   * @type {number}
   * @memberof ProductForReport
   */
  reservedMrcTotalCost?: number;
  /**
   * Сумма зарезервированного в ценах закупки
   * @type {number}
   * @memberof ProductForReport
   */
  reservedPurchaseTotalCost?: number;
  /**
   * МРЦ с полномочиями руководителя отдела продаж
   * @type {number}
   * @memberof ProductForReport
   */
  sellerChiefUnitCost?: number;
  /**
   * МРЦ с полномочиями менеджера по продажам
   * @type {number}
   * @memberof ProductForReport
   */
  sellerUnitCost?: number;
  /**
   *
   * @type {string}
   * @memberof ProductForReport
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ProductForReport
   */
  unitCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductForReport
   */
  unitShortName?: string;
  /**
   *
   * @type {Array<ProductUnit>}
   * @memberof ProductForReport
   */
  units?: Array<ProductUnit>;
  /**
   *
   * @type {string}
   * @memberof ProductForReport
   */
  warehouseCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductForReport
   */
  warehouseName?: string;
}
/**
 * Изображение товара
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
  /**
   * Тип или номер изображения
   * @type {string}
   * @memberof ProductImage
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof ProductImage
   */
  url?: string;
}
/**
 * Наценки на свойства товаров по KVI
 * @export
 * @interface ProductItemBranchOfficeItemMrcKviProperty
 */
export interface ProductItemBranchOfficeItemMrcKviProperty {
  /**
   * Название KVI
   * @type {string}
   * @memberof ProductItemBranchOfficeItemMrcKviProperty
   */
  kviName?: string;
  /**
   * Значение наценки
   * @type {number}
   * @memberof ProductItemBranchOfficeItemMrcKviProperty
   */
  markupAmount?: number;
  /**
   * Процент наценки
   * @type {number}
   * @memberof ProductItemBranchOfficeItemMrcKviProperty
   */
  markupFactor?: number;
  /**
   * Название свойства
   * @type {string}
   * @memberof ProductItemBranchOfficeItemMrcKviProperty
   */
  propertyName?: string;
  /**
   * Значение свойства
   * @type {string}
   * @memberof ProductItemBranchOfficeItemMrcKviProperty
   */
  propertyValue?: string;
}
/**
 * Остаток на складах филиала
 * @export
 * @interface ProductItemBranchOfficeStock
 */
export interface ProductItemBranchOfficeStock {
  /**
   * Код филиала
   * @type {string}
   * @memberof ProductItemBranchOfficeStock
   */
  branchOfficeCode?: string;
  /**
   * Наименование филиала
   * @type {string}
   * @memberof ProductItemBranchOfficeStock
   */
  branchOfficeName?: string;
  /**
   * Распределенные товары
   * @type {number}
   * @memberof ProductItemBranchOfficeStock
   */
  distributedQuantity?: number;
  /**
   * В накладных
   * @type {number}
   * @memberof ProductItemBranchOfficeStock
   */
  inConsignment?: number;
  /**
   * В резерве
   * @type {number}
   * @memberof ProductItemBranchOfficeStock
   */
  inReserve?: number;
  /**
   * В мягком резерве
   * @type {number}
   * @memberof ProductItemBranchOfficeStock
   */
  inSoftReserve?: number;
  /**
   * Всего на складе (включая резерв)
   * @type {number}
   * @memberof ProductItemBranchOfficeStock
   */
  inStock?: number;
  /**
   * Свободный остаток
   * @type {number}
   * @memberof ProductItemBranchOfficeStock
   */
  inStockForSale?: number;
  /**
   * Товары в пути
   * @type {number}
   * @memberof ProductItemBranchOfficeStock
   */
  inTransitQuantity?: number;
  /**
   * Код единицы измерения остатка
   * @type {string}
   * @memberof ProductItemBranchOfficeStock
   */
  unit?: string;
  /**
   * Единица измерения остатка
   * @type {string}
   * @memberof ProductItemBranchOfficeStock
   */
  unitShortName?: string;
  /**
   * Остатки на складах
   * @type {Array<ProductItemStock>}
   * @memberof ProductItemBranchOfficeStock
   */
  warehousesStocks?: Array<ProductItemStock>;
}
/**
 * Пачка из партии на складе
 * @export
 * @interface ProductItemPack
 */
export interface ProductItemPack {
  /**
   * Код-идентификатор партии. В перспективе может отличаться от batchNumber.
   * @type {string}
   * @memberof ProductItemPack
   */
  batchCode?: string;
  /**
   * Не продавать партию
   * @type {boolean}
   * @memberof ProductItemPack
   */
  batchDoNotSell?: boolean;
  /**
   * Номер партии
   * @type {string}
   * @memberof ProductItemPack
   */
  batchNumber?: string;
  /**
   * Полное количество в партии
   * @type {number}
   * @memberof ProductItemPack
   */
  batchQuantity?: number;
  /**
   * Самозакуп
   * @type {boolean}
   * @memberof ProductItemPack
   */
  batchSelfPurchase?: boolean;
  /**
   * Полный вес партии, т
   * @type {number}
   * @memberof ProductItemPack
   */
  batchWeight?: number;
  /**
   * Номер упаковки
   * @type {string}
   * @memberof ProductItemPack
   */
  boxingNumber?: string;
  /**
   * Кассета - место хранения на складе
   * @type {string}
   * @memberof ProductItemPack
   */
  cassette?: string;
  /**
   * Название файла сертификата
   * @type {string}
   * @memberof ProductItemPack
   */
  certificateName?: string;
  /**
   * ВесПачкиПоСертификату
   * @type {number}
   * @memberof ProductItemPack
   */
  certificatePackWeight?: number;
  /**
   * Финансовые затраты
   * @type {number}
   * @memberof ProductItemPack
   */
  financialUnitCost?: number;
  /**
   * Код внешнего дефекта
   * @type {string}
   * @memberof ProductItemPack
   */
  illiquidDefectCode?: string;
  /**
   * Название вида дефектов
   * @type {string}
   * @memberof ProductItemPack
   */
  illiquidDefectTitle?: string;
  /**
   * Размер скидки для ржавых и дефектных партий
   * @type {number}
   * @memberof ProductItemPack
   */
  illiquidDiscountPct?: number;
  /**
   * Код степени ржавчины
   * @type {string}
   * @memberof ProductItemPack
   */
  illiquidRustCode?: string;
  /**
   * Название степени ржавчины
   * @type {string}
   * @memberof ProductItemPack
   */
  illiquidRustTitle?: string;
  /**
   * Остаток на складе без учета резервов
   * @type {number}
   * @memberof ProductItemPack
   */
  inStock?: number;
  /**
   * Единицы измерения остатка пачки
   * @type {string}
   * @memberof ProductItemPack
   */
  inStockUnitCode?: string;
  /**
   * Партия на ответственном хранении
   * @type {boolean}
   * @memberof ProductItemPack
   */
  isSafeCustody?: boolean;
  /**
   * Логистические затраты склада
   * @type {number}
   * @memberof ProductItemPack
   */
  lwhUnitCost?: number;
  /**
   * Номер пачки
   * @type {string}
   * @memberof ProductItemPack
   */
  number?: string;
  /**
   * Номер в сертификате
   * @type {string}
   * @memberof ProductItemPack
   */
  numberInCertificate?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemPack
   */
  parameter?: string;
  /**
   * Цена закупки
   * @type {number}
   * @memberof ProductItemPack
   */
  purchaseUnitCost?: number;
  /**
   * Количество в пачке
   * @type {number}
   * @memberof ProductItemPack
   */
  quantity?: number;
  /**
   * ЕИ количества в пачке
   * @type {string}
   * @memberof ProductItemPack
   */
  quantityUnitCode?: string;
  /**
   * ЕИ количества в пачке
   * @type {string}
   * @memberof ProductItemPack
   */
  quantityUnitShort?: string;
  /**
   * Дата поступления партии на склад
   * @type {string}
   * @memberof ProductItemPack
   */
  receiptDate?: string;
  /**
   * Дней хранения
   * @type {number}
   * @memberof ProductItemPack
   */
  todhDays?: number;
  /**
   * Скидка по неликвиду обнулена скидкой по некондиции
   * @type {boolean}
   * @memberof ProductItemPack
   */
  todhDiscountIlliquidBlocked?: boolean;
  /**
   * Размер скидки по неликвиду
   * @type {number}
   * @memberof ProductItemPack
   */
  todhDiscountPct?: number;
  /**
   * Обозначение времени хранения (Т30, Т60, Т120...)
   * @type {string}
   * @memberof ProductItemPack
   */
  todhText?: string;
  /**
   * ТЗР
   * @type {number}
   * @memberof ProductItemPack
   */
  tpcUnitCost?: number;
  /**
   * Код единицы измерения партии
   * @type {string}
   * @memberof ProductItemPack
   */
  unitCode?: string;
  /**
   * Единица измерения
   * @type {string}
   * @memberof ProductItemPack
   */
  unitShortName?: string;
  /**
   * Номер вагона
   * @type {string}
   * @memberof ProductItemPack
   */
  wagonNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemPack
   */
  warehouseCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemPack
   */
  warehouseName?: string;
  /**
   * Вес пачки
   * @type {number}
   * @memberof ProductItemPack
   */
  weight?: number;
}
/**
 * Цена для диапазаона объема
 * @export
 * @interface ProductItemPrice
 */
export interface ProductItemPrice {
  /**
   * Цена для открытого прайс-листа
   * @type {boolean}
   * @memberof ProductItemPrice
   */
  isOPL?: boolean | null;
  /**
   * Цена за тонну
   * @type {number}
   * @memberof ProductItemPrice
   */
  priceBu?: number;
  /**
   * Цена за штуку
   * @type {number}
   * @memberof ProductItemPrice
   */
  priceSu?: number;
  /**
   *
   * @type {string}
   * @memberof ProductItemPrice
   */
  rangeCode?: string;
  /**
   * Максимальное количество за тонну
   * @type {number}
   * @memberof ProductItemPrice
   */
  rangeMaxCount?: number;
  /**
   * Минимальное количество за тонну
   * @type {number}
   * @memberof ProductItemPrice
   */
  rangeMinCount?: number;
  /**
   *
   * @type {string}
   * @memberof ProductItemPrice
   */
  rangeTitle?: string;
  /**
   * Цена за базовую единицу (тонну) без НДС
   * @type {number}
   * @memberof ProductItemPrice
   */
  wovBuCost?: number;
  /**
   * Цена за продажную единицу (штуку, метр и т.п.) без НДС
   * @type {number}
   * @memberof ProductItemPrice
   */
  wovSuCost?: number;
}
/**
 * Конкретное значение свойства у товара
 * @export
 * @interface ProductItemProperty
 */
export interface ProductItemProperty {
  /**
   *
   * @type {string}
   * @memberof ProductItemProperty
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemProperty
   */
  title?: string;
  /**
   * Тип свойства (string, int, float, bool, color)
   * @type {string}
   * @memberof ProductItemProperty
   */
  typeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemProperty
   */
  valueCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemProperty
   */
  valueValue?: string;
}
/**
 * Резерв товара на складе
 * @export
 * @interface ProductItemReserve
 */
export interface ProductItemReserve {
  /**
   * Авто-резерв включен (только для мягких резервов)
   * @type {boolean}
   * @memberof ProductItemReserve
   */
  autoReserveEnabled?: boolean;
  /**
   * Уникальный код счета
   * @type {string}
   * @memberof ProductItemReserve
   */
  billCode?: string;
  /**
   * Время создания счета
   * @type {string}
   * @memberof ProductItemReserve
   */
  billDocumentDate?: string;
  /**
   * Номер документа счета
   * @type {string}
   * @memberof ProductItemReserve
   */
  billDocumentNumber?: string;
  /**
   * У счета есть подчиненный документ. Подчиненный документ - оплата по счету,
   * @type {boolean}
   * @memberof ProductItemReserve
   */
  billHasSubordinateDoc?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductItemReserve
   */
  billPaymentStateCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemReserve
   */
  billPaymentStateTitle?: string;
  /**
   * Код статуса счета
   * @type {string}
   * @memberof ProductItemReserve
   */
  billStateCode?: string;
  /**
   * Статус счета
   * @type {string}
   * @memberof ProductItemReserve
   */
  billStateTitle?: string;
  /**
   * Код резерва
   * @type {string}
   * @memberof ProductItemReserve
   */
  code?: string;
  /**
   * Идентификатор клиента
   * @type {string}
   * @memberof ProductItemReserve
   */
  customerCode?: string;
  /**
   * Сокращение наименование клиента
   * @type {string}
   * @memberof ProductItemReserve
   */
  customerShort?: string;
  /**
   * Наименование клиента
   * @type {string}
   * @memberof ProductItemReserve
   */
  customerTitle?: string;
  /**
   * Код сделки
   * @type {string}
   * @memberof ProductItemReserve
   */
  dealCode?: string;
  /**
   * Код сотрудника, оформившего сделку
   * @type {string}
   * @memberof ProductItemReserve
   */
  dealEmployeeCode?: string;
  /**
   * Имя сотрудника
   * @type {string}
   * @memberof ProductItemReserve
   */
  dealEmployeeName?: string;
  /**
   * Отчество
   * @type {string}
   * @memberof ProductItemReserve
   */
  dealEmployeePatronymic?: string;
  /**
   * Фамилия сотрудника, оформившего сделку
   * @type {string}
   * @memberof ProductItemReserve
   */
  dealEmployeeSurname?: string;
  /**
   * Номер документа
   * @type {string}
   * @memberof ProductItemReserve
   */
  documentNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemReserve
   */
  freezeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemReserve
   */
  freezeDocumentDate?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemReserve
   */
  freezeDocumentNumber?: string;
  /**
   * Стоимость в МРЦ
   * @type {number}
   * @memberof ProductItemReserve
   */
  mrcTotalCost?: number;
  /**
   * Потребность в резерве (только для мягких резервов)
   * @type {number}
   * @memberof ProductItemReserve
   */
  plannedQuantity?: number;
  /**
   * Код товара
   * @type {string}
   * @memberof ProductItemReserve
   */
  productCode?: string;
  /**
   * Цена товара по счету резерва
   * @type {number}
   * @memberof ProductItemReserve
   */
  productCost?: number;
  /**
   * Цена товара по счету резерва (максимальная)
   * @type {number}
   * @memberof ProductItemReserve
   */
  productCostMax?: number;
  /**
   * Цена товара по счету резерва (минимальная)
   * @type {number}
   * @memberof ProductItemReserve
   */
  productCostMin?: number;
  /**
   * Стоимость в цене с услугами
   * @type {number}
   * @memberof ProductItemReserve
   */
  productTotalCost?: number;
  /**
   * Стоимость в цене закупки
   * @type {number}
   * @memberof ProductItemReserve
   */
  purchaseTotalCost?: number;
  /**
   * Количество зарезервированного товара
   * @type {number}
   * @memberof ProductItemReserve
   */
  quantity?: number;
  /**
   * Дней резерва
   * @type {number}
   * @memberof ProductItemReserve
   */
  reserveDays?: number;
  /**
   * Дата резерва
   * @type {string}
   * @memberof ProductItemReserve
   */
  reservedAt?: string;
  /**
   * Код состояния резерва
   * @type {string}
   * @memberof ProductItemReserve
   */
  stateCode?: ProductItemReserveStateCodeEnum;
  /**
   * Состояние резерва для отображения
   * @type {string}
   * @memberof ProductItemReserve
   */
  stateTitle?: string;
  /**
   * Код типа резерва
   * @type {string}
   * @memberof ProductItemReserve
   */
  typeCode?: ProductItemReserveTypeCodeEnum;
  /**
   * Описание типа резерва
   * @type {string}
   * @memberof ProductItemReserve
   */
  typeDescription?: string;
  /**
   * Тип резерва для отображения
   * @type {string}
   * @memberof ProductItemReserve
   */
  typeTitle?: string;
  /**
   * Единица измерения
   * @type {string}
   * @memberof ProductItemReserve
   */
  unit?: string;
  /**
   * Код единицы измерения
   * @type {string}
   * @memberof ProductItemReserve
   */
  unitCode?: string;
  /**
   * Код склада
   * @type {string}
   * @memberof ProductItemReserve
   */
  warehouseCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemReserve
   */
  warehouseName?: string;
  /**
   * Вес в тоннах
   * @type {number}
   * @memberof ProductItemReserve
   */
  weightTne?: number;
}

export const ProductItemReserveStateCodeEnum = {
  NotFormed: 'not_formed',
  Formed: 'formed',
  PartiallyFormed: 'partially_formed',
  Confirmed: 'confirmed',
} as const;

export type ProductItemReserveStateCodeEnum = (typeof ProductItemReserveStateCodeEnum)[keyof typeof ProductItemReserveStateCodeEnum];
export const ProductItemReserveTypeCodeEnum = {
  Consignment: 'consignment',
  Reservation: 'reservation',
  BillCreated: 'bill_created',
  BillSoftReserve: 'bill_soft_reserve',
  Freeze: 'freeze',
} as const;

export type ProductItemReserveTypeCodeEnum = (typeof ProductItemReserveTypeCodeEnum)[keyof typeof ProductItemReserveTypeCodeEnum];

/**
 * Остаток на складе
 * @export
 * @interface ProductItemStock
 */
export interface ProductItemStock {
  /**
   * Распределенные товары
   * @type {number}
   * @memberof ProductItemStock
   */
  distributedQuantity?: number;
  /**
   * В накладных
   * @type {number}
   * @memberof ProductItemStock
   */
  inConsignment?: number;
  /**
   * В резерве
   * @type {number}
   * @memberof ProductItemStock
   */
  inReserve?: number;
  /**
   * В мягком резерве
   * @type {number}
   * @memberof ProductItemStock
   */
  inSoftReserve?: number;
  /**
   * Всего на складе (включая резерв)
   * @type {number}
   * @memberof ProductItemStock
   */
  inStock?: number;
  /**
   * Свободный остаток
   * @type {number}
   * @memberof ProductItemStock
   */
  inStockForSale?: number;
  /**
   * Товары в пути
   * @type {number}
   * @memberof ProductItemStock
   */
  inTransitQuantity?: number;
  /**
   * Код единицы измерения остатка
   * @type {string}
   * @memberof ProductItemStock
   */
  unit?: string;
  /**
   * Единица измерения остатка
   * @type {string}
   * @memberof ProductItemStock
   */
  unitShortName?: string;
  /**
   * Код склада
   * @type {string}
   * @memberof ProductItemStock
   */
  warehouseCode?: string;
  /**
   *
   * @type {string}
   * @memberof ProductItemStock
   */
  warehouseName?: string;
}
/**
 * Свойство товара (для фасетного поиска)
 * @export
 * @interface ProductProperty
 */
export interface ProductProperty {
  /**
   *
   * @type {string}
   * @memberof ProductProperty
   */
  code?: string;
  /**
   * Признак для сортировки свойств. Может быть отрицательным.
   * @type {number}
   * @memberof ProductProperty
   */
  sort?: number;
  /**
   *
   * @type {string}
   * @memberof ProductProperty
   */
  title?: string;
  /**
   * Тип свойства (string, int, float, bool, color)
   * @type {string}
   * @memberof ProductProperty
   */
  typeCode?: string;
  /**
   *
   * @type {Array<ProductPropertyValue>}
   * @memberof ProductProperty
   */
  values?: Array<ProductPropertyValue>;
}
/**
 * Возможное значение свойства
 * @export
 * @interface ProductPropertyValue
 */
export interface ProductPropertyValue {
  /**
   *
   * @type {string}
   * @memberof ProductPropertyValue
   */
  code?: string;
  /**
   * Оценка количества товаров с этим значением
   * @type {number}
   * @memberof ProductPropertyValue
   */
  productsQuantity?: number;
  /**
   * Было ли выбрано это свойство для фильтрации
   * @type {boolean}
   * @memberof ProductPropertyValue
   */
  selected?: boolean;
  /**
   * Значение свойства
   * @type {string}
   * @memberof ProductPropertyValue
   */
  value?: string;
}
/**
 *
 * @export
 * @interface ProductReservesRequest
 */
export interface ProductReservesRequest {
  /**
   * Включено авто-резервирование
   * @type {boolean}
   * @memberof ProductReservesRequest
   */
  autoReserveEnabled?: boolean | null;
  /**
   * Коды филиалов
   * @type {Array<string>}
   * @memberof ProductReservesRequest
   */
  branchOfficeCodes?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ProductReservesRequest
   */
  count?: number | null;
  /**
   * Найти жёсткие резервы
   * @type {boolean}
   * @memberof ProductReservesRequest
   */
  findHardReserves?: boolean;
  /**
   * Найти мягкие резервы
   * @type {boolean}
   * @memberof ProductReservesRequest
   */
  findSoftReserves?: boolean;
  /**
   * Коды номенклатур
   * @type {Array<string>}
   * @memberof ProductReservesRequest
   */
  nomenclatureCodes?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof ProductReservesRequest
   */
  page?: number | null;
  /**
   * Коды статусов оплаты
   * @type {Array<string>}
   * @memberof ProductReservesRequest
   */
  paymentStateCodes?: Array<string> | null;
  /**
   * Коды товара
   * @type {Array<string>}
   * @memberof ProductReservesRequest
   */
  productCodes?: Array<string>;
  /**
   * Количество дней резерва ОТ
   * @type {number}
   * @memberof ProductReservesRequest
   */
  reserveDaysFrom?: number | null;
  /**
   * Количество дней резерва ДО
   * @type {number}
   * @memberof ProductReservesRequest
   */
  reserveDaysTo?: number | null;
  /**
   * Состояние резерва
   * @type {Array<string>}
   * @memberof ProductReservesRequest
   */
  reserveStateCodes?: Array<ProductReservesRequestReserveStateCodesEnum>;
  /**
   * Сортировать по позиции в очереди мягкого резерва (не применимо для findHardReserves)
   * @type {boolean}
   * @memberof ProductReservesRequest
   */
  sortByRank?: boolean;
  /**
   * Коды складов
   * @type {Array<string>}
   * @memberof ProductReservesRequest
   */
  warehouseCodes?: Array<string>;
}

export const ProductReservesRequestReserveStateCodesEnum = {
  NotFormed: 'not_formed',
  Formed: 'formed',
  PartiallyFormed: 'partially_formed',
  Confirmed: 'confirmed',
} as const;

export type ProductReservesRequestReserveStateCodesEnum =
  (typeof ProductReservesRequestReserveStateCodesEnum)[keyof typeof ProductReservesRequestReserveStateCodesEnum];

/**
 * Графики цен и отгрузок для страницы сделки
 * @export
 * @interface ProductStatsPlot
 */
export interface ProductStatsPlot {
  /**
   *
   * @type {Plot}
   * @memberof ProductStatsPlot
   */
  plot?: Plot;
  /**
   *
   * @type {string}
   * @memberof ProductStatsPlot
   */
  plotType?: ProductStatsPlotPlotTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProductStatsPlot
   */
  productCode?: string;
  /**
   * Разница между последним и первым значением
   * @type {number}
   * @memberof ProductStatsPlot
   */
  summary?: number;
}

export const ProductStatsPlotPlotTypeEnum = {
  CompetitorsPrice: 'competitors_price',
  ShipmentAmount: 'shipment_amount',
  ShipmentPrice: 'shipment_price',
} as const;

export type ProductStatsPlotPlotTypeEnum = (typeof ProductStatsPlotPlotTypeEnum)[keyof typeof ProductStatsPlotPlotTypeEnum];

/**
 * ЕИ для выбора
 * @export
 * @interface ProductUnit
 */
export interface ProductUnit {
  /**
   *
   * @type {string}
   * @memberof ProductUnit
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductUnit
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof ProductUnit
   */
  weight?: number;
}
/**
 *
 * @export
 * @interface QueueProducePresetItemResponse
 */
export interface QueueProducePresetItemResponse {
  /**
   *
   * @type {number}
   * @memberof QueueProducePresetItemResponse
   */
  duration?: number;
  /**
   *
   * @type {object}
   * @memberof QueueProducePresetItemResponse
   */
  extra?: object;
  /**
   *
   * @type {string}
   * @memberof QueueProducePresetItemResponse
   */
  topic?: string;
}
/**
 *
 * @export
 * @interface Refusal
 */
export interface Refusal {
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  billCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  billDocumentDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  billDocumentNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  createdAt?: string;
  /**
   *
   * @type {Customer}
   * @memberof Refusal
   */
  customer?: Customer;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  dealCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  dealCreatedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  documentNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof Refusal
   */
  isCanceled?: boolean;
  /**
   *
   * @type {Array<RefusalPosition>}
   * @memberof Refusal
   */
  positions?: Array<RefusalPosition>;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  shipmentDocumentDate?: string | null;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  shipmentDocumentNumber?: string | null;
  /**
   *
   * @type {number}
   * @memberof Refusal
   */
  totalWeight?: number;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  warehouseCode?: string;
  /**
   *
   * @type {string}
   * @memberof Refusal
   */
  warehouseName?: string;
}
/**
 *
 * @export
 * @interface RefusalPosition
 */
export interface RefusalPosition {
  /**
   *
   * @type {string}
   * @memberof RefusalPosition
   */
  card?: string;
  /**
   *
   * @type {string}
   * @memberof RefusalPosition
   */
  cassette?: string;
  /**
   *
   * @type {string}
   * @memberof RefusalPosition
   */
  nomenclatureCode?: string;
  /**
   *
   * @type {string}
   * @memberof RefusalPosition
   */
  packNumber?: string;
  /**
   *
   * @type {number}
   * @memberof RefusalPosition
   */
  positionNumber?: number;
  /**
   *
   * @type {string}
   * @memberof RefusalPosition
   */
  productCode?: string;
  /**
   *
   * @type {string}
   * @memberof RefusalPosition
   */
  productTitle?: string;
  /**
   *
   * @type {number}
   * @memberof RefusalPosition
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof RefusalPosition
   */
  quantityUnit?: number;
  /**
   *
   * @type {string}
   * @memberof RefusalPosition
   */
  unitCode?: string;
  /**
   *
   * @type {string}
   * @memberof RefusalPosition
   */
  unitShortName?: string;
  /**
   *
   * @type {string}
   * @memberof RefusalPosition
   */
  updatedAt?: string;
  /**
   *
   * @type {number}
   * @memberof RefusalPosition
   */
  weight?: number;
}
/**
 *
 * @export
 * @interface RefusalsRequest
 */
export interface RefusalsRequest {
  /**
   * Код партии
   * @type {string}
   * @memberof RefusalsRequest
   */
  batchCode?: string | null;
  /**
   * Код счета
   * @type {string}
   * @memberof RefusalsRequest
   */
  billCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof RefusalsRequest
   */
  code?: string | null;
  /**
   *
   * @type {number}
   * @memberof RefusalsRequest
   */
  count?: number | null;
  /**
   * Номер документа
   * @type {string}
   * @memberof RefusalsRequest
   */
  documentNumber?: string | null;
  /**
   *
   * @type {number}
   * @memberof RefusalsRequest
   */
  page?: number | null;
  /**
   * Код отгрузки
   * @type {string}
   * @memberof RefusalsRequest
   */
  shipmentCode?: string | null;
}
/**
 *
 * @export
 * @interface RefusalsResponse
 */
export interface RefusalsResponse {
  /**
   *
   * @type {Array<Refusal>}
   * @memberof RefusalsResponse
   */
  refusals?: Array<Refusal>;
  /**
   *
   * @type {number}
   * @memberof RefusalsResponse
   */
  refusalsTotalCount?: number;
}
/**
 * Данные представителя
 * @export
 * @interface Representative
 */
export interface Representative {
  /**
   *
   * @type {string}
   * @memberof Representative
   */
  code?: string;
  /**
   *
   * @type {boolean}
   * @memberof Representative
   */
  isCurrent?: boolean;
  /**
   *
   * @type {string}
   * @memberof Representative
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Representative
   */
  patronymic?: string;
  /**
   * Путь к изображению подписи
   * @type {string}
   * @memberof Representative
   */
  signature?: string;
  /**
   *
   * @type {string}
   * @memberof Representative
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof Representative
   */
  title?: string;
}
/**
 * Сводка по резервированию товара на складе (относительно заявки или счёта)
 * @export
 * @interface ReservationSummary
 */
export interface ReservationSummary {
  /**
   * Количество в базовой единице (может не совпадать с baseQuantity у позиции). deprecated - следует использовать dealBaseQuantity or billBaseQuantity в зависимости от контекста
   * @type {number}
   * @memberof ReservationSummary
   */
  baseQuantity?: number;
  /**
   * Количество в базовой единице в счёте (может не совпадать с baseQuantity у позиции)
   * @type {number}
   * @memberof ReservationSummary
   */
  billBaseQuantity?: number;
  /**
   * Количество в базовой единице в заявке (может не совпадать с baseQuantity у позиции)
   * @type {number}
   * @memberof ReservationSummary
   */
  dealBaseQuantity?: number;
  /**
   * Количество в мягком резерве в БЕИ, которые находятся в очереди перед этим счетом
   * @type {number}
   * @memberof ReservationSummary
   */
  inAwaitingQuantity?: number;
  /**
   * Количество в мягком резерве в БЕИ, которые находятся в очереди перед этим счетом со включенным авто-резервированием
   * @type {number}
   * @memberof ReservationSummary
   */
  inAwaitingQuantityWithAutoReserveEnabled?: number;
  /**
   * Количество в жестком резерве в БЕИ
   * @type {number}
   * @memberof ReservationSummary
   */
  inHardReserveQuantity?: number;
  /**
   * Количество в мягком резерве в БЕИ
   * @type {number}
   * @memberof ReservationSummary
   */
  inSoftReserveQuantity?: number;
  /**
   * Количество в накладных в БЕИ
   * @type {number}
   * @memberof ReservationSummary
   */
  inWaybillQuantity?: number;
  /**
   * Отгруженное количество в БЕИ
   * @type {number}
   * @memberof ReservationSummary
   */
  shippedQuantity?: number;
  /**
   * Стоимость отгрузок по товару
   * @type {number}
   * @memberof ReservationSummary
   */
  shippedTotalCost?: number;
}
/**
 *
 * @export
 * @interface ReturnsBranchOffice
 */
export interface ReturnsBranchOffice {
  /**
   * Код филиала
   * @type {string}
   * @memberof ReturnsBranchOffice
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ReturnsBranchOffice
   */
  name?: string;
}
/**
 *
 * @export
 * @interface SalePlanCustomersHistoryItem
 */
export interface SalePlanCustomersHistoryItem {
  /**
   *
   * @type {string}
   * @memberof SalePlanCustomersHistoryItem
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof SalePlanCustomersHistoryItem
   */
  budgetGroupCode?: string;
  /**
   *
   * @type {string}
   * @memberof SalePlanCustomersHistoryItem
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof SalePlanCustomersHistoryItem
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof SalePlanCustomersHistoryItem
   */
  initiatorEmployeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof SalePlanCustomersHistoryItem
   */
  initiatorEmployeeTitle?: string;
  /**
   *
   * @type {string}
   * @memberof SalePlanCustomersHistoryItem
   */
  month?: string;
  /**
   *
   * @type {number}
   * @memberof SalePlanCustomersHistoryItem
   */
  newQuantity?: number;
  /**
   *
   * @type {number}
   * @memberof SalePlanCustomersHistoryItem
   */
  oldQuantity?: number;
}
/**
 * Отгрузка
 * @export
 * @interface SaleShipment
 */
export interface SaleShipment {
  /**
   * УПД - универсальный передаточный документ
   * @type {string}
   * @memberof SaleShipment
   */
  bbNumber?: string;
  /**
   * Срок отсрочки
   * @type {number}
   * @memberof SaleShipment
   */
  billDelayDays?: number;
  /**
   * Дата формирования счета
   * @type {string}
   * @memberof SaleShipment
   */
  billDocumentDate?: string;
  /**
   * Код счета, если известен
   * @type {string}
   * @memberof SaleShipment
   */
  billDocumentNumber?: string;
  /**
   * Дата, от которой считается просрочка
   * @type {string}
   * @memberof SaleShipment
   */
  billOverdueDate?: string | null;
  /**
   * Количество дней просрочки
   * @type {number}
   * @memberof SaleShipment
   */
  billOverdueDays?: number;
  /**
   * Сумма просрочено (ПДЗ)
   * @type {number}
   * @memberof SaleShipment
   */
  billOverdueSum?: number;
  /**
   * Статус оплаты по счету
   * @type {string}
   * @memberof SaleShipment
   */
  billPaymentStateTitle?: string;
  /**
   * Сумма отгрузки
   * @type {number}
   * @memberof SaleShipment
   */
  billShipmentTotalCost?: number;
  /**
   * Сумма не оплачено
   * @type {number}
   * @memberof SaleShipment
   */
  billUnpaidSum?: number;
  /**
   * Код филиала
   * @type {string}
   * @memberof SaleShipment
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof SaleShipment
   */
  branchOfficeName?: string;
  /**
   * Разрешено редактировать комментарии по отгрузке
   * @type {boolean}
   * @memberof SaleShipment
   */
  canEditComments?: boolean;
  /**
   *
   * @type {Array<SaleShipmentComment>}
   * @memberof SaleShipment
   */
  comments?: Array<SaleShipmentComment>;
  /**
   * Код сделки, если известен
   * @type {string}
   * @memberof SaleShipment
   */
  dealCode?: string;
  /**
   *
   * @type {string}
   * @memberof SaleShipment
   */
  dealCreatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof SaleShipment
   */
  dealSubmittedAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof SaleShipment
   */
  distributorEmployee?: Employee;
  /**
   * Разрешено отправлять во внешнюю систему ЭДО
   * @type {boolean}
   * @memberof SaleShipment
   */
  docFlowAllowSend?: boolean;
  /**
   *
   * @type {string}
   * @memberof SaleShipment
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof SaleShipment
   */
  documentNumber?: string;
  /**
   * Документ в статусе удален
   * @type {boolean}
   * @memberof SaleShipment
   */
  isDeleted?: boolean;
  /**
   *
   * @type {SaleShipmentMotivation}
   * @memberof SaleShipment
   */
  motivation?: SaleShipmentMotivation;
  /**
   *
   * @type {Employee}
   * @memberof SaleShipment
   */
  motivationEmployee?: Employee;
  /**
   *
   * @type {SaleShipmentPaymentSummary}
   * @memberof SaleShipment
   */
  paymentSummary?: SaleShipmentPaymentSummary;
  /**
   * Платёжные ожидания по отгрузке
   * @type {Array<PaymentExpectation>}
   * @memberof SaleShipment
   */
  planPaymentExpectations?: Array<PaymentExpectation>;
  /**
   *
   * @type {Array<SaleShipmentPosition>}
   * @memberof SaleShipment
   */
  positions?: Array<SaleShipmentPosition>;
  /**
   * Общая стоимость позиций
   * @type {number}
   * @memberof SaleShipment
   */
  positionsTotalCost?: number;
  /**
   * Общий вес позиций, кг
   * @type {number}
   * @memberof SaleShipment
   */
  positionsTotalWeight?: number;
  /**
   *
   * @type {Customer}
   * @memberof SaleShipment
   */
  recipientCustomer?: Customer;
  /**
   * Итого по возвратам, руб.
   * @type {number}
   * @memberof SaleShipment
   */
  returnsTotalCost?: number | null;
  /**
   * Итого по возвратам, кг
   * @type {number}
   * @memberof SaleShipment
   */
  returnsTotalWeight?: number | null;
  /**
   *
   * @type {string}
   * @memberof SaleShipment
   */
  shipmentCode?: string;
  /**
   *
   * @type {string}
   * @memberof SaleShipment
   */
  shipmentDate?: string;
  /**
   * Время отгрузки, если известно
   * @type {string}
   * @memberof SaleShipment
   */
  shipmentTime?: string;
  /**
   * Итоговая премия менеджера
   * @type {number}
   * @memberof SaleShipment
   */
  totalPremiumAmount?: number;
  /**
   * Тип отгрузки - код
   * @type {string}
   * @memberof SaleShipment
   */
  typeCode?: string;
  /**
   * Тип отгрузки - имя
   * @type {string}
   * @memberof SaleShipment
   */
  typeName?: string;
  /**
   * Тип отгрузки - короткое имя
   * @type {string}
   * @memberof SaleShipment
   */
  typeShortName?: string;
  /**
   *
   * @type {Warehouse}
   * @memberof SaleShipment
   */
  warehouse?: Warehouse;
}
/**
 *
 * @export
 * @interface SaleShipmentComment
 */
export interface SaleShipmentComment {
  /**
   * Код комментария отгрузки
   * @type {string}
   * @memberof SaleShipmentComment
   */
  code: string;
  /**
   * Комментарий отгрузки
   * @type {string}
   * @memberof SaleShipmentComment
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof SaleShipmentComment
   */
  createdAt?: string;
  /**
   *
   * @type {SaleShipmentCommentType}
   * @memberof SaleShipmentComment
   */
  type: SaleShipmentCommentType;
  /**
   *
   * @type {string}
   * @memberof SaleShipmentComment
   */
  updatedAt?: string;
}
/**
 *
 * @export
 * @interface SaleShipmentCommentType
 */
export interface SaleShipmentCommentType {
  /**
   * Код типа комментария отгрузки
   * @type {string}
   * @memberof SaleShipmentCommentType
   */
  code: string;
  /**
   * Описание типа комментария отгрузки
   * @type {string}
   * @memberof SaleShipmentCommentType
   */
  description?: string;
  /**
   * Заголовок типа комментария отгрузки
   * @type {string}
   * @memberof SaleShipmentCommentType
   */
  title?: string;
}
/**
 * Итоговая мотивация по отгрузке
 * @export
 * @interface SaleShipmentMotivation
 */
export interface SaleShipmentMotivation {
  /**
   * Премия БСЦ
   * @type {number}
   * @memberof SaleShipmentMotivation
   */
  centralPurchaseAmount?: number;
  /**
   * Премия П1
   * @type {number}
   * @memberof SaleShipmentMotivation
   */
  p1Amount?: number;
  /**
   * Премия П2
   * @type {number}
   * @memberof SaleShipmentMotivation
   */
  p2Amount?: number;
  /**
   * Премия БСС
   * @type {number}
   * @memberof SaleShipmentMotivation
   */
  selfPurchaseAmount?: number;
  /**
   * Итоговая премия менеджера
   * @type {number}
   * @memberof SaleShipmentMotivation
   */
  totalPremiumAmount?: number;
}
/**
 * Сводка по оплате отгрузки
 * @export
 * @interface SaleShipmentPaymentSummary
 */
export interface SaleShipmentPaymentSummary {
  /**
   *
   * @type {number}
   * @memberof SaleShipmentPaymentSummary
   */
  daysLeft?: number | null;
  /**
   *
   * @type {number}
   * @memberof SaleShipmentPaymentSummary
   */
  daysOverdue?: number | null;
  /**
   * Списание догов
   * @type {number}
   * @memberof SaleShipmentPaymentSummary
   */
  debtReliefAmount?: number;
  /**
   * Просрочено
   * @type {number}
   * @memberof SaleShipmentPaymentSummary
   */
  overdueReceivablesAmount?: number;
  /**
   * Оплачено (включая списание долгов)
   * @type {number}
   * @memberof SaleShipmentPaymentSummary
   */
  paidAmount?: number;
  /**
   *
   * @type {string}
   * @memberof SaleShipmentPaymentSummary
   */
  paymentStateCode?: string;
  /**
   *
   * @type {string}
   * @memberof SaleShipmentPaymentSummary
   */
  paymentStateTitle?: string;
  /**
   *
   * @type {string}
   * @memberof SaleShipmentPaymentSummary
   */
  plannedDate?: string;
  /**
   * Дебиторская задолженность
   * @type {number}
   * @memberof SaleShipmentPaymentSummary
   */
  receivablesAmount?: number;
  /**
   *
   * @type {string}
   * @memberof SaleShipmentPaymentSummary
   */
  updatedAt?: string;
}
/**
 * Позиция отгрузки
 * @export
 * @interface SaleShipmentPosition
 */
export interface SaleShipmentPosition {
  /**
   * Карточка (код партии)
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  card?: string;
  /**
   * Кассета
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  cassette?: string;
  /**
   * Название файла сертификата
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  certificateName?: string;
  /**
   * Голая цена в заявке на сделку
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  dealBareUnitCost?: number;
  /**
   * Стоимость доставки
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  deliveryCost?: number;
  /**
   * Код внешнего дефекта
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  illiquidDefectCode?: string;
  /**
   * Название вида дефектов
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  illiquidDefectTitle?: string;
  /**
   * Размер скидки по некондиции, руб
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  illiquidDiscountAbs?: number;
  /**
   * Размер скидки по некондиции, %
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  illiquidDiscountPct?: number;
  /**
   * Код степени ржавчины
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  illiquidRustCode?: string;
  /**
   * Название степени ржавчины
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  illiquidRustTitle?: string;
  /**
   *
   * @type {boolean}
   * @memberof SaleShipmentPosition
   */
  isService?: boolean;
  /**
   *
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  marginalityPct?: number;
  /**
   * Минимальная розничная цена
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  minRetailUnitCost?: number;
  /**
   *
   * @type {SaleShipmentPositionMotivation}
   * @memberof SaleShipmentPosition
   */
  motivation?: SaleShipmentPositionMotivation;
  /**
   * Код номенклатуры
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  nomenclatureCode?: string;
  /**
   *
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  number?: number;
  /**
   * Пачка
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  packNumber?: string;
  /**
   *
   * @type {Product}
   * @memberof SaleShipmentPosition
   */
  product?: Product;
  /**
   * Артикул
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  productCode?: string;
  /**
   * Название товара контрагента
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  productParticipantName?: string;
  /**
   * Количество в указанных единицах измерения
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  quantity?: number;
  /**
   * Вес в тоннах
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  quantityTne?: number;
  /**
   * Компенсация по самозакупу за единицу, руб
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  selfPurchaseAward?: number;
  /**
   * Компенсация по самозакупу за всю позицию, руб
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  selfPurchaseAwardSum?: number;
  /**
   * Стоимость услуг (включая доставку)
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  serviceCost?: number;
  /**
   *
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  title?: string;
  /**
   * Дней хранения
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  todhDays?: number;
  /**
   * Размер скидки по неликвиду, руб
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  todhDiscountAbs?: number;
  /**
   * Скидка по неликвиду обнулена скидкой по некондиции
   * @type {boolean}
   * @memberof SaleShipmentPosition
   */
  todhDiscountIlliquidBlocked?: boolean;
  /**
   * Размер скидки по неликвиду, %
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  todhDiscountPct?: number;
  /**
   * Обозначение времени хранения (Т30, Т60, Т120...)
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  todhText?: string;
  /**
   * Цена за всю позицию
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  totalCost?: number;
  /**
   * Маржинальность на позицию отгрузки, руб.
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  totalCostMargin?: number;
  /**
   * Код единицы измерения
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  unitCode?: string;
  /**
   * Цена за единицу
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  unitCost?: number;
  /**
   * Маржинальность на единицу, руб.
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  unitCostMargin?: number;
  /**
   * Единица измерения
   * @type {string}
   * @memberof SaleShipmentPosition
   */
  unitShortName?: string;
  /**
   * НДС, руб
   * @type {number}
   * @memberof SaleShipmentPosition
   */
  vatCost?: number;
}
/**
 *
 * @export
 * @interface SaleShipmentPositionMotivation
 */
export interface SaleShipmentPositionMotivation {
  /**
   * Первая абсолютная процентная ставка по центрозакупу (П2.1%+БСЦ%)
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  absoluteCentralPurchasePct?: number;
  /**
   * Базовый маржинальный доход
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  baseMarginIncome?: number;
  /**
   * Признак центрозакупа
   * @type {boolean}
   * @memberof SaleShipmentPositionMotivation
   */
  centralPurchase?: boolean;
  /**
   * Премия БСЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  centralPurchaseAmount?: number;
  /**
   * Процентная ставка по центрозакупу
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  centralPurchasePct?: number;
  /**
   * Компенсация по неликвиду, за тонну
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  compensationIlliquid?: number;
  /**
   * Ставка, % для расчета компенсации по неликвиду
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  compensationIlliquidPct?: number;
  /**
   * Компенсация по некондиции, за тонну
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  compensationTodh?: number;
  /**
   * Ставка, % для расчета компенсации по некондиции
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  compensationTodhPct?: number;
  /**
   * Первая сумма превышения МРЦ для БСЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  cpMrcExceedAmount1?: number;
  /**
   * Вторая сумма превышения МРЦ для БСЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  cpMrcExceedAmount2?: number;
  /**
   * Третья сумма превышения МРЦ для БСЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  cpMrcExceedAmount3?: number;
  /**
   * Четвертая сумма превышения МРЦ для БСЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  cpMrcExceedAmount4?: number;
  /**
   * Вторая абсолютная процентная ставка по центрозакупу (П2.2%+БСЦ%)
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  cpPct2?: number;
  /**
   * Третья абсолютная процентная ставка по центрозакупу (П2.3%+БСЦ%)
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  cpPct3?: number;
  /**
   * Четвертая абсолютная процентная ставка по центрозакупу (П2.4%+БСЦ%)
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  cpPct4?: number;
  /**
   * МРЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  minRetailUnitCost?: number;
  /**
   * База мотивационной маржинальности (МРЦ с полномочиями директора филиала)
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  mmbUnitCost?: number;
  /**
   * Код вида мотивации
   * @type {string}
   * @memberof SaleShipmentPositionMotivation
   */
  motivationTypeCode?: string;
  /**
   * Наименование вида мотивации
   * @type {string}
   * @memberof SaleShipmentPositionMotivation
   */
  motivationTypeName?: string;
  /**
   * Превышение МРЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  mrcExceedAmount?: number;
  /**
   * Первая шкала превышения МРЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  mrcExceedAmount1?: number;
  /**
   * Вторая шкала превышения МРЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  mrcExceedAmount2?: number;
  /**
   * Третья шкала превышения МРЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  mrcExceedAmount3?: number;
  /**
   * Четвертая шкала превышения МРЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  mrcExceedAmount4?: number;
  /**
   * Премия П1
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  p1Amount?: number;
  /**
   * Ставка бюджетной группы для расчета П1
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  p1Rate?: number;
  /**
   * Премия П2
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  p2Amount?: number;
  /**
   * Процентная ставка для расчета П2 (если имеет место превышение МРЦ, то эта ставка используется для первой шкалы)
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  p2Pct?: number;
  /**
   * Процентная ставка для расчета П2, для второй шкалы превышения МРЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  p2Pct2?: number;
  /**
   * Процентная ставка для расчета П2, для третьей шкалы превышения МРЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  p2Pct3?: number;
  /**
   * Процентная ставка для расчета П2, для четвертой шкалы превышения МРЦ
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  p2Pct4?: number;
  /**
   * deprecated. Премия П3 разделена на selfPurchaseAmount и centralPurchaseAmount. Премия П3
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  p3Amount?: number;
  /**
   * Процентная ставка расчета премии П3
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  p3Pct?: number;
  /**
   * Цена закупки товара по самозакупу
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  purchaseUnitCost?: number;
  /**
   * Количество
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  quantity?: number;
  /**
   * Цена продажи товара, без услуг
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  saleUnitCost?: number;
  /**
   * Признак самозакупа
   * @type {boolean}
   * @memberof SaleShipmentPositionMotivation
   */
  selfPurchase?: boolean;
  /**
   * Премия БСС
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  selfPurchaseAmount?: number;
  /**
   * Базовый маржинальный доход по самозакупу (saleUnitCost - purchaseUnitCost)
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  selfPurchaseMarginIncome?: number;
  /**
   * Процентная ставка по самозакупу
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  selfPurchasePct?: number;
  /**
   * Итоговая премия позиции
   * @type {number}
   * @memberof SaleShipmentPositionMotivation
   */
  totalPremiumAmount?: number;
  /**
   * Пользовательская группа полномочий МРЦ
   * @type {string}
   * @memberof SaleShipmentPositionMotivation
   */
  userGroupCode?: string;
}
/**
 * Тип отгрузки
 * @export
 * @interface SaleShipmentType
 */
export interface SaleShipmentType {
  /**
   *
   * @type {string}
   * @memberof SaleShipmentType
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof SaleShipmentType
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SaleShipmentType
   */
  shortName?: string;
}
/**
 * Расходная накладная
 * @export
 * @interface SalesInvoice
 */
export interface SalesInvoice {
  /**
   * Дата формирования счета
   * @type {string}
   * @memberof SalesInvoice
   */
  billDocumentDate?: string;
  /**
   * Код счета, если известен
   * @type {string}
   * @memberof SalesInvoice
   */
  billDocumentNumber?: string;
  /**
   * Код сделки, если известен
   * @type {string}
   * @memberof SalesInvoice
   */
  dealCode?: string;
  /**
   *
   * @type {string}
   * @memberof SalesInvoice
   */
  dealCreatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof SalesInvoice
   */
  dealSubmittedAt?: string;
  /**
   *
   * @type {Employee}
   * @memberof SalesInvoice
   */
  distributorEmployee?: Employee;
  /**
   *
   * @type {string}
   * @memberof SalesInvoice
   */
  documentCode?: string;
  /**
   *
   * @type {string}
   * @memberof SalesInvoice
   */
  documentDate?: string;
  /**
   *
   * @type {string}
   * @memberof SalesInvoice
   */
  documentNumber?: string;
  /**
   *
   * @type {Array<SalesInvoicePosition>}
   * @memberof SalesInvoice
   */
  positions?: Array<SalesInvoicePosition>;
  /**
   * Общая стоимость позиций
   * @type {number}
   * @memberof SalesInvoice
   */
  positionsTotalCost?: number;
  /**
   * Общий вес позиций, кг
   * @type {number}
   * @memberof SalesInvoice
   */
  positionsTotalWeight?: number;
  /**
   *
   * @type {Customer}
   * @memberof SalesInvoice
   */
  recipientCustomer?: Customer;
  /**
   *
   * @type {Warehouse}
   * @memberof SalesInvoice
   */
  warehouse?: Warehouse;
}
/**
 * Позиция накладной
 * @export
 * @interface SalesInvoicePosition
 */
export interface SalesInvoicePosition {
  /**
   * Цена из заявки
   * @type {number}
   * @memberof SalesInvoicePosition
   */
  dealUnitCost?: number;
  /**
   *
   * @type {number}
   * @memberof SalesInvoicePosition
   */
  number?: number;
  /**
   *
   * @type {Product}
   * @memberof SalesInvoicePosition
   */
  product?: Product;
  /**
   * Количество в базовых единицах измерения
   * @type {number}
   * @memberof SalesInvoicePosition
   */
  quantity?: number;
  /**
   * Количество в продажных единицах измерения
   * @type {number}
   * @memberof SalesInvoicePosition
   */
  quantityUnit?: number;
  /**
   *
   * @type {string}
   * @memberof SalesInvoicePosition
   */
  title?: string;
  /**
   * Цена за всю позицию
   * @type {number}
   * @memberof SalesInvoicePosition
   */
  totalCost?: number;
  /**
   * Код единицы измерения
   * @type {string}
   * @memberof SalesInvoicePosition
   */
  unitCode?: string;
  /**
   * Цена за единицу
   * @type {number}
   * @memberof SalesInvoicePosition
   */
  unitCost?: number;
  /**
   * Единица измерения
   * @type {string}
   * @memberof SalesInvoicePosition
   */
  unitShortName?: string;
}
/**
 *
 * @export
 * @interface SalesInvoicesRequest
 */
export interface SalesInvoicesRequest {
  /**
   * номер документа счет, в нумерации MX
   * @type {string}
   * @memberof SalesInvoicesRequest
   */
  billNumber?: string;
  /**
   * Номер документа
   * @type {string}
   * @memberof SalesInvoicesRequest
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface SalesInvoicesResponse
 */
export interface SalesInvoicesResponse {
  /**
   * Общая сумма, руб.
   * @type {number}
   * @memberof SalesInvoicesResponse
   */
  salesInvoiceTotalCost?: number;
  /**
   * Общий вес, кг.
   * @type {number}
   * @memberof SalesInvoicesResponse
   */
  salesInvoiceTotalWeight?: number;
  /**
   * Расходные накладные
   * @type {Array<SalesInvoice>}
   * @memberof SalesInvoicesResponse
   */
  salesInvoices?: Array<SalesInvoice>;
}
/**
 * Результаты поиска по сайту
 * @export
 * @interface Search
 */
export interface Search {
  /**
   * Поисковый запрос
   * @type {string}
   * @memberof Search
   */
  query?: string;
  /**
   * Результаты поиска
   * @type {Array<SearchResultItem>}
   * @memberof Search
   */
  result?: Array<SearchResultItem>;
}
/**
 *
 * @export
 * @interface SearchDataItem
 */
export interface SearchDataItem {
  /**
   *
   * @type {string}
   * @memberof SearchDataItem
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof SearchDataItem
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof SearchDataItem
   */
  rank?: number;
  /**
   *
   * @type {string}
   * @memberof SearchDataItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
  /**
   *
   * @type {string}
   * @memberof SearchRequest
   */
  query?: string;
  /**
   *
   * @type {string}
   * @memberof SearchRequest
   */
  searchIn?: string;
}
/**
 *
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
  /**
   *
   * @type {Search}
   * @memberof SearchResponse
   */
  search?: Search;
}
/**
 *
 * @export
 * @interface SearchResultItem
 */
export interface SearchResultItem {
  /**
   *
   * @type {Array<SearchDataItem>}
   * @memberof SearchResultItem
   */
  data?: Array<SearchDataItem>;
  /**
   *
   * @type {number}
   * @memberof SearchResultItem
   */
  found?: number;
  /**
   *
   * @type {string}
   * @memberof SearchResultItem
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof SearchResultItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ShipmentBranchOffice
 */
export interface ShipmentBranchOffice {
  /**
   * Код филиала
   * @type {string}
   * @memberof ShipmentBranchOffice
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentBranchOffice
   */
  name?: string;
}
/**
 * Возврат по отгрузке
 * @export
 * @interface ShipmentReturn
 */
export interface ShipmentReturn {
  /**
   * Дата формирования счета
   * @type {string}
   * @memberof ShipmentReturn
   */
  billDocumentDate?: string;
  /**
   * Код счета, если известен
   * @type {string}
   * @memberof ShipmentReturn
   */
  billDocumentNumber?: string;
  /**
   * Код филиала
   * @type {string}
   * @memberof ShipmentReturn
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturn
   */
  branchOfficeName?: string;
  /**
   * Код сделки, если известен
   * @type {string}
   * @memberof ShipmentReturn
   */
  dealCode?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturn
   */
  dealCreatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturn
   */
  dealSubmittedAt?: string;
  /**
   * Документ в статусе удален
   * @type {boolean}
   * @memberof ShipmentReturn
   */
  isDeleted?: boolean;
  /**
   *
   * @type {Array<ShipmentReturnPosition>}
   * @memberof ShipmentReturn
   */
  positions?: Array<ShipmentReturnPosition>;
  /**
   * Общая стоимость позиций
   * @type {number}
   * @memberof ShipmentReturn
   */
  positionsTotalCost?: number;
  /**
   * Общий вес позиций, кг
   * @type {number}
   * @memberof ShipmentReturn
   */
  positionsTotalWeight?: number;
  /**
   *
   * @type {Customer}
   * @memberof ShipmentReturn
   */
  recipientCustomer?: Customer;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturn
   */
  returnCode?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturn
   */
  returnDocumentDate?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturn
   */
  returnDocumentNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturn
   */
  shipmentCode?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturn
   */
  shipmentDocumentDate?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturn
   */
  shipmentDocumentNumber?: string;
  /**
   *
   * @type {Warehouse}
   * @memberof ShipmentReturn
   */
  warehouse?: Warehouse;
}
/**
 * Позиция возврата
 * @export
 * @interface ShipmentReturnPosition
 */
export interface ShipmentReturnPosition {
  /**
   * Карточка назначения (код партии)
   * @type {string}
   * @memberof ShipmentReturnPosition
   */
  card?: string;
  /**
   * Название файла сертификата карточки назначения
   * @type {string}
   * @memberof ShipmentReturnPosition
   */
  certificateName?: string;
  /**
   * Карточка источник (код партии)
   * @type {string}
   * @memberof ShipmentReturnPosition
   */
  fromCard?: string;
  /**
   * Название файла сертификата карточки источника
   * @type {string}
   * @memberof ShipmentReturnPosition
   */
  fromCertificateName?: string;
  /**
   * Код номенклатуры
   * @type {string}
   * @memberof ShipmentReturnPosition
   */
  nomenclatureCode?: string;
  /**
   *
   * @type {number}
   * @memberof ShipmentReturnPosition
   */
  number?: number;
  /**
   *
   * @type {Product}
   * @memberof ShipmentReturnPosition
   */
  product?: Product;
  /**
   * Артикул
   * @type {string}
   * @memberof ShipmentReturnPosition
   */
  productCode?: string;
  /**
   * Количество в указанных единицах измерения
   * @type {number}
   * @memberof ShipmentReturnPosition
   */
  quantity?: number;
  /**
   * Вес в тоннах
   * @type {number}
   * @memberof ShipmentReturnPosition
   */
  quantityTne?: number;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturnPosition
   */
  title?: string;
  /**
   * Цена за всю позицию
   * @type {number}
   * @memberof ShipmentReturnPosition
   */
  totalCost?: number;
  /**
   * Код единицы измерения
   * @type {string}
   * @memberof ShipmentReturnPosition
   */
  unitCode?: string;
  /**
   * Цена за единицу
   * @type {number}
   * @memberof ShipmentReturnPosition
   */
  unitCost?: number;
  /**
   * Единица измерения
   * @type {string}
   * @memberof ShipmentReturnPosition
   */
  unitShortName?: string;
  /**
   * НДС, руб
   * @type {number}
   * @memberof ShipmentReturnPosition
   */
  vatCost?: number;
}
/**
 *
 * @export
 * @interface ShipmentReturnsRequest
 */
export interface ShipmentReturnsRequest {
  /**
   * Код партии
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  batchCode?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  billCode?: string;
  /**
   * Коды филиалов
   * @type {Array<string>}
   * @memberof ShipmentReturnsRequest
   */
  branchOfficeCodes?: Array<string> | null;
  /**
   * Уникальный код возврата
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  code?: string;
  /**
   *
   * @type {number}
   * @memberof ShipmentReturnsRequest
   */
  count?: number | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  dateFrom?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  dateTo?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  dealCode?: string;
  /**
   * Коды отделов
   * @type {Array<string>}
   * @memberof ShipmentReturnsRequest
   */
  departmentCodes?: Array<string>;
  /**
   * Коды дивизионов
   * @type {Array<string>}
   * @memberof ShipmentReturnsRequest
   */
  divisionCodes?: Array<string>;
  /**
   * Код документа оплаты
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  documentNumber?: string;
  /**
   * Коды сотрудников
   * @type {Array<string>}
   * @memberof ShipmentReturnsRequest
   */
  employeeCodes?: Array<string>;
  /**
   * Код сотрудника или группы
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  employeeSet?: string | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentReturnsRequest
   */
  page?: number | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  participantCode?: string;
  /**
   * Фильтр для поиска
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  query?: string | null;
  /**
   *
   * @type {string}
   * @memberof ShipmentReturnsRequest
   */
  shipmentCode?: string;
}
/**
 *
 * @export
 * @interface ShipmentReturnsResponse
 */
export interface ShipmentReturnsResponse {
  /**
   * Возвраты
   * @type {Array<ShipmentReturn>}
   * @memberof ShipmentReturnsResponse
   */
  returns?: Array<ShipmentReturn>;
  /**
   *
   * @type {Array<ReturnsBranchOffice>}
   * @memberof ShipmentReturnsResponse
   */
  returnsBranchOffices?: Array<ReturnsBranchOffice>;
  /**
   * Общая сумма, руб.
   * @type {number}
   * @memberof ShipmentReturnsResponse
   */
  returnsTotalCost?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentReturnsResponse
   */
  returnsTotalCount?: number;
  /**
   * Общий вес, кг.
   * @type {number}
   * @memberof ShipmentReturnsResponse
   */
  returnsTotalWeight?: number;
}
/**
 *
 * @export
 * @interface ShipmentReturnsThreadRequest
 */
export interface ShipmentReturnsThreadRequest {
  /**
   * Уникальный код возврата
   * @type {string}
   * @memberof ShipmentReturnsThreadRequest
   */
  code?: string;
  /**
   * Код документа возврата
   * @type {string}
   * @memberof ShipmentReturnsThreadRequest
   */
  documentNumber?: string;
}
/**
 *
 * @export
 * @interface ShipmentReturnsThreadResponse
 */
export interface ShipmentReturnsThreadResponse {
  /**
   *
   * @type {DocumentsThread}
   * @memberof ShipmentReturnsThreadResponse
   */
  documentsThread?: DocumentsThread;
}
/**
 *
 * @export
 * @interface ShipmentsCommentTypesResponse
 */
export interface ShipmentsCommentTypesResponse {
  /**
   *
   * @type {Array<SaleShipmentCommentType>}
   * @memberof ShipmentsCommentTypesResponse
   */
  shipmentTypes?: Array<SaleShipmentCommentType>;
}
/**
 *
 * @export
 * @interface ShipmentsItemUpdateCommentsRequest
 */
export interface ShipmentsItemUpdateCommentsRequest {
  /**
   *
   * @type {Array<ShipmentsItemUpdateCommentsRequestComment>}
   * @memberof ShipmentsItemUpdateCommentsRequest
   */
  comments?: Array<ShipmentsItemUpdateCommentsRequestComment>;
}
/**
 *
 * @export
 * @interface ShipmentsItemUpdateCommentsRequestComment
 */
export interface ShipmentsItemUpdateCommentsRequestComment {
  /**
   * Код комментария отгрузки для обновления
   * @type {string}
   * @memberof ShipmentsItemUpdateCommentsRequestComment
   */
  code?: string | null;
  /**
   * Комментарий отгрузки
   * @type {string}
   * @memberof ShipmentsItemUpdateCommentsRequestComment
   */
  comment?: string;
  /**
   * Код типа комментария отгрузки
   * @type {string}
   * @memberof ShipmentsItemUpdateCommentsRequestComment
   */
  typeCode: string;
}
/**
 *
 * @export
 * @interface ShipmentsRequest
 */
export interface ShipmentsRequest {
  /**
   * Код партии
   * @type {Array<string>}
   * @memberof ShipmentsRequest
   */
  batchCodes?: Array<string> | null;
  /**
   * Код счета
   * @type {string}
   * @memberof ShipmentsRequest
   */
  billCode?: string;
  /**
   * Коды филиалов
   * @type {Array<string>}
   * @memberof ShipmentsRequest
   */
  branchOfficeCodes?: Array<string> | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentsRequest
   */
  count?: number;
  /**
   * deprecated. use customerCodes. Код клиента
   * @type {string}
   * @memberof ShipmentsRequest
   */
  customerCode?: string;
  /**
   * Коды клиентов
   * @type {Array<string>}
   * @memberof ShipmentsRequest
   */
  customerCodes?: Array<string>;
  /**
   * Дата от
   * @type {string}
   * @memberof ShipmentsRequest
   */
  dateFrom?: string | null;
  /**
   * Дата до
   * @type {string}
   * @memberof ShipmentsRequest
   */
  dateTo?: string | null;
  /**
   * Код заявки на счет
   * @type {string}
   * @memberof ShipmentsRequest
   */
  dealCode?: string;
  /**
   * Номер документа
   * @type {string}
   * @memberof ShipmentsRequest
   */
  documentNumber?: string;
  /**
   * Код сотрудника или группы
   * @type {string}
   * @memberof ShipmentsRequest
   */
  employeeSet?: string | null;
  /**
   * Имеется сотрудник по замещению
   * @type {boolean}
   * @memberof ShipmentsRequest
   */
  hasEmployeeDeputy?: boolean;
  /**
   * Год и месяц
   * @type {string}
   * @memberof ShipmentsRequest
   */
  month?: string | null;
  /**
   *
   * @type {number}
   * @memberof ShipmentsRequest
   */
  page?: number;
  /**
   * Коды статусов оплаты
   * @type {Array<string>}
   * @memberof ShipmentsRequest
   */
  paymentStateCodes?: Array<string> | null;
  /**
   * filter for shipments
   * @type {string}
   * @memberof ShipmentsRequest
   */
  query?: string | null;
  /**
   * Код типа отгрузки
   * @type {string}
   * @memberof ShipmentsRequest
   */
  shipmentTypeCode?: string;
  /**
   * Коды складов
   * @type {Array<string>}
   * @memberof ShipmentsRequest
   */
  warehousesCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface ShipmentsResponse
 */
export interface ShipmentsResponse {
  /**
   *
   * @type {Array<SaleShipment>}
   * @memberof ShipmentsResponse
   */
  shipments?: Array<SaleShipment>;
  /**
   *
   * @type {Array<ShipmentBranchOffice>}
   * @memberof ShipmentsResponse
   */
  shipmentsBranchOffices?: Array<ShipmentBranchOffice>;
  /**
   * Общая сумма, руб.
   * @type {number}
   * @memberof ShipmentsResponse
   */
  shipmentsTotalCost?: number;
  /**
   *
   * @type {number}
   * @memberof ShipmentsResponse
   */
  shipmentsTotalCount?: number;
  /**
   * Общий вес, кг.
   * @type {number}
   * @memberof ShipmentsResponse
   */
  shipmentsTotalWeight?: number;
}
/**
 *
 * @export
 * @interface ShipmentsTypesResponse
 */
export interface ShipmentsTypesResponse {
  /**
   *
   * @type {Array<SaleShipmentType>}
   * @memberof ShipmentsTypesResponse
   */
  shipmentTypes?: Array<SaleShipmentType>;
}
/**
 *
 * @export
 * @interface StatAgreementsCustomersResponse
 */
export interface StatAgreementsCustomersResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatAgreementsCustomersResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<Customer>}
   * @memberof StatAgreementsCustomersResponse
   */
  customers?: Array<Customer>;
  /**
   * Реализаторы клиентов
   * @type {Array<Employee>}
   * @memberof StatAgreementsCustomersResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatAgreementsCustomersResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<CustomerAgreementsReport>}
   * @memberof StatAgreementsCustomersResponse
   */
  stats?: Array<CustomerAgreementsReport>;
}
/**
 *
 * @export
 * @interface StatAgreementsDivisionsResponse
 */
export interface StatAgreementsDivisionsResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatAgreementsDivisionsResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<BranchOfficeDivision>}
   * @memberof StatAgreementsDivisionsResponse
   */
  divisions?: Array<BranchOfficeDivision>;
  /**
   *
   * @type {Array<DivisionAgreementsReport>}
   * @memberof StatAgreementsDivisionsResponse
   */
  stats?: Array<DivisionAgreementsReport>;
}
/**
 *
 * @export
 * @interface StatAgreementsHeadCompaniesResponse
 */
export interface StatAgreementsHeadCompaniesResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatAgreementsHeadCompaniesResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<HeadCompany>}
   * @memberof StatAgreementsHeadCompaniesResponse
   */
  headCompanies?: Array<HeadCompany>;
  /**
   *
   * @type {Array<HeadCompanyAgreementsReport>}
   * @memberof StatAgreementsHeadCompaniesResponse
   */
  stats?: Array<HeadCompanyAgreementsReport>;
}
/**
 *
 * @export
 * @interface StatAgreementsOfficesResponse
 */
export interface StatAgreementsOfficesResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatAgreementsOfficesResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatAgreementsOfficesResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<BranchOfficeAgreementsReport>}
   * @memberof StatAgreementsOfficesResponse
   */
  stats?: Array<BranchOfficeAgreementsReport>;
}
/**
 *
 * @export
 * @interface StatMarginalityCustomersResponse
 */
export interface StatMarginalityCustomersResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMarginalityCustomersResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<Customer>}
   * @memberof StatMarginalityCustomersResponse
   */
  customers?: Array<Customer>;
  /**
   * Реализаторы клиентов
   * @type {Array<Employee>}
   * @memberof StatMarginalityCustomersResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatMarginalityCustomersResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<CustomerMarginalityReport>}
   * @memberof StatMarginalityCustomersResponse
   */
  stats?: Array<CustomerMarginalityReport>;
}
/**
 *
 * @export
 * @interface StatMarginalityDepartmentsResponse
 */
export interface StatMarginalityDepartmentsResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMarginalityDepartmentsResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<EmployeeDepartment>}
   * @memberof StatMarginalityDepartmentsResponse
   */
  departments?: Array<EmployeeDepartment>;
  /**
   *
   * @type {Array<DepartmentMarginalityReport>}
   * @memberof StatMarginalityDepartmentsResponse
   */
  stats?: Array<DepartmentMarginalityReport>;
}
/**
 *
 * @export
 * @interface StatMarginalityDivisionsResponse
 */
export interface StatMarginalityDivisionsResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMarginalityDivisionsResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<BranchOfficeDivision>}
   * @memberof StatMarginalityDivisionsResponse
   */
  divisions?: Array<BranchOfficeDivision>;
  /**
   *
   * @type {Array<DivisionMarginalityReport>}
   * @memberof StatMarginalityDivisionsResponse
   */
  stats?: Array<DivisionMarginalityReport>;
}
/**
 *
 * @export
 * @interface StatMarginalityEmployeeCustomersResponse
 */
export interface StatMarginalityEmployeeCustomersResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMarginalityEmployeeCustomersResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<Customer>}
   * @memberof StatMarginalityEmployeeCustomersResponse
   */
  customers?: Array<Customer>;
  /**
   * Реализаторы клиентов
   * @type {Array<Employee>}
   * @memberof StatMarginalityEmployeeCustomersResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatMarginalityEmployeeCustomersResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<EmployeeCustomerMarginalityReport>}
   * @memberof StatMarginalityEmployeeCustomersResponse
   */
  stats?: Array<EmployeeCustomerMarginalityReport>;
}
/**
 *
 * @export
 * @interface StatMarginalityEmployeeOverallRatingItem
 */
export interface StatMarginalityEmployeeOverallRatingItem {
  /**
   * Процент общего выполнения плана продаж
   * @type {number}
   * @memberof StatMarginalityEmployeeOverallRatingItem
   */
  achievement?: number;
  /**
   *
   * @type {Employee}
   * @memberof StatMarginalityEmployeeOverallRatingItem
   */
  employee?: Employee;
  /**
   * Позиция в рейтинге
   * @type {number}
   * @memberof StatMarginalityEmployeeOverallRatingItem
   */
  ratingPosition?: number;
}
/**
 *
 * @export
 * @interface StatMarginalityEmployeeOverallRatingResponse
 */
export interface StatMarginalityEmployeeOverallRatingResponse {
  /**
   *
   * @type {Array<StatMarginalityEmployeeOverallRatingItem>}
   * @memberof StatMarginalityEmployeeOverallRatingResponse
   */
  rating?: Array<StatMarginalityEmployeeOverallRatingItem>;
}
/**
 *
 * @export
 * @interface StatMarginalityEmployeesResponse
 */
export interface StatMarginalityEmployeesResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMarginalityEmployeesResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<Employee>}
   * @memberof StatMarginalityEmployeesResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatMarginalityEmployeesResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<EmployeeMarginalityReport>}
   * @memberof StatMarginalityEmployeesResponse
   */
  stats?: Array<EmployeeMarginalityReport>;
}
/**
 *
 * @export
 * @interface StatMarginalityHeadCompaniesResponse
 */
export interface StatMarginalityHeadCompaniesResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMarginalityHeadCompaniesResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<HeadCompany>}
   * @memberof StatMarginalityHeadCompaniesResponse
   */
  headCompanies?: Array<HeadCompany>;
  /**
   *
   * @type {Array<HeadCompanyMarginalityReport>}
   * @memberof StatMarginalityHeadCompaniesResponse
   */
  stats?: Array<HeadCompanyMarginalityReport>;
}
/**
 *
 * @export
 * @interface StatMarginalityOfficesResponse
 */
export interface StatMarginalityOfficesResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMarginalityOfficesResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatMarginalityOfficesResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<BranchOfficeMarginalityReport>}
   * @memberof StatMarginalityOfficesResponse
   */
  stats?: Array<BranchOfficeMarginalityReport>;
}
/**
 *
 * @export
 * @interface StatMarginalityReferencesResponse
 */
export interface StatMarginalityReferencesResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMarginalityReferencesResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   * Отделы
   * @type {Array<EmployeeDepartment>}
   * @memberof StatMarginalityReferencesResponse
   */
  departments?: Array<EmployeeDepartment>;
  /**
   *
   * @type {Array<BranchOfficeDivision>}
   * @memberof StatMarginalityReferencesResponse
   */
  divisions?: Array<BranchOfficeDivision>;
  /**
   * Реализаторы клиентов
   * @type {Array<Employee>}
   * @memberof StatMarginalityReferencesResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<HeadCompany>}
   * @memberof StatMarginalityReferencesResponse
   */
  headCompanies?: Array<HeadCompany>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatMarginalityReferencesResponse
   */
  offices?: Array<BranchOffice>;
}
/**
 *
 * @export
 * @interface StatMotivationCustomersResponse
 */
export interface StatMotivationCustomersResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMotivationCustomersResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   * Может ли пользователь просматривать виджет процентного исполнения удельного бюджета
   * @type {boolean}
   * @memberof StatMotivationCustomersResponse
   */
  canViewBudgetPerTon?: boolean;
  /**
   *
   * @type {Array<Customer>}
   * @memberof StatMotivationCustomersResponse
   */
  customers?: Array<Customer>;
  /**
   *
   * @type {Array<Employee>}
   * @memberof StatMotivationCustomersResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatMotivationCustomersResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<CustomerMotivationReport>}
   * @memberof StatMotivationCustomersResponse
   */
  stats?: Array<CustomerMotivationReport>;
}
/**
 *
 * @export
 * @interface StatMotivationDepartmentsResponse
 */
export interface StatMotivationDepartmentsResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMotivationDepartmentsResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   * Может ли пользователь просматривать виджет процентного исполнения удельного бюджета
   * @type {boolean}
   * @memberof StatMotivationDepartmentsResponse
   */
  canViewBudgetPerTon?: boolean;
  /**
   *
   * @type {Array<EmployeeDepartment>}
   * @memberof StatMotivationDepartmentsResponse
   */
  departments?: Array<EmployeeDepartment>;
  /**
   *
   * @type {Array<DepartmentMotivationReport>}
   * @memberof StatMotivationDepartmentsResponse
   */
  stats?: Array<DepartmentMotivationReport>;
}
/**
 *
 * @export
 * @interface StatMotivationDivisionsResponse
 */
export interface StatMotivationDivisionsResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMotivationDivisionsResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   * Может ли пользователь просматривать виджет процентного исполнения удельного бюджета
   * @type {boolean}
   * @memberof StatMotivationDivisionsResponse
   */
  canViewBudgetPerTon?: boolean;
  /**
   *
   * @type {Array<BranchOfficeDivision>}
   * @memberof StatMotivationDivisionsResponse
   */
  divisions?: Array<BranchOfficeDivision>;
  /**
   *
   * @type {Array<DivisionMotivationReport>}
   * @memberof StatMotivationDivisionsResponse
   */
  stats?: Array<DivisionMotivationReport>;
}
/**
 *
 * @export
 * @interface StatMotivationEmployeesCustomersResponse
 */
export interface StatMotivationEmployeesCustomersResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMotivationEmployeesCustomersResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   * Может ли пользователь просматривать виджет процентного исполнения удельного бюджета
   * @type {boolean}
   * @memberof StatMotivationEmployeesCustomersResponse
   */
  canViewBudgetPerTon?: boolean;
  /**
   *
   * @type {Array<Customer>}
   * @memberof StatMotivationEmployeesCustomersResponse
   */
  customers?: Array<Customer>;
  /**
   *
   * @type {Array<Employee>}
   * @memberof StatMotivationEmployeesCustomersResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatMotivationEmployeesCustomersResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<CustomerMotivationReport>}
   * @memberof StatMotivationEmployeesCustomersResponse
   */
  stats?: Array<CustomerMotivationReport>;
}
/**
 *
 * @export
 * @interface StatMotivationEmployeesResponse
 */
export interface StatMotivationEmployeesResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMotivationEmployeesResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   * Может ли пользователь просматривать виджет процентного исполнения удельного бюджета
   * @type {boolean}
   * @memberof StatMotivationEmployeesResponse
   */
  canViewBudgetPerTon?: boolean;
  /**
   *
   * @type {Array<Employee>}
   * @memberof StatMotivationEmployeesResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatMotivationEmployeesResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<EmployeeMotivationReport>}
   * @memberof StatMotivationEmployeesResponse
   */
  stats?: Array<EmployeeMotivationReport>;
}
/**
 *
 * @export
 * @interface StatMotivationHeadCompaniesResponse
 */
export interface StatMotivationHeadCompaniesResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMotivationHeadCompaniesResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   * Может ли пользователь просматривать виджет процентного исполнения удельного бюджета
   * @type {boolean}
   * @memberof StatMotivationHeadCompaniesResponse
   */
  canViewBudgetPerTon?: boolean;
  /**
   *
   * @type {Array<HeadCompany>}
   * @memberof StatMotivationHeadCompaniesResponse
   */
  headCompanies?: Array<HeadCompany>;
  /**
   *
   * @type {Array<HeadCompanyMotivationReport>}
   * @memberof StatMotivationHeadCompaniesResponse
   */
  stats?: Array<HeadCompanyMotivationReport>;
}
/**
 *
 * @export
 * @interface StatMotivationOfficesResponse
 */
export interface StatMotivationOfficesResponse {
  /**
   *
   * @type {Array<BudgetGroup>}
   * @memberof StatMotivationOfficesResponse
   */
  budgetGroups?: Array<BudgetGroup>;
  /**
   * Может ли пользователь просматривать виджет процентного исполнения удельного бюджета
   * @type {boolean}
   * @memberof StatMotivationOfficesResponse
   */
  canViewBudgetPerTon?: boolean;
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatMotivationOfficesResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<BranchOfficeMotivationReport>}
   * @memberof StatMotivationOfficesResponse
   */
  stats?: Array<BranchOfficeMotivationReport>;
}
/**
 *
 * @export
 * @interface StatPaymentsDepartmentReport
 */
export interface StatPaymentsDepartmentReport {
  /**
   *
   * @type {string}
   * @memberof StatPaymentsDepartmentReport
   */
  departmentCode?: string;
  /**
   *
   * @type {StatPaymentsReport}
   * @memberof StatPaymentsDepartmentReport
   */
  report?: StatPaymentsReport;
}
/**
 *
 * @export
 * @interface StatPaymentsDepartmentsPlotResponse
 */
export interface StatPaymentsDepartmentsPlotResponse {
  /**
   *
   * @type {Plot}
   * @memberof StatPaymentsDepartmentsPlotResponse
   */
  statPlot?: Plot;
}
/**
 *
 * @export
 * @interface StatPaymentsDepartmentsResponse
 */
export interface StatPaymentsDepartmentsResponse {
  /**
   *
   * @type {Array<EmployeeDepartment>}
   * @memberof StatPaymentsDepartmentsResponse
   */
  departments?: Array<EmployeeDepartment>;
  /**
   *
   * @type {Array<StatPaymentsDepartmentReport>}
   * @memberof StatPaymentsDepartmentsResponse
   */
  stats?: Array<StatPaymentsDepartmentReport>;
}
/**
 *
 * @export
 * @interface StatPaymentsDivisionReport
 */
export interface StatPaymentsDivisionReport {
  /**
   *
   * @type {string}
   * @memberof StatPaymentsDivisionReport
   */
  divisionCode?: string;
  /**
   *
   * @type {StatPaymentsReport}
   * @memberof StatPaymentsDivisionReport
   */
  report?: StatPaymentsReport;
}
/**
 *
 * @export
 * @interface StatPaymentsDivisionsPlotResponse
 */
export interface StatPaymentsDivisionsPlotResponse {
  /**
   *
   * @type {Plot}
   * @memberof StatPaymentsDivisionsPlotResponse
   */
  statPlot?: Plot;
}
/**
 *
 * @export
 * @interface StatPaymentsDivisionsResponse
 */
export interface StatPaymentsDivisionsResponse {
  /**
   *
   * @type {Array<BranchOfficeDivision>}
   * @memberof StatPaymentsDivisionsResponse
   */
  divisions?: Array<BranchOfficeDivision>;
  /**
   *
   * @type {Array<StatPaymentsDivisionReport>}
   * @memberof StatPaymentsDivisionsResponse
   */
  stats?: Array<StatPaymentsDivisionReport>;
}
/**
 *
 * @export
 * @interface StatPaymentsEmployeeReport
 */
export interface StatPaymentsEmployeeReport {
  /**
   *
   * @type {string}
   * @memberof StatPaymentsEmployeeReport
   */
  employeeCode?: string;
  /**
   *
   * @type {StatPaymentsReport}
   * @memberof StatPaymentsEmployeeReport
   */
  report?: StatPaymentsReport;
}
/**
 *
 * @export
 * @interface StatPaymentsEmployeesPlotResponse
 */
export interface StatPaymentsEmployeesPlotResponse {
  /**
   *
   * @type {Plot}
   * @memberof StatPaymentsEmployeesPlotResponse
   */
  statPlot?: Plot;
}
/**
 *
 * @export
 * @interface StatPaymentsEmployeesResponse
 */
export interface StatPaymentsEmployeesResponse {
  /**
   *
   * @type {Array<Employee>}
   * @memberof StatPaymentsEmployeesResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<StatPaymentsEmployeeReport>}
   * @memberof StatPaymentsEmployeesResponse
   */
  stats?: Array<StatPaymentsEmployeeReport>;
}
/**
 *
 * @export
 * @interface StatPaymentsExpectationsResponse
 */
export interface StatPaymentsExpectationsResponse {
  /**
   *
   * @type {Array<Customer>}
   * @memberof StatPaymentsExpectationsResponse
   */
  customers?: Array<Customer>;
  /**
   *
   * @type {number}
   * @memberof StatPaymentsExpectationsResponse
   */
  customersTotalCount?: number;
  /**
   *
   * @type {Array<Employee>}
   * @memberof StatPaymentsExpectationsResponse
   */
  employees?: Array<Employee>;
  /**
   *
   * @type {Array<CustomerNotDistributedStatItem>}
   * @memberof StatPaymentsExpectationsResponse
   */
  notDistributed?: Array<CustomerNotDistributedStatItem>;
  /**
   *
   * @type {Array<PaymentExpectation>}
   * @memberof StatPaymentsExpectationsResponse
   */
  stats?: Array<PaymentExpectation>;
}
/**
 *
 * @export
 * @interface StatPaymentsHeadCompaniesPlotResponse
 */
export interface StatPaymentsHeadCompaniesPlotResponse {
  /**
   *
   * @type {Plot}
   * @memberof StatPaymentsHeadCompaniesPlotResponse
   */
  statPlot?: Plot;
}
/**
 *
 * @export
 * @interface StatPaymentsHeadCompaniesResponse
 */
export interface StatPaymentsHeadCompaniesResponse {
  /**
   *
   * @type {Array<HeadCompany>}
   * @memberof StatPaymentsHeadCompaniesResponse
   */
  headCompanies?: Array<HeadCompany>;
  /**
   *
   * @type {Array<StatPaymentsHeadCompanyReport>}
   * @memberof StatPaymentsHeadCompaniesResponse
   */
  stats?: Array<StatPaymentsHeadCompanyReport>;
}
/**
 *
 * @export
 * @interface StatPaymentsHeadCompanyReport
 */
export interface StatPaymentsHeadCompanyReport {
  /**
   *
   * @type {string}
   * @memberof StatPaymentsHeadCompanyReport
   */
  headCompanyCode?: string;
  /**
   *
   * @type {StatPaymentsReport}
   * @memberof StatPaymentsHeadCompanyReport
   */
  report?: StatPaymentsReport;
}
/**
 *
 * @export
 * @interface StatPaymentsOfficeReport
 */
export interface StatPaymentsOfficeReport {
  /**
   *
   * @type {string}
   * @memberof StatPaymentsOfficeReport
   */
  officeCode?: string;
  /**
   *
   * @type {StatPaymentsReport}
   * @memberof StatPaymentsOfficeReport
   */
  report?: StatPaymentsReport;
}
/**
 *
 * @export
 * @interface StatPaymentsOfficesPlotResponse
 */
export interface StatPaymentsOfficesPlotResponse {
  /**
   *
   * @type {Plot}
   * @memberof StatPaymentsOfficesPlotResponse
   */
  statPlot?: Plot;
}
/**
 *
 * @export
 * @interface StatPaymentsOfficesResponse
 */
export interface StatPaymentsOfficesResponse {
  /**
   *
   * @type {Array<BranchOffice>}
   * @memberof StatPaymentsOfficesResponse
   */
  offices?: Array<BranchOffice>;
  /**
   *
   * @type {Array<StatPaymentsOfficeReport>}
   * @memberof StatPaymentsOfficesResponse
   */
  stats?: Array<StatPaymentsOfficeReport>;
}
/**
 *
 * @export
 * @interface StatPaymentsPlannedRequest
 */
export interface StatPaymentsPlannedRequest {
  /**
   * Только Ожидание
   * @type {boolean}
   * @memberof StatPaymentsPlannedRequest
   */
  awaitingOnly?: boolean;
  /**
   * Код филиала
   * @type {Array<string>}
   * @memberof StatPaymentsPlannedRequest
   */
  branchOfficeCodes?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof StatPaymentsPlannedRequest
   */
  count?: number | null;
  /**
   * Код клиента
   * @type {string}
   * @memberof StatPaymentsPlannedRequest
   */
  customerCode?: string;
  /**
   * Код отдела
   * @type {Array<string>}
   * @memberof StatPaymentsPlannedRequest
   */
  departmentCodes?: Array<string>;
  /**
   * Код филиала
   * @type {Array<string>}
   * @memberof StatPaymentsPlannedRequest
   */
  divisionCodes?: Array<string>;
  /**
   * Код сотрудника
   * @type {Array<string>}
   * @memberof StatPaymentsPlannedRequest
   */
  employeeCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof StatPaymentsPlannedRequest
   */
  from?: string | null;
  /**
   * Код головной компании
   * @type {string}
   * @memberof StatPaymentsPlannedRequest
   */
  headCompanyCode?: string;
  /**
   * Только Просрочено
   * @type {boolean}
   * @memberof StatPaymentsPlannedRequest
   */
  overdueOnly?: boolean;
  /**
   *
   * @type {number}
   * @memberof StatPaymentsPlannedRequest
   */
  page?: number | null;
  /**
   *
   * @type {string}
   * @memberof StatPaymentsPlannedRequest
   */
  to?: string | null;
}
/**
 *
 * @export
 * @interface StatPaymentsPlannedResponse
 */
export interface StatPaymentsPlannedResponse {
  /**
   *
   * @type {Array<Customer>}
   * @memberof StatPaymentsPlannedResponse
   */
  customers?: Array<Customer>;
  /**
   *
   * @type {Array<PaymentExpectation>}
   * @memberof StatPaymentsPlannedResponse
   */
  plans?: Array<PaymentExpectation>;
  /**
   *
   * @type {number}
   * @memberof StatPaymentsPlannedResponse
   */
  plansTotalCount?: number;
}
/**
 *
 * @export
 * @interface StatPaymentsReceivableReportRequest
 */
export interface StatPaymentsReceivableReportRequest {
  /**
   * Код филиала
   * @type {Array<string>}
   * @memberof StatPaymentsReceivableReportRequest
   */
  branchOfficeCodes?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof StatPaymentsReceivableReportRequest
   */
  count?: number | null;
  /**
   * Код клиента
   * @type {string}
   * @memberof StatPaymentsReceivableReportRequest
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof StatPaymentsReceivableReportRequest
   */
  dateFrom: string;
  /**
   *
   * @type {string}
   * @memberof StatPaymentsReceivableReportRequest
   */
  dateTo: string;
  /**
   * Код отдела
   * @type {Array<string>}
   * @memberof StatPaymentsReceivableReportRequest
   */
  departmentCodes?: Array<string>;
  /**
   * Код дивизиона
   * @type {Array<string>}
   * @memberof StatPaymentsReceivableReportRequest
   */
  divisionCodes?: Array<string>;
  /**
   * Код сотрудника
   * @type {Array<string>}
   * @memberof StatPaymentsReceivableReportRequest
   */
  employeeCodes?: Array<string>;
  /**
   * Код головной компании
   * @type {string}
   * @memberof StatPaymentsReceivableReportRequest
   */
  headCompanyCode?: string;
  /**
   * Нижняя граница диапазона количества дней просрочки - ОТ
   * @type {number}
   * @memberof StatPaymentsReceivableReportRequest
   */
  overdueDayFrom?: number | null;
  /**
   * Верхняя граница диапазона количества дней просрочки - ДО
   * @type {number}
   * @memberof StatPaymentsReceivableReportRequest
   */
  overdueDayTo?: number | null;
  /**
   *
   * @type {number}
   * @memberof StatPaymentsReceivableReportRequest
   */
  page?: number | null;
}
/**
 *
 * @export
 * @interface StatPaymentsReceivableReportResponse
 */
export interface StatPaymentsReceivableReportResponse {
  /**
   *
   * @type {Array<ClientContract>}
   * @memberof StatPaymentsReceivableReportResponse
   */
  contracts?: Array<ClientContract>;
  /**
   *
   * @type {Array<CreditState>}
   * @memberof StatPaymentsReceivableReportResponse
   */
  creditStates?: Array<CreditState>;
  /**
   *
   * @type {Array<Customer>}
   * @memberof StatPaymentsReceivableReportResponse
   */
  customers?: Array<Customer>;
  /**
   *
   * @type {number}
   * @memberof StatPaymentsReceivableReportResponse
   */
  customersTotalCount?: number;
  /**
   *
   * @type {Array<CustomerNotDistributedStatItem>}
   * @memberof StatPaymentsReceivableReportResponse
   */
  notDistributed?: Array<CustomerNotDistributedStatItem>;
  /**
   *
   * @type {Array<PaymentExpectation>}
   * @memberof StatPaymentsReceivableReportResponse
   */
  plans?: Array<PaymentExpectation>;
}
/**
 *
 * @export
 * @interface StatPaymentsReport
 */
export interface StatPaymentsReport {
  /**
   * Ожидание
   * @type {number}
   * @memberof StatPaymentsReport
   */
  awaitingAmount?: number;
  /**
   * Распределено
   * @type {number}
   * @memberof StatPaymentsReport
   */
  distributedAmount?: number;
  /**
   * Мой доход
   * @type {number}
   * @memberof StatPaymentsReport
   */
  earningsAmount?: number;
  /**
   * Доп.перспектива
   * @type {number}
   * @memberof StatPaymentsReport
   */
  extraPerspective?: number;
  /**
   * Штраф
   * @type {number}
   * @memberof StatPaymentsReport
   */
  fineAmount?: number;
  /**
   * Просрочено
   * @type {number}
   * @memberof StatPaymentsReport
   */
  overdueAmount?: number;
  /**
   * Оплачено
   * @type {number}
   * @memberof StatPaymentsReport
   */
  paidAmount?: number;
}
/**
 *
 * @export
 * @interface StatSaleFunnel
 */
export interface StatSaleFunnel {
  /**
   * Счёт
   * @type {number}
   * @memberof StatSaleFunnel
   */
  bill?: number;
  /**
   * Резерв
   * @type {number}
   * @memberof StatSaleFunnel
   */
  billReserved?: number;
  /**
   * Заявка
   * @type {number}
   * @memberof StatSaleFunnel
   */
  deal?: number;
  /**
   * Заявка одобрена
   * @type {number}
   * @memberof StatSaleFunnel
   */
  dealSubmitted?: number;
  /**
   * Отгрузка
   * @type {number}
   * @memberof StatSaleFunnel
   */
  shipment?: number;
}
/**
 *
 * @export
 * @interface StatSaleFunnelDocsRequest
 */
export interface StatSaleFunnelDocsRequest {
  /**
   * Код филиала
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequest
   */
  branchOfficeCodes?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof StatSaleFunnelDocsRequest
   */
  count?: number;
  /**
   * Код клиента
   * @type {string}
   * @memberof StatSaleFunnelDocsRequest
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof StatSaleFunnelDocsRequest
   */
  dateFrom: string;
  /**
   *
   * @type {string}
   * @memberof StatSaleFunnelDocsRequest
   */
  dateTo: string;
  /**
   * Код отдела
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequest
   */
  departmentCodes?: Array<string>;
  /**
   * Код дивизиона
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequest
   */
  divisionCodes?: Array<string>;
  /**
   *
   * @type {StatSaleFunnelDocsRequestDocFilters}
   * @memberof StatSaleFunnelDocsRequest
   */
  docFilters?: StatSaleFunnelDocsRequestDocFilters;
  /**
   *
   * @type {string}
   * @memberof StatSaleFunnelDocsRequest
   */
  docsType: StatSaleFunnelDocsRequestDocsTypeEnum;
  /**
   * Код сотрудника
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequest
   */
  employeeCodes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof StatSaleFunnelDocsRequest
   */
  funnelType: StatSaleFunnelDocsRequestFunnelTypeEnum;
  /**
   * Код головной компании
   * @type {string}
   * @memberof StatSaleFunnelDocsRequest
   */
  headCompanyCode?: string;
  /**
   *
   * @type {number}
   * @memberof StatSaleFunnelDocsRequest
   */
  page?: number;
}

export const StatSaleFunnelDocsRequestDocsTypeEnum = {
  Deal: 'deal',
  DealApprovalAsk: 'dealApprovalAsk',
  DealApprovalAskDelta: 'dealApprovalAskDelta',
  Bill: 'bill',
  BillDelta: 'billDelta',
  BillReserved: 'billReserved',
  BillReservedDelta: 'billReservedDelta',
  Shipment: 'shipment',
  ShipmentDelta: 'shipmentDelta',
} as const;

export type StatSaleFunnelDocsRequestDocsTypeEnum =
  (typeof StatSaleFunnelDocsRequestDocsTypeEnum)[keyof typeof StatSaleFunnelDocsRequestDocsTypeEnum];
export const StatSaleFunnelDocsRequestFunnelTypeEnum = {
  Periodic: 'periodic',
  Conversion: 'conversion',
} as const;

export type StatSaleFunnelDocsRequestFunnelTypeEnum =
  (typeof StatSaleFunnelDocsRequestFunnelTypeEnum)[keyof typeof StatSaleFunnelDocsRequestFunnelTypeEnum];

/**
 *
 * @export
 * @interface StatSaleFunnelDocsRequestDocFilters
 */
export interface StatSaleFunnelDocsRequestDocFilters {
  /**
   *
   * @type {StatSaleFunnelDocsRequestDocFiltersBill}
   * @memberof StatSaleFunnelDocsRequestDocFilters
   */
  bill?: StatSaleFunnelDocsRequestDocFiltersBill;
  /**
   *
   * @type {StatSaleFunnelDocsRequestDocFiltersDeal}
   * @memberof StatSaleFunnelDocsRequestDocFilters
   */
  deal?: StatSaleFunnelDocsRequestDocFiltersDeal;
  /**
   *
   * @type {StatSaleFunnelDocsRequestDocFiltersShipment}
   * @memberof StatSaleFunnelDocsRequestDocFilters
   */
  shipment?: StatSaleFunnelDocsRequestDocFiltersShipment;
}
/**
 *
 * @export
 * @interface StatSaleFunnelDocsRequestDocFiltersBill
 */
export interface StatSaleFunnelDocsRequestDocFiltersBill {
  /**
   * Коды клиентов
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersBill
   */
  customerCodes?: Array<string>;
  /**
   * В наличии документ Отгрузка
   * @type {boolean}
   * @memberof StatSaleFunnelDocsRequestDocFiltersBill
   */
  hasShipments?: boolean;
  /**
   * Коды статусов оплаты
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersBill
   */
  paymentStateCodes?: Array<string>;
  /**
   * filter for bill
   * @type {string}
   * @memberof StatSaleFunnelDocsRequestDocFiltersBill
   */
  query?: string | null;
  /**
   * Коды статуса отгрузки
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersBill
   */
  shipmentStateCodes?: Array<string>;
  /**
   * Коды источника счета
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersBill
   */
  sourceCodes?: Array<string>;
  /**
   * Коды статусов
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersBill
   */
  stateCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface StatSaleFunnelDocsRequestDocFiltersDeal
 */
export interface StatSaleFunnelDocsRequestDocFiltersDeal {
  /**
   * Коды статусов согласования документов
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersDeal
   */
  approvalStateCodes?: Array<string>;
  /**
   * Коды клиентов
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersDeal
   */
  customerCodes?: Array<string>;
  /**
   * filter for deal
   * @type {string}
   * @memberof StatSaleFunnelDocsRequestDocFiltersDeal
   */
  query?: string | null;
  /**
   * Коды статусов
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersDeal
   */
  stateCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface StatSaleFunnelDocsRequestDocFiltersShipment
 */
export interface StatSaleFunnelDocsRequestDocFiltersShipment {
  /**
   * Коды клиентов
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersShipment
   */
  customerCodes?: Array<string>;
  /**
   * Имеется сотрудник по замещению
   * @type {boolean}
   * @memberof StatSaleFunnelDocsRequestDocFiltersShipment
   */
  hasEmployeeDeputy?: boolean;
  /**
   * Коды статусов оплаты
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersShipment
   */
  paymentStateCodes?: Array<string>;
  /**
   * filter for shipments
   * @type {string}
   * @memberof StatSaleFunnelDocsRequestDocFiltersShipment
   */
  query?: string | null;
  /**
   * Код типа отгрузки
   * @type {string}
   * @memberof StatSaleFunnelDocsRequestDocFiltersShipment
   */
  shipmentTypeCode?: string;
  /**
   * Коды складов
   * @type {Array<string>}
   * @memberof StatSaleFunnelDocsRequestDocFiltersShipment
   */
  warehousesCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface StatSaleFunnelDocsResponse
 */
export interface StatSaleFunnelDocsResponse {
  /**
   *
   * @type {Array<Bill>}
   * @memberof StatSaleFunnelDocsResponse
   */
  bills?: Array<Bill>;
  /**
   *
   * @type {Array<Deal>}
   * @memberof StatSaleFunnelDocsResponse
   */
  deals?: Array<Deal>;
  /**
   *
   * @type {Array<SaleShipment>}
   * @memberof StatSaleFunnelDocsResponse
   */
  saleShipments?: Array<SaleShipment>;
  /**
   *
   * @type {number}
   * @memberof StatSaleFunnelDocsResponse
   */
  totalCost?: number;
  /**
   *
   * @type {number}
   * @memberof StatSaleFunnelDocsResponse
   */
  totalCount?: number;
  /**
   *
   * @type {number}
   * @memberof StatSaleFunnelDocsResponse
   */
  totalWeight?: number;
}
/**
 *
 * @export
 * @interface StatSaleFunnelGraph
 */
export interface StatSaleFunnelGraph {
  /**
   *
   * @type {StatSaleFunnelGraphCosts}
   * @memberof StatSaleFunnelGraph
   */
  costs?: StatSaleFunnelGraphCosts;
  /**
   *
   * @type {StatSaleFunnelGraphCosts}
   * @memberof StatSaleFunnelGraph
   */
  counts: StatSaleFunnelGraphCosts;
  /**
   *
   * @type {StatSaleFunnelGraphCosts}
   * @memberof StatSaleFunnelGraph
   */
  weights?: StatSaleFunnelGraphCosts;
}
/**
 *
 * @export
 * @interface StatSaleFunnelGraphCosts
 */
export interface StatSaleFunnelGraphCosts {
  /**
   * Счёт
   * @type {number}
   * @memberof StatSaleFunnelGraphCosts
   */
  bill?: number;
  /**
   * Резерв
   * @type {number}
   * @memberof StatSaleFunnelGraphCosts
   */
  billReserved?: number;
  /**
   * Заявка
   * @type {number}
   * @memberof StatSaleFunnelGraphCosts
   */
  deal?: number;
  /**
   * Заявка одобрена
   * @type {number}
   * @memberof StatSaleFunnelGraphCosts
   */
  dealSubmitted?: number;
  /**
   * Отгрузка
   * @type {number}
   * @memberof StatSaleFunnelGraphCosts
   */
  shipment?: number;
}
/**
 *
 * @export
 * @interface StatSaleFunnelGraphsRequest
 */
export interface StatSaleFunnelGraphsRequest {
  /**
   * Код филиала
   * @type {Array<string>}
   * @memberof StatSaleFunnelGraphsRequest
   */
  branchOfficeCodes?: Array<string>;
  /**
   * Код клиента
   * @type {string}
   * @memberof StatSaleFunnelGraphsRequest
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof StatSaleFunnelGraphsRequest
   */
  dateFrom: string;
  /**
   *
   * @type {string}
   * @memberof StatSaleFunnelGraphsRequest
   */
  dateTo: string;
  /**
   * Код отдела
   * @type {Array<string>}
   * @memberof StatSaleFunnelGraphsRequest
   */
  departmentCodes?: Array<string>;
  /**
   * Код дивизиона
   * @type {Array<string>}
   * @memberof StatSaleFunnelGraphsRequest
   */
  divisionCodes?: Array<string>;
  /**
   * Код сотрудника
   * @type {Array<string>}
   * @memberof StatSaleFunnelGraphsRequest
   */
  employeeCodes?: Array<string>;
  /**
   * Код головной компании
   * @type {string}
   * @memberof StatSaleFunnelGraphsRequest
   */
  headCompanyCode?: string;
  /**
   *
   * @type {string}
   * @memberof StatSaleFunnelGraphsRequest
   */
  viewMode: StatSaleFunnelGraphsRequestViewModeEnum;
}

export const StatSaleFunnelGraphsRequestViewModeEnum = {
  Cost: 'cost',
  Weight: 'weight',
} as const;

export type StatSaleFunnelGraphsRequestViewModeEnum =
  (typeof StatSaleFunnelGraphsRequestViewModeEnum)[keyof typeof StatSaleFunnelGraphsRequestViewModeEnum];

/**
 *
 * @export
 * @interface StatSaleFunnelGraphsResponse
 */
export interface StatSaleFunnelGraphsResponse {
  /**
   *
   * @type {StatSaleFunnelGraph}
   * @memberof StatSaleFunnelGraphsResponse
   */
  conversion?: StatSaleFunnelGraph;
  /**
   *
   * @type {StatSaleFunnelGraph}
   * @memberof StatSaleFunnelGraphsResponse
   */
  periodic?: StatSaleFunnelGraph;
}
/**
 *
 * @export
 * @interface StatSaleFunnelReportRequest
 */
export interface StatSaleFunnelReportRequest {
  /**
   * Код филиала
   * @type {Array<string>}
   * @memberof StatSaleFunnelReportRequest
   */
  branchOfficeCodes?: Array<string>;
  /**
   * Код клиента
   * @type {string}
   * @memberof StatSaleFunnelReportRequest
   */
  customerCode?: string;
  /**
   *
   * @type {string}
   * @memberof StatSaleFunnelReportRequest
   */
  dateFrom: string;
  /**
   *
   * @type {string}
   * @memberof StatSaleFunnelReportRequest
   */
  dateTo: string;
  /**
   * Код отдела
   * @type {Array<string>}
   * @memberof StatSaleFunnelReportRequest
   */
  departmentCodes?: Array<string>;
  /**
   * Код дивизиона
   * @type {Array<string>}
   * @memberof StatSaleFunnelReportRequest
   */
  divisionCodes?: Array<string>;
  /**
   * Код сотрудника
   * @type {Array<string>}
   * @memberof StatSaleFunnelReportRequest
   */
  employeeCodes?: Array<string>;
  /**
   * Код головной компании
   * @type {string}
   * @memberof StatSaleFunnelReportRequest
   */
  headCompanyCode?: string;
  /**
   *
   * @type {string}
   * @memberof StatSaleFunnelReportRequest
   */
  viewMode: StatSaleFunnelReportRequestViewModeEnum;
}

export const StatSaleFunnelReportRequestViewModeEnum = {
  Count: 'count',
  Cost: 'cost',
  Weight: 'weight',
  Cumulative: 'cumulative',
} as const;

export type StatSaleFunnelReportRequestViewModeEnum =
  (typeof StatSaleFunnelReportRequestViewModeEnum)[keyof typeof StatSaleFunnelReportRequestViewModeEnum];

/**
 *
 * @export
 * @interface StatSaleFunnelReportResponse
 */
export interface StatSaleFunnelReportResponse {
  /**
   * 1 счет, шт.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  billCount?: number;
  /**
   * 1 зарезервированный счет, шт.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  billReservedCount?: number;
  /**
   * Общая сумма по зарезервированному счету, руб.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  billReservedTotalCost?: number;
  /**
   * Общий объем по зарезервированному счету, тн.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  billReservedTotalWeightTne?: number;
  /**
   * Общая сумма по счету, руб.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  billTotalCost?: number;
  /**
   * Общий объем по счету, тн.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  billTotalWeightTne?: number;
  /**
   *
   * @type {StatSaleFunnel}
   * @memberof StatSaleFunnelReportResponse
   */
  costs?: StatSaleFunnel;
  /**
   *
   * @type {StatSaleFunnel}
   * @memberof StatSaleFunnelReportResponse
   */
  counts?: StatSaleFunnel;
  /**
   * Единица заявки, шт.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  dealCount?: number;
  /**
   * 1 заявка, шт. в статусе Одобрено
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  dealSubmittedCount?: number;
  /**
   * Общая сумма по заявке, руб. в статусе Одобрено
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  dealSubmittedTotalCost?: number;
  /**
   * Общий объем по 1 заявке в статусе Одобрено
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  dealSubmittedTotalTne?: number;
  /**
   * Общая сумма по заявке, руб.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  dealTotalCost?: number;
  /**
   * Общий объем по заявке, тн
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  dealTotalWeightTne?: number;
  /**
   * 1 документ отгрузки, шт.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  shipmentCount?: number;
  /**
   * Общая сумма по отгрузке, руб.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  shipmentTotalCost?: number;
  /**
   * Общий объем по документу отгрузки, тн.
   * @type {number}
   * @memberof StatSaleFunnelReportResponse
   */
  shipmentTotalWeightTne?: number;
  /**
   *
   * @type {StatSaleFunnel}
   * @memberof StatSaleFunnelReportResponse
   */
  weights?: StatSaleFunnel;
}
/**
 * Сотрудник, запрашивающий согласования
 * @export
 * @interface Subordinate
 */
export interface Subordinate {
  /**
   * Количество активных запросов на согласование
   * @type {number}
   * @memberof Subordinate
   */
  approvalAskCount?: number;
  /**
   *
   * @type {string}
   * @memberof Subordinate
   */
  employeeCode?: string;
  /**
   *
   * @type {string}
   * @memberof Subordinate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Subordinate
   */
  patronymic?: string;
  /**
   *
   * @type {string}
   * @memberof Subordinate
   */
  surname?: string;
}
/**
 *
 * @export
 * @interface TaskChangePositionRequest
 */
export interface TaskChangePositionRequest {
  /**
   * Код следующей задачи
   * @type {string}
   * @memberof TaskChangePositionRequest
   */
  nextCode?: string;
  /**
   * Код предыдущей задачи
   * @type {string}
   * @memberof TaskChangePositionRequest
   */
  prevCode?: string;
  /**
   * Код статуса задачи
   * @type {string}
   * @memberof TaskChangePositionRequest
   */
  statusCode?: string;
}
/**
 *
 * @export
 * @interface TaskChangePositionResponse
 */
export interface TaskChangePositionResponse {
  /**
   *
   * @type {TaskItem}
   * @memberof TaskChangePositionResponse
   */
  task?: TaskItem;
}
/**
 *
 * @export
 * @interface TaskCommentCreateRequest
 */
export interface TaskCommentCreateRequest {
  /**
   * Комментарий
   * @type {string}
   * @memberof TaskCommentCreateRequest
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface TaskCommentCreateResponse
 */
export interface TaskCommentCreateResponse {
  /**
   *
   * @type {TaskCommentItem}
   * @memberof TaskCommentCreateResponse
   */
  comment?: TaskCommentItem;
}
/**
 *
 * @export
 * @interface TaskCommentItem
 */
export interface TaskCommentItem {
  /**
   * Код комментария
   * @type {string}
   * @memberof TaskCommentItem
   */
  code?: string;
  /**
   * Комментарий
   * @type {string}
   * @memberof TaskCommentItem
   */
  comment?: string;
  /**
   * Дата создания
   * @type {string}
   * @memberof TaskCommentItem
   */
  createdAt?: string | null;
  /**
   *
   * @type {Employee}
   * @memberof TaskCommentItem
   */
  initiator?: Employee;
  /**
   * Дата изменения
   * @type {string}
   * @memberof TaskCommentItem
   */
  updatedAt?: string | null;
}
/**
 *
 * @export
 * @interface TaskCommentUpdateRequest
 */
export interface TaskCommentUpdateRequest {
  /**
   * Комментарий
   * @type {string}
   * @memberof TaskCommentUpdateRequest
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface TaskCommentUpdateResponse
 */
export interface TaskCommentUpdateResponse {
  /**
   *
   * @type {TaskCommentItem}
   * @memberof TaskCommentUpdateResponse
   */
  comment?: TaskCommentItem;
}
/**
 *
 * @export
 * @interface TaskCreateEntityItem
 */
export interface TaskCreateEntityItem {
  /**
   * Код сущности
   * @type {string}
   * @memberof TaskCreateEntityItem
   */
  entityCode?: string;
  /**
   * Код типа сущности
   * @type {string}
   * @memberof TaskCreateEntityItem
   */
  entityTypeCode?: string;
}
/**
 *
 * @export
 * @interface TaskCreateRequest
 */
export interface TaskCreateRequest {
  /**
   * Дата начала
   * @type {string}
   * @memberof TaskCreateRequest
   */
  beginDate?: string | null;
  /**
   * Срок выполнения
   * @type {string}
   * @memberof TaskCreateRequest
   */
  deadlineDate?: string | null;
  /**
   * Описание
   * @type {string}
   * @memberof TaskCreateRequest
   */
  description?: string;
  /**
   *
   * @type {Array<TaskCreateEntityItem>}
   * @memberof TaskCreateRequest
   */
  entities?: Array<TaskCreateEntityItem>;
  /**
   * Ответственный - исполнитель по задаче
   * @type {string}
   * @memberof TaskCreateRequest
   */
  executorEmployeeCode?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TaskCreateRequest
   */
  labelCodes?: Array<string>;
  /**
   * Стоимость задачи в баллах
   * @type {number}
   * @memberof TaskCreateRequest
   */
  points?: number;
  /**
   * Код приоритета задачи
   * @type {string}
   * @memberof TaskCreateRequest
   */
  priorityCode?: string;
  /**
   * Код статуса задачи
   * @type {string}
   * @memberof TaskCreateRequest
   */
  statusCode?: string;
  /**
   * Наименование задачи
   * @type {string}
   * @memberof TaskCreateRequest
   */
  title?: string;
}
/**
 *
 * @export
 * @interface TaskCreateResponse
 */
export interface TaskCreateResponse {
  /**
   *
   * @type {TaskItem}
   * @memberof TaskCreateResponse
   */
  task?: TaskItem;
}
/**
 *
 * @export
 * @interface TaskEntityItem
 */
export interface TaskEntityItem {
  /**
   * Код
   * @type {string}
   * @memberof TaskEntityItem
   */
  code?: string;
  /**
   * Код сущности
   * @type {string}
   * @memberof TaskEntityItem
   */
  entityCode?: string;
  /**
   * Заголовок сущности
   * @type {string}
   * @memberof TaskEntityItem
   */
  entityTitle?: string;
  /**
   * Код типа сущности
   * @type {string}
   * @memberof TaskEntityItem
   */
  entityTypeCode?: string;
  /**
   * Заголовок типа сущности
   * @type {string}
   * @memberof TaskEntityItem
   */
  entityTypeTitle?: string;
}
/**
 *
 * @export
 * @interface TaskEntityTypeItem
 */
export interface TaskEntityTypeItem {
  /**
   * Код
   * @type {string}
   * @memberof TaskEntityTypeItem
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof TaskEntityTypeItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface TaskEntityTypesResponse
 */
export interface TaskEntityTypesResponse {
  /**
   *
   * @type {Array<TaskEntityTypeItem>}
   * @memberof TaskEntityTypesResponse
   */
  entityTypes?: Array<TaskEntityTypeItem>;
}
/**
 *
 * @export
 * @interface TaskFilterItem
 */
export interface TaskFilterItem {
  /**
   * Код фильтра
   * @type {string}
   * @memberof TaskFilterItem
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof TaskFilterItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface TaskFiltersResponse
 */
export interface TaskFiltersResponse {
  /**
   *
   * @type {Array<TaskFilterItem>}
   * @memberof TaskFiltersResponse
   */
  filters?: Array<TaskFilterItem>;
}
/**
 *
 * @export
 * @interface TaskFollowersSaveRequest
 */
export interface TaskFollowersSaveRequest {
  /**
   * Коды сотрудников для добавления наблюдателей
   * @type {Array<string>}
   * @memberof TaskFollowersSaveRequest
   */
  addEmployeeMemberCodes?: Array<string>;
  /**
   * Коды сотрудников для удаления наблюдателей
   * @type {Array<string>}
   * @memberof TaskFollowersSaveRequest
   */
  removeEmployeeMemberCodes?: Array<string>;
}
/**
 *
 * @export
 * @interface TaskFollowersSaveResponse
 */
export interface TaskFollowersSaveResponse {
  /**
   *
   * @type {TaskItem}
   * @memberof TaskFollowersSaveResponse
   */
  task?: TaskItem;
}
/**
 *
 * @export
 * @interface TaskItem
 */
export interface TaskItem {
  /**
   * Дата начала
   * @type {string}
   * @memberof TaskItem
   */
  beginAt?: string | null;
  /**
   * Код филиала
   * @type {string}
   * @memberof TaskItem
   */
  branchOfficeCode?: string;
  /**
   * Филиал
   * @type {string}
   * @memberof TaskItem
   */
  branchOfficeName?: string;
  /**
   *
   * @type {TaskItemCanEdit}
   * @memberof TaskItem
   */
  canEdit?: TaskItemCanEdit;
  /**
   * Код задачи
   * @type {string}
   * @memberof TaskItem
   */
  code?: string;
  /**
   * Комментарии
   * @type {Array<TaskCommentItem>}
   * @memberof TaskItem
   */
  comments?: Array<TaskCommentItem>;
  /**
   * Дата создания
   * @type {string}
   * @memberof TaskItem
   */
  createdAt?: string | null;
  /**
   * Срок выполнения
   * @type {string}
   * @memberof TaskItem
   */
  deadlineAt?: string | null;
  /**
   * Описание
   * @type {string}
   * @memberof TaskItem
   */
  description?: string;
  /**
   *
   * @type {Array<TaskEntityItem>}
   * @memberof TaskItem
   */
  entities?: Array<TaskEntityItem>;
  /**
   *
   * @type {Employee}
   * @memberof TaskItem
   */
  executor?: Employee;
  /**
   * Наблюдатели
   * @type {Array<Employee>}
   * @memberof TaskItem
   */
  followers?: Array<Employee>;
  /**
   *
   * @type {Employee}
   * @memberof TaskItem
   */
  initiator?: Employee;
  /**
   *
   * @type {Array<TaskLabelItem>}
   * @memberof TaskItem
   */
  labels?: Array<TaskLabelItem>;
  /**
   * Логи
   * @type {Array<TaskLogItem>}
   * @memberof TaskItem
   */
  logs?: Array<TaskLogItem>;
  /**
   * Участники
   * @type {Array<Employee>}
   * @memberof TaskItem
   */
  participants?: Array<Employee>;
  /**
   * Стоимость задачи в баллах
   * @type {number}
   * @memberof TaskItem
   */
  points?: number;
  /**
   *
   * @type {TaskPriorityItem}
   * @memberof TaskItem
   */
  priority?: TaskPriorityItem;
  /**
   * Ранк
   * @type {string}
   * @memberof TaskItem
   */
  rank?: string;
  /**
   *
   * @type {TaskStatusItem}
   * @memberof TaskItem
   */
  status?: TaskStatusItem;
  /**
   * Наименование задачи
   * @type {string}
   * @memberof TaskItem
   */
  title?: string;
  /**
   * Дата изменения
   * @type {string}
   * @memberof TaskItem
   */
  updatedAt?: string | null;
}
/**
 *
 * @export
 * @interface TaskItemCanEdit
 */
export interface TaskItemCanEdit {
  /**
   * Разрешено менять все атрибуты задачи
   * @type {boolean}
   * @memberof TaskItemCanEdit
   */
  all?: boolean;
  /**
   * Разрешено менять исполнителя
   * @type {boolean}
   * @memberof TaskItemCanEdit
   */
  executor?: boolean;
  /**
   * Разрешено менять наблюдателей у задачи
   * @type {boolean}
   * @memberof TaskItemCanEdit
   */
  follower?: boolean;
  /**
   * Разрешено менять ранк задачи
   * @type {boolean}
   * @memberof TaskItemCanEdit
   */
  rank?: boolean;
  /**
   * Разрешено менять статус задачи
   * @type {boolean}
   * @memberof TaskItemCanEdit
   */
  status?: boolean;
}
/**
 *
 * @export
 * @interface TaskLabelItem
 */
export interface TaskLabelItem {
  /**
   * Код
   * @type {string}
   * @memberof TaskLabelItem
   */
  code?: string;
  /**
   * Цвет метки
   * @type {string}
   * @memberof TaskLabelItem
   */
  color?: string;
  /**
   * Описание
   * @type {string}
   * @memberof TaskLabelItem
   */
  description?: string;
  /**
   * Цвет текста метки
   * @type {string}
   * @memberof TaskLabelItem
   */
  textColor?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof TaskLabelItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface TaskLabelsResponse
 */
export interface TaskLabelsResponse {
  /**
   *
   * @type {Array<TaskLabelItem>}
   * @memberof TaskLabelsResponse
   */
  labels?: Array<TaskLabelItem>;
}
/**
 *
 * @export
 * @interface TaskListResponse
 */
export interface TaskListResponse {
  /**
   *
   * @type {Array<TaskItem>}
   * @memberof TaskListResponse
   */
  tasks?: Array<TaskItem>;
}
/**
 *
 * @export
 * @interface TaskLogExtra
 */
export interface TaskLogExtra {
  /**
   * Новая дата начала
   * @type {string}
   * @memberof TaskLogExtra
   */
  newBeginAt?: string | null;
  /**
   * Новый срок выполнения
   * @type {string}
   * @memberof TaskLogExtra
   */
  newDeadlineAt?: string | null;
  /**
   * Новое описание
   * @type {string}
   * @memberof TaskLogExtra
   */
  newDescription?: string | null;
  /**
   *
   * @type {Employee}
   * @memberof TaskLogExtra
   */
  newExecutor?: Employee;
  /**
   * Новая стоимость задачи в баллах
   * @type {number}
   * @memberof TaskLogExtra
   */
  newPoints?: number | null;
  /**
   *
   * @type {TaskPriorityItem}
   * @memberof TaskLogExtra
   */
  newPriority?: TaskPriorityItem;
  /**
   *
   * @type {TaskStatusItem}
   * @memberof TaskLogExtra
   */
  newStatus?: TaskStatusItem;
  /**
   * Новое наименование
   * @type {string}
   * @memberof TaskLogExtra
   */
  newTitle?: string | null;
  /**
   * Старая дата начала
   * @type {string}
   * @memberof TaskLogExtra
   */
  oldBeginAt?: string | null;
  /**
   * Старый срок выполнения
   * @type {string}
   * @memberof TaskLogExtra
   */
  oldDeadlineAt?: string | null;
  /**
   * Старое описание
   * @type {string}
   * @memberof TaskLogExtra
   */
  oldDescription?: string | null;
  /**
   *
   * @type {Employee}
   * @memberof TaskLogExtra
   */
  oldExecutor?: Employee;
  /**
   * Старая стоимость задачи в баллах
   * @type {number}
   * @memberof TaskLogExtra
   */
  oldPoints?: number | null;
  /**
   *
   * @type {TaskPriorityItem}
   * @memberof TaskLogExtra
   */
  oldPriority?: TaskPriorityItem;
  /**
   *
   * @type {TaskStatusItem}
   * @memberof TaskLogExtra
   */
  oldStatus?: TaskStatusItem;
  /**
   * Старое наименование
   * @type {string}
   * @memberof TaskLogExtra
   */
  oldTitle?: string | null;
}
/**
 *
 * @export
 * @interface TaskLogItem
 */
export interface TaskLogItem {
  /**
   * Дата создания
   * @type {string}
   * @memberof TaskLogItem
   */
  createdAt?: string | null;
  /**
   * Описание
   * @type {string}
   * @memberof TaskLogItem
   */
  description?: string;
  /**
   *
   * @type {TaskLogExtra}
   * @memberof TaskLogItem
   */
  extra?: TaskLogExtra;
  /**
   *
   * @type {Employee}
   * @memberof TaskLogItem
   */
  initiator?: Employee;
  /**
   * Заголовок
   * @type {string}
   * @memberof TaskLogItem
   */
  title?: string;
  /**
   * Код типа лога
   * @type {string}
   * @memberof TaskLogItem
   */
  typeCode?: string;
}
/**
 *
 * @export
 * @interface TaskPrioritiesResponse
 */
export interface TaskPrioritiesResponse {
  /**
   *
   * @type {Array<TaskPriorityItem>}
   * @memberof TaskPrioritiesResponse
   */
  priorities?: Array<TaskPriorityItem>;
}
/**
 *
 * @export
 * @interface TaskPriorityItem
 */
export interface TaskPriorityItem {
  /**
   * Код
   * @type {string}
   * @memberof TaskPriorityItem
   */
  code?: string;
  /**
   * Описание
   * @type {string}
   * @memberof TaskPriorityItem
   */
  description?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof TaskPriorityItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface TaskStatusItem
 */
export interface TaskStatusItem {
  /**
   * Код
   * @type {string}
   * @memberof TaskStatusItem
   */
  code?: string;
  /**
   * Описание
   * @type {string}
   * @memberof TaskStatusItem
   */
  description?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof TaskStatusItem
   */
  title?: string;
}
/**
 *
 * @export
 * @interface TaskStatusesResponse
 */
export interface TaskStatusesResponse {
  /**
   *
   * @type {Array<TaskStatusItem>}
   * @memberof TaskStatusesResponse
   */
  statuses?: Array<TaskStatusItem>;
}
/**
 *
 * @export
 * @interface TaskUpdateRequest
 */
export interface TaskUpdateRequest {
  /**
   * Дата начала
   * @type {string}
   * @memberof TaskUpdateRequest
   */
  beginDate?: string | null;
  /**
   * Срок выполнения
   * @type {string}
   * @memberof TaskUpdateRequest
   */
  deadlineDate?: string | null;
  /**
   * Описание
   * @type {string}
   * @memberof TaskUpdateRequest
   */
  description?: string | null;
  /**
   * Ответственный - исполнитель по задаче
   * @type {string}
   * @memberof TaskUpdateRequest
   */
  executorEmployeeCode?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TaskUpdateRequest
   */
  labelCodes?: Array<string> | null;
  /**
   * Стоимость задачи в баллах
   * @type {number}
   * @memberof TaskUpdateRequest
   */
  points?: number | null;
  /**
   * Код приоритета задачи
   * @type {string}
   * @memberof TaskUpdateRequest
   */
  priorityCode?: string | null;
  /**
   * Код статуса задачи
   * @type {string}
   * @memberof TaskUpdateRequest
   */
  statusCode?: string | null;
  /**
   * Наименование задачи
   * @type {string}
   * @memberof TaskUpdateRequest
   */
  title?: string | null;
}
/**
 *
 * @export
 * @interface TaskUpdateResponse
 */
export interface TaskUpdateResponse {
  /**
   *
   * @type {TaskItem}
   * @memberof TaskUpdateResponse
   */
  task?: TaskItem;
}
/**
 *
 * @export
 * @interface UserFeatures
 */
export interface UserFeatures {
  /**
   * Может ли пользователь прикрепить соглашение к сделке
   * @type {boolean}
   * @memberof UserFeatures
   */
  canDealAgreement?: boolean;
  /**
   * deprecated. Use customer.canSaveDeal. Может создавать заявки
   * @type {boolean}
   * @memberof UserFeatures
   */
  canSaveDeal?: boolean;
  /**
   * Может видеть цену закупки партий
   * @type {boolean}
   * @memberof UserFeatures
   */
  canSeeBatchCost?: boolean;
  /**
   * Может видеть маржинальность от плановой себестоимости
   * @type {boolean}
   * @memberof UserFeatures
   */
  canSeeDealPrimeCostMargin?: boolean;
  /**
   * Может устанавливать тендерную цену
   * @type {boolean}
   * @memberof UserFeatures
   */
  canSetTenderCost?: boolean;
  /**
   * Фильтр по умолчанию на странице клиентов
   * @type {string}
   * @memberof UserFeatures
   */
  clientsDefaultFilter?: string;
  /**
   * Фильтр по умолчанию на странице заявок
   * @type {string}
   * @memberof UserFeatures
   */
  dealsDefaultFilter?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserFeatures
   */
  hasAgreementsAccess?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserFeatures
   */
  hasBackofficeAccess?: boolean;
  /**
   * Стартовая страница пользователя. Сюда следует направлять пользователя после авторизации.
   * @type {string}
   * @memberof UserFeatures
   */
  homePage?: string;
}
/**
 *
 * @export
 * @interface UserGroup
 */
export interface UserGroup {
  /**
   *
   * @type {string}
   * @memberof UserGroup
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof UserGroup
   */
  title?: string;
}
/**
 *
 * @export
 * @interface UserNotificationSettings
 */
export interface UserNotificationSettings {
  /**
   * Получать емейл-уведомления
   * @type {boolean}
   * @memberof UserNotificationSettings
   */
  receiveEmail?: boolean;
  /**
   * Получать пуш-уведомления
   * @type {boolean}
   * @memberof UserNotificationSettings
   */
  receivePush?: boolean;
  /**
   * Получать веб-уведомления
   * @type {boolean}
   * @memberof UserNotificationSettings
   */
  receiveWeb?: boolean;
}
/**
 *
 * @export
 * @interface UserNotificationsItem
 */
export interface UserNotificationsItem {
  /**
   * Код уведомления
   * @type {string}
   * @memberof UserNotificationsItem
   */
  code?: string;
  /**
   * Флаг прочтения
   * @type {boolean}
   * @memberof UserNotificationsItem
   */
  read?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof UserNotificationsItem
   */
  sentAt?: string;
  /**
   * Текст уведомления
   * @type {string}
   * @memberof UserNotificationsItem
   */
  text?: string;
  /**
   * Заголовок уведомления
   * @type {string}
   * @memberof UserNotificationsItem
   */
  title?: string;
  /**
   * Ссылка на объект уведомления
   * @type {string}
   * @memberof UserNotificationsItem
   */
  url?: string;
}
/**
 *
 * @export
 * @interface UserNotificationsItemResponse
 */
export interface UserNotificationsItemResponse {
  /**
   *
   * @type {UserNotificationsItem}
   * @memberof UserNotificationsItemResponse
   */
  notification?: UserNotificationsItem;
}
/**
 *
 * @export
 * @interface UserNotificationsPagedResponse
 */
export interface UserNotificationsPagedResponse {
  /**
   *
   * @type {string}
   * @memberof UserNotificationsPagedResponse
   */
  at?: string;
  /**
   *
   * @type {Array<UserNotificationsItem>}
   * @memberof UserNotificationsPagedResponse
   */
  notifications?: Array<UserNotificationsItem>;
  /**
   *
   * @type {number}
   * @memberof UserNotificationsPagedResponse
   */
  totalCount?: number;
}
/**
 *
 * @export
 * @interface UserNotificationsResponse
 */
export interface UserNotificationsResponse {
  /**
   *
   * @type {Array<UserNotificationsItem>}
   * @memberof UserNotificationsResponse
   */
  notifications?: Array<UserNotificationsItem>;
}
/**
 *
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  branchOfficeCode?: string;
  /**
   *
   * @type {number}
   * @memberof UserProfile
   */
  branchOfficeId?: number;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  branchOfficeName?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  chosenBranchOfficeCode?: string;
  /**
   *
   * @type {number}
   * @memberof UserProfile
   */
  chosenBranchOfficeId?: number;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  chosenBranchOfficeName?: string;
  /**
   *
   * @type {EmployeeDepartment}
   * @memberof UserProfile
   */
  department?: EmployeeDepartment;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  email?: string;
  /**
   *
   * @type {Employee}
   * @memberof UserProfile
   */
  employee?: Employee;
  /**
   * for debug only
   * @type {object}
   * @memberof UserProfile
   */
  extra?: object;
  /**
   *
   * @type {UserFeatures}
   * @memberof UserProfile
   */
  features?: UserFeatures;
  /**
   *
   * @type {Array<string>}
   * @memberof UserProfile
   */
  groups?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  name?: string;
  /**
   *
   * @type {UserNotificationSettings}
   * @memberof UserProfile
   */
  notificationSettings?: UserNotificationSettings;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  phoneInternal?: string;
  /**
   *
   * @type {Array<UserResource>}
   * @memberof UserProfile
   */
  resources?: Array<UserResource>;
  /**
   *
   * @type {Array<UserGroup>}
   * @memberof UserProfile
   */
  userGroups?: Array<UserGroup>;
  /**
   * Код склада по умолчанию
   * @type {string}
   * @memberof UserProfile
   */
  warehouseCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  warehouseName?: string;
}
/**
 *
 * @export
 * @interface UserProfileResponse
 */
export interface UserProfileResponse {
  /**
   *
   * @type {UserProfile}
   * @memberof UserProfileResponse
   */
  profile?: UserProfile;
}
/**
 *
 * @export
 * @interface UserProfileSaveRequest
 */
export interface UserProfileSaveRequest {
  /**
   *
   * @type {UserProfileUpdates}
   * @memberof UserProfileSaveRequest
   */
  updates?: UserProfileUpdates;
}
/**
 *
 * @export
 * @interface UserProfileSaveResponse
 */
export interface UserProfileSaveResponse {
  /**
   *
   * @type {number}
   * @memberof UserProfileSaveResponse
   */
  result?: number;
}
/**
 *
 * @export
 * @interface UserProfileUpdates
 */
export interface UserProfileUpdates {
  /**
   *
   * @type {string}
   * @memberof UserProfileUpdates
   */
  chosenBranchOfficeCode?: string | null;
  /**
   * Получать емейл-уведомления
   * @type {boolean}
   * @memberof UserProfileUpdates
   */
  receiveEmail?: boolean | null;
  /**
   * Получать пуш-уведомления
   * @type {boolean}
   * @memberof UserProfileUpdates
   */
  receivePush?: boolean | null;
  /**
   * Получать веб-уведомления
   * @type {boolean}
   * @memberof UserProfileUpdates
   */
  receiveWeb?: boolean | null;
}
/**
 *
 * @export
 * @interface UserResource
 */
export interface UserResource {
  /**
   *
   * @type {string}
   * @memberof UserResource
   */
  comment?: string;
  /**
   *
   * @type {string}
   * @memberof UserResource
   */
  uri?: string;
}
/**
 *
 * @export
 * @interface VatRate
 */
export interface VatRate {
  /**
   * Код
   * @type {string}
   * @memberof VatRate
   */
  code?: string;
  /**
   * Заголовок
   * @type {string}
   * @memberof VatRate
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof VatRate
   */
  value?: number;
}
/**
 *
 * @export
 * @interface VatRateResponse
 */
export interface VatRateResponse {
  /**
   *
   * @type {Array<VatRate>}
   * @memberof VatRateResponse
   */
  vatRates?: Array<VatRate>;
}
/**
 *
 * @export
 * @interface Warehouse
 */
export interface Warehouse {
  /**
   *
   * @type {Address}
   * @memberof Warehouse
   */
  address?: Address;
  /**
   *
   * @type {string}
   * @memberof Warehouse
   */
  code?: string;
  /**
   * Склад по умолчанию для виртуальных групп.
   * @type {string}
   * @memberof Warehouse
   */
  defaultWarehouseCode?: string;
  /**
   * Если true, то этот склад виртуальный, объединяет остатки по другим складам.
   * @type {boolean}
   * @memberof Warehouse
   */
  isGroup?: boolean;
  /**
   * Признак записи \"без склада\"
   * @type {boolean}
   * @memberof Warehouse
   */
  isNoWarehouse?: boolean;
  /**
   *
   * @type {string}
   * @memberof Warehouse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Warehouse
   */
  officeCode?: string;
  /**
   *
   * @type {string}
   * @memberof Warehouse
   */
  officeName?: string;
}
/**
 * Склад в качестве фильтра
 * @export
 * @interface WarehouseAsFilter
 */
export interface WarehouseAsFilter {
  /**
   * Код склада
   * @type {string}
   * @memberof WarehouseAsFilter
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof WarehouseAsFilter
   */
  name?: string;
}
/**
 *
 * @export
 * @interface WarehousesForFilterResponse
 */
export interface WarehousesForFilterResponse {
  /**
   *
   * @type {Array<WarehouseAsFilter>}
   * @memberof WarehousesForFilterResponse
   */
  warehouses?: Array<WarehouseAsFilter>;
}
/**
 *
 * @export
 * @interface WsAgreementApprovalStateChanged
 */
export interface WsAgreementApprovalStateChanged {
  /**
   *
   * @type {string}
   * @memberof WsAgreementApprovalStateChanged
   */
  agreementCode?: string;
  /**
   * Состояние подтверждения
   * @type {string}
   * @memberof WsAgreementApprovalStateChanged
   */
  stateCode?: string;
}
/**
 *
 * @export
 * @interface WsCatalogBatchCertificateLoadingAnswer
 */
export interface WsCatalogBatchCertificateLoadingAnswer {
  /**
   * Код партии
   * @type {string}
   * @memberof WsCatalogBatchCertificateLoadingAnswer
   */
  batchCode?: string;
  /**
   * Ошибка в случае неудачной загрузки или получения ссылки на файл
   * @type {string}
   * @memberof WsCatalogBatchCertificateLoadingAnswer
   */
  error?: string;
  /**
   * GUID запроса, на который получен ответ
   * @type {string}
   * @memberof WsCatalogBatchCertificateLoadingAnswer
   */
  requestGuid?: string;
  /**
   * Размер файла сертификата (если загружен)
   * @type {number}
   * @memberof WsCatalogBatchCertificateLoadingAnswer
   */
  size?: number;
  /**
   * Текущий статус запроса
   * @type {string}
   * @memberof WsCatalogBatchCertificateLoadingAnswer
   */
  stateCode?: string;
  /**
   * Текущий статус запроса
   * @type {string}
   * @memberof WsCatalogBatchCertificateLoadingAnswer
   */
  stateTitle?: string;
  /**
   * Адрес для загрузки сертификата (если загружен)
   * @type {string}
   * @memberof WsCatalogBatchCertificateLoadingAnswer
   */
  url?: string;
}
/**
 *
 * @export
 * @interface WsClientsCreditStateSyncRequest
 */
export interface WsClientsCreditStateSyncRequest {
  /**
   *
   * @type {string}
   * @memberof WsClientsCreditStateSyncRequest
   */
  customerCode?: string;
}
/**
 *
 * @export
 * @interface WsClientsCreditStateSyncResponse
 */
export interface WsClientsCreditStateSyncResponse {
  /**
   *
   * @type {CreditState}
   * @memberof WsClientsCreditStateSyncResponse
   */
  creditState?: CreditState;
}
/**
 *
 * @export
 * @interface WsDealApprovalAsk
 */
export interface WsDealApprovalAsk {
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalAsk
   */
  askCode?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalAsk
   */
  askDate?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalAsk
   */
  dealCode?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalAsk
   */
  dealCompanyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalAsk
   */
  dealDate?: string;
  /**
   *
   * @type {Employee}
   * @memberof WsDealApprovalAsk
   */
  dealEmployee?: Employee;
  /**
   *
   * @type {Employee}
   * @memberof WsDealApprovalAsk
   */
  initiator?: Employee;
  /**
   * Код уведомления из notify
   * @type {string}
   * @memberof WsDealApprovalAsk
   */
  notifyCode?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalAsk
   */
  source?: string;
}
/**
 *
 * @export
 * @interface WsDealApprovalResolution
 */
export interface WsDealApprovalResolution {
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalResolution
   */
  askCode?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalResolution
   */
  askDate?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalResolution
   */
  dealCode?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalResolution
   */
  dealCompanyShortName?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalResolution
   */
  dealDate?: string;
  /**
   *
   * @type {Employee}
   * @memberof WsDealApprovalResolution
   */
  initiator?: Employee;
  /**
   * Код уведомления из notify
   * @type {string}
   * @memberof WsDealApprovalResolution
   */
  notifyCode?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalResolution
   */
  resolutionComment?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalResolution
   */
  resolutionDate?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalResolution
   */
  source?: string;
  /**
   *
   * @type {string}
   * @memberof WsDealApprovalResolution
   */
  state?: string;
}
/**
 *
 * @export
 * @interface WsDefaultInResponse
 */
export interface WsDefaultInResponse {
  /**
   *
   * @type {WsAgreementApprovalStateChanged}
   * @memberof WsDefaultInResponse
   */
  wsAgreementApprovalStateChanged?: WsAgreementApprovalStateChanged;
  /**
   *
   * @type {WsCatalogBatchCertificateLoadingAnswer}
   * @memberof WsDefaultInResponse
   */
  wsCatalogBatchCertificateLoadingAnswer?: WsCatalogBatchCertificateLoadingAnswer;
  /**
   *
   * @type {WsDealApprovalAsk}
   * @memberof WsDefaultInResponse
   */
  wsDealApprovalAsk?: WsDealApprovalAsk;
  /**
   *
   * @type {WsDealApprovalResolution}
   * @memberof WsDefaultInResponse
   */
  wsDealApprovalResolution?: WsDealApprovalResolution;
}
/**
 *
 * @export
 * @interface WsDeviceAuthTokenRequest
 */
export interface WsDeviceAuthTokenRequest {
  /**
   *
   * @type {string}
   * @memberof WsDeviceAuthTokenRequest
   */
  token?: string;
}
/**
 *
 * @export
 * @interface WsDeviceAuthTokenResponse
 */
export interface WsDeviceAuthTokenResponse {
  /**
   *
   * @type {number}
   * @memberof WsDeviceAuthTokenResponse
   */
  number?: number;
}

/**
 * AddressApi - axios parameter creator
 * @export
 */
export const AddressApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Поиск адреса по строке
     * @param {string} text Строка для поиска адреса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressGeoSuggest: async (text: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'text' is not null or undefined
      assertParamExists('addressGeoSuggest', 'text', text);
      const localVarPath = `/address/geosuggest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (text !== undefined) {
        localVarQueryParameter['text'] = text;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Поиск адреса по координатам
     * @param {string} coords latitude and longitude. Like \&quot;55.75,37.57\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressGeocodingDecode: async (coords: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'coords' is not null or undefined
      assertParamExists('addressGeocodingDecode', 'coords', coords);
      const localVarPath = `/address/geocoding/decode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (coords !== undefined) {
        localVarQueryParameter['coords'] = coords;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Поиск адреса по строке
     * @param {string} text Строка для поиска адреса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressGeocodingSearch: async (text: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'text' is not null or undefined
      assertParamExists('addressGeocodingSearch', 'text', text);
      const localVarPath = `/address/geocoding/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (text !== undefined) {
        localVarQueryParameter['text'] = text;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} addressCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressItemDisable: async (addressCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'addressCode' is not null or undefined
      assertParamExists('addressItemDisable', 'addressCode', addressCode);
      const localVarPath = `/address/{addressCode}/disable`.replace(`{${'addressCode'}}`, encodeURIComponent(String(addressCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} addressCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressItemXmlPreview: async (addressCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'addressCode' is not null or undefined
      assertParamExists('addressItemXmlPreview', 'addressCode', addressCode);
      const localVarPath = `/address/{addressCode}/xml/preview`.replace(`{${'addressCode'}}`, encodeURIComponent(String(addressCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список адресов
     * @param {string} customerCode Клиент-покупатель
     * @param {string} [branchOfficeCode] Филиал адреса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressList: async (customerCode: string, branchOfficeCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('addressList', 'customerCode', customerCode);
      const localVarPath = `/address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление адреса
     * @param {AddressSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressSave: async (request: AddressSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('addressSave', 'request', request);
      const localVarPath = `/address/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AddressApi - functional programming interface
 * @export
 */
export const AddressApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AddressApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Поиск адреса по строке
     * @param {string} text Строка для поиска адреса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addressGeoSuggest(
      text: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressGeoSuggestResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addressGeoSuggest(text, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Поиск адреса по координатам
     * @param {string} coords latitude and longitude. Like \&quot;55.75,37.57\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addressGeocodingDecode(
      coords: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressGeocodingDecodeResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addressGeocodingDecode(coords, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Поиск адреса по строке
     * @param {string} text Строка для поиска адреса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addressGeocodingSearch(
      text: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressGeocodingSearchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addressGeocodingSearch(text, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} addressCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addressItemDisable(
      addressCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addressItemDisable(addressCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} addressCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addressItemXmlPreview(
      addressCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addressItemXmlPreview(addressCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список адресов
     * @param {string} customerCode Клиент-покупатель
     * @param {string} [branchOfficeCode] Филиал адреса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addressList(
      customerCode: string,
      branchOfficeCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addressList(customerCode, branchOfficeCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновление адреса
     * @param {AddressSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addressSave(
      request: AddressSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressSaveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addressSave(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AddressApi - factory interface
 * @export
 */
export const AddressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AddressApiFp(configuration);
  return {
    /**
     *
     * @summary Поиск адреса по строке
     * @param {string} text Строка для поиска адреса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressGeoSuggest(text: string, options?: any): AxiosPromise<AddressGeoSuggestResponse> {
      return localVarFp.addressGeoSuggest(text, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Поиск адреса по координатам
     * @param {string} coords latitude and longitude. Like \&quot;55.75,37.57\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressGeocodingDecode(coords: string, options?: any): AxiosPromise<AddressGeocodingDecodeResponse> {
      return localVarFp.addressGeocodingDecode(coords, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Поиск адреса по строке
     * @param {string} text Строка для поиска адреса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressGeocodingSearch(text: string, options?: any): AxiosPromise<AddressGeocodingSearchResponse> {
      return localVarFp.addressGeocodingSearch(text, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} addressCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressItemDisable(addressCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.addressItemDisable(addressCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} addressCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressItemXmlPreview(addressCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.addressItemXmlPreview(addressCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список адресов
     * @param {string} customerCode Клиент-покупатель
     * @param {string} [branchOfficeCode] Филиал адреса
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressList(customerCode: string, branchOfficeCode?: string, options?: any): AxiosPromise<AddressListResponse> {
      return localVarFp.addressList(customerCode, branchOfficeCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление адреса
     * @param {AddressSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addressSave(request: AddressSaveRequest, options?: any): AxiosPromise<AddressSaveResponse> {
      return localVarFp.addressSave(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AddressApi - interface
 * @export
 * @interface AddressApi
 */
export interface AddressApiInterface {
  /**
   *
   * @summary Поиск адреса по строке
   * @param {string} text Строка для поиска адреса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApiInterface
   */
  addressGeoSuggest(text: string, options?: AxiosRequestConfig): AxiosPromise<AddressGeoSuggestResponse>;

  /**
   *
   * @summary Поиск адреса по координатам
   * @param {string} coords latitude and longitude. Like \&quot;55.75,37.57\&quot;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApiInterface
   */
  addressGeocodingDecode(coords: string, options?: AxiosRequestConfig): AxiosPromise<AddressGeocodingDecodeResponse>;

  /**
   *
   * @summary Поиск адреса по строке
   * @param {string} text Строка для поиска адреса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApiInterface
   */
  addressGeocodingSearch(text: string, options?: AxiosRequestConfig): AxiosPromise<AddressGeocodingSearchResponse>;

  /**
   *
   * @param {string} addressCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApiInterface
   */
  addressItemDisable(addressCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @param {string} addressCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApiInterface
   */
  addressItemXmlPreview(addressCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Список адресов
   * @param {string} customerCode Клиент-покупатель
   * @param {string} [branchOfficeCode] Филиал адреса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApiInterface
   */
  addressList(customerCode: string, branchOfficeCode?: string, options?: AxiosRequestConfig): AxiosPromise<AddressListResponse>;

  /**
   *
   * @summary Обновление адреса
   * @param {AddressSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApiInterface
   */
  addressSave(request: AddressSaveRequest, options?: AxiosRequestConfig): AxiosPromise<AddressSaveResponse>;
}

/**
 * AddressApi - object-oriented interface
 * @export
 * @class AddressApi
 * @extends {BaseAPI}
 */
export class AddressApi extends BaseAPI implements AddressApiInterface {
  /**
   *
   * @summary Поиск адреса по строке
   * @param {string} text Строка для поиска адреса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApi
   */
  public addressGeoSuggest(text: string, options?: AxiosRequestConfig) {
    return AddressApiFp(this.configuration)
      .addressGeoSuggest(text, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Поиск адреса по координатам
   * @param {string} coords latitude and longitude. Like \&quot;55.75,37.57\&quot;
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApi
   */
  public addressGeocodingDecode(coords: string, options?: AxiosRequestConfig) {
    return AddressApiFp(this.configuration)
      .addressGeocodingDecode(coords, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Поиск адреса по строке
   * @param {string} text Строка для поиска адреса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApi
   */
  public addressGeocodingSearch(text: string, options?: AxiosRequestConfig) {
    return AddressApiFp(this.configuration)
      .addressGeocodingSearch(text, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} addressCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApi
   */
  public addressItemDisable(addressCode: string, options?: AxiosRequestConfig) {
    return AddressApiFp(this.configuration)
      .addressItemDisable(addressCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} addressCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApi
   */
  public addressItemXmlPreview(addressCode: string, options?: AxiosRequestConfig) {
    return AddressApiFp(this.configuration)
      .addressItemXmlPreview(addressCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список адресов
   * @param {string} customerCode Клиент-покупатель
   * @param {string} [branchOfficeCode] Филиал адреса
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApi
   */
  public addressList(customerCode: string, branchOfficeCode?: string, options?: AxiosRequestConfig) {
    return AddressApiFp(this.configuration)
      .addressList(customerCode, branchOfficeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление адреса
   * @param {AddressSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AddressApi
   */
  public addressSave(request: AddressSaveRequest, options?: AxiosRequestConfig) {
    return AddressApiFp(this.configuration)
      .addressSave(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Проверка авторизации и прав доступа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authTest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user/auth/test`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Фиксирует факт успешной авторизации.
     * @summary Успешная авторизация на фронте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthSuccess: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user/auth/success`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLogout(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLogoutResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLogout(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Проверка авторизации и прав доступа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authTest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authTest(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Фиксирует факт успешной авторизации.
     * @summary Успешная авторизация на фронте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthSuccess(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthSuccess(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogout(options?: any): AxiosPromise<AuthLogoutResponse> {
      return localVarFp.authLogout(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Проверка авторизации и прав доступа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authTest(options?: any): AxiosPromise<void> {
      return localVarFp.authTest(options).then(request => request(axios, basePath));
    },
    /**
     * Фиксирует факт успешной авторизации.
     * @summary Успешная авторизация на фронте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthSuccess(options?: any): AxiosPromise<void> {
      return localVarFp.userAuthSuccess(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - interface
 * @export
 * @interface AuthApi
 */
export interface AuthApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  authLogout(options?: AxiosRequestConfig): AxiosPromise<AuthLogoutResponse>;

  /**
   *
   * @summary Проверка авторизации и прав доступа
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  authTest(options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Фиксирует факт успешной авторизации.
   * @summary Успешная авторизация на фронте
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApiInterface
   */
  userAuthSuccess(options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI implements AuthApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLogout(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authLogout(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Проверка авторизации и прав доступа
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authTest(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authTest(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Фиксирует факт успешной авторизации.
   * @summary Успешная авторизация на фронте
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public userAuthSuccess(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .userAuthSuccess(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BatchesApi - axios parameter creator
 * @export
 */
export const BatchesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Информация по партиям
     * @param {BatchesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batches: async (request: BatchesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('batches', 'request', request);
      const localVarPath = `/batches`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BatchesApi - functional programming interface
 * @export
 */
export const BatchesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BatchesApiAxiosParamCreator(configuration);
  return {
    /**
     * Информация по партиям
     * @param {BatchesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batches(
      request: BatchesRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batches(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BatchesApi - factory interface
 * @export
 */
export const BatchesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = BatchesApiFp(configuration);
  return {
    /**
     * Информация по партиям
     * @param {BatchesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batches(request: BatchesRequest, options?: any): AxiosPromise<BatchesResponse> {
      return localVarFp.batches(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * BatchesApi - interface
 * @export
 * @interface BatchesApi
 */
export interface BatchesApiInterface {
  /**
   * Информация по партиям
   * @param {BatchesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApiInterface
   */
  batches(request: BatchesRequest, options?: AxiosRequestConfig): AxiosPromise<BatchesResponse>;
}

/**
 * BatchesApi - object-oriented interface
 * @export
 * @class BatchesApi
 * @extends {BaseAPI}
 */
export class BatchesApi extends BaseAPI implements BatchesApiInterface {
  /**
   * Информация по партиям
   * @param {BatchesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchesApi
   */
  public batches(request: BatchesRequest, options?: AxiosRequestConfig) {
    return BatchesApiFp(this.configuration)
      .batches(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BillApi - axios parameter creator
 * @export
 */
export const BillApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Осуществить попытку мягкого резервирования по счёту
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billSoftReserveCalculate: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billSoftReserveCalculate', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/softReserve/calulate`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Вывод счета на приоритетную позицию в очереди на резерв
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billSoftReservePrioritize: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billSoftReservePrioritize', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/softReserve/prioritize`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить настройки мягкого резервирования по счёту
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billSoftReserveSettingsGet: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billSoftReserveSettingsGet', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/softReserve/settings`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновить настройки мягкого резервирования по счёту
     * @param {string} documentNumber
     * @param {BillSoftReserveSettingsUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billSoftReserveSettingsUpdate: async (
      documentNumber: string,
      request: BillSoftReserveSettingsUpdateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billSoftReserveSettingsUpdate', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billSoftReserveSettingsUpdate', 'request', request);
      const localVarPath = `/bills/{documentNumber}/softReserve/settings`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список счетов
     * @param {BillsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bills: async (request: BillsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('bills', 'request', request);
      const localVarPath = `/bills`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Активация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemActivate: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemActivate', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/activate`.replace(`{${'documentNumber'}}`, encodeURIComponent(String(documentNumber)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Деактивация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemDeactivate: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemDeactivate', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/deactivate`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Зафиксировать изменение счёта
     * @param {string} documentNumber
     * @param {BillsItemEditPersistRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemEditPersist: async (
      documentNumber: string,
      request: BillsItemEditPersistRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemEditPersist', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsItemEditPersist', 'request', request);
      const localVarPath = `/bills/{documentNumber}/edit/persist`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Предпросмотр изменений счёта
     * @param {string} documentNumber
     * @param {BillsItemEditPreviewRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemEditPreview: async (
      documentNumber: string,
      request: BillsItemEditPreviewRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemEditPreview', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsItemEditPreview', 'request', request);
      const localVarPath = `/bills/{documentNumber}/edit/preview`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Получение информации о документе Счёт на оплату. Сам документ можно получить в HTML и PDF-формате (BillsItemInvoiceHTML, BillsItemInvoicePdf) В ответе BillsItemInvoiceHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью BillsItemInvoiceUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
     * @summary Получение информации о документе Счёт на оплату
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemInvoice: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemInvoice', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/invoice`.replace(`{${'documentNumber'}}`, encodeURIComponent(String(documentNumber)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование HTML счета на оплату по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemInvoiceHTML: async (
      documentNumber: string,
      withoutStampAndSign?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemInvoiceHTML', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/invoice/html`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withoutStampAndSign !== undefined) {
        localVarQueryParameter['withoutStampAndSign'] = withoutStampAndSign;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование PDF счета на оплату по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemInvoicePdf: async (
      documentNumber: string,
      withoutStampAndSign?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemInvoicePdf', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/invoice/pdf`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withoutStampAndSign !== undefined) {
        localVarQueryParameter['withoutStampAndSign'] = withoutStampAndSign;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление текстовок и другой информации документа Счёт на оплату
     * @param {string} documentNumber
     * @param {BillsItemInvoiceUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemInvoiceUpdate: async (
      documentNumber: string,
      request: BillsItemInvoiceUpdateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemInvoiceUpdate', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsItemInvoiceUpdate', 'request', request);
      const localVarPath = `/bills/{documentNumber}/invoice`.replace(`{${'documentNumber'}}`, encodeURIComponent(String(documentNumber)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список партнеров счёта
     * @param {string} documentNumber
     * @param {string} [typeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemPartners: async (documentNumber: string, typeCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemPartners', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/partners`.replace(`{${'documentNumber'}}`, encodeURIComponent(String(documentNumber)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (typeCode !== undefined) {
        localVarQueryParameter['typeCode'] = typeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Пролонгация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemProlong: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemProlong', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/prolong`.replace(`{${'documentNumber'}}`, encodeURIComponent(String(documentNumber)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Фактурировать счёт
     * @param {string} documentNumber
     * @param {BillsItemReconcileRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemReconcile: async (
      documentNumber: string,
      request?: BillsItemReconcileRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemReconcile', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/reconcile`.replace(`{${'documentNumber'}}`, encodeURIComponent(String(documentNumber)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Снятие флага наличия подчиненных документов
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemRemoveSubordinateDoc: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemRemoveSubordinateDoc', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/removeSubordinateDoc`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Получение информации о документе Спецификация счёта. Сам документ можно получить в HTML и PDF-формате (BillsItemSpecificationHTML, BillsItemSpecificationPdf) В ответе BillsItemSpecificationHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью BillsItemSpecificationUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
     * @summary Получение информации о документе Спецификация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemSpecification: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemSpecification', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/specification`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование HTML спецификации счёта по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemSpecificationHTML: async (
      documentNumber: string,
      withoutStampAndSign?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemSpecificationHTML', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/specification/html`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withoutStampAndSign !== undefined) {
        localVarQueryParameter['withoutStampAndSign'] = withoutStampAndSign;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование PDF спецификации счёта по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemSpecificationPdf: async (
      documentNumber: string,
      withoutStampAndSign?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemSpecificationPdf', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/specification/pdf`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withoutStampAndSign !== undefined) {
        localVarQueryParameter['withoutStampAndSign'] = withoutStampAndSign;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление текстовок и другой информации документа Спецификация счёта
     * @param {string} documentNumber
     * @param {BillsItemSpecificationUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemSpecificationUpdate: async (
      documentNumber: string,
      request: BillsItemSpecificationUpdateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemSpecificationUpdate', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsItemSpecificationUpdate', 'request', request);
      const localVarPath = `/bills/{documentNumber}/specification`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Обновление комментариев счёта
     * @param {string} documentNumber
     * @param {BillsItemUpdateCommentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemUpdateComments: async (
      documentNumber: string,
      request: BillsItemUpdateCommentsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemUpdateComments', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsItemUpdateComments', 'request', request);
      const localVarPath = `/bills/{documentNumber}/update/comments`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Обновление партнера счета
     * @param {string} documentNumber
     * @param {BillsItemUpdatePartnerRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemUpdatePartner: async (
      documentNumber: string,
      request: BillsItemUpdatePartnerRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemUpdatePartner', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsItemUpdatePartner', 'request', request);
      const localVarPath = `/bills/{documentNumber}/update/partner`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Обновление адреса партнера счета
     * @param {string} documentNumber
     * @param {BillsItemUpdatePartnerAddressRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemUpdatePartnerAddress: async (
      documentNumber: string,
      request: BillsItemUpdatePartnerAddressRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemUpdatePartnerAddress', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsItemUpdatePartnerAddress', 'request', request);
      const localVarPath = `/bills/{documentNumber}/update/partner/address`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * deprecated Нужно использовать метод BillsItemUpdatePartner. Обновление плательщика счета
     * @param {string} documentNumber
     * @param {BillsItemUpdatePayerRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemUpdatePayer: async (
      documentNumber: string,
      request: BillsItemUpdatePayerRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsItemUpdatePayer', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsItemUpdatePayer', 'request', request);
      const localVarPath = `/bills/{documentNumber}/update/payer`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник статусов отгрузки по счету
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsShipmentStates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/bills/shipmentStates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Осуществить попытку мягкого резервирования по всему счёту или по товару со склада в счёте
     * @param {string} documentNumber
     * @param {BillsSoftReserveCalculateV2Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsSoftReserveCalculateV2: async (
      documentNumber: string,
      request: BillsSoftReserveCalculateV2Request,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsSoftReserveCalculateV2', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsSoftReserveCalculateV2', 'request', request);
      const localVarPath = `/bills/{documentNumber}/softReserve/calulate/v2`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Снять мягкие резервы
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsSoftReserveWithdraw: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsSoftReserveWithdraw', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/softReserve/withdraw`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Снять мягкие резервы по всему счёту или по товару со склада в счёте
     * @param {string} documentNumber
     * @param {BillsSoftReserveWithdrawV2Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsSoftReserveWithdrawV2: async (
      documentNumber: string,
      request: BillsSoftReserveWithdrawV2Request,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsSoftReserveWithdrawV2', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsSoftReserveWithdrawV2', 'request', request);
      const localVarPath = `/bills/{documentNumber}/softReserve/withdraw/v2`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник источников счета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsSources: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/bills/sources`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник статусов счета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsStates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/bills/states`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Цепочка документов к счету
     * @param {BillsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsThread: async (request: BillsThreadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsThread', 'request', request);
      const localVarPath = `/bills/thread`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создать или обновить счёт по заявке
     * @param {BillsUpdateFromDealRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsUpdateFromDeal: async (request: BillsUpdateFromDealRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsUpdateFromDeal', 'request', request);
      const localVarPath = `/bills/updateFromDeal`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Визуализировать эмуляцию снятия резерва по позиции в счёте
     * @param {string} documentNumber
     * @param {BillsVisualizeEmulatedWithdrawRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsVisualizeEmulatedWithdraw: async (
      documentNumber: string,
      request: BillsVisualizeEmulatedWithdrawRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsVisualizeEmulatedWithdraw', 'documentNumber', documentNumber);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('billsVisualizeEmulatedWithdraw', 'request', request);
      const localVarPath = `/bills/{documentNumber}/visualizeEmulatedWithdraw`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsXmlPreview: async (documentNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'documentNumber' is not null or undefined
      assertParamExists('billsXmlPreview', 'documentNumber', documentNumber);
      const localVarPath = `/bills/{documentNumber}/xml/preview`.replace(
        `{${'documentNumber'}}`,
        encodeURIComponent(String(documentNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BillApi - functional programming interface
 * @export
 */
export const BillApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BillApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Осуществить попытку мягкого резервирования по счёту
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billSoftReserveCalculate(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billSoftReserveCalculate(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Вывод счета на приоритетную позицию в очереди на резерв
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billSoftReservePrioritize(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billSoftReservePrioritize(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получить настройки мягкого резервирования по счёту
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billSoftReserveSettingsGet(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillSoftReserveSettingsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billSoftReserveSettingsGet(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновить настройки мягкого резервирования по счёту
     * @param {string} documentNumber
     * @param {BillSoftReserveSettingsUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billSoftReserveSettingsUpdate(
      documentNumber: string,
      request: BillSoftReserveSettingsUpdateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillSoftReserveSettingsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billSoftReserveSettingsUpdate(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список счетов
     * @param {BillsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bills(
      request: BillsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bills(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Активация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemActivate(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemActivate(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Деактивация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemDeactivate(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemDeactivate(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Зафиксировать изменение счёта
     * @param {string} documentNumber
     * @param {BillsItemEditPersistRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemEditPersist(
      documentNumber: string,
      request: BillsItemEditPersistRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemEditPersist(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Предпросмотр изменений счёта
     * @param {string} documentNumber
     * @param {BillsItemEditPreviewRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemEditPreview(
      documentNumber: string,
      request: BillsItemEditPreviewRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsItemEditPreviewResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemEditPreview(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Получение информации о документе Счёт на оплату. Сам документ можно получить в HTML и PDF-формате (BillsItemInvoiceHTML, BillsItemInvoicePdf) В ответе BillsItemInvoiceHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью BillsItemInvoiceUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
     * @summary Получение информации о документе Счёт на оплату
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemInvoice(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsItemInvoice>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemInvoice(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование HTML счета на оплату по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemInvoiceHTML(
      documentNumber: string,
      withoutStampAndSign?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemInvoiceHTML(documentNumber, withoutStampAndSign, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование PDF счета на оплату по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemInvoicePdf(
      documentNumber: string,
      withoutStampAndSign?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemInvoicePdf(documentNumber, withoutStampAndSign, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновление текстовок и другой информации документа Счёт на оплату
     * @param {string} documentNumber
     * @param {BillsItemInvoiceUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemInvoiceUpdate(
      documentNumber: string,
      request: BillsItemInvoiceUpdateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsItemInvoice>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemInvoiceUpdate(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список партнеров счёта
     * @param {string} documentNumber
     * @param {string} [typeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemPartners(
      documentNumber: string,
      typeCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsItemPartnersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemPartners(documentNumber, typeCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Пролонгация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemProlong(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemProlong(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Фактурировать счёт
     * @param {string} documentNumber
     * @param {BillsItemReconcileRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemReconcile(
      documentNumber: string,
      request?: BillsItemReconcileRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemReconcile(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Снятие флага наличия подчиненных документов
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemRemoveSubordinateDoc(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemRemoveSubordinateDoc(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Получение информации о документе Спецификация счёта. Сам документ можно получить в HTML и PDF-формате (BillsItemSpecificationHTML, BillsItemSpecificationPdf) В ответе BillsItemSpecificationHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью BillsItemSpecificationUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
     * @summary Получение информации о документе Спецификация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemSpecification(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsItemSpecification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemSpecification(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование HTML спецификации счёта по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemSpecificationHTML(
      documentNumber: string,
      withoutStampAndSign?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemSpecificationHTML(documentNumber, withoutStampAndSign, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование PDF спецификации счёта по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemSpecificationPdf(
      documentNumber: string,
      withoutStampAndSign?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemSpecificationPdf(documentNumber, withoutStampAndSign, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновление текстовок и другой информации документа Спецификация счёта
     * @param {string} documentNumber
     * @param {BillsItemSpecificationUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemSpecificationUpdate(
      documentNumber: string,
      request: BillsItemSpecificationUpdateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsItemSpecification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemSpecificationUpdate(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Обновление комментариев счёта
     * @param {string} documentNumber
     * @param {BillsItemUpdateCommentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemUpdateComments(
      documentNumber: string,
      request: BillsItemUpdateCommentsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemUpdateComments(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Обновление партнера счета
     * @param {string} documentNumber
     * @param {BillsItemUpdatePartnerRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemUpdatePartner(
      documentNumber: string,
      request: BillsItemUpdatePartnerRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemUpdatePartner(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Обновление адреса партнера счета
     * @param {string} documentNumber
     * @param {BillsItemUpdatePartnerAddressRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemUpdatePartnerAddress(
      documentNumber: string,
      request: BillsItemUpdatePartnerAddressRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemUpdatePartnerAddress(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * deprecated Нужно использовать метод BillsItemUpdatePartner. Обновление плательщика счета
     * @param {string} documentNumber
     * @param {BillsItemUpdatePayerRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsItemUpdatePayer(
      documentNumber: string,
      request: BillsItemUpdatePayerRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsItemUpdatePayer(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник статусов отгрузки по счету
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsShipmentStates(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsShipmentStatesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsShipmentStates(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Осуществить попытку мягкого резервирования по всему счёту или по товару со склада в счёте
     * @param {string} documentNumber
     * @param {BillsSoftReserveCalculateV2Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsSoftReserveCalculateV2(
      documentNumber: string,
      request: BillsSoftReserveCalculateV2Request,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsSoftReserveCalculateV2(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Снять мягкие резервы
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsSoftReserveWithdraw(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsSoftReserveWithdraw(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Снять мягкие резервы по всему счёту или по товару со склада в счёте
     * @param {string} documentNumber
     * @param {BillsSoftReserveWithdrawV2Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsSoftReserveWithdrawV2(
      documentNumber: string,
      request: BillsSoftReserveWithdrawV2Request,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsSoftReserveWithdrawV2(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник источников счета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsSources(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsSourcesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsSources(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник статусов счета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsStates(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsStatesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsStates(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Цепочка документов к счету
     * @param {BillsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsThread(
      request: BillsThreadRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsThreadResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsThread(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создать или обновить счёт по заявке
     * @param {BillsUpdateFromDealRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsUpdateFromDeal(
      request: BillsUpdateFromDealRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsUpdateFromDealResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsUpdateFromDeal(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Визуализировать эмуляцию снятия резерва по позиции в счёте
     * @param {string} documentNumber
     * @param {BillsVisualizeEmulatedWithdrawRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsVisualizeEmulatedWithdraw(
      documentNumber: string,
      request: BillsVisualizeEmulatedWithdrawRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillsVisualizeEmulatedWithdrawResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsVisualizeEmulatedWithdraw(documentNumber, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billsXmlPreview(
      documentNumber: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.billsXmlPreview(documentNumber, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BillApi - factory interface
 * @export
 */
export const BillApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = BillApiFp(configuration);
  return {
    /**
     *
     * @summary Осуществить попытку мягкого резервирования по счёту
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billSoftReserveCalculate(documentNumber: string, options?: any): AxiosPromise<void> {
      return localVarFp.billSoftReserveCalculate(documentNumber, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Вывод счета на приоритетную позицию в очереди на резерв
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billSoftReservePrioritize(documentNumber: string, options?: any): AxiosPromise<void> {
      return localVarFp.billSoftReservePrioritize(documentNumber, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Получить настройки мягкого резервирования по счёту
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billSoftReserveSettingsGet(documentNumber: string, options?: any): AxiosPromise<BillSoftReserveSettingsResponse> {
      return localVarFp.billSoftReserveSettingsGet(documentNumber, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Обновить настройки мягкого резервирования по счёту
     * @param {string} documentNumber
     * @param {BillSoftReserveSettingsUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billSoftReserveSettingsUpdate(
      documentNumber: string,
      request: BillSoftReserveSettingsUpdateRequest,
      options?: any
    ): AxiosPromise<BillSoftReserveSettingsResponse> {
      return localVarFp.billSoftReserveSettingsUpdate(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список счетов
     * @param {BillsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bills(request: BillsRequest, options?: any): AxiosPromise<BillsResponse> {
      return localVarFp.bills(request, options).then(request => request(axios, basePath));
    },
    /**
     * Активация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemActivate(documentNumber: string, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemActivate(documentNumber, options).then(request => request(axios, basePath));
    },
    /**
     * Деактивация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemDeactivate(documentNumber: string, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemDeactivate(documentNumber, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Зафиксировать изменение счёта
     * @param {string} documentNumber
     * @param {BillsItemEditPersistRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemEditPersist(documentNumber: string, request: BillsItemEditPersistRequest, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemEditPersist(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Предпросмотр изменений счёта
     * @param {string} documentNumber
     * @param {BillsItemEditPreviewRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemEditPreview(
      documentNumber: string,
      request: BillsItemEditPreviewRequest,
      options?: any
    ): AxiosPromise<BillsItemEditPreviewResponse> {
      return localVarFp.billsItemEditPreview(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     * Получение информации о документе Счёт на оплату. Сам документ можно получить в HTML и PDF-формате (BillsItemInvoiceHTML, BillsItemInvoicePdf) В ответе BillsItemInvoiceHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью BillsItemInvoiceUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
     * @summary Получение информации о документе Счёт на оплату
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemInvoice(documentNumber: string, options?: any): AxiosPromise<BillsItemInvoice> {
      return localVarFp.billsItemInvoice(documentNumber, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование HTML счета на оплату по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemInvoiceHTML(documentNumber: string, withoutStampAndSign?: boolean, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemInvoiceHTML(documentNumber, withoutStampAndSign, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование PDF счета на оплату по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemInvoicePdf(documentNumber: string, withoutStampAndSign?: boolean, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemInvoicePdf(documentNumber, withoutStampAndSign, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление текстовок и другой информации документа Счёт на оплату
     * @param {string} documentNumber
     * @param {BillsItemInvoiceUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemInvoiceUpdate(documentNumber: string, request: BillsItemInvoiceUpdateRequest, options?: any): AxiosPromise<BillsItemInvoice> {
      return localVarFp.billsItemInvoiceUpdate(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     * Список партнеров счёта
     * @param {string} documentNumber
     * @param {string} [typeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemPartners(documentNumber: string, typeCode?: string, options?: any): AxiosPromise<BillsItemPartnersResponse> {
      return localVarFp.billsItemPartners(documentNumber, typeCode, options).then(request => request(axios, basePath));
    },
    /**
     * Пролонгация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemProlong(documentNumber: string, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemProlong(documentNumber, options).then(request => request(axios, basePath));
    },
    /**
     * Фактурировать счёт
     * @param {string} documentNumber
     * @param {BillsItemReconcileRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemReconcile(documentNumber: string, request?: BillsItemReconcileRequest, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemReconcile(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     * Снятие флага наличия подчиненных документов
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemRemoveSubordinateDoc(documentNumber: string, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemRemoveSubordinateDoc(documentNumber, options).then(request => request(axios, basePath));
    },
    /**
     * Получение информации о документе Спецификация счёта. Сам документ можно получить в HTML и PDF-формате (BillsItemSpecificationHTML, BillsItemSpecificationPdf) В ответе BillsItemSpecificationHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью BillsItemSpecificationUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
     * @summary Получение информации о документе Спецификация счёта
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemSpecification(documentNumber: string, options?: any): AxiosPromise<BillsItemSpecification> {
      return localVarFp.billsItemSpecification(documentNumber, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование HTML спецификации счёта по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemSpecificationHTML(documentNumber: string, withoutStampAndSign?: boolean, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemSpecificationHTML(documentNumber, withoutStampAndSign, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование PDF спецификации счёта по счету
     * @param {string} documentNumber
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemSpecificationPdf(documentNumber: string, withoutStampAndSign?: boolean, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemSpecificationPdf(documentNumber, withoutStampAndSign, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление текстовок и другой информации документа Спецификация счёта
     * @param {string} documentNumber
     * @param {BillsItemSpecificationUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemSpecificationUpdate(
      documentNumber: string,
      request: BillsItemSpecificationUpdateRequest,
      options?: any
    ): AxiosPromise<BillsItemSpecification> {
      return localVarFp.billsItemSpecificationUpdate(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     * Обновление комментариев счёта
     * @param {string} documentNumber
     * @param {BillsItemUpdateCommentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemUpdateComments(documentNumber: string, request: BillsItemUpdateCommentsRequest, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemUpdateComments(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     * Обновление партнера счета
     * @param {string} documentNumber
     * @param {BillsItemUpdatePartnerRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemUpdatePartner(documentNumber: string, request: BillsItemUpdatePartnerRequest, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemUpdatePartner(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     * Обновление адреса партнера счета
     * @param {string} documentNumber
     * @param {BillsItemUpdatePartnerAddressRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemUpdatePartnerAddress(
      documentNumber: string,
      request: BillsItemUpdatePartnerAddressRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp.billsItemUpdatePartnerAddress(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     * deprecated Нужно использовать метод BillsItemUpdatePartner. Обновление плательщика счета
     * @param {string} documentNumber
     * @param {BillsItemUpdatePayerRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsItemUpdatePayer(documentNumber: string, request: BillsItemUpdatePayerRequest, options?: any): AxiosPromise<void> {
      return localVarFp.billsItemUpdatePayer(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник статусов отгрузки по счету
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsShipmentStates(options?: any): AxiosPromise<BillsShipmentStatesResponse> {
      return localVarFp.billsShipmentStates(options).then(request => request(axios, basePath));
    },
    /**
     * Осуществить попытку мягкого резервирования по всему счёту или по товару со склада в счёте
     * @param {string} documentNumber
     * @param {BillsSoftReserveCalculateV2Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsSoftReserveCalculateV2(documentNumber: string, request: BillsSoftReserveCalculateV2Request, options?: any): AxiosPromise<void> {
      return localVarFp.billsSoftReserveCalculateV2(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     * Снять мягкие резервы
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsSoftReserveWithdraw(documentNumber: string, options?: any): AxiosPromise<void> {
      return localVarFp.billsSoftReserveWithdraw(documentNumber, options).then(request => request(axios, basePath));
    },
    /**
     * Снять мягкие резервы по всему счёту или по товару со склада в счёте
     * @param {string} documentNumber
     * @param {BillsSoftReserveWithdrawV2Request} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsSoftReserveWithdrawV2(documentNumber: string, request: BillsSoftReserveWithdrawV2Request, options?: any): AxiosPromise<void> {
      return localVarFp.billsSoftReserveWithdrawV2(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник источников счета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsSources(options?: any): AxiosPromise<BillsSourcesResponse> {
      return localVarFp.billsSources(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник статусов счета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsStates(options?: any): AxiosPromise<BillsStatesResponse> {
      return localVarFp.billsStates(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Цепочка документов к счету
     * @param {BillsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsThread(request: BillsThreadRequest, options?: any): AxiosPromise<BillsThreadResponse> {
      return localVarFp.billsThread(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создать или обновить счёт по заявке
     * @param {BillsUpdateFromDealRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsUpdateFromDeal(request: BillsUpdateFromDealRequest, options?: any): AxiosPromise<BillsUpdateFromDealResponse> {
      return localVarFp.billsUpdateFromDeal(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Визуализировать эмуляцию снятия резерва по позиции в счёте
     * @param {string} documentNumber
     * @param {BillsVisualizeEmulatedWithdrawRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsVisualizeEmulatedWithdraw(
      documentNumber: string,
      request: BillsVisualizeEmulatedWithdrawRequest,
      options?: any
    ): AxiosPromise<BillsVisualizeEmulatedWithdrawResponse> {
      return localVarFp.billsVisualizeEmulatedWithdraw(documentNumber, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} documentNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billsXmlPreview(documentNumber: string, options?: any): AxiosPromise<void> {
      return localVarFp.billsXmlPreview(documentNumber, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * BillApi - interface
 * @export
 * @interface BillApi
 */
export interface BillApiInterface {
  /**
   *
   * @summary Осуществить попытку мягкого резервирования по счёту
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billSoftReserveCalculate(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Вывод счета на приоритетную позицию в очереди на резерв
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billSoftReservePrioritize(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Получить настройки мягкого резервирования по счёту
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billSoftReserveSettingsGet(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<BillSoftReserveSettingsResponse>;

  /**
   *
   * @summary Обновить настройки мягкого резервирования по счёту
   * @param {string} documentNumber
   * @param {BillSoftReserveSettingsUpdateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billSoftReserveSettingsUpdate(
    documentNumber: string,
    request: BillSoftReserveSettingsUpdateRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<BillSoftReserveSettingsResponse>;

  /**
   *
   * @summary Список счетов
   * @param {BillsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  bills(request: BillsRequest, options?: AxiosRequestConfig): AxiosPromise<BillsResponse>;

  /**
   * Активация счёта
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemActivate(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Деактивация счёта
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemDeactivate(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Зафиксировать изменение счёта
   * @param {string} documentNumber
   * @param {BillsItemEditPersistRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemEditPersist(documentNumber: string, request: BillsItemEditPersistRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Предпросмотр изменений счёта
   * @param {string} documentNumber
   * @param {BillsItemEditPreviewRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemEditPreview(
    documentNumber: string,
    request: BillsItemEditPreviewRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<BillsItemEditPreviewResponse>;

  /**
   * Получение информации о документе Счёт на оплату. Сам документ можно получить в HTML и PDF-формате (BillsItemInvoiceHTML, BillsItemInvoicePdf) В ответе BillsItemInvoiceHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью BillsItemInvoiceUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
   * @summary Получение информации о документе Счёт на оплату
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemInvoice(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<BillsItemInvoice>;

  /**
   *
   * @summary Формирование HTML счета на оплату по счету
   * @param {string} documentNumber
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemInvoiceHTML(documentNumber: string, withoutStampAndSign?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Формирование PDF счета на оплату по счету
   * @param {string} documentNumber
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemInvoicePdf(documentNumber: string, withoutStampAndSign?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Обновление текстовок и другой информации документа Счёт на оплату
   * @param {string} documentNumber
   * @param {BillsItemInvoiceUpdateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemInvoiceUpdate(
    documentNumber: string,
    request: BillsItemInvoiceUpdateRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<BillsItemInvoice>;

  /**
   * Список партнеров счёта
   * @param {string} documentNumber
   * @param {string} [typeCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemPartners(documentNumber: string, typeCode?: string, options?: AxiosRequestConfig): AxiosPromise<BillsItemPartnersResponse>;

  /**
   * Пролонгация счёта
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemProlong(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Фактурировать счёт
   * @param {string} documentNumber
   * @param {BillsItemReconcileRequest} [request]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemReconcile(documentNumber: string, request?: BillsItemReconcileRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Снятие флага наличия подчиненных документов
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemRemoveSubordinateDoc(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Получение информации о документе Спецификация счёта. Сам документ можно получить в HTML и PDF-формате (BillsItemSpecificationHTML, BillsItemSpecificationPdf) В ответе BillsItemSpecificationHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью BillsItemSpecificationUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
   * @summary Получение информации о документе Спецификация счёта
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemSpecification(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<BillsItemSpecification>;

  /**
   *
   * @summary Формирование HTML спецификации счёта по счету
   * @param {string} documentNumber
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemSpecificationHTML(documentNumber: string, withoutStampAndSign?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Формирование PDF спецификации счёта по счету
   * @param {string} documentNumber
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemSpecificationPdf(documentNumber: string, withoutStampAndSign?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Обновление текстовок и другой информации документа Спецификация счёта
   * @param {string} documentNumber
   * @param {BillsItemSpecificationUpdateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemSpecificationUpdate(
    documentNumber: string,
    request: BillsItemSpecificationUpdateRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<BillsItemSpecification>;

  /**
   * Обновление комментариев счёта
   * @param {string} documentNumber
   * @param {BillsItemUpdateCommentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemUpdateComments(
    documentNumber: string,
    request: BillsItemUpdateCommentsRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Обновление партнера счета
   * @param {string} documentNumber
   * @param {BillsItemUpdatePartnerRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemUpdatePartner(documentNumber: string, request: BillsItemUpdatePartnerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Обновление адреса партнера счета
   * @param {string} documentNumber
   * @param {BillsItemUpdatePartnerAddressRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemUpdatePartnerAddress(
    documentNumber: string,
    request: BillsItemUpdatePartnerAddressRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * deprecated Нужно использовать метод BillsItemUpdatePartner. Обновление плательщика счета
   * @param {string} documentNumber
   * @param {BillsItemUpdatePayerRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsItemUpdatePayer(documentNumber: string, request: BillsItemUpdatePayerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Справочник статусов отгрузки по счету
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsShipmentStates(options?: AxiosRequestConfig): AxiosPromise<BillsShipmentStatesResponse>;

  /**
   * Осуществить попытку мягкого резервирования по всему счёту или по товару со склада в счёте
   * @param {string} documentNumber
   * @param {BillsSoftReserveCalculateV2Request} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsSoftReserveCalculateV2(
    documentNumber: string,
    request: BillsSoftReserveCalculateV2Request,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Снять мягкие резервы
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsSoftReserveWithdraw(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Снять мягкие резервы по всему счёту или по товару со склада в счёте
   * @param {string} documentNumber
   * @param {BillsSoftReserveWithdrawV2Request} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsSoftReserveWithdrawV2(
    documentNumber: string,
    request: BillsSoftReserveWithdrawV2Request,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Справочник источников счета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsSources(options?: AxiosRequestConfig): AxiosPromise<BillsSourcesResponse>;

  /**
   *
   * @summary Справочник статусов счета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsStates(options?: AxiosRequestConfig): AxiosPromise<BillsStatesResponse>;

  /**
   *
   * @summary Цепочка документов к счету
   * @param {BillsThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsThread(request: BillsThreadRequest, options?: AxiosRequestConfig): AxiosPromise<BillsThreadResponse>;

  /**
   *
   * @summary Создать или обновить счёт по заявке
   * @param {BillsUpdateFromDealRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsUpdateFromDeal(request: BillsUpdateFromDealRequest, options?: AxiosRequestConfig): AxiosPromise<BillsUpdateFromDealResponse>;

  /**
   *
   * @summary Визуализировать эмуляцию снятия резерва по позиции в счёте
   * @param {string} documentNumber
   * @param {BillsVisualizeEmulatedWithdrawRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsVisualizeEmulatedWithdraw(
    documentNumber: string,
    request: BillsVisualizeEmulatedWithdrawRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<BillsVisualizeEmulatedWithdrawResponse>;

  /**
   *
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApiInterface
   */
  billsXmlPreview(documentNumber: string, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * BillApi - object-oriented interface
 * @export
 * @class BillApi
 * @extends {BaseAPI}
 */
export class BillApi extends BaseAPI implements BillApiInterface {
  /**
   *
   * @summary Осуществить попытку мягкого резервирования по счёту
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billSoftReserveCalculate(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billSoftReserveCalculate(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Вывод счета на приоритетную позицию в очереди на резерв
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billSoftReservePrioritize(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billSoftReservePrioritize(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить настройки мягкого резервирования по счёту
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billSoftReserveSettingsGet(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billSoftReserveSettingsGet(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновить настройки мягкого резервирования по счёту
   * @param {string} documentNumber
   * @param {BillSoftReserveSettingsUpdateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billSoftReserveSettingsUpdate(
    documentNumber: string,
    request: BillSoftReserveSettingsUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return BillApiFp(this.configuration)
      .billSoftReserveSettingsUpdate(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список счетов
   * @param {BillsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public bills(request: BillsRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .bills(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Активация счёта
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemActivate(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemActivate(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Деактивация счёта
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemDeactivate(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemDeactivate(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Зафиксировать изменение счёта
   * @param {string} documentNumber
   * @param {BillsItemEditPersistRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemEditPersist(documentNumber: string, request: BillsItemEditPersistRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemEditPersist(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Предпросмотр изменений счёта
   * @param {string} documentNumber
   * @param {BillsItemEditPreviewRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemEditPreview(documentNumber: string, request: BillsItemEditPreviewRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemEditPreview(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Получение информации о документе Счёт на оплату. Сам документ можно получить в HTML и PDF-формате (BillsItemInvoiceHTML, BillsItemInvoicePdf) В ответе BillsItemInvoiceHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью BillsItemInvoiceUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
   * @summary Получение информации о документе Счёт на оплату
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemInvoice(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemInvoice(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование HTML счета на оплату по счету
   * @param {string} documentNumber
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemInvoiceHTML(documentNumber: string, withoutStampAndSign?: boolean, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemInvoiceHTML(documentNumber, withoutStampAndSign, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование PDF счета на оплату по счету
   * @param {string} documentNumber
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemInvoicePdf(documentNumber: string, withoutStampAndSign?: boolean, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemInvoicePdf(documentNumber, withoutStampAndSign, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление текстовок и другой информации документа Счёт на оплату
   * @param {string} documentNumber
   * @param {BillsItemInvoiceUpdateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemInvoiceUpdate(documentNumber: string, request: BillsItemInvoiceUpdateRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemInvoiceUpdate(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список партнеров счёта
   * @param {string} documentNumber
   * @param {string} [typeCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemPartners(documentNumber: string, typeCode?: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemPartners(documentNumber, typeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Пролонгация счёта
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemProlong(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemProlong(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Фактурировать счёт
   * @param {string} documentNumber
   * @param {BillsItemReconcileRequest} [request]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemReconcile(documentNumber: string, request?: BillsItemReconcileRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemReconcile(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Снятие флага наличия подчиненных документов
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemRemoveSubordinateDoc(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemRemoveSubordinateDoc(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Получение информации о документе Спецификация счёта. Сам документ можно получить в HTML и PDF-формате (BillsItemSpecificationHTML, BillsItemSpecificationPdf) В ответе BillsItemSpecificationHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью BillsItemSpecificationUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
   * @summary Получение информации о документе Спецификация счёта
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemSpecification(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemSpecification(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование HTML спецификации счёта по счету
   * @param {string} documentNumber
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemSpecificationHTML(documentNumber: string, withoutStampAndSign?: boolean, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemSpecificationHTML(documentNumber, withoutStampAndSign, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование PDF спецификации счёта по счету
   * @param {string} documentNumber
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemSpecificationPdf(documentNumber: string, withoutStampAndSign?: boolean, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemSpecificationPdf(documentNumber, withoutStampAndSign, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление текстовок и другой информации документа Спецификация счёта
   * @param {string} documentNumber
   * @param {BillsItemSpecificationUpdateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemSpecificationUpdate(documentNumber: string, request: BillsItemSpecificationUpdateRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemSpecificationUpdate(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Обновление комментариев счёта
   * @param {string} documentNumber
   * @param {BillsItemUpdateCommentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemUpdateComments(documentNumber: string, request: BillsItemUpdateCommentsRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemUpdateComments(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Обновление партнера счета
   * @param {string} documentNumber
   * @param {BillsItemUpdatePartnerRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemUpdatePartner(documentNumber: string, request: BillsItemUpdatePartnerRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemUpdatePartner(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Обновление адреса партнера счета
   * @param {string} documentNumber
   * @param {BillsItemUpdatePartnerAddressRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemUpdatePartnerAddress(
    documentNumber: string,
    request: BillsItemUpdatePartnerAddressRequest,
    options?: AxiosRequestConfig
  ) {
    return BillApiFp(this.configuration)
      .billsItemUpdatePartnerAddress(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * deprecated Нужно использовать метод BillsItemUpdatePartner. Обновление плательщика счета
   * @param {string} documentNumber
   * @param {BillsItemUpdatePayerRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsItemUpdatePayer(documentNumber: string, request: BillsItemUpdatePayerRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsItemUpdatePayer(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник статусов отгрузки по счету
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsShipmentStates(options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsShipmentStates(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Осуществить попытку мягкого резервирования по всему счёту или по товару со склада в счёте
   * @param {string} documentNumber
   * @param {BillsSoftReserveCalculateV2Request} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsSoftReserveCalculateV2(documentNumber: string, request: BillsSoftReserveCalculateV2Request, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsSoftReserveCalculateV2(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Снять мягкие резервы
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsSoftReserveWithdraw(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsSoftReserveWithdraw(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Снять мягкие резервы по всему счёту или по товару со склада в счёте
   * @param {string} documentNumber
   * @param {BillsSoftReserveWithdrawV2Request} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsSoftReserveWithdrawV2(documentNumber: string, request: BillsSoftReserveWithdrawV2Request, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsSoftReserveWithdrawV2(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник источников счета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsSources(options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsSources(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник статусов счета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsStates(options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsStates(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Цепочка документов к счету
   * @param {BillsThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsThread(request: BillsThreadRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsThread(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создать или обновить счёт по заявке
   * @param {BillsUpdateFromDealRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsUpdateFromDeal(request: BillsUpdateFromDealRequest, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsUpdateFromDeal(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Визуализировать эмуляцию снятия резерва по позиции в счёте
   * @param {string} documentNumber
   * @param {BillsVisualizeEmulatedWithdrawRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsVisualizeEmulatedWithdraw(
    documentNumber: string,
    request: BillsVisualizeEmulatedWithdrawRequest,
    options?: AxiosRequestConfig
  ) {
    return BillApiFp(this.configuration)
      .billsVisualizeEmulatedWithdraw(documentNumber, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} documentNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillApi
   */
  public billsXmlPreview(documentNumber: string, options?: AxiosRequestConfig) {
    return BillApiFp(this.configuration)
      .billsXmlPreview(documentNumber, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CatalogApi - axios parameter creator
 * @export
 */
export const CatalogApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Запрос данных по сертификату
     * @param {string} [batchCode] Код партии
     * @param {string} [shipmentCode] Код отгрузки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogBatchCertificates: async (batchCode?: string, shipmentCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/catalog/batch/certificates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (batchCode !== undefined) {
        localVarQueryParameter['batchCode'] = batchCode;
      }

      if (shipmentCode !== undefined) {
        localVarQueryParameter['shipmentCode'] = shipmentCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Запрос содержимого раздела
     * @param {CatalogCategoriesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogCategories: async (request: CatalogCategoriesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogCategories', 'request', request);
      const localVarPath = `/catalog/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Выгрузка прайслиста
     * @param {string} categoryCode Код раздела
     * @param {string} [filters] Фильтрация для продуктов. См. getCatalogProducts
     * @param {string} [query] Строка для поиска товара
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [returnSet] Набор данных:   category - только разделы,   catalog - раздел каталога с необходимыми свойствами.   products - товары и базовые фильтры. Свойства будут запрашиваться отдельно. По умолчанию возвращаются все доступные данные - используется для выгрузки на сайт.
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogCategoriesItemPricelistXlsx: async (
      categoryCode: string,
      filters?: string,
      query?: string,
      manufacturer?: string,
      productType?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      sort?: string,
      branchOfficeCode?: string,
      returnSet?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryCode' is not null or undefined
      assertParamExists('catalogCategoriesItemPricelistXlsx', 'categoryCode', categoryCode);
      const localVarPath = `/catalog/categories/{categoryCode}/pricelist/xlsx`.replace(
        `{${'categoryCode'}}`,
        encodeURIComponent(String(categoryCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filters !== undefined) {
        localVarQueryParameter['filters'] = filters;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter['manufacturer'] = manufacturer;
      }

      if (productType !== undefined) {
        localVarQueryParameter['productType'] = productType;
      }

      if (baseSize !== undefined) {
        localVarQueryParameter['baseSize'] = baseSize;
      }

      if (warehouse !== undefined) {
        localVarQueryParameter['warehouse'] = warehouse;
      }

      if (stockRequired !== undefined) {
        localVarQueryParameter['stockRequired'] = stockRequired;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (returnSet !== undefined) {
        localVarQueryParameter['returnSet'] = returnSet;
      }

      if (searchMarker !== undefined) {
        localVarQueryParameter['searchMarker'] = searchMarker;
      }

      if (dealCode !== undefined) {
        localVarQueryParameter['dealCode'] = dealCode;
      }

      if (agreementCode !== undefined) {
        localVarQueryParameter['agreementCode'] = agreementCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Запрос содержимого раздела
     * @param {string} categoryCode Код раздела
     * @param {string} [filters] Фильтрация для продуктов. См. getCatalogProducts
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [returnSet] Набор данных:   category - только разделы,   catalog - раздел каталога с необходимыми свойствами.   products - товары и базовые фильтры. Свойства будут запрашиваться отдельно. По умолчанию возвращаются все доступные данные - используется для выгрузки на сайт.
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogCategoriesItemView: async (
      categoryCode: string,
      filters?: string,
      manufacturer?: string,
      productType?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      page?: number,
      count?: number,
      sort?: string,
      branchOfficeCode?: string,
      returnSet?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryCode' is not null or undefined
      assertParamExists('catalogCategoriesItemView', 'categoryCode', categoryCode);
      const localVarPath = `/catalog/categories/{categoryCode}/view`.replace(
        `{${'categoryCode'}}`,
        encodeURIComponent(String(categoryCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filters !== undefined) {
        localVarQueryParameter['filters'] = filters;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter['manufacturer'] = manufacturer;
      }

      if (productType !== undefined) {
        localVarQueryParameter['productType'] = productType;
      }

      if (baseSize !== undefined) {
        localVarQueryParameter['baseSize'] = baseSize;
      }

      if (warehouse !== undefined) {
        localVarQueryParameter['warehouse'] = warehouse;
      }

      if (stockRequired !== undefined) {
        localVarQueryParameter['stockRequired'] = stockRequired;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (returnSet !== undefined) {
        localVarQueryParameter['returnSet'] = returnSet;
      }

      if (searchMarker !== undefined) {
        localVarQueryParameter['searchMarker'] = searchMarker;
      }

      if (dealCode !== undefined) {
        localVarQueryParameter['dealCode'] = dealCode;
      }

      if (agreementCode !== undefined) {
        localVarQueryParameter['agreementCode'] = agreementCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список фильтров для выборки товаров
     * @param {CatalogFiltersRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogFilters: async (request: CatalogFiltersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogFilters', 'request', request);
      const localVarPath = `/catalog/filters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Данные номенклатуры по товару для аккордеона
     * @param {CatalogProductAccordionNomenclatureRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductAccordionNomenclature: async (
      request: CatalogProductAccordionNomenclatureRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductAccordionNomenclature', 'request', request);
      const localVarPath = `/catalog/product/accordion/nomenclature`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Резервы товара
     * @param {CatalogProductReservesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductReserves: async (request: CatalogProductReservesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductReserves', 'request', request);
      const localVarPath = `/catalog/product/reserves`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Резервы товара - статистика
     * @param {ProductReservesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductReservesStat: async (request: ProductReservesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductReservesStat', 'request', request);
      const localVarPath = `/catalog/product/reserves/stat`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Резервы товара v2
     * @param {ProductReservesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductReservesV2: async (request: ProductReservesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductReservesV2', 'request', request);
      const localVarPath = `/catalog/product/reserves/v2`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Остатки товара
     * @param {CatalogProductStocksRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductStocks: async (request: CatalogProductStocksRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductStocks', 'request', request);
      const localVarPath = `/catalog/product/stocks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Поиск товаров
     * @param {string} [code] Код товара
     * @param {string} [query] Строка для поиска товара
     * @param {string} [category] Раздел каталога
     * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProducts: async (
      code?: string,
      query?: string,
      category?: string,
      filters?: string,
      manufacturer?: string,
      productType?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      page?: number,
      count?: number,
      sort?: string,
      branchOfficeCode?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/catalog/products`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (category !== undefined) {
        localVarQueryParameter['category'] = category;
      }

      if (filters !== undefined) {
        localVarQueryParameter['filters'] = filters;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter['manufacturer'] = manufacturer;
      }

      if (productType !== undefined) {
        localVarQueryParameter['productType'] = productType;
      }

      if (baseSize !== undefined) {
        localVarQueryParameter['baseSize'] = baseSize;
      }

      if (warehouse !== undefined) {
        localVarQueryParameter['warehouse'] = warehouse;
      }

      if (stockRequired !== undefined) {
        localVarQueryParameter['stockRequired'] = stockRequired;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (sort !== undefined) {
        localVarQueryParameter['sort'] = sort;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (searchMarker !== undefined) {
        localVarQueryParameter['searchMarker'] = searchMarker;
      }

      if (dealCode !== undefined) {
        localVarQueryParameter['dealCode'] = dealCode;
      }

      if (agreementCode !== undefined) {
        localVarQueryParameter['agreementCode'] = agreementCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Альтернативы для товаров
     * @param {CatalogProductsAlternativesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsAlternatives: async (
      request: CatalogProductsAlternativesRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductsAlternatives', 'request', request);
      const localVarPath = `/catalog/products/alternatives`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Расширенная информация по продуктам, догружаемая после получения списка товаров
     * @param {CatalogProductsExtensionsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsExtensions: async (
      request: CatalogProductsExtensionsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductsExtensions', 'request', request);
      const localVarPath = `/catalog/products/extensions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Быстрый поиск товаров
     * @param {CatalogProductsFastSearchRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsFastSearch: async (
      request: CatalogProductsFastSearchRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductsFastSearch', 'request', request);
      const localVarPath = `/catalog/products/fastSearch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Просмотр страницы товара
     * @param {string} productCode Код товара
     * @param {string} [category] Раздел каталога
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItem: async (
      productCode: string,
      category?: string,
      warehouse?: string,
      branchOfficeCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'productCode' is not null or undefined
      assertParamExists('catalogProductsItem', 'productCode', productCode);
      const localVarPath = `/catalog/products/{productCode}`.replace(`{${'productCode'}}`, encodeURIComponent(String(productCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (category !== undefined) {
        localVarQueryParameter['category'] = category;
      }

      if (warehouse !== undefined) {
        localVarQueryParameter['warehouse'] = warehouse;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Информация о МРЦ товара
     * @param {string} productCode
     * @param {string} branchOfficeCode
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemBranchOfficeItemMrc: async (
      productCode: string,
      branchOfficeCode: string,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'productCode' is not null or undefined
      assertParamExists('catalogProductsItemBranchOfficeItemMrc', 'productCode', productCode);
      // verify required parameter 'branchOfficeCode' is not null or undefined
      assertParamExists('catalogProductsItemBranchOfficeItemMrc', 'branchOfficeCode', branchOfficeCode);
      const localVarPath = `/catalog/products/{productCode}/branchOffice/{branchOfficeCode}/mrc`
        .replace(`{${'productCode'}}`, encodeURIComponent(String(productCode)))
        .replace(`{${'branchOfficeCode'}}`, encodeURIComponent(String(branchOfficeCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузка изображения к товару
     * @param {string} productCode
     * @param {string} imageKey Номер или тип изображения
     * @param {any} [image] Изображение в формате png/jpeg/gif
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemImagesItemUpload: async (
      productCode: string,
      imageKey: string,
      image?: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'productCode' is not null or undefined
      assertParamExists('catalogProductsItemImagesItemUpload', 'productCode', productCode);
      // verify required parameter 'imageKey' is not null or undefined
      assertParamExists('catalogProductsItemImagesItemUpload', 'imageKey', imageKey);
      const localVarPath = `/catalog/products/{productCode}/images/{imageKey}/upload`
        .replace(`{${'productCode'}}`, encodeURIComponent(String(productCode)))
        .replace(`{${'imageKey'}}`, encodeURIComponent(String(imageKey)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * В частности используется на странице сделки для вывода доступных производителей к товару. Устарело, следует использовать DealsItemManufacturers.
     * @summary Список производителей для материала
     * @param {string} productCode
     * @param {string} [dealCode] Код сделки для исключения уже использованных остатков
     * @param {string} [positionCode] Позиция сделки для исключения уже использованных остатков
     * @param {string} [warehouse] Склад отгрузки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemManufacturers: async (
      productCode: string,
      dealCode?: string,
      positionCode?: string,
      warehouse?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'productCode' is not null or undefined
      assertParamExists('catalogProductsItemManufacturers', 'productCode', productCode);
      const localVarPath = `/catalog/products/{productCode}/manufacturers`.replace(
        `{${'productCode'}}`,
        encodeURIComponent(String(productCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dealCode !== undefined) {
        localVarQueryParameter['dealCode'] = dealCode;
      }

      if (positionCode !== undefined) {
        localVarQueryParameter['positionCode'] = positionCode;
      }

      if (warehouse !== undefined) {
        localVarQueryParameter['warehouse'] = warehouse;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Информация о пачках (или рулонах) на остатках склада
     * @param {string} productCode
     * @param {string} [warehouse]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemPacks: async (
      productCode: string,
      warehouse?: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'productCode' is not null or undefined
      assertParamExists('catalogProductsItemPacks', 'productCode', productCode);
      const localVarPath = `/catalog/products/{productCode}/packs`.replace(`{${'productCode'}}`, encodeURIComponent(String(productCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (warehouse !== undefined) {
        localVarQueryParameter['warehouse'] = warehouse;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Доступные услуги для товара
     * @param {string} productCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemServices: async (productCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'productCode' is not null or undefined
      assertParamExists('catalogProductsItemServices', 'productCode', productCode);
      const localVarPath = `/catalog/products/{productCode}/services`.replace(
        `{${'productCode'}}`,
        encodeURIComponent(String(productCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список отгрузок по товару
     * @param {string} productCode
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemShipments: async (
      productCode: string,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'productCode' is not null or undefined
      assertParamExists('catalogProductsItemShipments', 'productCode', productCode);
      const localVarPath = `/catalog/products/{productCode}/shipments`.replace(
        `{${'productCode'}}`,
        encodeURIComponent(String(productCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список товаров
     * @param {CatalogProductsListRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsList: async (request: CatalogProductsListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductsList', 'request', request);
      const localVarPath = `/catalog/products/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Расширенный отчет по товарам
     * @param {CatalogProductsReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsReport: async (request: CatalogProductsReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductsReport', 'request', request);
      const localVarPath = `/catalog/products/report`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Расширенный отчет по товарам
     * @param {CatalogProductsReportXlsxRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsReportXlsx: async (
      request: CatalogProductsReportXlsxRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('catalogProductsReportXlsx', 'request', request);
      const localVarPath = `/catalog/products/report/xlsx`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Фильтры товаров для поиска
     * @param {string} [code] Код товара
     * @param {string} [query] Строка для поиска товара
     * @param {string} [category] Раздел каталога
     * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsSearchFilter: async (
      code?: string,
      query?: string,
      category?: string,
      filters?: string,
      manufacturer?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      branchOfficeCode?: string,
      productType?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/catalog/products/search/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (category !== undefined) {
        localVarQueryParameter['category'] = category;
      }

      if (filters !== undefined) {
        localVarQueryParameter['filters'] = filters;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter['manufacturer'] = manufacturer;
      }

      if (baseSize !== undefined) {
        localVarQueryParameter['baseSize'] = baseSize;
      }

      if (warehouse !== undefined) {
        localVarQueryParameter['warehouse'] = warehouse;
      }

      if (stockRequired !== undefined) {
        localVarQueryParameter['stockRequired'] = stockRequired;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (productType !== undefined) {
        localVarQueryParameter['productType'] = productType;
      }

      if (searchMarker !== undefined) {
        localVarQueryParameter['searchMarker'] = searchMarker;
      }

      if (dealCode !== undefined) {
        localVarQueryParameter['dealCode'] = dealCode;
      }

      if (agreementCode !== undefined) {
        localVarQueryParameter['agreementCode'] = agreementCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Графики по ценам конкурентов
     * @param {string} productCode
     * @param {string} [competitorCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsStatsCompetitorsPlot: async (
      productCode: string,
      competitorCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'productCode' is not null or undefined
      assertParamExists('catalogProductsStatsCompetitorsPlot', 'productCode', productCode);
      const localVarPath = `/catalog/products/stats/competitors/plot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (productCode !== undefined) {
        localVarQueryParameter['productCode'] = productCode;
      }

      if (competitorCode !== undefined) {
        localVarQueryParameter['competitorCode'] = competitorCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Графики цен и отгрузок по товару
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsStatsPlot: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('catalogProductsStatsPlot', 'code', code);
      const localVarPath = `/catalog/products/stats/plot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Запрос главной страницы каталога
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [stockRequired] Только с остатками
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogRoot: async (
      warehouse?: string,
      branchOfficeCode?: string,
      stockRequired?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/catalog/root`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (warehouse !== undefined) {
        localVarQueryParameter['warehouse'] = warehouse;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (stockRequired !== undefined) {
        localVarQueryParameter['stockRequired'] = stockRequired;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список будет зависеть от филиала текущего МОП.
     * @summary Список доступных складов
     * @param {string} [branchOfficeCode] Код филиала, если нужен список складов не от текущего филиала сотрудника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogWarehouses: async (branchOfficeCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/catalog/warehouses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CatalogApi - functional programming interface
 * @export
 */
export const CatalogApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CatalogApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Запрос данных по сертификату
     * @param {string} [batchCode] Код партии
     * @param {string} [shipmentCode] Код отгрузки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogBatchCertificates(
      batchCode?: string,
      shipmentCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogBatchCertificatesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogBatchCertificates(batchCode, shipmentCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Запрос содержимого раздела
     * @param {CatalogCategoriesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogCategories(
      request: CatalogCategoriesRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogCategoriesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogCategories(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Выгрузка прайслиста
     * @param {string} categoryCode Код раздела
     * @param {string} [filters] Фильтрация для продуктов. См. getCatalogProducts
     * @param {string} [query] Строка для поиска товара
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [returnSet] Набор данных:   category - только разделы,   catalog - раздел каталога с необходимыми свойствами.   products - товары и базовые фильтры. Свойства будут запрашиваться отдельно. По умолчанию возвращаются все доступные данные - используется для выгрузки на сайт.
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogCategoriesItemPricelistXlsx(
      categoryCode: string,
      filters?: string,
      query?: string,
      manufacturer?: string,
      productType?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      sort?: string,
      branchOfficeCode?: string,
      returnSet?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogCategoriesItemPricelistXlsx(
        categoryCode,
        filters,
        query,
        manufacturer,
        productType,
        baseSize,
        warehouse,
        stockRequired,
        sort,
        branchOfficeCode,
        returnSet,
        searchMarker,
        dealCode,
        agreementCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Запрос содержимого раздела
     * @param {string} categoryCode Код раздела
     * @param {string} [filters] Фильтрация для продуктов. См. getCatalogProducts
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [returnSet] Набор данных:   category - только разделы,   catalog - раздел каталога с необходимыми свойствами.   products - товары и базовые фильтры. Свойства будут запрашиваться отдельно. По умолчанию возвращаются все доступные данные - используется для выгрузки на сайт.
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogCategoriesItemView(
      categoryCode: string,
      filters?: string,
      manufacturer?: string,
      productType?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      page?: number,
      count?: number,
      sort?: string,
      branchOfficeCode?: string,
      returnSet?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogCategoriesItemViewResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogCategoriesItemView(
        categoryCode,
        filters,
        manufacturer,
        productType,
        baseSize,
        warehouse,
        stockRequired,
        page,
        count,
        sort,
        branchOfficeCode,
        returnSet,
        searchMarker,
        dealCode,
        agreementCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список фильтров для выборки товаров
     * @param {CatalogFiltersRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogFilters(
      request: CatalogFiltersRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogFiltersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogFilters(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Данные номенклатуры по товару для аккордеона
     * @param {CatalogProductAccordionNomenclatureRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductAccordionNomenclature(
      request: CatalogProductAccordionNomenclatureRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductAccordionNomenclatureResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductAccordionNomenclature(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Резервы товара
     * @param {CatalogProductReservesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductReserves(
      request: CatalogProductReservesRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductReservesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductReserves(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Резервы товара - статистика
     * @param {ProductReservesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductReservesStat(
      request: ProductReservesRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductReservesStatResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductReservesStat(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Резервы товара v2
     * @param {ProductReservesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductReservesV2(
      request: ProductReservesRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductReservesV2Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductReservesV2(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Остатки товара
     * @param {CatalogProductStocksRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductStocks(
      request: CatalogProductStocksRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductStocksResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductStocks(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Поиск товаров
     * @param {string} [code] Код товара
     * @param {string} [query] Строка для поиска товара
     * @param {string} [category] Раздел каталога
     * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProducts(
      code?: string,
      query?: string,
      category?: string,
      filters?: string,
      manufacturer?: string,
      productType?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      page?: number,
      count?: number,
      sort?: string,
      branchOfficeCode?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProducts(
        code,
        query,
        category,
        filters,
        manufacturer,
        productType,
        baseSize,
        warehouse,
        stockRequired,
        page,
        count,
        sort,
        branchOfficeCode,
        searchMarker,
        dealCode,
        agreementCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Альтернативы для товаров
     * @param {CatalogProductsAlternativesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsAlternatives(
      request: CatalogProductsAlternativesRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsAlternativesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsAlternatives(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Расширенная информация по продуктам, догружаемая после получения списка товаров
     * @param {CatalogProductsExtensionsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsExtensions(
      request: CatalogProductsExtensionsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsExtensionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsExtensions(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Быстрый поиск товаров
     * @param {CatalogProductsFastSearchRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsFastSearch(
      request: CatalogProductsFastSearchRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsFastSearchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsFastSearch(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Просмотр страницы товара
     * @param {string} productCode Код товара
     * @param {string} [category] Раздел каталога
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsItem(
      productCode: string,
      category?: string,
      warehouse?: string,
      branchOfficeCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsItemResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsItem(
        productCode,
        category,
        warehouse,
        branchOfficeCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Информация о МРЦ товара
     * @param {string} productCode
     * @param {string} branchOfficeCode
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsItemBranchOfficeItemMrc(
      productCode: string,
      branchOfficeCode: string,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsItemBranchOfficeItemMrcResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsItemBranchOfficeItemMrc(
        productCode,
        branchOfficeCode,
        date,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Загрузка изображения к товару
     * @param {string} productCode
     * @param {string} imageKey Номер или тип изображения
     * @param {any} [image] Изображение в формате png/jpeg/gif
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsItemImagesItemUpload(
      productCode: string,
      imageKey: string,
      image?: any,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsItemImagesItemUploadResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsItemImagesItemUpload(productCode, imageKey, image, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * В частности используется на странице сделки для вывода доступных производителей к товару. Устарело, следует использовать DealsItemManufacturers.
     * @summary Список производителей для материала
     * @param {string} productCode
     * @param {string} [dealCode] Код сделки для исключения уже использованных остатков
     * @param {string} [positionCode] Позиция сделки для исключения уже использованных остатков
     * @param {string} [warehouse] Склад отгрузки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsItemManufacturers(
      productCode: string,
      dealCode?: string,
      positionCode?: string,
      warehouse?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsItemManufacturersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsItemManufacturers(
        productCode,
        dealCode,
        positionCode,
        warehouse,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Информация о пачках (или рулонах) на остатках склада
     * @param {string} productCode
     * @param {string} [warehouse]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsItemPacks(
      productCode: string,
      warehouse?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsItemPacksResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsItemPacks(productCode, warehouse, page, count, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Доступные услуги для товара
     * @param {string} productCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsItemServices(
      productCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsItemServicesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsItemServices(productCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список отгрузок по товару
     * @param {string} productCode
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsItemShipments(
      productCode: string,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsItemShipmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsItemShipments(
        productCode,
        dateFrom,
        dateTo,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список товаров
     * @param {CatalogProductsListRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsList(
      request: CatalogProductsListRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsList(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Расширенный отчет по товарам
     * @param {CatalogProductsReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsReport(
      request: CatalogProductsReportRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsReportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsReport(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Расширенный отчет по товарам
     * @param {CatalogProductsReportXlsxRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsReportXlsx(
      request: CatalogProductsReportXlsxRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsReportXlsx(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Фильтры товаров для поиска
     * @param {string} [code] Код товара
     * @param {string} [query] Строка для поиска товара
     * @param {string} [category] Раздел каталога
     * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsSearchFilter(
      code?: string,
      query?: string,
      category?: string,
      filters?: string,
      manufacturer?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      branchOfficeCode?: string,
      productType?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsSearchFilterResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsSearchFilter(
        code,
        query,
        category,
        filters,
        manufacturer,
        baseSize,
        warehouse,
        stockRequired,
        branchOfficeCode,
        productType,
        searchMarker,
        dealCode,
        agreementCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Графики по ценам конкурентов
     * @param {string} productCode
     * @param {string} [competitorCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsStatsCompetitorsPlot(
      productCode: string,
      competitorCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsStatsCompetitorsPlotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsStatsCompetitorsPlot(productCode, competitorCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Графики цен и отгрузок по товару
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsStatsPlot(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsStatsPlotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsStatsPlot(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Запрос главной страницы каталога
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [stockRequired] Только с остатками
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogRoot(
      warehouse?: string,
      branchOfficeCode?: string,
      stockRequired?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogRootResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogRoot(warehouse, branchOfficeCode, stockRequired, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список будет зависеть от филиала текущего МОП.
     * @summary Список доступных складов
     * @param {string} [branchOfficeCode] Код филиала, если нужен список складов не от текущего филиала сотрудника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogWarehouses(
      branchOfficeCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogWarehousesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogWarehouses(branchOfficeCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CatalogApi - factory interface
 * @export
 */
export const CatalogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CatalogApiFp(configuration);
  return {
    /**
     *
     * @summary Запрос данных по сертификату
     * @param {string} [batchCode] Код партии
     * @param {string} [shipmentCode] Код отгрузки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogBatchCertificates(batchCode?: string, shipmentCode?: string, options?: any): AxiosPromise<CatalogBatchCertificatesResponse> {
      return localVarFp.catalogBatchCertificates(batchCode, shipmentCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Запрос содержимого раздела
     * @param {CatalogCategoriesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogCategories(request: CatalogCategoriesRequest, options?: any): AxiosPromise<CatalogCategoriesResponse> {
      return localVarFp.catalogCategories(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Выгрузка прайслиста
     * @param {string} categoryCode Код раздела
     * @param {string} [filters] Фильтрация для продуктов. См. getCatalogProducts
     * @param {string} [query] Строка для поиска товара
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [returnSet] Набор данных:   category - только разделы,   catalog - раздел каталога с необходимыми свойствами.   products - товары и базовые фильтры. Свойства будут запрашиваться отдельно. По умолчанию возвращаются все доступные данные - используется для выгрузки на сайт.
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogCategoriesItemPricelistXlsx(
      categoryCode: string,
      filters?: string,
      query?: string,
      manufacturer?: string,
      productType?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      sort?: string,
      branchOfficeCode?: string,
      returnSet?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .catalogCategoriesItemPricelistXlsx(
          categoryCode,
          filters,
          query,
          manufacturer,
          productType,
          baseSize,
          warehouse,
          stockRequired,
          sort,
          branchOfficeCode,
          returnSet,
          searchMarker,
          dealCode,
          agreementCode,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Запрос содержимого раздела
     * @param {string} categoryCode Код раздела
     * @param {string} [filters] Фильтрация для продуктов. См. getCatalogProducts
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [returnSet] Набор данных:   category - только разделы,   catalog - раздел каталога с необходимыми свойствами.   products - товары и базовые фильтры. Свойства будут запрашиваться отдельно. По умолчанию возвращаются все доступные данные - используется для выгрузки на сайт.
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogCategoriesItemView(
      categoryCode: string,
      filters?: string,
      manufacturer?: string,
      productType?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      page?: number,
      count?: number,
      sort?: string,
      branchOfficeCode?: string,
      returnSet?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options?: any
    ): AxiosPromise<CatalogCategoriesItemViewResponse> {
      return localVarFp
        .catalogCategoriesItemView(
          categoryCode,
          filters,
          manufacturer,
          productType,
          baseSize,
          warehouse,
          stockRequired,
          page,
          count,
          sort,
          branchOfficeCode,
          returnSet,
          searchMarker,
          dealCode,
          agreementCode,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список фильтров для выборки товаров
     * @param {CatalogFiltersRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogFilters(request: CatalogFiltersRequest, options?: any): AxiosPromise<CatalogFiltersResponse> {
      return localVarFp.catalogFilters(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Данные номенклатуры по товару для аккордеона
     * @param {CatalogProductAccordionNomenclatureRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductAccordionNomenclature(
      request: CatalogProductAccordionNomenclatureRequest,
      options?: any
    ): AxiosPromise<CatalogProductAccordionNomenclatureResponse> {
      return localVarFp.catalogProductAccordionNomenclature(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Резервы товара
     * @param {CatalogProductReservesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductReserves(request: CatalogProductReservesRequest, options?: any): AxiosPromise<CatalogProductReservesResponse> {
      return localVarFp.catalogProductReserves(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Резервы товара - статистика
     * @param {ProductReservesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductReservesStat(request: ProductReservesRequest, options?: any): AxiosPromise<CatalogProductReservesStatResponse> {
      return localVarFp.catalogProductReservesStat(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Резервы товара v2
     * @param {ProductReservesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductReservesV2(request: ProductReservesRequest, options?: any): AxiosPromise<CatalogProductReservesV2Response> {
      return localVarFp.catalogProductReservesV2(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Остатки товара
     * @param {CatalogProductStocksRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductStocks(request: CatalogProductStocksRequest, options?: any): AxiosPromise<CatalogProductStocksResponse> {
      return localVarFp.catalogProductStocks(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Поиск товаров
     * @param {string} [code] Код товара
     * @param {string} [query] Строка для поиска товара
     * @param {string} [category] Раздел каталога
     * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProducts(
      code?: string,
      query?: string,
      category?: string,
      filters?: string,
      manufacturer?: string,
      productType?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      page?: number,
      count?: number,
      sort?: string,
      branchOfficeCode?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options?: any
    ): AxiosPromise<CatalogProductsResponse> {
      return localVarFp
        .catalogProducts(
          code,
          query,
          category,
          filters,
          manufacturer,
          productType,
          baseSize,
          warehouse,
          stockRequired,
          page,
          count,
          sort,
          branchOfficeCode,
          searchMarker,
          dealCode,
          agreementCode,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Альтернативы для товаров
     * @param {CatalogProductsAlternativesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsAlternatives(
      request: CatalogProductsAlternativesRequest,
      options?: any
    ): AxiosPromise<CatalogProductsAlternativesResponse> {
      return localVarFp.catalogProductsAlternatives(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Расширенная информация по продуктам, догружаемая после получения списка товаров
     * @param {CatalogProductsExtensionsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsExtensions(request: CatalogProductsExtensionsRequest, options?: any): AxiosPromise<CatalogProductsExtensionsResponse> {
      return localVarFp.catalogProductsExtensions(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Быстрый поиск товаров
     * @param {CatalogProductsFastSearchRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsFastSearch(request: CatalogProductsFastSearchRequest, options?: any): AxiosPromise<CatalogProductsFastSearchResponse> {
      return localVarFp.catalogProductsFastSearch(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Просмотр страницы товара
     * @param {string} productCode Код товара
     * @param {string} [category] Раздел каталога
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItem(
      productCode: string,
      category?: string,
      warehouse?: string,
      branchOfficeCode?: string,
      options?: any
    ): AxiosPromise<CatalogProductsItemResponse> {
      return localVarFp
        .catalogProductsItem(productCode, category, warehouse, branchOfficeCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Информация о МРЦ товара
     * @param {string} productCode
     * @param {string} branchOfficeCode
     * @param {string} [date]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemBranchOfficeItemMrc(
      productCode: string,
      branchOfficeCode: string,
      date?: string,
      options?: any
    ): AxiosPromise<CatalogProductsItemBranchOfficeItemMrcResponse> {
      return localVarFp
        .catalogProductsItemBranchOfficeItemMrc(productCode, branchOfficeCode, date, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузка изображения к товару
     * @param {string} productCode
     * @param {string} imageKey Номер или тип изображения
     * @param {any} [image] Изображение в формате png/jpeg/gif
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemImagesItemUpload(
      productCode: string,
      imageKey: string,
      image?: any,
      options?: any
    ): AxiosPromise<CatalogProductsItemImagesItemUploadResponse> {
      return localVarFp
        .catalogProductsItemImagesItemUpload(productCode, imageKey, image, options)
        .then(request => request(axios, basePath));
    },
    /**
     * В частности используется на странице сделки для вывода доступных производителей к товару. Устарело, следует использовать DealsItemManufacturers.
     * @summary Список производителей для материала
     * @param {string} productCode
     * @param {string} [dealCode] Код сделки для исключения уже использованных остатков
     * @param {string} [positionCode] Позиция сделки для исключения уже использованных остатков
     * @param {string} [warehouse] Склад отгрузки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemManufacturers(
      productCode: string,
      dealCode?: string,
      positionCode?: string,
      warehouse?: string,
      options?: any
    ): AxiosPromise<CatalogProductsItemManufacturersResponse> {
      return localVarFp
        .catalogProductsItemManufacturers(productCode, dealCode, positionCode, warehouse, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Информация о пачках (или рулонах) на остатках склада
     * @param {string} productCode
     * @param {string} [warehouse]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemPacks(
      productCode: string,
      warehouse?: string,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<CatalogProductsItemPacksResponse> {
      return localVarFp.catalogProductsItemPacks(productCode, warehouse, page, count, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Доступные услуги для товара
     * @param {string} productCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemServices(productCode: string, options?: any): AxiosPromise<CatalogProductsItemServicesResponse> {
      return localVarFp.catalogProductsItemServices(productCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список отгрузок по товару
     * @param {string} productCode
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsItemShipments(
      productCode: string,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<CatalogProductsItemShipmentsResponse> {
      return localVarFp
        .catalogProductsItemShipments(productCode, dateFrom, dateTo, page, count, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список товаров
     * @param {CatalogProductsListRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsList(request: CatalogProductsListRequest, options?: any): AxiosPromise<CatalogProductsListResponse> {
      return localVarFp.catalogProductsList(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Расширенный отчет по товарам
     * @param {CatalogProductsReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsReport(request: CatalogProductsReportRequest, options?: any): AxiosPromise<CatalogProductsReportResponse> {
      return localVarFp.catalogProductsReport(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Расширенный отчет по товарам
     * @param {CatalogProductsReportXlsxRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsReportXlsx(request: CatalogProductsReportXlsxRequest, options?: any): AxiosPromise<object> {
      return localVarFp.catalogProductsReportXlsx(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Фильтры товаров для поиска
     * @param {string} [code] Код товара
     * @param {string} [query] Строка для поиска товара
     * @param {string} [category] Раздел каталога
     * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsSearchFilter(
      code?: string,
      query?: string,
      category?: string,
      filters?: string,
      manufacturer?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      branchOfficeCode?: string,
      productType?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options?: any
    ): AxiosPromise<CatalogProductsSearchFilterResponse> {
      return localVarFp
        .catalogProductsSearchFilter(
          code,
          query,
          category,
          filters,
          manufacturer,
          baseSize,
          warehouse,
          stockRequired,
          branchOfficeCode,
          productType,
          searchMarker,
          dealCode,
          agreementCode,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Графики по ценам конкурентов
     * @param {string} productCode
     * @param {string} [competitorCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsStatsCompetitorsPlot(
      productCode: string,
      competitorCode?: string,
      options?: any
    ): AxiosPromise<CatalogProductsStatsCompetitorsPlotResponse> {
      return localVarFp.catalogProductsStatsCompetitorsPlot(productCode, competitorCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Графики цен и отгрузок по товару
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsStatsPlot(code: string, options?: any): AxiosPromise<CatalogProductsStatsPlotResponse> {
      return localVarFp.catalogProductsStatsPlot(code, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Запрос главной страницы каталога
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [stockRequired] Только с остатками
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogRoot(warehouse?: string, branchOfficeCode?: string, stockRequired?: string, options?: any): AxiosPromise<CatalogRootResponse> {
      return localVarFp.catalogRoot(warehouse, branchOfficeCode, stockRequired, options).then(request => request(axios, basePath));
    },
    /**
     * Список будет зависеть от филиала текущего МОП.
     * @summary Список доступных складов
     * @param {string} [branchOfficeCode] Код филиала, если нужен список складов не от текущего филиала сотрудника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogWarehouses(branchOfficeCode?: string, options?: any): AxiosPromise<CatalogWarehousesResponse> {
      return localVarFp.catalogWarehouses(branchOfficeCode, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CatalogApi - interface
 * @export
 * @interface CatalogApi
 */
export interface CatalogApiInterface {
  /**
   *
   * @summary Запрос данных по сертификату
   * @param {string} [batchCode] Код партии
   * @param {string} [shipmentCode] Код отгрузки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogBatchCertificates(
    batchCode?: string,
    shipmentCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogBatchCertificatesResponse>;

  /**
   *
   * @summary Запрос содержимого раздела
   * @param {CatalogCategoriesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogCategories(request: CatalogCategoriesRequest, options?: AxiosRequestConfig): AxiosPromise<CatalogCategoriesResponse>;

  /**
   *
   * @summary Выгрузка прайслиста
   * @param {string} categoryCode Код раздела
   * @param {string} [filters] Фильтрация для продуктов. См. getCatalogProducts
   * @param {string} [query] Строка для поиска товара
   * @param {string} [manufacturer] Фильтр по производителю
   * @param {string} [productType] Фильтр по виду товара
   * @param {string} [baseSize] Фильтр по базовому размеру
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [stockRequired] Только с остатками
   * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [returnSet] Набор данных:   category - только разделы,   catalog - раздел каталога с необходимыми свойствами.   products - товары и базовые фильтры. Свойства будут запрашиваться отдельно. По умолчанию возвращаются все доступные данные - используется для выгрузки на сайт.
   * @param {string} [searchMarker]
   * @param {string} [dealCode] Код сделки
   * @param {string} [agreementCode] Код соглашения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogCategoriesItemPricelistXlsx(
    categoryCode: string,
    filters?: string,
    query?: string,
    manufacturer?: string,
    productType?: string,
    baseSize?: string,
    warehouse?: string,
    stockRequired?: string,
    sort?: string,
    branchOfficeCode?: string,
    returnSet?: string,
    searchMarker?: string,
    dealCode?: string,
    agreementCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<object>;

  /**
   *
   * @summary Запрос содержимого раздела
   * @param {string} categoryCode Код раздела
   * @param {string} [filters] Фильтрация для продуктов. См. getCatalogProducts
   * @param {string} [manufacturer] Фильтр по производителю
   * @param {string} [productType] Фильтр по виду товара
   * @param {string} [baseSize] Фильтр по базовому размеру
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [stockRequired] Только с остатками
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [returnSet] Набор данных:   category - только разделы,   catalog - раздел каталога с необходимыми свойствами.   products - товары и базовые фильтры. Свойства будут запрашиваться отдельно. По умолчанию возвращаются все доступные данные - используется для выгрузки на сайт.
   * @param {string} [searchMarker]
   * @param {string} [dealCode] Код сделки
   * @param {string} [agreementCode] Код соглашения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogCategoriesItemView(
    categoryCode: string,
    filters?: string,
    manufacturer?: string,
    productType?: string,
    baseSize?: string,
    warehouse?: string,
    stockRequired?: string,
    page?: number,
    count?: number,
    sort?: string,
    branchOfficeCode?: string,
    returnSet?: string,
    searchMarker?: string,
    dealCode?: string,
    agreementCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogCategoriesItemViewResponse>;

  /**
   *
   * @summary Список фильтров для выборки товаров
   * @param {CatalogFiltersRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogFilters(request: CatalogFiltersRequest, options?: AxiosRequestConfig): AxiosPromise<CatalogFiltersResponse>;

  /**
   *
   * @summary Данные номенклатуры по товару для аккордеона
   * @param {CatalogProductAccordionNomenclatureRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductAccordionNomenclature(
    request: CatalogProductAccordionNomenclatureRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductAccordionNomenclatureResponse>;

  /**
   *
   * @summary Резервы товара
   * @param {CatalogProductReservesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductReserves(
    request: CatalogProductReservesRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductReservesResponse>;

  /**
   *
   * @summary Резервы товара - статистика
   * @param {ProductReservesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductReservesStat(
    request: ProductReservesRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductReservesStatResponse>;

  /**
   *
   * @summary Резервы товара v2
   * @param {ProductReservesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductReservesV2(request: ProductReservesRequest, options?: AxiosRequestConfig): AxiosPromise<CatalogProductReservesV2Response>;

  /**
   *
   * @summary Остатки товара
   * @param {CatalogProductStocksRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductStocks(request: CatalogProductStocksRequest, options?: AxiosRequestConfig): AxiosPromise<CatalogProductStocksResponse>;

  /**
   *
   * @summary Поиск товаров
   * @param {string} [code] Код товара
   * @param {string} [query] Строка для поиска товара
   * @param {string} [category] Раздел каталога
   * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
   * @param {string} [manufacturer] Фильтр по производителю
   * @param {string} [productType] Фильтр по виду товара
   * @param {string} [baseSize] Фильтр по базовому размеру
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [stockRequired] Только с остатками
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [searchMarker]
   * @param {string} [dealCode] Код сделки
   * @param {string} [agreementCode] Код соглашения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProducts(
    code?: string,
    query?: string,
    category?: string,
    filters?: string,
    manufacturer?: string,
    productType?: string,
    baseSize?: string,
    warehouse?: string,
    stockRequired?: string,
    page?: number,
    count?: number,
    sort?: string,
    branchOfficeCode?: string,
    searchMarker?: string,
    dealCode?: string,
    agreementCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsResponse>;

  /**
   *
   * @summary Альтернативы для товаров
   * @param {CatalogProductsAlternativesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsAlternatives(
    request: CatalogProductsAlternativesRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsAlternativesResponse>;

  /**
   *
   * @summary Расширенная информация по продуктам, догружаемая после получения списка товаров
   * @param {CatalogProductsExtensionsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsExtensions(
    request: CatalogProductsExtensionsRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsExtensionsResponse>;

  /**
   *
   * @summary Быстрый поиск товаров
   * @param {CatalogProductsFastSearchRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsFastSearch(
    request: CatalogProductsFastSearchRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsFastSearchResponse>;

  /**
   *
   * @summary Просмотр страницы товара
   * @param {string} productCode Код товара
   * @param {string} [category] Раздел каталога
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsItem(
    productCode: string,
    category?: string,
    warehouse?: string,
    branchOfficeCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsItemResponse>;

  /**
   *
   * @summary Информация о МРЦ товара
   * @param {string} productCode
   * @param {string} branchOfficeCode
   * @param {string} [date]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsItemBranchOfficeItemMrc(
    productCode: string,
    branchOfficeCode: string,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsItemBranchOfficeItemMrcResponse>;

  /**
   *
   * @summary Загрузка изображения к товару
   * @param {string} productCode
   * @param {string} imageKey Номер или тип изображения
   * @param {any} [image] Изображение в формате png/jpeg/gif
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsItemImagesItemUpload(
    productCode: string,
    imageKey: string,
    image?: any,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsItemImagesItemUploadResponse>;

  /**
   * В частности используется на странице сделки для вывода доступных производителей к товару. Устарело, следует использовать DealsItemManufacturers.
   * @summary Список производителей для материала
   * @param {string} productCode
   * @param {string} [dealCode] Код сделки для исключения уже использованных остатков
   * @param {string} [positionCode] Позиция сделки для исключения уже использованных остатков
   * @param {string} [warehouse] Склад отгрузки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsItemManufacturers(
    productCode: string,
    dealCode?: string,
    positionCode?: string,
    warehouse?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsItemManufacturersResponse>;

  /**
   *
   * @summary Информация о пачках (или рулонах) на остатках склада
   * @param {string} productCode
   * @param {string} [warehouse]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsItemPacks(
    productCode: string,
    warehouse?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsItemPacksResponse>;

  /**
   *
   * @summary Доступные услуги для товара
   * @param {string} productCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsItemServices(productCode: string, options?: AxiosRequestConfig): AxiosPromise<CatalogProductsItemServicesResponse>;

  /**
   *
   * @summary Список отгрузок по товару
   * @param {string} productCode
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsItemShipments(
    productCode: string,
    dateFrom?: string,
    dateTo?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsItemShipmentsResponse>;

  /**
   *
   * @summary Список товаров
   * @param {CatalogProductsListRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsList(request: CatalogProductsListRequest, options?: AxiosRequestConfig): AxiosPromise<CatalogProductsListResponse>;

  /**
   *
   * @summary Расширенный отчет по товарам
   * @param {CatalogProductsReportRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsReport(request: CatalogProductsReportRequest, options?: AxiosRequestConfig): AxiosPromise<CatalogProductsReportResponse>;

  /**
   *
   * @summary Расширенный отчет по товарам
   * @param {CatalogProductsReportXlsxRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsReportXlsx(request: CatalogProductsReportXlsxRequest, options?: AxiosRequestConfig): AxiosPromise<object>;

  /**
   *
   * @summary Фильтры товаров для поиска
   * @param {string} [code] Код товара
   * @param {string} [query] Строка для поиска товара
   * @param {string} [category] Раздел каталога
   * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
   * @param {string} [manufacturer] Фильтр по производителю
   * @param {string} [baseSize] Фильтр по базовому размеру
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [stockRequired] Только с остатками
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [productType] Фильтр по виду товара
   * @param {string} [searchMarker]
   * @param {string} [dealCode] Код сделки
   * @param {string} [agreementCode] Код соглашения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsSearchFilter(
    code?: string,
    query?: string,
    category?: string,
    filters?: string,
    manufacturer?: string,
    baseSize?: string,
    warehouse?: string,
    stockRequired?: string,
    branchOfficeCode?: string,
    productType?: string,
    searchMarker?: string,
    dealCode?: string,
    agreementCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsSearchFilterResponse>;

  /**
   *
   * @summary Графики по ценам конкурентов
   * @param {string} productCode
   * @param {string} [competitorCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsStatsCompetitorsPlot(
    productCode: string,
    competitorCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsStatsCompetitorsPlotResponse>;

  /**
   *
   * @summary Графики цен и отгрузок по товару
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogProductsStatsPlot(code: string, options?: AxiosRequestConfig): AxiosPromise<CatalogProductsStatsPlotResponse>;

  /**
   *
   * @summary Запрос главной страницы каталога
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [stockRequired] Только с остатками
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogRoot(
    warehouse?: string,
    branchOfficeCode?: string,
    stockRequired?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogRootResponse>;

  /**
   * Список будет зависеть от филиала текущего МОП.
   * @summary Список доступных складов
   * @param {string} [branchOfficeCode] Код филиала, если нужен список складов не от текущего филиала сотрудника
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApiInterface
   */
  catalogWarehouses(branchOfficeCode?: string, options?: AxiosRequestConfig): AxiosPromise<CatalogWarehousesResponse>;
}

/**
 * CatalogApi - object-oriented interface
 * @export
 * @class CatalogApi
 * @extends {BaseAPI}
 */
export class CatalogApi extends BaseAPI implements CatalogApiInterface {
  /**
   *
   * @summary Запрос данных по сертификату
   * @param {string} [batchCode] Код партии
   * @param {string} [shipmentCode] Код отгрузки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogBatchCertificates(batchCode?: string, shipmentCode?: string, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogBatchCertificates(batchCode, shipmentCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Запрос содержимого раздела
   * @param {CatalogCategoriesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogCategories(request: CatalogCategoriesRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogCategories(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Выгрузка прайслиста
   * @param {string} categoryCode Код раздела
   * @param {string} [filters] Фильтрация для продуктов. См. getCatalogProducts
   * @param {string} [query] Строка для поиска товара
   * @param {string} [manufacturer] Фильтр по производителю
   * @param {string} [productType] Фильтр по виду товара
   * @param {string} [baseSize] Фильтр по базовому размеру
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [stockRequired] Только с остатками
   * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [returnSet] Набор данных:   category - только разделы,   catalog - раздел каталога с необходимыми свойствами.   products - товары и базовые фильтры. Свойства будут запрашиваться отдельно. По умолчанию возвращаются все доступные данные - используется для выгрузки на сайт.
   * @param {string} [searchMarker]
   * @param {string} [dealCode] Код сделки
   * @param {string} [agreementCode] Код соглашения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogCategoriesItemPricelistXlsx(
    categoryCode: string,
    filters?: string,
    query?: string,
    manufacturer?: string,
    productType?: string,
    baseSize?: string,
    warehouse?: string,
    stockRequired?: string,
    sort?: string,
    branchOfficeCode?: string,
    returnSet?: string,
    searchMarker?: string,
    dealCode?: string,
    agreementCode?: string,
    options?: AxiosRequestConfig
  ) {
    return CatalogApiFp(this.configuration)
      .catalogCategoriesItemPricelistXlsx(
        categoryCode,
        filters,
        query,
        manufacturer,
        productType,
        baseSize,
        warehouse,
        stockRequired,
        sort,
        branchOfficeCode,
        returnSet,
        searchMarker,
        dealCode,
        agreementCode,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Запрос содержимого раздела
   * @param {string} categoryCode Код раздела
   * @param {string} [filters] Фильтрация для продуктов. См. getCatalogProducts
   * @param {string} [manufacturer] Фильтр по производителю
   * @param {string} [productType] Фильтр по виду товара
   * @param {string} [baseSize] Фильтр по базовому размеру
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [stockRequired] Только с остатками
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [returnSet] Набор данных:   category - только разделы,   catalog - раздел каталога с необходимыми свойствами.   products - товары и базовые фильтры. Свойства будут запрашиваться отдельно. По умолчанию возвращаются все доступные данные - используется для выгрузки на сайт.
   * @param {string} [searchMarker]
   * @param {string} [dealCode] Код сделки
   * @param {string} [agreementCode] Код соглашения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogCategoriesItemView(
    categoryCode: string,
    filters?: string,
    manufacturer?: string,
    productType?: string,
    baseSize?: string,
    warehouse?: string,
    stockRequired?: string,
    page?: number,
    count?: number,
    sort?: string,
    branchOfficeCode?: string,
    returnSet?: string,
    searchMarker?: string,
    dealCode?: string,
    agreementCode?: string,
    options?: AxiosRequestConfig
  ) {
    return CatalogApiFp(this.configuration)
      .catalogCategoriesItemView(
        categoryCode,
        filters,
        manufacturer,
        productType,
        baseSize,
        warehouse,
        stockRequired,
        page,
        count,
        sort,
        branchOfficeCode,
        returnSet,
        searchMarker,
        dealCode,
        agreementCode,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список фильтров для выборки товаров
   * @param {CatalogFiltersRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogFilters(request: CatalogFiltersRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogFilters(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Данные номенклатуры по товару для аккордеона
   * @param {CatalogProductAccordionNomenclatureRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductAccordionNomenclature(request: CatalogProductAccordionNomenclatureRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductAccordionNomenclature(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Резервы товара
   * @param {CatalogProductReservesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductReserves(request: CatalogProductReservesRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductReserves(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Резервы товара - статистика
   * @param {ProductReservesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductReservesStat(request: ProductReservesRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductReservesStat(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Резервы товара v2
   * @param {ProductReservesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductReservesV2(request: ProductReservesRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductReservesV2(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Остатки товара
   * @param {CatalogProductStocksRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductStocks(request: CatalogProductStocksRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductStocks(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Поиск товаров
   * @param {string} [code] Код товара
   * @param {string} [query] Строка для поиска товара
   * @param {string} [category] Раздел каталога
   * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
   * @param {string} [manufacturer] Фильтр по производителю
   * @param {string} [productType] Фильтр по виду товара
   * @param {string} [baseSize] Фильтр по базовому размеру
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [stockRequired] Только с остатками
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [sort] Правило сортировка для товаров. См. ProductSorting
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [searchMarker]
   * @param {string} [dealCode] Код сделки
   * @param {string} [agreementCode] Код соглашения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProducts(
    code?: string,
    query?: string,
    category?: string,
    filters?: string,
    manufacturer?: string,
    productType?: string,
    baseSize?: string,
    warehouse?: string,
    stockRequired?: string,
    page?: number,
    count?: number,
    sort?: string,
    branchOfficeCode?: string,
    searchMarker?: string,
    dealCode?: string,
    agreementCode?: string,
    options?: AxiosRequestConfig
  ) {
    return CatalogApiFp(this.configuration)
      .catalogProducts(
        code,
        query,
        category,
        filters,
        manufacturer,
        productType,
        baseSize,
        warehouse,
        stockRequired,
        page,
        count,
        sort,
        branchOfficeCode,
        searchMarker,
        dealCode,
        agreementCode,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Альтернативы для товаров
   * @param {CatalogProductsAlternativesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsAlternatives(request: CatalogProductsAlternativesRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsAlternatives(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Расширенная информация по продуктам, догружаемая после получения списка товаров
   * @param {CatalogProductsExtensionsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsExtensions(request: CatalogProductsExtensionsRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsExtensions(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Быстрый поиск товаров
   * @param {CatalogProductsFastSearchRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsFastSearch(request: CatalogProductsFastSearchRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsFastSearch(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Просмотр страницы товара
   * @param {string} productCode Код товара
   * @param {string} [category] Раздел каталога
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsItem(
    productCode: string,
    category?: string,
    warehouse?: string,
    branchOfficeCode?: string,
    options?: AxiosRequestConfig
  ) {
    return CatalogApiFp(this.configuration)
      .catalogProductsItem(productCode, category, warehouse, branchOfficeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Информация о МРЦ товара
   * @param {string} productCode
   * @param {string} branchOfficeCode
   * @param {string} [date]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsItemBranchOfficeItemMrc(
    productCode: string,
    branchOfficeCode: string,
    date?: string,
    options?: AxiosRequestConfig
  ) {
    return CatalogApiFp(this.configuration)
      .catalogProductsItemBranchOfficeItemMrc(productCode, branchOfficeCode, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузка изображения к товару
   * @param {string} productCode
   * @param {string} imageKey Номер или тип изображения
   * @param {any} [image] Изображение в формате png/jpeg/gif
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsItemImagesItemUpload(productCode: string, imageKey: string, image?: any, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsItemImagesItemUpload(productCode, imageKey, image, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * В частности используется на странице сделки для вывода доступных производителей к товару. Устарело, следует использовать DealsItemManufacturers.
   * @summary Список производителей для материала
   * @param {string} productCode
   * @param {string} [dealCode] Код сделки для исключения уже использованных остатков
   * @param {string} [positionCode] Позиция сделки для исключения уже использованных остатков
   * @param {string} [warehouse] Склад отгрузки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsItemManufacturers(
    productCode: string,
    dealCode?: string,
    positionCode?: string,
    warehouse?: string,
    options?: AxiosRequestConfig
  ) {
    return CatalogApiFp(this.configuration)
      .catalogProductsItemManufacturers(productCode, dealCode, positionCode, warehouse, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Информация о пачках (или рулонах) на остатках склада
   * @param {string} productCode
   * @param {string} [warehouse]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsItemPacks(productCode: string, warehouse?: string, page?: number, count?: number, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsItemPacks(productCode, warehouse, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Доступные услуги для товара
   * @param {string} productCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsItemServices(productCode: string, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsItemServices(productCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список отгрузок по товару
   * @param {string} productCode
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsItemShipments(
    productCode: string,
    dateFrom?: string,
    dateTo?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return CatalogApiFp(this.configuration)
      .catalogProductsItemShipments(productCode, dateFrom, dateTo, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список товаров
   * @param {CatalogProductsListRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsList(request: CatalogProductsListRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsList(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Расширенный отчет по товарам
   * @param {CatalogProductsReportRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsReport(request: CatalogProductsReportRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsReport(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Расширенный отчет по товарам
   * @param {CatalogProductsReportXlsxRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsReportXlsx(request: CatalogProductsReportXlsxRequest, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsReportXlsx(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Фильтры товаров для поиска
   * @param {string} [code] Код товара
   * @param {string} [query] Строка для поиска товара
   * @param {string} [category] Раздел каталога
   * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
   * @param {string} [manufacturer] Фильтр по производителю
   * @param {string} [baseSize] Фильтр по базовому размеру
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [stockRequired] Только с остатками
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [productType] Фильтр по виду товара
   * @param {string} [searchMarker]
   * @param {string} [dealCode] Код сделки
   * @param {string} [agreementCode] Код соглашения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsSearchFilter(
    code?: string,
    query?: string,
    category?: string,
    filters?: string,
    manufacturer?: string,
    baseSize?: string,
    warehouse?: string,
    stockRequired?: string,
    branchOfficeCode?: string,
    productType?: string,
    searchMarker?: string,
    dealCode?: string,
    agreementCode?: string,
    options?: AxiosRequestConfig
  ) {
    return CatalogApiFp(this.configuration)
      .catalogProductsSearchFilter(
        code,
        query,
        category,
        filters,
        manufacturer,
        baseSize,
        warehouse,
        stockRequired,
        branchOfficeCode,
        productType,
        searchMarker,
        dealCode,
        agreementCode,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Графики по ценам конкурентов
   * @param {string} productCode
   * @param {string} [competitorCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsStatsCompetitorsPlot(productCode: string, competitorCode?: string, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsStatsCompetitorsPlot(productCode, competitorCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Графики цен и отгрузок по товару
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogProductsStatsPlot(code: string, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogProductsStatsPlot(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Запрос главной страницы каталога
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [stockRequired] Только с остатками
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogRoot(warehouse?: string, branchOfficeCode?: string, stockRequired?: string, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogRoot(warehouse, branchOfficeCode, stockRequired, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список будет зависеть от филиала текущего МОП.
   * @summary Список доступных складов
   * @param {string} [branchOfficeCode] Код филиала, если нужен список складов не от текущего филиала сотрудника
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogApi
   */
  public catalogWarehouses(branchOfficeCode?: string, options?: AxiosRequestConfig) {
    return CatalogApiFp(this.configuration)
      .catalogWarehouses(branchOfficeCode, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Список офисов и складов
     * @param {string} [variant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyPublicOffices: async (variant?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/company/public/offices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (variant !== undefined) {
        localVarQueryParameter['variant'] = variant;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список сотрудников, запрашивающих согласование
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companySubordinates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/company/subordinates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration);
  return {
    /**
     * Список офисов и складов
     * @param {string} [variant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyPublicOffices(
      variant?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPublicOfficesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyPublicOffices(variant, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список сотрудников, запрашивающих согласование
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companySubordinates(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySubordinatesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companySubordinates(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CompanyApiFp(configuration);
  return {
    /**
     * Список офисов и складов
     * @param {string} [variant]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyPublicOffices(variant?: string, options?: any): AxiosPromise<CompanyPublicOfficesResponse> {
      return localVarFp.companyPublicOffices(variant, options).then(request => request(axios, basePath));
    },
    /**
     * Список сотрудников, запрашивающих согласование
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companySubordinates(options?: any): AxiosPromise<CompanySubordinatesResponse> {
      return localVarFp.companySubordinates(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CompanyApi - interface
 * @export
 * @interface CompanyApi
 */
export interface CompanyApiInterface {
  /**
   * Список офисов и складов
   * @param {string} [variant]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApiInterface
   */
  companyPublicOffices(variant?: string, options?: AxiosRequestConfig): AxiosPromise<CompanyPublicOfficesResponse>;

  /**
   * Список сотрудников, запрашивающих согласование
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApiInterface
   */
  companySubordinates(options?: AxiosRequestConfig): AxiosPromise<CompanySubordinatesResponse>;
}

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI implements CompanyApiInterface {
  /**
   * Список офисов и складов
   * @param {string} [variant]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companyPublicOffices(variant?: string, options?: AxiosRequestConfig) {
    return CompanyApiFp(this.configuration)
      .companyPublicOffices(variant, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список сотрудников, запрашивающих согласование
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companySubordinates(options?: AxiosRequestConfig) {
    return CompanyApiFp(this.configuration)
      .companySubordinates(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CompetitorApi - axios parameter creator
 * @export
 */
export const CompetitorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Список конкурентов
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitors: async (query?: string, page?: number, count?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/competitors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompetitorApi - functional programming interface
 * @export
 */
export const CompetitorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompetitorApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Список конкурентов
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async competitors(
      query?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompetitorsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.competitors(query, page, count, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CompetitorApi - factory interface
 * @export
 */
export const CompetitorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CompetitorApiFp(configuration);
  return {
    /**
     *
     * @summary Список конкурентов
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    competitors(query?: string, page?: number, count?: number, options?: any): AxiosPromise<CompetitorsResponse> {
      return localVarFp.competitors(query, page, count, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CompetitorApi - interface
 * @export
 * @interface CompetitorApi
 */
export interface CompetitorApiInterface {
  /**
   *
   * @summary Список конкурентов
   * @param {string} [query]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitorApiInterface
   */
  competitors(query?: string, page?: number, count?: number, options?: AxiosRequestConfig): AxiosPromise<CompetitorsResponse>;
}

/**
 * CompetitorApi - object-oriented interface
 * @export
 * @class CompetitorApi
 * @extends {BaseAPI}
 */
export class CompetitorApi extends BaseAPI implements CompetitorApiInterface {
  /**
   *
   * @summary Список конкурентов
   * @param {string} [query]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompetitorApi
   */
  public competitors(query?: string, page?: number, count?: number, options?: AxiosRequestConfig) {
    return CompetitorApiFp(this.configuration)
      .competitors(query, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CorrectionShipmentApi - axios parameter creator
 * @export
 */
export const CorrectionShipmentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Список корректировок отгрузок
     * @param {CorrectionShipmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    correctionShipments: async (request: CorrectionShipmentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('correctionShipments', 'request', request);
      const localVarPath = `/corrections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CorrectionShipmentApi - functional programming interface
 * @export
 */
export const CorrectionShipmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CorrectionShipmentApiAxiosParamCreator(configuration);
  return {
    /**
     * Список корректировок отгрузок
     * @param {CorrectionShipmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async correctionShipments(
      request: CorrectionShipmentsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorrectionShipmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.correctionShipments(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CorrectionShipmentApi - factory interface
 * @export
 */
export const CorrectionShipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CorrectionShipmentApiFp(configuration);
  return {
    /**
     * Список корректировок отгрузок
     * @param {CorrectionShipmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    correctionShipments(request: CorrectionShipmentsRequest, options?: any): AxiosPromise<CorrectionShipmentsResponse> {
      return localVarFp.correctionShipments(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CorrectionShipmentApi - interface
 * @export
 * @interface CorrectionShipmentApi
 */
export interface CorrectionShipmentApiInterface {
  /**
   * Список корректировок отгрузок
   * @param {CorrectionShipmentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorrectionShipmentApiInterface
   */
  correctionShipments(request: CorrectionShipmentsRequest, options?: AxiosRequestConfig): AxiosPromise<CorrectionShipmentsResponse>;
}

/**
 * CorrectionShipmentApi - object-oriented interface
 * @export
 * @class CorrectionShipmentApi
 * @extends {BaseAPI}
 */
export class CorrectionShipmentApi extends BaseAPI implements CorrectionShipmentApiInterface {
  /**
   * Список корректировок отгрузок
   * @param {CorrectionShipmentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CorrectionShipmentApi
   */
  public correctionShipments(request: CorrectionShipmentsRequest, options?: AxiosRequestConfig) {
    return CorrectionShipmentApiFp(this.configuration)
      .correctionShipments(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Справочник статусов активности клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientActivityStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/clients/activityStatuses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник статусов участия во внешней системе СЭД клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientDocFlowStates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/clients/docFlowStates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создать лида
     * @param {ClientLeadsCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLeadsCreate: async (request: ClientLeadsCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientLeadsCreate', 'request', request);
      const localVarPath = `/clients/leads/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создать отправку клиента
     * @param {string} customerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLeadsItemSendingCreate: async (customerCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientLeadsItemSendingCreate', 'customerCode', customerCode);
      const localVarPath = `/clients/leads/{customerCode}/sendings`.replace(
        `{${'customerCode'}}`,
        encodeURIComponent(String(customerCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отправки клиента
     * @param {string} customerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLeadsItemSendingList: async (customerCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientLeadsItemSendingList', 'customerCode', customerCode);
      const localVarPath = `/clients/leads/{customerCode}/sendings`.replace(
        `{${'customerCode'}}`,
        encodeURIComponent(String(customerCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить информацию о юридическом лице перед созданием лида
     * @param {ClientLeadsLookupRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLeadsLookup: async (request: ClientLeadsLookupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientLeadsLookup', 'request', request);
      const localVarPath = `/clients/leads/lookup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник отраслей клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientOccupations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/clients/occupations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник подотраслей клиента
     * @param {Array<string>} [occupationCodes] Список отраслей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientOccupationsSpecified: async (occupationCodes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/clients/occupations/specified`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (occupationCodes) {
        localVarQueryParameter['occupationCodes'] = occupationCodes.join(COLLECTION_FORMATS.csv);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список сегментов клиента в разрезе филиала
     * @param {ClientOfficeSegmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientOfficeSegments: async (request: ClientOfficeSegmentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientOfficeSegments', 'request', request);
      const localVarPath = `/client/office/segments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник сегментов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientSegments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/clients/segments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник сегментов клиента
     * @param {'consuming' | 'shipment' | 'recency' | 'frequency' | 'monetary'} segmentType Список сегментов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientSegmentsDictionary: async (
      segmentType: 'consuming' | 'shipment' | 'recency' | 'frequency' | 'monetary',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'segmentType' is not null or undefined
      assertParamExists('clientSegmentsDictionary', 'segmentType', segmentType);
      const localVarPath = `/clients/segmentsDictionary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (segmentType !== undefined) {
        localVarQueryParameter['segmentType'] = segmentType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список сегментов по отгрузке клиента в разрезе филиала
     * @param {ClientShipmentSegmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientShipmentSegments: async (request: ClientShipmentSegmentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientShipmentSegments', 'request', request);
      const localVarPath = `/client/shipmentSegments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список изменений сегментов по отгрузке клиента в разрезе филиала
     * @param {ClientShipmentSegmentsHistoryRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientShipmentSegmentsHistory: async (
      request: ClientShipmentSegmentsHistoryRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientShipmentSegmentsHistory', 'request', request);
      const localVarPath = `/client/shipmentSegments/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список клиентов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clients: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/clients`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление напоминаний клиента
     * @param {string} code Код напоминания по клиенту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsAttentionsItemDelete: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('clientsAttentionsItemDelete', 'code', code);
      const localVarPath = `/clients/attentions/{code}/delete`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Редактирование напоминаний клиента
     * @param {string} code Код напоминания по клиенту
     * @param {ClientsAttentionsItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsAttentionsItemSave: async (
      code: string,
      request: ClientsAttentionsItemSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('clientsAttentionsItemSave', 'code', code);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientsAttentionsItemSave', 'request', request);
      const localVarPath = `/clients/attentions/{code}/save`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник причин напоминаний по клиенту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsAttentionsReasons: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/clients/attentions/reasons`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список заявок на изменение клиента
     * @param {string} customerCode
     * @param {string} [branchOfficeCode]
     * @param {string} [stateCode] Статус заявки (/adm/rad#/customer_change_request_state)
     * @param {string} [kindCode] Вид заявки (adm/rad#/customer_change_request_kind)
     * @param {boolean} [active] Показать активные заявки. Можно совмещать с stateCode
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsChangeRequests: async (
      customerCode: string,
      branchOfficeCode?: string,
      stateCode?: string,
      kindCode?: string,
      active?: boolean,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsChangeRequests', 'customerCode', customerCode);
      const localVarPath = `/clients/changeRequests`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (stateCode !== undefined) {
        localVarQueryParameter['stateCode'] = stateCode;
      }

      if (kindCode !== undefined) {
        localVarQueryParameter['kindCode'] = kindCode;
      }

      if (active !== undefined) {
        localVarQueryParameter['active'] = active;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создать заявку на изменение клиента
     * @param {ClientsChangeRequestCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsChangeRequestsCreate: async (
      request: ClientsChangeRequestCreateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientsChangeRequestsCreate', 'request', request);
      const localVarPath = `/clients/changeRequests`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Закрыть доступ для филиала
     * @param {string} customerCode
     * @param {ClientsCloseOfficeAccessRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsCloseOfficeAccess: async (
      customerCode: string,
      request: ClientsCloseOfficeAccessRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsCloseOfficeAccess', 'customerCode', customerCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientsCloseOfficeAccess', 'request', request);
      const localVarPath = `/clients/{customerCode}/closeOfficeAccess`.replace(
        `{${'customerCode'}}`,
        encodeURIComponent(String(customerCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Документы лимит-кредит
     * @param {string} customerCode
     * @param {string} [branchOfficeCode]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsCreditLimitDocuments: async (
      customerCode: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsCreditLimitDocuments', 'customerCode', customerCode);
      const localVarPath = `/clients/credit/limit/documents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список клиентов
     * @param {ClientsForFilterRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsForFilter: async (request: ClientsForFilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientsForFilter', 'request', request);
      const localVarPath = `/clients/forFilter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Детали по клиенту
     * @param {string} customerCode
     * @param {string} [month] Год и месяц
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItem: async (customerCode: string, month?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItem', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (month !== undefined) {
        localVarQueryParameter['month'] = (month as any) instanceof Date ? (month as any).toISOString().substr(0, 10) : month;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список адресов клиента
     * @param {string} customerCode
     * @param {string} [consigneeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAddresses: async (customerCode: string, consigneeCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemAddresses', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}/addresses`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (consigneeCode !== undefined) {
        localVarQueryParameter['consigneeCode'] = consigneeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Генерация кода для нового адреса
     * @param {string} customerCode
     * @param {string} frontCode Код нового адреса на фронте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAddressesAdd: async (customerCode: string, frontCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemAddressesAdd', 'customerCode', customerCode);
      // verify required parameter 'frontCode' is not null or undefined
      assertParamExists('clientsItemAddressesAdd', 'frontCode', frontCode);
      const localVarPath = `/clients/{customerCode}/addresses/add`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (frontCode !== undefined) {
        localVarQueryParameter['frontCode'] = frontCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление адреса клиента
     * @param {string} customerCode
     * @param {string} addressCode
     * @param {ClientsItemAddressesItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAddressesItemSave: async (
      customerCode: string,
      addressCode: string,
      request: ClientsItemAddressesItemSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemAddressesItemSave', 'customerCode', customerCode);
      // verify required parameter 'addressCode' is not null or undefined
      assertParamExists('clientsItemAddressesItemSave', 'addressCode', addressCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientsItemAddressesItemSave', 'request', request);
      const localVarPath = `/clients/{customerCode}/addresses/{addressCode}/save`
        .replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)))
        .replace(`{${'addressCode'}}`, encodeURIComponent(String(addressCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список напоминаний клиента
     * @param {string} customerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAttentions: async (customerCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemAttentions', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}/attentions`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создание напоминания клиента
     * @param {string} customerCode
     * @param {ClientsItemAttentionsCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAttentionsCreate: async (
      customerCode: string,
      request: ClientsItemAttentionsCreateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemAttentionsCreate', 'customerCode', customerCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientsItemAttentionsCreate', 'request', request);
      const localVarPath = `/clients/{customerCode}/attentions`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary История изменения напоминаний клиента
     * @param {string} customerCode
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAttentionsHistory: async (
      customerCode: string,
      dateFrom?: string,
      dateTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemAttentionsHistory', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}/attentions/history`.replace(
        `{${'customerCode'}}`,
        encodeURIComponent(String(customerCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список грузополучателей
     * @param {string} customerCode
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemConsignees: async (
      customerCode: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemConsignees', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}/consignees`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список договоров с клиентом
     * @param {string} customerCode
     * @param {string} [kind] Тип договора (with_buyer, with_supplier, other)
     * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {boolean} [withDrafts] deprecated. use preset. Показывать черновики
     * @param {boolean} [forDeals] deprecated. use preset. Показывать только для заявок
     * @param {string} [preset] Пресеты для контрактов (with_drafts, for_deals, for_additional)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemContracts: async (
      customerCode: string,
      kind?: string,
      branchOfficeCode?: string,
      withDrafts?: boolean,
      forDeals?: boolean,
      preset?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemContracts', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}/contracts`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (kind !== undefined) {
        localVarQueryParameter['kind'] = kind;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (withDrafts !== undefined) {
        localVarQueryParameter['withDrafts'] = withDrafts;
      }

      if (forDeals !== undefined) {
        localVarQueryParameter['forDeals'] = forDeals;
      }

      if (preset !== undefined) {
        localVarQueryParameter['preset'] = preset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Состояние кредитования клиента
     * @param {string} customerCode
     * @param {string} [branchOfficeCode]
     * @param {boolean} [makeEsr] Выполнить запрос актуального статуса во внешнюю систему
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemCreditState: async (
      customerCode: string,
      branchOfficeCode?: string,
      makeEsr?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemCreditState', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}/credit/state`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (makeEsr !== undefined) {
        localVarQueryParameter['makeEsr'] = makeEsr;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список заявок на сделку
     * @param {string} customerCode
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
     * @param {Array<string>} [stateCodes] Коды статусов
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [month] Год и месяц
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemDeals: async (
      customerCode: string,
      branchOfficeCodes?: Array<string>,
      stateCode?: string,
      stateCodes?: Array<string>,
      page?: number,
      count?: number,
      month?: string,
      dateFrom?: string,
      dateTo?: string,
      containsAttachments?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemDeals', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}/deals`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (branchOfficeCodes) {
        localVarQueryParameter['branchOfficeCodes'] = branchOfficeCodes.join(COLLECTION_FORMATS.csv);
      }

      if (stateCode !== undefined) {
        localVarQueryParameter['stateCode'] = stateCode;
      }

      if (stateCodes) {
        localVarQueryParameter['stateCodes'] = stateCodes.join(COLLECTION_FORMATS.csv);
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = (month as any) instanceof Date ? (month as any).toISOString().substr(0, 10) : month;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (containsAttachments !== undefined) {
        localVarQueryParameter['containsAttachments'] = containsAttachments;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary График маржинальности по клиенту
     * @param {string} customerCode
     * @param {string} [dealCode] Код сделки, которая будет добавлена последней точкой на график
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemMarginalityPlot: async (customerCode: string, dealCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemMarginalityPlot', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}/marginality/plot`.replace(
        `{${'customerCode'}}`,
        encodeURIComponent(String(customerCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dealCode !== undefined) {
        localVarQueryParameter['dealCode'] = dealCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Статистика маржинальности на странице клиента
     * @param {string} customerCode
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemMarginalityStat: async (customerCode: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemMarginalityStat', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}/marginality/stat`.replace(
        `{${'customerCode'}}`,
        encodeURIComponent(String(customerCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Сделки клиента - по сути заявки в лучшем статусе
     * @param {string} customerCode
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [month] Год и месяц
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [hasBills] Фильтр по наличию счета
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemOrders: async (
      customerCode: string,
      page?: number,
      count?: number,
      month?: string,
      dateFrom?: string,
      dateTo?: string,
      hasBills?: boolean,
      containsAttachments?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemOrders', 'customerCode', customerCode);
      const localVarPath = `/clients/{customerCode}/orders`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = (month as any) instanceof Date ? (month as any).toISOString().substr(0, 10) : month;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (hasBills !== undefined) {
        localVarQueryParameter['hasBills'] = hasBills;
      }

      if (containsAttachments !== undefined) {
        localVarQueryParameter['containsAttachments'] = containsAttachments;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление клиента
     * @param {string} customerCode
     * @param {ClientsItemSaveRequest} request
     * @param {string} [month] Год и месяц
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemSave: async (
      customerCode: string,
      request: ClientsItemSaveRequest,
      month?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsItemSave', 'customerCode', customerCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientsItemSave', 'request', request);
      const localVarPath = `/clients/{customerCode}`.replace(`{${'customerCode'}}`, encodeURIComponent(String(customerCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (month !== undefined) {
        localVarQueryParameter['month'] = (month as any) instanceof Date ? (month as any).toISOString().substr(0, 10) : month;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удалить взаимоотношения для филиала
     * @param {string} customerCode
     * @param {ClientsRemoveOfficeRelationshipsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsRemoveOfficeRelationships: async (
      customerCode: string,
      request: ClientsRemoveOfficeRelationshipsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('clientsRemoveOfficeRelationships', 'customerCode', customerCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientsRemoveOfficeRelationships', 'request', request);
      const localVarPath = `/clients/{customerCode}/removeOfficeRelationships`.replace(
        `{${'customerCode'}}`,
        encodeURIComponent(String(customerCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Цепочка документов к отгрузке
     * @param {ClientsShipmentsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsShipmentsThread: async (request: ClientsShipmentsThreadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('clientsShipmentsThread', 'request', request);
      const localVarPath = `/clients/shipments/thread`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список видов соглашения договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractAgreementTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contract/agreement/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список договоров - соглашений по ценам с клиентом
     * @param {string} [customerCode] deprecated. use customerCodes
     * @param {Array<string>} [customerCodes]
     * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [query] filter for agreements
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {Array<number>} [marketDeviationLevel] Уровень отклонения цены (от -2 - хуже максимального отклонения для филиала до 2)
     * @param {Array<number>} [stateIds] Состояние соглашения, 1 - на согласовании, 2 - согласовано, 3 - активно, 4 - неактивно
     * @param {Array<string>} [approvalStateCodes] Коды статусов согласования документов
     * @param {Array<string>} [documentTypeCodes] Коды типов прикрепленных документов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreements: async (
      customerCode?: string,
      customerCodes?: Array<string>,
      branchOfficeCode?: string,
      branchOfficeCodes?: Array<string>,
      page?: number,
      count?: number,
      query?: string,
      employeeSet?: string,
      dateFrom?: string,
      dateTo?: string,
      marketDeviationLevel?: Array<number>,
      stateIds?: Array<number>,
      approvalStateCodes?: Array<string>,
      documentTypeCodes?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contract/cost/agreements`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (customerCodes) {
        localVarQueryParameter['customerCodes'] = customerCodes.join(COLLECTION_FORMATS.csv);
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (branchOfficeCodes) {
        localVarQueryParameter['branchOfficeCodes'] = branchOfficeCodes.join(COLLECTION_FORMATS.csv);
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (marketDeviationLevel) {
        localVarQueryParameter['marketDeviationLevel'] = marketDeviationLevel.join(COLLECTION_FORMATS.csv);
      }

      if (stateIds) {
        localVarQueryParameter['stateIds'] = stateIds.join(COLLECTION_FORMATS.csv);
      }

      if (approvalStateCodes) {
        localVarQueryParameter['approvalStateCodes'] = approvalStateCodes.join(COLLECTION_FORMATS.csv);
      }

      if (documentTypeCodes) {
        localVarQueryParameter['documentTypeCodes'] = documentTypeCodes.join(COLLECTION_FORMATS.csv);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отправка на согласование или согласование соглашения
     * @param {ContractCostAgreementsApprovalConfirmRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsApprovalConfirm: async (
      request: ContractCostAgreementsApprovalConfirmRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractCostAgreementsApprovalConfirm', 'request', request);
      const localVarPath = `/contract/cost/agreements/approval/confirm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отклонение соглашения
     * @param {ContractCostAgreementsApprovalDeclineRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsApprovalDecline: async (
      request: ContractCostAgreementsApprovalDeclineRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractCostAgreementsApprovalDecline', 'request', request);
      const localVarPath = `/contract/cost/agreements/approval/decline`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отзыв заявки на согласование соглашения
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsApprovalHistory: async (agreementCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsApprovalHistory', 'agreementCode', agreementCode);
      const localVarPath = `/contract/cost/agreements/approval/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (agreementCode !== undefined) {
        localVarQueryParameter['agreementCode'] = agreementCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отзыв заявки на согласование соглашения
     * @param {ContractCostAgreementsApprovalWithdrawRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsApprovalWithdraw: async (
      request: ContractCostAgreementsApprovalWithdrawRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractCostAgreementsApprovalWithdraw', 'request', request);
      const localVarPath = `/contract/cost/agreements/approval/withdraw`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Клонирование договора-соглашения
     * @param {ContractCostAgreementsCloneRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsClone: async (
      request: ContractCostAgreementsCloneRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractCostAgreementsClone', 'request', request);
      const localVarPath = `/contract/cost/agreements/clone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создание договора-соглашения
     * @param {ContractCostAgreementsCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsCreate: async (
      request: ContractCostAgreementsCreateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractCostAgreementsCreate', 'request', request);
      const localVarPath = `/contract/cost/agreements/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Договор - соглашение по ценам с клиентом
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItem: async (agreementCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItem', 'agreementCode', agreementCode);
      const localVarPath = `/contract/cost/agreements/{agreementCode}`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Договор - соглашение по ценам с клиентом
     * @param {string} agreementCode
     * @param {ContractCostAgreementsItemAlternativesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemAlternatives: async (
      agreementCode: string,
      request: ContractCostAgreementsItemAlternativesRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemAlternatives', 'agreementCode', agreementCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractCostAgreementsItemAlternatives', 'request', request);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/alternatives`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список доступных реализаторов
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemDistributors: async (agreementCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemDistributors', 'agreementCode', agreementCode);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/distributors`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление документа из заявки/сделки
     * @param {string} agreementCode Код соглашения
     * @param {string} docCode Код документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemDocsItemDelete: async (
      agreementCode: string,
      docCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemDocsItemDelete', 'agreementCode', agreementCode);
      // verify required parameter 'docCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemDocsItemDelete', 'docCode', docCode);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/docs/{docCode}/delete`
        .replace(`{${'agreementCode'}}`, encodeURIComponent(String(agreementCode)))
        .replace(`{${'docCode'}}`, encodeURIComponent(String(docCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузка нового документа в заявку/сделку
     * @param {string} agreementCode Код соглашения
     * @param {string} documentTypeCode Тип загружаемого документа
     * @param {any} file Файл к загрузке
     * @param {string} [description]
     * @param {string} [positionCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemDocsUpload: async (
      agreementCode: string,
      documentTypeCode: string,
      file: any,
      description?: string,
      positionCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemDocsUpload', 'agreementCode', agreementCode);
      // verify required parameter 'documentTypeCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemDocsUpload', 'documentTypeCode', documentTypeCode);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('contractCostAgreementsItemDocsUpload', 'file', file);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/docs/upload`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (documentTypeCode !== undefined) {
        localVarFormParams.append('documentTypeCode', documentTypeCode as any);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (description !== undefined) {
        localVarFormParams.append('description', description as any);
      }

      if (positionCode !== undefined) {
        localVarFormParams.append('positionCode', positionCode as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Установка ограничения на редактирование
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemLock: async (agreementCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemLock', 'agreementCode', agreementCode);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/lock`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование PDF коммерческого предложения из соглашения
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemPdf: async (agreementCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemPdf', 'agreementCode', agreementCode);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/pdf`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузка SKU с ценами из эксель-файла
     * @param {string} agreementCode Код соглашения
     * @param {any} file Файл к загрузке
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemPricesUpload: async (
      agreementCode: string,
      file: any,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemPricesUpload', 'agreementCode', agreementCode);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('contractCostAgreementsItemPricesUpload', 'file', file);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/prices/upload`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Внесение изменений в договор-соглашение
     * @param {string} agreementCode
     * @param {ContractCostAgreementsItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemSave: async (
      agreementCode: string,
      request: ContractCostAgreementsItemSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemSave', 'agreementCode', agreementCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractCostAgreementsItemSave', 'request', request);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/save`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Получение информации о документе Спецификация соглашения. Сам документ можно получить в HTML и PDF-формате (ContractCostAgreementsItemSpecificationHTML, ContractCostAgreementsItemSpecificationPdf) В ответе ContractCostAgreementsItemSpecificationHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью ContractCostAgreementsItemSpecificationUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
     * @summary Получение информации о документе Спецификация соглашения
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemSpecification: async (agreementCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemSpecification', 'agreementCode', agreementCode);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/specification`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование HTML спецификации соглашения
     * @param {string} agreementCode
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemSpecificationHTML: async (
      agreementCode: string,
      withoutStampAndSign?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemSpecificationHTML', 'agreementCode', agreementCode);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/specification/html`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withoutStampAndSign !== undefined) {
        localVarQueryParameter['withoutStampAndSign'] = withoutStampAndSign;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование PDF спецификации соглашения
     * @param {string} agreementCode
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemSpecificationPdf: async (
      agreementCode: string,
      withoutStampAndSign?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemSpecificationPdf', 'agreementCode', agreementCode);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/specification/pdf`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (withoutStampAndSign !== undefined) {
        localVarQueryParameter['withoutStampAndSign'] = withoutStampAndSign;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление текстовок и другой информации документа Спецификация соглашения
     * @param {string} agreementCode
     * @param {ContractCostAgreementsItemSpecificationUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemSpecificationUpdate: async (
      agreementCode: string,
      request: ContractCostAgreementsItemSpecificationUpdateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemSpecificationUpdate', 'agreementCode', agreementCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractCostAgreementsItemSpecificationUpdate', 'request', request);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/specification`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Снятие ограничения на редактирование
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemUnlock: async (agreementCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'agreementCode' is not null or undefined
      assertParamExists('contractCostAgreementsItemUnlock', 'agreementCode', agreementCode);
      const localVarPath = `/contract/cost/agreements/{agreementCode}/unlock`.replace(
        `{${'agreementCode'}}`,
        encodeURIComponent(String(agreementCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Move agreement position
     * @param {ContractCostAgreementsPositionsMoveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsPositionsMove: async (
      request: ContractCostAgreementsPositionsMoveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractCostAgreementsPositionsMove', 'request', request);
      const localVarPath = `/contract/cost/agreements/positions/move`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Цепочка документов по соглашению
     * @param {ContractCostAgreementsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsThread: async (
      request: ContractCostAgreementsThreadRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractCostAgreementsThread', 'request', request);
      const localVarPath = `/contract/cost/agreements/thread`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список типов файлов договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractFileTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contract/file/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список файлов договора
     * @param {string} contractCode Код договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractFiles: async (contractCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'contractCode' is not null or undefined
      assertParamExists('contractFiles', 'contractCode', contractCode);
      const localVarPath = `/contract/files`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (contractCode !== undefined) {
        localVarQueryParameter['contractCode'] = contractCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Удаляет файл договора
     * @param {string} code Код файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractFilesDelete: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('contractFilesDelete', 'code', code);
      const localVarPath = `/contract/files/{code}/delete`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список форм договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractForms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contract/forms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Загрузка нового файла договора
     * @param {string} code Код договора
     * @param {string} title Заголовок
     * @param {string} fileTypeCode Код типа файла
     * @param {any} file Файл к загрузке
     * @param {string} [fileCode] Код файла договора (если передан то создается новая версия)
     * @param {string} [description] Описание
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractItemFilesUpload: async (
      code: string,
      title: string,
      fileTypeCode: string,
      file: any,
      fileCode?: string,
      description?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('contractItemFilesUpload', 'code', code);
      // verify required parameter 'title' is not null or undefined
      assertParamExists('contractItemFilesUpload', 'title', title);
      // verify required parameter 'fileTypeCode' is not null or undefined
      assertParamExists('contractItemFilesUpload', 'fileTypeCode', fileTypeCode);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('contractItemFilesUpload', 'file', file);
      const localVarPath = `/contract/{code}/files/upload`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (fileCode !== undefined) {
        localVarFormParams.append('fileCode', fileCode as any);
      }

      if (title !== undefined) {
        localVarFormParams.append('title', title as any);
      }

      if (fileTypeCode !== undefined) {
        localVarFormParams.append('fileTypeCode', fileTypeCode as any);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (description !== undefined) {
        localVarFormParams.append('description', description as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Переотправляет договор с клиентом в СЭД
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractItemResendingCreate: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('contractItemResendingCreate', 'code', code);
      const localVarPath = `/contracts/{code}/resend`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список видов договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractKinds: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contract/kinds`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список типов пролонгации договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractRenewalTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contract/renewal/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список типов документа подписанта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractSignerDocumentTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contract/contact/signer/document/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список типов подписываемых договоров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractSignerTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contract/signer/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список статусов договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contract/statuses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список видов договора
     * @param {string} [customerCode] Код клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractTypes: async (customerCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contract/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список договоров с клиентом
     * @param {string} [code]
     * @param {string} [customerCode] Код клиента
     * @param {string} [kind] Тип договора (with_buyer, with_supplier, other)
     * @param {string} [branchOfficeCode] deprecated. use branchOfficeCodes. Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [query] Фильтр
     * @param {string} [statusCode] Статус (draft, approval, active, inactive)
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contracts: async (
      code?: string,
      customerCode?: string,
      kind?: string,
      branchOfficeCode?: string,
      branchOfficeCodes?: Array<string>,
      employeeSet?: string,
      query?: string,
      statusCode?: string,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contracts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (kind !== undefined) {
        localVarQueryParameter['kind'] = kind;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (branchOfficeCodes) {
        localVarQueryParameter['branchOfficeCodes'] = branchOfficeCodes.join(COLLECTION_FORMATS.csv);
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (statusCode !== undefined) {
        localVarQueryParameter['statusCode'] = statusCode;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создает черновик договора с клиентом
     * @param {string} customerCode Код клиента
     * @param {string} typeCode Вид договора (Договор на реализацию металла с отсрочкой и тд)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftCreate: async (customerCode: string, typeCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('contractsItemDraftCreate', 'customerCode', customerCode);
      // verify required parameter 'typeCode' is not null or undefined
      assertParamExists('contractsItemDraftCreate', 'typeCode', typeCode);
      const localVarPath = `/contracts/{customerCode}/draft/create`.replace(
        `{${'customerCode'}}`,
        encodeURIComponent(String(customerCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (typeCode !== undefined) {
        localVarQueryParameter['typeCode'] = typeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаляет черновик договора с клиентом
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftDelete: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('contractsItemDraftDelete', 'code', code);
      const localVarPath = `/contracts/{code}/delete`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Поиск черновика договора с клиентом
     * @param {string} customerCode Код клиента
     * @param {string} [typeCode] Вид договора (Договор на реализацию металла с отсрочкой и тд)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftLookup: async (customerCode: string, typeCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('contractsItemDraftLookup', 'customerCode', customerCode);
      const localVarPath = `/contracts/{customerCode}/draft/lookup`.replace(
        `{${'customerCode'}}`,
        encodeURIComponent(String(customerCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (typeCode !== undefined) {
        localVarQueryParameter['typeCode'] = typeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отправляет черновик договора с клиентом в СЭД
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftSendingCreate: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('contractsItemDraftSendingCreate', 'code', code);
      const localVarPath = `/contracts/{code}/send`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список отправок черновика договора с клиентом в СЭД
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftSendingList: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('contractsItemDraftSendingList', 'code', code);
      const localVarPath = `/contracts/{code}/send`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновляет черновик договора с клиентом
     * @param {string} code Код черновика
     * @param {ContractsItemDraftUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftUpdate: async (
      code: string,
      request: ContractsItemDraftUpdateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('contractsItemDraftUpdate', 'code', code);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contractsItemDraftUpdate', 'request', request);
      const localVarPath = `/contracts/{code}/save`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Справочник статусов активности клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientActivityStatuses(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientActivityStatusesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientActivityStatuses(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник статусов участия во внешней системе СЭД клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientDocFlowStates(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientDocFlowStatesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientDocFlowStates(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создать лида
     * @param {ClientLeadsCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientLeadsCreate(
      request: ClientLeadsCreateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientLeadsCreate(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создать отправку клиента
     * @param {string} customerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientLeadsItemSendingCreate(
      customerCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientLeadsItemSendingCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientLeadsItemSendingCreate(customerCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отправки клиента
     * @param {string} customerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientLeadsItemSendingList(
      customerCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientLeadsItemSendingListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientLeadsItemSendingList(customerCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получить информацию о юридическом лице перед созданием лида
     * @param {ClientLeadsLookupRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientLeadsLookup(
      request: ClientLeadsLookupRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientLeadsLookupResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientLeadsLookup(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник отраслей клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientOccupations(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientOccupationsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientOccupations(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник подотраслей клиента
     * @param {Array<string>} [occupationCodes] Список отраслей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientOccupationsSpecified(
      occupationCodes?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientOccupationsSpecifiedResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientOccupationsSpecified(occupationCodes, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список сегментов клиента в разрезе филиала
     * @param {ClientOfficeSegmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientOfficeSegments(
      request: ClientOfficeSegmentsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientOfficeSegmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientOfficeSegments(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник сегментов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientSegments(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientSegmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientSegments(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник сегментов клиента
     * @param {'consuming' | 'shipment' | 'recency' | 'frequency' | 'monetary'} segmentType Список сегментов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientSegmentsDictionary(
      segmentType: 'consuming' | 'shipment' | 'recency' | 'frequency' | 'monetary',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientSegmentsDictionary(segmentType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список сегментов по отгрузке клиента в разрезе филиала
     * @param {ClientShipmentSegmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientShipmentSegments(
      request: ClientShipmentSegmentsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientShipmentSegmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientShipmentSegments(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список изменений сегментов по отгрузке клиента в разрезе филиала
     * @param {ClientShipmentSegmentsHistoryRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientShipmentSegmentsHistory(
      request: ClientShipmentSegmentsHistoryRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientShipmentSegmentsHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientShipmentSegmentsHistory(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список клиентов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clients(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clients(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление напоминаний клиента
     * @param {string} code Код напоминания по клиенту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsAttentionsItemDelete(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsAttentionsItemDelete(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Редактирование напоминаний клиента
     * @param {string} code Код напоминания по клиенту
     * @param {ClientsAttentionsItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsAttentionsItemSave(
      code: string,
      request: ClientsAttentionsItemSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsAttentionsItemSaveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsAttentionsItemSave(code, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник причин напоминаний по клиенту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsAttentionsReasons(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsAttentionsReasonsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsAttentionsReasons(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список заявок на изменение клиента
     * @param {string} customerCode
     * @param {string} [branchOfficeCode]
     * @param {string} [stateCode] Статус заявки (/adm/rad#/customer_change_request_state)
     * @param {string} [kindCode] Вид заявки (adm/rad#/customer_change_request_kind)
     * @param {boolean} [active] Показать активные заявки. Можно совмещать с stateCode
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsChangeRequests(
      customerCode: string,
      branchOfficeCode?: string,
      stateCode?: string,
      kindCode?: string,
      active?: boolean,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsChangeRequestsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsChangeRequests(
        customerCode,
        branchOfficeCode,
        stateCode,
        kindCode,
        active,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создать заявку на изменение клиента
     * @param {ClientsChangeRequestCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsChangeRequestsCreate(
      request: ClientsChangeRequestCreateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsChangeRequest>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsChangeRequestsCreate(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Закрыть доступ для филиала
     * @param {string} customerCode
     * @param {ClientsCloseOfficeAccessRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsCloseOfficeAccess(
      customerCode: string,
      request: ClientsCloseOfficeAccessRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsCloseOfficeAccess(customerCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Документы лимит-кредит
     * @param {string} customerCode
     * @param {string} [branchOfficeCode]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsCreditLimitDocuments(
      customerCode: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsCreditLimitDocumentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsCreditLimitDocuments(
        customerCode,
        branchOfficeCode,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список клиентов
     * @param {ClientsForFilterRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsForFilter(
      request: ClientsForFilterRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsForFilterResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsForFilter(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Детали по клиенту
     * @param {string} customerCode
     * @param {string} [month] Год и месяц
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItem(
      customerCode: string,
      month?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItem(customerCode, month, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список адресов клиента
     * @param {string} customerCode
     * @param {string} [consigneeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemAddresses(
      customerCode: string,
      consigneeCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemAddressesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemAddresses(customerCode, consigneeCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Генерация кода для нового адреса
     * @param {string} customerCode
     * @param {string} frontCode Код нового адреса на фронте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemAddressesAdd(
      customerCode: string,
      frontCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemAddressesAddResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemAddressesAdd(customerCode, frontCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновление адреса клиента
     * @param {string} customerCode
     * @param {string} addressCode
     * @param {ClientsItemAddressesItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemAddressesItemSave(
      customerCode: string,
      addressCode: string,
      request: ClientsItemAddressesItemSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemAddressesItemSaveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemAddressesItemSave(customerCode, addressCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список напоминаний клиента
     * @param {string} customerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemAttentions(
      customerCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemAttentionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemAttentions(customerCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создание напоминания клиента
     * @param {string} customerCode
     * @param {ClientsItemAttentionsCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemAttentionsCreate(
      customerCode: string,
      request: ClientsItemAttentionsCreateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemAttentionsCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemAttentionsCreate(customerCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary История изменения напоминаний клиента
     * @param {string} customerCode
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemAttentionsHistory(
      customerCode: string,
      dateFrom?: string,
      dateTo?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemAttentionsHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemAttentionsHistory(customerCode, dateFrom, dateTo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список грузополучателей
     * @param {string} customerCode
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemConsignees(
      customerCode: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemConsigneesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemConsignees(customerCode, page, count, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список договоров с клиентом
     * @param {string} customerCode
     * @param {string} [kind] Тип договора (with_buyer, with_supplier, other)
     * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {boolean} [withDrafts] deprecated. use preset. Показывать черновики
     * @param {boolean} [forDeals] deprecated. use preset. Показывать только для заявок
     * @param {string} [preset] Пресеты для контрактов (with_drafts, for_deals, for_additional)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemContracts(
      customerCode: string,
      kind?: string,
      branchOfficeCode?: string,
      withDrafts?: boolean,
      forDeals?: boolean,
      preset?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemContractsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemContracts(
        customerCode,
        kind,
        branchOfficeCode,
        withDrafts,
        forDeals,
        preset,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Состояние кредитования клиента
     * @param {string} customerCode
     * @param {string} [branchOfficeCode]
     * @param {boolean} [makeEsr] Выполнить запрос актуального статуса во внешнюю систему
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemCreditState(
      customerCode: string,
      branchOfficeCode?: string,
      makeEsr?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemCreditStateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemCreditState(customerCode, branchOfficeCode, makeEsr, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список заявок на сделку
     * @param {string} customerCode
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
     * @param {Array<string>} [stateCodes] Коды статусов
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [month] Год и месяц
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemDeals(
      customerCode: string,
      branchOfficeCodes?: Array<string>,
      stateCode?: string,
      stateCodes?: Array<string>,
      page?: number,
      count?: number,
      month?: string,
      dateFrom?: string,
      dateTo?: string,
      containsAttachments?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemDealsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemDeals(
        customerCode,
        branchOfficeCodes,
        stateCode,
        stateCodes,
        page,
        count,
        month,
        dateFrom,
        dateTo,
        containsAttachments,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary График маржинальности по клиенту
     * @param {string} customerCode
     * @param {string} [dealCode] Код сделки, которая будет добавлена последней точкой на график
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemMarginalityPlot(
      customerCode: string,
      dealCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemMarginalityPlotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemMarginalityPlot(customerCode, dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Статистика маржинальности на странице клиента
     * @param {string} customerCode
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemMarginalityStat(
      customerCode: string,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemMarginalityStatResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemMarginalityStat(customerCode, date, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Сделки клиента - по сути заявки в лучшем статусе
     * @param {string} customerCode
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [month] Год и месяц
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [hasBills] Фильтр по наличию счета
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemOrders(
      customerCode: string,
      page?: number,
      count?: number,
      month?: string,
      dateFrom?: string,
      dateTo?: string,
      hasBills?: boolean,
      containsAttachments?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemOrdersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemOrders(
        customerCode,
        page,
        count,
        month,
        dateFrom,
        dateTo,
        hasBills,
        containsAttachments,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновление клиента
     * @param {string} customerCode
     * @param {ClientsItemSaveRequest} request
     * @param {string} [month] Год и месяц
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsItemSave(
      customerCode: string,
      request: ClientsItemSaveRequest,
      month?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsItemResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsItemSave(customerCode, request, month, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удалить взаимоотношения для филиала
     * @param {string} customerCode
     * @param {ClientsRemoveOfficeRelationshipsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsRemoveOfficeRelationships(
      customerCode: string,
      request: ClientsRemoveOfficeRelationshipsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsRemoveOfficeRelationships(customerCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Цепочка документов к отгрузке
     * @param {ClientsShipmentsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clientsShipmentsThread(
      request: ClientsShipmentsThreadRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsShipmentsThreadResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clientsShipmentsThread(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список видов соглашения договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractAgreementTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractAgreementTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractAgreementTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список договоров - соглашений по ценам с клиентом
     * @param {string} [customerCode] deprecated. use customerCodes
     * @param {Array<string>} [customerCodes]
     * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [query] filter for agreements
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {Array<number>} [marketDeviationLevel] Уровень отклонения цены (от -2 - хуже максимального отклонения для филиала до 2)
     * @param {Array<number>} [stateIds] Состояние соглашения, 1 - на согласовании, 2 - согласовано, 3 - активно, 4 - неактивно
     * @param {Array<string>} [approvalStateCodes] Коды статусов согласования документов
     * @param {Array<string>} [documentTypeCodes] Коды типов прикрепленных документов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreements(
      customerCode?: string,
      customerCodes?: Array<string>,
      branchOfficeCode?: string,
      branchOfficeCodes?: Array<string>,
      page?: number,
      count?: number,
      query?: string,
      employeeSet?: string,
      dateFrom?: string,
      dateTo?: string,
      marketDeviationLevel?: Array<number>,
      stateIds?: Array<number>,
      approvalStateCodes?: Array<string>,
      documentTypeCodes?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreements(
        customerCode,
        customerCodes,
        branchOfficeCode,
        branchOfficeCodes,
        page,
        count,
        query,
        employeeSet,
        dateFrom,
        dateTo,
        marketDeviationLevel,
        stateIds,
        approvalStateCodes,
        documentTypeCodes,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отправка на согласование или согласование соглашения
     * @param {ContractCostAgreementsApprovalConfirmRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsApprovalConfirm(
      request: ContractCostAgreementsApprovalConfirmRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsApprovalConfirmResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsApprovalConfirm(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отклонение соглашения
     * @param {ContractCostAgreementsApprovalDeclineRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsApprovalDecline(
      request: ContractCostAgreementsApprovalDeclineRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsApprovalDeclineResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsApprovalDecline(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отзыв заявки на согласование соглашения
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsApprovalHistory(
      agreementCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsApprovalHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsApprovalHistory(agreementCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отзыв заявки на согласование соглашения
     * @param {ContractCostAgreementsApprovalWithdrawRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsApprovalWithdraw(
      request: ContractCostAgreementsApprovalWithdrawRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsApprovalWithdrawResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsApprovalWithdraw(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Клонирование договора-соглашения
     * @param {ContractCostAgreementsCloneRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsClone(
      request: ContractCostAgreementsCloneRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsCloneResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsClone(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создание договора-соглашения
     * @param {ContractCostAgreementsCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsCreate(
      request: ContractCostAgreementsCreateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsCreate(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Договор - соглашение по ценам с клиентом
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItem(
      agreementCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsItemResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItem(agreementCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Договор - соглашение по ценам с клиентом
     * @param {string} agreementCode
     * @param {ContractCostAgreementsItemAlternativesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemAlternatives(
      agreementCode: string,
      request: ContractCostAgreementsItemAlternativesRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsItemAlternativesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemAlternatives(agreementCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список доступных реализаторов
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemDistributors(
      agreementCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsItemDistributorsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemDistributors(agreementCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление документа из заявки/сделки
     * @param {string} agreementCode Код соглашения
     * @param {string} docCode Код документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemDocsItemDelete(
      agreementCode: string,
      docCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemDocsItemDelete(agreementCode, docCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Загрузка нового документа в заявку/сделку
     * @param {string} agreementCode Код соглашения
     * @param {string} documentTypeCode Тип загружаемого документа
     * @param {any} file Файл к загрузке
     * @param {string} [description]
     * @param {string} [positionCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemDocsUpload(
      agreementCode: string,
      documentTypeCode: string,
      file: any,
      description?: string,
      positionCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealDocument>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemDocsUpload(
        agreementCode,
        documentTypeCode,
        file,
        description,
        positionCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Установка ограничения на редактирование
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemLock(
      agreementCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemLock(agreementCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование PDF коммерческого предложения из соглашения
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemPdf(
      agreementCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsItemPdfResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemPdf(agreementCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Загрузка SKU с ценами из эксель-файла
     * @param {string} agreementCode Код соглашения
     * @param {any} file Файл к загрузке
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemPricesUpload(
      agreementCode: string,
      file: any,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreement>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemPricesUpload(agreementCode, file, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Внесение изменений в договор-соглашение
     * @param {string} agreementCode
     * @param {ContractCostAgreementsItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemSave(
      agreementCode: string,
      request: ContractCostAgreementsItemSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsItemSaveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemSave(agreementCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Получение информации о документе Спецификация соглашения. Сам документ можно получить в HTML и PDF-формате (ContractCostAgreementsItemSpecificationHTML, ContractCostAgreementsItemSpecificationPdf) В ответе ContractCostAgreementsItemSpecificationHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью ContractCostAgreementsItemSpecificationUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
     * @summary Получение информации о документе Спецификация соглашения
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemSpecification(
      agreementCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsItemSpecification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemSpecification(agreementCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование HTML спецификации соглашения
     * @param {string} agreementCode
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemSpecificationHTML(
      agreementCode: string,
      withoutStampAndSign?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemSpecificationHTML(
        agreementCode,
        withoutStampAndSign,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование PDF спецификации соглашения
     * @param {string} agreementCode
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemSpecificationPdf(
      agreementCode: string,
      withoutStampAndSign?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemSpecificationPdf(
        agreementCode,
        withoutStampAndSign,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновление текстовок и другой информации документа Спецификация соглашения
     * @param {string} agreementCode
     * @param {ContractCostAgreementsItemSpecificationUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemSpecificationUpdate(
      agreementCode: string,
      request: ContractCostAgreementsItemSpecificationUpdateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsItemSpecification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemSpecificationUpdate(
        agreementCode,
        request,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Снятие ограничения на редактирование
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsItemUnlock(
      agreementCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsItemUnlock(agreementCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Move agreement position
     * @param {ContractCostAgreementsPositionsMoveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsPositionsMove(
      request: ContractCostAgreementsPositionsMoveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsPositionsMoveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsPositionsMove(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Цепочка документов по соглашению
     * @param {ContractCostAgreementsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractCostAgreementsThread(
      request: ContractCostAgreementsThreadRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractCostAgreementsThreadResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractCostAgreementsThread(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список типов файлов договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractFileTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractFileTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractFileTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список файлов договора
     * @param {string} contractCode Код договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractFiles(
      contractCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractFilesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractFiles(contractCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Удаляет файл договора
     * @param {string} code Код файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractFilesDelete(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractFilesDelete(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список форм договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractForms(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractFormsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractForms(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Загрузка нового файла договора
     * @param {string} code Код договора
     * @param {string} title Заголовок
     * @param {string} fileTypeCode Код типа файла
     * @param {any} file Файл к загрузке
     * @param {string} [fileCode] Код файла договора (если передан то создается новая версия)
     * @param {string} [description] Описание
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractItemFilesUpload(
      code: string,
      title: string,
      fileTypeCode: string,
      file: any,
      fileCode?: string,
      description?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractItemFilesUploadResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractItemFilesUpload(
        code,
        title,
        fileTypeCode,
        file,
        fileCode,
        description,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Переотправляет договор с клиентом в СЭД
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractItemResendingCreate(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractItemResendingCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractItemResendingCreate(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список видов договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractKinds(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractKindsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractKinds(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список типов пролонгации договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractRenewalTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractRenewalTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractRenewalTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список типов документа подписанта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractSignerDocumentTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractSignerDocumentTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractSignerDocumentTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список типов подписываемых договоров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractSignerTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractSignerTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractSignerTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список статусов договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractStatuses(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractStatusesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractStatuses(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список видов договора
     * @param {string} [customerCode] Код клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractTypes(
      customerCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractTypes(customerCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список договоров с клиентом
     * @param {string} [code]
     * @param {string} [customerCode] Код клиента
     * @param {string} [kind] Тип договора (with_buyer, with_supplier, other)
     * @param {string} [branchOfficeCode] deprecated. use branchOfficeCodes. Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [query] Фильтр
     * @param {string} [statusCode] Статус (draft, approval, active, inactive)
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contracts(
      code?: string,
      customerCode?: string,
      kind?: string,
      branchOfficeCode?: string,
      branchOfficeCodes?: Array<string>,
      employeeSet?: string,
      query?: string,
      statusCode?: string,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contracts(
        code,
        customerCode,
        kind,
        branchOfficeCode,
        branchOfficeCodes,
        employeeSet,
        query,
        statusCode,
        dateFrom,
        dateTo,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создает черновик договора с клиентом
     * @param {string} customerCode Код клиента
     * @param {string} typeCode Вид договора (Договор на реализацию металла с отсрочкой и тд)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractsItemDraftCreate(
      customerCode: string,
      typeCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractsItemDraftCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractsItemDraftCreate(customerCode, typeCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаляет черновик договора с клиентом
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractsItemDraftDelete(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractsItemDraftDelete(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Поиск черновика договора с клиентом
     * @param {string} customerCode Код клиента
     * @param {string} [typeCode] Вид договора (Договор на реализацию металла с отсрочкой и тд)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractsItemDraftLookup(
      customerCode: string,
      typeCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractsItemDraftLookupResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractsItemDraftLookup(customerCode, typeCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отправляет черновик договора с клиентом в СЭД
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractsItemDraftSendingCreate(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractsItemDraftSendingCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractsItemDraftSendingCreate(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список отправок черновика договора с клиентом в СЭД
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractsItemDraftSendingList(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractsItemDraftSendingListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractsItemDraftSendingList(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновляет черновик договора с клиентом
     * @param {string} code Код черновика
     * @param {ContractsItemDraftUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contractsItemDraftUpdate(
      code: string,
      request: ContractsItemDraftUpdateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractsItemDraftUpdateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contractsItemDraftUpdate(code, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CustomerApiFp(configuration);
  return {
    /**
     *
     * @summary Справочник статусов активности клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientActivityStatuses(options?: any): AxiosPromise<ClientActivityStatusesResponse> {
      return localVarFp.clientActivityStatuses(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник статусов участия во внешней системе СЭД клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientDocFlowStates(options?: any): AxiosPromise<ClientDocFlowStatesResponse> {
      return localVarFp.clientDocFlowStates(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создать лида
     * @param {ClientLeadsCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLeadsCreate(request: ClientLeadsCreateRequest, options?: any): AxiosPromise<ClientsItemResponse> {
      return localVarFp.clientLeadsCreate(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создать отправку клиента
     * @param {string} customerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLeadsItemSendingCreate(customerCode: string, options?: any): AxiosPromise<ClientLeadsItemSendingCreateResponse> {
      return localVarFp.clientLeadsItemSendingCreate(customerCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Отправки клиента
     * @param {string} customerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLeadsItemSendingList(customerCode: string, options?: any): AxiosPromise<ClientLeadsItemSendingListResponse> {
      return localVarFp.clientLeadsItemSendingList(customerCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Получить информацию о юридическом лице перед созданием лида
     * @param {ClientLeadsLookupRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientLeadsLookup(request: ClientLeadsLookupRequest, options?: any): AxiosPromise<ClientLeadsLookupResponse> {
      return localVarFp.clientLeadsLookup(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник отраслей клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientOccupations(options?: any): AxiosPromise<ClientOccupationsResponse> {
      return localVarFp.clientOccupations(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник подотраслей клиента
     * @param {Array<string>} [occupationCodes] Список отраслей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientOccupationsSpecified(occupationCodes?: Array<string>, options?: any): AxiosPromise<ClientOccupationsSpecifiedResponse> {
      return localVarFp.clientOccupationsSpecified(occupationCodes, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список сегментов клиента в разрезе филиала
     * @param {ClientOfficeSegmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientOfficeSegments(request: ClientOfficeSegmentsRequest, options?: any): AxiosPromise<ClientOfficeSegmentsResponse> {
      return localVarFp.clientOfficeSegments(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник сегментов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientSegments(options?: any): AxiosPromise<ClientSegmentsResponse> {
      return localVarFp.clientSegments(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник сегментов клиента
     * @param {'consuming' | 'shipment' | 'recency' | 'frequency' | 'monetary'} segmentType Список сегментов клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientSegmentsDictionary(
      segmentType: 'consuming' | 'shipment' | 'recency' | 'frequency' | 'monetary',
      options?: any
    ): AxiosPromise<InlineResponse200> {
      return localVarFp.clientSegmentsDictionary(segmentType, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список сегментов по отгрузке клиента в разрезе филиала
     * @param {ClientShipmentSegmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientShipmentSegments(request: ClientShipmentSegmentsRequest, options?: any): AxiosPromise<ClientShipmentSegmentsResponse> {
      return localVarFp.clientShipmentSegments(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список изменений сегментов по отгрузке клиента в разрезе филиала
     * @param {ClientShipmentSegmentsHistoryRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientShipmentSegmentsHistory(
      request: ClientShipmentSegmentsHistoryRequest,
      options?: any
    ): AxiosPromise<ClientShipmentSegmentsHistoryResponse> {
      return localVarFp.clientShipmentSegmentsHistory(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список клиентов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clients(options?: any): AxiosPromise<ClientsResponse> {
      return localVarFp.clients(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление напоминаний клиента
     * @param {string} code Код напоминания по клиенту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsAttentionsItemDelete(code: string, options?: any): AxiosPromise<void> {
      return localVarFp.clientsAttentionsItemDelete(code, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Редактирование напоминаний клиента
     * @param {string} code Код напоминания по клиенту
     * @param {ClientsAttentionsItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsAttentionsItemSave(
      code: string,
      request: ClientsAttentionsItemSaveRequest,
      options?: any
    ): AxiosPromise<ClientsAttentionsItemSaveResponse> {
      return localVarFp.clientsAttentionsItemSave(code, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник причин напоминаний по клиенту
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsAttentionsReasons(options?: any): AxiosPromise<ClientsAttentionsReasonsResponse> {
      return localVarFp.clientsAttentionsReasons(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список заявок на изменение клиента
     * @param {string} customerCode
     * @param {string} [branchOfficeCode]
     * @param {string} [stateCode] Статус заявки (/adm/rad#/customer_change_request_state)
     * @param {string} [kindCode] Вид заявки (adm/rad#/customer_change_request_kind)
     * @param {boolean} [active] Показать активные заявки. Можно совмещать с stateCode
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsChangeRequests(
      customerCode: string,
      branchOfficeCode?: string,
      stateCode?: string,
      kindCode?: string,
      active?: boolean,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<ClientsChangeRequestsResponse> {
      return localVarFp
        .clientsChangeRequests(customerCode, branchOfficeCode, stateCode, kindCode, active, page, count, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создать заявку на изменение клиента
     * @param {ClientsChangeRequestCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsChangeRequestsCreate(request: ClientsChangeRequestCreateRequest, options?: any): AxiosPromise<ClientsChangeRequest> {
      return localVarFp.clientsChangeRequestsCreate(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Закрыть доступ для филиала
     * @param {string} customerCode
     * @param {ClientsCloseOfficeAccessRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsCloseOfficeAccess(customerCode: string, request: ClientsCloseOfficeAccessRequest, options?: any): AxiosPromise<void> {
      return localVarFp.clientsCloseOfficeAccess(customerCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Документы лимит-кредит
     * @param {string} customerCode
     * @param {string} [branchOfficeCode]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsCreditLimitDocuments(
      customerCode: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<ClientsCreditLimitDocumentsResponse> {
      return localVarFp
        .clientsCreditLimitDocuments(customerCode, branchOfficeCode, page, count, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список клиентов
     * @param {ClientsForFilterRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsForFilter(request: ClientsForFilterRequest, options?: any): AxiosPromise<ClientsForFilterResponse> {
      return localVarFp.clientsForFilter(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Детали по клиенту
     * @param {string} customerCode
     * @param {string} [month] Год и месяц
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItem(customerCode: string, month?: string, options?: any): AxiosPromise<ClientsItemResponse> {
      return localVarFp.clientsItem(customerCode, month, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список адресов клиента
     * @param {string} customerCode
     * @param {string} [consigneeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAddresses(customerCode: string, consigneeCode?: string, options?: any): AxiosPromise<ClientsItemAddressesResponse> {
      return localVarFp.clientsItemAddresses(customerCode, consigneeCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Генерация кода для нового адреса
     * @param {string} customerCode
     * @param {string} frontCode Код нового адреса на фронте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAddressesAdd(customerCode: string, frontCode: string, options?: any): AxiosPromise<ClientsItemAddressesAddResponse> {
      return localVarFp.clientsItemAddressesAdd(customerCode, frontCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление адреса клиента
     * @param {string} customerCode
     * @param {string} addressCode
     * @param {ClientsItemAddressesItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAddressesItemSave(
      customerCode: string,
      addressCode: string,
      request: ClientsItemAddressesItemSaveRequest,
      options?: any
    ): AxiosPromise<ClientsItemAddressesItemSaveResponse> {
      return localVarFp.clientsItemAddressesItemSave(customerCode, addressCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список напоминаний клиента
     * @param {string} customerCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAttentions(customerCode: string, options?: any): AxiosPromise<ClientsItemAttentionsResponse> {
      return localVarFp.clientsItemAttentions(customerCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создание напоминания клиента
     * @param {string} customerCode
     * @param {ClientsItemAttentionsCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAttentionsCreate(
      customerCode: string,
      request: ClientsItemAttentionsCreateRequest,
      options?: any
    ): AxiosPromise<ClientsItemAttentionsCreateResponse> {
      return localVarFp.clientsItemAttentionsCreate(customerCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary История изменения напоминаний клиента
     * @param {string} customerCode
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemAttentionsHistory(
      customerCode: string,
      dateFrom?: string,
      dateTo?: string,
      options?: any
    ): AxiosPromise<ClientsItemAttentionsHistoryResponse> {
      return localVarFp.clientsItemAttentionsHistory(customerCode, dateFrom, dateTo, options).then(request => request(axios, basePath));
    },
    /**
     * Список грузополучателей
     * @param {string} customerCode
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemConsignees(customerCode: string, page?: number, count?: number, options?: any): AxiosPromise<ClientsItemConsigneesResponse> {
      return localVarFp.clientsItemConsignees(customerCode, page, count, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список договоров с клиентом
     * @param {string} customerCode
     * @param {string} [kind] Тип договора (with_buyer, with_supplier, other)
     * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {boolean} [withDrafts] deprecated. use preset. Показывать черновики
     * @param {boolean} [forDeals] deprecated. use preset. Показывать только для заявок
     * @param {string} [preset] Пресеты для контрактов (with_drafts, for_deals, for_additional)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemContracts(
      customerCode: string,
      kind?: string,
      branchOfficeCode?: string,
      withDrafts?: boolean,
      forDeals?: boolean,
      preset?: string,
      options?: any
    ): AxiosPromise<ClientsItemContractsResponse> {
      return localVarFp
        .clientsItemContracts(customerCode, kind, branchOfficeCode, withDrafts, forDeals, preset, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Состояние кредитования клиента
     * @param {string} customerCode
     * @param {string} [branchOfficeCode]
     * @param {boolean} [makeEsr] Выполнить запрос актуального статуса во внешнюю систему
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemCreditState(
      customerCode: string,
      branchOfficeCode?: string,
      makeEsr?: boolean,
      options?: any
    ): AxiosPromise<ClientsItemCreditStateResponse> {
      return localVarFp.clientsItemCreditState(customerCode, branchOfficeCode, makeEsr, options).then(request => request(axios, basePath));
    },
    /**
     * Список заявок на сделку
     * @param {string} customerCode
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
     * @param {Array<string>} [stateCodes] Коды статусов
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [month] Год и месяц
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemDeals(
      customerCode: string,
      branchOfficeCodes?: Array<string>,
      stateCode?: string,
      stateCodes?: Array<string>,
      page?: number,
      count?: number,
      month?: string,
      dateFrom?: string,
      dateTo?: string,
      containsAttachments?: boolean,
      options?: any
    ): AxiosPromise<ClientsItemDealsResponse> {
      return localVarFp
        .clientsItemDeals(
          customerCode,
          branchOfficeCodes,
          stateCode,
          stateCodes,
          page,
          count,
          month,
          dateFrom,
          dateTo,
          containsAttachments,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary График маржинальности по клиенту
     * @param {string} customerCode
     * @param {string} [dealCode] Код сделки, которая будет добавлена последней точкой на график
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemMarginalityPlot(customerCode: string, dealCode?: string, options?: any): AxiosPromise<ClientsItemMarginalityPlotResponse> {
      return localVarFp.clientsItemMarginalityPlot(customerCode, dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Статистика маржинальности на странице клиента
     * @param {string} customerCode
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemMarginalityStat(customerCode: string, date?: string, options?: any): AxiosPromise<ClientsItemMarginalityStatResponse> {
      return localVarFp.clientsItemMarginalityStat(customerCode, date, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Сделки клиента - по сути заявки в лучшем статусе
     * @param {string} customerCode
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [month] Год и месяц
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [hasBills] Фильтр по наличию счета
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemOrders(
      customerCode: string,
      page?: number,
      count?: number,
      month?: string,
      dateFrom?: string,
      dateTo?: string,
      hasBills?: boolean,
      containsAttachments?: boolean,
      options?: any
    ): AxiosPromise<ClientsItemOrdersResponse> {
      return localVarFp
        .clientsItemOrders(customerCode, page, count, month, dateFrom, dateTo, hasBills, containsAttachments, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление клиента
     * @param {string} customerCode
     * @param {ClientsItemSaveRequest} request
     * @param {string} [month] Год и месяц
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsItemSave(
      customerCode: string,
      request: ClientsItemSaveRequest,
      month?: string,
      options?: any
    ): AxiosPromise<ClientsItemResponse> {
      return localVarFp.clientsItemSave(customerCode, request, month, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Удалить взаимоотношения для филиала
     * @param {string} customerCode
     * @param {ClientsRemoveOfficeRelationshipsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsRemoveOfficeRelationships(
      customerCode: string,
      request: ClientsRemoveOfficeRelationshipsRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp.clientsRemoveOfficeRelationships(customerCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Цепочка документов к отгрузке
     * @param {ClientsShipmentsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientsShipmentsThread(request: ClientsShipmentsThreadRequest, options?: any): AxiosPromise<ClientsShipmentsThreadResponse> {
      return localVarFp.clientsShipmentsThread(request, options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список видов соглашения договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractAgreementTypes(options?: any): AxiosPromise<ContractAgreementTypesResponse> {
      return localVarFp.contractAgreementTypes(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список договоров - соглашений по ценам с клиентом
     * @param {string} [customerCode] deprecated. use customerCodes
     * @param {Array<string>} [customerCodes]
     * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [query] filter for agreements
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {Array<number>} [marketDeviationLevel] Уровень отклонения цены (от -2 - хуже максимального отклонения для филиала до 2)
     * @param {Array<number>} [stateIds] Состояние соглашения, 1 - на согласовании, 2 - согласовано, 3 - активно, 4 - неактивно
     * @param {Array<string>} [approvalStateCodes] Коды статусов согласования документов
     * @param {Array<string>} [documentTypeCodes] Коды типов прикрепленных документов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreements(
      customerCode?: string,
      customerCodes?: Array<string>,
      branchOfficeCode?: string,
      branchOfficeCodes?: Array<string>,
      page?: number,
      count?: number,
      query?: string,
      employeeSet?: string,
      dateFrom?: string,
      dateTo?: string,
      marketDeviationLevel?: Array<number>,
      stateIds?: Array<number>,
      approvalStateCodes?: Array<string>,
      documentTypeCodes?: Array<string>,
      options?: any
    ): AxiosPromise<ContractCostAgreementsResponse> {
      return localVarFp
        .contractCostAgreements(
          customerCode,
          customerCodes,
          branchOfficeCode,
          branchOfficeCodes,
          page,
          count,
          query,
          employeeSet,
          dateFrom,
          dateTo,
          marketDeviationLevel,
          stateIds,
          approvalStateCodes,
          documentTypeCodes,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Отправка на согласование или согласование соглашения
     * @param {ContractCostAgreementsApprovalConfirmRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsApprovalConfirm(
      request: ContractCostAgreementsApprovalConfirmRequest,
      options?: any
    ): AxiosPromise<ContractCostAgreementsApprovalConfirmResponse> {
      return localVarFp.contractCostAgreementsApprovalConfirm(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Отклонение соглашения
     * @param {ContractCostAgreementsApprovalDeclineRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsApprovalDecline(
      request: ContractCostAgreementsApprovalDeclineRequest,
      options?: any
    ): AxiosPromise<ContractCostAgreementsApprovalDeclineResponse> {
      return localVarFp.contractCostAgreementsApprovalDecline(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Отзыв заявки на согласование соглашения
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsApprovalHistory(
      agreementCode: string,
      options?: any
    ): AxiosPromise<ContractCostAgreementsApprovalHistoryResponse> {
      return localVarFp.contractCostAgreementsApprovalHistory(agreementCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Отзыв заявки на согласование соглашения
     * @param {ContractCostAgreementsApprovalWithdrawRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsApprovalWithdraw(
      request: ContractCostAgreementsApprovalWithdrawRequest,
      options?: any
    ): AxiosPromise<ContractCostAgreementsApprovalWithdrawResponse> {
      return localVarFp.contractCostAgreementsApprovalWithdraw(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Клонирование договора-соглашения
     * @param {ContractCostAgreementsCloneRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsClone(
      request: ContractCostAgreementsCloneRequest,
      options?: any
    ): AxiosPromise<ContractCostAgreementsCloneResponse> {
      return localVarFp.contractCostAgreementsClone(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создание договора-соглашения
     * @param {ContractCostAgreementsCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsCreate(
      request: ContractCostAgreementsCreateRequest,
      options?: any
    ): AxiosPromise<ContractCostAgreementsCreateResponse> {
      return localVarFp.contractCostAgreementsCreate(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Договор - соглашение по ценам с клиентом
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItem(agreementCode: string, options?: any): AxiosPromise<ContractCostAgreementsItemResponse> {
      return localVarFp.contractCostAgreementsItem(agreementCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Договор - соглашение по ценам с клиентом
     * @param {string} agreementCode
     * @param {ContractCostAgreementsItemAlternativesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemAlternatives(
      agreementCode: string,
      request: ContractCostAgreementsItemAlternativesRequest,
      options?: any
    ): AxiosPromise<ContractCostAgreementsItemAlternativesResponse> {
      return localVarFp.contractCostAgreementsItemAlternatives(agreementCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список доступных реализаторов
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemDistributors(
      agreementCode: string,
      options?: any
    ): AxiosPromise<ContractCostAgreementsItemDistributorsResponse> {
      return localVarFp.contractCostAgreementsItemDistributors(agreementCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление документа из заявки/сделки
     * @param {string} agreementCode Код соглашения
     * @param {string} docCode Код документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemDocsItemDelete(agreementCode: string, docCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.contractCostAgreementsItemDocsItemDelete(agreementCode, docCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузка нового документа в заявку/сделку
     * @param {string} agreementCode Код соглашения
     * @param {string} documentTypeCode Тип загружаемого документа
     * @param {any} file Файл к загрузке
     * @param {string} [description]
     * @param {string} [positionCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemDocsUpload(
      agreementCode: string,
      documentTypeCode: string,
      file: any,
      description?: string,
      positionCode?: string,
      options?: any
    ): AxiosPromise<DealDocument> {
      return localVarFp
        .contractCostAgreementsItemDocsUpload(agreementCode, documentTypeCode, file, description, positionCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Установка ограничения на редактирование
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemLock(agreementCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.contractCostAgreementsItemLock(agreementCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование PDF коммерческого предложения из соглашения
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemPdf(agreementCode: string, options?: any): AxiosPromise<ContractCostAgreementsItemPdfResponse> {
      return localVarFp.contractCostAgreementsItemPdf(agreementCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузка SKU с ценами из эксель-файла
     * @param {string} agreementCode Код соглашения
     * @param {any} file Файл к загрузке
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemPricesUpload(agreementCode: string, file: any, options?: any): AxiosPromise<ContractCostAgreement> {
      return localVarFp.contractCostAgreementsItemPricesUpload(agreementCode, file, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Внесение изменений в договор-соглашение
     * @param {string} agreementCode
     * @param {ContractCostAgreementsItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemSave(
      agreementCode: string,
      request: ContractCostAgreementsItemSaveRequest,
      options?: any
    ): AxiosPromise<ContractCostAgreementsItemSaveResponse> {
      return localVarFp.contractCostAgreementsItemSave(agreementCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Получение информации о документе Спецификация соглашения. Сам документ можно получить в HTML и PDF-формате (ContractCostAgreementsItemSpecificationHTML, ContractCostAgreementsItemSpecificationPdf) В ответе ContractCostAgreementsItemSpecificationHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью ContractCostAgreementsItemSpecificationUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
     * @summary Получение информации о документе Спецификация соглашения
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemSpecification(agreementCode: string, options?: any): AxiosPromise<ContractCostAgreementsItemSpecification> {
      return localVarFp.contractCostAgreementsItemSpecification(agreementCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование HTML спецификации соглашения
     * @param {string} agreementCode
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemSpecificationHTML(agreementCode: string, withoutStampAndSign?: boolean, options?: any): AxiosPromise<void> {
      return localVarFp
        .contractCostAgreementsItemSpecificationHTML(agreementCode, withoutStampAndSign, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование PDF спецификации соглашения
     * @param {string} agreementCode
     * @param {boolean} [withoutStampAndSign] Без подписи и печати
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemSpecificationPdf(agreementCode: string, withoutStampAndSign?: boolean, options?: any): AxiosPromise<void> {
      return localVarFp
        .contractCostAgreementsItemSpecificationPdf(agreementCode, withoutStampAndSign, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление текстовок и другой информации документа Спецификация соглашения
     * @param {string} agreementCode
     * @param {ContractCostAgreementsItemSpecificationUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemSpecificationUpdate(
      agreementCode: string,
      request: ContractCostAgreementsItemSpecificationUpdateRequest,
      options?: any
    ): AxiosPromise<ContractCostAgreementsItemSpecification> {
      return localVarFp
        .contractCostAgreementsItemSpecificationUpdate(agreementCode, request, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Снятие ограничения на редактирование
     * @param {string} agreementCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsItemUnlock(agreementCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.contractCostAgreementsItemUnlock(agreementCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Move agreement position
     * @param {ContractCostAgreementsPositionsMoveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsPositionsMove(
      request: ContractCostAgreementsPositionsMoveRequest,
      options?: any
    ): AxiosPromise<ContractCostAgreementsPositionsMoveResponse> {
      return localVarFp.contractCostAgreementsPositionsMove(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Цепочка документов по соглашению
     * @param {ContractCostAgreementsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractCostAgreementsThread(
      request: ContractCostAgreementsThreadRequest,
      options?: any
    ): AxiosPromise<ContractCostAgreementsThreadResponse> {
      return localVarFp.contractCostAgreementsThread(request, options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список типов файлов договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractFileTypes(options?: any): AxiosPromise<ContractFileTypesResponse> {
      return localVarFp.contractFileTypes(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список файлов договора
     * @param {string} contractCode Код договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractFiles(contractCode: string, options?: any): AxiosPromise<ContractFilesResponse> {
      return localVarFp.contractFiles(contractCode, options).then(request => request(axios, basePath));
    },
    /**
     * Удаляет файл договора
     * @param {string} code Код файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractFilesDelete(code: string, options?: any): AxiosPromise<void> {
      return localVarFp.contractFilesDelete(code, options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список форм договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractForms(options?: any): AxiosPromise<ContractFormsResponse> {
      return localVarFp.contractForms(options).then(request => request(axios, basePath));
    },
    /**
     * Загрузка нового файла договора
     * @param {string} code Код договора
     * @param {string} title Заголовок
     * @param {string} fileTypeCode Код типа файла
     * @param {any} file Файл к загрузке
     * @param {string} [fileCode] Код файла договора (если передан то создается новая версия)
     * @param {string} [description] Описание
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractItemFilesUpload(
      code: string,
      title: string,
      fileTypeCode: string,
      file: any,
      fileCode?: string,
      description?: string,
      options?: any
    ): AxiosPromise<ContractItemFilesUploadResponse> {
      return localVarFp
        .contractItemFilesUpload(code, title, fileTypeCode, file, fileCode, description, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Переотправляет договор с клиентом в СЭД
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractItemResendingCreate(code: string, options?: any): AxiosPromise<ContractItemResendingCreateResponse> {
      return localVarFp.contractItemResendingCreate(code, options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список видов договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractKinds(options?: any): AxiosPromise<ContractKindsResponse> {
      return localVarFp.contractKinds(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список типов пролонгации договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractRenewalTypes(options?: any): AxiosPromise<ContractRenewalTypesResponse> {
      return localVarFp.contractRenewalTypes(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список типов документа подписанта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractSignerDocumentTypes(options?: any): AxiosPromise<ContractSignerDocumentTypesResponse> {
      return localVarFp.contractSignerDocumentTypes(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список типов подписываемых договоров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractSignerTypes(options?: any): AxiosPromise<ContractSignerTypesResponse> {
      return localVarFp.contractSignerTypes(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список статусов договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractStatuses(options?: any): AxiosPromise<ContractStatusesResponse> {
      return localVarFp.contractStatuses(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список видов договора
     * @param {string} [customerCode] Код клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractTypes(customerCode?: string, options?: any): AxiosPromise<ContractTypesResponse> {
      return localVarFp.contractTypes(customerCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список договоров с клиентом
     * @param {string} [code]
     * @param {string} [customerCode] Код клиента
     * @param {string} [kind] Тип договора (with_buyer, with_supplier, other)
     * @param {string} [branchOfficeCode] deprecated. use branchOfficeCodes. Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [query] Фильтр
     * @param {string} [statusCode] Статус (draft, approval, active, inactive)
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contracts(
      code?: string,
      customerCode?: string,
      kind?: string,
      branchOfficeCode?: string,
      branchOfficeCodes?: Array<string>,
      employeeSet?: string,
      query?: string,
      statusCode?: string,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<ContractsResponse> {
      return localVarFp
        .contracts(
          code,
          customerCode,
          kind,
          branchOfficeCode,
          branchOfficeCodes,
          employeeSet,
          query,
          statusCode,
          dateFrom,
          dateTo,
          page,
          count,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создает черновик договора с клиентом
     * @param {string} customerCode Код клиента
     * @param {string} typeCode Вид договора (Договор на реализацию металла с отсрочкой и тд)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftCreate(customerCode: string, typeCode: string, options?: any): AxiosPromise<ContractsItemDraftCreateResponse> {
      return localVarFp.contractsItemDraftCreate(customerCode, typeCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Удаляет черновик договора с клиентом
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftDelete(code: string, options?: any): AxiosPromise<void> {
      return localVarFp.contractsItemDraftDelete(code, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Поиск черновика договора с клиентом
     * @param {string} customerCode Код клиента
     * @param {string} [typeCode] Вид договора (Договор на реализацию металла с отсрочкой и тд)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftLookup(customerCode: string, typeCode?: string, options?: any): AxiosPromise<ContractsItemDraftLookupResponse> {
      return localVarFp.contractsItemDraftLookup(customerCode, typeCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Отправляет черновик договора с клиентом в СЭД
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftSendingCreate(code: string, options?: any): AxiosPromise<ContractsItemDraftSendingCreateResponse> {
      return localVarFp.contractsItemDraftSendingCreate(code, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список отправок черновика договора с клиентом в СЭД
     * @param {string} code Код черновика
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftSendingList(code: string, options?: any): AxiosPromise<ContractsItemDraftSendingListResponse> {
      return localVarFp.contractsItemDraftSendingList(code, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Обновляет черновик договора с клиентом
     * @param {string} code Код черновика
     * @param {ContractsItemDraftUpdateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contractsItemDraftUpdate(
      code: string,
      request: ContractsItemDraftUpdateRequest,
      options?: any
    ): AxiosPromise<ContractsItemDraftUpdateResponse> {
      return localVarFp.contractsItemDraftUpdate(code, request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CustomerApi - interface
 * @export
 * @interface CustomerApi
 */
export interface CustomerApiInterface {
  /**
   *
   * @summary Справочник статусов активности клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientActivityStatuses(options?: AxiosRequestConfig): AxiosPromise<ClientActivityStatusesResponse>;

  /**
   *
   * @summary Справочник статусов участия во внешней системе СЭД клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientDocFlowStates(options?: AxiosRequestConfig): AxiosPromise<ClientDocFlowStatesResponse>;

  /**
   *
   * @summary Создать лида
   * @param {ClientLeadsCreateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientLeadsCreate(request: ClientLeadsCreateRequest, options?: AxiosRequestConfig): AxiosPromise<ClientsItemResponse>;

  /**
   *
   * @summary Создать отправку клиента
   * @param {string} customerCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientLeadsItemSendingCreate(customerCode: string, options?: AxiosRequestConfig): AxiosPromise<ClientLeadsItemSendingCreateResponse>;

  /**
   *
   * @summary Отправки клиента
   * @param {string} customerCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientLeadsItemSendingList(customerCode: string, options?: AxiosRequestConfig): AxiosPromise<ClientLeadsItemSendingListResponse>;

  /**
   *
   * @summary Получить информацию о юридическом лице перед созданием лида
   * @param {ClientLeadsLookupRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientLeadsLookup(request: ClientLeadsLookupRequest, options?: AxiosRequestConfig): AxiosPromise<ClientLeadsLookupResponse>;

  /**
   *
   * @summary Справочник отраслей клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientOccupations(options?: AxiosRequestConfig): AxiosPromise<ClientOccupationsResponse>;

  /**
   *
   * @summary Справочник подотраслей клиента
   * @param {Array<string>} [occupationCodes] Список отраслей
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientOccupationsSpecified(
    occupationCodes?: Array<string>,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientOccupationsSpecifiedResponse>;

  /**
   *
   * @summary Список сегментов клиента в разрезе филиала
   * @param {ClientOfficeSegmentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientOfficeSegments(request: ClientOfficeSegmentsRequest, options?: AxiosRequestConfig): AxiosPromise<ClientOfficeSegmentsResponse>;

  /**
   *
   * @summary Справочник сегментов клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientSegments(options?: AxiosRequestConfig): AxiosPromise<ClientSegmentsResponse>;

  /**
   *
   * @summary Справочник сегментов клиента
   * @param {'consuming' | 'shipment' | 'recency' | 'frequency' | 'monetary'} segmentType Список сегментов клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientSegmentsDictionary(
    segmentType: 'consuming' | 'shipment' | 'recency' | 'frequency' | 'monetary',
    options?: AxiosRequestConfig
  ): AxiosPromise<InlineResponse200>;

  /**
   *
   * @summary Список сегментов по отгрузке клиента в разрезе филиала
   * @param {ClientShipmentSegmentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientShipmentSegments(
    request: ClientShipmentSegmentsRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientShipmentSegmentsResponse>;

  /**
   *
   * @summary Список изменений сегментов по отгрузке клиента в разрезе филиала
   * @param {ClientShipmentSegmentsHistoryRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientShipmentSegmentsHistory(
    request: ClientShipmentSegmentsHistoryRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientShipmentSegmentsHistoryResponse>;

  /**
   *
   * @summary Список клиентов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clients(options?: AxiosRequestConfig): AxiosPromise<ClientsResponse>;

  /**
   *
   * @summary Удаление напоминаний клиента
   * @param {string} code Код напоминания по клиенту
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsAttentionsItemDelete(code: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Редактирование напоминаний клиента
   * @param {string} code Код напоминания по клиенту
   * @param {ClientsAttentionsItemSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsAttentionsItemSave(
    code: string,
    request: ClientsAttentionsItemSaveRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsAttentionsItemSaveResponse>;

  /**
   *
   * @summary Справочник причин напоминаний по клиенту
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsAttentionsReasons(options?: AxiosRequestConfig): AxiosPromise<ClientsAttentionsReasonsResponse>;

  /**
   *
   * @summary Список заявок на изменение клиента
   * @param {string} customerCode
   * @param {string} [branchOfficeCode]
   * @param {string} [stateCode] Статус заявки (/adm/rad#/customer_change_request_state)
   * @param {string} [kindCode] Вид заявки (adm/rad#/customer_change_request_kind)
   * @param {boolean} [active] Показать активные заявки. Можно совмещать с stateCode
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsChangeRequests(
    customerCode: string,
    branchOfficeCode?: string,
    stateCode?: string,
    kindCode?: string,
    active?: boolean,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsChangeRequestsResponse>;

  /**
   *
   * @summary Создать заявку на изменение клиента
   * @param {ClientsChangeRequestCreateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsChangeRequestsCreate(request: ClientsChangeRequestCreateRequest, options?: AxiosRequestConfig): AxiosPromise<ClientsChangeRequest>;

  /**
   *
   * @summary Закрыть доступ для филиала
   * @param {string} customerCode
   * @param {ClientsCloseOfficeAccessRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsCloseOfficeAccess(
    customerCode: string,
    request: ClientsCloseOfficeAccessRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Документы лимит-кредит
   * @param {string} customerCode
   * @param {string} [branchOfficeCode]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsCreditLimitDocuments(
    customerCode: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsCreditLimitDocumentsResponse>;

  /**
   *
   * @summary Список клиентов
   * @param {ClientsForFilterRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsForFilter(request: ClientsForFilterRequest, options?: AxiosRequestConfig): AxiosPromise<ClientsForFilterResponse>;

  /**
   *
   * @summary Детали по клиенту
   * @param {string} customerCode
   * @param {string} [month] Год и месяц
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItem(customerCode: string, month?: string, options?: AxiosRequestConfig): AxiosPromise<ClientsItemResponse>;

  /**
   *
   * @summary Список адресов клиента
   * @param {string} customerCode
   * @param {string} [consigneeCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemAddresses(
    customerCode: string,
    consigneeCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemAddressesResponse>;

  /**
   *
   * @summary Генерация кода для нового адреса
   * @param {string} customerCode
   * @param {string} frontCode Код нового адреса на фронте
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemAddressesAdd(
    customerCode: string,
    frontCode: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemAddressesAddResponse>;

  /**
   *
   * @summary Обновление адреса клиента
   * @param {string} customerCode
   * @param {string} addressCode
   * @param {ClientsItemAddressesItemSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemAddressesItemSave(
    customerCode: string,
    addressCode: string,
    request: ClientsItemAddressesItemSaveRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemAddressesItemSaveResponse>;

  /**
   *
   * @summary Список напоминаний клиента
   * @param {string} customerCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemAttentions(customerCode: string, options?: AxiosRequestConfig): AxiosPromise<ClientsItemAttentionsResponse>;

  /**
   *
   * @summary Создание напоминания клиента
   * @param {string} customerCode
   * @param {ClientsItemAttentionsCreateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemAttentionsCreate(
    customerCode: string,
    request: ClientsItemAttentionsCreateRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemAttentionsCreateResponse>;

  /**
   *
   * @summary История изменения напоминаний клиента
   * @param {string} customerCode
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemAttentionsHistory(
    customerCode: string,
    dateFrom?: string,
    dateTo?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemAttentionsHistoryResponse>;

  /**
   * Список грузополучателей
   * @param {string} customerCode
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemConsignees(
    customerCode: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemConsigneesResponse>;

  /**
   *
   * @summary Список договоров с клиентом
   * @param {string} customerCode
   * @param {string} [kind] Тип договора (with_buyer, with_supplier, other)
   * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
   * @param {boolean} [withDrafts] deprecated. use preset. Показывать черновики
   * @param {boolean} [forDeals] deprecated. use preset. Показывать только для заявок
   * @param {string} [preset] Пресеты для контрактов (with_drafts, for_deals, for_additional)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemContracts(
    customerCode: string,
    kind?: string,
    branchOfficeCode?: string,
    withDrafts?: boolean,
    forDeals?: boolean,
    preset?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemContractsResponse>;

  /**
   *
   * @summary Состояние кредитования клиента
   * @param {string} customerCode
   * @param {string} [branchOfficeCode]
   * @param {boolean} [makeEsr] Выполнить запрос актуального статуса во внешнюю систему
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemCreditState(
    customerCode: string,
    branchOfficeCode?: string,
    makeEsr?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemCreditStateResponse>;

  /**
   * Список заявок на сделку
   * @param {string} customerCode
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
   * @param {Array<string>} [stateCodes] Коды статусов
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [month] Год и месяц
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {boolean} [containsAttachments] Содержит вложения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemDeals(
    customerCode: string,
    branchOfficeCodes?: Array<string>,
    stateCode?: string,
    stateCodes?: Array<string>,
    page?: number,
    count?: number,
    month?: string,
    dateFrom?: string,
    dateTo?: string,
    containsAttachments?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemDealsResponse>;

  /**
   *
   * @summary График маржинальности по клиенту
   * @param {string} customerCode
   * @param {string} [dealCode] Код сделки, которая будет добавлена последней точкой на график
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemMarginalityPlot(
    customerCode: string,
    dealCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemMarginalityPlotResponse>;

  /**
   *
   * @summary Статистика маржинальности на странице клиента
   * @param {string} customerCode
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemMarginalityStat(
    customerCode: string,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemMarginalityStatResponse>;

  /**
   *
   * @summary Сделки клиента - по сути заявки в лучшем статусе
   * @param {string} customerCode
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [month] Год и месяц
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {boolean} [hasBills] Фильтр по наличию счета
   * @param {boolean} [containsAttachments] Содержит вложения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemOrders(
    customerCode: string,
    page?: number,
    count?: number,
    month?: string,
    dateFrom?: string,
    dateTo?: string,
    hasBills?: boolean,
    containsAttachments?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemOrdersResponse>;

  /**
   *
   * @summary Обновление клиента
   * @param {string} customerCode
   * @param {ClientsItemSaveRequest} request
   * @param {string} [month] Год и месяц
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsItemSave(
    customerCode: string,
    request: ClientsItemSaveRequest,
    month?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsItemResponse>;

  /**
   *
   * @summary Удалить взаимоотношения для филиала
   * @param {string} customerCode
   * @param {ClientsRemoveOfficeRelationshipsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsRemoveOfficeRelationships(
    customerCode: string,
    request: ClientsRemoveOfficeRelationshipsRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Цепочка документов к отгрузке
   * @param {ClientsShipmentsThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  clientsShipmentsThread(
    request: ClientsShipmentsThreadRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ClientsShipmentsThreadResponse>;

  /**
   * Возвращает список видов соглашения договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractAgreementTypes(options?: AxiosRequestConfig): AxiosPromise<ContractAgreementTypesResponse>;

  /**
   *
   * @summary Список договоров - соглашений по ценам с клиентом
   * @param {string} [customerCode] deprecated. use customerCodes
   * @param {Array<string>} [customerCodes]
   * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [query] filter for agreements
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {Array<number>} [marketDeviationLevel] Уровень отклонения цены (от -2 - хуже максимального отклонения для филиала до 2)
   * @param {Array<number>} [stateIds] Состояние соглашения, 1 - на согласовании, 2 - согласовано, 3 - активно, 4 - неактивно
   * @param {Array<string>} [approvalStateCodes] Коды статусов согласования документов
   * @param {Array<string>} [documentTypeCodes] Коды типов прикрепленных документов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreements(
    customerCode?: string,
    customerCodes?: Array<string>,
    branchOfficeCode?: string,
    branchOfficeCodes?: Array<string>,
    page?: number,
    count?: number,
    query?: string,
    employeeSet?: string,
    dateFrom?: string,
    dateTo?: string,
    marketDeviationLevel?: Array<number>,
    stateIds?: Array<number>,
    approvalStateCodes?: Array<string>,
    documentTypeCodes?: Array<string>,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsResponse>;

  /**
   *
   * @summary Отправка на согласование или согласование соглашения
   * @param {ContractCostAgreementsApprovalConfirmRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsApprovalConfirm(
    request: ContractCostAgreementsApprovalConfirmRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsApprovalConfirmResponse>;

  /**
   *
   * @summary Отклонение соглашения
   * @param {ContractCostAgreementsApprovalDeclineRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsApprovalDecline(
    request: ContractCostAgreementsApprovalDeclineRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsApprovalDeclineResponse>;

  /**
   *
   * @summary Отзыв заявки на согласование соглашения
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsApprovalHistory(
    agreementCode: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsApprovalHistoryResponse>;

  /**
   *
   * @summary Отзыв заявки на согласование соглашения
   * @param {ContractCostAgreementsApprovalWithdrawRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsApprovalWithdraw(
    request: ContractCostAgreementsApprovalWithdrawRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsApprovalWithdrawResponse>;

  /**
   *
   * @summary Клонирование договора-соглашения
   * @param {ContractCostAgreementsCloneRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsClone(
    request: ContractCostAgreementsCloneRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsCloneResponse>;

  /**
   *
   * @summary Создание договора-соглашения
   * @param {ContractCostAgreementsCreateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsCreate(
    request: ContractCostAgreementsCreateRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsCreateResponse>;

  /**
   *
   * @summary Договор - соглашение по ценам с клиентом
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItem(agreementCode: string, options?: AxiosRequestConfig): AxiosPromise<ContractCostAgreementsItemResponse>;

  /**
   *
   * @summary Договор - соглашение по ценам с клиентом
   * @param {string} agreementCode
   * @param {ContractCostAgreementsItemAlternativesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemAlternatives(
    agreementCode: string,
    request: ContractCostAgreementsItemAlternativesRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsItemAlternativesResponse>;

  /**
   *
   * @summary Список доступных реализаторов
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemDistributors(
    agreementCode: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsItemDistributorsResponse>;

  /**
   *
   * @summary Удаление документа из заявки/сделки
   * @param {string} agreementCode Код соглашения
   * @param {string} docCode Код документа
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemDocsItemDelete(agreementCode: string, docCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Загрузка нового документа в заявку/сделку
   * @param {string} agreementCode Код соглашения
   * @param {string} documentTypeCode Тип загружаемого документа
   * @param {any} file Файл к загрузке
   * @param {string} [description]
   * @param {string} [positionCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemDocsUpload(
    agreementCode: string,
    documentTypeCode: string,
    file: any,
    description?: string,
    positionCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealDocument>;

  /**
   *
   * @summary Установка ограничения на редактирование
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemLock(agreementCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Формирование PDF коммерческого предложения из соглашения
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemPdf(agreementCode: string, options?: AxiosRequestConfig): AxiosPromise<ContractCostAgreementsItemPdfResponse>;

  /**
   *
   * @summary Загрузка SKU с ценами из эксель-файла
   * @param {string} agreementCode Код соглашения
   * @param {any} file Файл к загрузке
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemPricesUpload(
    agreementCode: string,
    file: any,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreement>;

  /**
   *
   * @summary Внесение изменений в договор-соглашение
   * @param {string} agreementCode
   * @param {ContractCostAgreementsItemSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemSave(
    agreementCode: string,
    request: ContractCostAgreementsItemSaveRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsItemSaveResponse>;

  /**
   * Получение информации о документе Спецификация соглашения. Сам документ можно получить в HTML и PDF-формате (ContractCostAgreementsItemSpecificationHTML, ContractCostAgreementsItemSpecificationPdf) В ответе ContractCostAgreementsItemSpecificationHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью ContractCostAgreementsItemSpecificationUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
   * @summary Получение информации о документе Спецификация соглашения
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemSpecification(
    agreementCode: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsItemSpecification>;

  /**
   *
   * @summary Формирование HTML спецификации соглашения
   * @param {string} agreementCode
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemSpecificationHTML(
    agreementCode: string,
    withoutStampAndSign?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Формирование PDF спецификации соглашения
   * @param {string} agreementCode
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemSpecificationPdf(
    agreementCode: string,
    withoutStampAndSign?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Обновление текстовок и другой информации документа Спецификация соглашения
   * @param {string} agreementCode
   * @param {ContractCostAgreementsItemSpecificationUpdateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemSpecificationUpdate(
    agreementCode: string,
    request: ContractCostAgreementsItemSpecificationUpdateRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsItemSpecification>;

  /**
   *
   * @summary Снятие ограничения на редактирование
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsItemUnlock(agreementCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Move agreement position
   * @param {ContractCostAgreementsPositionsMoveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsPositionsMove(
    request: ContractCostAgreementsPositionsMoveRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsPositionsMoveResponse>;

  /**
   *
   * @summary Цепочка документов по соглашению
   * @param {ContractCostAgreementsThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractCostAgreementsThread(
    request: ContractCostAgreementsThreadRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractCostAgreementsThreadResponse>;

  /**
   * Возвращает список типов файлов договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractFileTypes(options?: AxiosRequestConfig): AxiosPromise<ContractFileTypesResponse>;

  /**
   * Возвращает список файлов договора
   * @param {string} contractCode Код договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractFiles(contractCode: string, options?: AxiosRequestConfig): AxiosPromise<ContractFilesResponse>;

  /**
   * Удаляет файл договора
   * @param {string} code Код файла
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractFilesDelete(code: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Возвращает список форм договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractForms(options?: AxiosRequestConfig): AxiosPromise<ContractFormsResponse>;

  /**
   * Загрузка нового файла договора
   * @param {string} code Код договора
   * @param {string} title Заголовок
   * @param {string} fileTypeCode Код типа файла
   * @param {any} file Файл к загрузке
   * @param {string} [fileCode] Код файла договора (если передан то создается новая версия)
   * @param {string} [description] Описание
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractItemFilesUpload(
    code: string,
    title: string,
    fileTypeCode: string,
    file: any,
    fileCode?: string,
    description?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractItemFilesUploadResponse>;

  /**
   *
   * @summary Переотправляет договор с клиентом в СЭД
   * @param {string} code Код черновика
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractItemResendingCreate(code: string, options?: AxiosRequestConfig): AxiosPromise<ContractItemResendingCreateResponse>;

  /**
   * Возвращает список видов договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractKinds(options?: AxiosRequestConfig): AxiosPromise<ContractKindsResponse>;

  /**
   * Возвращает список типов пролонгации договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractRenewalTypes(options?: AxiosRequestConfig): AxiosPromise<ContractRenewalTypesResponse>;

  /**
   * Возвращает список типов документа подписанта
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractSignerDocumentTypes(options?: AxiosRequestConfig): AxiosPromise<ContractSignerDocumentTypesResponse>;

  /**
   * Возвращает список типов подписываемых договоров
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractSignerTypes(options?: AxiosRequestConfig): AxiosPromise<ContractSignerTypesResponse>;

  /**
   * Возвращает список статусов договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractStatuses(options?: AxiosRequestConfig): AxiosPromise<ContractStatusesResponse>;

  /**
   * Возвращает список видов договора
   * @param {string} [customerCode] Код клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractTypes(customerCode?: string, options?: AxiosRequestConfig): AxiosPromise<ContractTypesResponse>;

  /**
   *
   * @summary Список договоров с клиентом
   * @param {string} [code]
   * @param {string} [customerCode] Код клиента
   * @param {string} [kind] Тип договора (with_buyer, with_supplier, other)
   * @param {string} [branchOfficeCode] deprecated. use branchOfficeCodes. Код филиала, если нужен список договоров не от текущего филиала сотрудника
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [query] Фильтр
   * @param {string} [statusCode] Статус (draft, approval, active, inactive)
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contracts(
    code?: string,
    customerCode?: string,
    kind?: string,
    branchOfficeCode?: string,
    branchOfficeCodes?: Array<string>,
    employeeSet?: string,
    query?: string,
    statusCode?: string,
    dateFrom?: string,
    dateTo?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractsResponse>;

  /**
   *
   * @summary Создает черновик договора с клиентом
   * @param {string} customerCode Код клиента
   * @param {string} typeCode Вид договора (Договор на реализацию металла с отсрочкой и тд)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractsItemDraftCreate(
    customerCode: string,
    typeCode: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractsItemDraftCreateResponse>;

  /**
   *
   * @summary Удаляет черновик договора с клиентом
   * @param {string} code Код черновика
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractsItemDraftDelete(code: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Поиск черновика договора с клиентом
   * @param {string} customerCode Код клиента
   * @param {string} [typeCode] Вид договора (Договор на реализацию металла с отсрочкой и тд)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractsItemDraftLookup(
    customerCode: string,
    typeCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractsItemDraftLookupResponse>;

  /**
   *
   * @summary Отправляет черновик договора с клиентом в СЭД
   * @param {string} code Код черновика
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractsItemDraftSendingCreate(code: string, options?: AxiosRequestConfig): AxiosPromise<ContractsItemDraftSendingCreateResponse>;

  /**
   *
   * @summary Список отправок черновика договора с клиентом в СЭД
   * @param {string} code Код черновика
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractsItemDraftSendingList(code: string, options?: AxiosRequestConfig): AxiosPromise<ContractsItemDraftSendingListResponse>;

  /**
   *
   * @summary Обновляет черновик договора с клиентом
   * @param {string} code Код черновика
   * @param {ContractsItemDraftUpdateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApiInterface
   */
  contractsItemDraftUpdate(
    code: string,
    request: ContractsItemDraftUpdateRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContractsItemDraftUpdateResponse>;
}

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI implements CustomerApiInterface {
  /**
   *
   * @summary Справочник статусов активности клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientActivityStatuses(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientActivityStatuses(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник статусов участия во внешней системе СЭД клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientDocFlowStates(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientDocFlowStates(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создать лида
   * @param {ClientLeadsCreateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientLeadsCreate(request: ClientLeadsCreateRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientLeadsCreate(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создать отправку клиента
   * @param {string} customerCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientLeadsItemSendingCreate(customerCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientLeadsItemSendingCreate(customerCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отправки клиента
   * @param {string} customerCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientLeadsItemSendingList(customerCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientLeadsItemSendingList(customerCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить информацию о юридическом лице перед созданием лида
   * @param {ClientLeadsLookupRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientLeadsLookup(request: ClientLeadsLookupRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientLeadsLookup(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник отраслей клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientOccupations(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientOccupations(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник подотраслей клиента
   * @param {Array<string>} [occupationCodes] Список отраслей
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientOccupationsSpecified(occupationCodes?: Array<string>, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientOccupationsSpecified(occupationCodes, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список сегментов клиента в разрезе филиала
   * @param {ClientOfficeSegmentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientOfficeSegments(request: ClientOfficeSegmentsRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientOfficeSegments(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник сегментов клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientSegments(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientSegments(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник сегментов клиента
   * @param {'consuming' | 'shipment' | 'recency' | 'frequency' | 'monetary'} segmentType Список сегментов клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientSegmentsDictionary(
    segmentType: 'consuming' | 'shipment' | 'recency' | 'frequency' | 'monetary',
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .clientSegmentsDictionary(segmentType, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список сегментов по отгрузке клиента в разрезе филиала
   * @param {ClientShipmentSegmentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientShipmentSegments(request: ClientShipmentSegmentsRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientShipmentSegments(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список изменений сегментов по отгрузке клиента в разрезе филиала
   * @param {ClientShipmentSegmentsHistoryRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientShipmentSegmentsHistory(request: ClientShipmentSegmentsHistoryRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientShipmentSegmentsHistory(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список клиентов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clients(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clients(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление напоминаний клиента
   * @param {string} code Код напоминания по клиенту
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsAttentionsItemDelete(code: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsAttentionsItemDelete(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Редактирование напоминаний клиента
   * @param {string} code Код напоминания по клиенту
   * @param {ClientsAttentionsItemSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsAttentionsItemSave(code: string, request: ClientsAttentionsItemSaveRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsAttentionsItemSave(code, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник причин напоминаний по клиенту
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsAttentionsReasons(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsAttentionsReasons(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список заявок на изменение клиента
   * @param {string} customerCode
   * @param {string} [branchOfficeCode]
   * @param {string} [stateCode] Статус заявки (/adm/rad#/customer_change_request_state)
   * @param {string} [kindCode] Вид заявки (adm/rad#/customer_change_request_kind)
   * @param {boolean} [active] Показать активные заявки. Можно совмещать с stateCode
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsChangeRequests(
    customerCode: string,
    branchOfficeCode?: string,
    stateCode?: string,
    kindCode?: string,
    active?: boolean,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .clientsChangeRequests(customerCode, branchOfficeCode, stateCode, kindCode, active, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создать заявку на изменение клиента
   * @param {ClientsChangeRequestCreateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsChangeRequestsCreate(request: ClientsChangeRequestCreateRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsChangeRequestsCreate(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Закрыть доступ для филиала
   * @param {string} customerCode
   * @param {ClientsCloseOfficeAccessRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsCloseOfficeAccess(customerCode: string, request: ClientsCloseOfficeAccessRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsCloseOfficeAccess(customerCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Документы лимит-кредит
   * @param {string} customerCode
   * @param {string} [branchOfficeCode]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsCreditLimitDocuments(
    customerCode: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .clientsCreditLimitDocuments(customerCode, branchOfficeCode, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список клиентов
   * @param {ClientsForFilterRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsForFilter(request: ClientsForFilterRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsForFilter(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Детали по клиенту
   * @param {string} customerCode
   * @param {string} [month] Год и месяц
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItem(customerCode: string, month?: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItem(customerCode, month, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список адресов клиента
   * @param {string} customerCode
   * @param {string} [consigneeCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemAddresses(customerCode: string, consigneeCode?: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItemAddresses(customerCode, consigneeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Генерация кода для нового адреса
   * @param {string} customerCode
   * @param {string} frontCode Код нового адреса на фронте
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemAddressesAdd(customerCode: string, frontCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItemAddressesAdd(customerCode, frontCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление адреса клиента
   * @param {string} customerCode
   * @param {string} addressCode
   * @param {ClientsItemAddressesItemSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemAddressesItemSave(
    customerCode: string,
    addressCode: string,
    request: ClientsItemAddressesItemSaveRequest,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .clientsItemAddressesItemSave(customerCode, addressCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список напоминаний клиента
   * @param {string} customerCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemAttentions(customerCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItemAttentions(customerCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создание напоминания клиента
   * @param {string} customerCode
   * @param {ClientsItemAttentionsCreateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemAttentionsCreate(customerCode: string, request: ClientsItemAttentionsCreateRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItemAttentionsCreate(customerCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary История изменения напоминаний клиента
   * @param {string} customerCode
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemAttentionsHistory(customerCode: string, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItemAttentionsHistory(customerCode, dateFrom, dateTo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список грузополучателей
   * @param {string} customerCode
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemConsignees(customerCode: string, page?: number, count?: number, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItemConsignees(customerCode, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список договоров с клиентом
   * @param {string} customerCode
   * @param {string} [kind] Тип договора (with_buyer, with_supplier, other)
   * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
   * @param {boolean} [withDrafts] deprecated. use preset. Показывать черновики
   * @param {boolean} [forDeals] deprecated. use preset. Показывать только для заявок
   * @param {string} [preset] Пресеты для контрактов (with_drafts, for_deals, for_additional)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemContracts(
    customerCode: string,
    kind?: string,
    branchOfficeCode?: string,
    withDrafts?: boolean,
    forDeals?: boolean,
    preset?: string,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .clientsItemContracts(customerCode, kind, branchOfficeCode, withDrafts, forDeals, preset, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Состояние кредитования клиента
   * @param {string} customerCode
   * @param {string} [branchOfficeCode]
   * @param {boolean} [makeEsr] Выполнить запрос актуального статуса во внешнюю систему
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemCreditState(customerCode: string, branchOfficeCode?: string, makeEsr?: boolean, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItemCreditState(customerCode, branchOfficeCode, makeEsr, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список заявок на сделку
   * @param {string} customerCode
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
   * @param {Array<string>} [stateCodes] Коды статусов
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [month] Год и месяц
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {boolean} [containsAttachments] Содержит вложения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemDeals(
    customerCode: string,
    branchOfficeCodes?: Array<string>,
    stateCode?: string,
    stateCodes?: Array<string>,
    page?: number,
    count?: number,
    month?: string,
    dateFrom?: string,
    dateTo?: string,
    containsAttachments?: boolean,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .clientsItemDeals(
        customerCode,
        branchOfficeCodes,
        stateCode,
        stateCodes,
        page,
        count,
        month,
        dateFrom,
        dateTo,
        containsAttachments,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary График маржинальности по клиенту
   * @param {string} customerCode
   * @param {string} [dealCode] Код сделки, которая будет добавлена последней точкой на график
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemMarginalityPlot(customerCode: string, dealCode?: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItemMarginalityPlot(customerCode, dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Статистика маржинальности на странице клиента
   * @param {string} customerCode
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemMarginalityStat(customerCode: string, date?: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItemMarginalityStat(customerCode, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Сделки клиента - по сути заявки в лучшем статусе
   * @param {string} customerCode
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [month] Год и месяц
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {boolean} [hasBills] Фильтр по наличию счета
   * @param {boolean} [containsAttachments] Содержит вложения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemOrders(
    customerCode: string,
    page?: number,
    count?: number,
    month?: string,
    dateFrom?: string,
    dateTo?: string,
    hasBills?: boolean,
    containsAttachments?: boolean,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .clientsItemOrders(customerCode, page, count, month, dateFrom, dateTo, hasBills, containsAttachments, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление клиента
   * @param {string} customerCode
   * @param {ClientsItemSaveRequest} request
   * @param {string} [month] Год и месяц
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsItemSave(customerCode: string, request: ClientsItemSaveRequest, month?: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsItemSave(customerCode, request, month, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удалить взаимоотношения для филиала
   * @param {string} customerCode
   * @param {ClientsRemoveOfficeRelationshipsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsRemoveOfficeRelationships(
    customerCode: string,
    request: ClientsRemoveOfficeRelationshipsRequest,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .clientsRemoveOfficeRelationships(customerCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Цепочка документов к отгрузке
   * @param {ClientsShipmentsThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public clientsShipmentsThread(request: ClientsShipmentsThreadRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .clientsShipmentsThread(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список видов соглашения договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractAgreementTypes(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractAgreementTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список договоров - соглашений по ценам с клиентом
   * @param {string} [customerCode] deprecated. use customerCodes
   * @param {Array<string>} [customerCodes]
   * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [query] filter for agreements
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {Array<number>} [marketDeviationLevel] Уровень отклонения цены (от -2 - хуже максимального отклонения для филиала до 2)
   * @param {Array<number>} [stateIds] Состояние соглашения, 1 - на согласовании, 2 - согласовано, 3 - активно, 4 - неактивно
   * @param {Array<string>} [approvalStateCodes] Коды статусов согласования документов
   * @param {Array<string>} [documentTypeCodes] Коды типов прикрепленных документов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreements(
    customerCode?: string,
    customerCodes?: Array<string>,
    branchOfficeCode?: string,
    branchOfficeCodes?: Array<string>,
    page?: number,
    count?: number,
    query?: string,
    employeeSet?: string,
    dateFrom?: string,
    dateTo?: string,
    marketDeviationLevel?: Array<number>,
    stateIds?: Array<number>,
    approvalStateCodes?: Array<string>,
    documentTypeCodes?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreements(
        customerCode,
        customerCodes,
        branchOfficeCode,
        branchOfficeCodes,
        page,
        count,
        query,
        employeeSet,
        dateFrom,
        dateTo,
        marketDeviationLevel,
        stateIds,
        approvalStateCodes,
        documentTypeCodes,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отправка на согласование или согласование соглашения
   * @param {ContractCostAgreementsApprovalConfirmRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsApprovalConfirm(request: ContractCostAgreementsApprovalConfirmRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsApprovalConfirm(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отклонение соглашения
   * @param {ContractCostAgreementsApprovalDeclineRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsApprovalDecline(request: ContractCostAgreementsApprovalDeclineRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsApprovalDecline(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отзыв заявки на согласование соглашения
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsApprovalHistory(agreementCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsApprovalHistory(agreementCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отзыв заявки на согласование соглашения
   * @param {ContractCostAgreementsApprovalWithdrawRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsApprovalWithdraw(request: ContractCostAgreementsApprovalWithdrawRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsApprovalWithdraw(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Клонирование договора-соглашения
   * @param {ContractCostAgreementsCloneRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsClone(request: ContractCostAgreementsCloneRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsClone(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создание договора-соглашения
   * @param {ContractCostAgreementsCreateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsCreate(request: ContractCostAgreementsCreateRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsCreate(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Договор - соглашение по ценам с клиентом
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItem(agreementCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItem(agreementCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Договор - соглашение по ценам с клиентом
   * @param {string} agreementCode
   * @param {ContractCostAgreementsItemAlternativesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemAlternatives(
    agreementCode: string,
    request: ContractCostAgreementsItemAlternativesRequest,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemAlternatives(agreementCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список доступных реализаторов
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemDistributors(agreementCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemDistributors(agreementCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление документа из заявки/сделки
   * @param {string} agreementCode Код соглашения
   * @param {string} docCode Код документа
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemDocsItemDelete(agreementCode: string, docCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemDocsItemDelete(agreementCode, docCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузка нового документа в заявку/сделку
   * @param {string} agreementCode Код соглашения
   * @param {string} documentTypeCode Тип загружаемого документа
   * @param {any} file Файл к загрузке
   * @param {string} [description]
   * @param {string} [positionCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemDocsUpload(
    agreementCode: string,
    documentTypeCode: string,
    file: any,
    description?: string,
    positionCode?: string,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemDocsUpload(agreementCode, documentTypeCode, file, description, positionCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Установка ограничения на редактирование
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemLock(agreementCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemLock(agreementCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование PDF коммерческого предложения из соглашения
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemPdf(agreementCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemPdf(agreementCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузка SKU с ценами из эксель-файла
   * @param {string} agreementCode Код соглашения
   * @param {any} file Файл к загрузке
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemPricesUpload(agreementCode: string, file: any, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemPricesUpload(agreementCode, file, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Внесение изменений в договор-соглашение
   * @param {string} agreementCode
   * @param {ContractCostAgreementsItemSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemSave(
    agreementCode: string,
    request: ContractCostAgreementsItemSaveRequest,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemSave(agreementCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Получение информации о документе Спецификация соглашения. Сам документ можно получить в HTML и PDF-формате (ContractCostAgreementsItemSpecificationHTML, ContractCostAgreementsItemSpecificationPdf) В ответе ContractCostAgreementsItemSpecificationHTML содержатся элементы с классом .editable. Содержимое этих элементов доступно к обновлению с помощью ContractCostAgreementsItemSpecificationUpdate (поле texts). Все .editable элементы содержат аттрибут id, который и является кодом текстовки
   * @summary Получение информации о документе Спецификация соглашения
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemSpecification(agreementCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemSpecification(agreementCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование HTML спецификации соглашения
   * @param {string} agreementCode
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemSpecificationHTML(agreementCode: string, withoutStampAndSign?: boolean, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemSpecificationHTML(agreementCode, withoutStampAndSign, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование PDF спецификации соглашения
   * @param {string} agreementCode
   * @param {boolean} [withoutStampAndSign] Без подписи и печати
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemSpecificationPdf(agreementCode: string, withoutStampAndSign?: boolean, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemSpecificationPdf(agreementCode, withoutStampAndSign, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление текстовок и другой информации документа Спецификация соглашения
   * @param {string} agreementCode
   * @param {ContractCostAgreementsItemSpecificationUpdateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemSpecificationUpdate(
    agreementCode: string,
    request: ContractCostAgreementsItemSpecificationUpdateRequest,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemSpecificationUpdate(agreementCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Снятие ограничения на редактирование
   * @param {string} agreementCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsItemUnlock(agreementCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsItemUnlock(agreementCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Move agreement position
   * @param {ContractCostAgreementsPositionsMoveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsPositionsMove(request: ContractCostAgreementsPositionsMoveRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsPositionsMove(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Цепочка документов по соглашению
   * @param {ContractCostAgreementsThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractCostAgreementsThread(request: ContractCostAgreementsThreadRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractCostAgreementsThread(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список типов файлов договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractFileTypes(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractFileTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список файлов договора
   * @param {string} contractCode Код договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractFiles(contractCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractFiles(contractCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Удаляет файл договора
   * @param {string} code Код файла
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractFilesDelete(code: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractFilesDelete(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список форм договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractForms(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractForms(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Загрузка нового файла договора
   * @param {string} code Код договора
   * @param {string} title Заголовок
   * @param {string} fileTypeCode Код типа файла
   * @param {any} file Файл к загрузке
   * @param {string} [fileCode] Код файла договора (если передан то создается новая версия)
   * @param {string} [description] Описание
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractItemFilesUpload(
    code: string,
    title: string,
    fileTypeCode: string,
    file: any,
    fileCode?: string,
    description?: string,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .contractItemFilesUpload(code, title, fileTypeCode, file, fileCode, description, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Переотправляет договор с клиентом в СЭД
   * @param {string} code Код черновика
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractItemResendingCreate(code: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractItemResendingCreate(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список видов договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractKinds(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractKinds(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список типов пролонгации договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractRenewalTypes(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractRenewalTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список типов документа подписанта
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractSignerDocumentTypes(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractSignerDocumentTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список типов подписываемых договоров
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractSignerTypes(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractSignerTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список статусов договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractStatuses(options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractStatuses(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список видов договора
   * @param {string} [customerCode] Код клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractTypes(customerCode?: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractTypes(customerCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список договоров с клиентом
   * @param {string} [code]
   * @param {string} [customerCode] Код клиента
   * @param {string} [kind] Тип договора (with_buyer, with_supplier, other)
   * @param {string} [branchOfficeCode] deprecated. use branchOfficeCodes. Код филиала, если нужен список договоров не от текущего филиала сотрудника
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [query] Фильтр
   * @param {string} [statusCode] Статус (draft, approval, active, inactive)
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contracts(
    code?: string,
    customerCode?: string,
    kind?: string,
    branchOfficeCode?: string,
    branchOfficeCodes?: Array<string>,
    employeeSet?: string,
    query?: string,
    statusCode?: string,
    dateFrom?: string,
    dateTo?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return CustomerApiFp(this.configuration)
      .contracts(
        code,
        customerCode,
        kind,
        branchOfficeCode,
        branchOfficeCodes,
        employeeSet,
        query,
        statusCode,
        dateFrom,
        dateTo,
        page,
        count,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создает черновик договора с клиентом
   * @param {string} customerCode Код клиента
   * @param {string} typeCode Вид договора (Договор на реализацию металла с отсрочкой и тд)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractsItemDraftCreate(customerCode: string, typeCode: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractsItemDraftCreate(customerCode, typeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаляет черновик договора с клиентом
   * @param {string} code Код черновика
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractsItemDraftDelete(code: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractsItemDraftDelete(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Поиск черновика договора с клиентом
   * @param {string} customerCode Код клиента
   * @param {string} [typeCode] Вид договора (Договор на реализацию металла с отсрочкой и тд)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractsItemDraftLookup(customerCode: string, typeCode?: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractsItemDraftLookup(customerCode, typeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отправляет черновик договора с клиентом в СЭД
   * @param {string} code Код черновика
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractsItemDraftSendingCreate(code: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractsItemDraftSendingCreate(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список отправок черновика договора с клиентом в СЭД
   * @param {string} code Код черновика
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractsItemDraftSendingList(code: string, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractsItemDraftSendingList(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновляет черновик договора с клиентом
   * @param {string} code Код черновика
   * @param {ContractsItemDraftUpdateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomerApi
   */
  public contractsItemDraftUpdate(code: string, request: ContractsItemDraftUpdateRequest, options?: AxiosRequestConfig) {
    return CustomerApiFp(this.configuration)
      .contractsItemDraftUpdate(code, request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DealApi - axios parameter creator
 * @export
 */
export const DealApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Создание новой заявки
     * @param {DealsCreateRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsCreate: async (request?: DealsCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/deals/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Просмотр сделки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItem: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItem', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Используется на странице сделки для вывода доступных альтернатив к товару
     * @summary Список альтернативных товаров
     * @param {string} dealCode
     * @param {DealsItemAlternativesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemAlternatives: async (
      dealCode: string,
      request: DealsItemAlternativesRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemAlternatives', 'dealCode', dealCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemAlternatives', 'request', request);
      const localVarPath = `/deals/{dealCode}/alternatives`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Подтверждение заявки МОПом, РОПом или директором. Если заявка соответствует требованиям, она сразу будет отправлена в 1С.
     * @param {string} dealCode
     * @param {DealsItemApprovalConfirmRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemApprovalConfirm: async (
      dealCode: string,
      request: DealsItemApprovalConfirmRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemApprovalConfirm', 'dealCode', dealCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemApprovalConfirm', 'request', request);
      const localVarPath = `/deals/{dealCode}/approval/confirm`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отклонение заявки руководителем
     * @param {string} dealCode
     * @param {DealsItemApprovalDeclineRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemApprovalDecline: async (
      dealCode: string,
      request: DealsItemApprovalDeclineRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemApprovalDecline', 'dealCode', dealCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemApprovalDecline', 'request', request);
      const localVarPath = `/deals/{dealCode}/approval/decline`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary История согласования заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemApprovalHistory: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemApprovalHistory', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/approval/history`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отозвать запрос на одобрение заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemApprovalWithdraw: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemApprovalWithdraw', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/approval/withdraw`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование PDF счета по сделке
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemBillPdf: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemBillPdf', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/bill/pdf`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Клонирование заявки
     * @param {string} dealCode Исходная заявка, из которой клонируются данные. Должно совпадать с sourceDealCode.
     * @param {DealsItemCloneRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemClone: async (dealCode: string, request: DealsItemCloneRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemClone', 'dealCode', dealCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemClone', 'request', request);
      const localVarPath = `/deals/{dealCode}/clone`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Возвращает список грузополучателей к сделке.
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemConsignees: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemConsignees', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/consignees`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создание соглашения на основании заявки заявки
     * @param {string} dealCode Исходная заявка, на основании котороый создается соглашение
     * @param {DealsItemCreateAgreementRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemCreateAgreement: async (
      dealCode: string,
      request: DealsItemCreateAgreementRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemCreateAgreement', 'dealCode', dealCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemCreateAgreement', 'request', request);
      const localVarPath = `/deals/{dealCode}/createAgreement`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Деактивация заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemDeactivate: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemDeactivate', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/deactivate`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Возвращает доступные типы доставок и стоимости
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemDeliveryTypes: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemDeliveryTypes', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/delivery/types`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список доступных реализаторов
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemDistributors: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemDistributors', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/distributors`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление документа из заявки/сделки
     * @param {string} dealCode Код заявки/сделки
     * @param {string} docCode Код документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemDocumentDelete: async (dealCode: string, docCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemDocumentDelete', 'dealCode', dealCode);
      // verify required parameter 'docCode' is not null or undefined
      assertParamExists('dealsItemDocumentDelete', 'docCode', docCode);
      const localVarPath = `/deals/{dealCode}/docs/{docCode}/delete`
        .replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)))
        .replace(`{${'docCode'}}`, encodeURIComponent(String(docCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузка нового документа в заявку/сделку
     * @param {string} dealCode Код заявки
     * @param {string} documentTypeCode Тип загружаемого документа
     * @param {any} file Файл к загрузке
     * @param {string} [description]
     * @param {string} [positionCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemDocumentUpload: async (
      dealCode: string,
      documentTypeCode: string,
      file: any,
      description?: string,
      positionCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemDocumentUpload', 'dealCode', dealCode);
      // verify required parameter 'documentTypeCode' is not null or undefined
      assertParamExists('dealsItemDocumentUpload', 'documentTypeCode', documentTypeCode);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('dealsItemDocumentUpload', 'file', file);
      const localVarPath = `/deals/{dealCode}/docs/upload`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (documentTypeCode !== undefined) {
        localVarFormParams.append('documentTypeCode', documentTypeCode as any);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (description !== undefined) {
        localVarFormParams.append('description', description as any);
      }

      if (positionCode !== undefined) {
        localVarFormParams.append('positionCode', positionCode as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary История заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemHistory: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemHistory', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/history`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemLock: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemLock', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/lock`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Используется на странице сделки для вывода доступных производителей к товару
     * @summary Список альтернативных производителей для материалов
     * @param {string} dealCode Код сделки для исключения уже использованных остатков
     * @param {Array<string>} positionsCodes
     * @param {string} [stockRequired] Только с остатками
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemManufacturers: async (
      dealCode: string,
      positionsCodes: Array<string>,
      stockRequired?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemManufacturers', 'dealCode', dealCode);
      // verify required parameter 'positionsCodes' is not null or undefined
      assertParamExists('dealsItemManufacturers', 'positionsCodes', positionsCodes);
      const localVarPath = `/deals/{dealCode}/manufacturers`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (positionsCodes) {
        localVarQueryParameter['positionsCodes'] = positionsCodes.join(COLLECTION_FORMATS.csv);
      }

      if (stockRequired !== undefined) {
        localVarQueryParameter['stockRequired'] = stockRequired;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список возможных плательщиков заявки
     * @param {string} dealCode
     * @param {string} [typeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemPartners: async (dealCode: string, typeCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemPartners', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/partners`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (typeCode !== undefined) {
        localVarQueryParameter['typeCode'] = typeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete deal position
     * @param {string} dealCode
     * @param {string} positionCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemPositionsItemDelete: async (
      dealCode: string,
      positionCode: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemPositionsItemDelete', 'dealCode', dealCode);
      // verify required parameter 'positionCode' is not null or undefined
      assertParamExists('dealsItemPositionsItemDelete', 'positionCode', positionCode);
      const localVarPath = `/deals/{dealCode}/positions/{positionCode}/delete`
        .replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)))
        .replace(`{${'positionCode'}}`, encodeURIComponent(String(positionCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Move deal position
     * @param {string} dealCode
     * @param {string} positionCode
     * @param {DealsItemPositionsItemMoveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemPositionsItemMove: async (
      dealCode: string,
      positionCode: string,
      request: DealsItemPositionsItemMoveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemPositionsItemMove', 'dealCode', dealCode);
      // verify required parameter 'positionCode' is not null or undefined
      assertParamExists('dealsItemPositionsItemMove', 'positionCode', positionCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemPositionsItemMove', 'request', request);
      const localVarPath = `/deals/{dealCode}/positions/{positionCode}/move`
        .replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)))
        .replace(`{${'positionCode'}}`, encodeURIComponent(String(positionCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Метод отправляет текущий товар в архив, новый добавляет в заявку
     * @summary Replace deal position
     * @param {string} dealCode
     * @param {string} positionCode
     * @param {DealsItemPositionsItemReplaceRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemPositionsItemReplace: async (
      dealCode: string,
      positionCode: string,
      request: DealsItemPositionsItemReplaceRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemPositionsItemReplace', 'dealCode', dealCode);
      // verify required parameter 'positionCode' is not null or undefined
      assertParamExists('dealsItemPositionsItemReplace', 'positionCode', positionCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemPositionsItemReplace', 'request', request);
      const localVarPath = `/deals/{dealCode}/positions/{positionCode}/replace`
        .replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)))
        .replace(`{${'positionCode'}}`, encodeURIComponent(String(positionCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Пролонгация заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemProlong: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemProlong', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/prolong`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Пересчет мотивации заявки
     * @param {string} dealCode
     * @param {DealsItemRecalculateMotivationRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemRecalculateMotivation: async (
      dealCode: string,
      request: DealsItemRecalculateMotivationRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemRecalculateMotivation', 'dealCode', dealCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemRecalculateMotivation', 'request', request);
      const localVarPath = `/deals/{dealCode}/recalculateMotivation`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создание или обновление заявки, возвращает актуальное состояние.
     * @param {string} dealCode
     * @param {DealsItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemSave: async (dealCode: string, request: DealsItemSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemSave', 'dealCode', dealCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemSave', 'request', request);
      const localVarPath = `/deals/{dealCode}/save`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Тексты PDF спецификации по сделке для вывода на фронт
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemSpecification: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemSpecification', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/specification`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование PDF спецификации по сделке
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemSpecificationPdf: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemSpecificationPdf', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/specification/pdf`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование PDF спецификации по сделке
     * @param {string} dealCode
     * @param {DealsItemSpecificationPdfRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemSpecificationPdfPost: async (
      dealCode: string,
      request: DealsItemSpecificationPdfRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemSpecificationPdfPost', 'dealCode', dealCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemSpecificationPdfPost', 'request', request);
      const localVarPath = `/deals/{dealCode}/specification/pdf`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Submit deal request
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemSubmit: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemSubmit', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/submit`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemUnlock: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemUnlock', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/unlock`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Обновление партнера заявки
     * @param {string} dealCode
     * @param {DealsItemUpdatePartnerRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemUpdatePartner: async (
      dealCode: string,
      request: DealsItemUpdatePartnerRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemUpdatePartner', 'dealCode', dealCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemUpdatePartner', 'request', request);
      const localVarPath = `/deals/{dealCode}/update/partner`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Обновление адреса партнера заявки
     * @param {string} dealCode
     * @param {DealsItemUpdatePartnerAddressRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemUpdatePartnerAddress: async (
      dealCode: string,
      request: DealsItemUpdatePartnerAddressRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemUpdatePartnerAddress', 'dealCode', dealCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('dealsItemUpdatePartnerAddress', 'request', request);
      const localVarPath = `/deals/{dealCode}/update/partner/address`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список доступных складов
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemWarehouses: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemWarehouses', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/warehouses`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemXmlPreview: async (dealCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'dealCode' is not null or undefined
      assertParamExists('dealsItemXmlPreview', 'dealCode', dealCode);
      const localVarPath = `/deals/{dealCode}/xml/preview`.replace(`{${'dealCode'}}`, encodeURIComponent(String(dealCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список своих заявок
     * @param {string} [agreementCode] Код соглашения
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {boolean} [hasBills] В наличии документ Счет
     * @param {boolean} [hasPS] Есть ПС (акция)
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {string} [customerCode] deprecated. use customerCodes
     * @param {Array<string>} [customerCodes] Коды клиентов
     * @param {number} [count]
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {string} [employee] Сотрудник, оформивший сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [month] Год и месяц
     * @param {number} [page]
     * @param {string} [query] filter for deals
     * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
     * @param {Array<string>} [stateCodes] Коды статусов
     * @param {Array<string>} [approvalStateCodes] Коды статусов согласования документов
     * @param {Array<string>} [documentTypeCodes] Коды типов прикрепленных документов
     * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsList: async (
      agreementCode?: string,
      branchOfficeCodes?: Array<string>,
      hasBills?: boolean,
      hasPS?: boolean,
      containsAttachments?: boolean,
      customerCode?: string,
      customerCodes?: Array<string>,
      count?: number,
      dateFrom?: string,
      dateTo?: string,
      employee?: string,
      employeeSet?: string,
      month?: string,
      page?: number,
      query?: string,
      stateCode?: string,
      stateCodes?: Array<string>,
      approvalStateCodes?: Array<string>,
      documentTypeCodes?: Array<string>,
      type?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/deals/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (agreementCode !== undefined) {
        localVarQueryParameter['agreementCode'] = agreementCode;
      }

      if (branchOfficeCodes) {
        localVarQueryParameter['branchOfficeCodes'] = branchOfficeCodes.join(COLLECTION_FORMATS.csv);
      }

      if (hasBills !== undefined) {
        localVarQueryParameter['hasBills'] = hasBills;
      }

      if (hasPS !== undefined) {
        localVarQueryParameter['hasPS'] = hasPS;
      }

      if (containsAttachments !== undefined) {
        localVarQueryParameter['containsAttachments'] = containsAttachments;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (customerCodes) {
        localVarQueryParameter['customerCodes'] = customerCodes.join(COLLECTION_FORMATS.csv);
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (employee !== undefined) {
        localVarQueryParameter['employee'] = employee;
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = (month as any) instanceof Date ? (month as any).toISOString().substr(0, 10) : month;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (stateCode !== undefined) {
        localVarQueryParameter['stateCode'] = stateCode;
      }

      if (stateCodes) {
        localVarQueryParameter['stateCodes'] = stateCodes.join(COLLECTION_FORMATS.csv);
      }

      if (approvalStateCodes) {
        localVarQueryParameter['approvalStateCodes'] = approvalStateCodes.join(COLLECTION_FORMATS.csv);
      }

      if (documentTypeCodes) {
        localVarQueryParameter['documentTypeCodes'] = documentTypeCodes.join(COLLECTION_FORMATS.csv);
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник статусов сделки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsStates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/deals/states`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создание новой заявки
     * @param {DealsThreadRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsThread: async (request?: DealsThreadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/deals/thread`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DealApi - functional programming interface
 * @export
 */
export const DealApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DealApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Создание новой заявки
     * @param {DealsCreateRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsCreate(
      request?: DealsCreateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsCreate(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Просмотр сделки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItem(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItem(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Используется на странице сделки для вывода доступных альтернатив к товару
     * @summary Список альтернативных товаров
     * @param {string} dealCode
     * @param {DealsItemAlternativesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemAlternatives(
      dealCode: string,
      request: DealsItemAlternativesRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemAlternativesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemAlternatives(dealCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Подтверждение заявки МОПом, РОПом или директором. Если заявка соответствует требованиям, она сразу будет отправлена в 1С.
     * @param {string} dealCode
     * @param {DealsItemApprovalConfirmRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemApprovalConfirm(
      dealCode: string,
      request: DealsItemApprovalConfirmRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemApprovalConfirmResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemApprovalConfirm(dealCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отклонение заявки руководителем
     * @param {string} dealCode
     * @param {DealsItemApprovalDeclineRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemApprovalDecline(
      dealCode: string,
      request: DealsItemApprovalDeclineRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemApprovalDeclineResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemApprovalDecline(dealCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary История согласования заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemApprovalHistory(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemApprovalHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemApprovalHistory(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отозвать запрос на одобрение заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemApprovalWithdraw(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemApprovalWithdrawResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemApprovalWithdraw(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование PDF счета по сделке
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemBillPdf(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemBillPdfResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemBillPdf(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Клонирование заявки
     * @param {string} dealCode Исходная заявка, из которой клонируются данные. Должно совпадать с sourceDealCode.
     * @param {DealsItemCloneRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemClone(
      dealCode: string,
      request: DealsItemCloneRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemCloneResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemClone(dealCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Возвращает список грузополучателей к сделке.
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemConsignees(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemConsigneesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemConsignees(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создание соглашения на основании заявки заявки
     * @param {string} dealCode Исходная заявка, на основании котороый создается соглашение
     * @param {DealsItemCreateAgreementRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemCreateAgreement(
      dealCode: string,
      request: DealsItemCreateAgreementRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemCreateAgreementResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemCreateAgreement(dealCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Деактивация заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemDeactivate(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemDeactivate(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Возвращает доступные типы доставок и стоимости
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemDeliveryTypes(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemDeliveryTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemDeliveryTypes(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список доступных реализаторов
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemDistributors(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemDistributorsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemDistributors(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление документа из заявки/сделки
     * @param {string} dealCode Код заявки/сделки
     * @param {string} docCode Код документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemDocumentDelete(
      dealCode: string,
      docCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemDocumentDelete(dealCode, docCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Загрузка нового документа в заявку/сделку
     * @param {string} dealCode Код заявки
     * @param {string} documentTypeCode Тип загружаемого документа
     * @param {any} file Файл к загрузке
     * @param {string} [description]
     * @param {string} [positionCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemDocumentUpload(
      dealCode: string,
      documentTypeCode: string,
      file: any,
      description?: string,
      positionCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealDocument>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemDocumentUpload(
        dealCode,
        documentTypeCode,
        file,
        description,
        positionCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary История заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemHistory(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemHistory(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemLock(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemLock(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Используется на странице сделки для вывода доступных производителей к товару
     * @summary Список альтернативных производителей для материалов
     * @param {string} dealCode Код сделки для исключения уже использованных остатков
     * @param {Array<string>} positionsCodes
     * @param {string} [stockRequired] Только с остатками
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemManufacturers(
      dealCode: string,
      positionsCodes: Array<string>,
      stockRequired?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemManufacturersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemManufacturers(dealCode, positionsCodes, stockRequired, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список возможных плательщиков заявки
     * @param {string} dealCode
     * @param {string} [typeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemPartners(
      dealCode: string,
      typeCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemPartnersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemPartners(dealCode, typeCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete deal position
     * @param {string} dealCode
     * @param {string} positionCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemPositionsItemDelete(
      dealCode: string,
      positionCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemPositionsItemDeleteResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemPositionsItemDelete(dealCode, positionCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Move deal position
     * @param {string} dealCode
     * @param {string} positionCode
     * @param {DealsItemPositionsItemMoveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemPositionsItemMove(
      dealCode: string,
      positionCode: string,
      request: DealsItemPositionsItemMoveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemPositionsItemMoveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemPositionsItemMove(dealCode, positionCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Метод отправляет текущий товар в архив, новый добавляет в заявку
     * @summary Replace deal position
     * @param {string} dealCode
     * @param {string} positionCode
     * @param {DealsItemPositionsItemReplaceRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemPositionsItemReplace(
      dealCode: string,
      positionCode: string,
      request: DealsItemPositionsItemReplaceRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemPositionsItemReplaceResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemPositionsItemReplace(dealCode, positionCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Пролонгация заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemProlong(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemProlongResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemProlong(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Пересчет мотивации заявки
     * @param {string} dealCode
     * @param {DealsItemRecalculateMotivationRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemRecalculateMotivation(
      dealCode: string,
      request: DealsItemRecalculateMotivationRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemRecalculateMotivation(dealCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создание или обновление заявки, возвращает актуальное состояние.
     * @param {string} dealCode
     * @param {DealsItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemSave(
      dealCode: string,
      request: DealsItemSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemSaveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemSave(dealCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Тексты PDF спецификации по сделке для вывода на фронт
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemSpecification(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemSpecificationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemSpecification(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование PDF спецификации по сделке
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemSpecificationPdf(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemSpecificationPdf(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование PDF спецификации по сделке
     * @param {string} dealCode
     * @param {DealsItemSpecificationPdfRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemSpecificationPdfPost(
      dealCode: string,
      request: DealsItemSpecificationPdfRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemSpecificationPdfResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemSpecificationPdfPost(dealCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Submit deal request
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemSubmit(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemSubmitResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemSubmit(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemUnlock(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemUnlock(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Обновление партнера заявки
     * @param {string} dealCode
     * @param {DealsItemUpdatePartnerRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemUpdatePartner(
      dealCode: string,
      request: DealsItemUpdatePartnerRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemUpdatePartner(dealCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Обновление адреса партнера заявки
     * @param {string} dealCode
     * @param {DealsItemUpdatePartnerAddressRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemUpdatePartnerAddress(
      dealCode: string,
      request: DealsItemUpdatePartnerAddressRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemUpdatePartnerAddress(dealCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список доступных складов
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemWarehouses(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsItemWarehousesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemWarehouses(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsItemXmlPreview(
      dealCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsItemXmlPreview(dealCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список своих заявок
     * @param {string} [agreementCode] Код соглашения
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {boolean} [hasBills] В наличии документ Счет
     * @param {boolean} [hasPS] Есть ПС (акция)
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {string} [customerCode] deprecated. use customerCodes
     * @param {Array<string>} [customerCodes] Коды клиентов
     * @param {number} [count]
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {string} [employee] Сотрудник, оформивший сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [month] Год и месяц
     * @param {number} [page]
     * @param {string} [query] filter for deals
     * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
     * @param {Array<string>} [stateCodes] Коды статусов
     * @param {Array<string>} [approvalStateCodes] Коды статусов согласования документов
     * @param {Array<string>} [documentTypeCodes] Коды типов прикрепленных документов
     * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsList(
      agreementCode?: string,
      branchOfficeCodes?: Array<string>,
      hasBills?: boolean,
      hasPS?: boolean,
      containsAttachments?: boolean,
      customerCode?: string,
      customerCodes?: Array<string>,
      count?: number,
      dateFrom?: string,
      dateTo?: string,
      employee?: string,
      employeeSet?: string,
      month?: string,
      page?: number,
      query?: string,
      stateCode?: string,
      stateCodes?: Array<string>,
      approvalStateCodes?: Array<string>,
      documentTypeCodes?: Array<string>,
      type?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsList(
        agreementCode,
        branchOfficeCodes,
        hasBills,
        hasPS,
        containsAttachments,
        customerCode,
        customerCodes,
        count,
        dateFrom,
        dateTo,
        employee,
        employeeSet,
        month,
        page,
        query,
        stateCode,
        stateCodes,
        approvalStateCodes,
        documentTypeCodes,
        type,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник статусов сделки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsStates(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsStatesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsStates(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создание новой заявки
     * @param {DealsThreadRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async dealsThread(
      request?: DealsThreadRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DealsThreadResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.dealsThread(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DealApi - factory interface
 * @export
 */
export const DealApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DealApiFp(configuration);
  return {
    /**
     *
     * @summary Создание новой заявки
     * @param {DealsCreateRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsCreate(request?: DealsCreateRequest, options?: any): AxiosPromise<DealsCreateResponse> {
      return localVarFp.dealsCreate(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Просмотр сделки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItem(dealCode: string, options?: any): AxiosPromise<DealsItemResponse> {
      return localVarFp.dealsItem(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     * Используется на странице сделки для вывода доступных альтернатив к товару
     * @summary Список альтернативных товаров
     * @param {string} dealCode
     * @param {DealsItemAlternativesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemAlternatives(
      dealCode: string,
      request: DealsItemAlternativesRequest,
      options?: any
    ): AxiosPromise<DealsItemAlternativesResponse> {
      return localVarFp.dealsItemAlternatives(dealCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Подтверждение заявки МОПом, РОПом или директором. Если заявка соответствует требованиям, она сразу будет отправлена в 1С.
     * @param {string} dealCode
     * @param {DealsItemApprovalConfirmRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemApprovalConfirm(
      dealCode: string,
      request: DealsItemApprovalConfirmRequest,
      options?: any
    ): AxiosPromise<DealsItemApprovalConfirmResponse> {
      return localVarFp.dealsItemApprovalConfirm(dealCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Отклонение заявки руководителем
     * @param {string} dealCode
     * @param {DealsItemApprovalDeclineRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemApprovalDecline(
      dealCode: string,
      request: DealsItemApprovalDeclineRequest,
      options?: any
    ): AxiosPromise<DealsItemApprovalDeclineResponse> {
      return localVarFp.dealsItemApprovalDecline(dealCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary История согласования заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemApprovalHistory(dealCode: string, options?: any): AxiosPromise<DealsItemApprovalHistoryResponse> {
      return localVarFp.dealsItemApprovalHistory(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Отозвать запрос на одобрение заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemApprovalWithdraw(dealCode: string, options?: any): AxiosPromise<DealsItemApprovalWithdrawResponse> {
      return localVarFp.dealsItemApprovalWithdraw(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование PDF счета по сделке
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemBillPdf(dealCode: string, options?: any): AxiosPromise<DealsItemBillPdfResponse> {
      return localVarFp.dealsItemBillPdf(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Клонирование заявки
     * @param {string} dealCode Исходная заявка, из которой клонируются данные. Должно совпадать с sourceDealCode.
     * @param {DealsItemCloneRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemClone(dealCode: string, request: DealsItemCloneRequest, options?: any): AxiosPromise<DealsItemCloneResponse> {
      return localVarFp.dealsItemClone(dealCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Возвращает список грузополучателей к сделке.
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemConsignees(dealCode: string, options?: any): AxiosPromise<DealsItemConsigneesResponse> {
      return localVarFp.dealsItemConsignees(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создание соглашения на основании заявки заявки
     * @param {string} dealCode Исходная заявка, на основании котороый создается соглашение
     * @param {DealsItemCreateAgreementRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemCreateAgreement(
      dealCode: string,
      request: DealsItemCreateAgreementRequest,
      options?: any
    ): AxiosPromise<DealsItemCreateAgreementResponse> {
      return localVarFp.dealsItemCreateAgreement(dealCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Деактивация заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemDeactivate(dealCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.dealsItemDeactivate(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Возвращает доступные типы доставок и стоимости
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemDeliveryTypes(dealCode: string, options?: any): AxiosPromise<DealsItemDeliveryTypesResponse> {
      return localVarFp.dealsItemDeliveryTypes(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список доступных реализаторов
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemDistributors(dealCode: string, options?: any): AxiosPromise<DealsItemDistributorsResponse> {
      return localVarFp.dealsItemDistributors(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление документа из заявки/сделки
     * @param {string} dealCode Код заявки/сделки
     * @param {string} docCode Код документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemDocumentDelete(dealCode: string, docCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.dealsItemDocumentDelete(dealCode, docCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузка нового документа в заявку/сделку
     * @param {string} dealCode Код заявки
     * @param {string} documentTypeCode Тип загружаемого документа
     * @param {any} file Файл к загрузке
     * @param {string} [description]
     * @param {string} [positionCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemDocumentUpload(
      dealCode: string,
      documentTypeCode: string,
      file: any,
      description?: string,
      positionCode?: string,
      options?: any
    ): AxiosPromise<DealDocument> {
      return localVarFp
        .dealsItemDocumentUpload(dealCode, documentTypeCode, file, description, positionCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary История заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemHistory(dealCode: string, options?: any): AxiosPromise<DealsItemHistoryResponse> {
      return localVarFp.dealsItemHistory(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemLock(dealCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.dealsItemLock(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     * Используется на странице сделки для вывода доступных производителей к товару
     * @summary Список альтернативных производителей для материалов
     * @param {string} dealCode Код сделки для исключения уже использованных остатков
     * @param {Array<string>} positionsCodes
     * @param {string} [stockRequired] Только с остатками
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemManufacturers(
      dealCode: string,
      positionsCodes: Array<string>,
      stockRequired?: string,
      options?: any
    ): AxiosPromise<DealsItemManufacturersResponse> {
      return localVarFp.dealsItemManufacturers(dealCode, positionsCodes, stockRequired, options).then(request => request(axios, basePath));
    },
    /**
     * Список возможных плательщиков заявки
     * @param {string} dealCode
     * @param {string} [typeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemPartners(dealCode: string, typeCode?: string, options?: any): AxiosPromise<DealsItemPartnersResponse> {
      return localVarFp.dealsItemPartners(dealCode, typeCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Delete deal position
     * @param {string} dealCode
     * @param {string} positionCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemPositionsItemDelete(
      dealCode: string,
      positionCode: string,
      options?: any
    ): AxiosPromise<DealsItemPositionsItemDeleteResponse> {
      return localVarFp.dealsItemPositionsItemDelete(dealCode, positionCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Move deal position
     * @param {string} dealCode
     * @param {string} positionCode
     * @param {DealsItemPositionsItemMoveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemPositionsItemMove(
      dealCode: string,
      positionCode: string,
      request: DealsItemPositionsItemMoveRequest,
      options?: any
    ): AxiosPromise<DealsItemPositionsItemMoveResponse> {
      return localVarFp.dealsItemPositionsItemMove(dealCode, positionCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Метод отправляет текущий товар в архив, новый добавляет в заявку
     * @summary Replace deal position
     * @param {string} dealCode
     * @param {string} positionCode
     * @param {DealsItemPositionsItemReplaceRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemPositionsItemReplace(
      dealCode: string,
      positionCode: string,
      request: DealsItemPositionsItemReplaceRequest,
      options?: any
    ): AxiosPromise<DealsItemPositionsItemReplaceResponse> {
      return localVarFp.dealsItemPositionsItemReplace(dealCode, positionCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Пролонгация заявки
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemProlong(dealCode: string, options?: any): AxiosPromise<DealsItemProlongResponse> {
      return localVarFp.dealsItemProlong(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Пересчет мотивации заявки
     * @param {string} dealCode
     * @param {DealsItemRecalculateMotivationRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemRecalculateMotivation(dealCode: string, request: DealsItemRecalculateMotivationRequest, options?: any): AxiosPromise<void> {
      return localVarFp.dealsItemRecalculateMotivation(dealCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создание или обновление заявки, возвращает актуальное состояние.
     * @param {string} dealCode
     * @param {DealsItemSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemSave(dealCode: string, request: DealsItemSaveRequest, options?: any): AxiosPromise<DealsItemSaveResponse> {
      return localVarFp.dealsItemSave(dealCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Тексты PDF спецификации по сделке для вывода на фронт
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemSpecification(dealCode: string, options?: any): AxiosPromise<DealsItemSpecificationResponse> {
      return localVarFp.dealsItemSpecification(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование PDF спецификации по сделке
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemSpecificationPdf(dealCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.dealsItemSpecificationPdf(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование PDF спецификации по сделке
     * @param {string} dealCode
     * @param {DealsItemSpecificationPdfRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemSpecificationPdfPost(
      dealCode: string,
      request: DealsItemSpecificationPdfRequest,
      options?: any
    ): AxiosPromise<DealsItemSpecificationPdfResponse> {
      return localVarFp.dealsItemSpecificationPdfPost(dealCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Submit deal request
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemSubmit(dealCode: string, options?: any): AxiosPromise<DealsItemSubmitResponse> {
      return localVarFp.dealsItemSubmit(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemUnlock(dealCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.dealsItemUnlock(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     * Обновление партнера заявки
     * @param {string} dealCode
     * @param {DealsItemUpdatePartnerRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemUpdatePartner(dealCode: string, request: DealsItemUpdatePartnerRequest, options?: any): AxiosPromise<void> {
      return localVarFp.dealsItemUpdatePartner(dealCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Обновление адреса партнера заявки
     * @param {string} dealCode
     * @param {DealsItemUpdatePartnerAddressRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemUpdatePartnerAddress(dealCode: string, request: DealsItemUpdatePartnerAddressRequest, options?: any): AxiosPromise<void> {
      return localVarFp.dealsItemUpdatePartnerAddress(dealCode, request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список доступных складов
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemWarehouses(dealCode: string, options?: any): AxiosPromise<DealsItemWarehousesResponse> {
      return localVarFp.dealsItemWarehouses(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} dealCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsItemXmlPreview(dealCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.dealsItemXmlPreview(dealCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список своих заявок
     * @param {string} [agreementCode] Код соглашения
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {boolean} [hasBills] В наличии документ Счет
     * @param {boolean} [hasPS] Есть ПС (акция)
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {string} [customerCode] deprecated. use customerCodes
     * @param {Array<string>} [customerCodes] Коды клиентов
     * @param {number} [count]
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {string} [employee] Сотрудник, оформивший сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [month] Год и месяц
     * @param {number} [page]
     * @param {string} [query] filter for deals
     * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
     * @param {Array<string>} [stateCodes] Коды статусов
     * @param {Array<string>} [approvalStateCodes] Коды статусов согласования документов
     * @param {Array<string>} [documentTypeCodes] Коды типов прикрепленных документов
     * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsList(
      agreementCode?: string,
      branchOfficeCodes?: Array<string>,
      hasBills?: boolean,
      hasPS?: boolean,
      containsAttachments?: boolean,
      customerCode?: string,
      customerCodes?: Array<string>,
      count?: number,
      dateFrom?: string,
      dateTo?: string,
      employee?: string,
      employeeSet?: string,
      month?: string,
      page?: number,
      query?: string,
      stateCode?: string,
      stateCodes?: Array<string>,
      approvalStateCodes?: Array<string>,
      documentTypeCodes?: Array<string>,
      type?: string,
      options?: any
    ): AxiosPromise<DealsListResponse> {
      return localVarFp
        .dealsList(
          agreementCode,
          branchOfficeCodes,
          hasBills,
          hasPS,
          containsAttachments,
          customerCode,
          customerCodes,
          count,
          dateFrom,
          dateTo,
          employee,
          employeeSet,
          month,
          page,
          query,
          stateCode,
          stateCodes,
          approvalStateCodes,
          documentTypeCodes,
          type,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник статусов сделки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsStates(options?: any): AxiosPromise<DealsStatesResponse> {
      return localVarFp.dealsStates(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создание новой заявки
     * @param {DealsThreadRequest} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    dealsThread(request?: DealsThreadRequest, options?: any): AxiosPromise<DealsThreadResponse> {
      return localVarFp.dealsThread(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DealApi - interface
 * @export
 * @interface DealApi
 */
export interface DealApiInterface {
  /**
   *
   * @summary Создание новой заявки
   * @param {DealsCreateRequest} [request]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsCreate(request?: DealsCreateRequest, options?: AxiosRequestConfig): AxiosPromise<DealsCreateResponse>;

  /**
   *
   * @summary Просмотр сделки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItem(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemResponse>;

  /**
   * Используется на странице сделки для вывода доступных альтернатив к товару
   * @summary Список альтернативных товаров
   * @param {string} dealCode
   * @param {DealsItemAlternativesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemAlternatives(
    dealCode: string,
    request: DealsItemAlternativesRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealsItemAlternativesResponse>;

  /**
   *
   * @summary Подтверждение заявки МОПом, РОПом или директором. Если заявка соответствует требованиям, она сразу будет отправлена в 1С.
   * @param {string} dealCode
   * @param {DealsItemApprovalConfirmRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemApprovalConfirm(
    dealCode: string,
    request: DealsItemApprovalConfirmRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealsItemApprovalConfirmResponse>;

  /**
   *
   * @summary Отклонение заявки руководителем
   * @param {string} dealCode
   * @param {DealsItemApprovalDeclineRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemApprovalDecline(
    dealCode: string,
    request: DealsItemApprovalDeclineRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealsItemApprovalDeclineResponse>;

  /**
   *
   * @summary История согласования заявки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemApprovalHistory(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemApprovalHistoryResponse>;

  /**
   *
   * @summary Отозвать запрос на одобрение заявки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemApprovalWithdraw(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemApprovalWithdrawResponse>;

  /**
   *
   * @summary Формирование PDF счета по сделке
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemBillPdf(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemBillPdfResponse>;

  /**
   *
   * @summary Клонирование заявки
   * @param {string} dealCode Исходная заявка, из которой клонируются данные. Должно совпадать с sourceDealCode.
   * @param {DealsItemCloneRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemClone(dealCode: string, request: DealsItemCloneRequest, options?: AxiosRequestConfig): AxiosPromise<DealsItemCloneResponse>;

  /**
   *
   * @summary Возвращает список грузополучателей к сделке.
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemConsignees(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemConsigneesResponse>;

  /**
   *
   * @summary Создание соглашения на основании заявки заявки
   * @param {string} dealCode Исходная заявка, на основании котороый создается соглашение
   * @param {DealsItemCreateAgreementRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemCreateAgreement(
    dealCode: string,
    request: DealsItemCreateAgreementRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealsItemCreateAgreementResponse>;

  /**
   * Деактивация заявки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemDeactivate(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Возвращает доступные типы доставок и стоимости
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemDeliveryTypes(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemDeliveryTypesResponse>;

  /**
   *
   * @summary Список доступных реализаторов
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemDistributors(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemDistributorsResponse>;

  /**
   *
   * @summary Удаление документа из заявки/сделки
   * @param {string} dealCode Код заявки/сделки
   * @param {string} docCode Код документа
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemDocumentDelete(dealCode: string, docCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Загрузка нового документа в заявку/сделку
   * @param {string} dealCode Код заявки
   * @param {string} documentTypeCode Тип загружаемого документа
   * @param {any} file Файл к загрузке
   * @param {string} [description]
   * @param {string} [positionCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemDocumentUpload(
    dealCode: string,
    documentTypeCode: string,
    file: any,
    description?: string,
    positionCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealDocument>;

  /**
   *
   * @summary История заявки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemHistory(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemHistoryResponse>;

  /**
   *
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemLock(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Используется на странице сделки для вывода доступных производителей к товару
   * @summary Список альтернативных производителей для материалов
   * @param {string} dealCode Код сделки для исключения уже использованных остатков
   * @param {Array<string>} positionsCodes
   * @param {string} [stockRequired] Только с остатками
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemManufacturers(
    dealCode: string,
    positionsCodes: Array<string>,
    stockRequired?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealsItemManufacturersResponse>;

  /**
   * Список возможных плательщиков заявки
   * @param {string} dealCode
   * @param {string} [typeCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemPartners(dealCode: string, typeCode?: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemPartnersResponse>;

  /**
   *
   * @summary Delete deal position
   * @param {string} dealCode
   * @param {string} positionCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemPositionsItemDelete(
    dealCode: string,
    positionCode: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealsItemPositionsItemDeleteResponse>;

  /**
   *
   * @summary Move deal position
   * @param {string} dealCode
   * @param {string} positionCode
   * @param {DealsItemPositionsItemMoveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemPositionsItemMove(
    dealCode: string,
    positionCode: string,
    request: DealsItemPositionsItemMoveRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealsItemPositionsItemMoveResponse>;

  /**
   * Метод отправляет текущий товар в архив, новый добавляет в заявку
   * @summary Replace deal position
   * @param {string} dealCode
   * @param {string} positionCode
   * @param {DealsItemPositionsItemReplaceRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemPositionsItemReplace(
    dealCode: string,
    positionCode: string,
    request: DealsItemPositionsItemReplaceRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealsItemPositionsItemReplaceResponse>;

  /**
   * Пролонгация заявки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemProlong(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemProlongResponse>;

  /**
   *
   * @summary Пересчет мотивации заявки
   * @param {string} dealCode
   * @param {DealsItemRecalculateMotivationRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemRecalculateMotivation(
    dealCode: string,
    request: DealsItemRecalculateMotivationRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Создание или обновление заявки, возвращает актуальное состояние.
   * @param {string} dealCode
   * @param {DealsItemSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemSave(dealCode: string, request: DealsItemSaveRequest, options?: AxiosRequestConfig): AxiosPromise<DealsItemSaveResponse>;

  /**
   *
   * @summary Тексты PDF спецификации по сделке для вывода на фронт
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemSpecification(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemSpecificationResponse>;

  /**
   *
   * @summary Формирование PDF спецификации по сделке
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemSpecificationPdf(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Формирование PDF спецификации по сделке
   * @param {string} dealCode
   * @param {DealsItemSpecificationPdfRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemSpecificationPdfPost(
    dealCode: string,
    request: DealsItemSpecificationPdfRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealsItemSpecificationPdfResponse>;

  /**
   *
   * @summary Submit deal request
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemSubmit(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemSubmitResponse>;

  /**
   *
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemUnlock(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Обновление партнера заявки
   * @param {string} dealCode
   * @param {DealsItemUpdatePartnerRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemUpdatePartner(dealCode: string, request: DealsItemUpdatePartnerRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Обновление адреса партнера заявки
   * @param {string} dealCode
   * @param {DealsItemUpdatePartnerAddressRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemUpdatePartnerAddress(
    dealCode: string,
    request: DealsItemUpdatePartnerAddressRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   *
   * @summary Список доступных складов
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemWarehouses(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<DealsItemWarehousesResponse>;

  /**
   *
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsItemXmlPreview(dealCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Список своих заявок
   * @param {string} [agreementCode] Код соглашения
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {boolean} [hasBills] В наличии документ Счет
   * @param {boolean} [hasPS] Есть ПС (акция)
   * @param {boolean} [containsAttachments] Содержит вложения
   * @param {string} [customerCode] deprecated. use customerCodes
   * @param {Array<string>} [customerCodes] Коды клиентов
   * @param {number} [count]
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {string} [employee] Сотрудник, оформивший сделку
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [month] Год и месяц
   * @param {number} [page]
   * @param {string} [query] filter for deals
   * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
   * @param {Array<string>} [stateCodes] Коды статусов
   * @param {Array<string>} [approvalStateCodes] Коды статусов согласования документов
   * @param {Array<string>} [documentTypeCodes] Коды типов прикрепленных документов
   * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsList(
    agreementCode?: string,
    branchOfficeCodes?: Array<string>,
    hasBills?: boolean,
    hasPS?: boolean,
    containsAttachments?: boolean,
    customerCode?: string,
    customerCodes?: Array<string>,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    employee?: string,
    employeeSet?: string,
    month?: string,
    page?: number,
    query?: string,
    stateCode?: string,
    stateCodes?: Array<string>,
    approvalStateCodes?: Array<string>,
    documentTypeCodes?: Array<string>,
    type?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<DealsListResponse>;

  /**
   *
   * @summary Справочник статусов сделки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsStates(options?: AxiosRequestConfig): AxiosPromise<DealsStatesResponse>;

  /**
   *
   * @summary Создание новой заявки
   * @param {DealsThreadRequest} [request]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApiInterface
   */
  dealsThread(request?: DealsThreadRequest, options?: AxiosRequestConfig): AxiosPromise<DealsThreadResponse>;
}

/**
 * DealApi - object-oriented interface
 * @export
 * @class DealApi
 * @extends {BaseAPI}
 */
export class DealApi extends BaseAPI implements DealApiInterface {
  /**
   *
   * @summary Создание новой заявки
   * @param {DealsCreateRequest} [request]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsCreate(request?: DealsCreateRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsCreate(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Просмотр сделки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItem(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItem(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Используется на странице сделки для вывода доступных альтернатив к товару
   * @summary Список альтернативных товаров
   * @param {string} dealCode
   * @param {DealsItemAlternativesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemAlternatives(dealCode: string, request: DealsItemAlternativesRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemAlternatives(dealCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Подтверждение заявки МОПом, РОПом или директором. Если заявка соответствует требованиям, она сразу будет отправлена в 1С.
   * @param {string} dealCode
   * @param {DealsItemApprovalConfirmRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemApprovalConfirm(dealCode: string, request: DealsItemApprovalConfirmRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemApprovalConfirm(dealCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отклонение заявки руководителем
   * @param {string} dealCode
   * @param {DealsItemApprovalDeclineRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemApprovalDecline(dealCode: string, request: DealsItemApprovalDeclineRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemApprovalDecline(dealCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary История согласования заявки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemApprovalHistory(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemApprovalHistory(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отозвать запрос на одобрение заявки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemApprovalWithdraw(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemApprovalWithdraw(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование PDF счета по сделке
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemBillPdf(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemBillPdf(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Клонирование заявки
   * @param {string} dealCode Исходная заявка, из которой клонируются данные. Должно совпадать с sourceDealCode.
   * @param {DealsItemCloneRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemClone(dealCode: string, request: DealsItemCloneRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemClone(dealCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Возвращает список грузополучателей к сделке.
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemConsignees(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemConsignees(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создание соглашения на основании заявки заявки
   * @param {string} dealCode Исходная заявка, на основании котороый создается соглашение
   * @param {DealsItemCreateAgreementRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemCreateAgreement(dealCode: string, request: DealsItemCreateAgreementRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemCreateAgreement(dealCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Деактивация заявки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemDeactivate(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemDeactivate(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Возвращает доступные типы доставок и стоимости
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemDeliveryTypes(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemDeliveryTypes(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список доступных реализаторов
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemDistributors(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemDistributors(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление документа из заявки/сделки
   * @param {string} dealCode Код заявки/сделки
   * @param {string} docCode Код документа
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemDocumentDelete(dealCode: string, docCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemDocumentDelete(dealCode, docCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузка нового документа в заявку/сделку
   * @param {string} dealCode Код заявки
   * @param {string} documentTypeCode Тип загружаемого документа
   * @param {any} file Файл к загрузке
   * @param {string} [description]
   * @param {string} [positionCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemDocumentUpload(
    dealCode: string,
    documentTypeCode: string,
    file: any,
    description?: string,
    positionCode?: string,
    options?: AxiosRequestConfig
  ) {
    return DealApiFp(this.configuration)
      .dealsItemDocumentUpload(dealCode, documentTypeCode, file, description, positionCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary История заявки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemHistory(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemHistory(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemLock(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemLock(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Используется на странице сделки для вывода доступных производителей к товару
   * @summary Список альтернативных производителей для материалов
   * @param {string} dealCode Код сделки для исключения уже использованных остатков
   * @param {Array<string>} positionsCodes
   * @param {string} [stockRequired] Только с остатками
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemManufacturers(dealCode: string, positionsCodes: Array<string>, stockRequired?: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemManufacturers(dealCode, positionsCodes, stockRequired, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список возможных плательщиков заявки
   * @param {string} dealCode
   * @param {string} [typeCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemPartners(dealCode: string, typeCode?: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemPartners(dealCode, typeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete deal position
   * @param {string} dealCode
   * @param {string} positionCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemPositionsItemDelete(dealCode: string, positionCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemPositionsItemDelete(dealCode, positionCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Move deal position
   * @param {string} dealCode
   * @param {string} positionCode
   * @param {DealsItemPositionsItemMoveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemPositionsItemMove(
    dealCode: string,
    positionCode: string,
    request: DealsItemPositionsItemMoveRequest,
    options?: AxiosRequestConfig
  ) {
    return DealApiFp(this.configuration)
      .dealsItemPositionsItemMove(dealCode, positionCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Метод отправляет текущий товар в архив, новый добавляет в заявку
   * @summary Replace deal position
   * @param {string} dealCode
   * @param {string} positionCode
   * @param {DealsItemPositionsItemReplaceRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemPositionsItemReplace(
    dealCode: string,
    positionCode: string,
    request: DealsItemPositionsItemReplaceRequest,
    options?: AxiosRequestConfig
  ) {
    return DealApiFp(this.configuration)
      .dealsItemPositionsItemReplace(dealCode, positionCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Пролонгация заявки
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemProlong(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemProlong(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Пересчет мотивации заявки
   * @param {string} dealCode
   * @param {DealsItemRecalculateMotivationRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemRecalculateMotivation(dealCode: string, request: DealsItemRecalculateMotivationRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemRecalculateMotivation(dealCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создание или обновление заявки, возвращает актуальное состояние.
   * @param {string} dealCode
   * @param {DealsItemSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemSave(dealCode: string, request: DealsItemSaveRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemSave(dealCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Тексты PDF спецификации по сделке для вывода на фронт
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemSpecification(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemSpecification(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование PDF спецификации по сделке
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemSpecificationPdf(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemSpecificationPdf(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование PDF спецификации по сделке
   * @param {string} dealCode
   * @param {DealsItemSpecificationPdfRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemSpecificationPdfPost(dealCode: string, request: DealsItemSpecificationPdfRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemSpecificationPdfPost(dealCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Submit deal request
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemSubmit(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemSubmit(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemUnlock(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemUnlock(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Обновление партнера заявки
   * @param {string} dealCode
   * @param {DealsItemUpdatePartnerRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemUpdatePartner(dealCode: string, request: DealsItemUpdatePartnerRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemUpdatePartner(dealCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Обновление адреса партнера заявки
   * @param {string} dealCode
   * @param {DealsItemUpdatePartnerAddressRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemUpdatePartnerAddress(dealCode: string, request: DealsItemUpdatePartnerAddressRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemUpdatePartnerAddress(dealCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список доступных складов
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemWarehouses(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemWarehouses(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} dealCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsItemXmlPreview(dealCode: string, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsItemXmlPreview(dealCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список своих заявок
   * @param {string} [agreementCode] Код соглашения
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {boolean} [hasBills] В наличии документ Счет
   * @param {boolean} [hasPS] Есть ПС (акция)
   * @param {boolean} [containsAttachments] Содержит вложения
   * @param {string} [customerCode] deprecated. use customerCodes
   * @param {Array<string>} [customerCodes] Коды клиентов
   * @param {number} [count]
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {string} [employee] Сотрудник, оформивший сделку
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [month] Год и месяц
   * @param {number} [page]
   * @param {string} [query] filter for deals
   * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
   * @param {Array<string>} [stateCodes] Коды статусов
   * @param {Array<string>} [approvalStateCodes] Коды статусов согласования документов
   * @param {Array<string>} [documentTypeCodes] Коды типов прикрепленных документов
   * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsList(
    agreementCode?: string,
    branchOfficeCodes?: Array<string>,
    hasBills?: boolean,
    hasPS?: boolean,
    containsAttachments?: boolean,
    customerCode?: string,
    customerCodes?: Array<string>,
    count?: number,
    dateFrom?: string,
    dateTo?: string,
    employee?: string,
    employeeSet?: string,
    month?: string,
    page?: number,
    query?: string,
    stateCode?: string,
    stateCodes?: Array<string>,
    approvalStateCodes?: Array<string>,
    documentTypeCodes?: Array<string>,
    type?: string,
    options?: AxiosRequestConfig
  ) {
    return DealApiFp(this.configuration)
      .dealsList(
        agreementCode,
        branchOfficeCodes,
        hasBills,
        hasPS,
        containsAttachments,
        customerCode,
        customerCodes,
        count,
        dateFrom,
        dateTo,
        employee,
        employeeSet,
        month,
        page,
        query,
        stateCode,
        stateCodes,
        approvalStateCodes,
        documentTypeCodes,
        type,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник статусов сделки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsStates(options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsStates(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создание новой заявки
   * @param {DealsThreadRequest} [request]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DealApi
   */
  public dealsThread(request?: DealsThreadRequest, options?: AxiosRequestConfig) {
    return DealApiFp(this.configuration)
      .dealsThread(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DeliveryApi - axios parameter creator
 * @export
 */
export const DeliveryApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveryTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/delivery/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeliveryApi - functional programming interface
 * @export
 */
export const DeliveryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DeliveryApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deliveryTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deliveryTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DeliveryApi - factory interface
 * @export
 */
export const DeliveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DeliveryApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deliveryTypes(options?: any): AxiosPromise<DeliveryTypesResponse> {
      return localVarFp.deliveryTypes(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DeliveryApi - interface
 * @export
 * @interface DeliveryApi
 */
export interface DeliveryApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryApiInterface
   */
  deliveryTypes(options?: AxiosRequestConfig): AxiosPromise<DeliveryTypesResponse>;
}

/**
 * DeliveryApi - object-oriented interface
 * @export
 * @class DeliveryApi
 * @extends {BaseAPI}
 */
export class DeliveryApi extends BaseAPI implements DeliveryApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeliveryApi
   */
  public deliveryTypes(options?: AxiosRequestConfig) {
    return DeliveryApiFp(this.configuration)
      .deliveryTypes(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DocFlowApi - axios parameter creator
 * @export
 */
export const DocFlowApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Отправляет договор в систему ЭДО
     * @param {string} code Код договора
     * @param {string} branchOfficeCode Код филиала
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowContractSend: async (code: string, branchOfficeCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('docFlowContractSend', 'code', code);
      // verify required parameter 'branchOfficeCode' is not null or undefined
      assertParamExists('docFlowContractSend', 'branchOfficeCode', branchOfficeCode);
      const localVarPath = `/doc_flow/contract/{code}/send`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список отправок в систему ЭДО
     * @param {string} code Код договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowContractSendList: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('docFlowContractSendList', 'code', code);
      const localVarPath = `/doc_flow/contract/{code}/send`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаляет черновик договора в системе ЭДО
     * @param {string} code Код отправки
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    docFlowContractSendingDelete: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('docFlowContractSendingDelete', 'code', code);
      const localVarPath = `/doc_flow/contract/sending/{code}/delete`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список отправок в систему ЭДО
     * @param {string} [contractCode] Код договора
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [query] Фильтр
     * @param {boolean} [filterActive] Фильтр по активному статусу
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    docFlowContractSendingList: async (
      contractCode?: string,
      employeeSet?: string,
      query?: string,
      filterActive?: boolean,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/doc_flow/contract/sendings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (contractCode !== undefined) {
        localVarQueryParameter['contractCode'] = contractCode;
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (filterActive !== undefined) {
        localVarQueryParameter['filterActive'] = filterActive;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Возвращает контрагентов в системе ЭДО
     * @param {string} customerCode Код клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowCounteragents: async (customerCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'customerCode' is not null or undefined
      assertParamExists('docFlowCounteragents', 'customerCode', customerCode);
      const localVarPath = `/doc_flow/countereragents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновить контрагентов из системы ЭДО
     * @param {DocFlowCounteragentsRefreshRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowCounteragentsRefresh: async (
      request: DocFlowCounteragentsRefreshRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('docFlowCounteragentsRefresh', 'request', request);
      const localVarPath = `/doc_flow/countereragents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаляет черновик договора в системе ЭДО
     * @param {string} code Код отправки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowExchangeDelete: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('docFlowExchangeDelete', 'code', code);
      const localVarPath = `/doc_flow/exchange/{code}/delete`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список отправок в систему ЭДО
     * @param {string} [contractCode] Код договора
     * @param {string} [saleShipmentCode] Код отгрузки
     * @param {Array<string>} [typeCodes] Код типа документа
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [query] Фильтр
     * @param {boolean} [filterActive] Фильтр по активному статусу
     * @param {boolean} [enrichFiles] Добавить список файлов
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowExchangeList: async (
      contractCode?: string,
      saleShipmentCode?: string,
      typeCodes?: Array<string>,
      employeeSet?: string,
      query?: string,
      filterActive?: boolean,
      enrichFiles?: boolean,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/doc_flow/exchanges`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (contractCode !== undefined) {
        localVarQueryParameter['contractCode'] = contractCode;
      }

      if (saleShipmentCode !== undefined) {
        localVarQueryParameter['saleShipmentCode'] = saleShipmentCode;
      }

      if (typeCodes) {
        localVarQueryParameter['typeCodes'] = typeCodes.join(COLLECTION_FORMATS.csv);
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (filterActive !== undefined) {
        localVarQueryParameter['filterActive'] = filterActive;
      }

      if (enrichFiles !== undefined) {
        localVarQueryParameter['enrichFiles'] = enrichFiles;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список типов документов отправленных в систему ЭДО
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowExchangeTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/doc_flow/exchange/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Отправляет отгрузку в систему ЭДО
     * @param {string} code Код отгрузки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowShipmentSend: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('docFlowShipmentSend', 'code', code);
      const localVarPath = `/doc_flow/shipment/{code}/send`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocFlowApi - functional programming interface
 * @export
 */
export const DocFlowApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DocFlowApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Отправляет договор в систему ЭДО
     * @param {string} code Код договора
     * @param {string} branchOfficeCode Код филиала
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docFlowContractSend(
      code: string,
      branchOfficeCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.docFlowContractSend(code, branchOfficeCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список отправок в систему ЭДО
     * @param {string} code Код договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docFlowContractSendList(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocFlowContractSendListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.docFlowContractSendList(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаляет черновик договора в системе ЭДО
     * @param {string} code Код отправки
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async docFlowContractSendingDelete(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.docFlowContractSendingDelete(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список отправок в систему ЭДО
     * @param {string} [contractCode] Код договора
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [query] Фильтр
     * @param {boolean} [filterActive] Фильтр по активному статусу
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async docFlowContractSendingList(
      contractCode?: string,
      employeeSet?: string,
      query?: string,
      filterActive?: boolean,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocFlowContractSendingListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.docFlowContractSendingList(
        contractCode,
        employeeSet,
        query,
        filterActive,
        dateFrom,
        dateTo,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Возвращает контрагентов в системе ЭДО
     * @param {string} customerCode Код клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docFlowCounteragents(
      customerCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocFlowCounteragentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.docFlowCounteragents(customerCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновить контрагентов из системы ЭДО
     * @param {DocFlowCounteragentsRefreshRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docFlowCounteragentsRefresh(
      request: DocFlowCounteragentsRefreshRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocFlowCounteragentsRefreshResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.docFlowCounteragentsRefresh(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаляет черновик договора в системе ЭДО
     * @param {string} code Код отправки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docFlowExchangeDelete(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.docFlowExchangeDelete(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список отправок в систему ЭДО
     * @param {string} [contractCode] Код договора
     * @param {string} [saleShipmentCode] Код отгрузки
     * @param {Array<string>} [typeCodes] Код типа документа
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [query] Фильтр
     * @param {boolean} [filterActive] Фильтр по активному статусу
     * @param {boolean} [enrichFiles] Добавить список файлов
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docFlowExchangeList(
      contractCode?: string,
      saleShipmentCode?: string,
      typeCodes?: Array<string>,
      employeeSet?: string,
      query?: string,
      filterActive?: boolean,
      enrichFiles?: boolean,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocFlowExchangeListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.docFlowExchangeList(
        contractCode,
        saleShipmentCode,
        typeCodes,
        employeeSet,
        query,
        filterActive,
        enrichFiles,
        dateFrom,
        dateTo,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список типов документов отправленных в систему ЭДО
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docFlowExchangeTypeList(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocFlowExchangeTypeListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.docFlowExchangeTypeList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Отправляет отгрузку в систему ЭДО
     * @param {string} code Код отгрузки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docFlowShipmentSend(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.docFlowShipmentSend(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DocFlowApi - factory interface
 * @export
 */
export const DocFlowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DocFlowApiFp(configuration);
  return {
    /**
     *
     * @summary Отправляет договор в систему ЭДО
     * @param {string} code Код договора
     * @param {string} branchOfficeCode Код филиала
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowContractSend(code: string, branchOfficeCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.docFlowContractSend(code, branchOfficeCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список отправок в систему ЭДО
     * @param {string} code Код договора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowContractSendList(code: string, options?: any): AxiosPromise<DocFlowContractSendListResponse> {
      return localVarFp.docFlowContractSendList(code, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Удаляет черновик договора в системе ЭДО
     * @param {string} code Код отправки
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    docFlowContractSendingDelete(code: string, options?: any): AxiosPromise<void> {
      return localVarFp.docFlowContractSendingDelete(code, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список отправок в систему ЭДО
     * @param {string} [contractCode] Код договора
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [query] Фильтр
     * @param {boolean} [filterActive] Фильтр по активному статусу
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    docFlowContractSendingList(
      contractCode?: string,
      employeeSet?: string,
      query?: string,
      filterActive?: boolean,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<DocFlowContractSendingListResponse> {
      return localVarFp
        .docFlowContractSendingList(contractCode, employeeSet, query, filterActive, dateFrom, dateTo, page, count, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Возвращает контрагентов в системе ЭДО
     * @param {string} customerCode Код клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowCounteragents(customerCode: string, options?: any): AxiosPromise<DocFlowCounteragentsResponse> {
      return localVarFp.docFlowCounteragents(customerCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Обновить контрагентов из системы ЭДО
     * @param {DocFlowCounteragentsRefreshRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowCounteragentsRefresh(
      request: DocFlowCounteragentsRefreshRequest,
      options?: any
    ): AxiosPromise<DocFlowCounteragentsRefreshResponse> {
      return localVarFp.docFlowCounteragentsRefresh(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Удаляет черновик договора в системе ЭДО
     * @param {string} code Код отправки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowExchangeDelete(code: string, options?: any): AxiosPromise<void> {
      return localVarFp.docFlowExchangeDelete(code, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список отправок в систему ЭДО
     * @param {string} [contractCode] Код договора
     * @param {string} [saleShipmentCode] Код отгрузки
     * @param {Array<string>} [typeCodes] Код типа документа
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [query] Фильтр
     * @param {boolean} [filterActive] Фильтр по активному статусу
     * @param {boolean} [enrichFiles] Добавить список файлов
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowExchangeList(
      contractCode?: string,
      saleShipmentCode?: string,
      typeCodes?: Array<string>,
      employeeSet?: string,
      query?: string,
      filterActive?: boolean,
      enrichFiles?: boolean,
      dateFrom?: string,
      dateTo?: string,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<DocFlowExchangeListResponse> {
      return localVarFp
        .docFlowExchangeList(
          contractCode,
          saleShipmentCode,
          typeCodes,
          employeeSet,
          query,
          filterActive,
          enrichFiles,
          dateFrom,
          dateTo,
          page,
          count,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список типов документов отправленных в систему ЭДО
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowExchangeTypeList(options?: any): AxiosPromise<DocFlowExchangeTypeListResponse> {
      return localVarFp.docFlowExchangeTypeList(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Отправляет отгрузку в систему ЭДО
     * @param {string} code Код отгрузки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docFlowShipmentSend(code: string, options?: any): AxiosPromise<void> {
      return localVarFp.docFlowShipmentSend(code, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DocFlowApi - interface
 * @export
 * @interface DocFlowApi
 */
export interface DocFlowApiInterface {
  /**
   *
   * @summary Отправляет договор в систему ЭДО
   * @param {string} code Код договора
   * @param {string} branchOfficeCode Код филиала
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApiInterface
   */
  docFlowContractSend(code: string, branchOfficeCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Список отправок в систему ЭДО
   * @param {string} code Код договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApiInterface
   */
  docFlowContractSendList(code: string, options?: AxiosRequestConfig): AxiosPromise<DocFlowContractSendListResponse>;

  /**
   *
   * @summary Удаляет черновик договора в системе ЭДО
   * @param {string} code Код отправки
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DocFlowApiInterface
   */
  docFlowContractSendingDelete(code: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Список отправок в систему ЭДО
   * @param {string} [contractCode] Код договора
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [query] Фильтр
   * @param {boolean} [filterActive] Фильтр по активному статусу
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DocFlowApiInterface
   */
  docFlowContractSendingList(
    contractCode?: string,
    employeeSet?: string,
    query?: string,
    filterActive?: boolean,
    dateFrom?: string,
    dateTo?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<DocFlowContractSendingListResponse>;

  /**
   *
   * @summary Возвращает контрагентов в системе ЭДО
   * @param {string} customerCode Код клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApiInterface
   */
  docFlowCounteragents(customerCode: string, options?: AxiosRequestConfig): AxiosPromise<DocFlowCounteragentsResponse>;

  /**
   *
   * @summary Обновить контрагентов из системы ЭДО
   * @param {DocFlowCounteragentsRefreshRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApiInterface
   */
  docFlowCounteragentsRefresh(
    request: DocFlowCounteragentsRefreshRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<DocFlowCounteragentsRefreshResponse>;

  /**
   *
   * @summary Удаляет черновик договора в системе ЭДО
   * @param {string} code Код отправки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApiInterface
   */
  docFlowExchangeDelete(code: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Список отправок в систему ЭДО
   * @param {string} [contractCode] Код договора
   * @param {string} [saleShipmentCode] Код отгрузки
   * @param {Array<string>} [typeCodes] Код типа документа
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [query] Фильтр
   * @param {boolean} [filterActive] Фильтр по активному статусу
   * @param {boolean} [enrichFiles] Добавить список файлов
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApiInterface
   */
  docFlowExchangeList(
    contractCode?: string,
    saleShipmentCode?: string,
    typeCodes?: Array<string>,
    employeeSet?: string,
    query?: string,
    filterActive?: boolean,
    enrichFiles?: boolean,
    dateFrom?: string,
    dateTo?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<DocFlowExchangeListResponse>;

  /**
   *
   * @summary Список типов документов отправленных в систему ЭДО
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApiInterface
   */
  docFlowExchangeTypeList(options?: AxiosRequestConfig): AxiosPromise<DocFlowExchangeTypeListResponse>;

  /**
   *
   * @summary Отправляет отгрузку в систему ЭДО
   * @param {string} code Код отгрузки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApiInterface
   */
  docFlowShipmentSend(code: string, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * DocFlowApi - object-oriented interface
 * @export
 * @class DocFlowApi
 * @extends {BaseAPI}
 */
export class DocFlowApi extends BaseAPI implements DocFlowApiInterface {
  /**
   *
   * @summary Отправляет договор в систему ЭДО
   * @param {string} code Код договора
   * @param {string} branchOfficeCode Код филиала
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApi
   */
  public docFlowContractSend(code: string, branchOfficeCode: string, options?: AxiosRequestConfig) {
    return DocFlowApiFp(this.configuration)
      .docFlowContractSend(code, branchOfficeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список отправок в систему ЭДО
   * @param {string} code Код договора
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApi
   */
  public docFlowContractSendList(code: string, options?: AxiosRequestConfig) {
    return DocFlowApiFp(this.configuration)
      .docFlowContractSendList(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаляет черновик договора в системе ЭДО
   * @param {string} code Код отправки
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DocFlowApi
   */
  public docFlowContractSendingDelete(code: string, options?: AxiosRequestConfig) {
    return DocFlowApiFp(this.configuration)
      .docFlowContractSendingDelete(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список отправок в систему ЭДО
   * @param {string} [contractCode] Код договора
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [query] Фильтр
   * @param {boolean} [filterActive] Фильтр по активному статусу
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DocFlowApi
   */
  public docFlowContractSendingList(
    contractCode?: string,
    employeeSet?: string,
    query?: string,
    filterActive?: boolean,
    dateFrom?: string,
    dateTo?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return DocFlowApiFp(this.configuration)
      .docFlowContractSendingList(contractCode, employeeSet, query, filterActive, dateFrom, dateTo, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Возвращает контрагентов в системе ЭДО
   * @param {string} customerCode Код клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApi
   */
  public docFlowCounteragents(customerCode: string, options?: AxiosRequestConfig) {
    return DocFlowApiFp(this.configuration)
      .docFlowCounteragents(customerCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновить контрагентов из системы ЭДО
   * @param {DocFlowCounteragentsRefreshRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApi
   */
  public docFlowCounteragentsRefresh(request: DocFlowCounteragentsRefreshRequest, options?: AxiosRequestConfig) {
    return DocFlowApiFp(this.configuration)
      .docFlowCounteragentsRefresh(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаляет черновик договора в системе ЭДО
   * @param {string} code Код отправки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApi
   */
  public docFlowExchangeDelete(code: string, options?: AxiosRequestConfig) {
    return DocFlowApiFp(this.configuration)
      .docFlowExchangeDelete(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список отправок в систему ЭДО
   * @param {string} [contractCode] Код договора
   * @param {string} [saleShipmentCode] Код отгрузки
   * @param {Array<string>} [typeCodes] Код типа документа
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [query] Фильтр
   * @param {boolean} [filterActive] Фильтр по активному статусу
   * @param {boolean} [enrichFiles] Добавить список файлов
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApi
   */
  public docFlowExchangeList(
    contractCode?: string,
    saleShipmentCode?: string,
    typeCodes?: Array<string>,
    employeeSet?: string,
    query?: string,
    filterActive?: boolean,
    enrichFiles?: boolean,
    dateFrom?: string,
    dateTo?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return DocFlowApiFp(this.configuration)
      .docFlowExchangeList(
        contractCode,
        saleShipmentCode,
        typeCodes,
        employeeSet,
        query,
        filterActive,
        enrichFiles,
        dateFrom,
        dateTo,
        page,
        count,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список типов документов отправленных в систему ЭДО
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApi
   */
  public docFlowExchangeTypeList(options?: AxiosRequestConfig) {
    return DocFlowApiFp(this.configuration)
      .docFlowExchangeTypeList(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Отправляет отгрузку в систему ЭДО
   * @param {string} code Код отгрузки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocFlowApi
   */
  public docFlowShipmentSend(code: string, options?: AxiosRequestConfig) {
    return DocFlowApiFp(this.configuration)
      .docFlowShipmentSend(code, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Справочник статусов согласования документов
     * @param {Array<string>} [codes] Получить только список этих кодов
     * @param {Array<string>} [addCodes] Добавить коды к списку выводимому по-умолчанию
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsApprovalStates: async (
      codes?: Array<string>,
      addCodes?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/documents/approval/states`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (codes) {
        localVarQueryParameter['codes'] = codes.join(COLLECTION_FORMATS.csv);
      }

      if (addCodes) {
        localVarQueryParameter['addCodes'] = addCodes.join(COLLECTION_FORMATS.csv);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Поиск по документам
     * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {string} [query] Поисковая строка
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsSearch: async (
      branchOfficeCode?: string,
      query?: string,
      employeeSet?: string,
      dateFrom?: string,
      dateTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/documents/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник типов документов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/documents/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Справочник статусов согласования документов
     * @param {Array<string>} [codes] Получить только список этих кодов
     * @param {Array<string>} [addCodes] Добавить коды к списку выводимому по-умолчанию
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentsApprovalStates(
      codes?: Array<string>,
      addCodes?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentsApprovalStatesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentsApprovalStates(codes, addCodes, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Поиск по документам
     * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {string} [query] Поисковая строка
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentsSearch(
      branchOfficeCode?: string,
      query?: string,
      employeeSet?: string,
      dateFrom?: string,
      dateTo?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentsSearchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentsSearch(
        branchOfficeCode,
        query,
        employeeSet,
        dateFrom,
        dateTo,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник типов документов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async documentsTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentsTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.documentsTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DocumentsApiFp(configuration);
  return {
    /**
     *
     * @summary Справочник статусов согласования документов
     * @param {Array<string>} [codes] Получить только список этих кодов
     * @param {Array<string>} [addCodes] Добавить коды к списку выводимому по-умолчанию
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsApprovalStates(codes?: Array<string>, addCodes?: Array<string>, options?: any): AxiosPromise<DocumentsApprovalStatesResponse> {
      return localVarFp.documentsApprovalStates(codes, addCodes, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Поиск по документам
     * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
     * @param {string} [query] Поисковая строка
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsSearch(
      branchOfficeCode?: string,
      query?: string,
      employeeSet?: string,
      dateFrom?: string,
      dateTo?: string,
      options?: any
    ): AxiosPromise<DocumentsSearchResponse> {
      return localVarFp
        .documentsSearch(branchOfficeCode, query, employeeSet, dateFrom, dateTo, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник типов документов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    documentsTypes(options?: any): AxiosPromise<DocumentsTypesResponse> {
      return localVarFp.documentsTypes(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DocumentsApi - interface
 * @export
 * @interface DocumentsApi
 */
export interface DocumentsApiInterface {
  /**
   *
   * @summary Справочник статусов согласования документов
   * @param {Array<string>} [codes] Получить только список этих кодов
   * @param {Array<string>} [addCodes] Добавить коды к списку выводимому по-умолчанию
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApiInterface
   */
  documentsApprovalStates(
    codes?: Array<string>,
    addCodes?: Array<string>,
    options?: AxiosRequestConfig
  ): AxiosPromise<DocumentsApprovalStatesResponse>;

  /**
   *
   * @summary Поиск по документам
   * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
   * @param {string} [query] Поисковая строка
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApiInterface
   */
  documentsSearch(
    branchOfficeCode?: string,
    query?: string,
    employeeSet?: string,
    dateFrom?: string,
    dateTo?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<DocumentsSearchResponse>;

  /**
   *
   * @summary Справочник типов документов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApiInterface
   */
  documentsTypes(options?: AxiosRequestConfig): AxiosPromise<DocumentsTypesResponse>;
}

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI implements DocumentsApiInterface {
  /**
   *
   * @summary Справочник статусов согласования документов
   * @param {Array<string>} [codes] Получить только список этих кодов
   * @param {Array<string>} [addCodes] Добавить коды к списку выводимому по-умолчанию
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public documentsApprovalStates(codes?: Array<string>, addCodes?: Array<string>, options?: AxiosRequestConfig) {
    return DocumentsApiFp(this.configuration)
      .documentsApprovalStates(codes, addCodes, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Поиск по документам
   * @param {string} [branchOfficeCode] Код филиала, если нужен список договоров не от текущего филиала сотрудника
   * @param {string} [query] Поисковая строка
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public documentsSearch(
    branchOfficeCode?: string,
    query?: string,
    employeeSet?: string,
    dateFrom?: string,
    dateTo?: string,
    options?: AxiosRequestConfig
  ) {
    return DocumentsApiFp(this.configuration)
      .documentsSearch(branchOfficeCode, query, employeeSet, dateFrom, dateTo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник типов документов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocumentsApi
   */
  public documentsTypes(options?: AxiosRequestConfig) {
    return DocumentsApiFp(this.configuration)
      .documentsTypes(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получить мотивацию сотрудника за период
     * @param {string} employeeCode Код сотрудника
     * @param {string} [dateBegin] Начало периода выборки
     * @param {string} [dateEnd] Окончание периода выборки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesItemMotivation: async (
      employeeCode: string,
      dateBegin?: string,
      dateEnd?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'employeeCode' is not null or undefined
      assertParamExists('employeesItemMotivation', 'employeeCode', employeeCode);
      const localVarPath = `/employees/{employeeCode}/motivation`.replace(`{${'employeeCode'}}`, encodeURIComponent(String(employeeCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateBegin !== undefined) {
        localVarQueryParameter['dateBegin'] =
          (dateBegin as any) instanceof Date ? (dateBegin as any).toISOString().substr(0, 10) : dateBegin;
      }

      if (dateEnd !== undefined) {
        localVarQueryParameter['dateEnd'] = (dateEnd as any) instanceof Date ? (dateEnd as any).toISOString().substr(0, 10) : dateEnd;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {EmployeesSearchRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesSearch: async (request: EmployeesSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('employeesSearch', 'request', request);
      const localVarPath = `/employees/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmployeeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Получить мотивацию сотрудника за период
     * @param {string} employeeCode Код сотрудника
     * @param {string} [dateBegin] Начало периода выборки
     * @param {string} [dateEnd] Окончание периода выборки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async employeesItemMotivation(
      employeeCode: string,
      dateBegin?: string,
      dateEnd?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeesItemMotivationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.employeesItemMotivation(employeeCode, dateBegin, dateEnd, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {EmployeesSearchRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async employeesSearch(
      request: EmployeesSearchRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.employeesSearch(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EmployeeApiFp(configuration);
  return {
    /**
     *
     * @summary Получить мотивацию сотрудника за период
     * @param {string} employeeCode Код сотрудника
     * @param {string} [dateBegin] Начало периода выборки
     * @param {string} [dateEnd] Окончание периода выборки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesItemMotivation(
      employeeCode: string,
      dateBegin?: string,
      dateEnd?: string,
      options?: any
    ): AxiosPromise<EmployeesItemMotivationResponse> {
      return localVarFp.employeesItemMotivation(employeeCode, dateBegin, dateEnd, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {EmployeesSearchRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeesSearch(request: EmployeesSearchRequest, options?: any): AxiosPromise<EmployeesResponse> {
      return localVarFp.employeesSearch(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * EmployeeApi - interface
 * @export
 * @interface EmployeeApi
 */
export interface EmployeeApiInterface {
  /**
   *
   * @summary Получить мотивацию сотрудника за период
   * @param {string} employeeCode Код сотрудника
   * @param {string} [dateBegin] Начало периода выборки
   * @param {string} [dateEnd] Окончание периода выборки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApiInterface
   */
  employeesItemMotivation(
    employeeCode: string,
    dateBegin?: string,
    dateEnd?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<EmployeesItemMotivationResponse>;

  /**
   *
   * @param {EmployeesSearchRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApiInterface
   */
  employeesSearch(request: EmployeesSearchRequest, options?: AxiosRequestConfig): AxiosPromise<EmployeesResponse>;
}

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI implements EmployeeApiInterface {
  /**
   *
   * @summary Получить мотивацию сотрудника за период
   * @param {string} employeeCode Код сотрудника
   * @param {string} [dateBegin] Начало периода выборки
   * @param {string} [dateEnd] Окончание периода выборки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public employeesItemMotivation(employeeCode: string, dateBegin?: string, dateEnd?: string, options?: AxiosRequestConfig) {
    return EmployeeApiFp(this.configuration)
      .employeesItemMotivation(employeeCode, dateBegin, dateEnd, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {EmployeesSearchRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeeApi
   */
  public employeesSearch(request: EmployeesSearchRequest, options?: AxiosRequestConfig) {
    return EmployeeApiFp(this.configuration)
      .employeesSearch(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FaqApi - axios parameter creator
 * @export
 */
export const FaqApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Список часто задаваемых вопросов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faq: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/faq`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FaqApi - functional programming interface
 * @export
 */
export const FaqApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FaqApiAxiosParamCreator(configuration);
  return {
    /**
     * Список часто задаваемых вопросов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faq(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.faq(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FaqApi - factory interface
 * @export
 */
export const FaqApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FaqApiFp(configuration);
  return {
    /**
     * Список часто задаваемых вопросов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faq(options?: any): AxiosPromise<FaqResponse> {
      return localVarFp.faq(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * FaqApi - interface
 * @export
 * @interface FaqApi
 */
export interface FaqApiInterface {
  /**
   * Список часто задаваемых вопросов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaqApiInterface
   */
  faq(options?: AxiosRequestConfig): AxiosPromise<FaqResponse>;
}

/**
 * FaqApi - object-oriented interface
 * @export
 * @class FaqApi
 * @extends {BaseAPI}
 */
export class FaqApi extends BaseAPI implements FaqApiInterface {
  /**
   * Список часто задаваемых вопросов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FaqApi
   */
  public faq(options?: AxiosRequestConfig) {
    return FaqApiFp(this.configuration)
      .faq(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Открытие формы обратной связи
     * @param {FeedbackFormOpenRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbackFormOpen: async (request: FeedbackFormOpenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('feedbackFormOpen', 'request', request);
      const localVarPath = `/feedback/form/open`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Отправка заполненной формы обратной связи
     * @param {FeedbackFormSendRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbackFormSend: async (request: FeedbackFormSendRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('feedbackFormSend', 'request', request);
      const localVarPath = `/feedback/form/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Загрузить вложение для формы обратной связи
     * @param {any} file
     * @param {string} formGuid Идентификатор открытой формы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbackUploadAttachment: async (file: any, formGuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists('feedbackUploadAttachment', 'file', file);
      // verify required parameter 'formGuid' is not null or undefined
      assertParamExists('feedbackUploadAttachment', 'formGuid', formGuid);
      const localVarPath = `/feedback/form/uploadAttachment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (formGuid !== undefined) {
        localVarFormParams.append('formGuid', formGuid as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration);
  return {
    /**
     * Открытие формы обратной связи
     * @param {FeedbackFormOpenRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async feedbackFormOpen(
      request: FeedbackFormOpenRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackFormOpenResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackFormOpen(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Отправка заполненной формы обратной связи
     * @param {FeedbackFormSendRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async feedbackFormSend(
      request: FeedbackFormSendRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackFormSendResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackFormSend(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Загрузить вложение для формы обратной связи
     * @param {any} file
     * @param {string} formGuid Идентификатор открытой формы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async feedbackUploadAttachment(
      file: any,
      formGuid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedbackUploadAttachmentResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackUploadAttachment(file, formGuid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FeedbackApiFp(configuration);
  return {
    /**
     * Открытие формы обратной связи
     * @param {FeedbackFormOpenRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbackFormOpen(request: FeedbackFormOpenRequest, options?: any): AxiosPromise<FeedbackFormOpenResponse> {
      return localVarFp.feedbackFormOpen(request, options).then(request => request(axios, basePath));
    },
    /**
     * Отправка заполненной формы обратной связи
     * @param {FeedbackFormSendRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbackFormSend(request: FeedbackFormSendRequest, options?: any): AxiosPromise<FeedbackFormSendResponse> {
      return localVarFp.feedbackFormSend(request, options).then(request => request(axios, basePath));
    },
    /**
     * Загрузить вложение для формы обратной связи
     * @param {any} file
     * @param {string} formGuid Идентификатор открытой формы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    feedbackUploadAttachment(file: any, formGuid: string, options?: any): AxiosPromise<FeedbackUploadAttachmentResponse> {
      return localVarFp.feedbackUploadAttachment(file, formGuid, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * FeedbackApi - interface
 * @export
 * @interface FeedbackApi
 */
export interface FeedbackApiInterface {
  /**
   * Открытие формы обратной связи
   * @param {FeedbackFormOpenRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApiInterface
   */
  feedbackFormOpen(request: FeedbackFormOpenRequest, options?: AxiosRequestConfig): AxiosPromise<FeedbackFormOpenResponse>;

  /**
   * Отправка заполненной формы обратной связи
   * @param {FeedbackFormSendRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApiInterface
   */
  feedbackFormSend(request: FeedbackFormSendRequest, options?: AxiosRequestConfig): AxiosPromise<FeedbackFormSendResponse>;

  /**
   * Загрузить вложение для формы обратной связи
   * @param {any} file
   * @param {string} formGuid Идентификатор открытой формы
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApiInterface
   */
  feedbackUploadAttachment(file: any, formGuid: string, options?: AxiosRequestConfig): AxiosPromise<FeedbackUploadAttachmentResponse>;
}

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI implements FeedbackApiInterface {
  /**
   * Открытие формы обратной связи
   * @param {FeedbackFormOpenRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApi
   */
  public feedbackFormOpen(request: FeedbackFormOpenRequest, options?: AxiosRequestConfig) {
    return FeedbackApiFp(this.configuration)
      .feedbackFormOpen(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Отправка заполненной формы обратной связи
   * @param {FeedbackFormSendRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApi
   */
  public feedbackFormSend(request: FeedbackFormSendRequest, options?: AxiosRequestConfig) {
    return FeedbackApiFp(this.configuration)
      .feedbackFormSend(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Загрузить вложение для формы обратной связи
   * @param {any} file
   * @param {string} formGuid Идентификатор открытой формы
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedbackApi
   */
  public feedbackUploadAttachment(file: any, formGuid: string, options?: AxiosRequestConfig) {
    return FeedbackApiFp(this.configuration)
      .feedbackUploadAttachment(file, formGuid, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FreezeApi - axios parameter creator
 * @export
 */
export const FreezeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Список фризов
     * @param {FreezesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freezes: async (request: FreezesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('freezes', 'request', request);
      const localVarPath = `/freezes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Подтвердить и применить фриз
     * @param {FreezesApproveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freezesApprove: async (request: FreezesApproveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('freezesApprove', 'request', request);
      const localVarPath = `/freezes/approve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создание или обновление фриза, возвращает актуальное состояние.
     * @param {FreezesSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freezesSave: async (request: FreezesSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('freezesSave', 'request', request);
      const localVarPath = `/freezes/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Снять резерв со всего счёта или части позиций
     * @param {FreezesWithdrawRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freezesWithdraw: async (request: FreezesWithdrawRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('freezesWithdraw', 'request', request);
      const localVarPath = `/freezes/withdraw`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FreezeApi - functional programming interface
 * @export
 */
export const FreezeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FreezeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Список фризов
     * @param {FreezesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freezes(
      request: FreezesRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreezesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freezes(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Подтвердить и применить фриз
     * @param {FreezesApproveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freezesApprove(
      request: FreezesApproveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freezesApprove(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создание или обновление фриза, возвращает актуальное состояние.
     * @param {FreezesSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freezesSave(
      request: FreezesSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FreezesSaveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freezesSave(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Снять резерв со всего счёта или части позиций
     * @param {FreezesWithdrawRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async freezesWithdraw(
      request: FreezesWithdrawRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.freezesWithdraw(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FreezeApi - factory interface
 * @export
 */
export const FreezeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FreezeApiFp(configuration);
  return {
    /**
     *
     * @summary Список фризов
     * @param {FreezesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freezes(request: FreezesRequest, options?: any): AxiosPromise<FreezesResponse> {
      return localVarFp.freezes(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Подтвердить и применить фриз
     * @param {FreezesApproveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freezesApprove(request: FreezesApproveRequest, options?: any): AxiosPromise<void> {
      return localVarFp.freezesApprove(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создание или обновление фриза, возвращает актуальное состояние.
     * @param {FreezesSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freezesSave(request: FreezesSaveRequest, options?: any): AxiosPromise<FreezesSaveResponse> {
      return localVarFp.freezesSave(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Снять резерв со всего счёта или части позиций
     * @param {FreezesWithdrawRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    freezesWithdraw(request: FreezesWithdrawRequest, options?: any): AxiosPromise<void> {
      return localVarFp.freezesWithdraw(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * FreezeApi - interface
 * @export
 * @interface FreezeApi
 */
export interface FreezeApiInterface {
  /**
   *
   * @summary Список фризов
   * @param {FreezesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FreezeApiInterface
   */
  freezes(request: FreezesRequest, options?: AxiosRequestConfig): AxiosPromise<FreezesResponse>;

  /**
   *
   * @summary Подтвердить и применить фриз
   * @param {FreezesApproveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FreezeApiInterface
   */
  freezesApprove(request: FreezesApproveRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Создание или обновление фриза, возвращает актуальное состояние.
   * @param {FreezesSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FreezeApiInterface
   */
  freezesSave(request: FreezesSaveRequest, options?: AxiosRequestConfig): AxiosPromise<FreezesSaveResponse>;

  /**
   *
   * @summary Снять резерв со всего счёта или части позиций
   * @param {FreezesWithdrawRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FreezeApiInterface
   */
  freezesWithdraw(request: FreezesWithdrawRequest, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * FreezeApi - object-oriented interface
 * @export
 * @class FreezeApi
 * @extends {BaseAPI}
 */
export class FreezeApi extends BaseAPI implements FreezeApiInterface {
  /**
   *
   * @summary Список фризов
   * @param {FreezesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FreezeApi
   */
  public freezes(request: FreezesRequest, options?: AxiosRequestConfig) {
    return FreezeApiFp(this.configuration)
      .freezes(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Подтвердить и применить фриз
   * @param {FreezesApproveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FreezeApi
   */
  public freezesApprove(request: FreezesApproveRequest, options?: AxiosRequestConfig) {
    return FreezeApiFp(this.configuration)
      .freezesApprove(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создание или обновление фриза, возвращает актуальное состояние.
   * @param {FreezesSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FreezeApi
   */
  public freezesSave(request: FreezesSaveRequest, options?: AxiosRequestConfig) {
    return FreezeApiFp(this.configuration)
      .freezesSave(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Снять резерв со всего счёта или части позиций
   * @param {FreezesWithdrawRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FreezeApi
   */
  public freezesWithdraw(request: FreezesWithdrawRequest, options?: AxiosRequestConfig) {
    return FreezeApiFp(this.configuration)
      .freezesWithdraw(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MyApi - axios parameter creator
 * @export
 */
export const MyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Список групп и людей, которые могут запросить согласование
     * @param {Array<string>} [requiredEmployeeCodes] Клиенты, которые будут выведены в любом случае
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myApprovalsAsking: async (requiredEmployeeCodes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/my/approvals/asking`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (requiredEmployeeCodes) {
        localVarQueryParameter['requiredEmployeeCodes'] = requiredEmployeeCodes.join(COLLECTION_FORMATS.csv);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список сделок и соглашений на согласовании
     * @param {string} [query] filter for deals
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [kind] Тип документов: agreement - соглашения deal - заявки на сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myApprovalsObjects: async (
      query?: string,
      page?: number,
      count?: number,
      kind?: string,
      employeeSet?: string,
      dateFrom?: string,
      dateTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/my/approvals/objects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (kind !== undefined) {
        localVarQueryParameter['kind'] = kind;
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список филиалов, которые может выбрать сотрудник
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myBranchOffices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/my/branchOffices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список клиентов текущего пользователя
     * @param {string} [query] filter for clients
     * @param {number} [page]
     * @param {number} [count]
     * @param {Array<string>} [rating] filter customers by rating
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {Array<string>} [activityStatus] Фильтр по статусу активности
     * @param {Array<string>} [participantRelationshipCodes] Фильтр по типу взаимодействий
     * @param {Array<string>} [occupationCodes] Фильтр по отраслям
     * @param {Array<string>} [occupationSpecifiedCodes] Фильтр по подотраслям
     * @param {Array<string>} [segmentCodes] Фильтр по сегментам
     * @param {Array<string>} [attentionReasonCodes] Фильтр по причине напоминания
     * @param {Array<'inactive' | 'active' | 'invited'>} [docFlowStateCodes] Фильтр по статусу участия во внешней системе СЭД
     * @param {Array<'A' | 'B' | 'C'>} [shipmentSegmentCodes] Фильтр по сегменту отгрузки клиента
     * @param {Array<string>} [recencySegmentCodes] Фильтр по сегменту давности отгрузки клиента
     * @param {Array<string>} [frequencySegmentCodes] Фильтр по сегменту частоты отгрузки клиента
     * @param {Array<string>} [monetarySegmentCodes] Фильтр по сегменту доходности клиента
     * @param {string} [lastShipmentDateFrom] Фильтр ОТ - по дате последней отгрузки клиента в разрезе филиала
     * @param {string} [lastShipmentDateTo] Фильтр ДО - по дате последней отгрузки клиента в разрезе филиала
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myClientsCustomers: async (
      query?: string,
      page?: number,
      count?: number,
      rating?: Array<string>,
      employeeSet?: string,
      activityStatus?: Array<string>,
      participantRelationshipCodes?: Array<string>,
      occupationCodes?: Array<string>,
      occupationSpecifiedCodes?: Array<string>,
      segmentCodes?: Array<string>,
      attentionReasonCodes?: Array<string>,
      docFlowStateCodes?: Array<'inactive' | 'active' | 'invited'>,
      shipmentSegmentCodes?: Array<'A' | 'B' | 'C'>,
      recencySegmentCodes?: Array<string>,
      frequencySegmentCodes?: Array<string>,
      monetarySegmentCodes?: Array<string>,
      lastShipmentDateFrom?: string,
      lastShipmentDateTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/my/clients/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (rating) {
        localVarQueryParameter['rating'] = rating.join(COLLECTION_FORMATS.csv);
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (activityStatus) {
        localVarQueryParameter['activityStatus'] = activityStatus.join(COLLECTION_FORMATS.csv);
      }

      if (participantRelationshipCodes) {
        localVarQueryParameter['participantRelationshipCodes'] = participantRelationshipCodes.join(COLLECTION_FORMATS.csv);
      }

      if (occupationCodes) {
        localVarQueryParameter['occupationCodes'] = occupationCodes.join(COLLECTION_FORMATS.csv);
      }

      if (occupationSpecifiedCodes) {
        localVarQueryParameter['occupationSpecifiedCodes'] = occupationSpecifiedCodes.join(COLLECTION_FORMATS.csv);
      }

      if (segmentCodes) {
        localVarQueryParameter['segmentCodes'] = segmentCodes.join(COLLECTION_FORMATS.csv);
      }

      if (attentionReasonCodes) {
        localVarQueryParameter['attentionReasonCodes'] = attentionReasonCodes.join(COLLECTION_FORMATS.csv);
      }

      if (docFlowStateCodes) {
        localVarQueryParameter['docFlowStateCodes'] = docFlowStateCodes.join(COLLECTION_FORMATS.csv);
      }

      if (shipmentSegmentCodes) {
        localVarQueryParameter['shipmentSegmentCodes'] = shipmentSegmentCodes.join(COLLECTION_FORMATS.csv);
      }

      if (recencySegmentCodes) {
        localVarQueryParameter['recencySegmentCodes'] = recencySegmentCodes.join(COLLECTION_FORMATS.csv);
      }

      if (frequencySegmentCodes) {
        localVarQueryParameter['frequencySegmentCodes'] = frequencySegmentCodes.join(COLLECTION_FORMATS.csv);
      }

      if (monetarySegmentCodes) {
        localVarQueryParameter['monetarySegmentCodes'] = monetarySegmentCodes.join(COLLECTION_FORMATS.csv);
      }

      if (lastShipmentDateFrom !== undefined) {
        localVarQueryParameter['lastShipmentDateFrom'] =
          (lastShipmentDateFrom as any) instanceof Date ? (lastShipmentDateFrom as any).toISOString() : lastShipmentDateFrom;
      }

      if (lastShipmentDateTo !== undefined) {
        localVarQueryParameter['lastShipmentDateTo'] =
          (lastShipmentDateTo as any) instanceof Date ? (lastShipmentDateTo as any).toISOString() : lastShipmentDateTo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Фильтры для клиентов. Список менеджеров, чьих клиентов можно просматривать.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myClientsFilters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/my/clients/filters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список своих заявок
     * @param {string} [query] filter for deals
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
     * @param {Array<string>} [stateCodes] Коды статусов
     * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [employee] Сотрудник, оформивший сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [agreementCode] Код соглашения
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myDeals: async (
      query?: string,
      page?: number,
      count?: number,
      stateCode?: string,
      stateCodes?: Array<string>,
      type?: string,
      branchOfficeCodes?: Array<string>,
      employee?: string,
      employeeSet?: string,
      agreementCode?: string,
      dateFrom?: string,
      dateTo?: string,
      containsAttachments?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/my/deals`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (stateCode !== undefined) {
        localVarQueryParameter['stateCode'] = stateCode;
      }

      if (stateCodes) {
        localVarQueryParameter['stateCodes'] = stateCodes.join(COLLECTION_FORMATS.csv);
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (branchOfficeCodes) {
        localVarQueryParameter['branchOfficeCodes'] = branchOfficeCodes.join(COLLECTION_FORMATS.csv);
      }

      if (employee !== undefined) {
        localVarQueryParameter['employee'] = employee;
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (agreementCode !== undefined) {
        localVarQueryParameter['agreementCode'] = agreementCode;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (containsAttachments !== undefined) {
        localVarQueryParameter['containsAttachments'] = containsAttachments;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список своих заявок (короткие карточки)
     * @param {string} [query] filter for deals
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [employee] Сотрудник, оформивший сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [agreementCode] Код соглашения
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myDealsShort: async (
      query?: string,
      page?: number,
      count?: number,
      type?: string,
      branchOfficeCodes?: Array<string>,
      employee?: string,
      employeeSet?: string,
      agreementCode?: string,
      dateFrom?: string,
      dateTo?: string,
      containsAttachments?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/my/deals/short`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (branchOfficeCodes) {
        localVarQueryParameter['branchOfficeCodes'] = branchOfficeCodes.join(COLLECTION_FORMATS.csv);
      }

      if (employee !== undefined) {
        localVarQueryParameter['employee'] = employee;
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (agreementCode !== undefined) {
        localVarQueryParameter['agreementCode'] = agreementCode;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (containsAttachments !== undefined) {
        localVarQueryParameter['containsAttachments'] = containsAttachments;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MyApi - functional programming interface
 * @export
 */
export const MyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Список групп и людей, которые могут запросить согласование
     * @param {Array<string>} [requiredEmployeeCodes] Клиенты, которые будут выведены в любом случае
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async myApprovalsAsking(
      requiredEmployeeCodes?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyApprovalsAskingResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.myApprovalsAsking(requiredEmployeeCodes, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список сделок и соглашений на согласовании
     * @param {string} [query] filter for deals
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [kind] Тип документов: agreement - соглашения deal - заявки на сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async myApprovalsObjects(
      query?: string,
      page?: number,
      count?: number,
      kind?: string,
      employeeSet?: string,
      dateFrom?: string,
      dateTo?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyApprovalsObjectsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.myApprovalsObjects(
        query,
        page,
        count,
        kind,
        employeeSet,
        dateFrom,
        dateTo,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список филиалов, которые может выбрать сотрудник
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async myBranchOffices(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyBranchOfficesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.myBranchOffices(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список клиентов текущего пользователя
     * @param {string} [query] filter for clients
     * @param {number} [page]
     * @param {number} [count]
     * @param {Array<string>} [rating] filter customers by rating
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {Array<string>} [activityStatus] Фильтр по статусу активности
     * @param {Array<string>} [participantRelationshipCodes] Фильтр по типу взаимодействий
     * @param {Array<string>} [occupationCodes] Фильтр по отраслям
     * @param {Array<string>} [occupationSpecifiedCodes] Фильтр по подотраслям
     * @param {Array<string>} [segmentCodes] Фильтр по сегментам
     * @param {Array<string>} [attentionReasonCodes] Фильтр по причине напоминания
     * @param {Array<'inactive' | 'active' | 'invited'>} [docFlowStateCodes] Фильтр по статусу участия во внешней системе СЭД
     * @param {Array<'A' | 'B' | 'C'>} [shipmentSegmentCodes] Фильтр по сегменту отгрузки клиента
     * @param {Array<string>} [recencySegmentCodes] Фильтр по сегменту давности отгрузки клиента
     * @param {Array<string>} [frequencySegmentCodes] Фильтр по сегменту частоты отгрузки клиента
     * @param {Array<string>} [monetarySegmentCodes] Фильтр по сегменту доходности клиента
     * @param {string} [lastShipmentDateFrom] Фильтр ОТ - по дате последней отгрузки клиента в разрезе филиала
     * @param {string} [lastShipmentDateTo] Фильтр ДО - по дате последней отгрузки клиента в разрезе филиала
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async myClientsCustomers(
      query?: string,
      page?: number,
      count?: number,
      rating?: Array<string>,
      employeeSet?: string,
      activityStatus?: Array<string>,
      participantRelationshipCodes?: Array<string>,
      occupationCodes?: Array<string>,
      occupationSpecifiedCodes?: Array<string>,
      segmentCodes?: Array<string>,
      attentionReasonCodes?: Array<string>,
      docFlowStateCodes?: Array<'inactive' | 'active' | 'invited'>,
      shipmentSegmentCodes?: Array<'A' | 'B' | 'C'>,
      recencySegmentCodes?: Array<string>,
      frequencySegmentCodes?: Array<string>,
      monetarySegmentCodes?: Array<string>,
      lastShipmentDateFrom?: string,
      lastShipmentDateTo?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyClientsCustomersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.myClientsCustomers(
        query,
        page,
        count,
        rating,
        employeeSet,
        activityStatus,
        participantRelationshipCodes,
        occupationCodes,
        occupationSpecifiedCodes,
        segmentCodes,
        attentionReasonCodes,
        docFlowStateCodes,
        shipmentSegmentCodes,
        recencySegmentCodes,
        frequencySegmentCodes,
        monetarySegmentCodes,
        lastShipmentDateFrom,
        lastShipmentDateTo,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Фильтры для клиентов. Список менеджеров, чьих клиентов можно просматривать.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async myClientsFilters(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyClientsFiltersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.myClientsFilters(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список своих заявок
     * @param {string} [query] filter for deals
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
     * @param {Array<string>} [stateCodes] Коды статусов
     * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [employee] Сотрудник, оформивший сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [agreementCode] Код соглашения
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async myDeals(
      query?: string,
      page?: number,
      count?: number,
      stateCode?: string,
      stateCodes?: Array<string>,
      type?: string,
      branchOfficeCodes?: Array<string>,
      employee?: string,
      employeeSet?: string,
      agreementCode?: string,
      dateFrom?: string,
      dateTo?: string,
      containsAttachments?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyDealsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.myDeals(
        query,
        page,
        count,
        stateCode,
        stateCodes,
        type,
        branchOfficeCodes,
        employee,
        employeeSet,
        agreementCode,
        dateFrom,
        dateTo,
        containsAttachments,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список своих заявок (короткие карточки)
     * @param {string} [query] filter for deals
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [employee] Сотрудник, оформивший сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [agreementCode] Код соглашения
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async myDealsShort(
      query?: string,
      page?: number,
      count?: number,
      type?: string,
      branchOfficeCodes?: Array<string>,
      employee?: string,
      employeeSet?: string,
      agreementCode?: string,
      dateFrom?: string,
      dateTo?: string,
      containsAttachments?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyDealsShortResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.myDealsShort(
        query,
        page,
        count,
        type,
        branchOfficeCodes,
        employee,
        employeeSet,
        agreementCode,
        dateFrom,
        dateTo,
        containsAttachments,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MyApi - factory interface
 * @export
 */
export const MyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MyApiFp(configuration);
  return {
    /**
     *
     * @summary Список групп и людей, которые могут запросить согласование
     * @param {Array<string>} [requiredEmployeeCodes] Клиенты, которые будут выведены в любом случае
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myApprovalsAsking(requiredEmployeeCodes?: Array<string>, options?: any): AxiosPromise<MyApprovalsAskingResponse> {
      return localVarFp.myApprovalsAsking(requiredEmployeeCodes, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список сделок и соглашений на согласовании
     * @param {string} [query] filter for deals
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [kind] Тип документов: agreement - соглашения deal - заявки на сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myApprovalsObjects(
      query?: string,
      page?: number,
      count?: number,
      kind?: string,
      employeeSet?: string,
      dateFrom?: string,
      dateTo?: string,
      options?: any
    ): AxiosPromise<MyApprovalsObjectsResponse> {
      return localVarFp
        .myApprovalsObjects(query, page, count, kind, employeeSet, dateFrom, dateTo, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список филиалов, которые может выбрать сотрудник
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myBranchOffices(options?: any): AxiosPromise<MyBranchOfficesResponse> {
      return localVarFp.myBranchOffices(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список клиентов текущего пользователя
     * @param {string} [query] filter for clients
     * @param {number} [page]
     * @param {number} [count]
     * @param {Array<string>} [rating] filter customers by rating
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {Array<string>} [activityStatus] Фильтр по статусу активности
     * @param {Array<string>} [participantRelationshipCodes] Фильтр по типу взаимодействий
     * @param {Array<string>} [occupationCodes] Фильтр по отраслям
     * @param {Array<string>} [occupationSpecifiedCodes] Фильтр по подотраслям
     * @param {Array<string>} [segmentCodes] Фильтр по сегментам
     * @param {Array<string>} [attentionReasonCodes] Фильтр по причине напоминания
     * @param {Array<'inactive' | 'active' | 'invited'>} [docFlowStateCodes] Фильтр по статусу участия во внешней системе СЭД
     * @param {Array<'A' | 'B' | 'C'>} [shipmentSegmentCodes] Фильтр по сегменту отгрузки клиента
     * @param {Array<string>} [recencySegmentCodes] Фильтр по сегменту давности отгрузки клиента
     * @param {Array<string>} [frequencySegmentCodes] Фильтр по сегменту частоты отгрузки клиента
     * @param {Array<string>} [monetarySegmentCodes] Фильтр по сегменту доходности клиента
     * @param {string} [lastShipmentDateFrom] Фильтр ОТ - по дате последней отгрузки клиента в разрезе филиала
     * @param {string} [lastShipmentDateTo] Фильтр ДО - по дате последней отгрузки клиента в разрезе филиала
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myClientsCustomers(
      query?: string,
      page?: number,
      count?: number,
      rating?: Array<string>,
      employeeSet?: string,
      activityStatus?: Array<string>,
      participantRelationshipCodes?: Array<string>,
      occupationCodes?: Array<string>,
      occupationSpecifiedCodes?: Array<string>,
      segmentCodes?: Array<string>,
      attentionReasonCodes?: Array<string>,
      docFlowStateCodes?: Array<'inactive' | 'active' | 'invited'>,
      shipmentSegmentCodes?: Array<'A' | 'B' | 'C'>,
      recencySegmentCodes?: Array<string>,
      frequencySegmentCodes?: Array<string>,
      monetarySegmentCodes?: Array<string>,
      lastShipmentDateFrom?: string,
      lastShipmentDateTo?: string,
      options?: any
    ): AxiosPromise<MyClientsCustomersResponse> {
      return localVarFp
        .myClientsCustomers(
          query,
          page,
          count,
          rating,
          employeeSet,
          activityStatus,
          participantRelationshipCodes,
          occupationCodes,
          occupationSpecifiedCodes,
          segmentCodes,
          attentionReasonCodes,
          docFlowStateCodes,
          shipmentSegmentCodes,
          recencySegmentCodes,
          frequencySegmentCodes,
          monetarySegmentCodes,
          lastShipmentDateFrom,
          lastShipmentDateTo,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Фильтры для клиентов. Список менеджеров, чьих клиентов можно просматривать.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myClientsFilters(options?: any): AxiosPromise<MyClientsFiltersResponse> {
      return localVarFp.myClientsFilters(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список своих заявок
     * @param {string} [query] filter for deals
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
     * @param {Array<string>} [stateCodes] Коды статусов
     * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [employee] Сотрудник, оформивший сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [agreementCode] Код соглашения
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myDeals(
      query?: string,
      page?: number,
      count?: number,
      stateCode?: string,
      stateCodes?: Array<string>,
      type?: string,
      branchOfficeCodes?: Array<string>,
      employee?: string,
      employeeSet?: string,
      agreementCode?: string,
      dateFrom?: string,
      dateTo?: string,
      containsAttachments?: boolean,
      options?: any
    ): AxiosPromise<MyDealsResponse> {
      return localVarFp
        .myDeals(
          query,
          page,
          count,
          stateCode,
          stateCodes,
          type,
          branchOfficeCodes,
          employee,
          employeeSet,
          agreementCode,
          dateFrom,
          dateTo,
          containsAttachments,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список своих заявок (короткие карточки)
     * @param {string} [query] filter for deals
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {string} [employee] Сотрудник, оформивший сделку
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [agreementCode] Код соглашения
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {boolean} [containsAttachments] Содержит вложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myDealsShort(
      query?: string,
      page?: number,
      count?: number,
      type?: string,
      branchOfficeCodes?: Array<string>,
      employee?: string,
      employeeSet?: string,
      agreementCode?: string,
      dateFrom?: string,
      dateTo?: string,
      containsAttachments?: boolean,
      options?: any
    ): AxiosPromise<MyDealsShortResponse> {
      return localVarFp
        .myDealsShort(
          query,
          page,
          count,
          type,
          branchOfficeCodes,
          employee,
          employeeSet,
          agreementCode,
          dateFrom,
          dateTo,
          containsAttachments,
          options
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * MyApi - interface
 * @export
 * @interface MyApi
 */
export interface MyApiInterface {
  /**
   *
   * @summary Список групп и людей, которые могут запросить согласование
   * @param {Array<string>} [requiredEmployeeCodes] Клиенты, которые будут выведены в любом случае
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  myApprovalsAsking(requiredEmployeeCodes?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<MyApprovalsAskingResponse>;

  /**
   *
   * @summary Список сделок и соглашений на согласовании
   * @param {string} [query] filter for deals
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [kind] Тип документов: agreement - соглашения deal - заявки на сделку
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  myApprovalsObjects(
    query?: string,
    page?: number,
    count?: number,
    kind?: string,
    employeeSet?: string,
    dateFrom?: string,
    dateTo?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<MyApprovalsObjectsResponse>;

  /**
   *
   * @summary Список филиалов, которые может выбрать сотрудник
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  myBranchOffices(options?: AxiosRequestConfig): AxiosPromise<MyBranchOfficesResponse>;

  /**
   *
   * @summary Список клиентов текущего пользователя
   * @param {string} [query] filter for clients
   * @param {number} [page]
   * @param {number} [count]
   * @param {Array<string>} [rating] filter customers by rating
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {Array<string>} [activityStatus] Фильтр по статусу активности
   * @param {Array<string>} [participantRelationshipCodes] Фильтр по типу взаимодействий
   * @param {Array<string>} [occupationCodes] Фильтр по отраслям
   * @param {Array<string>} [occupationSpecifiedCodes] Фильтр по подотраслям
   * @param {Array<string>} [segmentCodes] Фильтр по сегментам
   * @param {Array<string>} [attentionReasonCodes] Фильтр по причине напоминания
   * @param {Array<'inactive' | 'active' | 'invited'>} [docFlowStateCodes] Фильтр по статусу участия во внешней системе СЭД
   * @param {Array<'A' | 'B' | 'C'>} [shipmentSegmentCodes] Фильтр по сегменту отгрузки клиента
   * @param {Array<string>} [recencySegmentCodes] Фильтр по сегменту давности отгрузки клиента
   * @param {Array<string>} [frequencySegmentCodes] Фильтр по сегменту частоты отгрузки клиента
   * @param {Array<string>} [monetarySegmentCodes] Фильтр по сегменту доходности клиента
   * @param {string} [lastShipmentDateFrom] Фильтр ОТ - по дате последней отгрузки клиента в разрезе филиала
   * @param {string} [lastShipmentDateTo] Фильтр ДО - по дате последней отгрузки клиента в разрезе филиала
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  myClientsCustomers(
    query?: string,
    page?: number,
    count?: number,
    rating?: Array<string>,
    employeeSet?: string,
    activityStatus?: Array<string>,
    participantRelationshipCodes?: Array<string>,
    occupationCodes?: Array<string>,
    occupationSpecifiedCodes?: Array<string>,
    segmentCodes?: Array<string>,
    attentionReasonCodes?: Array<string>,
    docFlowStateCodes?: Array<'inactive' | 'active' | 'invited'>,
    shipmentSegmentCodes?: Array<'A' | 'B' | 'C'>,
    recencySegmentCodes?: Array<string>,
    frequencySegmentCodes?: Array<string>,
    monetarySegmentCodes?: Array<string>,
    lastShipmentDateFrom?: string,
    lastShipmentDateTo?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<MyClientsCustomersResponse>;

  /**
   *
   * @summary Фильтры для клиентов. Список менеджеров, чьих клиентов можно просматривать.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  myClientsFilters(options?: AxiosRequestConfig): AxiosPromise<MyClientsFiltersResponse>;

  /**
   *
   * @summary Список своих заявок
   * @param {string} [query] filter for deals
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
   * @param {Array<string>} [stateCodes] Коды статусов
   * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {string} [employee] Сотрудник, оформивший сделку
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [agreementCode] Код соглашения
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {boolean} [containsAttachments] Содержит вложения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  myDeals(
    query?: string,
    page?: number,
    count?: number,
    stateCode?: string,
    stateCodes?: Array<string>,
    type?: string,
    branchOfficeCodes?: Array<string>,
    employee?: string,
    employeeSet?: string,
    agreementCode?: string,
    dateFrom?: string,
    dateTo?: string,
    containsAttachments?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<MyDealsResponse>;

  /**
   *
   * @summary Список своих заявок (короткие карточки)
   * @param {string} [query] filter for deals
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {string} [employee] Сотрудник, оформивший сделку
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [agreementCode] Код соглашения
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {boolean} [containsAttachments] Содержит вложения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApiInterface
   */
  myDealsShort(
    query?: string,
    page?: number,
    count?: number,
    type?: string,
    branchOfficeCodes?: Array<string>,
    employee?: string,
    employeeSet?: string,
    agreementCode?: string,
    dateFrom?: string,
    dateTo?: string,
    containsAttachments?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<MyDealsShortResponse>;
}

/**
 * MyApi - object-oriented interface
 * @export
 * @class MyApi
 * @extends {BaseAPI}
 */
export class MyApi extends BaseAPI implements MyApiInterface {
  /**
   *
   * @summary Список групп и людей, которые могут запросить согласование
   * @param {Array<string>} [requiredEmployeeCodes] Клиенты, которые будут выведены в любом случае
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public myApprovalsAsking(requiredEmployeeCodes?: Array<string>, options?: AxiosRequestConfig) {
    return MyApiFp(this.configuration)
      .myApprovalsAsking(requiredEmployeeCodes, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список сделок и соглашений на согласовании
   * @param {string} [query] filter for deals
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [kind] Тип документов: agreement - соглашения deal - заявки на сделку
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public myApprovalsObjects(
    query?: string,
    page?: number,
    count?: number,
    kind?: string,
    employeeSet?: string,
    dateFrom?: string,
    dateTo?: string,
    options?: AxiosRequestConfig
  ) {
    return MyApiFp(this.configuration)
      .myApprovalsObjects(query, page, count, kind, employeeSet, dateFrom, dateTo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список филиалов, которые может выбрать сотрудник
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public myBranchOffices(options?: AxiosRequestConfig) {
    return MyApiFp(this.configuration)
      .myBranchOffices(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список клиентов текущего пользователя
   * @param {string} [query] filter for clients
   * @param {number} [page]
   * @param {number} [count]
   * @param {Array<string>} [rating] filter customers by rating
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {Array<string>} [activityStatus] Фильтр по статусу активности
   * @param {Array<string>} [participantRelationshipCodes] Фильтр по типу взаимодействий
   * @param {Array<string>} [occupationCodes] Фильтр по отраслям
   * @param {Array<string>} [occupationSpecifiedCodes] Фильтр по подотраслям
   * @param {Array<string>} [segmentCodes] Фильтр по сегментам
   * @param {Array<string>} [attentionReasonCodes] Фильтр по причине напоминания
   * @param {Array<'inactive' | 'active' | 'invited'>} [docFlowStateCodes] Фильтр по статусу участия во внешней системе СЭД
   * @param {Array<'A' | 'B' | 'C'>} [shipmentSegmentCodes] Фильтр по сегменту отгрузки клиента
   * @param {Array<string>} [recencySegmentCodes] Фильтр по сегменту давности отгрузки клиента
   * @param {Array<string>} [frequencySegmentCodes] Фильтр по сегменту частоты отгрузки клиента
   * @param {Array<string>} [monetarySegmentCodes] Фильтр по сегменту доходности клиента
   * @param {string} [lastShipmentDateFrom] Фильтр ОТ - по дате последней отгрузки клиента в разрезе филиала
   * @param {string} [lastShipmentDateTo] Фильтр ДО - по дате последней отгрузки клиента в разрезе филиала
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public myClientsCustomers(
    query?: string,
    page?: number,
    count?: number,
    rating?: Array<string>,
    employeeSet?: string,
    activityStatus?: Array<string>,
    participantRelationshipCodes?: Array<string>,
    occupationCodes?: Array<string>,
    occupationSpecifiedCodes?: Array<string>,
    segmentCodes?: Array<string>,
    attentionReasonCodes?: Array<string>,
    docFlowStateCodes?: Array<'inactive' | 'active' | 'invited'>,
    shipmentSegmentCodes?: Array<'A' | 'B' | 'C'>,
    recencySegmentCodes?: Array<string>,
    frequencySegmentCodes?: Array<string>,
    monetarySegmentCodes?: Array<string>,
    lastShipmentDateFrom?: string,
    lastShipmentDateTo?: string,
    options?: AxiosRequestConfig
  ) {
    return MyApiFp(this.configuration)
      .myClientsCustomers(
        query,
        page,
        count,
        rating,
        employeeSet,
        activityStatus,
        participantRelationshipCodes,
        occupationCodes,
        occupationSpecifiedCodes,
        segmentCodes,
        attentionReasonCodes,
        docFlowStateCodes,
        shipmentSegmentCodes,
        recencySegmentCodes,
        frequencySegmentCodes,
        monetarySegmentCodes,
        lastShipmentDateFrom,
        lastShipmentDateTo,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Фильтры для клиентов. Список менеджеров, чьих клиентов можно просматривать.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public myClientsFilters(options?: AxiosRequestConfig) {
    return MyApiFp(this.configuration)
      .myClientsFilters(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список своих заявок
   * @param {string} [query] filter for deals
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [stateCode] deprecated. use stateCodes. Код статуса
   * @param {Array<string>} [stateCodes] Коды статусов
   * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {string} [employee] Сотрудник, оформивший сделку
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [agreementCode] Код соглашения
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {boolean} [containsAttachments] Содержит вложения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public myDeals(
    query?: string,
    page?: number,
    count?: number,
    stateCode?: string,
    stateCodes?: Array<string>,
    type?: string,
    branchOfficeCodes?: Array<string>,
    employee?: string,
    employeeSet?: string,
    agreementCode?: string,
    dateFrom?: string,
    dateTo?: string,
    containsAttachments?: boolean,
    options?: AxiosRequestConfig
  ) {
    return MyApiFp(this.configuration)
      .myDeals(
        query,
        page,
        count,
        stateCode,
        stateCodes,
        type,
        branchOfficeCodes,
        employee,
        employeeSet,
        agreementCode,
        dateFrom,
        dateTo,
        containsAttachments,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список своих заявок (короткие карточки)
   * @param {string} [query] filter for deals
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [type] Тип списка my - по умолчанию - только свои approval - на согласовании от прямых подчиненных officeApproval - на согласовании в офисе (только для директоров) agreement - для поиска по коду соглашения, игнорирует филиал заявки
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {string} [employee] Сотрудник, оформивший сделку
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [agreementCode] Код соглашения
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {boolean} [containsAttachments] Содержит вложения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MyApi
   */
  public myDealsShort(
    query?: string,
    page?: number,
    count?: number,
    type?: string,
    branchOfficeCodes?: Array<string>,
    employee?: string,
    employeeSet?: string,
    agreementCode?: string,
    dateFrom?: string,
    dateTo?: string,
    containsAttachments?: boolean,
    options?: AxiosRequestConfig
  ) {
    return MyApiFp(this.configuration)
      .myDealsShort(
        query,
        page,
        count,
        type,
        branchOfficeCodes,
        employee,
        employeeSet,
        agreementCode,
        dateFrom,
        dateTo,
        containsAttachments,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Список уведомлений пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Просмотр уведомления
     * @param {string} notifyCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userNotificationsItem: async (notifyCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'notifyCode' is not null or undefined
      assertParamExists('userNotificationsItem', 'notifyCode', notifyCode);
      const localVarPath = `/user/notifications/{notifyCode}`.replace(`{${'notifyCode'}}`, encodeURIComponent(String(notifyCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список уведомлений пользователя
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [at]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userNotificationsPaged: async (page?: number, count?: number, at?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user/notifications/paged`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (at !== undefined) {
        localVarQueryParameter['at'] = (at as any) instanceof Date ? (at as any).toISOString() : at;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Прочитать уведомление
     * @param {string} code код уведомления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userNotificationsRead: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('userNotificationsRead', 'code', code);
      const localVarPath = `/user/notifications/read`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Прочитать все уведомления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userNotificationsReadAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user/notifications/read/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Список уведомлений пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userNotifications(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNotificationsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userNotifications(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Просмотр уведомления
     * @param {string} notifyCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userNotificationsItem(
      notifyCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNotificationsItemResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userNotificationsItem(notifyCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список уведомлений пользователя
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [at]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userNotificationsPaged(
      page?: number,
      count?: number,
      at?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNotificationsPagedResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userNotificationsPaged(page, count, at, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Прочитать уведомление
     * @param {string} code код уведомления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userNotificationsRead(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userNotificationsRead(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Прочитать все уведомления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userNotificationsReadAll(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userNotificationsReadAll(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = NotificationApiFp(configuration);
  return {
    /**
     *
     * @summary Список уведомлений пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userNotifications(options?: any): AxiosPromise<UserNotificationsResponse> {
      return localVarFp.userNotifications(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Просмотр уведомления
     * @param {string} notifyCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userNotificationsItem(notifyCode: string, options?: any): AxiosPromise<UserNotificationsItemResponse> {
      return localVarFp.userNotificationsItem(notifyCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список уведомлений пользователя
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [at]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userNotificationsPaged(page?: number, count?: number, at?: string, options?: any): AxiosPromise<UserNotificationsPagedResponse> {
      return localVarFp.userNotificationsPaged(page, count, at, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Прочитать уведомление
     * @param {string} code код уведомления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userNotificationsRead(code: string, options?: any): AxiosPromise<void> {
      return localVarFp.userNotificationsRead(code, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Прочитать все уведомления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userNotificationsReadAll(options?: any): AxiosPromise<void> {
      return localVarFp.userNotificationsReadAll(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * NotificationApi - interface
 * @export
 * @interface NotificationApi
 */
export interface NotificationApiInterface {
  /**
   *
   * @summary Список уведомлений пользователя
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiInterface
   */
  userNotifications(options?: AxiosRequestConfig): AxiosPromise<UserNotificationsResponse>;

  /**
   *
   * @summary Просмотр уведомления
   * @param {string} notifyCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiInterface
   */
  userNotificationsItem(notifyCode: string, options?: AxiosRequestConfig): AxiosPromise<UserNotificationsItemResponse>;

  /**
   *
   * @summary Список уведомлений пользователя
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [at]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiInterface
   */
  userNotificationsPaged(
    page?: number,
    count?: number,
    at?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<UserNotificationsPagedResponse>;

  /**
   *
   * @summary Прочитать уведомление
   * @param {string} code код уведомления
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiInterface
   */
  userNotificationsRead(code: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Прочитать все уведомления
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApiInterface
   */
  userNotificationsReadAll(options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI implements NotificationApiInterface {
  /**
   *
   * @summary Список уведомлений пользователя
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public userNotifications(options?: AxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .userNotifications(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Просмотр уведомления
   * @param {string} notifyCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public userNotificationsItem(notifyCode: string, options?: AxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .userNotificationsItem(notifyCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список уведомлений пользователя
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [at]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public userNotificationsPaged(page?: number, count?: number, at?: string, options?: AxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .userNotificationsPaged(page, count, at, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Прочитать уведомление
   * @param {string} code код уведомления
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public userNotificationsRead(code: string, options?: AxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .userNotificationsRead(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Прочитать все уведомления
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public userNotificationsReadAll(options?: AxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .userNotificationsReadAll(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * OnesApi - axios parameter creator
 * @export
 */
export const OnesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Информация из 1С о готовности к фактурированию счёта
     * @param {OnesBillReconcilePostRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onesBillReconcilePost: async (request: OnesBillReconcilePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('onesBillReconcilePost', 'request', request);
      const localVarPath = `/ones/syncExchange/billReconcile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Информация о резерве по счёту из 1С
     * @param {OnesBillReservePostRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onesBillReservePost: async (request: OnesBillReservePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('onesBillReservePost', 'request', request);
      const localVarPath = `/ones/syncExchange/billReserve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Запрос цены поступления товаров из 1С
     * @param {OnesReceiptPriceQueryPostRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onesReceiptPriceQuery: async (request: OnesReceiptPriceQueryPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('onesReceiptPriceQuery', 'request', request);
      const localVarPath = `/ones/syncExchange/receiptPriceQuery`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {InlineObject} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportProblem: async (request: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('reportProblem', 'request', request);
      const localVarPath = `/ones/syncExchange/reportProblem`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OnesApi - functional programming interface
 * @export
 */
export const OnesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OnesApiAxiosParamCreator(configuration);
  return {
    /**
     * Информация из 1С о готовности к фактурированию счёта
     * @param {OnesBillReconcilePostRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async onesBillReconcilePost(
      request: OnesBillReconcilePostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.onesBillReconcilePost(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Информация о резерве по счёту из 1С
     * @param {OnesBillReservePostRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async onesBillReservePost(
      request: OnesBillReservePostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnesBillReservePostResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.onesBillReservePost(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Запрос цены поступления товаров из 1С
     * @param {OnesReceiptPriceQueryPostRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async onesReceiptPriceQuery(
      request: OnesReceiptPriceQueryPostRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnesReceiptPriceQueryPostResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.onesReceiptPriceQuery(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {InlineObject} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async reportProblem(
      request: InlineObject,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.reportProblem(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OnesApi - factory interface
 * @export
 */
export const OnesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = OnesApiFp(configuration);
  return {
    /**
     * Информация из 1С о готовности к фактурированию счёта
     * @param {OnesBillReconcilePostRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onesBillReconcilePost(request: OnesBillReconcilePostRequest, options?: any): AxiosPromise<void> {
      return localVarFp.onesBillReconcilePost(request, options).then(request => request(axios, basePath));
    },
    /**
     * Информация о резерве по счёту из 1С
     * @param {OnesBillReservePostRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onesBillReservePost(request: OnesBillReservePostRequest, options?: any): AxiosPromise<OnesBillReservePostResponse> {
      return localVarFp.onesBillReservePost(request, options).then(request => request(axios, basePath));
    },
    /**
     * Запрос цены поступления товаров из 1С
     * @param {OnesReceiptPriceQueryPostRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    onesReceiptPriceQuery(request: OnesReceiptPriceQueryPostRequest, options?: any): AxiosPromise<OnesReceiptPriceQueryPostResponse> {
      return localVarFp.onesReceiptPriceQuery(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {InlineObject} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportProblem(request: InlineObject, options?: any): AxiosPromise<void> {
      return localVarFp.reportProblem(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * OnesApi - interface
 * @export
 * @interface OnesApi
 */
export interface OnesApiInterface {
  /**
   * Информация из 1С о готовности к фактурированию счёта
   * @param {OnesBillReconcilePostRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnesApiInterface
   */
  onesBillReconcilePost(request: OnesBillReconcilePostRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Информация о резерве по счёту из 1С
   * @param {OnesBillReservePostRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnesApiInterface
   */
  onesBillReservePost(request: OnesBillReservePostRequest, options?: AxiosRequestConfig): AxiosPromise<OnesBillReservePostResponse>;

  /**
   * Запрос цены поступления товаров из 1С
   * @param {OnesReceiptPriceQueryPostRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnesApiInterface
   */
  onesReceiptPriceQuery(
    request: OnesReceiptPriceQueryPostRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<OnesReceiptPriceQueryPostResponse>;

  /**
   *
   * @param {InlineObject} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnesApiInterface
   */
  reportProblem(request: InlineObject, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * OnesApi - object-oriented interface
 * @export
 * @class OnesApi
 * @extends {BaseAPI}
 */
export class OnesApi extends BaseAPI implements OnesApiInterface {
  /**
   * Информация из 1С о готовности к фактурированию счёта
   * @param {OnesBillReconcilePostRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnesApi
   */
  public onesBillReconcilePost(request: OnesBillReconcilePostRequest, options?: AxiosRequestConfig) {
    return OnesApiFp(this.configuration)
      .onesBillReconcilePost(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Информация о резерве по счёту из 1С
   * @param {OnesBillReservePostRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnesApi
   */
  public onesBillReservePost(request: OnesBillReservePostRequest, options?: AxiosRequestConfig) {
    return OnesApiFp(this.configuration)
      .onesBillReservePost(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Запрос цены поступления товаров из 1С
   * @param {OnesReceiptPriceQueryPostRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnesApi
   */
  public onesReceiptPriceQuery(request: OnesReceiptPriceQueryPostRequest, options?: AxiosRequestConfig) {
    return OnesApiFp(this.configuration)
      .onesReceiptPriceQuery(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {InlineObject} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnesApi
   */
  public reportProblem(request: InlineObject, options?: AxiosRequestConfig) {
    return OnesApiFp(this.configuration)
      .reportProblem(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ParticipantApi - axios parameter creator
 * @export
 */
export const ParticipantApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Список возможных хобби контактного лица (справочник)
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactHobbies: async (query?: string, page?: number, count?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contacts/hobbies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создать запись в справочнике хобби контактного лица
     * @param {ContactHobby} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactHobbiesCreate: async (request: ContactHobby, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contactHobbiesCreate', 'request', request);
      const localVarPath = `/contacts/hobbies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список возможных должностей контактного лица (справочник)
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactPosts: async (query?: string, page?: number, count?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/contacts/posts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Создать запись в справочнике должностей контактного лица
     * @param {ContactPost} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactPostsCreate: async (request: ContactPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contactPostsCreate', 'request', request);
      const localVarPath = `/contacts/posts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Список контактных лиц контрагента
     * @param {string} [customerCode]
     * @param {string} [participantCode]
     * @param {string} [phone] Фильтр по телефону
     * @param {boolean} [enrichCustomers] Загрузить данные о клиенте
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contacts: async (
      customerCode?: string,
      participantCode?: string,
      phone?: string,
      enrichCustomers?: boolean,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contacts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (participantCode !== undefined) {
        localVarQueryParameter['participantCode'] = participantCode;
      }

      if (phone !== undefined) {
        localVarQueryParameter['phone'] = phone;
      }

      if (enrichCustomers !== undefined) {
        localVarQueryParameter['enrichCustomers'] = enrichCustomers;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить историю изменения контактных лиц
     * @param {string} [customerCode]
     * @param {string} [participantCode]
     * @param {string} [contactCode]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactsGetHistory: async (
      customerCode?: string,
      participantCode?: string,
      contactCode?: string,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/contacts/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (participantCode !== undefined) {
        localVarQueryParameter['participantCode'] = participantCode;
      }

      if (contactCode !== undefined) {
        localVarQueryParameter['contactCode'] = contactCode;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаляет контактное лицо
     * @param {string} code Код контактного лица
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactsItemDelete: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('contactsItemDelete', 'code', code);
      const localVarPath = `/contacts/{code}/delete`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Отправить обновления по контактному лицу или создать его
     * @summary Отправить обновленное контактное лицо
     * @param {ContactSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactsSave: async (request: ContactSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('contactsSave', 'request', request);
      const localVarPath = `/contacts/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Формирование заявки на доступ к клиенту
     * @param {ParticipantAccessRequestCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantAccessRequestCreate: async (
      request: ParticipantAccessRequestCreateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('participantAccessRequestCreate', 'request', request);
      const localVarPath = `/participant/access/request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список заявок на доступ к клиенту
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantAccessRequestList: async (
      participantCode?: string,
      customerCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/participant/access/request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (participantCode !== undefined) {
        localVarQueryParameter['participantCode'] = participantCode;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Отправить обновленный документ контрагента или создать его
     * @param {string} typeCode Код типа документа
     * @param {string} title Наименование документа
     * @param {string} [code] Код документа
     * @param {string} [description] Описание документа
     * @param {any} [file] Файл к загрузке
     * @param {string} [participantCode] Код контрагента
     * @param {string} [customerCode] Код клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantDocumentSave: async (
      typeCode: string,
      title: string,
      code?: string,
      description?: string,
      file?: any,
      participantCode?: string,
      customerCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'typeCode' is not null or undefined
      assertParamExists('participantDocumentSave', 'typeCode', typeCode);
      // verify required parameter 'title' is not null or undefined
      assertParamExists('participantDocumentSave', 'title', title);
      const localVarPath = `/participant/docs/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (typeCode !== undefined) {
        localVarFormParams.append('typeCode', typeCode as any);
      }

      if (code !== undefined) {
        localVarFormParams.append('code', code as any);
      }

      if (title !== undefined) {
        localVarFormParams.append('title', title as any);
      }

      if (description !== undefined) {
        localVarFormParams.append('description', description as any);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      if (participantCode !== undefined) {
        localVarFormParams.append('participantCode', participantCode as any);
      }

      if (customerCode !== undefined) {
        localVarFormParams.append('customerCode', customerCode as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список типов документов контрагента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantDocumentTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/participant/docs/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список документов контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantDocuments: async (
      participantCode?: string,
      customerCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/participant/docs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (participantCode !== undefined) {
        localVarQueryParameter['participantCode'] = participantCode;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удаление документа контрагента
     * @param {string} docCode Код документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantDocumentsItemDelete: async (docCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'docCode' is not null or undefined
      assertParamExists('participantDocumentsItemDelete', 'docCode', docCode);
      const localVarPath = `/participant/docs/{docCode}/delete`.replace(`{${'docCode'}}`, encodeURIComponent(String(docCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Получить список исторических взаимодействий контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantHistoricalInteractions: async (
      participantCode?: string,
      customerCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/participant/historicalInteractions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (participantCode !== undefined) {
        localVarQueryParameter['participantCode'] = participantCode;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Типы результатов взаимодействия с контрагентом (справочник)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantInteractionResultTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/participant/interactions/resultTypes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Типы взаимодействий с контрагентом (справочник)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantInteractionTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/participant/interactions/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Получить список взаимодействий контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantInteractions: async (
      participantCode?: string,
      customerCode?: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/participant/interactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (participantCode !== undefined) {
        localVarQueryParameter['participantCode'] = participantCode;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить историю изменений взаимодействий
     * @param {string} [customerCode]
     * @param {string} [interactionCode]
     * @param {string} [participantCode]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantInteractionsGetHistory: async (
      customerCode?: string,
      interactionCode?: string,
      participantCode?: string,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/participant/interactions/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (interactionCode !== undefined) {
        localVarQueryParameter['interactionCode'] = interactionCode;
      }

      if (participantCode !== undefined) {
        localVarQueryParameter['participantCode'] = participantCode;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Отправить обновленное взаимодействие с контрагентом или создать его
     * @param {ParticipantInteractionsSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantInteractionsSave: async (
      request: ParticipantInteractionsSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('participantInteractionsSave', 'request', request);
      const localVarPath = `/participant/interactions/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список значимых дат контрагента
     * @param {string} participantCode
     * @param {Array<string>} [typeCodes] Типы адресов участника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantItemAddresses: async (
      participantCode: string,
      typeCodes?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'participantCode' is not null or undefined
      assertParamExists('participantItemAddresses', 'participantCode', participantCode);
      const localVarPath = `/participant/{participantCode}/addresses`.replace(
        `{${'participantCode'}}`,
        encodeURIComponent(String(participantCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (typeCodes) {
        localVarQueryParameter['typeCodes'] = typeCodes.join(COLLECTION_FORMATS.csv);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Удаление взаимоотношения контрагента с другими партнерами
     * @param {string} code Код взаимоотношения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantRelationshipPartnerDelete: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('participantRelationshipPartnerDelete', 'code', code);
      const localVarPath = `/participant/relationship/partner/{code}/delete`.replace(`{${'code'}}`, encodeURIComponent(String(code)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Сохранение взаимоотношения контрагента с другими партнерами
     * @param {ParticipantRelationshipPartnerSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantRelationshipPartnerSave: async (
      request: ParticipantRelationshipPartnerSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('participantRelationshipPartnerSave', 'request', request);
      const localVarPath = `/participant/relationship/partner/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Взаимоотношения контрагента с другими партнерами
     * @param {string} [customerCode]
     * @param {string} [participantCode]
     * @param {string} [partnerCustomerCode]
     * @param {string} [partnerParticipantCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantRelationshipPartners: async (
      customerCode?: string,
      participantCode?: string,
      partnerCustomerCode?: string,
      partnerParticipantCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/participant/relationship/partners`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (participantCode !== undefined) {
        localVarQueryParameter['participantCode'] = participantCode;
      }

      if (partnerCustomerCode !== undefined) {
        localVarQueryParameter['partnerCustomerCode'] = partnerCustomerCode;
      }

      if (partnerParticipantCode !== undefined) {
        localVarQueryParameter['partnerParticipantCode'] = partnerParticipantCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Словарь типов взаимоотношений контрагента
     * @param {'for_partners' | 'for_client_access'} [preset] Пресеты для типов взаимоотношений (for_partners, for_client_access)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantRelationshipTypes: async (
      preset?: 'for_partners' | 'for_client_access',
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/participant/relationship/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (preset !== undefined) {
        localVarQueryParameter['preset'] = preset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Словарь источников информации о контрагенте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantSources: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/participant/sources`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список значимых дат контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {string} [contactCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantTimePoints: async (
      participantCode?: string,
      customerCode?: string,
      contactCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/participant/timePoints`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (participantCode !== undefined) {
        localVarQueryParameter['participantCode'] = participantCode;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (contactCode !== undefined) {
        localVarQueryParameter['contactCode'] = contactCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Отправить обновленную значимую дату контрагента или создать её
     * @param {ParticipantTimePointsSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantTimePointsSave: async (
      request: ParticipantTimePointsSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('participantTimePointsSave', 'request', request);
      const localVarPath = `/participant/timePoints/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить историю изменений значимых дат
     * @param {string} [customerCode]
     * @param {string} [timePointCode]
     * @param {string} [participantCode]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantTimePointsSaveGetHistory: async (
      customerCode?: string,
      timePointCode?: string,
      participantCode?: string,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/participant/timePoints/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (timePointCode !== undefined) {
        localVarQueryParameter['timePointCode'] = timePointCode;
      }

      if (participantCode !== undefined) {
        localVarQueryParameter['participantCode'] = participantCode;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ParticipantApi - functional programming interface
 * @export
 */
export const ParticipantApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ParticipantApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Список возможных хобби контактного лица (справочник)
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contactHobbies(
      query?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactHobbiesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contactHobbies(query, page, count, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создать запись в справочнике хобби контактного лица
     * @param {ContactHobby} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contactHobbiesCreate(
      request: ContactHobby,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactHobby>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contactHobbiesCreate(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список возможных должностей контактного лица (справочник)
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contactPosts(
      query?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactPostsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contactPosts(query, page, count, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Создать запись в справочнике должностей контактного лица
     * @param {ContactPost} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contactPostsCreate(
      request: ContactPost,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactPost>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contactPostsCreate(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Список контактных лиц контрагента
     * @param {string} [customerCode]
     * @param {string} [participantCode]
     * @param {string} [phone] Фильтр по телефону
     * @param {boolean} [enrichCustomers] Загрузить данные о клиенте
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contacts(
      customerCode?: string,
      participantCode?: string,
      phone?: string,
      enrichCustomers?: boolean,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contacts(
        customerCode,
        participantCode,
        phone,
        enrichCustomers,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получить историю изменения контактных лиц
     * @param {string} [customerCode]
     * @param {string} [participantCode]
     * @param {string} [contactCode]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contactsGetHistory(
      customerCode?: string,
      participantCode?: string,
      contactCode?: string,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactsGetHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contactsGetHistory(
        customerCode,
        participantCode,
        contactCode,
        from,
        to,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаляет контактное лицо
     * @param {string} code Код контактного лица
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contactsItemDelete(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contactsItemDelete(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Отправить обновления по контактному лицу или создать его
     * @summary Отправить обновленное контактное лицо
     * @param {ContactSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async contactsSave(
      request: ContactSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.contactsSave(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Формирование заявки на доступ к клиенту
     * @param {ParticipantAccessRequestCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantAccessRequestCreate(
      request: ParticipantAccessRequestCreateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantAccessRequestCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantAccessRequestCreate(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список заявок на доступ к клиенту
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantAccessRequestList(
      participantCode?: string,
      customerCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantAccessRequestListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantAccessRequestList(participantCode, customerCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Отправить обновленный документ контрагента или создать его
     * @param {string} typeCode Код типа документа
     * @param {string} title Наименование документа
     * @param {string} [code] Код документа
     * @param {string} [description] Описание документа
     * @param {any} [file] Файл к загрузке
     * @param {string} [participantCode] Код контрагента
     * @param {string} [customerCode] Код клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantDocumentSave(
      typeCode: string,
      title: string,
      code?: string,
      description?: string,
      file?: any,
      participantCode?: string,
      customerCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantDocumentSaveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantDocumentSave(
        typeCode,
        title,
        code,
        description,
        file,
        participantCode,
        customerCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список типов документов контрагента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantDocumentTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantDocumentsTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantDocumentTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список документов контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantDocuments(
      participantCode?: string,
      customerCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantDocumentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantDocuments(participantCode, customerCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Удаление документа контрагента
     * @param {string} docCode Код документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantDocumentsItemDelete(
      docCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantDocumentsItemDelete(docCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Получить список исторических взаимодействий контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantHistoricalInteractions(
      participantCode?: string,
      customerCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantHistoricalInteractionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantHistoricalInteractions(participantCode, customerCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Типы результатов взаимодействия с контрагентом (справочник)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantInteractionResultTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantInteractionResultTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantInteractionResultTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Типы взаимодействий с контрагентом (справочник)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantInteractionTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantInteractionTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantInteractionTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Получить список взаимодействий контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantInteractions(
      participantCode?: string,
      customerCode?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantInteractionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantInteractions(
        participantCode,
        customerCode,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получить историю изменений взаимодействий
     * @param {string} [customerCode]
     * @param {string} [interactionCode]
     * @param {string} [participantCode]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantInteractionsGetHistory(
      customerCode?: string,
      interactionCode?: string,
      participantCode?: string,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantInteractionsGetHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantInteractionsGetHistory(
        customerCode,
        interactionCode,
        participantCode,
        from,
        to,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Отправить обновленное взаимодействие с контрагентом или создать его
     * @param {ParticipantInteractionsSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantInteractionsSave(
      request: ParticipantInteractionsSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantInteraction>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantInteractionsSave(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список значимых дат контрагента
     * @param {string} participantCode
     * @param {Array<string>} [typeCodes] Типы адресов участника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantItemAddresses(
      participantCode: string,
      typeCodes?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantItemAddressesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantItemAddresses(participantCode, typeCodes, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Удаление взаимоотношения контрагента с другими партнерами
     * @param {string} code Код взаимоотношения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantRelationshipPartnerDelete(
      code: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantRelationshipPartnerDelete(code, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Сохранение взаимоотношения контрагента с другими партнерами
     * @param {ParticipantRelationshipPartnerSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantRelationshipPartnerSave(
      request: ParticipantRelationshipPartnerSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantRelationshipPartnerSave(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Взаимоотношения контрагента с другими партнерами
     * @param {string} [customerCode]
     * @param {string} [participantCode]
     * @param {string} [partnerCustomerCode]
     * @param {string} [partnerParticipantCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantRelationshipPartners(
      customerCode?: string,
      participantCode?: string,
      partnerCustomerCode?: string,
      partnerParticipantCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantRelationshipPartnersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantRelationshipPartners(
        customerCode,
        participantCode,
        partnerCustomerCode,
        partnerParticipantCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Словарь типов взаимоотношений контрагента
     * @param {'for_partners' | 'for_client_access'} [preset] Пресеты для типов взаимоотношений (for_partners, for_client_access)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantRelationshipTypes(
      preset?: 'for_partners' | 'for_client_access',
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantRelationshipTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantRelationshipTypes(preset, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Словарь источников информации о контрагенте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantSources(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantSourcesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantSources(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список значимых дат контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {string} [contactCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantTimePoints(
      participantCode?: string,
      customerCode?: string,
      contactCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantTimePointsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantTimePoints(participantCode, customerCode, contactCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Отправить обновленную значимую дату контрагента или создать её
     * @param {ParticipantTimePointsSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantTimePointsSave(
      request: ParticipantTimePointsSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantTimePoint>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantTimePointsSave(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получить историю изменений значимых дат
     * @param {string} [customerCode]
     * @param {string} [timePointCode]
     * @param {string} [participantCode]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async participantTimePointsSaveGetHistory(
      customerCode?: string,
      timePointCode?: string,
      participantCode?: string,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipantTimePointsSaveGetHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.participantTimePointsSaveGetHistory(
        customerCode,
        timePointCode,
        participantCode,
        from,
        to,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ParticipantApi - factory interface
 * @export
 */
export const ParticipantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ParticipantApiFp(configuration);
  return {
    /**
     *
     * @summary Список возможных хобби контактного лица (справочник)
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactHobbies(query?: string, page?: number, count?: number, options?: any): AxiosPromise<ContactHobbiesResponse> {
      return localVarFp.contactHobbies(query, page, count, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создать запись в справочнике хобби контактного лица
     * @param {ContactHobby} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactHobbiesCreate(request: ContactHobby, options?: any): AxiosPromise<ContactHobby> {
      return localVarFp.contactHobbiesCreate(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список возможных должностей контактного лица (справочник)
     * @param {string} [query]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactPosts(query?: string, page?: number, count?: number, options?: any): AxiosPromise<ContactPostsResponse> {
      return localVarFp.contactPosts(query, page, count, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Создать запись в справочнике должностей контактного лица
     * @param {ContactPost} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactPostsCreate(request: ContactPost, options?: any): AxiosPromise<ContactPost> {
      return localVarFp.contactPostsCreate(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Список контактных лиц контрагента
     * @param {string} [customerCode]
     * @param {string} [participantCode]
     * @param {string} [phone] Фильтр по телефону
     * @param {boolean} [enrichCustomers] Загрузить данные о клиенте
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contacts(
      customerCode?: string,
      participantCode?: string,
      phone?: string,
      enrichCustomers?: boolean,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<ContactsResponse> {
      return localVarFp
        .contacts(customerCode, participantCode, phone, enrichCustomers, page, count, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Получить историю изменения контактных лиц
     * @param {string} [customerCode]
     * @param {string} [participantCode]
     * @param {string} [contactCode]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactsGetHistory(
      customerCode?: string,
      participantCode?: string,
      contactCode?: string,
      from?: string,
      to?: string,
      options?: any
    ): AxiosPromise<ContactsGetHistoryResponse> {
      return localVarFp
        .contactsGetHistory(customerCode, participantCode, contactCode, from, to, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Удаляет контактное лицо
     * @param {string} code Код контактного лица
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactsItemDelete(code: string, options?: any): AxiosPromise<void> {
      return localVarFp.contactsItemDelete(code, options).then(request => request(axios, basePath));
    },
    /**
     * Отправить обновления по контактному лицу или создать его
     * @summary Отправить обновленное контактное лицо
     * @param {ContactSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    contactsSave(request: ContactSaveRequest, options?: any): AxiosPromise<Contact> {
      return localVarFp.contactsSave(request, options).then(request => request(axios, basePath));
    },
    /**
     * Формирование заявки на доступ к клиенту
     * @param {ParticipantAccessRequestCreateRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantAccessRequestCreate(
      request: ParticipantAccessRequestCreateRequest,
      options?: any
    ): AxiosPromise<ParticipantAccessRequestCreateResponse> {
      return localVarFp.participantAccessRequestCreate(request, options).then(request => request(axios, basePath));
    },
    /**
     * Список заявок на доступ к клиенту
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantAccessRequestList(
      participantCode?: string,
      customerCode?: string,
      options?: any
    ): AxiosPromise<ParticipantAccessRequestListResponse> {
      return localVarFp.participantAccessRequestList(participantCode, customerCode, options).then(request => request(axios, basePath));
    },
    /**
     * Отправить обновленный документ контрагента или создать его
     * @param {string} typeCode Код типа документа
     * @param {string} title Наименование документа
     * @param {string} [code] Код документа
     * @param {string} [description] Описание документа
     * @param {any} [file] Файл к загрузке
     * @param {string} [participantCode] Код контрагента
     * @param {string} [customerCode] Код клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantDocumentSave(
      typeCode: string,
      title: string,
      code?: string,
      description?: string,
      file?: any,
      participantCode?: string,
      customerCode?: string,
      options?: any
    ): AxiosPromise<ParticipantDocumentSaveResponse> {
      return localVarFp
        .participantDocumentSave(typeCode, title, code, description, file, participantCode, customerCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Список типов документов контрагента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantDocumentTypes(options?: any): AxiosPromise<ParticipantDocumentsTypesResponse> {
      return localVarFp.participantDocumentTypes(options).then(request => request(axios, basePath));
    },
    /**
     * Список документов контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantDocuments(participantCode?: string, customerCode?: string, options?: any): AxiosPromise<ParticipantDocumentsResponse> {
      return localVarFp.participantDocuments(participantCode, customerCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Удаление документа контрагента
     * @param {string} docCode Код документа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantDocumentsItemDelete(docCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.participantDocumentsItemDelete(docCode, options).then(request => request(axios, basePath));
    },
    /**
     * Получить список исторических взаимодействий контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantHistoricalInteractions(
      participantCode?: string,
      customerCode?: string,
      options?: any
    ): AxiosPromise<ParticipantHistoricalInteractionsResponse> {
      return localVarFp.participantHistoricalInteractions(participantCode, customerCode, options).then(request => request(axios, basePath));
    },
    /**
     * Типы результатов взаимодействия с контрагентом (справочник)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantInteractionResultTypes(options?: any): AxiosPromise<ParticipantInteractionResultTypesResponse> {
      return localVarFp.participantInteractionResultTypes(options).then(request => request(axios, basePath));
    },
    /**
     * Типы взаимодействий с контрагентом (справочник)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantInteractionTypes(options?: any): AxiosPromise<ParticipantInteractionTypesResponse> {
      return localVarFp.participantInteractionTypes(options).then(request => request(axios, basePath));
    },
    /**
     * Получить список взаимодействий контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantInteractions(
      participantCode?: string,
      customerCode?: string,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<ParticipantInteractionsResponse> {
      return localVarFp
        .participantInteractions(participantCode, customerCode, page, count, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Получить историю изменений взаимодействий
     * @param {string} [customerCode]
     * @param {string} [interactionCode]
     * @param {string} [participantCode]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantInteractionsGetHistory(
      customerCode?: string,
      interactionCode?: string,
      participantCode?: string,
      from?: string,
      to?: string,
      options?: any
    ): AxiosPromise<ParticipantInteractionsGetHistoryResponse> {
      return localVarFp
        .participantInteractionsGetHistory(customerCode, interactionCode, participantCode, from, to, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Отправить обновленное взаимодействие с контрагентом или создать его
     * @param {ParticipantInteractionsSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantInteractionsSave(request: ParticipantInteractionsSaveRequest, options?: any): AxiosPromise<ParticipantInteraction> {
      return localVarFp.participantInteractionsSave(request, options).then(request => request(axios, basePath));
    },
    /**
     * Список значимых дат контрагента
     * @param {string} participantCode
     * @param {Array<string>} [typeCodes] Типы адресов участника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantItemAddresses(
      participantCode: string,
      typeCodes?: Array<string>,
      options?: any
    ): AxiosPromise<ParticipantItemAddressesResponse> {
      return localVarFp.participantItemAddresses(participantCode, typeCodes, options).then(request => request(axios, basePath));
    },
    /**
     * Удаление взаимоотношения контрагента с другими партнерами
     * @param {string} code Код взаимоотношения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantRelationshipPartnerDelete(code: string, options?: any): AxiosPromise<void> {
      return localVarFp.participantRelationshipPartnerDelete(code, options).then(request => request(axios, basePath));
    },
    /**
     * Сохранение взаимоотношения контрагента с другими партнерами
     * @param {ParticipantRelationshipPartnerSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantRelationshipPartnerSave(request: ParticipantRelationshipPartnerSaveRequest, options?: any): AxiosPromise<void> {
      return localVarFp.participantRelationshipPartnerSave(request, options).then(request => request(axios, basePath));
    },
    /**
     * Взаимоотношения контрагента с другими партнерами
     * @param {string} [customerCode]
     * @param {string} [participantCode]
     * @param {string} [partnerCustomerCode]
     * @param {string} [partnerParticipantCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantRelationshipPartners(
      customerCode?: string,
      participantCode?: string,
      partnerCustomerCode?: string,
      partnerParticipantCode?: string,
      options?: any
    ): AxiosPromise<ParticipantRelationshipPartnersResponse> {
      return localVarFp
        .participantRelationshipPartners(customerCode, participantCode, partnerCustomerCode, partnerParticipantCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Словарь типов взаимоотношений контрагента
     * @param {'for_partners' | 'for_client_access'} [preset] Пресеты для типов взаимоотношений (for_partners, for_client_access)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantRelationshipTypes(
      preset?: 'for_partners' | 'for_client_access',
      options?: any
    ): AxiosPromise<ParticipantRelationshipTypesResponse> {
      return localVarFp.participantRelationshipTypes(preset, options).then(request => request(axios, basePath));
    },
    /**
     * Словарь источников информации о контрагенте
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantSources(options?: any): AxiosPromise<ParticipantSourcesResponse> {
      return localVarFp.participantSources(options).then(request => request(axios, basePath));
    },
    /**
     * Список значимых дат контрагента
     * @param {string} [participantCode]
     * @param {string} [customerCode]
     * @param {string} [contactCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantTimePoints(
      participantCode?: string,
      customerCode?: string,
      contactCode?: string,
      options?: any
    ): AxiosPromise<ParticipantTimePointsResponse> {
      return localVarFp
        .participantTimePoints(participantCode, customerCode, contactCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Отправить обновленную значимую дату контрагента или создать её
     * @param {ParticipantTimePointsSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantTimePointsSave(request: ParticipantTimePointsSaveRequest, options?: any): AxiosPromise<ParticipantTimePoint> {
      return localVarFp.participantTimePointsSave(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Получить историю изменений значимых дат
     * @param {string} [customerCode]
     * @param {string} [timePointCode]
     * @param {string} [participantCode]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    participantTimePointsSaveGetHistory(
      customerCode?: string,
      timePointCode?: string,
      participantCode?: string,
      from?: string,
      to?: string,
      options?: any
    ): AxiosPromise<ParticipantTimePointsSaveGetHistoryResponse> {
      return localVarFp
        .participantTimePointsSaveGetHistory(customerCode, timePointCode, participantCode, from, to, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ParticipantApi - interface
 * @export
 * @interface ParticipantApi
 */
export interface ParticipantApiInterface {
  /**
   *
   * @summary Список возможных хобби контактного лица (справочник)
   * @param {string} [query]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  contactHobbies(query?: string, page?: number, count?: number, options?: AxiosRequestConfig): AxiosPromise<ContactHobbiesResponse>;

  /**
   *
   * @summary Создать запись в справочнике хобби контактного лица
   * @param {ContactHobby} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  contactHobbiesCreate(request: ContactHobby, options?: AxiosRequestConfig): AxiosPromise<ContactHobby>;

  /**
   *
   * @summary Список возможных должностей контактного лица (справочник)
   * @param {string} [query]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  contactPosts(query?: string, page?: number, count?: number, options?: AxiosRequestConfig): AxiosPromise<ContactPostsResponse>;

  /**
   *
   * @summary Создать запись в справочнике должностей контактного лица
   * @param {ContactPost} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  contactPostsCreate(request: ContactPost, options?: AxiosRequestConfig): AxiosPromise<ContactPost>;

  /**
   *
   * @summary Список контактных лиц контрагента
   * @param {string} [customerCode]
   * @param {string} [participantCode]
   * @param {string} [phone] Фильтр по телефону
   * @param {boolean} [enrichCustomers] Загрузить данные о клиенте
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  contacts(
    customerCode?: string,
    participantCode?: string,
    phone?: string,
    enrichCustomers?: boolean,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContactsResponse>;

  /**
   *
   * @summary Получить историю изменения контактных лиц
   * @param {string} [customerCode]
   * @param {string} [participantCode]
   * @param {string} [contactCode]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  contactsGetHistory(
    customerCode?: string,
    participantCode?: string,
    contactCode?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ContactsGetHistoryResponse>;

  /**
   *
   * @summary Удаляет контактное лицо
   * @param {string} code Код контактного лица
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  contactsItemDelete(code: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Отправить обновления по контактному лицу или создать его
   * @summary Отправить обновленное контактное лицо
   * @param {ContactSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  contactsSave(request: ContactSaveRequest, options?: AxiosRequestConfig): AxiosPromise<Contact>;

  /**
   * Формирование заявки на доступ к клиенту
   * @param {ParticipantAccessRequestCreateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantAccessRequestCreate(
    request: ParticipantAccessRequestCreateRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantAccessRequestCreateResponse>;

  /**
   * Список заявок на доступ к клиенту
   * @param {string} [participantCode]
   * @param {string} [customerCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantAccessRequestList(
    participantCode?: string,
    customerCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantAccessRequestListResponse>;

  /**
   * Отправить обновленный документ контрагента или создать его
   * @param {string} typeCode Код типа документа
   * @param {string} title Наименование документа
   * @param {string} [code] Код документа
   * @param {string} [description] Описание документа
   * @param {any} [file] Файл к загрузке
   * @param {string} [participantCode] Код контрагента
   * @param {string} [customerCode] Код клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantDocumentSave(
    typeCode: string,
    title: string,
    code?: string,
    description?: string,
    file?: any,
    participantCode?: string,
    customerCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantDocumentSaveResponse>;

  /**
   * Список типов документов контрагента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantDocumentTypes(options?: AxiosRequestConfig): AxiosPromise<ParticipantDocumentsTypesResponse>;

  /**
   * Список документов контрагента
   * @param {string} [participantCode]
   * @param {string} [customerCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantDocuments(
    participantCode?: string,
    customerCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantDocumentsResponse>;

  /**
   *
   * @summary Удаление документа контрагента
   * @param {string} docCode Код документа
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantDocumentsItemDelete(docCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Получить список исторических взаимодействий контрагента
   * @param {string} [participantCode]
   * @param {string} [customerCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantHistoricalInteractions(
    participantCode?: string,
    customerCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantHistoricalInteractionsResponse>;

  /**
   * Типы результатов взаимодействия с контрагентом (справочник)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantInteractionResultTypes(options?: AxiosRequestConfig): AxiosPromise<ParticipantInteractionResultTypesResponse>;

  /**
   * Типы взаимодействий с контрагентом (справочник)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantInteractionTypes(options?: AxiosRequestConfig): AxiosPromise<ParticipantInteractionTypesResponse>;

  /**
   * Получить список взаимодействий контрагента
   * @param {string} [participantCode]
   * @param {string} [customerCode]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantInteractions(
    participantCode?: string,
    customerCode?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantInteractionsResponse>;

  /**
   *
   * @summary Получить историю изменений взаимодействий
   * @param {string} [customerCode]
   * @param {string} [interactionCode]
   * @param {string} [participantCode]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantInteractionsGetHistory(
    customerCode?: string,
    interactionCode?: string,
    participantCode?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantInteractionsGetHistoryResponse>;

  /**
   * Отправить обновленное взаимодействие с контрагентом или создать его
   * @param {ParticipantInteractionsSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantInteractionsSave(
    request: ParticipantInteractionsSaveRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantInteraction>;

  /**
   * Список значимых дат контрагента
   * @param {string} participantCode
   * @param {Array<string>} [typeCodes] Типы адресов участника
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantItemAddresses(
    participantCode: string,
    typeCodes?: Array<string>,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantItemAddressesResponse>;

  /**
   * Удаление взаимоотношения контрагента с другими партнерами
   * @param {string} code Код взаимоотношения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantRelationshipPartnerDelete(code: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Сохранение взаимоотношения контрагента с другими партнерами
   * @param {ParticipantRelationshipPartnerSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantRelationshipPartnerSave(request: ParticipantRelationshipPartnerSaveRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Взаимоотношения контрагента с другими партнерами
   * @param {string} [customerCode]
   * @param {string} [participantCode]
   * @param {string} [partnerCustomerCode]
   * @param {string} [partnerParticipantCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantRelationshipPartners(
    customerCode?: string,
    participantCode?: string,
    partnerCustomerCode?: string,
    partnerParticipantCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantRelationshipPartnersResponse>;

  /**
   * Словарь типов взаимоотношений контрагента
   * @param {'for_partners' | 'for_client_access'} [preset] Пресеты для типов взаимоотношений (for_partners, for_client_access)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantRelationshipTypes(
    preset?: 'for_partners' | 'for_client_access',
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantRelationshipTypesResponse>;

  /**
   * Словарь источников информации о контрагенте
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantSources(options?: AxiosRequestConfig): AxiosPromise<ParticipantSourcesResponse>;

  /**
   * Список значимых дат контрагента
   * @param {string} [participantCode]
   * @param {string} [customerCode]
   * @param {string} [contactCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantTimePoints(
    participantCode?: string,
    customerCode?: string,
    contactCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantTimePointsResponse>;

  /**
   * Отправить обновленную значимую дату контрагента или создать её
   * @param {ParticipantTimePointsSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantTimePointsSave(request: ParticipantTimePointsSaveRequest, options?: AxiosRequestConfig): AxiosPromise<ParticipantTimePoint>;

  /**
   *
   * @summary Получить историю изменений значимых дат
   * @param {string} [customerCode]
   * @param {string} [timePointCode]
   * @param {string} [participantCode]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApiInterface
   */
  participantTimePointsSaveGetHistory(
    customerCode?: string,
    timePointCode?: string,
    participantCode?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<ParticipantTimePointsSaveGetHistoryResponse>;
}

/**
 * ParticipantApi - object-oriented interface
 * @export
 * @class ParticipantApi
 * @extends {BaseAPI}
 */
export class ParticipantApi extends BaseAPI implements ParticipantApiInterface {
  /**
   *
   * @summary Список возможных хобби контактного лица (справочник)
   * @param {string} [query]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public contactHobbies(query?: string, page?: number, count?: number, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .contactHobbies(query, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создать запись в справочнике хобби контактного лица
   * @param {ContactHobby} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public contactHobbiesCreate(request: ContactHobby, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .contactHobbiesCreate(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список возможных должностей контактного лица (справочник)
   * @param {string} [query]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public contactPosts(query?: string, page?: number, count?: number, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .contactPosts(query, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Создать запись в справочнике должностей контактного лица
   * @param {ContactPost} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public contactPostsCreate(request: ContactPost, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .contactPostsCreate(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Список контактных лиц контрагента
   * @param {string} [customerCode]
   * @param {string} [participantCode]
   * @param {string} [phone] Фильтр по телефону
   * @param {boolean} [enrichCustomers] Загрузить данные о клиенте
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public contacts(
    customerCode?: string,
    participantCode?: string,
    phone?: string,
    enrichCustomers?: boolean,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return ParticipantApiFp(this.configuration)
      .contacts(customerCode, participantCode, phone, enrichCustomers, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить историю изменения контактных лиц
   * @param {string} [customerCode]
   * @param {string} [participantCode]
   * @param {string} [contactCode]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public contactsGetHistory(
    customerCode?: string,
    participantCode?: string,
    contactCode?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ) {
    return ParticipantApiFp(this.configuration)
      .contactsGetHistory(customerCode, participantCode, contactCode, from, to, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаляет контактное лицо
   * @param {string} code Код контактного лица
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public contactsItemDelete(code: string, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .contactsItemDelete(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Отправить обновления по контактному лицу или создать его
   * @summary Отправить обновленное контактное лицо
   * @param {ContactSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public contactsSave(request: ContactSaveRequest, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .contactsSave(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Формирование заявки на доступ к клиенту
   * @param {ParticipantAccessRequestCreateRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantAccessRequestCreate(request: ParticipantAccessRequestCreateRequest, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantAccessRequestCreate(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список заявок на доступ к клиенту
   * @param {string} [participantCode]
   * @param {string} [customerCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantAccessRequestList(participantCode?: string, customerCode?: string, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantAccessRequestList(participantCode, customerCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Отправить обновленный документ контрагента или создать его
   * @param {string} typeCode Код типа документа
   * @param {string} title Наименование документа
   * @param {string} [code] Код документа
   * @param {string} [description] Описание документа
   * @param {any} [file] Файл к загрузке
   * @param {string} [participantCode] Код контрагента
   * @param {string} [customerCode] Код клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantDocumentSave(
    typeCode: string,
    title: string,
    code?: string,
    description?: string,
    file?: any,
    participantCode?: string,
    customerCode?: string,
    options?: AxiosRequestConfig
  ) {
    return ParticipantApiFp(this.configuration)
      .participantDocumentSave(typeCode, title, code, description, file, participantCode, customerCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список типов документов контрагента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantDocumentTypes(options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantDocumentTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список документов контрагента
   * @param {string} [participantCode]
   * @param {string} [customerCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantDocuments(participantCode?: string, customerCode?: string, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantDocuments(participantCode, customerCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удаление документа контрагента
   * @param {string} docCode Код документа
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantDocumentsItemDelete(docCode: string, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantDocumentsItemDelete(docCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Получить список исторических взаимодействий контрагента
   * @param {string} [participantCode]
   * @param {string} [customerCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantHistoricalInteractions(participantCode?: string, customerCode?: string, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantHistoricalInteractions(participantCode, customerCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Типы результатов взаимодействия с контрагентом (справочник)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantInteractionResultTypes(options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantInteractionResultTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Типы взаимодействий с контрагентом (справочник)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantInteractionTypes(options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantInteractionTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Получить список взаимодействий контрагента
   * @param {string} [participantCode]
   * @param {string} [customerCode]
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantInteractions(
    participantCode?: string,
    customerCode?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return ParticipantApiFp(this.configuration)
      .participantInteractions(participantCode, customerCode, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить историю изменений взаимодействий
   * @param {string} [customerCode]
   * @param {string} [interactionCode]
   * @param {string} [participantCode]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantInteractionsGetHistory(
    customerCode?: string,
    interactionCode?: string,
    participantCode?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ) {
    return ParticipantApiFp(this.configuration)
      .participantInteractionsGetHistory(customerCode, interactionCode, participantCode, from, to, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Отправить обновленное взаимодействие с контрагентом или создать его
   * @param {ParticipantInteractionsSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantInteractionsSave(request: ParticipantInteractionsSaveRequest, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantInteractionsSave(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список значимых дат контрагента
   * @param {string} participantCode
   * @param {Array<string>} [typeCodes] Типы адресов участника
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantItemAddresses(participantCode: string, typeCodes?: Array<string>, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantItemAddresses(participantCode, typeCodes, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Удаление взаимоотношения контрагента с другими партнерами
   * @param {string} code Код взаимоотношения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantRelationshipPartnerDelete(code: string, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantRelationshipPartnerDelete(code, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Сохранение взаимоотношения контрагента с другими партнерами
   * @param {ParticipantRelationshipPartnerSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantRelationshipPartnerSave(request: ParticipantRelationshipPartnerSaveRequest, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantRelationshipPartnerSave(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Взаимоотношения контрагента с другими партнерами
   * @param {string} [customerCode]
   * @param {string} [participantCode]
   * @param {string} [partnerCustomerCode]
   * @param {string} [partnerParticipantCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantRelationshipPartners(
    customerCode?: string,
    participantCode?: string,
    partnerCustomerCode?: string,
    partnerParticipantCode?: string,
    options?: AxiosRequestConfig
  ) {
    return ParticipantApiFp(this.configuration)
      .participantRelationshipPartners(customerCode, participantCode, partnerCustomerCode, partnerParticipantCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Словарь типов взаимоотношений контрагента
   * @param {'for_partners' | 'for_client_access'} [preset] Пресеты для типов взаимоотношений (for_partners, for_client_access)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantRelationshipTypes(preset?: 'for_partners' | 'for_client_access', options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantRelationshipTypes(preset, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Словарь источников информации о контрагенте
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantSources(options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantSources(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список значимых дат контрагента
   * @param {string} [participantCode]
   * @param {string} [customerCode]
   * @param {string} [contactCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantTimePoints(participantCode?: string, customerCode?: string, contactCode?: string, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantTimePoints(participantCode, customerCode, contactCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Отправить обновленную значимую дату контрагента или создать её
   * @param {ParticipantTimePointsSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantTimePointsSave(request: ParticipantTimePointsSaveRequest, options?: AxiosRequestConfig) {
    return ParticipantApiFp(this.configuration)
      .participantTimePointsSave(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить историю изменений значимых дат
   * @param {string} [customerCode]
   * @param {string} [timePointCode]
   * @param {string} [participantCode]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ParticipantApi
   */
  public participantTimePointsSaveGetHistory(
    customerCode?: string,
    timePointCode?: string,
    participantCode?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ) {
    return ParticipantApiFp(this.configuration)
      .participantTimePointsSaveGetHistory(customerCode, timePointCode, participantCode, from, to, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Списание долга
     * @param {DebtReliefRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    debtRelief: async (request: DebtReliefRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('debtRelief', 'request', request);
      const localVarPath = `/debt/relief`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Цепочка документов к списанию долга
     * @param {DebtReliefThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    debtReliefThread: async (request: DebtReliefThreadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('debtReliefThread', 'request', request);
      const localVarPath = `/debt/relief/thread`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Справочник статусов оплаты по счету
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentStates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/payment/states`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Цепочка документов по оплате
     * @param {PaymentThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentThread: async (request: PaymentThreadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('paymentThread', 'request', request);
      const localVarPath = `/payment/thread`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список оплат
     * @param {PaymentTransactionsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentTransactions: async (request: PaymentTransactionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('paymentTransactions', 'request', request);
      const localVarPath = `/payment/transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Доступны типы и схемы оплаты. Зависит от офиса.
     * @param {string} [deal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentTypes: async (deal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/payment/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deal !== undefined) {
        localVarQueryParameter['deal'] = deal;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration);
  return {
    /**
     * Списание долга
     * @param {DebtReliefRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async debtRelief(
      request: DebtReliefRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DebtReliefResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.debtRelief(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Цепочка документов к списанию долга
     * @param {DebtReliefThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async debtReliefThread(
      request: DebtReliefThreadRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DebtReliefThreadResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.debtReliefThread(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Справочник статусов оплаты по счету
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentStates(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentStatesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentStates(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Цепочка документов по оплате
     * @param {PaymentThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentThread(
      request: PaymentThreadRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentThreadResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentThread(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список оплат
     * @param {PaymentTransactionsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentTransactions(
      request: PaymentTransactionsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentTransactionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentTransactions(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Доступны типы и схемы оплаты. Зависит от офиса.
     * @param {string} [deal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentTypes(
      deal?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentTypes(deal, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PaymentApiFp(configuration);
  return {
    /**
     * Списание долга
     * @param {DebtReliefRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    debtRelief(request: DebtReliefRequest, options?: any): AxiosPromise<DebtReliefResponse> {
      return localVarFp.debtRelief(request, options).then(request => request(axios, basePath));
    },
    /**
     * Цепочка документов к списанию долга
     * @param {DebtReliefThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    debtReliefThread(request: DebtReliefThreadRequest, options?: any): AxiosPromise<DebtReliefThreadResponse> {
      return localVarFp.debtReliefThread(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Справочник статусов оплаты по счету
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentStates(options?: any): AxiosPromise<PaymentStatesResponse> {
      return localVarFp.paymentStates(options).then(request => request(axios, basePath));
    },
    /**
     * Цепочка документов по оплате
     * @param {PaymentThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentThread(request: PaymentThreadRequest, options?: any): AxiosPromise<PaymentThreadResponse> {
      return localVarFp.paymentThread(request, options).then(request => request(axios, basePath));
    },
    /**
     * Список оплат
     * @param {PaymentTransactionsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentTransactions(request: PaymentTransactionsRequest, options?: any): AxiosPromise<PaymentTransactionsResponse> {
      return localVarFp.paymentTransactions(request, options).then(request => request(axios, basePath));
    },
    /**
     * Доступны типы и схемы оплаты. Зависит от офиса.
     * @param {string} [deal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentTypes(deal?: string, options?: any): AxiosPromise<PaymentTypesResponse> {
      return localVarFp.paymentTypes(deal, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * PaymentApi - interface
 * @export
 * @interface PaymentApi
 */
export interface PaymentApiInterface {
  /**
   * Списание долга
   * @param {DebtReliefRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiInterface
   */
  debtRelief(request: DebtReliefRequest, options?: AxiosRequestConfig): AxiosPromise<DebtReliefResponse>;

  /**
   * Цепочка документов к списанию долга
   * @param {DebtReliefThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiInterface
   */
  debtReliefThread(request: DebtReliefThreadRequest, options?: AxiosRequestConfig): AxiosPromise<DebtReliefThreadResponse>;

  /**
   *
   * @summary Справочник статусов оплаты по счету
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiInterface
   */
  paymentStates(options?: AxiosRequestConfig): AxiosPromise<PaymentStatesResponse>;

  /**
   * Цепочка документов по оплате
   * @param {PaymentThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiInterface
   */
  paymentThread(request: PaymentThreadRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentThreadResponse>;

  /**
   * Список оплат
   * @param {PaymentTransactionsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiInterface
   */
  paymentTransactions(request: PaymentTransactionsRequest, options?: AxiosRequestConfig): AxiosPromise<PaymentTransactionsResponse>;

  /**
   * Доступны типы и схемы оплаты. Зависит от офиса.
   * @param {string} [deal]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApiInterface
   */
  paymentTypes(deal?: string, options?: AxiosRequestConfig): AxiosPromise<PaymentTypesResponse>;
}

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI implements PaymentApiInterface {
  /**
   * Списание долга
   * @param {DebtReliefRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public debtRelief(request: DebtReliefRequest, options?: AxiosRequestConfig) {
    return PaymentApiFp(this.configuration)
      .debtRelief(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Цепочка документов к списанию долга
   * @param {DebtReliefThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public debtReliefThread(request: DebtReliefThreadRequest, options?: AxiosRequestConfig) {
    return PaymentApiFp(this.configuration)
      .debtReliefThread(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Справочник статусов оплаты по счету
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public paymentStates(options?: AxiosRequestConfig) {
    return PaymentApiFp(this.configuration)
      .paymentStates(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Цепочка документов по оплате
   * @param {PaymentThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public paymentThread(request: PaymentThreadRequest, options?: AxiosRequestConfig) {
    return PaymentApiFp(this.configuration)
      .paymentThread(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список оплат
   * @param {PaymentTransactionsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public paymentTransactions(request: PaymentTransactionsRequest, options?: AxiosRequestConfig) {
    return PaymentApiFp(this.configuration)
      .paymentTransactions(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Доступны типы и схемы оплаты. Зависит от офиса.
   * @param {string} [deal]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentApi
   */
  public paymentTypes(deal?: string, options?: AxiosRequestConfig) {
    return PaymentApiFp(this.configuration)
      .paymentTypes(deal, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PlanApi - axios parameter creator
 * @export
 */
export const PlanApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получить историю изменения плана клиентов
     * @param {GetSalePlanCustomersHistoryRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalePlanCustomersHistory: async (
      request: GetSalePlanCustomersHistoryRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('getSalePlanCustomersHistory', 'request', request);
      const localVarPath = `/salePlan/customer/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Подтвердить планы продаж сотрудника на месяц
     * @param {EmployeeSalePlanApproveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salePlanEmployeeApprove: async (request: EmployeeSalePlanApproveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('salePlanEmployeeApprove', 'request', request);
      const localVarPath = `/salePlan/employee/approve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить статус планов продаж сотрудника на месяц
     * @param {string} month
     * @param {string} [employeeCode]
     * @param {string} [departmentCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salePlanEmployeeStatus: async (
      month: string,
      employeeCode?: string,
      departmentCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'month' is not null or undefined
      assertParamExists('salePlanEmployeeStatus', 'month', month);
      const localVarPath = `/salePlan/employee/status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (month !== undefined) {
        localVarQueryParameter['month'] = (month as any) instanceof Date ? (month as any).toISOString().substr(0, 10) : month;
      }

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlanApi - functional programming interface
 * @export
 */
export const PlanApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlanApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Получить историю изменения плана клиентов
     * @param {GetSalePlanCustomersHistoryRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSalePlanCustomersHistory(
      request: GetSalePlanCustomersHistoryRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSalePlanCustomersHistoryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSalePlanCustomersHistory(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Подтвердить планы продаж сотрудника на месяц
     * @param {EmployeeSalePlanApproveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salePlanEmployeeApprove(
      request: EmployeeSalePlanApproveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salePlanEmployeeApprove(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Получить статус планов продаж сотрудника на месяц
     * @param {string} month
     * @param {string} [employeeCode]
     * @param {string} [departmentCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salePlanEmployeeStatus(
      month: string,
      employeeCode?: string,
      departmentCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeSalePlanStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salePlanEmployeeStatus(month, employeeCode, departmentCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PlanApi - factory interface
 * @export
 */
export const PlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PlanApiFp(configuration);
  return {
    /**
     *
     * @summary Получить историю изменения плана клиентов
     * @param {GetSalePlanCustomersHistoryRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSalePlanCustomersHistory(
      request: GetSalePlanCustomersHistoryRequest,
      options?: any
    ): AxiosPromise<GetSalePlanCustomersHistoryResponse> {
      return localVarFp.getSalePlanCustomersHistory(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Подтвердить планы продаж сотрудника на месяц
     * @param {EmployeeSalePlanApproveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salePlanEmployeeApprove(request: EmployeeSalePlanApproveRequest, options?: any): AxiosPromise<void> {
      return localVarFp.salePlanEmployeeApprove(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Получить статус планов продаж сотрудника на месяц
     * @param {string} month
     * @param {string} [employeeCode]
     * @param {string} [departmentCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salePlanEmployeeStatus(
      month: string,
      employeeCode?: string,
      departmentCode?: string,
      options?: any
    ): AxiosPromise<EmployeeSalePlanStatus> {
      return localVarFp.salePlanEmployeeStatus(month, employeeCode, departmentCode, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * PlanApi - interface
 * @export
 * @interface PlanApi
 */
export interface PlanApiInterface {
  /**
   *
   * @summary Получить историю изменения плана клиентов
   * @param {GetSalePlanCustomersHistoryRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanApiInterface
   */
  getSalePlanCustomersHistory(
    request: GetSalePlanCustomersHistoryRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<GetSalePlanCustomersHistoryResponse>;

  /**
   *
   * @summary Подтвердить планы продаж сотрудника на месяц
   * @param {EmployeeSalePlanApproveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanApiInterface
   */
  salePlanEmployeeApprove(request: EmployeeSalePlanApproveRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   *
   * @summary Получить статус планов продаж сотрудника на месяц
   * @param {string} month
   * @param {string} [employeeCode]
   * @param {string} [departmentCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanApiInterface
   */
  salePlanEmployeeStatus(
    month: string,
    employeeCode?: string,
    departmentCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<EmployeeSalePlanStatus>;
}

/**
 * PlanApi - object-oriented interface
 * @export
 * @class PlanApi
 * @extends {BaseAPI}
 */
export class PlanApi extends BaseAPI implements PlanApiInterface {
  /**
   *
   * @summary Получить историю изменения плана клиентов
   * @param {GetSalePlanCustomersHistoryRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanApi
   */
  public getSalePlanCustomersHistory(request: GetSalePlanCustomersHistoryRequest, options?: AxiosRequestConfig) {
    return PlanApiFp(this.configuration)
      .getSalePlanCustomersHistory(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Подтвердить планы продаж сотрудника на месяц
   * @param {EmployeeSalePlanApproveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanApi
   */
  public salePlanEmployeeApprove(request: EmployeeSalePlanApproveRequest, options?: AxiosRequestConfig) {
    return PlanApiFp(this.configuration)
      .salePlanEmployeeApprove(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить статус планов продаж сотрудника на месяц
   * @param {string} month
   * @param {string} [employeeCode]
   * @param {string} [departmentCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanApi
   */
  public salePlanEmployeeStatus(month: string, employeeCode?: string, departmentCode?: string, options?: AxiosRequestConfig) {
    return PlanApiFp(this.configuration)
      .salePlanEmployeeStatus(month, employeeCode, departmentCode, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * RefusalApi - axios parameter creator
 * @export
 */
export const RefusalApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Список отказов
     * @param {RefusalsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refusals: async (request: RefusalsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('refusals', 'request', request);
      const localVarPath = `/refusals`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RefusalApi - functional programming interface
 * @export
 */
export const RefusalApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RefusalApiAxiosParamCreator(configuration);
  return {
    /**
     * Список отказов
     * @param {RefusalsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refusals(
      request: RefusalsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefusalsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refusals(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * RefusalApi - factory interface
 * @export
 */
export const RefusalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = RefusalApiFp(configuration);
  return {
    /**
     * Список отказов
     * @param {RefusalsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refusals(request: RefusalsRequest, options?: any): AxiosPromise<RefusalsResponse> {
      return localVarFp.refusals(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * RefusalApi - interface
 * @export
 * @interface RefusalApi
 */
export interface RefusalApiInterface {
  /**
   * Список отказов
   * @param {RefusalsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RefusalApiInterface
   */
  refusals(request: RefusalsRequest, options?: AxiosRequestConfig): AxiosPromise<RefusalsResponse>;
}

/**
 * RefusalApi - object-oriented interface
 * @export
 * @class RefusalApi
 * @extends {BaseAPI}
 */
export class RefusalApi extends BaseAPI implements RefusalApiInterface {
  /**
   * Список отказов
   * @param {RefusalsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RefusalApi
   */
  public refusals(request: RefusalsRequest, options?: AxiosRequestConfig) {
    return RefusalApiFp(this.configuration)
      .refusals(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SalesinvoiceApi - axios parameter creator
 * @export
 */
export const SalesinvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Список расходных накладных
     * @param {SalesInvoicesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesInvoices: async (request: SalesInvoicesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('salesInvoices', 'request', request);
      const localVarPath = `/salesInvoices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SalesinvoiceApi - functional programming interface
 * @export
 */
export const SalesinvoiceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SalesinvoiceApiAxiosParamCreator(configuration);
  return {
    /**
     * Список расходных накладных
     * @param {SalesInvoicesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async salesInvoices(
      request: SalesInvoicesRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesInvoicesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.salesInvoices(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SalesinvoiceApi - factory interface
 * @export
 */
export const SalesinvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SalesinvoiceApiFp(configuration);
  return {
    /**
     * Список расходных накладных
     * @param {SalesInvoicesRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    salesInvoices(request: SalesInvoicesRequest, options?: any): AxiosPromise<SalesInvoicesResponse> {
      return localVarFp.salesInvoices(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SalesinvoiceApi - interface
 * @export
 * @interface SalesinvoiceApi
 */
export interface SalesinvoiceApiInterface {
  /**
   * Список расходных накладных
   * @param {SalesInvoicesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesinvoiceApiInterface
   */
  salesInvoices(request: SalesInvoicesRequest, options?: AxiosRequestConfig): AxiosPromise<SalesInvoicesResponse>;
}

/**
 * SalesinvoiceApi - object-oriented interface
 * @export
 * @class SalesinvoiceApi
 * @extends {BaseAPI}
 */
export class SalesinvoiceApi extends BaseAPI implements SalesinvoiceApiInterface {
  /**
   * Список расходных накладных
   * @param {SalesInvoicesRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SalesinvoiceApi
   */
  public salesInvoices(request: SalesInvoicesRequest, options?: AxiosRequestConfig) {
    return SalesinvoiceApiFp(this.configuration)
      .salesInvoices(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Фильтры товаров для поиска
     * @param {string} [code] Код товара
     * @param {string} [query] Строка для поиска товара
     * @param {string} [category] Раздел каталога
     * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsSearchFilter: async (
      code?: string,
      query?: string,
      category?: string,
      filters?: string,
      manufacturer?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      branchOfficeCode?: string,
      productType?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/catalog/products/search/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (query !== undefined) {
        localVarQueryParameter['query'] = query;
      }

      if (category !== undefined) {
        localVarQueryParameter['category'] = category;
      }

      if (filters !== undefined) {
        localVarQueryParameter['filters'] = filters;
      }

      if (manufacturer !== undefined) {
        localVarQueryParameter['manufacturer'] = manufacturer;
      }

      if (baseSize !== undefined) {
        localVarQueryParameter['baseSize'] = baseSize;
      }

      if (warehouse !== undefined) {
        localVarQueryParameter['warehouse'] = warehouse;
      }

      if (stockRequired !== undefined) {
        localVarQueryParameter['stockRequired'] = stockRequired;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (productType !== undefined) {
        localVarQueryParameter['productType'] = productType;
      }

      if (searchMarker !== undefined) {
        localVarQueryParameter['searchMarker'] = searchMarker;
      }

      if (dealCode !== undefined) {
        localVarQueryParameter['dealCode'] = dealCode;
      }

      if (agreementCode !== undefined) {
        localVarQueryParameter['agreementCode'] = agreementCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Поиск по сайту
     * @param {SearchRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search: async (request: SearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('search', 'request', request);
      const localVarPath = `/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Фильтры товаров для поиска
     * @param {string} [code] Код товара
     * @param {string} [query] Строка для поиска товара
     * @param {string} [category] Раздел каталога
     * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async catalogProductsSearchFilter(
      code?: string,
      query?: string,
      category?: string,
      filters?: string,
      manufacturer?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      branchOfficeCode?: string,
      productType?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatalogProductsSearchFilterResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.catalogProductsSearchFilter(
        code,
        query,
        category,
        filters,
        manufacturer,
        baseSize,
        warehouse,
        stockRequired,
        branchOfficeCode,
        productType,
        searchMarker,
        dealCode,
        agreementCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Поиск по сайту
     * @param {SearchRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async search(
      request: SearchRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.search(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SearchApiFp(configuration);
  return {
    /**
     *
     * @summary Фильтры товаров для поиска
     * @param {string} [code] Код товара
     * @param {string} [query] Строка для поиска товара
     * @param {string} [category] Раздел каталога
     * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
     * @param {string} [manufacturer] Фильтр по производителю
     * @param {string} [baseSize] Фильтр по базовому размеру
     * @param {string} [warehouse] Склад отгрузки
     * @param {string} [stockRequired] Только с остатками
     * @param {string} [branchOfficeCode] Код филиала для подключения цен
     * @param {string} [productType] Фильтр по виду товара
     * @param {string} [searchMarker]
     * @param {string} [dealCode] Код сделки
     * @param {string} [agreementCode] Код соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    catalogProductsSearchFilter(
      code?: string,
      query?: string,
      category?: string,
      filters?: string,
      manufacturer?: string,
      baseSize?: string,
      warehouse?: string,
      stockRequired?: string,
      branchOfficeCode?: string,
      productType?: string,
      searchMarker?: string,
      dealCode?: string,
      agreementCode?: string,
      options?: any
    ): AxiosPromise<CatalogProductsSearchFilterResponse> {
      return localVarFp
        .catalogProductsSearchFilter(
          code,
          query,
          category,
          filters,
          manufacturer,
          baseSize,
          warehouse,
          stockRequired,
          branchOfficeCode,
          productType,
          searchMarker,
          dealCode,
          agreementCode,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Поиск по сайту
     * @param {SearchRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    search(request: SearchRequest, options?: any): AxiosPromise<SearchResponse> {
      return localVarFp.search(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SearchApi - interface
 * @export
 * @interface SearchApi
 */
export interface SearchApiInterface {
  /**
   *
   * @summary Фильтры товаров для поиска
   * @param {string} [code] Код товара
   * @param {string} [query] Строка для поиска товара
   * @param {string} [category] Раздел каталога
   * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
   * @param {string} [manufacturer] Фильтр по производителю
   * @param {string} [baseSize] Фильтр по базовому размеру
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [stockRequired] Только с остатками
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [productType] Фильтр по виду товара
   * @param {string} [searchMarker]
   * @param {string} [dealCode] Код сделки
   * @param {string} [agreementCode] Код соглашения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApiInterface
   */
  catalogProductsSearchFilter(
    code?: string,
    query?: string,
    category?: string,
    filters?: string,
    manufacturer?: string,
    baseSize?: string,
    warehouse?: string,
    stockRequired?: string,
    branchOfficeCode?: string,
    productType?: string,
    searchMarker?: string,
    dealCode?: string,
    agreementCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<CatalogProductsSearchFilterResponse>;

  /**
   *
   * @summary Поиск по сайту
   * @param {SearchRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApiInterface
   */
  search(request: SearchRequest, options?: AxiosRequestConfig): AxiosPromise<SearchResponse>;
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI implements SearchApiInterface {
  /**
   *
   * @summary Фильтры товаров для поиска
   * @param {string} [code] Код товара
   * @param {string} [query] Строка для поиска товара
   * @param {string} [category] Раздел каталога
   * @param {string} [filters] Условия фильтрации для фасетного поиска. Условия разделяются символом \&quot;;\&quot;, значения \&quot;,\&quot;, диапазон \&quot;-\&quot;. Общий вид: \&quot;property1:value1\&quot; Пример: \&quot;price:100:200;brand:mkm;length:10,12,15\&quot;
   * @param {string} [manufacturer] Фильтр по производителю
   * @param {string} [baseSize] Фильтр по базовому размеру
   * @param {string} [warehouse] Склад отгрузки
   * @param {string} [stockRequired] Только с остатками
   * @param {string} [branchOfficeCode] Код филиала для подключения цен
   * @param {string} [productType] Фильтр по виду товара
   * @param {string} [searchMarker]
   * @param {string} [dealCode] Код сделки
   * @param {string} [agreementCode] Код соглашения
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public catalogProductsSearchFilter(
    code?: string,
    query?: string,
    category?: string,
    filters?: string,
    manufacturer?: string,
    baseSize?: string,
    warehouse?: string,
    stockRequired?: string,
    branchOfficeCode?: string,
    productType?: string,
    searchMarker?: string,
    dealCode?: string,
    agreementCode?: string,
    options?: AxiosRequestConfig
  ) {
    return SearchApiFp(this.configuration)
      .catalogProductsSearchFilter(
        code,
        query,
        category,
        filters,
        manufacturer,
        baseSize,
        warehouse,
        stockRequired,
        branchOfficeCode,
        productType,
        searchMarker,
        dealCode,
        agreementCode,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Поиск по сайту
   * @param {SearchRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public search(request: SearchRequest, options?: AxiosRequestConfig) {
    return SearchApiFp(this.configuration)
      .search(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ShipmentApi - axios parameter creator
 * @export
 */
export const ShipmentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Список отгрузок
     * @param {ShipmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipments: async (request: ShipmentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('shipments', 'request', request);
      const localVarPath = `/shipments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Удаление комментария отгрузки
     * @param {string} commentCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsCommentItemDelete: async (commentCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'commentCode' is not null or undefined
      assertParamExists('shipmentsCommentItemDelete', 'commentCode', commentCode);
      const localVarPath = `/shipments/comments/{commentCode}/delete`.replace(
        `{${'commentCode'}}`,
        encodeURIComponent(String(commentCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список типов комментариев отгрузок
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsCommentTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/shipments/comment/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Обновление комментариев отгрузки
     * @param {string} shipmentCode
     * @param {ShipmentsItemUpdateCommentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsItemUpdateComments: async (
      shipmentCode: string,
      request: ShipmentsItemUpdateCommentsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'shipmentCode' is not null or undefined
      assertParamExists('shipmentsItemUpdateComments', 'shipmentCode', shipmentCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('shipmentsItemUpdateComments', 'request', request);
      const localVarPath = `/shipments/{shipmentCode}/update/comments`.replace(
        `{${'shipmentCode'}}`,
        encodeURIComponent(String(shipmentCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список типов отгрузок
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/shipments/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ShipmentApi - functional programming interface
 * @export
 */
export const ShipmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ShipmentApiAxiosParamCreator(configuration);
  return {
    /**
     * Список отгрузок
     * @param {ShipmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shipments(
      request: ShipmentsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shipments(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Удаление комментария отгрузки
     * @param {string} commentCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shipmentsCommentItemDelete(
      commentCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentsCommentItemDelete(commentCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список типов комментариев отгрузок
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shipmentsCommentTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentsCommentTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentsCommentTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Обновление комментариев отгрузки
     * @param {string} shipmentCode
     * @param {ShipmentsItemUpdateCommentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shipmentsItemUpdateComments(
      shipmentCode: string,
      request: ShipmentsItemUpdateCommentsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentsItemUpdateComments(shipmentCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список типов отгрузок
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shipmentsTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentsTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentsTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ShipmentApi - factory interface
 * @export
 */
export const ShipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ShipmentApiFp(configuration);
  return {
    /**
     * Список отгрузок
     * @param {ShipmentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipments(request: ShipmentsRequest, options?: any): AxiosPromise<ShipmentsResponse> {
      return localVarFp.shipments(request, options).then(request => request(axios, basePath));
    },
    /**
     * Удаление комментария отгрузки
     * @param {string} commentCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsCommentItemDelete(commentCode: string, options?: any): AxiosPromise<void> {
      return localVarFp.shipmentsCommentItemDelete(commentCode, options).then(request => request(axios, basePath));
    },
    /**
     * Список типов комментариев отгрузок
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsCommentTypes(options?: any): AxiosPromise<ShipmentsCommentTypesResponse> {
      return localVarFp.shipmentsCommentTypes(options).then(request => request(axios, basePath));
    },
    /**
     * Обновление комментариев отгрузки
     * @param {string} shipmentCode
     * @param {ShipmentsItemUpdateCommentsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsItemUpdateComments(shipmentCode: string, request: ShipmentsItemUpdateCommentsRequest, options?: any): AxiosPromise<void> {
      return localVarFp.shipmentsItemUpdateComments(shipmentCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Список типов отгрузок
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentsTypes(options?: any): AxiosPromise<ShipmentsTypesResponse> {
      return localVarFp.shipmentsTypes(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ShipmentApi - interface
 * @export
 * @interface ShipmentApi
 */
export interface ShipmentApiInterface {
  /**
   * Список отгрузок
   * @param {ShipmentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentApiInterface
   */
  shipments(request: ShipmentsRequest, options?: AxiosRequestConfig): AxiosPromise<ShipmentsResponse>;

  /**
   * Удаление комментария отгрузки
   * @param {string} commentCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentApiInterface
   */
  shipmentsCommentItemDelete(commentCode: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Список типов комментариев отгрузок
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentApiInterface
   */
  shipmentsCommentTypes(options?: AxiosRequestConfig): AxiosPromise<ShipmentsCommentTypesResponse>;

  /**
   * Обновление комментариев отгрузки
   * @param {string} shipmentCode
   * @param {ShipmentsItemUpdateCommentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentApiInterface
   */
  shipmentsItemUpdateComments(
    shipmentCode: string,
    request: ShipmentsItemUpdateCommentsRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;

  /**
   * Список типов отгрузок
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentApiInterface
   */
  shipmentsTypes(options?: AxiosRequestConfig): AxiosPromise<ShipmentsTypesResponse>;
}

/**
 * ShipmentApi - object-oriented interface
 * @export
 * @class ShipmentApi
 * @extends {BaseAPI}
 */
export class ShipmentApi extends BaseAPI implements ShipmentApiInterface {
  /**
   * Список отгрузок
   * @param {ShipmentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentApi
   */
  public shipments(request: ShipmentsRequest, options?: AxiosRequestConfig) {
    return ShipmentApiFp(this.configuration)
      .shipments(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Удаление комментария отгрузки
   * @param {string} commentCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentApi
   */
  public shipmentsCommentItemDelete(commentCode: string, options?: AxiosRequestConfig) {
    return ShipmentApiFp(this.configuration)
      .shipmentsCommentItemDelete(commentCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список типов комментариев отгрузок
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentApi
   */
  public shipmentsCommentTypes(options?: AxiosRequestConfig) {
    return ShipmentApiFp(this.configuration)
      .shipmentsCommentTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Обновление комментариев отгрузки
   * @param {string} shipmentCode
   * @param {ShipmentsItemUpdateCommentsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentApi
   */
  public shipmentsItemUpdateComments(shipmentCode: string, request: ShipmentsItemUpdateCommentsRequest, options?: AxiosRequestConfig) {
    return ShipmentApiFp(this.configuration)
      .shipmentsItemUpdateComments(shipmentCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список типов отгрузок
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentApi
   */
  public shipmentsTypes(options?: AxiosRequestConfig) {
    return ShipmentApiFp(this.configuration)
      .shipmentsTypes(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ShipmentreturnApi - axios parameter creator
 * @export
 */
export const ShipmentreturnApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Список возвратов по отгрузкам
     * @param {ShipmentReturnsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentReturns: async (request: ShipmentReturnsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('shipmentReturns', 'request', request);
      const localVarPath = `/shipmentReturns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Цепочка документов по возврату
     * @param {ShipmentReturnsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentReturnsThread: async (request: ShipmentReturnsThreadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('shipmentReturnsThread', 'request', request);
      const localVarPath = `/shipmentReturns/thread`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ShipmentreturnApi - functional programming interface
 * @export
 */
export const ShipmentreturnApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ShipmentreturnApiAxiosParamCreator(configuration);
  return {
    /**
     * Список возвратов по отгрузкам
     * @param {ShipmentReturnsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shipmentReturns(
      request: ShipmentReturnsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentReturnsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentReturns(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Цепочка документов по возврату
     * @param {ShipmentReturnsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shipmentReturnsThread(
      request: ShipmentReturnsThreadRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipmentReturnsThreadResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.shipmentReturnsThread(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ShipmentreturnApi - factory interface
 * @export
 */
export const ShipmentreturnApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ShipmentreturnApiFp(configuration);
  return {
    /**
     * Список возвратов по отгрузкам
     * @param {ShipmentReturnsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentReturns(request: ShipmentReturnsRequest, options?: any): AxiosPromise<ShipmentReturnsResponse> {
      return localVarFp.shipmentReturns(request, options).then(request => request(axios, basePath));
    },
    /**
     * Цепочка документов по возврату
     * @param {ShipmentReturnsThreadRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shipmentReturnsThread(request: ShipmentReturnsThreadRequest, options?: any): AxiosPromise<ShipmentReturnsThreadResponse> {
      return localVarFp.shipmentReturnsThread(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ShipmentreturnApi - interface
 * @export
 * @interface ShipmentreturnApi
 */
export interface ShipmentreturnApiInterface {
  /**
   * Список возвратов по отгрузкам
   * @param {ShipmentReturnsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentreturnApiInterface
   */
  shipmentReturns(request: ShipmentReturnsRequest, options?: AxiosRequestConfig): AxiosPromise<ShipmentReturnsResponse>;

  /**
   * Цепочка документов по возврату
   * @param {ShipmentReturnsThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentreturnApiInterface
   */
  shipmentReturnsThread(request: ShipmentReturnsThreadRequest, options?: AxiosRequestConfig): AxiosPromise<ShipmentReturnsThreadResponse>;
}

/**
 * ShipmentreturnApi - object-oriented interface
 * @export
 * @class ShipmentreturnApi
 * @extends {BaseAPI}
 */
export class ShipmentreturnApi extends BaseAPI implements ShipmentreturnApiInterface {
  /**
   * Список возвратов по отгрузкам
   * @param {ShipmentReturnsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentreturnApi
   */
  public shipmentReturns(request: ShipmentReturnsRequest, options?: AxiosRequestConfig) {
    return ShipmentreturnApiFp(this.configuration)
      .shipmentReturns(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Цепочка документов по возврату
   * @param {ShipmentReturnsThreadRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShipmentreturnApi
   */
  public shipmentReturnsThread(request: ShipmentReturnsThreadRequest, options?: AxiosRequestConfig) {
    return ShipmentreturnApiFp(this.configuration)
      .shipmentReturnsThread(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * StatApi - axios parameter creator
 * @export
 */
export const StatApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Статистика соглашений по клиентам.
     * @param {string} [customerCode]
     * @param {string} [employeeCode] Код реализатора
     * @param {string} [departmentCode] Код отдела
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statAgreementsCustomers: async (
      customerCode?: string,
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/agreements/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика соглашений по дивизионам
     * @param {string} [divisionCode] Код дивизиона
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statAgreementsDivisions: async (divisionCode?: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/stat/agreements/divisions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика маржинальности по группе компаний
     * @param {string} [headCompanyCode] Код головной компании
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statAgreementsHeadCompanies: async (
      headCompanyCode?: string,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/agreements/head/companies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (headCompanyCode !== undefined) {
        localVarQueryParameter['headCompanyCode'] = headCompanyCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика маржинальности по офисам
     * @param {string} [branchOfficeCode] Код офиса
     * @param {string} [date] Дата отчета
     * @param {string} [divisionCode] Код дивизиона
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statAgreementsOffices: async (
      branchOfficeCode?: string,
      date?: string,
      divisionCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/agreements/offices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Справочники для отображения страницы статистики по соглашениям
     * @param {string} [employeeCode] Код выбранного сотрудника
     * @param {string} [departmentCode] Код выбранного отдела
     * @param {string} [branchOfficeCode] Код выбранного офиса
     * @param {string} [divisionCode] Код выбранного отдела
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statAgreementsReferences: async (
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      divisionCode?: string,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/agreements/references`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика маржинальности по клиентам. Используется на странице показателей
     * @param {string} [customerCode]
     * @param {string} [employeeCode] Код реализатора
     * @param {string} [departmentCode] Код отдела
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityCustomers: async (
      customerCode?: string,
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/marginality/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика маржинальности по отделам
     * @param {string} [departmentCode]
     * @param {string} [date] Дата отчета
     * @param {string} [branchOfficeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityDepartments: async (
      departmentCode?: string,
      date?: string,
      branchOfficeCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/marginality/departments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика маржинальности по дивизионам
     * @param {string} [divisionCode] Код дивизиона
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityDivisions: async (divisionCode?: string, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/stat/marginality/divisions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика маржинальности по клиентам для менеджера
     * @param {string} [employeeCode] Код реализатора
     * @param {string} [customerCode]
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityEmployeeCustomers: async (
      employeeCode?: string,
      customerCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/marginality/employee/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Рейтинг сотрудников на основе общего показателя выполнения плана продаж
     * @param {string} [branchOfficeCode] Код офиса
     * @param {string} [date] Дата отчёта
     * @param {boolean} [allCompany] Запросить рейтинг по всей компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityEmployeeOverallRating: async (
      branchOfficeCode?: string,
      date?: string,
      allCompany?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/marginality/employees/overallRating`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (allCompany !== undefined) {
        localVarQueryParameter['allCompany'] = allCompany;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика маржинальности по сотрудникам
     * @param {string} [employeeCode]
     * @param {string} [branchOfficeCode]
     * @param {string} [date] Дата отчета
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityEmployees: async (
      employeeCode?: string,
      branchOfficeCode?: string,
      date?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/marginality/employees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (chiefEmployeeCode !== undefined) {
        localVarQueryParameter['chiefEmployeeCode'] = chiefEmployeeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика маржинальности по группе компаний
     * @param {string} [headCompanyCode] Код головной компании
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityHeadCompanies: async (
      headCompanyCode?: string,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/marginality/head/companies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (headCompanyCode !== undefined) {
        localVarQueryParameter['headCompanyCode'] = headCompanyCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика маржинальности по офисам
     * @param {string} [branchOfficeCode] Код офиса
     * @param {string} [date] Дата отчета
     * @param {string} [divisionCode] Код дивизиона
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityOffices: async (
      branchOfficeCode?: string,
      date?: string,
      divisionCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/marginality/offices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Справочники для отображения страницы показателей маржинальности
     * @param {string} [employeeCode] Код выбранного сотрудника
     * @param {string} [departmentCode] Код выбранного отдела
     * @param {string} [branchOfficeCode] Код выбранного офиса
     * @param {string} [divisionCode] Код выбранного отдела
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityReferences: async (
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      divisionCode?: string,
      date?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/marginality/references`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Мотивация по клиентам
     * @param {string} date Период отчета
     * @param {string} [customerCode]
     * @param {string} [departmentCode] Код департамента
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationCustomers: async (
      date: string,
      customerCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'date' is not null or undefined
      assertParamExists('statMotivationCustomers', 'date', date);
      const localVarPath = `/stat/motivation/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Мотивация по департаментам
     * @param {string} date Дата отчета
     * @param {string} [branchOfficeCode]
     * @param {string} [departmentCode] Код департамента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationDepartments: async (
      date: string,
      branchOfficeCode?: string,
      departmentCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'date' is not null or undefined
      assertParamExists('statMotivationDepartments', 'date', date);
      const localVarPath = `/stat/motivation/departments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Мотивация по дивизионам
     * @param {string} divisionCode Код дивизиона
     * @param {string} date Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationDivisions: async (divisionCode: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'divisionCode' is not null or undefined
      assertParamExists('statMotivationDivisions', 'divisionCode', divisionCode);
      // verify required parameter 'date' is not null or undefined
      assertParamExists('statMotivationDivisions', 'date', date);
      const localVarPath = `/stat/motivation/divisions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Мотивация по сотрудникам
     * @param {string} date Дата отчета
     * @param {string} [departmentCode] Код департамента
     * @param {string} [employeeCode] Код сотрудника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationEmployees: async (
      date: string,
      departmentCode?: string,
      employeeCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'date' is not null or undefined
      assertParamExists('statMotivationEmployees', 'date', date);
      const localVarPath = `/stat/motivation/employees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Мотивация по клиентам сотрудника
     * @param {string} date Дата отчета
     * @param {string} [customerCode]
     * @param {string} [departmentCode] Код департамента
     * @param {string} [employeeCode] Код сотрудника
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationEmployeesCustomers: async (
      date: string,
      customerCode?: string,
      departmentCode?: string,
      employeeCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'date' is not null or undefined
      assertParamExists('statMotivationEmployeesCustomers', 'date', date);
      const localVarPath = `/stat/motivation/employees/customers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Мотивация по компании
     * @param {string} headCompanyCode Код головной компании
     * @param {string} date Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationHeadCompanies: async (headCompanyCode: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'headCompanyCode' is not null or undefined
      assertParamExists('statMotivationHeadCompanies', 'headCompanyCode', headCompanyCode);
      // verify required parameter 'date' is not null or undefined
      assertParamExists('statMotivationHeadCompanies', 'date', date);
      const localVarPath = `/stat/motivation/head/companies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (headCompanyCode !== undefined) {
        localVarQueryParameter['headCompanyCode'] = headCompanyCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Мотивация по филиалам
     * @param {string} date Дата отчета
     * @param {string} [branchOfficeCode] Код филиала
     * @param {string} [divisionCode] Код дивизиона
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationOffices: async (
      date: string,
      branchOfficeCode?: string,
      divisionCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'date' is not null or undefined
      assertParamExists('statMotivationOffices', 'date', date);
      const localVarPath = `/stat/motivation/offices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      if (date !== undefined) {
        localVarQueryParameter['date'] = (date as any) instanceof Date ? (date as any).toISOString().substr(0, 10) : date;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежам по отделам
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {string} [branchOfficeCode] Филиал
     * @param {boolean} [operative] Не смотреть на текущий период. Использовать оперативные привязки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsDepartments: async (
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      operative?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/payments/departments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (chiefEmployeeCode !== undefined) {
        localVarQueryParameter['chiefEmployeeCode'] = chiefEmployeeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (operative !== undefined) {
        localVarQueryParameter['operative'] = operative;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежам по отделам (график)
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsDepartmentsPlot: async (
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/payments/departments/plot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (chiefEmployeeCode !== undefined) {
        localVarQueryParameter['chiefEmployeeCode'] = chiefEmployeeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежам по дивизионам
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [divisionCode] Дивизион
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsDivisions: async (
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      divisionCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/payments/divisions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежам по дивизионам (график)
     * @param {string} divisionCode Дивизион
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsDivisionsPlot: async (
      divisionCode: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'divisionCode' is not null or undefined
      assertParamExists('statPaymentsDivisionsPlot', 'divisionCode', divisionCode);
      const localVarPath = `/stat/payments/divisions/plot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежам по сотрудникам
     * @param {string} [employeeCode]
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {boolean} [operative] Не смотреть на текущий период. Использовать оперативные привязки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsEmployees: async (
      employeeCode?: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      operative?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/payments/employees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (chiefEmployeeCode !== undefined) {
        localVarQueryParameter['chiefEmployeeCode'] = chiefEmployeeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (operative !== undefined) {
        localVarQueryParameter['operative'] = operative;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежам по сотрудникам (график)
     * @param {string} employeeCode
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsEmployeesPlot: async (
      employeeCode: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'employeeCode' is not null or undefined
      assertParamExists('statPaymentsEmployeesPlot', 'employeeCode', employeeCode);
      const localVarPath = `/stat/payments/employees/plot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежным ожиданиям
     * @param {string} [employeeCode]
     * @param {string} [customerCode]
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsExpectations: async (
      employeeCode?: string,
      customerCode?: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/payments/expectations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (employeeCode !== undefined) {
        localVarQueryParameter['employeeCode'] = employeeCode;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (chiefEmployeeCode !== undefined) {
        localVarQueryParameter['chiefEmployeeCode'] = chiefEmployeeCode;
      }

      if (departmentCode !== undefined) {
        localVarQueryParameter['departmentCode'] = departmentCode;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежам по головным компаниям
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsHeadCompanies: async (
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/payments/head/companies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежам по головным компаниям (график)
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsHeadCompaniesPlot: async (
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/payments/head/companies/plot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежам по филиалам
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [divisionCode] Дивизион
     * @param {string} [branchOfficeCode] Филиал
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsOffices: async (
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      divisionCode?: string,
      branchOfficeCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/stat/payments/offices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (divisionCode !== undefined) {
        localVarQueryParameter['divisionCode'] = divisionCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежам по филиалам (график)
     * @param {string} branchOfficeCode Филиал
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsOfficesPlot: async (
      branchOfficeCode: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchOfficeCode' is not null or undefined
      assertParamExists('statPaymentsOfficesPlot', 'branchOfficeCode', branchOfficeCode);
      const localVarPath = `/stat/payments/offices/plot`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Статистика по платежным ожиданиям (запланированным)
     * @param {StatPaymentsPlannedRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsPlanned: async (request: StatPaymentsPlannedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('statPaymentsPlanned', 'request', request);
      const localVarPath = `/stat/payments/expectations/planned`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Отчёт по дебиторской задолженности (ДЗ)
     * @param {StatPaymentsReceivableReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsReceivableReport: async (
      request: StatPaymentsReceivableReportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('statPaymentsReceivableReport', 'request', request);
      const localVarPath = `/stat/payments/receivableReport`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Отчёт по дебиторской задолженности (ДЗ) в excel-файле
     * @param {StatPaymentsReceivableReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsReceivableReportXlsx: async (
      request: StatPaymentsReceivableReportRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('statPaymentsReceivableReportXlsx', 'request', request);
      const localVarPath = `/stat/payments/receivableReport/xlsx`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Документы для Конверсионной и Периодической воронок продаж
     * @param {StatSaleFunnelDocsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statSaleFunnelDocs: async (request: StatSaleFunnelDocsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('statSaleFunnelDocs', 'request', request);
      const localVarPath = `/stat/saleFunnel/docs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Конверсионная и Периодическая воронки продаж
     * @param {StatSaleFunnelGraphsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statSaleFunnelGraphs: async (request: StatSaleFunnelGraphsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('statSaleFunnelGraphs', 'request', request);
      const localVarPath = `/stat/saleFunnel/graphs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Воронка продаж
     * @param {StatSaleFunnelReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statSaleFunnelReport: async (request: StatSaleFunnelReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('statSaleFunnelReport', 'request', request);
      const localVarPath = `/stat/saleFunnel/report`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StatApi - functional programming interface
 * @export
 */
export const StatApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StatApiAxiosParamCreator(configuration);
  return {
    /**
     * Статистика соглашений по клиентам.
     * @param {string} [customerCode]
     * @param {string} [employeeCode] Код реализатора
     * @param {string} [departmentCode] Код отдела
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statAgreementsCustomers(
      customerCode?: string,
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatAgreementsCustomersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statAgreementsCustomers(
        customerCode,
        employeeCode,
        departmentCode,
        branchOfficeCode,
        page,
        count,
        date,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика соглашений по дивизионам
     * @param {string} [divisionCode] Код дивизиона
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statAgreementsDivisions(
      divisionCode?: string,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatAgreementsDivisionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statAgreementsDivisions(divisionCode, date, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика маржинальности по группе компаний
     * @param {string} [headCompanyCode] Код головной компании
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statAgreementsHeadCompanies(
      headCompanyCode?: string,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatAgreementsHeadCompaniesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statAgreementsHeadCompanies(headCompanyCode, date, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика маржинальности по офисам
     * @param {string} [branchOfficeCode] Код офиса
     * @param {string} [date] Дата отчета
     * @param {string} [divisionCode] Код дивизиона
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statAgreementsOffices(
      branchOfficeCode?: string,
      date?: string,
      divisionCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatAgreementsOfficesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statAgreementsOffices(branchOfficeCode, date, divisionCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Справочники для отображения страницы статистики по соглашениям
     * @param {string} [employeeCode] Код выбранного сотрудника
     * @param {string} [departmentCode] Код выбранного отдела
     * @param {string} [branchOfficeCode] Код выбранного офиса
     * @param {string} [divisionCode] Код выбранного отдела
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statAgreementsReferences(
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      divisionCode?: string,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMarginalityReferencesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statAgreementsReferences(
        employeeCode,
        departmentCode,
        branchOfficeCode,
        divisionCode,
        date,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика маржинальности по клиентам. Используется на странице показателей
     * @param {string} [customerCode]
     * @param {string} [employeeCode] Код реализатора
     * @param {string} [departmentCode] Код отдела
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMarginalityCustomers(
      customerCode?: string,
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMarginalityCustomersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMarginalityCustomers(
        customerCode,
        employeeCode,
        departmentCode,
        branchOfficeCode,
        page,
        count,
        date,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика маржинальности по отделам
     * @param {string} [departmentCode]
     * @param {string} [date] Дата отчета
     * @param {string} [branchOfficeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMarginalityDepartments(
      departmentCode?: string,
      date?: string,
      branchOfficeCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMarginalityDepartmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMarginalityDepartments(departmentCode, date, branchOfficeCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика маржинальности по дивизионам
     * @param {string} [divisionCode] Код дивизиона
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMarginalityDivisions(
      divisionCode?: string,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMarginalityDivisionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMarginalityDivisions(divisionCode, date, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика маржинальности по клиентам для менеджера
     * @param {string} [employeeCode] Код реализатора
     * @param {string} [customerCode]
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMarginalityEmployeeCustomers(
      employeeCode?: string,
      customerCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMarginalityEmployeeCustomersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMarginalityEmployeeCustomers(
        employeeCode,
        customerCode,
        branchOfficeCode,
        page,
        count,
        date,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Рейтинг сотрудников на основе общего показателя выполнения плана продаж
     * @param {string} [branchOfficeCode] Код офиса
     * @param {string} [date] Дата отчёта
     * @param {boolean} [allCompany] Запросить рейтинг по всей компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMarginalityEmployeeOverallRating(
      branchOfficeCode?: string,
      date?: string,
      allCompany?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMarginalityEmployeeOverallRatingResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMarginalityEmployeeOverallRating(
        branchOfficeCode,
        date,
        allCompany,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика маржинальности по сотрудникам
     * @param {string} [employeeCode]
     * @param {string} [branchOfficeCode]
     * @param {string} [date] Дата отчета
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMarginalityEmployees(
      employeeCode?: string,
      branchOfficeCode?: string,
      date?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMarginalityEmployeesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMarginalityEmployees(
        employeeCode,
        branchOfficeCode,
        date,
        chiefEmployeeCode,
        departmentCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика маржинальности по группе компаний
     * @param {string} [headCompanyCode] Код головной компании
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMarginalityHeadCompanies(
      headCompanyCode?: string,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMarginalityHeadCompaniesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMarginalityHeadCompanies(headCompanyCode, date, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика маржинальности по офисам
     * @param {string} [branchOfficeCode] Код офиса
     * @param {string} [date] Дата отчета
     * @param {string} [divisionCode] Код дивизиона
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMarginalityOffices(
      branchOfficeCode?: string,
      date?: string,
      divisionCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMarginalityOfficesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMarginalityOffices(branchOfficeCode, date, divisionCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Справочники для отображения страницы показателей маржинальности
     * @param {string} [employeeCode] Код выбранного сотрудника
     * @param {string} [departmentCode] Код выбранного отдела
     * @param {string} [branchOfficeCode] Код выбранного офиса
     * @param {string} [divisionCode] Код выбранного отдела
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMarginalityReferences(
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      divisionCode?: string,
      date?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMarginalityReferencesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMarginalityReferences(
        employeeCode,
        departmentCode,
        branchOfficeCode,
        divisionCode,
        date,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Мотивация по клиентам
     * @param {string} date Период отчета
     * @param {string} [customerCode]
     * @param {string} [departmentCode] Код департамента
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMotivationCustomers(
      date: string,
      customerCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMotivationCustomersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMotivationCustomers(
        date,
        customerCode,
        departmentCode,
        branchOfficeCode,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Мотивация по департаментам
     * @param {string} date Дата отчета
     * @param {string} [branchOfficeCode]
     * @param {string} [departmentCode] Код департамента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMotivationDepartments(
      date: string,
      branchOfficeCode?: string,
      departmentCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMotivationDepartmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMotivationDepartments(date, branchOfficeCode, departmentCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Мотивация по дивизионам
     * @param {string} divisionCode Код дивизиона
     * @param {string} date Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMotivationDivisions(
      divisionCode: string,
      date: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMotivationDivisionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMotivationDivisions(divisionCode, date, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Мотивация по сотрудникам
     * @param {string} date Дата отчета
     * @param {string} [departmentCode] Код департамента
     * @param {string} [employeeCode] Код сотрудника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMotivationEmployees(
      date: string,
      departmentCode?: string,
      employeeCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMotivationEmployeesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMotivationEmployees(date, departmentCode, employeeCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Мотивация по клиентам сотрудника
     * @param {string} date Дата отчета
     * @param {string} [customerCode]
     * @param {string} [departmentCode] Код департамента
     * @param {string} [employeeCode] Код сотрудника
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMotivationEmployeesCustomers(
      date: string,
      customerCode?: string,
      departmentCode?: string,
      employeeCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMotivationEmployeesCustomersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMotivationEmployeesCustomers(
        date,
        customerCode,
        departmentCode,
        employeeCode,
        branchOfficeCode,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Мотивация по компании
     * @param {string} headCompanyCode Код головной компании
     * @param {string} date Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMotivationHeadCompanies(
      headCompanyCode: string,
      date: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMotivationHeadCompaniesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMotivationHeadCompanies(headCompanyCode, date, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Мотивация по филиалам
     * @param {string} date Дата отчета
     * @param {string} [branchOfficeCode] Код филиала
     * @param {string} [divisionCode] Код дивизиона
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statMotivationOffices(
      date: string,
      branchOfficeCode?: string,
      divisionCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatMotivationOfficesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statMotivationOffices(date, branchOfficeCode, divisionCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежам по отделам
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {string} [branchOfficeCode] Филиал
     * @param {boolean} [operative] Не смотреть на текущий период. Использовать оперативные привязки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsDepartments(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      operative?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsDepartmentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsDepartments(
        dateFrom,
        dateTo,
        from,
        to,
        chiefEmployeeCode,
        departmentCode,
        branchOfficeCode,
        operative,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежам по отделам (график)
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsDepartmentsPlot(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsDepartmentsPlotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsDepartmentsPlot(
        dateFrom,
        dateTo,
        from,
        to,
        chiefEmployeeCode,
        departmentCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежам по дивизионам
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [divisionCode] Дивизион
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsDivisions(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      divisionCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsDivisionsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsDivisions(dateFrom, dateTo, from, to, divisionCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежам по дивизионам (график)
     * @param {string} divisionCode Дивизион
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsDivisionsPlot(
      divisionCode: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsDivisionsPlotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsDivisionsPlot(
        divisionCode,
        dateFrom,
        dateTo,
        from,
        to,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежам по сотрудникам
     * @param {string} [employeeCode]
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {boolean} [operative] Не смотреть на текущий период. Использовать оперативные привязки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsEmployees(
      employeeCode?: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      operative?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsEmployeesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsEmployees(
        employeeCode,
        dateFrom,
        dateTo,
        from,
        to,
        chiefEmployeeCode,
        departmentCode,
        operative,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежам по сотрудникам (график)
     * @param {string} employeeCode
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsEmployeesPlot(
      employeeCode: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsEmployeesPlotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsEmployeesPlot(
        employeeCode,
        dateFrom,
        dateTo,
        from,
        to,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежным ожиданиям
     * @param {string} [employeeCode]
     * @param {string} [customerCode]
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsExpectations(
      employeeCode?: string,
      customerCode?: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsExpectationsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsExpectations(
        employeeCode,
        customerCode,
        dateFrom,
        dateTo,
        from,
        to,
        chiefEmployeeCode,
        departmentCode,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежам по головным компаниям
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsHeadCompanies(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsHeadCompaniesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsHeadCompanies(dateFrom, dateTo, from, to, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежам по головным компаниям (график)
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsHeadCompaniesPlot(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsHeadCompaniesPlotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsHeadCompaniesPlot(dateFrom, dateTo, from, to, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежам по филиалам
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [divisionCode] Дивизион
     * @param {string} [branchOfficeCode] Филиал
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsOffices(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      divisionCode?: string,
      branchOfficeCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsOfficesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsOffices(
        dateFrom,
        dateTo,
        from,
        to,
        divisionCode,
        branchOfficeCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежам по филиалам (график)
     * @param {string} branchOfficeCode Филиал
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsOfficesPlot(
      branchOfficeCode: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsOfficesPlotResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsOfficesPlot(
        branchOfficeCode,
        dateFrom,
        dateTo,
        from,
        to,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Статистика по платежным ожиданиям (запланированным)
     * @param {StatPaymentsPlannedRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsPlanned(
      request: StatPaymentsPlannedRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsPlannedResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsPlanned(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Отчёт по дебиторской задолженности (ДЗ)
     * @param {StatPaymentsReceivableReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsReceivableReport(
      request: StatPaymentsReceivableReportRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatPaymentsReceivableReportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsReceivableReport(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Отчёт по дебиторской задолженности (ДЗ) в excel-файле
     * @param {StatPaymentsReceivableReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statPaymentsReceivableReportXlsx(
      request: StatPaymentsReceivableReportRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statPaymentsReceivableReportXlsx(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Документы для Конверсионной и Периодической воронок продаж
     * @param {StatSaleFunnelDocsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statSaleFunnelDocs(
      request: StatSaleFunnelDocsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatSaleFunnelDocsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statSaleFunnelDocs(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Конверсионная и Периодическая воронки продаж
     * @param {StatSaleFunnelGraphsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statSaleFunnelGraphs(
      request: StatSaleFunnelGraphsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatSaleFunnelGraphsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statSaleFunnelGraphs(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Воронка продаж
     * @param {StatSaleFunnelReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async statSaleFunnelReport(
      request: StatSaleFunnelReportRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatSaleFunnelReportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.statSaleFunnelReport(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * StatApi - factory interface
 * @export
 */
export const StatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = StatApiFp(configuration);
  return {
    /**
     * Статистика соглашений по клиентам.
     * @param {string} [customerCode]
     * @param {string} [employeeCode] Код реализатора
     * @param {string} [departmentCode] Код отдела
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statAgreementsCustomers(
      customerCode?: string,
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      date?: string,
      options?: any
    ): AxiosPromise<StatAgreementsCustomersResponse> {
      return localVarFp
        .statAgreementsCustomers(customerCode, employeeCode, departmentCode, branchOfficeCode, page, count, date, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика соглашений по дивизионам
     * @param {string} [divisionCode] Код дивизиона
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statAgreementsDivisions(divisionCode?: string, date?: string, options?: any): AxiosPromise<StatAgreementsDivisionsResponse> {
      return localVarFp.statAgreementsDivisions(divisionCode, date, options).then(request => request(axios, basePath));
    },
    /**
     * Статистика маржинальности по группе компаний
     * @param {string} [headCompanyCode] Код головной компании
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statAgreementsHeadCompanies(headCompanyCode?: string, date?: string, options?: any): AxiosPromise<StatAgreementsHeadCompaniesResponse> {
      return localVarFp.statAgreementsHeadCompanies(headCompanyCode, date, options).then(request => request(axios, basePath));
    },
    /**
     * Статистика маржинальности по офисам
     * @param {string} [branchOfficeCode] Код офиса
     * @param {string} [date] Дата отчета
     * @param {string} [divisionCode] Код дивизиона
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statAgreementsOffices(
      branchOfficeCode?: string,
      date?: string,
      divisionCode?: string,
      options?: any
    ): AxiosPromise<StatAgreementsOfficesResponse> {
      return localVarFp.statAgreementsOffices(branchOfficeCode, date, divisionCode, options).then(request => request(axios, basePath));
    },
    /**
     * Справочники для отображения страницы статистики по соглашениям
     * @param {string} [employeeCode] Код выбранного сотрудника
     * @param {string} [departmentCode] Код выбранного отдела
     * @param {string} [branchOfficeCode] Код выбранного офиса
     * @param {string} [divisionCode] Код выбранного отдела
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statAgreementsReferences(
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      divisionCode?: string,
      date?: string,
      options?: any
    ): AxiosPromise<StatMarginalityReferencesResponse> {
      return localVarFp
        .statAgreementsReferences(employeeCode, departmentCode, branchOfficeCode, divisionCode, date, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика маржинальности по клиентам. Используется на странице показателей
     * @param {string} [customerCode]
     * @param {string} [employeeCode] Код реализатора
     * @param {string} [departmentCode] Код отдела
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityCustomers(
      customerCode?: string,
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      date?: string,
      options?: any
    ): AxiosPromise<StatMarginalityCustomersResponse> {
      return localVarFp
        .statMarginalityCustomers(customerCode, employeeCode, departmentCode, branchOfficeCode, page, count, date, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика маржинальности по отделам
     * @param {string} [departmentCode]
     * @param {string} [date] Дата отчета
     * @param {string} [branchOfficeCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityDepartments(
      departmentCode?: string,
      date?: string,
      branchOfficeCode?: string,
      options?: any
    ): AxiosPromise<StatMarginalityDepartmentsResponse> {
      return localVarFp
        .statMarginalityDepartments(departmentCode, date, branchOfficeCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика маржинальности по дивизионам
     * @param {string} [divisionCode] Код дивизиона
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityDivisions(divisionCode?: string, date?: string, options?: any): AxiosPromise<StatMarginalityDivisionsResponse> {
      return localVarFp.statMarginalityDivisions(divisionCode, date, options).then(request => request(axios, basePath));
    },
    /**
     * Статистика маржинальности по клиентам для менеджера
     * @param {string} [employeeCode] Код реализатора
     * @param {string} [customerCode]
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityEmployeeCustomers(
      employeeCode?: string,
      customerCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      date?: string,
      options?: any
    ): AxiosPromise<StatMarginalityEmployeeCustomersResponse> {
      return localVarFp
        .statMarginalityEmployeeCustomers(employeeCode, customerCode, branchOfficeCode, page, count, date, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Рейтинг сотрудников на основе общего показателя выполнения плана продаж
     * @param {string} [branchOfficeCode] Код офиса
     * @param {string} [date] Дата отчёта
     * @param {boolean} [allCompany] Запросить рейтинг по всей компании
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityEmployeeOverallRating(
      branchOfficeCode?: string,
      date?: string,
      allCompany?: boolean,
      options?: any
    ): AxiosPromise<StatMarginalityEmployeeOverallRatingResponse> {
      return localVarFp
        .statMarginalityEmployeeOverallRating(branchOfficeCode, date, allCompany, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика маржинальности по сотрудникам
     * @param {string} [employeeCode]
     * @param {string} [branchOfficeCode]
     * @param {string} [date] Дата отчета
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityEmployees(
      employeeCode?: string,
      branchOfficeCode?: string,
      date?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      options?: any
    ): AxiosPromise<StatMarginalityEmployeesResponse> {
      return localVarFp
        .statMarginalityEmployees(employeeCode, branchOfficeCode, date, chiefEmployeeCode, departmentCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика маржинальности по группе компаний
     * @param {string} [headCompanyCode] Код головной компании
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityHeadCompanies(
      headCompanyCode?: string,
      date?: string,
      options?: any
    ): AxiosPromise<StatMarginalityHeadCompaniesResponse> {
      return localVarFp.statMarginalityHeadCompanies(headCompanyCode, date, options).then(request => request(axios, basePath));
    },
    /**
     * Статистика маржинальности по офисам
     * @param {string} [branchOfficeCode] Код офиса
     * @param {string} [date] Дата отчета
     * @param {string} [divisionCode] Код дивизиона
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityOffices(
      branchOfficeCode?: string,
      date?: string,
      divisionCode?: string,
      options?: any
    ): AxiosPromise<StatMarginalityOfficesResponse> {
      return localVarFp.statMarginalityOffices(branchOfficeCode, date, divisionCode, options).then(request => request(axios, basePath));
    },
    /**
     * Справочники для отображения страницы показателей маржинальности
     * @param {string} [employeeCode] Код выбранного сотрудника
     * @param {string} [departmentCode] Код выбранного отдела
     * @param {string} [branchOfficeCode] Код выбранного офиса
     * @param {string} [divisionCode] Код выбранного отдела
     * @param {string} [date] Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMarginalityReferences(
      employeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      divisionCode?: string,
      date?: string,
      options?: any
    ): AxiosPromise<StatMarginalityReferencesResponse> {
      return localVarFp
        .statMarginalityReferences(employeeCode, departmentCode, branchOfficeCode, divisionCode, date, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Мотивация по клиентам
     * @param {string} date Период отчета
     * @param {string} [customerCode]
     * @param {string} [departmentCode] Код департамента
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationCustomers(
      date: string,
      customerCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<StatMotivationCustomersResponse> {
      return localVarFp
        .statMotivationCustomers(date, customerCode, departmentCode, branchOfficeCode, page, count, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Мотивация по департаментам
     * @param {string} date Дата отчета
     * @param {string} [branchOfficeCode]
     * @param {string} [departmentCode] Код департамента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationDepartments(
      date: string,
      branchOfficeCode?: string,
      departmentCode?: string,
      options?: any
    ): AxiosPromise<StatMotivationDepartmentsResponse> {
      return localVarFp
        .statMotivationDepartments(date, branchOfficeCode, departmentCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Мотивация по дивизионам
     * @param {string} divisionCode Код дивизиона
     * @param {string} date Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationDivisions(divisionCode: string, date: string, options?: any): AxiosPromise<StatMotivationDivisionsResponse> {
      return localVarFp.statMotivationDivisions(divisionCode, date, options).then(request => request(axios, basePath));
    },
    /**
     * Мотивация по сотрудникам
     * @param {string} date Дата отчета
     * @param {string} [departmentCode] Код департамента
     * @param {string} [employeeCode] Код сотрудника
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationEmployees(
      date: string,
      departmentCode?: string,
      employeeCode?: string,
      options?: any
    ): AxiosPromise<StatMotivationEmployeesResponse> {
      return localVarFp.statMotivationEmployees(date, departmentCode, employeeCode, options).then(request => request(axios, basePath));
    },
    /**
     * Мотивация по клиентам сотрудника
     * @param {string} date Дата отчета
     * @param {string} [customerCode]
     * @param {string} [departmentCode] Код департамента
     * @param {string} [employeeCode] Код сотрудника
     * @param {string} [branchOfficeCode] Код офиса
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationEmployeesCustomers(
      date: string,
      customerCode?: string,
      departmentCode?: string,
      employeeCode?: string,
      branchOfficeCode?: string,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<StatMotivationEmployeesCustomersResponse> {
      return localVarFp
        .statMotivationEmployeesCustomers(date, customerCode, departmentCode, employeeCode, branchOfficeCode, page, count, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Мотивация по компании
     * @param {string} headCompanyCode Код головной компании
     * @param {string} date Дата отчета
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationHeadCompanies(headCompanyCode: string, date: string, options?: any): AxiosPromise<StatMotivationHeadCompaniesResponse> {
      return localVarFp.statMotivationHeadCompanies(headCompanyCode, date, options).then(request => request(axios, basePath));
    },
    /**
     * Мотивация по филиалам
     * @param {string} date Дата отчета
     * @param {string} [branchOfficeCode] Код филиала
     * @param {string} [divisionCode] Код дивизиона
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statMotivationOffices(
      date: string,
      branchOfficeCode?: string,
      divisionCode?: string,
      options?: any
    ): AxiosPromise<StatMotivationOfficesResponse> {
      return localVarFp.statMotivationOffices(date, branchOfficeCode, divisionCode, options).then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежам по отделам
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {string} [branchOfficeCode] Филиал
     * @param {boolean} [operative] Не смотреть на текущий период. Использовать оперативные привязки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsDepartments(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      branchOfficeCode?: string,
      operative?: boolean,
      options?: any
    ): AxiosPromise<StatPaymentsDepartmentsResponse> {
      return localVarFp
        .statPaymentsDepartments(dateFrom, dateTo, from, to, chiefEmployeeCode, departmentCode, branchOfficeCode, operative, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежам по отделам (график)
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsDepartmentsPlot(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      options?: any
    ): AxiosPromise<StatPaymentsDepartmentsPlotResponse> {
      return localVarFp
        .statPaymentsDepartmentsPlot(dateFrom, dateTo, from, to, chiefEmployeeCode, departmentCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежам по дивизионам
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [divisionCode] Дивизион
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsDivisions(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      divisionCode?: string,
      options?: any
    ): AxiosPromise<StatPaymentsDivisionsResponse> {
      return localVarFp.statPaymentsDivisions(dateFrom, dateTo, from, to, divisionCode, options).then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежам по дивизионам (график)
     * @param {string} divisionCode Дивизион
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsDivisionsPlot(
      divisionCode: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options?: any
    ): AxiosPromise<StatPaymentsDivisionsPlotResponse> {
      return localVarFp
        .statPaymentsDivisionsPlot(divisionCode, dateFrom, dateTo, from, to, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежам по сотрудникам
     * @param {string} [employeeCode]
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {boolean} [operative] Не смотреть на текущий период. Использовать оперативные привязки.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsEmployees(
      employeeCode?: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      operative?: boolean,
      options?: any
    ): AxiosPromise<StatPaymentsEmployeesResponse> {
      return localVarFp
        .statPaymentsEmployees(employeeCode, dateFrom, dateTo, from, to, chiefEmployeeCode, departmentCode, operative, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежам по сотрудникам (график)
     * @param {string} employeeCode
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsEmployeesPlot(
      employeeCode: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options?: any
    ): AxiosPromise<StatPaymentsEmployeesPlotResponse> {
      return localVarFp
        .statPaymentsEmployeesPlot(employeeCode, dateFrom, dateTo, from, to, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежным ожиданиям
     * @param {string} [employeeCode]
     * @param {string} [customerCode]
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [chiefEmployeeCode] Руководитель сотрудников
     * @param {string} [departmentCode] Отдел
     * @param {number} [page]
     * @param {number} [count]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsExpectations(
      employeeCode?: string,
      customerCode?: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      chiefEmployeeCode?: string,
      departmentCode?: string,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<StatPaymentsExpectationsResponse> {
      return localVarFp
        .statPaymentsExpectations(
          employeeCode,
          customerCode,
          dateFrom,
          dateTo,
          from,
          to,
          chiefEmployeeCode,
          departmentCode,
          page,
          count,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежам по головным компаниям
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsHeadCompanies(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options?: any
    ): AxiosPromise<StatPaymentsHeadCompaniesResponse> {
      return localVarFp.statPaymentsHeadCompanies(dateFrom, dateTo, from, to, options).then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежам по головным компаниям (график)
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsHeadCompaniesPlot(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options?: any
    ): AxiosPromise<StatPaymentsHeadCompaniesPlotResponse> {
      return localVarFp.statPaymentsHeadCompaniesPlot(dateFrom, dateTo, from, to, options).then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежам по филиалам
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [divisionCode] Дивизион
     * @param {string} [branchOfficeCode] Филиал
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsOffices(
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      divisionCode?: string,
      branchOfficeCode?: string,
      options?: any
    ): AxiosPromise<StatPaymentsOfficesResponse> {
      return localVarFp
        .statPaymentsOffices(dateFrom, dateTo, from, to, divisionCode, branchOfficeCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежам по филиалам (график)
     * @param {string} branchOfficeCode Филиал
     * @param {string} [dateFrom]
     * @param {string} [dateTo] Дата отчета
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsOfficesPlot(
      branchOfficeCode: string,
      dateFrom?: string,
      dateTo?: string,
      from?: string,
      to?: string,
      options?: any
    ): AxiosPromise<StatPaymentsOfficesPlotResponse> {
      return localVarFp
        .statPaymentsOfficesPlot(branchOfficeCode, dateFrom, dateTo, from, to, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Статистика по платежным ожиданиям (запланированным)
     * @param {StatPaymentsPlannedRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsPlanned(request: StatPaymentsPlannedRequest, options?: any): AxiosPromise<StatPaymentsPlannedResponse> {
      return localVarFp.statPaymentsPlanned(request, options).then(request => request(axios, basePath));
    },
    /**
     * Отчёт по дебиторской задолженности (ДЗ)
     * @param {StatPaymentsReceivableReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsReceivableReport(
      request: StatPaymentsReceivableReportRequest,
      options?: any
    ): AxiosPromise<StatPaymentsReceivableReportResponse> {
      return localVarFp.statPaymentsReceivableReport(request, options).then(request => request(axios, basePath));
    },
    /**
     * Отчёт по дебиторской задолженности (ДЗ) в excel-файле
     * @param {StatPaymentsReceivableReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statPaymentsReceivableReportXlsx(request: StatPaymentsReceivableReportRequest, options?: any): AxiosPromise<object> {
      return localVarFp.statPaymentsReceivableReportXlsx(request, options).then(request => request(axios, basePath));
    },
    /**
     * Документы для Конверсионной и Периодической воронок продаж
     * @param {StatSaleFunnelDocsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statSaleFunnelDocs(request: StatSaleFunnelDocsRequest, options?: any): AxiosPromise<StatSaleFunnelDocsResponse> {
      return localVarFp.statSaleFunnelDocs(request, options).then(request => request(axios, basePath));
    },
    /**
     * Конверсионная и Периодическая воронки продаж
     * @param {StatSaleFunnelGraphsRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statSaleFunnelGraphs(request: StatSaleFunnelGraphsRequest, options?: any): AxiosPromise<StatSaleFunnelGraphsResponse> {
      return localVarFp.statSaleFunnelGraphs(request, options).then(request => request(axios, basePath));
    },
    /**
     * Воронка продаж
     * @param {StatSaleFunnelReportRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    statSaleFunnelReport(request: StatSaleFunnelReportRequest, options?: any): AxiosPromise<StatSaleFunnelReportResponse> {
      return localVarFp.statSaleFunnelReport(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * StatApi - interface
 * @export
 * @interface StatApi
 */
export interface StatApiInterface {
  /**
   * Статистика соглашений по клиентам.
   * @param {string} [customerCode]
   * @param {string} [employeeCode] Код реализатора
   * @param {string} [departmentCode] Код отдела
   * @param {string} [branchOfficeCode] Код офиса
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statAgreementsCustomers(
    customerCode?: string,
    employeeCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatAgreementsCustomersResponse>;

  /**
   * Статистика соглашений по дивизионам
   * @param {string} [divisionCode] Код дивизиона
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statAgreementsDivisions(
    divisionCode?: string,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatAgreementsDivisionsResponse>;

  /**
   * Статистика маржинальности по группе компаний
   * @param {string} [headCompanyCode] Код головной компании
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statAgreementsHeadCompanies(
    headCompanyCode?: string,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatAgreementsHeadCompaniesResponse>;

  /**
   * Статистика маржинальности по офисам
   * @param {string} [branchOfficeCode] Код офиса
   * @param {string} [date] Дата отчета
   * @param {string} [divisionCode] Код дивизиона
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statAgreementsOffices(
    branchOfficeCode?: string,
    date?: string,
    divisionCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatAgreementsOfficesResponse>;

  /**
   * Справочники для отображения страницы статистики по соглашениям
   * @param {string} [employeeCode] Код выбранного сотрудника
   * @param {string} [departmentCode] Код выбранного отдела
   * @param {string} [branchOfficeCode] Код выбранного офиса
   * @param {string} [divisionCode] Код выбранного отдела
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statAgreementsReferences(
    employeeCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    divisionCode?: string,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMarginalityReferencesResponse>;

  /**
   * Статистика маржинальности по клиентам. Используется на странице показателей
   * @param {string} [customerCode]
   * @param {string} [employeeCode] Код реализатора
   * @param {string} [departmentCode] Код отдела
   * @param {string} [branchOfficeCode] Код офиса
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMarginalityCustomers(
    customerCode?: string,
    employeeCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMarginalityCustomersResponse>;

  /**
   * Статистика маржинальности по отделам
   * @param {string} [departmentCode]
   * @param {string} [date] Дата отчета
   * @param {string} [branchOfficeCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMarginalityDepartments(
    departmentCode?: string,
    date?: string,
    branchOfficeCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMarginalityDepartmentsResponse>;

  /**
   * Статистика маржинальности по дивизионам
   * @param {string} [divisionCode] Код дивизиона
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMarginalityDivisions(
    divisionCode?: string,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMarginalityDivisionsResponse>;

  /**
   * Статистика маржинальности по клиентам для менеджера
   * @param {string} [employeeCode] Код реализатора
   * @param {string} [customerCode]
   * @param {string} [branchOfficeCode] Код офиса
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMarginalityEmployeeCustomers(
    employeeCode?: string,
    customerCode?: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMarginalityEmployeeCustomersResponse>;

  /**
   * Рейтинг сотрудников на основе общего показателя выполнения плана продаж
   * @param {string} [branchOfficeCode] Код офиса
   * @param {string} [date] Дата отчёта
   * @param {boolean} [allCompany] Запросить рейтинг по всей компании
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMarginalityEmployeeOverallRating(
    branchOfficeCode?: string,
    date?: string,
    allCompany?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMarginalityEmployeeOverallRatingResponse>;

  /**
   * Статистика маржинальности по сотрудникам
   * @param {string} [employeeCode]
   * @param {string} [branchOfficeCode]
   * @param {string} [date] Дата отчета
   * @param {string} [chiefEmployeeCode] Руководитель сотрудников
   * @param {string} [departmentCode] Отдел
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMarginalityEmployees(
    employeeCode?: string,
    branchOfficeCode?: string,
    date?: string,
    chiefEmployeeCode?: string,
    departmentCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMarginalityEmployeesResponse>;

  /**
   * Статистика маржинальности по группе компаний
   * @param {string} [headCompanyCode] Код головной компании
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMarginalityHeadCompanies(
    headCompanyCode?: string,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMarginalityHeadCompaniesResponse>;

  /**
   * Статистика маржинальности по офисам
   * @param {string} [branchOfficeCode] Код офиса
   * @param {string} [date] Дата отчета
   * @param {string} [divisionCode] Код дивизиона
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMarginalityOffices(
    branchOfficeCode?: string,
    date?: string,
    divisionCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMarginalityOfficesResponse>;

  /**
   * Справочники для отображения страницы показателей маржинальности
   * @param {string} [employeeCode] Код выбранного сотрудника
   * @param {string} [departmentCode] Код выбранного отдела
   * @param {string} [branchOfficeCode] Код выбранного офиса
   * @param {string} [divisionCode] Код выбранного отдела
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMarginalityReferences(
    employeeCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    divisionCode?: string,
    date?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMarginalityReferencesResponse>;

  /**
   * Мотивация по клиентам
   * @param {string} date Период отчета
   * @param {string} [customerCode]
   * @param {string} [departmentCode] Код департамента
   * @param {string} [branchOfficeCode] Код офиса
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMotivationCustomers(
    date: string,
    customerCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMotivationCustomersResponse>;

  /**
   * Мотивация по департаментам
   * @param {string} date Дата отчета
   * @param {string} [branchOfficeCode]
   * @param {string} [departmentCode] Код департамента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMotivationDepartments(
    date: string,
    branchOfficeCode?: string,
    departmentCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMotivationDepartmentsResponse>;

  /**
   * Мотивация по дивизионам
   * @param {string} divisionCode Код дивизиона
   * @param {string} date Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMotivationDivisions(divisionCode: string, date: string, options?: AxiosRequestConfig): AxiosPromise<StatMotivationDivisionsResponse>;

  /**
   * Мотивация по сотрудникам
   * @param {string} date Дата отчета
   * @param {string} [departmentCode] Код департамента
   * @param {string} [employeeCode] Код сотрудника
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMotivationEmployees(
    date: string,
    departmentCode?: string,
    employeeCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMotivationEmployeesResponse>;

  /**
   * Мотивация по клиентам сотрудника
   * @param {string} date Дата отчета
   * @param {string} [customerCode]
   * @param {string} [departmentCode] Код департамента
   * @param {string} [employeeCode] Код сотрудника
   * @param {string} [branchOfficeCode] Код офиса
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMotivationEmployeesCustomers(
    date: string,
    customerCode?: string,
    departmentCode?: string,
    employeeCode?: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMotivationEmployeesCustomersResponse>;

  /**
   * Мотивация по компании
   * @param {string} headCompanyCode Код головной компании
   * @param {string} date Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMotivationHeadCompanies(
    headCompanyCode: string,
    date: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMotivationHeadCompaniesResponse>;

  /**
   * Мотивация по филиалам
   * @param {string} date Дата отчета
   * @param {string} [branchOfficeCode] Код филиала
   * @param {string} [divisionCode] Код дивизиона
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statMotivationOffices(
    date: string,
    branchOfficeCode?: string,
    divisionCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatMotivationOfficesResponse>;

  /**
   * Статистика по платежам по отделам
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [chiefEmployeeCode] Руководитель сотрудников
   * @param {string} [departmentCode] Отдел
   * @param {string} [branchOfficeCode] Филиал
   * @param {boolean} [operative] Не смотреть на текущий период. Использовать оперативные привязки.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsDepartments(
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    chiefEmployeeCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    operative?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsDepartmentsResponse>;

  /**
   * Статистика по платежам по отделам (график)
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [chiefEmployeeCode] Руководитель сотрудников
   * @param {string} [departmentCode] Отдел
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsDepartmentsPlot(
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    chiefEmployeeCode?: string,
    departmentCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsDepartmentsPlotResponse>;

  /**
   * Статистика по платежам по дивизионам
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [divisionCode] Дивизион
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsDivisions(
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    divisionCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsDivisionsResponse>;

  /**
   * Статистика по платежам по дивизионам (график)
   * @param {string} divisionCode Дивизион
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsDivisionsPlot(
    divisionCode: string,
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsDivisionsPlotResponse>;

  /**
   * Статистика по платежам по сотрудникам
   * @param {string} [employeeCode]
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [chiefEmployeeCode] Руководитель сотрудников
   * @param {string} [departmentCode] Отдел
   * @param {boolean} [operative] Не смотреть на текущий период. Использовать оперативные привязки.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsEmployees(
    employeeCode?: string,
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    chiefEmployeeCode?: string,
    departmentCode?: string,
    operative?: boolean,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsEmployeesResponse>;

  /**
   * Статистика по платежам по сотрудникам (график)
   * @param {string} employeeCode
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsEmployeesPlot(
    employeeCode: string,
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsEmployeesPlotResponse>;

  /**
   * Статистика по платежным ожиданиям
   * @param {string} [employeeCode]
   * @param {string} [customerCode]
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [chiefEmployeeCode] Руководитель сотрудников
   * @param {string} [departmentCode] Отдел
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsExpectations(
    employeeCode?: string,
    customerCode?: string,
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    chiefEmployeeCode?: string,
    departmentCode?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsExpectationsResponse>;

  /**
   * Статистика по платежам по головным компаниям
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsHeadCompanies(
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsHeadCompaniesResponse>;

  /**
   * Статистика по платежам по головным компаниям (график)
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsHeadCompaniesPlot(
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsHeadCompaniesPlotResponse>;

  /**
   * Статистика по платежам по филиалам
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [divisionCode] Дивизион
   * @param {string} [branchOfficeCode] Филиал
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsOffices(
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    divisionCode?: string,
    branchOfficeCode?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsOfficesResponse>;

  /**
   * Статистика по платежам по филиалам (график)
   * @param {string} branchOfficeCode Филиал
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsOfficesPlot(
    branchOfficeCode: string,
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsOfficesPlotResponse>;

  /**
   * Статистика по платежным ожиданиям (запланированным)
   * @param {StatPaymentsPlannedRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsPlanned(request: StatPaymentsPlannedRequest, options?: AxiosRequestConfig): AxiosPromise<StatPaymentsPlannedResponse>;

  /**
   * Отчёт по дебиторской задолженности (ДЗ)
   * @param {StatPaymentsReceivableReportRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsReceivableReport(
    request: StatPaymentsReceivableReportRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<StatPaymentsReceivableReportResponse>;

  /**
   * Отчёт по дебиторской задолженности (ДЗ) в excel-файле
   * @param {StatPaymentsReceivableReportRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statPaymentsReceivableReportXlsx(request: StatPaymentsReceivableReportRequest, options?: AxiosRequestConfig): AxiosPromise<object>;

  /**
   * Документы для Конверсионной и Периодической воронок продаж
   * @param {StatSaleFunnelDocsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statSaleFunnelDocs(request: StatSaleFunnelDocsRequest, options?: AxiosRequestConfig): AxiosPromise<StatSaleFunnelDocsResponse>;

  /**
   * Конверсионная и Периодическая воронки продаж
   * @param {StatSaleFunnelGraphsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statSaleFunnelGraphs(request: StatSaleFunnelGraphsRequest, options?: AxiosRequestConfig): AxiosPromise<StatSaleFunnelGraphsResponse>;

  /**
   * Воронка продаж
   * @param {StatSaleFunnelReportRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApiInterface
   */
  statSaleFunnelReport(request: StatSaleFunnelReportRequest, options?: AxiosRequestConfig): AxiosPromise<StatSaleFunnelReportResponse>;
}

/**
 * StatApi - object-oriented interface
 * @export
 * @class StatApi
 * @extends {BaseAPI}
 */
export class StatApi extends BaseAPI implements StatApiInterface {
  /**
   * Статистика соглашений по клиентам.
   * @param {string} [customerCode]
   * @param {string} [employeeCode] Код реализатора
   * @param {string} [departmentCode] Код отдела
   * @param {string} [branchOfficeCode] Код офиса
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statAgreementsCustomers(
    customerCode?: string,
    employeeCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    date?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statAgreementsCustomers(customerCode, employeeCode, departmentCode, branchOfficeCode, page, count, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика соглашений по дивизионам
   * @param {string} [divisionCode] Код дивизиона
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statAgreementsDivisions(divisionCode?: string, date?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statAgreementsDivisions(divisionCode, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика маржинальности по группе компаний
   * @param {string} [headCompanyCode] Код головной компании
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statAgreementsHeadCompanies(headCompanyCode?: string, date?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statAgreementsHeadCompanies(headCompanyCode, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика маржинальности по офисам
   * @param {string} [branchOfficeCode] Код офиса
   * @param {string} [date] Дата отчета
   * @param {string} [divisionCode] Код дивизиона
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statAgreementsOffices(branchOfficeCode?: string, date?: string, divisionCode?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statAgreementsOffices(branchOfficeCode, date, divisionCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Справочники для отображения страницы статистики по соглашениям
   * @param {string} [employeeCode] Код выбранного сотрудника
   * @param {string} [departmentCode] Код выбранного отдела
   * @param {string} [branchOfficeCode] Код выбранного офиса
   * @param {string} [divisionCode] Код выбранного отдела
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statAgreementsReferences(
    employeeCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    divisionCode?: string,
    date?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statAgreementsReferences(employeeCode, departmentCode, branchOfficeCode, divisionCode, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика маржинальности по клиентам. Используется на странице показателей
   * @param {string} [customerCode]
   * @param {string} [employeeCode] Код реализатора
   * @param {string} [departmentCode] Код отдела
   * @param {string} [branchOfficeCode] Код офиса
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMarginalityCustomers(
    customerCode?: string,
    employeeCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    date?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statMarginalityCustomers(customerCode, employeeCode, departmentCode, branchOfficeCode, page, count, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика маржинальности по отделам
   * @param {string} [departmentCode]
   * @param {string} [date] Дата отчета
   * @param {string} [branchOfficeCode]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMarginalityDepartments(departmentCode?: string, date?: string, branchOfficeCode?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statMarginalityDepartments(departmentCode, date, branchOfficeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика маржинальности по дивизионам
   * @param {string} [divisionCode] Код дивизиона
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMarginalityDivisions(divisionCode?: string, date?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statMarginalityDivisions(divisionCode, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика маржинальности по клиентам для менеджера
   * @param {string} [employeeCode] Код реализатора
   * @param {string} [customerCode]
   * @param {string} [branchOfficeCode] Код офиса
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMarginalityEmployeeCustomers(
    employeeCode?: string,
    customerCode?: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    date?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statMarginalityEmployeeCustomers(employeeCode, customerCode, branchOfficeCode, page, count, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Рейтинг сотрудников на основе общего показателя выполнения плана продаж
   * @param {string} [branchOfficeCode] Код офиса
   * @param {string} [date] Дата отчёта
   * @param {boolean} [allCompany] Запросить рейтинг по всей компании
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMarginalityEmployeeOverallRating(
    branchOfficeCode?: string,
    date?: string,
    allCompany?: boolean,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statMarginalityEmployeeOverallRating(branchOfficeCode, date, allCompany, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика маржинальности по сотрудникам
   * @param {string} [employeeCode]
   * @param {string} [branchOfficeCode]
   * @param {string} [date] Дата отчета
   * @param {string} [chiefEmployeeCode] Руководитель сотрудников
   * @param {string} [departmentCode] Отдел
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMarginalityEmployees(
    employeeCode?: string,
    branchOfficeCode?: string,
    date?: string,
    chiefEmployeeCode?: string,
    departmentCode?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statMarginalityEmployees(employeeCode, branchOfficeCode, date, chiefEmployeeCode, departmentCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика маржинальности по группе компаний
   * @param {string} [headCompanyCode] Код головной компании
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMarginalityHeadCompanies(headCompanyCode?: string, date?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statMarginalityHeadCompanies(headCompanyCode, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика маржинальности по офисам
   * @param {string} [branchOfficeCode] Код офиса
   * @param {string} [date] Дата отчета
   * @param {string} [divisionCode] Код дивизиона
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMarginalityOffices(branchOfficeCode?: string, date?: string, divisionCode?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statMarginalityOffices(branchOfficeCode, date, divisionCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Справочники для отображения страницы показателей маржинальности
   * @param {string} [employeeCode] Код выбранного сотрудника
   * @param {string} [departmentCode] Код выбранного отдела
   * @param {string} [branchOfficeCode] Код выбранного офиса
   * @param {string} [divisionCode] Код выбранного отдела
   * @param {string} [date] Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMarginalityReferences(
    employeeCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    divisionCode?: string,
    date?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statMarginalityReferences(employeeCode, departmentCode, branchOfficeCode, divisionCode, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Мотивация по клиентам
   * @param {string} date Период отчета
   * @param {string} [customerCode]
   * @param {string} [departmentCode] Код департамента
   * @param {string} [branchOfficeCode] Код офиса
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMotivationCustomers(
    date: string,
    customerCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statMotivationCustomers(date, customerCode, departmentCode, branchOfficeCode, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Мотивация по департаментам
   * @param {string} date Дата отчета
   * @param {string} [branchOfficeCode]
   * @param {string} [departmentCode] Код департамента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMotivationDepartments(date: string, branchOfficeCode?: string, departmentCode?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statMotivationDepartments(date, branchOfficeCode, departmentCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Мотивация по дивизионам
   * @param {string} divisionCode Код дивизиона
   * @param {string} date Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMotivationDivisions(divisionCode: string, date: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statMotivationDivisions(divisionCode, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Мотивация по сотрудникам
   * @param {string} date Дата отчета
   * @param {string} [departmentCode] Код департамента
   * @param {string} [employeeCode] Код сотрудника
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMotivationEmployees(date: string, departmentCode?: string, employeeCode?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statMotivationEmployees(date, departmentCode, employeeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Мотивация по клиентам сотрудника
   * @param {string} date Дата отчета
   * @param {string} [customerCode]
   * @param {string} [departmentCode] Код департамента
   * @param {string} [employeeCode] Код сотрудника
   * @param {string} [branchOfficeCode] Код офиса
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMotivationEmployeesCustomers(
    date: string,
    customerCode?: string,
    departmentCode?: string,
    employeeCode?: string,
    branchOfficeCode?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statMotivationEmployeesCustomers(date, customerCode, departmentCode, employeeCode, branchOfficeCode, page, count, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Мотивация по компании
   * @param {string} headCompanyCode Код головной компании
   * @param {string} date Дата отчета
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMotivationHeadCompanies(headCompanyCode: string, date: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statMotivationHeadCompanies(headCompanyCode, date, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Мотивация по филиалам
   * @param {string} date Дата отчета
   * @param {string} [branchOfficeCode] Код филиала
   * @param {string} [divisionCode] Код дивизиона
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statMotivationOffices(date: string, branchOfficeCode?: string, divisionCode?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statMotivationOffices(date, branchOfficeCode, divisionCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежам по отделам
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [chiefEmployeeCode] Руководитель сотрудников
   * @param {string} [departmentCode] Отдел
   * @param {string} [branchOfficeCode] Филиал
   * @param {boolean} [operative] Не смотреть на текущий период. Использовать оперативные привязки.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsDepartments(
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    chiefEmployeeCode?: string,
    departmentCode?: string,
    branchOfficeCode?: string,
    operative?: boolean,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statPaymentsDepartments(dateFrom, dateTo, from, to, chiefEmployeeCode, departmentCode, branchOfficeCode, operative, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежам по отделам (график)
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [chiefEmployeeCode] Руководитель сотрудников
   * @param {string} [departmentCode] Отдел
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsDepartmentsPlot(
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    chiefEmployeeCode?: string,
    departmentCode?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statPaymentsDepartmentsPlot(dateFrom, dateTo, from, to, chiefEmployeeCode, departmentCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежам по дивизионам
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [divisionCode] Дивизион
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsDivisions(
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    divisionCode?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statPaymentsDivisions(dateFrom, dateTo, from, to, divisionCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежам по дивизионам (график)
   * @param {string} divisionCode Дивизион
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsDivisionsPlot(
    divisionCode: string,
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statPaymentsDivisionsPlot(divisionCode, dateFrom, dateTo, from, to, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежам по сотрудникам
   * @param {string} [employeeCode]
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [chiefEmployeeCode] Руководитель сотрудников
   * @param {string} [departmentCode] Отдел
   * @param {boolean} [operative] Не смотреть на текущий период. Использовать оперативные привязки.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsEmployees(
    employeeCode?: string,
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    chiefEmployeeCode?: string,
    departmentCode?: string,
    operative?: boolean,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statPaymentsEmployees(employeeCode, dateFrom, dateTo, from, to, chiefEmployeeCode, departmentCode, operative, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежам по сотрудникам (график)
   * @param {string} employeeCode
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsEmployeesPlot(
    employeeCode: string,
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statPaymentsEmployeesPlot(employeeCode, dateFrom, dateTo, from, to, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежным ожиданиям
   * @param {string} [employeeCode]
   * @param {string} [customerCode]
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [chiefEmployeeCode] Руководитель сотрудников
   * @param {string} [departmentCode] Отдел
   * @param {number} [page]
   * @param {number} [count]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsExpectations(
    employeeCode?: string,
    customerCode?: string,
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    chiefEmployeeCode?: string,
    departmentCode?: string,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statPaymentsExpectations(
        employeeCode,
        customerCode,
        dateFrom,
        dateTo,
        from,
        to,
        chiefEmployeeCode,
        departmentCode,
        page,
        count,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежам по головным компаниям
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsHeadCompanies(dateFrom?: string, dateTo?: string, from?: string, to?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statPaymentsHeadCompanies(dateFrom, dateTo, from, to, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежам по головным компаниям (график)
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsHeadCompaniesPlot(dateFrom?: string, dateTo?: string, from?: string, to?: string, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statPaymentsHeadCompaniesPlot(dateFrom, dateTo, from, to, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежам по филиалам
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [divisionCode] Дивизион
   * @param {string} [branchOfficeCode] Филиал
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsOffices(
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    divisionCode?: string,
    branchOfficeCode?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statPaymentsOffices(dateFrom, dateTo, from, to, divisionCode, branchOfficeCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежам по филиалам (график)
   * @param {string} branchOfficeCode Филиал
   * @param {string} [dateFrom]
   * @param {string} [dateTo] Дата отчета
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsOfficesPlot(
    branchOfficeCode: string,
    dateFrom?: string,
    dateTo?: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig
  ) {
    return StatApiFp(this.configuration)
      .statPaymentsOfficesPlot(branchOfficeCode, dateFrom, dateTo, from, to, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Статистика по платежным ожиданиям (запланированным)
   * @param {StatPaymentsPlannedRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsPlanned(request: StatPaymentsPlannedRequest, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statPaymentsPlanned(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Отчёт по дебиторской задолженности (ДЗ)
   * @param {StatPaymentsReceivableReportRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsReceivableReport(request: StatPaymentsReceivableReportRequest, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statPaymentsReceivableReport(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Отчёт по дебиторской задолженности (ДЗ) в excel-файле
   * @param {StatPaymentsReceivableReportRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statPaymentsReceivableReportXlsx(request: StatPaymentsReceivableReportRequest, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statPaymentsReceivableReportXlsx(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Документы для Конверсионной и Периодической воронок продаж
   * @param {StatSaleFunnelDocsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statSaleFunnelDocs(request: StatSaleFunnelDocsRequest, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statSaleFunnelDocs(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Конверсионная и Периодическая воронки продаж
   * @param {StatSaleFunnelGraphsRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statSaleFunnelGraphs(request: StatSaleFunnelGraphsRequest, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statSaleFunnelGraphs(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Воронка продаж
   * @param {StatSaleFunnelReportRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StatApi
   */
  public statSaleFunnelReport(request: StatSaleFunnelReportRequest, options?: AxiosRequestConfig) {
    return StatApiFp(this.configuration)
      .statSaleFunnelReport(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    about: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/about`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список стран
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/countries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список валют
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currencies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/currencies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Формирование GUID\'а для нового объекта
     * @param {string} objectType
     * @param {string} frontCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    objectsPrepare: async (objectType: string, frontCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'objectType' is not null or undefined
      assertParamExists('objectsPrepare', 'objectType', objectType);
      // verify required parameter 'frontCode' is not null or undefined
      assertParamExists('objectsPrepare', 'frontCode', frontCode);
      const localVarPath = `/objects/prepare`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (objectType !== undefined) {
        localVarQueryParameter['objectType'] = objectType;
      }

      if (frontCode !== undefined) {
        localVarQueryParameter['frontCode'] = frontCode;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ObjectsPrepareRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    objectsPreparePost: async (request: ObjectsPrepareRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('objectsPreparePost', 'request', request);
      const localVarPath = `/objects/prepare`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} name Preset name for topic/msgType
     * @param {string} payload Message payload
     * @param {string} [key] Message key
     * @param {string} [traceid] Trace ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queueProducePresetItem: async (
      name: string,
      payload: string,
      key?: string,
      traceid?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists('queueProducePresetItem', 'name', name);
      // verify required parameter 'payload' is not null or undefined
      assertParamExists('queueProducePresetItem', 'payload', payload);
      const localVarPath = `/queue/produce/preset/{name}`.replace(`{${'name'}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (key !== undefined) {
        localVarQueryParameter['key'] = key;
      }

      if (traceid !== undefined) {
        localVarQueryParameter['traceid'] = traceid;
      }

      if (payload !== undefined) {
        localVarQueryParameter['payload'] = payload;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} name Preset name for topic/msgType
     * @param {string} [key] Message key
     * @param {string} [traceid] Trace ID
     * @param {string} [format] body format (raw, json)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queueProducePresetItemPost: async (
      name: string,
      key?: string,
      traceid?: string,
      format?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'name' is not null or undefined
      assertParamExists('queueProducePresetItemPost', 'name', name);
      const localVarPath = `/queue/produce/preset/{name}`.replace(`{${'name'}}`, encodeURIComponent(String(name)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (key !== undefined) {
        localVarQueryParameter['key'] = key;
      }

      if (traceid !== undefined) {
        localVarQueryParameter['traceid'] = traceid;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Пример ошибки
     * @param {string} [type] error preset name - 400, 404, 500
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemErrorExample: async (type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/system/error/example`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список ставок НДС
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vatRate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/vat/rate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async about(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.about(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список стран
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountriesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countries(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список валют
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async currencies(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrenciesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.currencies(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Формирование GUID\'а для нового объекта
     * @param {string} objectType
     * @param {string} frontCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async objectsPrepare(
      objectType: string,
      frontCode: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectsPrepareResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.objectsPrepare(objectType, frontCode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ObjectsPrepareRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async objectsPreparePost(
      request: ObjectsPrepareRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectsPrepareResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.objectsPreparePost(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} name Preset name for topic/msgType
     * @param {string} payload Message payload
     * @param {string} [key] Message key
     * @param {string} [traceid] Trace ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queueProducePresetItem(
      name: string,
      payload: string,
      key?: string,
      traceid?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueProducePresetItemResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.queueProducePresetItem(name, payload, key, traceid, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} name Preset name for topic/msgType
     * @param {string} [key] Message key
     * @param {string} [traceid] Trace ID
     * @param {string} [format] body format (raw, json)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async queueProducePresetItemPost(
      name: string,
      key?: string,
      traceid?: string,
      format?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueProducePresetItemResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.queueProducePresetItemPost(name, key, traceid, format, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Пример ошибки
     * @param {string} [type] error preset name - 400, 404, 500
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async systemErrorExample(
      type?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.systemErrorExample(type, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список ставок НДС
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async vatRate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VatRateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.vatRate(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SystemApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    about(options?: any): AxiosPromise<AboutResponse> {
      return localVarFp.about(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список стран
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countries(options?: any): AxiosPromise<CountriesResponse> {
      return localVarFp.countries(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список валют
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currencies(options?: any): AxiosPromise<CurrenciesResponse> {
      return localVarFp.currencies(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Формирование GUID\'а для нового объекта
     * @param {string} objectType
     * @param {string} frontCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    objectsPrepare(objectType: string, frontCode: string, options?: any): AxiosPromise<ObjectsPrepareResponse> {
      return localVarFp.objectsPrepare(objectType, frontCode, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ObjectsPrepareRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    objectsPreparePost(request: ObjectsPrepareRequest, options?: any): AxiosPromise<ObjectsPrepareResponse> {
      return localVarFp.objectsPreparePost(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} name Preset name for topic/msgType
     * @param {string} payload Message payload
     * @param {string} [key] Message key
     * @param {string} [traceid] Trace ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queueProducePresetItem(
      name: string,
      payload: string,
      key?: string,
      traceid?: string,
      options?: any
    ): AxiosPromise<QueueProducePresetItemResponse> {
      return localVarFp.queueProducePresetItem(name, payload, key, traceid, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} name Preset name for topic/msgType
     * @param {string} [key] Message key
     * @param {string} [traceid] Trace ID
     * @param {string} [format] body format (raw, json)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    queueProducePresetItemPost(
      name: string,
      key?: string,
      traceid?: string,
      format?: string,
      options?: any
    ): AxiosPromise<QueueProducePresetItemResponse> {
      return localVarFp.queueProducePresetItemPost(name, key, traceid, format, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Пример ошибки
     * @param {string} [type] error preset name - 400, 404, 500
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemErrorExample(type?: string, options?: any): AxiosPromise<void> {
      return localVarFp.systemErrorExample(type, options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список ставок НДС
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vatRate(options?: any): AxiosPromise<VatRateResponse> {
      return localVarFp.vatRate(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SystemApi - interface
 * @export
 * @interface SystemApi
 */
export interface SystemApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  about(options?: AxiosRequestConfig): AxiosPromise<AboutResponse>;

  /**
   * Возвращает список стран
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  countries(options?: AxiosRequestConfig): AxiosPromise<CountriesResponse>;

  /**
   * Возвращает список валют
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  currencies(options?: AxiosRequestConfig): AxiosPromise<CurrenciesResponse>;

  /**
   *
   * @summary Формирование GUID\'а для нового объекта
   * @param {string} objectType
   * @param {string} frontCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  objectsPrepare(objectType: string, frontCode: string, options?: AxiosRequestConfig): AxiosPromise<ObjectsPrepareResponse>;

  /**
   *
   * @param {ObjectsPrepareRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  objectsPreparePost(request: ObjectsPrepareRequest, options?: AxiosRequestConfig): AxiosPromise<ObjectsPrepareResponse>;

  /**
   *
   * @param {string} name Preset name for topic/msgType
   * @param {string} payload Message payload
   * @param {string} [key] Message key
   * @param {string} [traceid] Trace ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  queueProducePresetItem(
    name: string,
    payload: string,
    key?: string,
    traceid?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<QueueProducePresetItemResponse>;

  /**
   *
   * @param {string} name Preset name for topic/msgType
   * @param {string} [key] Message key
   * @param {string} [traceid] Trace ID
   * @param {string} [format] body format (raw, json)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  queueProducePresetItemPost(
    name: string,
    key?: string,
    traceid?: string,
    format?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<QueueProducePresetItemResponse>;

  /**
   *
   * @summary Пример ошибки
   * @param {string} [type] error preset name - 400, 404, 500
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  systemErrorExample(type?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * Возвращает список ставок НДС
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApiInterface
   */
  vatRate(options?: AxiosRequestConfig): AxiosPromise<VatRateResponse>;
}

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI implements SystemApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public about(options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .about(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список стран
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public countries(options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .countries(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список валют
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public currencies(options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .currencies(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Формирование GUID\'а для нового объекта
   * @param {string} objectType
   * @param {string} frontCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public objectsPrepare(objectType: string, frontCode: string, options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .objectsPrepare(objectType, frontCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ObjectsPrepareRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public objectsPreparePost(request: ObjectsPrepareRequest, options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .objectsPreparePost(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} name Preset name for topic/msgType
   * @param {string} payload Message payload
   * @param {string} [key] Message key
   * @param {string} [traceid] Trace ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public queueProducePresetItem(name: string, payload: string, key?: string, traceid?: string, options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .queueProducePresetItem(name, payload, key, traceid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} name Preset name for topic/msgType
   * @param {string} [key] Message key
   * @param {string} [traceid] Trace ID
   * @param {string} [format] body format (raw, json)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public queueProducePresetItemPost(name: string, key?: string, traceid?: string, format?: string, options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .queueProducePresetItemPost(name, key, traceid, format, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Пример ошибки
   * @param {string} [type] error preset name - 400, 404, 500
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public systemErrorExample(type?: string, options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .systemErrorExample(type, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список ставок НДС
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SystemApi
   */
  public vatRate(options?: AxiosRequestConfig) {
    return SystemApiFp(this.configuration)
      .vatRate(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Изменяет сортировку задачи
     * @summary Сортировка задачи
     * @param {string} taskCode Код задачи
     * @param {TaskChangePositionRequest} request Запрос на сортировку
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskChangePosition: async (
      taskCode: string,
      request: TaskChangePositionRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskCode' is not null or undefined
      assertParamExists('taskChangePosition', 'taskCode', taskCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('taskChangePosition', 'request', request);
      const localVarPath = `/tasks/{taskCode}/position`.replace(`{${'taskCode'}}`, encodeURIComponent(String(taskCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Создает новый комментарий
     * @summary Добавление комментария
     * @param {string} taskCode Код задачи
     * @param {TaskCommentCreateRequest} request Запрос на добавление комментария
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskCommentCreate: async (
      taskCode: string,
      request: TaskCommentCreateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskCode' is not null or undefined
      assertParamExists('taskCommentCreate', 'taskCode', taskCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('taskCommentCreate', 'request', request);
      const localVarPath = `/tasks/{taskCode}/comments`.replace(`{${'taskCode'}}`, encodeURIComponent(String(taskCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Изменяет комментарий
     * @summary Обновление комментария
     * @param {string} commentCode Код комментария
     * @param {TaskCommentUpdateRequest} request Запрос на обновление комментария
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskCommentUpdate: async (
      commentCode: string,
      request: TaskCommentUpdateRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'commentCode' is not null or undefined
      assertParamExists('taskCommentUpdate', 'commentCode', commentCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('taskCommentUpdate', 'request', request);
      const localVarPath = `/tasks/comments/{commentCode}/save`.replace(`{${'commentCode'}}`, encodeURIComponent(String(commentCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Создать задачу
     * @param {TaskCreateRequest} request Запрос на создание задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskCreate: async (request: TaskCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('taskCreate', 'request', request);
      const localVarPath = `/tasks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает список типов объектов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskEntityTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/tasks/entity/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает доступные фильтры задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskFilters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/tasks/filters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Изменяет наблюдателей задачи
     * @summary Добавление или удаление наблюдателей
     * @param {string} taskCode Код задачи
     * @param {TaskFollowersSaveRequest} request Запрос на изменение наблюдателей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskFollowersSave: async (
      taskCode: string,
      request: TaskFollowersSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'taskCode' is not null or undefined
      assertParamExists('taskFollowersSave', 'taskCode', taskCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('taskFollowersSave', 'request', request);
      const localVarPath = `/tasks/{taskCode}/followers/save`.replace(`{${'taskCode'}}`, encodeURIComponent(String(taskCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Обновляет параметры задачи если есть доступ
     * @summary Обновление задачи
     * @param {string} taskCode Код задачи
     * @param {TaskUpdateRequest} request Запрос на обновление задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskItemSave: async (taskCode: string, request: TaskUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'taskCode' is not null or undefined
      assertParamExists('taskItemSave', 'taskCode', taskCode);
      // verify required parameter 'request' is not null or undefined
      assertParamExists('taskItemSave', 'request', request);
      const localVarPath = `/tasks/{taskCode}/save`.replace(`{${'taskCode'}}`, encodeURIComponent(String(taskCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает доступные метки задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskLabels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/tasks/labels`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает доступные приоритеты задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskPriorities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/tasks/priorities`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Возвращает доступные статусы задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskStatuses: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/tasks/statuses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список задач
     * @param {string} [initiatorCode] Код создателя задачи
     * @param {string} [entityCode] Код сущности
     * @param {string} [entityTypeCode] Код типа сущности
     * @param {Array<string>} [executorCodes] Коды исполнителей задачи
     * @param {'all' | 'initiator' | 'executor' | 'follower'} [filterCode] Код фильтра
     * @param {string} [branchOfficeCode] Код филиала
     * @param {string} [deadlineFrom] Фильтр по сроку начиная с даты
     * @param {string} [deadlineTo] Фильтр по сроку до даты
     * @param {Array<string>} [statusCodes] Коды статуса задач
     * @param {Array<string>} [priorityCodes] Коды приоритета задач
     * @param {Array<string>} [labelCodes] Фильтр по меткам
     * @param {number} [readyExpireDays] Фильтр в днях для готовых или отмененных задач
     * @param {number} [page] Страница
     * @param {number} [count] Результатов на страницу
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasks: async (
      initiatorCode?: string,
      entityCode?: string,
      entityTypeCode?: string,
      executorCodes?: Array<string>,
      filterCode?: 'all' | 'initiator' | 'executor' | 'follower',
      branchOfficeCode?: string,
      deadlineFrom?: string,
      deadlineTo?: string,
      statusCodes?: Array<string>,
      priorityCodes?: Array<string>,
      labelCodes?: Array<string>,
      readyExpireDays?: number,
      page?: number,
      count?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/tasks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (initiatorCode !== undefined) {
        localVarQueryParameter['initiatorCode'] = initiatorCode;
      }

      if (entityCode !== undefined) {
        localVarQueryParameter['entityCode'] = entityCode;
      }

      if (entityTypeCode !== undefined) {
        localVarQueryParameter['entityTypeCode'] = entityTypeCode;
      }

      if (executorCodes) {
        localVarQueryParameter['executorCodes'] = executorCodes.join(COLLECTION_FORMATS.csv);
      }

      if (filterCode !== undefined) {
        localVarQueryParameter['filterCode'] = filterCode;
      }

      if (branchOfficeCode !== undefined) {
        localVarQueryParameter['branchOfficeCode'] = branchOfficeCode;
      }

      if (deadlineFrom !== undefined) {
        localVarQueryParameter['deadlineFrom'] =
          (deadlineFrom as any) instanceof Date ? (deadlineFrom as any).toISOString().substr(0, 10) : deadlineFrom;
      }

      if (deadlineTo !== undefined) {
        localVarQueryParameter['deadlineTo'] =
          (deadlineTo as any) instanceof Date ? (deadlineTo as any).toISOString().substr(0, 10) : deadlineTo;
      }

      if (statusCodes) {
        localVarQueryParameter['statusCodes'] = statusCodes.join(COLLECTION_FORMATS.csv);
      }

      if (priorityCodes) {
        localVarQueryParameter['priorityCodes'] = priorityCodes.join(COLLECTION_FORMATS.csv);
      }

      if (labelCodes) {
        localVarQueryParameter['labelCodes'] = labelCodes.join(COLLECTION_FORMATS.csv);
      }

      if (readyExpireDays !== undefined) {
        localVarQueryParameter['readyExpireDays'] = readyExpireDays;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration);
  return {
    /**
     * Изменяет сортировку задачи
     * @summary Сортировка задачи
     * @param {string} taskCode Код задачи
     * @param {TaskChangePositionRequest} request Запрос на сортировку
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskChangePosition(
      taskCode: string,
      request: TaskChangePositionRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskChangePositionResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskChangePosition(taskCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Создает новый комментарий
     * @summary Добавление комментария
     * @param {string} taskCode Код задачи
     * @param {TaskCommentCreateRequest} request Запрос на добавление комментария
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskCommentCreate(
      taskCode: string,
      request: TaskCommentCreateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskCommentCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskCommentCreate(taskCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Изменяет комментарий
     * @summary Обновление комментария
     * @param {string} commentCode Код комментария
     * @param {TaskCommentUpdateRequest} request Запрос на обновление комментария
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskCommentUpdate(
      commentCode: string,
      request: TaskCommentUpdateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskCommentUpdateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskCommentUpdate(commentCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Создать задачу
     * @param {TaskCreateRequest} request Запрос на создание задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskCreate(
      request: TaskCreateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskCreate(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает список типов объектов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskEntityTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskEntityTypesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskEntityTypes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает доступные фильтры задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskFilters(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskFiltersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskFilters(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Изменяет наблюдателей задачи
     * @summary Добавление или удаление наблюдателей
     * @param {string} taskCode Код задачи
     * @param {TaskFollowersSaveRequest} request Запрос на изменение наблюдателей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskFollowersSave(
      taskCode: string,
      request: TaskFollowersSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskFollowersSaveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskFollowersSave(taskCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Обновляет параметры задачи если есть доступ
     * @summary Обновление задачи
     * @param {string} taskCode Код задачи
     * @param {TaskUpdateRequest} request Запрос на обновление задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskItemSave(
      taskCode: string,
      request: TaskUpdateRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskUpdateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskItemSave(taskCode, request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает доступные метки задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskLabels(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskLabelsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskLabels(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает доступные приоритеты задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskPriorities(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskPrioritiesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskPriorities(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Возвращает доступные статусы задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskStatuses(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskStatusesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskStatuses(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список задач
     * @param {string} [initiatorCode] Код создателя задачи
     * @param {string} [entityCode] Код сущности
     * @param {string} [entityTypeCode] Код типа сущности
     * @param {Array<string>} [executorCodes] Коды исполнителей задачи
     * @param {'all' | 'initiator' | 'executor' | 'follower'} [filterCode] Код фильтра
     * @param {string} [branchOfficeCode] Код филиала
     * @param {string} [deadlineFrom] Фильтр по сроку начиная с даты
     * @param {string} [deadlineTo] Фильтр по сроку до даты
     * @param {Array<string>} [statusCodes] Коды статуса задач
     * @param {Array<string>} [priorityCodes] Коды приоритета задач
     * @param {Array<string>} [labelCodes] Фильтр по меткам
     * @param {number} [readyExpireDays] Фильтр в днях для готовых или отмененных задач
     * @param {number} [page] Страница
     * @param {number} [count] Результатов на страницу
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasks(
      initiatorCode?: string,
      entityCode?: string,
      entityTypeCode?: string,
      executorCodes?: Array<string>,
      filterCode?: 'all' | 'initiator' | 'executor' | 'follower',
      branchOfficeCode?: string,
      deadlineFrom?: string,
      deadlineTo?: string,
      statusCodes?: Array<string>,
      priorityCodes?: Array<string>,
      labelCodes?: Array<string>,
      readyExpireDays?: number,
      page?: number,
      count?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskListResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tasks(
        initiatorCode,
        entityCode,
        entityTypeCode,
        executorCodes,
        filterCode,
        branchOfficeCode,
        deadlineFrom,
        deadlineTo,
        statusCodes,
        priorityCodes,
        labelCodes,
        readyExpireDays,
        page,
        count,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TaskApiFp(configuration);
  return {
    /**
     * Изменяет сортировку задачи
     * @summary Сортировка задачи
     * @param {string} taskCode Код задачи
     * @param {TaskChangePositionRequest} request Запрос на сортировку
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskChangePosition(taskCode: string, request: TaskChangePositionRequest, options?: any): AxiosPromise<TaskChangePositionResponse> {
      return localVarFp.taskChangePosition(taskCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Создает новый комментарий
     * @summary Добавление комментария
     * @param {string} taskCode Код задачи
     * @param {TaskCommentCreateRequest} request Запрос на добавление комментария
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskCommentCreate(taskCode: string, request: TaskCommentCreateRequest, options?: any): AxiosPromise<TaskCommentCreateResponse> {
      return localVarFp.taskCommentCreate(taskCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Изменяет комментарий
     * @summary Обновление комментария
     * @param {string} commentCode Код комментария
     * @param {TaskCommentUpdateRequest} request Запрос на обновление комментария
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskCommentUpdate(commentCode: string, request: TaskCommentUpdateRequest, options?: any): AxiosPromise<TaskCommentUpdateResponse> {
      return localVarFp.taskCommentUpdate(commentCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Создать задачу
     * @param {TaskCreateRequest} request Запрос на создание задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskCreate(request: TaskCreateRequest, options?: any): AxiosPromise<TaskCreateResponse> {
      return localVarFp.taskCreate(request, options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает список типов объектов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskEntityTypes(options?: any): AxiosPromise<TaskEntityTypesResponse> {
      return localVarFp.taskEntityTypes(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает доступные фильтры задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskFilters(options?: any): AxiosPromise<TaskFiltersResponse> {
      return localVarFp.taskFilters(options).then(request => request(axios, basePath));
    },
    /**
     * Изменяет наблюдателей задачи
     * @summary Добавление или удаление наблюдателей
     * @param {string} taskCode Код задачи
     * @param {TaskFollowersSaveRequest} request Запрос на изменение наблюдателей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskFollowersSave(taskCode: string, request: TaskFollowersSaveRequest, options?: any): AxiosPromise<TaskFollowersSaveResponse> {
      return localVarFp.taskFollowersSave(taskCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Обновляет параметры задачи если есть доступ
     * @summary Обновление задачи
     * @param {string} taskCode Код задачи
     * @param {TaskUpdateRequest} request Запрос на обновление задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskItemSave(taskCode: string, request: TaskUpdateRequest, options?: any): AxiosPromise<TaskUpdateResponse> {
      return localVarFp.taskItemSave(taskCode, request, options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает доступные метки задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskLabels(options?: any): AxiosPromise<TaskLabelsResponse> {
      return localVarFp.taskLabels(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает доступные приоритеты задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskPriorities(options?: any): AxiosPromise<TaskPrioritiesResponse> {
      return localVarFp.taskPriorities(options).then(request => request(axios, basePath));
    },
    /**
     * Возвращает доступные статусы задачи
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskStatuses(options?: any): AxiosPromise<TaskStatusesResponse> {
      return localVarFp.taskStatuses(options).then(request => request(axios, basePath));
    },
    /**
     * Список задач
     * @param {string} [initiatorCode] Код создателя задачи
     * @param {string} [entityCode] Код сущности
     * @param {string} [entityTypeCode] Код типа сущности
     * @param {Array<string>} [executorCodes] Коды исполнителей задачи
     * @param {'all' | 'initiator' | 'executor' | 'follower'} [filterCode] Код фильтра
     * @param {string} [branchOfficeCode] Код филиала
     * @param {string} [deadlineFrom] Фильтр по сроку начиная с даты
     * @param {string} [deadlineTo] Фильтр по сроку до даты
     * @param {Array<string>} [statusCodes] Коды статуса задач
     * @param {Array<string>} [priorityCodes] Коды приоритета задач
     * @param {Array<string>} [labelCodes] Фильтр по меткам
     * @param {number} [readyExpireDays] Фильтр в днях для готовых или отмененных задач
     * @param {number} [page] Страница
     * @param {number} [count] Результатов на страницу
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasks(
      initiatorCode?: string,
      entityCode?: string,
      entityTypeCode?: string,
      executorCodes?: Array<string>,
      filterCode?: 'all' | 'initiator' | 'executor' | 'follower',
      branchOfficeCode?: string,
      deadlineFrom?: string,
      deadlineTo?: string,
      statusCodes?: Array<string>,
      priorityCodes?: Array<string>,
      labelCodes?: Array<string>,
      readyExpireDays?: number,
      page?: number,
      count?: number,
      options?: any
    ): AxiosPromise<TaskListResponse> {
      return localVarFp
        .tasks(
          initiatorCode,
          entityCode,
          entityTypeCode,
          executorCodes,
          filterCode,
          branchOfficeCode,
          deadlineFrom,
          deadlineTo,
          statusCodes,
          priorityCodes,
          labelCodes,
          readyExpireDays,
          page,
          count,
          options
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * TaskApi - interface
 * @export
 * @interface TaskApi
 */
export interface TaskApiInterface {
  /**
   * Изменяет сортировку задачи
   * @summary Сортировка задачи
   * @param {string} taskCode Код задачи
   * @param {TaskChangePositionRequest} request Запрос на сортировку
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskChangePosition(
    taskCode: string,
    request: TaskChangePositionRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<TaskChangePositionResponse>;

  /**
   * Создает новый комментарий
   * @summary Добавление комментария
   * @param {string} taskCode Код задачи
   * @param {TaskCommentCreateRequest} request Запрос на добавление комментария
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskCommentCreate(
    taskCode: string,
    request: TaskCommentCreateRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<TaskCommentCreateResponse>;

  /**
   * Изменяет комментарий
   * @summary Обновление комментария
   * @param {string} commentCode Код комментария
   * @param {TaskCommentUpdateRequest} request Запрос на обновление комментария
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskCommentUpdate(
    commentCode: string,
    request: TaskCommentUpdateRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<TaskCommentUpdateResponse>;

  /**
   * Создать задачу
   * @param {TaskCreateRequest} request Запрос на создание задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskCreate(request: TaskCreateRequest, options?: AxiosRequestConfig): AxiosPromise<TaskCreateResponse>;

  /**
   * Возвращает список типов объектов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskEntityTypes(options?: AxiosRequestConfig): AxiosPromise<TaskEntityTypesResponse>;

  /**
   * Возвращает доступные фильтры задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskFilters(options?: AxiosRequestConfig): AxiosPromise<TaskFiltersResponse>;

  /**
   * Изменяет наблюдателей задачи
   * @summary Добавление или удаление наблюдателей
   * @param {string} taskCode Код задачи
   * @param {TaskFollowersSaveRequest} request Запрос на изменение наблюдателей
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskFollowersSave(
    taskCode: string,
    request: TaskFollowersSaveRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<TaskFollowersSaveResponse>;

  /**
   * Обновляет параметры задачи если есть доступ
   * @summary Обновление задачи
   * @param {string} taskCode Код задачи
   * @param {TaskUpdateRequest} request Запрос на обновление задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskItemSave(taskCode: string, request: TaskUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<TaskUpdateResponse>;

  /**
   * Возвращает доступные метки задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskLabels(options?: AxiosRequestConfig): AxiosPromise<TaskLabelsResponse>;

  /**
   * Возвращает доступные приоритеты задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskPriorities(options?: AxiosRequestConfig): AxiosPromise<TaskPrioritiesResponse>;

  /**
   * Возвращает доступные статусы задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  taskStatuses(options?: AxiosRequestConfig): AxiosPromise<TaskStatusesResponse>;

  /**
   * Список задач
   * @param {string} [initiatorCode] Код создателя задачи
   * @param {string} [entityCode] Код сущности
   * @param {string} [entityTypeCode] Код типа сущности
   * @param {Array<string>} [executorCodes] Коды исполнителей задачи
   * @param {'all' | 'initiator' | 'executor' | 'follower'} [filterCode] Код фильтра
   * @param {string} [branchOfficeCode] Код филиала
   * @param {string} [deadlineFrom] Фильтр по сроку начиная с даты
   * @param {string} [deadlineTo] Фильтр по сроку до даты
   * @param {Array<string>} [statusCodes] Коды статуса задач
   * @param {Array<string>} [priorityCodes] Коды приоритета задач
   * @param {Array<string>} [labelCodes] Фильтр по меткам
   * @param {number} [readyExpireDays] Фильтр в днях для готовых или отмененных задач
   * @param {number} [page] Страница
   * @param {number} [count] Результатов на страницу
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApiInterface
   */
  tasks(
    initiatorCode?: string,
    entityCode?: string,
    entityTypeCode?: string,
    executorCodes?: Array<string>,
    filterCode?: 'all' | 'initiator' | 'executor' | 'follower',
    branchOfficeCode?: string,
    deadlineFrom?: string,
    deadlineTo?: string,
    statusCodes?: Array<string>,
    priorityCodes?: Array<string>,
    labelCodes?: Array<string>,
    readyExpireDays?: number,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ): AxiosPromise<TaskListResponse>;
}

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI implements TaskApiInterface {
  /**
   * Изменяет сортировку задачи
   * @summary Сортировка задачи
   * @param {string} taskCode Код задачи
   * @param {TaskChangePositionRequest} request Запрос на сортировку
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskChangePosition(taskCode: string, request: TaskChangePositionRequest, options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskChangePosition(taskCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Создает новый комментарий
   * @summary Добавление комментария
   * @param {string} taskCode Код задачи
   * @param {TaskCommentCreateRequest} request Запрос на добавление комментария
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskCommentCreate(taskCode: string, request: TaskCommentCreateRequest, options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskCommentCreate(taskCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Изменяет комментарий
   * @summary Обновление комментария
   * @param {string} commentCode Код комментария
   * @param {TaskCommentUpdateRequest} request Запрос на обновление комментария
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskCommentUpdate(commentCode: string, request: TaskCommentUpdateRequest, options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskCommentUpdate(commentCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Создать задачу
   * @param {TaskCreateRequest} request Запрос на создание задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskCreate(request: TaskCreateRequest, options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskCreate(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает список типов объектов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskEntityTypes(options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskEntityTypes(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает доступные фильтры задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskFilters(options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskFilters(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Изменяет наблюдателей задачи
   * @summary Добавление или удаление наблюдателей
   * @param {string} taskCode Код задачи
   * @param {TaskFollowersSaveRequest} request Запрос на изменение наблюдателей
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskFollowersSave(taskCode: string, request: TaskFollowersSaveRequest, options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskFollowersSave(taskCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Обновляет параметры задачи если есть доступ
   * @summary Обновление задачи
   * @param {string} taskCode Код задачи
   * @param {TaskUpdateRequest} request Запрос на обновление задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskItemSave(taskCode: string, request: TaskUpdateRequest, options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskItemSave(taskCode, request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает доступные метки задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskLabels(options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskLabels(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает доступные приоритеты задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskPriorities(options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskPriorities(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Возвращает доступные статусы задачи
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public taskStatuses(options?: AxiosRequestConfig) {
    return TaskApiFp(this.configuration)
      .taskStatuses(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список задач
   * @param {string} [initiatorCode] Код создателя задачи
   * @param {string} [entityCode] Код сущности
   * @param {string} [entityTypeCode] Код типа сущности
   * @param {Array<string>} [executorCodes] Коды исполнителей задачи
   * @param {'all' | 'initiator' | 'executor' | 'follower'} [filterCode] Код фильтра
   * @param {string} [branchOfficeCode] Код филиала
   * @param {string} [deadlineFrom] Фильтр по сроку начиная с даты
   * @param {string} [deadlineTo] Фильтр по сроку до даты
   * @param {Array<string>} [statusCodes] Коды статуса задач
   * @param {Array<string>} [priorityCodes] Коды приоритета задач
   * @param {Array<string>} [labelCodes] Фильтр по меткам
   * @param {number} [readyExpireDays] Фильтр в днях для готовых или отмененных задач
   * @param {number} [page] Страница
   * @param {number} [count] Результатов на страницу
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskApi
   */
  public tasks(
    initiatorCode?: string,
    entityCode?: string,
    entityTypeCode?: string,
    executorCodes?: Array<string>,
    filterCode?: 'all' | 'initiator' | 'executor' | 'follower',
    branchOfficeCode?: string,
    deadlineFrom?: string,
    deadlineTo?: string,
    statusCodes?: Array<string>,
    priorityCodes?: Array<string>,
    labelCodes?: Array<string>,
    readyExpireDays?: number,
    page?: number,
    count?: number,
    options?: AxiosRequestConfig
  ) {
    return TaskApiFp(this.configuration)
      .tasks(
        initiatorCode,
        entityCode,
        entityTypeCode,
        executorCodes,
        filterCode,
        branchOfficeCode,
        deadlineFrom,
        deadlineTo,
        statusCodes,
        priorityCodes,
        labelCodes,
        readyExpireDays,
        page,
        count,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Возвращает информацию о текущем пользователе
     * @param {string} [sup] Поддерживаемые особенности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userProfile: async (sup?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sup !== undefined) {
        localVarQueryParameter['sup'] = sup;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление профиля текущего пользователя
     * @param {UserProfileSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userProfileSave: async (request: UserProfileSaveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('userProfileSave', 'request', request);
      const localVarPath = `/user/profile/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Возвращает информацию о текущем пользователе
     * @param {string} [sup] Поддерживаемые особенности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userProfile(
      sup?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userProfile(sup, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Обновление профиля текущего пользователя
     * @param {UserProfileSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userProfileSave(
      request: UserProfileSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileSaveResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userProfileSave(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @summary Возвращает информацию о текущем пользователе
     * @param {string} [sup] Поддерживаемые особенности.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userProfile(sup?: string, options?: any): AxiosPromise<UserProfileResponse> {
      return localVarFp.userProfile(sup, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление профиля текущего пользователя
     * @param {UserProfileSaveRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userProfileSave(request: UserProfileSaveRequest, options?: any): AxiosPromise<UserProfileSaveResponse> {
      return localVarFp.userProfileSave(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UserApi - interface
 * @export
 * @interface UserApi
 */
export interface UserApiInterface {
  /**
   *
   * @summary Возвращает информацию о текущем пользователе
   * @param {string} [sup] Поддерживаемые особенности.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  userProfile(sup?: string, options?: AxiosRequestConfig): AxiosPromise<UserProfileResponse>;

  /**
   *
   * @summary Обновление профиля текущего пользователя
   * @param {UserProfileSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApiInterface
   */
  userProfileSave(request: UserProfileSaveRequest, options?: AxiosRequestConfig): AxiosPromise<UserProfileSaveResponse>;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI implements UserApiInterface {
  /**
   *
   * @summary Возвращает информацию о текущем пользователе
   * @param {string} [sup] Поддерживаемые особенности.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userProfile(sup?: string, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userProfile(sup, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление профиля текущего пользователя
   * @param {UserProfileSaveRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userProfileSave(request: UserProfileSaveRequest, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userProfileSave(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * WarehouseApi - axios parameter creator
 * @export
 */
export const WarehouseApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Список складов для фильтра
     * @param {string} documentType Тип документа (shipment, deal, agreement, bill, и т.д.)
     * @param {Array<string>} [requiredWarehouseCodes] Коды складов, обязательных к выводу
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {Array<string>} [batchCodes] Коды партий
     * @param {string} [billCode] Код счета
     * @param {string} [documentNumber] Номер документа
     * @param {string} [customerCode] Код клиента
     * @param {string} [dealCode] Код заявки на счет
     * @param {Array<string>} [paymentStateCodes] Коды статусов оплаты
     * @param {string} [shipmentTypeCode] Код типа отгрузки
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [queryDocuments] filter for documents
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [queryWarehouses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    warehousesForFilter: async (
      documentType: string,
      requiredWarehouseCodes?: Array<string>,
      branchOfficeCodes?: Array<string>,
      batchCodes?: Array<string>,
      billCode?: string,
      documentNumber?: string,
      customerCode?: string,
      dealCode?: string,
      paymentStateCodes?: Array<string>,
      shipmentTypeCode?: string,
      dateFrom?: string,
      dateTo?: string,
      employeeSet?: string,
      queryDocuments?: string,
      page?: number,
      count?: number,
      queryWarehouses?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentType' is not null or undefined
      assertParamExists('warehousesForFilter', 'documentType', documentType);
      const localVarPath = `/warehouses/forFilter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (requiredWarehouseCodes) {
        localVarQueryParameter['requiredWarehouseCodes'] = requiredWarehouseCodes.join(COLLECTION_FORMATS.csv);
      }

      if (branchOfficeCodes) {
        localVarQueryParameter['branchOfficeCodes'] = branchOfficeCodes.join(COLLECTION_FORMATS.csv);
      }

      if (batchCodes) {
        localVarQueryParameter['batchCodes'] = batchCodes.join(COLLECTION_FORMATS.csv);
      }

      if (billCode !== undefined) {
        localVarQueryParameter['billCode'] = billCode;
      }

      if (documentNumber !== undefined) {
        localVarQueryParameter['documentNumber'] = documentNumber;
      }

      if (customerCode !== undefined) {
        localVarQueryParameter['customerCode'] = customerCode;
      }

      if (dealCode !== undefined) {
        localVarQueryParameter['dealCode'] = dealCode;
      }

      if (paymentStateCodes) {
        localVarQueryParameter['paymentStateCodes'] = paymentStateCodes.join(COLLECTION_FORMATS.csv);
      }

      if (shipmentTypeCode !== undefined) {
        localVarQueryParameter['shipmentTypeCode'] = shipmentTypeCode;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString().substr(0, 10) : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] = (dateTo as any) instanceof Date ? (dateTo as any).toISOString().substr(0, 10) : dateTo;
      }

      if (employeeSet !== undefined) {
        localVarQueryParameter['employeeSet'] = employeeSet;
      }

      if (documentType !== undefined) {
        localVarQueryParameter['documentType'] = documentType;
      }

      if (queryDocuments !== undefined) {
        localVarQueryParameter['queryDocuments'] = queryDocuments;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (count !== undefined) {
        localVarQueryParameter['count'] = count;
      }

      if (queryWarehouses !== undefined) {
        localVarQueryParameter['queryWarehouses'] = queryWarehouses;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WarehouseApi - functional programming interface
 * @export
 */
export const WarehouseApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WarehouseApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Список складов для фильтра
     * @param {string} documentType Тип документа (shipment, deal, agreement, bill, и т.д.)
     * @param {Array<string>} [requiredWarehouseCodes] Коды складов, обязательных к выводу
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {Array<string>} [batchCodes] Коды партий
     * @param {string} [billCode] Код счета
     * @param {string} [documentNumber] Номер документа
     * @param {string} [customerCode] Код клиента
     * @param {string} [dealCode] Код заявки на счет
     * @param {Array<string>} [paymentStateCodes] Коды статусов оплаты
     * @param {string} [shipmentTypeCode] Код типа отгрузки
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [queryDocuments] filter for documents
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [queryWarehouses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async warehousesForFilter(
      documentType: string,
      requiredWarehouseCodes?: Array<string>,
      branchOfficeCodes?: Array<string>,
      batchCodes?: Array<string>,
      billCode?: string,
      documentNumber?: string,
      customerCode?: string,
      dealCode?: string,
      paymentStateCodes?: Array<string>,
      shipmentTypeCode?: string,
      dateFrom?: string,
      dateTo?: string,
      employeeSet?: string,
      queryDocuments?: string,
      page?: number,
      count?: number,
      queryWarehouses?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarehousesForFilterResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.warehousesForFilter(
        documentType,
        requiredWarehouseCodes,
        branchOfficeCodes,
        batchCodes,
        billCode,
        documentNumber,
        customerCode,
        dealCode,
        paymentStateCodes,
        shipmentTypeCode,
        dateFrom,
        dateTo,
        employeeSet,
        queryDocuments,
        page,
        count,
        queryWarehouses,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WarehouseApi - factory interface
 * @export
 */
export const WarehouseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = WarehouseApiFp(configuration);
  return {
    /**
     *
     * @summary Список складов для фильтра
     * @param {string} documentType Тип документа (shipment, deal, agreement, bill, и т.д.)
     * @param {Array<string>} [requiredWarehouseCodes] Коды складов, обязательных к выводу
     * @param {Array<string>} [branchOfficeCodes] Коды филиалов
     * @param {Array<string>} [batchCodes] Коды партий
     * @param {string} [billCode] Код счета
     * @param {string} [documentNumber] Номер документа
     * @param {string} [customerCode] Код клиента
     * @param {string} [dealCode] Код заявки на счет
     * @param {Array<string>} [paymentStateCodes] Коды статусов оплаты
     * @param {string} [shipmentTypeCode] Код типа отгрузки
     * @param {string} [dateFrom] Дата от
     * @param {string} [dateTo] Дата до
     * @param {string} [employeeSet] Код сотрудника или группы
     * @param {string} [queryDocuments] filter for documents
     * @param {number} [page]
     * @param {number} [count]
     * @param {string} [queryWarehouses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    warehousesForFilter(
      documentType: string,
      requiredWarehouseCodes?: Array<string>,
      branchOfficeCodes?: Array<string>,
      batchCodes?: Array<string>,
      billCode?: string,
      documentNumber?: string,
      customerCode?: string,
      dealCode?: string,
      paymentStateCodes?: Array<string>,
      shipmentTypeCode?: string,
      dateFrom?: string,
      dateTo?: string,
      employeeSet?: string,
      queryDocuments?: string,
      page?: number,
      count?: number,
      queryWarehouses?: string,
      options?: any
    ): AxiosPromise<WarehousesForFilterResponse> {
      return localVarFp
        .warehousesForFilter(
          documentType,
          requiredWarehouseCodes,
          branchOfficeCodes,
          batchCodes,
          billCode,
          documentNumber,
          customerCode,
          dealCode,
          paymentStateCodes,
          shipmentTypeCode,
          dateFrom,
          dateTo,
          employeeSet,
          queryDocuments,
          page,
          count,
          queryWarehouses,
          options
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * WarehouseApi - interface
 * @export
 * @interface WarehouseApi
 */
export interface WarehouseApiInterface {
  /**
   *
   * @summary Список складов для фильтра
   * @param {string} documentType Тип документа (shipment, deal, agreement, bill, и т.д.)
   * @param {Array<string>} [requiredWarehouseCodes] Коды складов, обязательных к выводу
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {Array<string>} [batchCodes] Коды партий
   * @param {string} [billCode] Код счета
   * @param {string} [documentNumber] Номер документа
   * @param {string} [customerCode] Код клиента
   * @param {string} [dealCode] Код заявки на счет
   * @param {Array<string>} [paymentStateCodes] Коды статусов оплаты
   * @param {string} [shipmentTypeCode] Код типа отгрузки
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [queryDocuments] filter for documents
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [queryWarehouses]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseApiInterface
   */
  warehousesForFilter(
    documentType: string,
    requiredWarehouseCodes?: Array<string>,
    branchOfficeCodes?: Array<string>,
    batchCodes?: Array<string>,
    billCode?: string,
    documentNumber?: string,
    customerCode?: string,
    dealCode?: string,
    paymentStateCodes?: Array<string>,
    shipmentTypeCode?: string,
    dateFrom?: string,
    dateTo?: string,
    employeeSet?: string,
    queryDocuments?: string,
    page?: number,
    count?: number,
    queryWarehouses?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<WarehousesForFilterResponse>;
}

/**
 * WarehouseApi - object-oriented interface
 * @export
 * @class WarehouseApi
 * @extends {BaseAPI}
 */
export class WarehouseApi extends BaseAPI implements WarehouseApiInterface {
  /**
   *
   * @summary Список складов для фильтра
   * @param {string} documentType Тип документа (shipment, deal, agreement, bill, и т.д.)
   * @param {Array<string>} [requiredWarehouseCodes] Коды складов, обязательных к выводу
   * @param {Array<string>} [branchOfficeCodes] Коды филиалов
   * @param {Array<string>} [batchCodes] Коды партий
   * @param {string} [billCode] Код счета
   * @param {string} [documentNumber] Номер документа
   * @param {string} [customerCode] Код клиента
   * @param {string} [dealCode] Код заявки на счет
   * @param {Array<string>} [paymentStateCodes] Коды статусов оплаты
   * @param {string} [shipmentTypeCode] Код типа отгрузки
   * @param {string} [dateFrom] Дата от
   * @param {string} [dateTo] Дата до
   * @param {string} [employeeSet] Код сотрудника или группы
   * @param {string} [queryDocuments] filter for documents
   * @param {number} [page]
   * @param {number} [count]
   * @param {string} [queryWarehouses]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseApi
   */
  public warehousesForFilter(
    documentType: string,
    requiredWarehouseCodes?: Array<string>,
    branchOfficeCodes?: Array<string>,
    batchCodes?: Array<string>,
    billCode?: string,
    documentNumber?: string,
    customerCode?: string,
    dealCode?: string,
    paymentStateCodes?: Array<string>,
    shipmentTypeCode?: string,
    dateFrom?: string,
    dateTo?: string,
    employeeSet?: string,
    queryDocuments?: string,
    page?: number,
    count?: number,
    queryWarehouses?: string,
    options?: AxiosRequestConfig
  ) {
    return WarehouseApiFp(this.configuration)
      .warehousesForFilter(
        documentType,
        requiredWarehouseCodes,
        branchOfficeCodes,
        batchCodes,
        billCode,
        documentNumber,
        customerCode,
        dealCode,
        paymentStateCodes,
        shipmentTypeCode,
        dateFrom,
        dateTo,
        employeeSet,
        queryDocuments,
        page,
        count,
        queryWarehouses,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * WsApi - axios parameter creator
 * @export
 */
export const WsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Запрос синхронизации состояния кредита (с 1С)
     * @param {WsClientsCreditStateSyncRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wsClientsCreditStateSync: async (request: WsClientsCreditStateSyncRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('wsClientsCreditStateSync', 'request', request);
      const localVarPath = `/ws/clients/credit/state/sync`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Используется для описания структуры сообщений веб-сокетов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wsDefaultIn: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/ws/default/in`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {WsDeviceAuthTokenRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wsDeviceAuthToken: async (request: WsDeviceAuthTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'request' is not null or undefined
      assertParamExists('wsDeviceAuthToken', 'request', request);
      const localVarPath = `/ws/device/auth/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WsApi - functional programming interface
 * @export
 */
export const WsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Запрос синхронизации состояния кредита (с 1С)
     * @param {WsClientsCreditStateSyncRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wsClientsCreditStateSync(
      request: WsClientsCreditStateSyncRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WsClientsCreditStateSyncResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wsClientsCreditStateSync(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Используется для описания структуры сообщений веб-сокетов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wsDefaultIn(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WsDefaultInResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wsDefaultIn(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {WsDeviceAuthTokenRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wsDeviceAuthToken(
      request: WsDeviceAuthTokenRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WsDeviceAuthTokenResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wsDeviceAuthToken(request, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WsApi - factory interface
 * @export
 */
export const WsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = WsApiFp(configuration);
  return {
    /**
     *
     * @summary Запрос синхронизации состояния кредита (с 1С)
     * @param {WsClientsCreditStateSyncRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wsClientsCreditStateSync(request: WsClientsCreditStateSyncRequest, options?: any): AxiosPromise<WsClientsCreditStateSyncResponse> {
      return localVarFp.wsClientsCreditStateSync(request, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Используется для описания структуры сообщений веб-сокетов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wsDefaultIn(options?: any): AxiosPromise<WsDefaultInResponse> {
      return localVarFp.wsDefaultIn(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {WsDeviceAuthTokenRequest} request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wsDeviceAuthToken(request: WsDeviceAuthTokenRequest, options?: any): AxiosPromise<WsDeviceAuthTokenResponse> {
      return localVarFp.wsDeviceAuthToken(request, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * WsApi - interface
 * @export
 * @interface WsApi
 */
export interface WsApiInterface {
  /**
   *
   * @summary Запрос синхронизации состояния кредита (с 1С)
   * @param {WsClientsCreditStateSyncRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WsApiInterface
   */
  wsClientsCreditStateSync(
    request: WsClientsCreditStateSyncRequest,
    options?: AxiosRequestConfig
  ): AxiosPromise<WsClientsCreditStateSyncResponse>;

  /**
   *
   * @summary Используется для описания структуры сообщений веб-сокетов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WsApiInterface
   */
  wsDefaultIn(options?: AxiosRequestConfig): AxiosPromise<WsDefaultInResponse>;

  /**
   *
   * @param {WsDeviceAuthTokenRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WsApiInterface
   */
  wsDeviceAuthToken(request: WsDeviceAuthTokenRequest, options?: AxiosRequestConfig): AxiosPromise<WsDeviceAuthTokenResponse>;
}

/**
 * WsApi - object-oriented interface
 * @export
 * @class WsApi
 * @extends {BaseAPI}
 */
export class WsApi extends BaseAPI implements WsApiInterface {
  /**
   *
   * @summary Запрос синхронизации состояния кредита (с 1С)
   * @param {WsClientsCreditStateSyncRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WsApi
   */
  public wsClientsCreditStateSync(request: WsClientsCreditStateSyncRequest, options?: AxiosRequestConfig) {
    return WsApiFp(this.configuration)
      .wsClientsCreditStateSync(request, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Используется для описания структуры сообщений веб-сокетов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WsApi
   */
  public wsDefaultIn(options?: AxiosRequestConfig) {
    return WsApiFp(this.configuration)
      .wsDefaultIn(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {WsDeviceAuthTokenRequest} request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WsApi
   */
  public wsDeviceAuthToken(request: WsDeviceAuthTokenRequest, options?: AxiosRequestConfig) {
    return WsApiFp(this.configuration)
      .wsDeviceAuthToken(request, options)
      .then(request => request(this.axios, this.basePath));
  }
}
