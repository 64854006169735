import React, { FC, JSX, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DoughnutChart } from '../charts/DoughnutChart';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import { EmployeeMarginalityReport } from 'src/api/marketx';
import { EmployeesMarginalityStore } from 'src/store/EmployeesMarginalityStore';
import { formatNumber3, formatNumberSpacing, formatPriceCur, roundNumber } from '@mx-ui/helpers';
import PieChartIcon from './PieChartIcon';
import { MxSlider, GradientColorAndVal, Link as MxLink } from '@mx-ui/ui';
import useMediaQuery from '@mui/material/useMediaQuery';

export const calculatePersent = (fact = 0, plan = 0, isRound = false): number => {
  if (Number(plan) === 0 && Number(fact) !== 0) {
    return 100;
  }

  const percent = (fact / plan) * 100;
  if (!isFinite(percent)) {
    return 0;
  }
  return isRound ? Math.round(percent) : percent;
};

interface ChartsBlockProps {
  employeesMarginality: EmployeesMarginalityStore;
  budgetGroupsStats: EmployeeMarginalityReport;
  isLink?: boolean;
  isMotivationBlockOpen?: boolean;
}
type AnchorIdConst = 'count' | 'marginality' | 'motivation' | '';
const colorsByValue = (value: number): GradientColorAndVal[] => {
  const gradientColors = [];
  if (value < 100) {
    gradientColors.push({ color: 'rgba(255,106,0,1)', value: 100 - Math.round((value * 100) / 70) }); // orange
  } else {
    gradientColors.push({ color: 'rgba(255,106,0,1)', value: 0 }); // orange
  }
  if (value >= 100) {
    gradientColors.push({ color: 'rgba(255,248,0,1)', value: 60 }); // yellow
    gradientColors.push({ color: 'rgba(109, 230, 74, 1)', value: 100 }); // green
  } else {
    gradientColors.push({ color: 'rgba(255,248,0,1)', value: 100 }); // yellow
  }
  return [...gradientColors];
};
const formatValueCur = (value = 0): string => {
  const val = String(Math.round(parseFloat('' + value) * 100) / 100 || 0);
  return val.length <= 9 ? formatPriceCur(val) : formatNumberSpacing(val) + ` ₽`;
};
const formatValueTon = (value = 0): string => {
  const val = String(Math.round(parseFloat('' + value) * 1000) / 1000 || 0);
  return val.length <= 9 ? formatNumber3(val) : formatNumberSpacing(val);
};
export const ChartsBlock: FC<ChartsBlockProps> = observer(
  // eslint-disable-next-line complexity
  ({ budgetGroupsStats: stats, employeesMarginality, isLink = false, isMotivationBlockOpen = false }): JSX.Element => {
    const [anchorElementId, setAnchorElementId] = useState<AnchorIdConst>('');

    const { report, motivationReport } = stats || {};
    const {
      totalCompletionPct,
      dynamicScaleThresholds,
      marginCompletionScore,
      marginCompletionPct,
      quantityCompletionPct,
      quantityCompletionScore,
      marginCompletionFinalPct,
      quantityCompletionFinalPct,
    } = motivationReport ?? {};
    const { overallForecast, marginalityForecast, quantityForecast, overallWeight } = report || {};

    const { level: overallForecastLevel, value: overallForecastValue } = overallForecast || {};
    const { level: marginalityForecastLevel, value: marginalityForecastValue } = marginalityForecast || {};
    const { level: quantityForecastLevel, value: quantityForecastValue } = quantityForecast || {};
    const { margin: overallWeightMargin, quantity: overallWeightQuantity } = overallWeight || {};

    const overallAchievementPercent = Math.round(report?.overallAchievement ?? 0);
    const quantityAchievementPercent = Math.round(report?.quantityAchievement ?? 0);
    const marginalityAchievementPercent = Math.round(report?.marginalityAchievement ?? 0);
    const motivationTotalCompletionPercent = typeof totalCompletionPct === 'number' ? Math.round(totalCompletionPct) : undefined;

    const marks = useMemo(() => dynamicScaleThresholds?.map(i => ({ value: i, label: String(i) })), [dynamicScaleThresholds]);

    const marginCompletionPctV = Math.round(marginCompletionPct ?? 0);
    const quantityCompletionPctV = Math.round(quantityCompletionPct ?? 0);

    const gradientColorAndValForMarginality = useMemo(() => colorsByValue(marginCompletionPctV), [marginCompletionPctV]);
    const gradientColorAndValForQuantity = useMemo(() => colorsByValue(quantityCompletionPctV), [quantityCompletionPctV]);

    const marginCompletionScoreV = Math.round(marginCompletionScore ?? 0);
    const quantityCompletionScoreV = Math.round(quantityCompletionScore ?? 0);

    const handlePopoverOpen = (anchorId: AnchorIdConst): void => {
      setAnchorElementId(anchorId);
    };
    const handlePopoverClose = (): void => {
      setAnchorElementId('');
    };
    const isCountPopoverHidden = useMemo(() => anchorElementId === 'count', [anchorElementId]);
    const isMarginalityPopoverHidden = useMemo(() => anchorElementId === 'marginality', [anchorElementId]);
    // TODO временное скрытие виджета ПРЕМИИ
    // const motivationAccess = motivationAccessFunc(authStore);
    const motivationAccess = false;
    const motivationPopoverAsses = isMotivationBlockOpen && motivationAccess && employeesMarginality.employeeMotivationItem;

    const isMotivationPopoverHidden = useMemo(() => anchorElementId === 'motivation', [anchorElementId]);
    const isScreen1200px = useMediaQuery('(min-width:1200px)');

    return (
      <>
        <Grid container>
          <Grid
            item
            md={motivationPopoverAsses ? 2.4 : 6}
            sm={motivationPopoverAsses ? 4 : 6}
            xs={12}
            sx={{ minHeight: { xs: 200, sm: 206 }, position: 'relative' }}
          >
            <Paper
              sx={{
                padding: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 'fit-content',
                width: { xs: '100%', sm: 'calc(100% - 8px)' },
                position: 'absolute',
                zIndex: 1101,
              }}
              onMouseEnter={() => handlePopoverOpen('count')}
              onMouseLeave={handlePopoverClose}
            >
              <Grid>
                <Box sx={{ textAlign: 'left' }}>
                  {isLink ? (
                    <MxLink href="/app/report/targets" variant="h6" color="inherit">
                      Количество
                    </MxLink>
                  ) : (
                    <Typography variant="h6" component="span">
                      Количество
                    </Typography>
                  )}
                </Box>

                <Grid>
                  <Box
                    sx={{
                      position: 'relative',
                      margin: '0 auto',
                      maxWidth: '120px',
                      height: '100%',
                      maxHeight: '120px',
                      padding: 1,
                    }}
                  >
                    <DoughnutChart value={!employeesMarginality.isLoading ? quantityAchievementPercent : 0} />
                  </Box>
                  <Grid container justifyContent="center">
                    {quantityForecastLevel && <CircleIcon sx={{ color: quantityForecastLevel }} />}
                    <Typography sx={{ marginLeft: '4px' }} variant="body1" gutterBottom>
                      Тренд {quantityForecastValue ? Math.round(quantityForecastValue) : 0}%
                    </Typography>
                  </Grid>
                </Grid>
                <Collapse in={isCountPopoverHidden}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography variant="body2">План</Typography>
                    </Grid>
                    <Grid item xs={8} textAlign="right" color={report?.planQuantity < 0 ? 'red' : undefined}>
                      <Typography variant="body2" noWrap>
                        {formatValueTon(report?.planQuantity)}&nbsp;т
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body2">Факт</Typography>
                    </Grid>
                    <Grid item xs={8} textAlign="right" color={report?.planQuantity < 0 ? 'red' : undefined}>
                      <Typography variant="body2" noWrap>
                        {formatValueTon(report?.factQuantity)}&nbsp;т
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box borderBottom={'1px solid rgba(224,224,224,1)'} mt={0.5} mb={1} />
                  <Grid container flexDirection="column">
                    <Box sx={{ marginLeft: 1 / 2, marginRight: 1 / 2, paddingTop: 1.5, paddingBottom: 1 }} display="flex">
                      {motivationReport && (
                        <MxSlider
                          value={quantityCompletionPctV}
                          disabled
                          // valueLabelDisplay="on"
                          valueLabelFormat={() => `${quantityCompletionPctV}%`}
                          gradientColorAndVal={gradientColorAndValForQuantity}
                          marks={marks}
                          min={0}
                          max={150}
                        />
                      )}
                      {motivationReport && (
                        <Typography variant="body2" sx={{ marginLeft: 0.5, paddingTop: 1 }}>{`${quantityCompletionScoreV}%`}</Typography>
                      )}
                    </Box>

                    <Grid
                      container
                      justifyContent={motivationReport ? 'space-between' : 'flex-end'}
                      sx={{ marginLeft: 0.5, paddingRight: 0.5 }}
                    >
                      {motivationReport && (
                        <Typography variant="body1">
                          Оценка {quantityCompletionFinalPct ? Math.round(quantityCompletionFinalPct) : 0}%
                        </Typography>
                      )}
                      <Typography variant="body1">Вес {overallWeightQuantity ? Math.round(100 * overallWeightQuantity) : 0}%</Typography>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Paper>
          </Grid>
          <Grid
            item
            md={motivationPopoverAsses ? 2.4 : 6}
            sm={motivationPopoverAsses ? 4 : 6}
            xs={12}
            sx={{ minHeight: { xs: 200, sm: 206 }, position: 'relative' }}
            onMouseEnter={() => handlePopoverOpen('marginality')}
            onMouseLeave={handlePopoverClose}
          >
            <Paper
              sx={{
                padding: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 'fit-content',
                width: { xs: '100%', sm: 'calc(100% - 8px)' },
                ml: { xs: 0, sm: isScreen1200px ? 0 : 1 },
                position: 'absolute',
                zIndex: 1100,
              }}
            >
              <Box>
                <Box sx={{ textAlign: 'left' }}>
                  {isLink ? (
                    <MxLink href="/app/report/targets" variant="h6" color="inherit">
                      МаржДоход
                    </MxLink>
                  ) : (
                    <Typography variant="h6" gutterBottom component="span">
                      МаржДоход
                    </Typography>
                  )}
                </Box>
                <Grid>
                  <Box sx={{ position: 'relative', margin: '0 auto', maxWidth: '120px', maxHeight: '120px', padding: 1 }}>
                    <DoughnutChart value={!employeesMarginality.isLoading ? marginalityAchievementPercent : 0} textColor />
                  </Box>
                </Grid>
                <Grid container justifyContent="center">
                  {marginalityForecastLevel && <CircleIcon sx={{ color: marginalityForecastLevel }} />}
                  <Typography sx={{ marginLeft: '4px' }} variant="body1" gutterBottom>
                    Тренд {marginalityForecastValue ? Math.round(marginalityForecastValue) : 0}%
                  </Typography>
                </Grid>
              </Box>
              <Collapse in={isMarginalityPopoverHidden}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="body2">План</Typography>
                  </Grid>
                  <Grid item xs={8} textAlign="right">
                    <Typography variant="body2" noWrap>
                      {formatValueCur(report?.planMarginality)}
                    </Typography>
                  </Grid>

                  {report?.factIlliquidDiscountSum > 0 ? (
                    <>
                      <Grid item xs={4}>
                        <Box
                          sx={{
                            borderRadius: '3px',
                            fontSize: 16,
                            verticalAlign: 'middle',
                            fontFamily: 'Arial, Helvetica, sans-serif',
                            backgroundColor: '#F3A023',
                            display: 'inline-block',
                            width: 40,
                            height: 20,
                            textAlign: 'center',
                            lineHeight: '20px',
                          }}
                        >
                          <Box title="Некондиция" display="flex" justifyContent="center" sx={{ color: '#f6eeee' }}>
                            НК
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={8} textAlign="right">
                        <Typography variant="body2" noWrap>
                          {formatValueCur(report?.factIlliquidDiscountSum)}
                        </Typography>
                      </Grid>
                    </>
                  ) : undefined}

                  {report?.factTodhDiscountSum > 0 ? (
                    <>
                      <Grid item xs={4}>
                        <Box
                          sx={{
                            borderRadius: '3px',
                            fontSize: 16,
                            verticalAlign: 'middle',
                            fontFamily: 'Arial, Helvetica, sans-serif',
                            backgroundColor: '#B71C1C',
                            display: 'inline-block',
                            width: 40,
                            height: 20,
                            textAlign: 'center',
                            lineHeight: '20px',
                          }}
                        >
                          <Box title="Неликвид" display="flex" justifyContent="center" sx={{ color: '#f6eeee' }}>
                            НЛ
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={8} textAlign="right">
                        <Typography variant="body2" noWrap>
                          {formatValueCur(report?.factTodhDiscountSum)}
                        </Typography>
                      </Grid>
                    </>
                  ) : undefined}

                  {report?.factSelfPurchaseAwardSum > 0 ? (
                    <>
                      <Grid item xs={4}>
                        <Box
                          sx={{
                            borderRadius: '3px',
                            fontSize: 16,
                            verticalAlign: 'middle',
                            fontFamily: 'Arial, Helvetica, sans-serif',
                            backgroundColor: '#00a2e8',
                            display: 'inline-block',
                            width: 40,
                            height: 20,
                            textAlign: 'center',
                            lineHeight: '20px',
                          }}
                        >
                          <Box title="Самозакуп" display="flex" justifyContent="center" sx={{ color: '#f6eeee' }}>
                            СЗ
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={8} textAlign="right">
                        <Typography variant="body2" noWrap>
                          {formatValueCur(report?.factSelfPurchaseAwardSum)}
                        </Typography>
                      </Grid>
                    </>
                  ) : undefined}

                  <Grid item xs={4} color={report?.factMarginality < 0 ? 'red' : undefined}>
                    <Typography variant="body2">Факт</Typography>
                  </Grid>
                  <Grid item xs={8} textAlign="right" color={report?.factMarginality < 0 ? 'red' : undefined}>
                    <Typography variant="body2" noWrap>
                      {formatValueCur(report?.factMarginality)}
                    </Typography>
                  </Grid>
                </Grid>
                <Box borderBottom={'1px solid rgba(224,224,224,1)'} mt={0.5} />
                <Box display="flex" flexDirection={'column'}>
                  <Box sx={{ marginLeft: 1 / 2, marginRight: 1 / 2, paddingTop: 1.5, paddingBottom: 1 }} display="flex">
                    {motivationReport && (
                      <MxSlider
                        value={marginCompletionPctV}
                        disabled
                        // valueLabelDisplay="on"
                        valueLabelFormat={() => `${marginCompletionPctV}%`}
                        gradientColorAndVal={gradientColorAndValForMarginality}
                        marks={marks}
                        min={0}
                        max={150}
                      />
                    )}
                    {motivationReport && (
                      <Typography variant="body2" sx={{ marginLeft: 0.5, paddingTop: 1 }}>{`${marginCompletionScoreV}%`}</Typography>
                    )}
                  </Box>

                  <Box
                    display={'flex'}
                    justifyContent={motivationReport ? 'space-between' : 'flex-end'}
                    sx={{ marginLeft: 0.5, marginRight: 0.5 }}
                  >
                    {motivationReport && (
                      <Typography variant="body1">Оценка {marginCompletionFinalPct ? Math.round(marginCompletionFinalPct) : 0}%</Typography>
                    )}
                    <Typography variant="body1">Вес {overallWeightMargin ? Math.round(100 * overallWeightMargin) : 0}%</Typography>
                  </Box>
                </Box>
              </Collapse>
            </Paper>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={motivationPopoverAsses ? 2.4 : 6} sm={motivationPopoverAsses ? 4 : 6} xs={12}>
            <Paper sx={{ padding: 1, height: 'fit-content', maxHeight: '200px', width: { xs: '100%', sm: 'calc(100% - 8px)' } }}>
              <Box sx={{ textAlign: 'left' }}>
                {isLink ? (
                  <MxLink href="/app/report/targets" variant="h6" color="inherit">
                    План
                  </MxLink>
                ) : (
                  <Typography variant="h6" gutterBottom component="div">
                    План
                  </Typography>
                )}
              </Box>
              <Grid container alignItems="center" justifyContent="center" flexDirection="column">
                <Grid item sx={{ position: 'relative', margin: '0 auto', maxWidth: '120px', maxHeight: '120px', padding: 1 }}>
                  <DoughnutChart value={!employeesMarginality.isLoading ? overallAchievementPercent : 0} />
                </Grid>
                <Grid item>
                  <Grid container>
                    {overallForecastLevel && <CircleIcon sx={{ color: overallForecastLevel }} />}
                    <Typography sx={{ marginLeft: '4px' }} variant="body1" gutterBottom>
                      Тренд {overallForecastValue ? Math.round(overallForecastValue) : 0}%
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={motivationPopoverAsses ? 2.4 : 6} sm={motivationPopoverAsses ? 4 : 6} xs={12}>
            <Paper
              sx={{
                padding: 1,
                height: '100%',
                maxHeight: '200px',
                width: { xs: '100%', sm: 'calc(100% - 8px)' },
                ml: { xs: 0, sm: isScreen1200px ? 0 : 1 },
                my: {
                  xs: 1,
                  sm: 0,
                },
              }}
            >
              <Box sx={{ textAlign: 'left' }}>
                {isLink ? (
                  <MxLink href="/app/report/targets" variant="h6" color="inherit">
                    Оценка работы
                  </MxLink>
                ) : (
                  <Typography variant="h6" gutterBottom component="div">
                    Оценка работы
                  </Typography>
                )}
              </Box>
              <Grid container alignItems="center" justifyContent="center" flexDirection="column">
                <Grid item sx={{ paddingTop: 2 }}>
                  <Typography sx={{ marginLeft: '4px', fontSize: '40px' }} variant="body1" gutterBottom color="#66BB6A" fontSize="28px">
                    {roundNumber(motivationTotalCompletionPercent)}%
                  </Typography>
                </Grid>
                <Grid item container>
                  <Grid item container justifyContent="space-between" alignItems="flex-start" mb={1}>
                    <span>Кол-во</span>
                    <Box display="flex" justifyContent="flex-end" flex="0 1 50%">
                      <span title="Оценка (Количество)" style={{ fontWeight: 'bold', paddingRight: '15px ' }}>
                        {quantityCompletionFinalPct ? Math.round(quantityCompletionFinalPct) : 0}%
                      </span>
                      <PieChartIcon width="25px" height="24px" />
                      <span title="Вес (Количество)" style={{ paddingLeft: '3px' }}>
                        {overallWeightQuantity ? Math.round(100 * overallWeightQuantity) : 0}%
                      </span>
                    </Box>
                  </Grid>
                  <Grid item container justifyContent="space-between" alignItems="center">
                    <span>МД</span>
                    <Box display="flex" justifyContent="flex-end" flex="0 1 50%">
                      <span title="Оценка (маржинальный доход)" style={{ fontWeight: 'bold', paddingRight: '15px' }}>
                        {marginCompletionFinalPct ? Math.round(marginCompletionFinalPct) : 0}%
                      </span>
                      <PieChartIcon width="25px" height="25px" />
                      <span title="Вес (маржинальный доход)" style={{ paddingLeft: '3px' }}>
                        {overallWeightMargin ? Math.round(100 * overallWeightMargin) : 0}%
                      </span>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {motivationPopoverAsses && (
          <Grid item md={motivationPopoverAsses ? 2.4 : 6} sm={6} xs={12} sx={{ minHeight: '206px', position: 'relative' }}>
            <Paper
              sx={{
                padding: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '100%',
                width: '100%',
                position: 'absolute',
                zIndex: 1101,
              }}
              onMouseEnter={() => handlePopoverOpen('motivation')}
              onMouseLeave={handlePopoverClose}
            >
              <Grid height={'190px'}>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography variant="h6" component="span">
                    Премия
                  </Typography>
                </Box>
                <Grid item sx={{ paddingTop: 5 }} container justifyContent={'center'}>
                  <Typography sx={{ marginLeft: '4px', fontSize: '30px' }} variant="body1" gutterBottom color="#66BB6A" fontSize="28px">
                    {formatValueCur(employeesMarginality.employeeMotivationItem.totalAmount)}
                  </Typography>
                </Grid>
                <Grid></Grid>
              </Grid>
              <Collapse in={isMotivationPopoverHidden}>
                <Grid container>
                  <Grid item container xs={12} flexWrap={'nowrap'} justifyContent={'space-between'}>
                    <Grid item>
                      <Typography variant="body2">Премия П1</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" noWrap>
                        {formatValueCur(employeesMarginality.employeeMotivationItem.p1amount)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} flexWrap={'nowrap'} justifyContent={'space-between'}>
                    <Grid item>
                      <Typography variant="body2">Премия П2</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" noWrap>
                        {formatValueCur(employeesMarginality.employeeMotivationItem.p2amount)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} flexWrap={'nowrap'} justifyContent={'space-between'}>
                    <Grid item>
                      <Typography variant="body2">Премия БСС</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" noWrap>
                        {formatValueCur(employeesMarginality.employeeMotivationItem.selfPurchaseAmount)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} flexWrap={'nowrap'} justifyContent={'space-between'}>
                    <Grid item>
                      <Typography variant="body2">Премия БСЦ</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" noWrap>
                        {formatValueCur(employeesMarginality.employeeMotivationItem.centralPurchaseAmount)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
            </Paper>
          </Grid>
        )}
      </>
    );
  }
);
