import React, { CSSProperties, FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import { ReservesStore } from '../../../store/ReservesStore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid, Skeleton } from '@mui/material';
import { formatNumberByUnit, formatPriceCur, formatYmdHiLZ } from '@mx-ui/helpers';
import { ProductItemReserve } from '../../../api/marketx';
import { ThreadLink } from '../../Bills/ThreadLink';
import { billStateColor, paymentStateColor } from '../../Bills/BillListItem';
import { MxLabel } from '@mx-ui/ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@mui/material/styles';
import HdrAutoOutlinedIcon from '@mui/icons-material/HdrAutoOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import { Link as MxLink } from '@mx-ui/ui';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';

export const boxGridStyle = {
  display: 'grid',
  minWidth: '950px',
  gridTemplateColumns: 'repeat(9, 1fr)',
  gridGap: '4px',
  padding: '8px 0',
};
const infiniteScrollStyle = { overflow: 'visible' } as CSSProperties;
const infiniteScrollLoader = <Skeleton variant="rectangular" style={{ margin: '10px' }} />;
export const ReserveDetailsV2Skeleton: FC = (): JSX.Element => {
  const matches = useMediaQuery('(min-width:865px)');
  const colTemplate = matches ? { gridTemplateColumns: 'repeat(6, 1fr)' } : { gridTemplateColumns: 'repeat(1, 1fr)' };
  return (
    <>
      {[1].map(item => {
        return (
          <Box sx={{ ...boxGridStyle, ...colTemplate }} key={item} mb={2}>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)` }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  Дата резерва
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)` }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  Резерв
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)` }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  Статус резерва
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)` }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  Цена с услугами
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)` }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  Статус оплаты
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
            <Box display="grid" sx={{ gridTemplateColumns: `repeat(${matches ? 1 : 2}, 1fr)` }}>
              <Box>
                <Typography fontSize={'12px'} color={'text.secondary'}>
                  Склад
                </Typography>
              </Box>
              <Box>
                <Skeleton />
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
interface ReserveDetailsV2Props {
  reserveStore: ReservesStore;
  withSkeleton?: boolean;
  isForSoftReserve?: boolean;
  selectedWarehouse?: string;
  productCode?: string;
  documentNumber?: string;
}

export const ReserveDetailsV2: FC<ReserveDetailsV2Props> = observer(
  ({ reserveStore, withSkeleton = false, productCode, documentNumber, isForSoftReserve }): JSX.Element => {
    let scrollableTargetId = document.getElementById(`${productCode}TabSelectWrapperScrollableReserveContainer`)
      ? `${productCode}TabSelectWrapperScrollableReserveContainer`
      : `${productCode}ScrollableReserveContainer`;
    if (reserveStore.nomenclatureCodes?.length) {
      scrollableTargetId = `${productCode}NomenclatureScrollableReserveContainer`;
    }

    if (!reserveStore.reserves?.length && !reserveStore.isLoading) {
      return (
        <Box p={2}>
          <Typography variant="body2">
            {isForSoftReserve ? 'Очереди резервирования по данному товару нет' : 'Резервов по данному товару нет'}
          </Typography>
        </Box>
      );
    }
    if (reserveStore.isLoading && withSkeleton && !reserveStore.reserves?.length) {
      return <ReserveDetailsV2Skeleton />;
    }

    return (
      <InfiniteScroll
        dataLength={reserveStore.reserves.length}
        next={() => reserveStore.loadMoreReserve()}
        hasMore={reserveStore.hasMore}
        style={infiniteScrollStyle}
        loader={infiniteScrollLoader}
        scrollableTarget={scrollableTargetId}
        scrollThreshold={0.7}
      >
        {reserveStore.reserves.map(reserve => {
          return <ReserveDetailsItemV2 reserve={reserve} key={reserve.code} documentNumber={documentNumber} />;
        })}
      </InfiniteScroll>
    );
  }
);

interface ReserveDetailsItemV2Props {
  reserve: ProductItemReserve;
  selectedWarehouse?: string;
  documentNumber?: string;
  forReservePage?: boolean;
}

export const ReserveDetailsItemV2: FC<ReserveDetailsItemV2Props> = observer(
  // eslint-disable-next-line complexity
  ({ reserve, selectedWarehouse, documentNumber, forReservePage = false }): JSX.Element => {
    const matches = useMediaQuery('(min-width:865px)');
    const theme = useTheme();
    const isSelectedWarehouse = selectedWarehouse ? reserve.warehouseCode === selectedWarehouse : true;
    const colorText = isSelectedWarehouse ? 'textPrimary' : 'textSecondary';
    const fontWeight = selectedWarehouse && isSelectedWarehouse ? 500 : undefined;
    const backgroundColor =
      (documentNumber === reserve.billDocumentNumber && !!reserve.billDocumentNumber && !!documentNumber) ||
      (documentNumber === reserve.freezeDocumentNumber && !!reserve.freezeDocumentNumber && !!documentNumber)
        ? theme.palette.mode === 'dark'
          ? '#121212'
          : '#F5F5F5'
        : 'background.paper';
    const titleHeight = '40px';
    return (
      <>
        {matches ? (
          <Box mb={2} key={reserve.code} sx={{ minWidth: matches ? '950px' : '300px' }}>
            <Grid container alignItems="center" pl={1} sx={{ backgroundColor: backgroundColor }}>
              {!!reserve.billDocumentNumber ? (
                <Grid item pr={1}>
                  <Grid container alignItems="center">
                    <Grid item pr={1} height={'24px'}>
                      <CreditCardOutlinedIcon />
                    </Grid>
                    <Grid item>
                      <MxLink
                        href={`/app/bills/${reserve.billDocumentNumber}`}
                        variant="body2"
                        color={colorText}
                        typographySx={{ fontWeight: documentNumber === reserve.billDocumentNumber ? 600 : fontWeight }}
                      >
                        {reserve.billDocumentNumber}
                      </MxLink>
                    </Grid>
                    <Grid item>
                      <ThreadLink link={`/app/thread/bills/${encodeURIComponent(reserve.billDocumentNumber)}`} />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {!!reserve.freezeDocumentNumber ? (
                <Grid item pr={1}>
                  <Grid container alignItems="center">
                    <Grid item pr={1} height={'24px'}>
                      <AcUnitOutlinedIcon />
                    </Grid>
                    <Grid item>
                      <MxLink
                        href={`/app/freeze/${reserve.freezeDocumentNumber}`}
                        variant="body2"
                        color={colorText}
                        typographySx={{ fontWeight: documentNumber === reserve.freezeDocumentNumber ? 600 : fontWeight }}
                      >
                        {reserve.freezeDocumentNumber}
                      </MxLink>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {!!reserve.documentNumber && !reserve.billDocumentNumber && !reserve.freezeDocumentNumber ? (
                <Grid item pr={1}>
                  <Grid container alignItems="center">
                    <Grid item pr={1} height={'24px'}>
                      <QuizOutlinedIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">{reserve.documentNumber}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {(reserve.dealEmployeeSurname || reserve.dealEmployeeName || reserve.dealEmployeePatronymic) && (
                <Grid item pr={1}>
                  <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                    {`${reserve.dealEmployeeSurname || ''} ${reserve.dealEmployeeName || ''} ${
                      reserve.dealEmployeePatronymic || ''
                    }`.trim()}
                  </Typography>
                </Grid>
              )}
              {reserve.autoReserveEnabled && (
                <Grid item height={'20px'} pr={1} title={'Авторезерв'}>
                  <HdrAutoOutlinedIcon fontSize={'small'} />
                </Grid>
              )}
              {reserve?.billStateCode ? (
                <Grid item pr={1}>
                  <Grid container alignItems="center">
                    <Grid item pr={1}>
                      <Typography fontSize={'12px'} color={'text.secondary'}>
                        Статус
                      </Typography>
                    </Grid>
                    <Grid item>
                      <MxLabel color={billStateColor(reserve?.billStateCode)} style={{ fontSize: '10px' }}>
                        {reserve?.billStateTitle}
                      </MxLabel>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {reserve?.freezeDocumentNumber ? (
                <Grid item pr={1}>
                  <Grid container alignItems="center">
                    <Grid item pr={1}>
                      <Typography fontSize={'12px'} color={'text.secondary'}>
                        Статус
                      </Typography>
                    </Grid>
                    <Grid item>
                      <MxLabel color={'success'} style={{ fontSize: '10px' }}>
                        Резерв подтвержден
                      </MxLabel>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {reserve?.billPaymentStateCode ? (
                <Grid item pr={1}>
                  <Grid container alignItems="center">
                    <Grid item pr={1}>
                      <Typography fontSize={'12px'} color={'text.secondary'}>
                        Статус оплаты
                      </Typography>
                    </Grid>
                    <Grid item>
                      <MxLabel color={paymentStateColor(reserve?.billPaymentStateCode)} style={{ fontSize: '10px' }}>
                        {reserve.billPaymentStateTitle}
                      </MxLabel>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              {reserve?.customerCode ? (
                <Grid item xs={12} container pr={1}>
                  <MxLink
                    href={`/app/clients/${reserve?.customerCode}`}
                    variant="body2"
                    color={colorText}
                    typographySx={{ fontWeight: fontWeight }}
                  >
                    {reserve.customerShort || reserve.customerTitle || '-'}
                  </MxLink>
                </Grid>
              ) : (
                (reserve.customerShort || reserve.customerTitle) && (
                  <Grid item xs={12} container pr={1}>
                    <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                      {reserve.customerShort || reserve.customerTitle}
                    </Typography>
                  </Grid>
                )
              )}
            </Grid>
            <Box sx={boxGridStyle}>
              <Box display="grid">
                <Box minHeight={titleHeight}>
                  <Typography fontSize={'12px'} color={'text.secondary'}>
                    Дата резерва
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
                    {reserve.reservedAt ? formatYmdHiLZ(reserve.reservedAt) : '-'}
                  </Typography>
                </Box>
              </Box>
              <Box display="grid">
                <Box minHeight={titleHeight}>
                  <Typography fontSize={'12px'} color={'text.secondary'}>
                    Количество
                  </Typography>
                </Box>
                <Box>
                  {
                    <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
                      {reserve.quantity > 0 && reserve.quantity < reserve.plannedQuantity
                        ? `${formatNumberByUnit(reserve.quantity, reserve.unitCode, reserve.unit || 'т')} из ${formatNumberByUnit(
                            reserve.plannedQuantity,
                            reserve.unitCode,
                            reserve.unit || 'т'
                          )}`
                        : reserve.plannedQuantity
                        ? formatNumberByUnit(reserve.plannedQuantity, reserve.unitCode, reserve.unit || 'т')
                        : reserve.quantity
                        ? formatNumberByUnit(reserve.quantity, reserve.unitCode, reserve.unit || 'т')
                        : '-'}
                    </Typography>
                  }
                </Box>
              </Box>
              <Box display="grid">
                <Box minHeight={titleHeight}>
                  <Typography fontSize={'12px'} color={'text.secondary'}>
                    Дней резерва
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
                    {reserve.reserveDays || ''}
                  </Typography>
                </Box>
              </Box>
              <Box display="grid">
                <Box minHeight={titleHeight}>
                  <Typography fontSize={'12px'} color={'text.secondary'}>
                    Стоимость в ЦЗ
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
                    {reserve.purchaseTotalCost ? formatPriceCur(reserve.purchaseTotalCost) : ''}
                  </Typography>
                </Box>
              </Box>
              <Box display="grid">
                <Box minHeight={titleHeight}>
                  <Typography fontSize={'12px'} color={'text.secondary'}>
                    Стоимость в МРЦ
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
                    {reserve.mrcTotalCost ? formatPriceCur(reserve.mrcTotalCost) : ''}
                  </Typography>
                </Box>
              </Box>
              <Box display="grid">
                <Box minHeight={titleHeight}>
                  <Typography fontSize={'12px'} color={'text.secondary'}>
                    Стоимость в цене с услугами
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
                    {reserve.productTotalCost ? formatPriceCur(reserve.productTotalCost) : ''}
                  </Typography>
                </Box>
              </Box>
              <Box display="grid">
                <Box minHeight={titleHeight}>
                  <Typography fontSize={'12px'} color={'text.secondary'}>
                    Цена с услугами
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
                    {reserve.productCost ? formatPriceCur(reserve.productCost) : ''}
                  </Typography>
                </Box>
              </Box>
              <Box display="grid">
                <Box minHeight={titleHeight}>
                  <Typography fontSize={'12px'} color={'text.secondary'}>
                    Статус резерва
                  </Typography>
                </Box>
                <Box>
                  {reserve?.stateCode ? (
                    <MxLabel color={paymentStateColor(reserve?.stateCode)} style={{ fontSize: '10px' }}>
                      {reserve.stateTitle}
                    </MxLabel>
                  ) : (
                    <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
                      {reserve.stateTitle || ''}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box display="grid">
                <Box minHeight={titleHeight}>
                  <Typography fontSize={'12px'} color={'text.secondary'}>
                    Склад
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="caption" color={colorText} fontWeight={fontWeight}>
                    {reserve.warehouseName}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Accordion
            key={reserve.code}
            sx={{
              margin: '0px !important',
              boxShadow: forReservePage ? 'none' : '0px 2px 1px -1px rgb(0 0 0 / 20%)',
              ':before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderTop: forReservePage ? '' : '1px solid #DBDBDB' }}>
              <Grid container alignItems="center" pl={1} sx={{ backgroundColor: backgroundColor }}>
                {!!reserve.billDocumentNumber ? (
                  <Grid item pr={1}>
                    <Grid container alignItems="center">
                      <Grid item pr={1} height={'24px'}>
                        <CreditCardOutlinedIcon />
                      </Grid>
                      <Grid item>
                        <MxLink
                          href={`/app/bills/${reserve.billDocumentNumber}`}
                          variant="body2"
                          color={colorText}
                          typographySx={{ fontWeight: documentNumber === reserve.billDocumentNumber ? 600 : fontWeight }}
                        >
                          {reserve.billDocumentNumber || '-'}
                        </MxLink>
                      </Grid>
                      <Grid item>
                        <ThreadLink link={`/app/thread/bills/${encodeURIComponent(reserve.billDocumentNumber)}`} />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {!!reserve.freezeDocumentNumber ? (
                  <Grid item pr={1}>
                    <Grid container alignItems="center">
                      <Grid item pr={1} height={'24px'}>
                        <AcUnitOutlinedIcon />
                      </Grid>
                      <Grid item>
                        <MxLink
                          href={`/app/freeze/${reserve.freezeDocumentNumber}`}
                          variant="body2"
                          color={colorText}
                          typographySx={{ fontWeight: documentNumber === reserve.freezeDocumentNumber ? 600 : fontWeight }}
                        >
                          {reserve.freezeDocumentNumber || '-'}
                        </MxLink>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {!!reserve.documentNumber && !reserve.billDocumentNumber && !reserve.freezeDocumentNumber ? (
                  <Grid item pr={1}>
                    <Grid container alignItems="center">
                      <Grid item pr={1} height={'24px'}>
                        <QuizOutlinedIcon />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{reserve.documentNumber}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {(reserve.dealEmployeeSurname || reserve.dealEmployeeName || reserve.dealEmployeePatronymic) && (
                  <Grid item pr={1}>
                    <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                      {`${reserve.dealEmployeeSurname || ''} ${reserve.dealEmployeeName || ''} ${
                        reserve.dealEmployeePatronymic || ''
                      }`.trim()}
                    </Typography>
                  </Grid>
                )}
                {reserve.autoReserveEnabled && (
                  <Grid item height={'20px'} pr={1} title={'Авторезерв'}>
                    <HdrAutoOutlinedIcon fontSize={'small'} />
                  </Grid>
                )}
                {reserve.typeCode && (
                  <Grid item>
                    <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                      ({reserve.typeDescription})
                    </Typography>
                  </Grid>
                )}
                {reserve?.customerCode ? (
                  <Grid item xs={12} container pr={1}>
                    <MxLink
                      href={`/app/clients/${reserve?.customerCode}`}
                      variant="body2"
                      color={colorText}
                      typographySx={{ fontWeight: fontWeight }}
                    >
                      {reserve.customerShort || reserve.customerTitle || '-'}
                    </MxLink>
                  </Grid>
                ) : (
                  (reserve.customerShort || reserve.customerTitle) && (
                    <Grid item xs={12} container pr={1}>
                      <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                        {reserve.customerShort || reserve.customerTitle}
                      </Typography>
                    </Grid>
                  )
                )}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Divider />
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}> Дата резерва</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                        {reserve.reservedAt ? formatYmdHiLZ(reserve.reservedAt) : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>Количество</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {reserve.quantity > 0 && reserve.quantity < reserve.plannedQuantity
                        ? `${formatNumberByUnit(reserve.quantity, reserve.unitCode, reserve.unit || 'т')} из ${formatNumberByUnit(
                            reserve.plannedQuantity,
                            reserve.unitCode,
                            reserve.unit || 'т'
                          )}`
                        : reserve.plannedQuantity
                        ? formatNumberByUnit(reserve.plannedQuantity, reserve.unitCode, reserve.unit || 'т')
                        : reserve.quantity
                        ? formatNumberByUnit(reserve.quantity, reserve.unitCode, reserve.unit || 'т')
                        : '-'}
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>Дней резерва</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                        {reserve.reserveDays || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>Стоимость в ЦЗ</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                        {reserve.purchaseTotalCost ? formatPriceCur(reserve.purchaseTotalCost) : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>Стоимость в МРЦ</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                        {reserve.mrcTotalCost ? formatPriceCur(reserve.mrcTotalCost) : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>Стоимость в цене с услугами</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                        {reserve.productTotalCost ? formatPriceCur(reserve.productTotalCost) : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>Цена с услугами</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                        {reserve.productCost ? formatPriceCur(reserve.productCost) : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>Статус резерва</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {reserve?.stateCode ? (
                        <MxLabel color={paymentStateColor(reserve?.stateCode)} style={{ fontSize: '10px' }}>
                          {reserve.stateTitle}
                        </MxLabel>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>Статус оплаты</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {reserve?.billPaymentStateCode ? (
                        <MxLabel color={paymentStateColor(reserve?.billPaymentStateCode)} style={{ fontSize: '10px' }}>
                          {reserve.billPaymentStateTitle}
                        </MxLabel>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>Склад</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                        {reserve.warehouseName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  }
);
