import hash from 'object-hash';
import { makeAutoObservable, observable, onBecomeUnobserved, runInAction } from 'mobx';
import { AppDeal, AppDealApproval, AppDealDistributor, AppDealPosition, AppDealPositionUpdates, AppDealUpdates } from '../slices/AppDeal';
import {
  CatalogProductsFastSearchResponse,
  ContractCostAgreement,
  ContractCostPosition,
  Deal,
  DealApprovalStateEnum,
  DealDocument,
  DealPartners,
  DealPositionComment,
  DealsCreateRequest,
  DealsCreateResponse,
  DealsItemApprovalConfirmRequest,
  DealsItemApprovalDeclineRequest,
  DealsItemHistoryResponse,
  DealsItemProlongResponse,
  DealsItemResponse,
  DealsItemSaveRequest,
  DealsItemSaveResponse,
  DealUpdateLogChange,
  DealUpdates,
  DealUpdatesPosition,
  DocumentNotice,
  DocumentNoticeTypeEnum,
  ObjectsPrepareRequest,
  ProductItemPack,
} from '../api/marketx';
import { AxiosPromise, AxiosResponse } from 'axios';
import { buildHash, mapDeal, mapDealUpdates, mapNewPosition, mapPositionCodes } from '../slices/AppDeal/lib';
import { AxiosCallContext, getCallContext } from '../utils/axiosInit';
import { RootStore } from './StoreManager';
import { ProductsNormalType } from '../slices/AppCatalog';
import { v4 as uuidv4 } from 'uuid';
import { formatNumber02, formatPriceCur, toFloat } from '@mx-ui/helpers';
import { DeliveryTypeListStore } from './DeliveryTypeListStore';
import { WarehouseListStore } from './WarehouseListStore';
import { SnackbarStore } from './SnackbarStore';
import { formatDateTimeLZ } from '@mx-ui/helpers';
import { MsgType, WebSocketStore } from './Global/WebSocketStore';
import { DealDistributorListStore } from './Deals/DealDistributorListStore';
import { changeDealByWs } from './DealListStore';
import { ApiStore } from './Global/ApiStore';
import {
  DealApprovalAction,
  DealApprovalActionType,
  DealApprovalState,
  DealApprovalStateType,
  ErrorForButton,
  MenuItemsApprovalIdsType,
} from 'src/components/Deals/ApproveResolutionControl';
import { isOfflineError } from '../utils/network';
import { castInput, ValueStore, ValueStoreInputTypes, ValueStoreInputTypesType } from './ValueStore';
import { entityType, TopBarEntityStore } from './TopBarStore';
import { PositionsManufacturersListStore } from './Deals/PositionsManufacturersListStore';
import { setClear } from 'src/utils/mobx';
import { ApprovalHistoryStore, DealUpdateLogChangeExtended, DocumentApprovalHistoryWithUpdates } from './ApprovalHistoryStore';
import { DealPaymentStore } from './Deals/DealPaymentStore';
import { IncomeMessage } from './Global/EventManager';
import { CreditStateStore } from './Clients/CreditStateStore';
import { BillListStore } from './Documents/BillListStore';
import { ShipmentsListStore } from './Documents/ShipmentsListStore';
import { RouterStore } from './RouterStore';
import { ReservesStore } from './ReservesStore';
import { AgreementItemStore } from './Clients/AgreementItemStore';
import { ErrorDocuments } from './Documents/types';
import { DealPartnersStore } from './DealPartnersStore';

type ActionType = 'save' | 'upload' | 'deleteFiles' | 'movePosition';

export type UpdateQueueItem = {
  createdAt: Date; // Время внесения изменения
  updatedAt: Date; // Время последнего изменения (если будет реализован мерж)
  hash: string; // хэш объекта изменений
  updates: AppDealUpdates;
  actionType: ActionType;
  files?: File[];
  codes?: string[];
  positionCode?: string;
  documentTypeCode?: string;
  properties: string;
  onResolve: (UpdateQueueItem) => void;
  onReject: (UpdateQueueItem) => void;
};

const transformDealChanges = (dealStore: DealItemStore, logs: DealUpdateLogChange[] = []): DealUpdateLogChangeExtended[] => {
  const logsNew = [];
  const deliveryTypesStore = dealStore.getDeliveryTypesStore();

  logs.forEach(log => {
    const newLog: DealUpdateLogChangeExtended = {
      changedEntity: '',
      changeEntityProperty: '',
      changeValue: '',
    };
    newLog.initiatorEmployee = log.initiatorEmployee;
    newLog.createdAt = log.createdAt;
    newLog.userGroupCode = log.userGroupCode;
    if (log.deliveryTypeCode) {
      newLog.changeEntityProperty = 'Изменение типа доставки';
      const itemCurrent = (deliveryTypesStore?.items || []).find(i => i.code === log.deliveryTypeCode);
      const itemOld = (deliveryTypesStore?.items || []).find(i => i.code === log.oldDeliveryTypeCode);
      if (itemOld && itemCurrent) {
        newLog.changeValue = `${itemOld.name}>${itemCurrent.name}`;
        logsNew.push({ ...newLog });
      } else {
        console.warn('deliveryTypes не подгружен');
      }
    }
    if (log.deliveryCost !== null && log.deliveryCost !== undefined) {
      newLog.changeEntityProperty = 'Изменение цены доставки';
      newLog.changeValue = `${formatPriceCur(log.oldDeliveryCost) || 'Бесплатно'}>${formatPriceCur(log.deliveryCost) || 'Бесплатно'}`;
      logsNew.push({ ...newLog });
    }
    if (log.postpayDays !== null && log.postpayDays !== undefined) {
      newLog.changeEntityProperty = 'Изменение дней отсрочки';
      newLog.changeValue = `${log.oldPostpayDays}>${log.postpayDays}`;
      logsNew.push({ ...newLog });
    }
    if (log.prepayPct !== null && log.prepayPct !== undefined) {
      newLog.changeEntityProperty = 'Изменение процента предоплаты';
      newLog.changeValue = `${log.oldPrepayPct}>${log.prepayPct}`;
      logsNew.push({ ...newLog });
    }

    log.positions.forEach(positionLog => {
      const positionDeal = (dealStore.deal.positions || []).find(i => i.code === positionLog.code);
      newLog.changedEntity = `#${positionDeal?.lineNumber}`;
      if (positionLog.unitCost !== null && positionLog.unitCost !== undefined) {
        newLog.changeEntityProperty = 'Изменение цены товара у позиции';
        newLog.changeValue = `${positionLog.oldUnitCost} ${positionDeal?.currency || '' + '/' + positionDeal?.unit || ''}>${
          positionLog.unitCost
        } ${positionDeal?.currency || '' + '/' + positionDeal?.unit || ''}`;
        logsNew.push({ ...newLog });
      }
      if (positionLog.quantity !== null && positionLog.quantity !== undefined) {
        newLog.changeEntityProperty = 'Изменение количества у позиции';
        newLog.changeValue = `${positionLog.oldQuantity + ' ' + positionDeal?.unit || ''}>${
          positionLog.quantity + ' ' + positionDeal?.unit
        }`;
        logsNew.push({ ...newLog });
      }
      if (positionLog.isNew) {
        newLog.changeEntityProperty = 'Добавление позиции';
        newLog.changeValue = `${positionDeal?.product?.code} ${positionDeal?.product?.title}`;
        logsNew.push({ ...newLog });
      }
    });
  });

  return logsNew;
};
export interface ConfirmDialogContent {
  text: string;
  header: string;
  contentHeader: string;
  isShowInput: boolean;
  isInputRequired: boolean;
  action: DealApprovalActionType;
}

export interface ApprovalStoreInterface {
  stateCode(): DealApprovalStateEnum;

  readonly isSaving: boolean;
  readonly isLoading: boolean;
  readonly approval: AppDealApproval;
  readonly creator: AppDealDistributor;
  readonly errors: ErrorForButton[];
  /**
   * Названия для списка действий на кнопке действия
   */
  labelsMenuItems?: Partial<Record<MenuItemsApprovalIdsType, string>>;
  /**
   * Названия для кнопок действия.
   */
  labelsControls?: Partial<Record<DealApprovalStateType, string>>;

  /**
   * Возвращает данные для отображения в диалоговом окне подтверждения действия.
   */
  buildContentForDialog(action: DealApprovalActionType, label: string): ConfirmDialogContent;

  /**
   * Обработка событий подтверждения, отклонения и отзыва запроса на согласование.
   * @param action
   * @param comment
   */
  imposeApprovalResolutionByType(action: DealApprovalActionType, comment?: string): Promise<void>;

  readonly lastSubmittedAt?: string;
}

export class DealTopBarEntityStore implements TopBarEntityStore {
  dealItemStore: DealItemStore = null;

  constructor(dealItemStore: DealItemStore) {
    this.dealItemStore = dealItemStore;
    makeAutoObservable(this);
  }

  entityCode(): string {
    return this.dealItemStore.dealCode;
  }

  titleForCatalog(): string {
    return 'Выберите товарные позиции для добавления в заявку';
  }

  titleForClient(): string {
    return 'Выберите клиента по заявке';
  }

  typeName(): entityType {
    return 'deal';
  }

  customerTitle(): string {
    return this.dealItemStore?.deal?.customer?.shortTitle || this.dealItemStore?.deal?.customer?.title || '';
  }
}

class ApprovalStore implements ApprovalStoreInterface {
  readonly store: DealItemStore;
  labelsMenuItems = {
    confirm: 'Подтвердить заявку',
    decline: 'Отклонить заявку',
  };
  labelsControls = {
    [DealApprovalState.Declined]: 'Заявка отклонена',
  };

  constructor(store: DealItemStore) {
    this.store = store;
  }

  get isSaving(): boolean {
    return this.store?.isSaving ?? false;
  }

  stateCode(): DealApprovalStateEnum {
    return this.store.deal?.approval?.state;
  }

  get errors(): ErrorForButton[] {
    return getErrorsApprovalControl(this.store.deal?.approval, this.store.deal?.positions, this.store.deal?.submitNotices);
  }

  get lastSubmittedAt(): string {
    return this.store?.deal.lastSubmittedAt;
  }

  get isLoading(): boolean {
    return this.store?.isLoading ?? false;
  }

  get approval(): AppDealApproval {
    return this.store.deal.approval;
  }

  get code(): string {
    return this.store.dealCode;
  }

  get creator(): AppDealDistributor {
    return this.store.deal.creator;
  }

  buildContentForDialog(action: DealApprovalActionType, label: string): ConfirmDialogContent {
    const cdc = {
      header: label,
      contentHeader: '',
      text: '',
      isShowInput: false,
      action: action,
    } as ConfirmDialogContent;

    switch (action) {
      case DealApprovalAction.Confirm:
        cdc.contentHeader = 'Вы действительно хотите подтвердить заявку?';
        cdc.text = 'После подтверждения редактирование заявки станет недоступно.';
        cdc.isShowInput = true;
        break;
      case DealApprovalAction.Decline:
        cdc.contentHeader = 'Отклонить формирование счета и вернуть заявку в работу.';
        cdc.isShowInput = true;
        cdc.isInputRequired = true;
        break;

      default:
        throw new Error(`Необработанный action ${action}`);
    }
    return cdc;
  }

  /**
   * Установки резолюции по действию:
   * подтверждение заявки, отозвать запрос на согласование, отозвать подтверждение (отклонить заявку).
   * @param action
   * @param comment
   */
  async imposeApprovalResolutionByType(action: DealApprovalActionType, comment?: string): Promise<void> {
    let resDeal: Deal;
    let res: AxiosResponse;
    runInAction(() => {
      this.store.isLoading = true;
    });
    const code = this.store.dealCode;

    if (action === 'confirm') {
      try {
        res = await this.store.apiStore.apiClientDeal().dealsItemApprovalConfirm(code, <DealsItemApprovalConfirmRequest>{
          comment: comment,
        });
        resDeal = res.data?.deal;
        this.store.webSocketStore.processMessages({
          msgType: MsgType.SHOP_FRONT_DEAL_POSSIBLE_APPROVAL_CHANGED,
          data: {
            dealCode: code,
          },
        });
        this.store.loadBillsAndShioments();
      } catch (error) {
        this.store.reload();
        console.error('dealsItemApprovalConfirm', error);
        return;
      }
    } else if (action === 'decline') {
      try {
        res = await this.store.apiStore.apiClientDeal().dealsItemApprovalDecline(code, <DealsItemApprovalDeclineRequest>{
          dealCode: code,
          comment: comment,
        });
        resDeal = res.data?.deal;
        this.store.webSocketStore.processMessages({
          msgType: MsgType.SHOP_FRONT_DEAL_POSSIBLE_APPROVAL_CHANGED,
          data: {
            dealCode: code,
          },
        });
        this.store.loadBillsAndShioments();
      } catch (error) {
        this.store.reload();
        console.error('dealsItemApprovalDecline', error);
        return;
      }
    } else if (action === 'withdraw') {
      try {
        res = await this.store.apiStore.apiClientDeal().dealsItemApprovalWithdraw(code);
        resDeal = res.data?.deal;
        this.store.webSocketStore.processMessages({
          msgType: MsgType.SHOP_FRONT_DEAL_POSSIBLE_APPROVAL_CHANGED,
          data: {
            dealCode: code,
          },
        });
      } catch (error) {
        this.store.reload();
        console.error('dealsItemApprovalWithdraw', error);
        return;
      }
    }
    if (this.store.deal?.code === resDeal?.code) {
      runInAction(() => {
        this.store.replaceDeal(getCallContext(res), resDeal);
        this.store.isLoading = false;
      });
    }
  }
}

const getErrorsApprovalControl = (
  approval: AppDealApproval,
  positions: { isArchive?: boolean }[] = [],
  submitNotices: DocumentNotice[] = []
): ErrorForButton[] => {
  const errors: ErrorForButton[] = [];
  if (!approval?.currentAccessGroup) {
    errors.push({ text: 'Вы не можете согласовывать данную заявку.' });
  }
  if (!positions.filter(i => !i?.isArchive)?.length) {
    errors.push({ text: 'В заявке отсутствуют позиции.' });
  }
  if (!approval?.resolutionMinimumGroup) {
    errors.push({ text: 'Цена товара меньше цены с МРЦ' });
  }
  if (submitNotices?.length > 0 && submitNotices.filter(i => i.type === DocumentNoticeTypeEnum.Error).length) {
    errors.push({ text: 'В заявке имеются ошибки.' });
  }
  return errors;
};
const generateCommentFromStr = (comment: string, newComment: string): string => {
  let commentText = (comment || '').trim();
  const commentLines = commentText ? commentText.split('\n') : [];
  commentLines.push(newComment);
  commentText = commentLines.join('\n');
  return commentText;
};

class DealApprovalChangesHistoryStore implements ApprovalHistoryStore {
  dealStore: DealItemStore;
  isLoading = false;
  isLoaded = false;
  loadingEnabled = false;
  loadedEpoch = 0;
  historyWithUpdate: DocumentApprovalHistoryWithUpdates[] = null;

  constructor(dealStore: DealItemStore) {
    this.dealStore = dealStore;
    this.handleWs = this.handleWs.bind(this);
    makeAutoObservable(this, {
      dealStore: false,
    });
  }

  reload(enableLoading: boolean): void {
    if (!this.loadingEnabled && !enableLoading) {
      return;
    }
    runInAction(() => {
      if (enableLoading) {
        this.loadingEnabled = enableLoading;
      }
      this.isLoading = true;
    });
    this.dealStore.apiStore
      .apiClientDeal()
      .dealsItemHistory(this.dealStore.dealCode)
      .then((res: AxiosResponse<DealsItemHistoryResponse>) => {
        this.setLoadResult(res.data);
      })
      .catch(e => {
        console.warn(e);
      });
  }

  setEnableLoading(enableLoading: boolean): void {
    if (!this.loadingEnabled && enableLoading) {
      this.reload(true);
    } else {
      runInAction(() => {
        this.loadingEnabled = enableLoading;
      });
    }
  }

  handleWs(msg: IncomeMessage): void {
    if (msg.msgType === MsgType.SHOP_FRONT_DEAL_POSSIBLE_APPROVAL_CHANGED) {
      this.reload(false);
    }
  }

  setLoadResult(res: DealsItemHistoryResponse): void {
    const result = [];
    res?.history?.events?.forEach(log => {
      let transformUpdateLogs = [];
      if (log?.dealUpdateLogChanges?.length) {
        transformUpdateLogs = transformDealChanges(this.dealStore, log?.dealUpdateLogChanges);
      }
      result.push({ ...log, dealUpdateLogChanges: transformUpdateLogs });
    });

    runInAction(() => {
      this.isLoaded = true;
      this.loadedEpoch++;
      this.isLoading = false;
      this.historyWithUpdate = observable(result);
    });
  }
}

// Позиция заказано-отгружено
interface OrderedShippedPosition {
  ordered: number;
  shipped: number;
  remains: number;
}

// Заказано-отгружено
interface OrderedShipped {
  totalShipped: number;
  totalOrdered: number;
  totalRemains: number;
  positions: {
    [key: string]: OrderedShippedPosition;
  };
}

// услуги (с кнопками копирования) - начальное состояние
export const initialServicesWithCopyBtnValue = {
  ['delivery']: true,
  ['credit']: true,
  ['promotion']: false,
  ['auto_rounding']: false,
  ['distributed_deal_services_cost']: true,
};

/**
 * Заявка или сделка.
 */
export class DealItemStore {
  svc: DealItemService;
  routerStore: RouterStore;
  apiStore: ApiStore;
  reserveStore: ReservesStore;
  error: ErrorDocuments = null;
  billsStore: BillListStore;
  shipmentsListStore: ShipmentsListStore;
  agreementStore: AgreementItemStore;
  rootStore: RootStore;
  snackbarStore: SnackbarStore;
  webSocketStore: WebSocketStore;
  valuesStores = new Map<string, ValueStore>();
  //
  topBarEntityStore: DealTopBarEntityStore;
  positionsManufacturersStore: PositionsManufacturersListStore;
  // время отправки запроса из ответа которого обновлено состояние сделки
  lastLoadedRequestTime?: Date;
  ignoreBeforeDate?: Date;

  dealCode: string;
  deal: AppDeal = {} as AppDeal;
  creditStateStore: CreditStateStore;

  // Сделка в процессе загрузки
  isLoading = true;
  // Устанавливается true после первой загрузки
  isLoaded = false;
  isDeleted = false;
  loadedEpoch = 0;
  // Идет сохранение
  isSaving = false;
  positionsCodesFromAgreement = new Set();

  // Заказано-отгружено
  orderedShipped: OrderedShipped = {
    totalOrdered: 0,
    totalShipped: 0,
    totalRemains: 0,
    positions: {},
  };

  /**
   * Очередь обновлений сделки
   */
  updatesQueue = new Array<UpdateQueueItem>();

  /**
   * Требуется перезагрузить всю сделку.
   * Если да, то после всех апдейтов будет вызван reload()
   */
  reloadRequired = false;

  /**
   * Свернутые позиции
   */
  positionClosed: Record<string, boolean> = {};

  /**
   * Коды позиций при добавлении новых товаров.
   * Нужно сохранять, чтобы отправлять изменения с тем же кодом, чтобы позиции не дублировались.
   */
  productsPositionsCodes: Record<string, string> = {};
  approvalStore: ApprovalStore;
  approvalHistoryStore: DealApprovalChangesHistoryStore;

  /**
   * Сторы для значений позиций (количество, цена).
   * Позволяет организовать удобный для пользователя ввод с обновлениями от бэка.
   */
  positionsValuesStores = new WeakMap<AppDealPosition, Map<string, ValueStore>>();

  paymentStore: DealPaymentStore;
  dealPartnersStore: DealPartnersStore;
  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.reserveStore = new ReservesStore(rootStore);
    this.rootStore = rootStore;
    this.snackbarStore = rootStore.getSnackbar();
    this.svc = new DealItemService();
    this.billsStore = new BillListStore(rootStore);
    this.shipmentsListStore = new ShipmentsListStore(rootStore);
    this.agreementStore = new AgreementItemStore(rootStore);
    this.webSocketStore = rootStore.getWebSocket();
    this.handleWs = this.handleWs.bind(this);
    this.approvalStore = new ApprovalStore(this);
    this.approvalHistoryStore = new DealApprovalChangesHistoryStore(this);
    this.dealPartnersStore = new DealPartnersStore(rootStore);
    this.routerStore = rootStore.getRouter();

    makeAutoObservable(this, {
      svc: false,
      rootStore: false,
      snackbarStore: false,
      valuesStores: false,
      positionsValuesStores: false,
    });

    // * когда поле deal перестает наблюдаться
    onBecomeUnobserved(this, 'deal', () => {
      this.svc.deleteIdleInterval();
    });
  }
  getCreditStateStore(): CreditStateStore {
    if (!this.creditStateStore) {
      this.creditStateStore = new CreditStateStore(this.rootStore);
    }
    return this.creditStateStore;
  }
  addPositionsFromAgreement(positions: DealUpdatesPosition[], selectedAgreementCode: string, userCode: string): Promise<any> {
    this.isLoading = true;
    const updatePositions = [];

    positions.forEach(p => {
      if (!this.positionsCodesFromAgreement.has(p.productCode)) {
        updatePositions.push(p);
        this.positionsCodesFromAgreement.add(p.productCode);
      }
    });
    if (!updatePositions.length) {
      return Promise.resolve('await');
    }

    return this.apiStore
      .apiClientDeal()
      .dealsItemSave(this.dealCode, {
        code: this.dealCode,
        frontCode: userCode,
        source: {
          itemCode: selectedAgreementCode,
          kind: 'agreement',
        },
        updates: {
          agreementCode: selectedAgreementCode,
          positions: updatePositions,
        },
      })
      .then(res => {
        this.setSavingResult(getCallContext(res), res.data);
        return 'done';
      });
  }

  createBillFromDeal(): void {
    this.svc.createBillFromDeal(this);
  }

  createWithAgreementPositions(
    frontCode: string,
    agreement: ContractCostAgreement
    // positions: ContractCostPosition[] = []
  ): AxiosPromise<DealsCreateResponse> {
    // обработка позиций для сделки
    // const updatePositions = new Array<DealUpdatesPosition>();
    // if (positions?.length > 0) {
    //   positions.forEach((p, i) => {
    //     updatePositions.push(<DealUpdatesPosition>{
    //       frontCode: frontCode + '-' + i,
    //       agreementPositionCode: p.code,
    //       productCode: p.productCode,
    //       amount: 1,
    //       bareUnitCost: p.saleUnitCost,
    //     });
    //   });
    // }

    return this.apiStore.apiClientDeal().dealsCreate(<DealsCreateRequest>{
      frontCode: frontCode,
      source: {
        kind: 'agreement',
        itemCode: agreement.code,
      },
      updates: <DealUpdates>{
        customerCode: agreement.customer?.code,
        agreementCode: agreement.code,
        prepayPct: agreement.payment.prepayPct || 0,
        postpayDays: agreement.payment.postpayDays,
        // change updatePositions by null
        positions: null,
      },
    });
  }

  getDealPaymentStore(): DealPaymentStore {
    if (!this.paymentStore) {
      this.paymentStore = new DealPaymentStore(this);
    }
    return this.paymentStore;
  }

  startIdleRefresh(): void {
    this.svc.setupIdleInterval(this);
  }

  calculateOrderedShipped(): void {
    const positions = this.deal?.positions;
    const shipments = this.shipmentsListStore.items;

    if (!positions || !shipments) {
      return;
    }

    const ordShip: OrderedShipped = {
      totalOrdered: 0,
      totalShipped: 0,
      totalRemains: 0,
      positions: {},
    };

    positions.map(position => {
      ordShip.totalOrdered += position.baseQuantity;
      ordShip.positions[position.product.nomenclatureCode] = {
        ordered: position.baseQuantity,
        shipped: position.reservationSummary?.shippedQuantity || 0,
        remains: position.baseQuantity,
      };
    });

    shipments.map(shipment => {
      shipment.positions.map(position => {
        ordShip.totalShipped += position.quantityTne;
        ordShip.totalRemains -= position.quantityTne;

        const v = ordShip.positions[position.nomenclatureCode];
        if (v === undefined) {
          return;
        }

        v.remains -= position.quantityTne;
        ordShip.positions[position.nomenclatureCode] = v;
      });
    });

    this.orderedShipped = ordShip;
  }

  async addPosition(
    row: ProductsNormalType,
    quantity?: number,
    cost?: number,
    positionCode?: string,
    unitCode?: string,
    warehouseCode?: string,
    agreementCode?: string
  ): Promise<any> {
    const hashProduct = agreementCode ? buildHash(row?.code + warehouseCode + agreementCode) : buildHash(row?.code + warehouseCode);
    if (!positionCode && hashProduct && this.productsPositionsCodes[hashProduct]) {
      positionCode = this.productsPositionsCodes[hashProduct];
    }

    let isPositionFromAgreement: boolean | null = null;
    let currentAmount: number | null = null;
    if (positionCode) {
      this.deal.positions.forEach(position => {
        if (position.code === positionCode && position.agreementPositionCode) {
          isPositionFromAgreement = true;
          currentAmount = quantity - position.baseQuantity;
        }
      });
    }
    if (isPositionFromAgreement || !positionCode) {
      const request: ObjectsPrepareRequest = {
        frontCode: uuidv4(),
        objectType: 'deal_position',
      };

      const res = await this.apiStore.apiClientSystem().objectsPreparePost(request);
      positionCode = res.data.code;
      if (hashProduct && this.productsPositionsCodes[hashProduct]) {
        // Параллельный запрос уже получил код позиции, используем его.
        positionCode = this.productsPositionsCodes[hashProduct];
      } else {
        this.productsPositionsCodes[hashProduct] = positionCode;
      }
    } else {
      warehouseCode = undefined;
      // Если товар уже добавлен, то цену и ЕИ передавать не надо, только количество!
      cost = undefined;
      // unitCode = undefined;
    }

    const newPosition = mapNewPosition(positionCode, this.deal, row, currentAmount || quantity, cost, unitCode, warehouseCode);

    const changes = {
      code: this.dealCode,
      positionCodes: [positionCode],
      byCode: {
        [positionCode]: newPosition,
      },
      byProductCode: {
        [positionCode]: {
          amount: newPosition.amount || 1,
          bareUnitCost: newPosition.cost || 0,
          productCode: row.code,
          positionCode,
        },
      },
    };

    return this.updateDeal(changes);
  }

  closePosition(code: string): void {
    this.positionClosed[code] = !this.positionClosed[code];
  }

  closeAllPositions(): void {
    if (!this.deal?.positionCodes) {
      console.warn('empty positionsCodes');
      return;
    }
    const isAllClosed = this.deal?.positionCodes?.length === this.deal?.positionCodes?.filter(code => !!this.positionClosed[code]).length;

    runInAction(() => {
      this.deal?.positionCodes?.forEach(code => {
        this.positionClosed[code] = !isAllClosed;
      });
    });
  }

  deletePosition(position: AppDealPosition): Promise<UpdateQueueItem> {
    const changes = {
      byCode: {
        [position.code]: {
          code: position.code,
          isArchive: true,
        },
      },
    };
    return this.updateDeal(changes);
  }

  getDeliveryTypesStore(): DeliveryTypeListStore {
    return this.rootStore.getLocalStoreFor(this, 'DeliveryTypeListStore', DeliveryTypeListStore, store => {
      store.loadListForDeal(this.dealCode);
    });
  }

  getDistributorsStore(): DealDistributorListStore {
    return this.rootStore.getLocalStoreFor(this, 'DealDistributorListStore', DealDistributorListStore, store => {
      store.loadForDeal(this.deal);
    });
  }

  /**
   * Возвращает стор с альтернативными производителями позиций сделки.
   */
  getPositionsManufacturersStore(): PositionsManufacturersListStore {
    if (!this.positionsManufacturersStore) {
      this.positionsManufacturersStore = new PositionsManufacturersListStore(this.rootStore);
      if (this.deal) {
        this.positionsManufacturersStore.loadForDeal(this.deal);
      }
    }
    return this.positionsManufacturersStore;
  }

  getTopBarEntityStore(): DealTopBarEntityStore {
    if (!this.topBarEntityStore) {
      this.topBarEntityStore = new DealTopBarEntityStore(this);
    }
    return this.topBarEntityStore;
  }

  getWarehousesStore(): WarehouseListStore {
    if (!!this.deal) {
      return this.rootStore.getLocalStoreFor(this, 'WarehouseListStore', WarehouseListStore, store => {
        store.loadListForDeal(this.deal);
      });
    } else {
      return { items: [] } as WarehouseListStore;
    }
  }

  /**
   * Изменение статуса сделки по событиям из веб сокетов
   * @param msg
   */
  handleWs(msg: IncomeMessage): void {
    const msgDealCode = msg.data?.dealCode;
    if (msg.msgType === MsgType.SHOP_FRONT_DEAL_PRODUCT_ADDED && msgDealCode === this.dealCode) {
      this.reload(false);
      return;
    }
    if (msgDealCode !== this.dealCode || !this.deal?.approval) {
      return;
    }
    changeDealByWs(msg, this.deal);
  }

  isAllPositionsClosed(): boolean {
    return this.deal?.positionCodes?.length === this.deal?.positionCodes?.filter(code => !!this.positionClosed[code]).length;
  }

  prolong(): Promise<null> {
    return new Promise<null>((resolve, reject) => {
      this.apiStore
        .apiClientDeal()
        .dealsItemProlong(this.dealCode)
        .then((res: AxiosResponse<DealsItemProlongResponse>) => {
          this.deal.validUntil = res.data.validUntil;
          const time = formatDateTimeLZ(this.deal.validUntil);
          this.snackbarStore.showSuccess(`Заявка продлена до ${time}`);
          resolve(null);
          // изменился статус заявки, возможно она стала доступной для редактирования, поэтому нужно обновить целиком
          this.reload();
        })
        .catch(r => {
          reject(r);
        });
    });
  }

  deactivate(): Promise<null> {
    return new Promise<null>((resolve, reject) => {
      this.apiStore
        .apiClientDeal()
        .dealsItemDeactivate(this.dealCode)
        .then(() => {
          this.snackbarStore.showSuccess(`Заявка деактивирована`);
          resolve(null);
          this.reload();
        })
        .catch(r => {
          reject(r);
        });
    });
  }

  reload(isIdleRefresh = false): void {
    this.ignoreBeforeDate = new Date();
    this.svc.load(this, isIdleRefresh);
    const dtStore = this.rootStore.getLocalStoreFor(this, 'DeliveryTypeListStore', DeliveryTypeListStore);
    dtStore.loadListForDeal(this.dealCode);
  }

  isPositionClosed(position: AppDealPosition): boolean {
    return this.positionClosed[position.code];
  }

  /**
   *
   * @param deal Уже должна быть observable
   */
  setDeal(deal: AppDeal): void {
    if (this.deal === deal) {
      return;
    }
    this.deal = deal;
    this.dealCode = deal?.code;
    this.isLoading = false;
    this.isLoaded = true;
    // this.positionClosed // тут не понятно, нужно ли. Скорее всего и без этого проблем не будет.
  }

  setDealCode(code: string): void {
    if (this.dealCode === code) {
      return;
    }
    this.isLoaded = false;
    this.dealCode = code;
    this.approvalHistoryStore = new DealApprovalChangesHistoryStore(this);
    this.approvalStore = new ApprovalStore(this);
    this.getDeliveryTypesStore().loadListForDeal(code);
    this.svc.load(this);
  }

  getValueStore(field: string, type: ValueStoreInputTypesType = ValueStoreInputTypes.String): ValueStore {
    let fieldStore = this.valuesStores.get(field);
    if (!fieldStore) {
      fieldStore = new ValueStore({
        inputDelay: 700,
        value: this.filterDisplayField(field, this.deal[field]),
        onInputChangeDebounced: (value: string) => {
          this.updateDeal({ [field]: castInput(value, type) });
        },
      });
      this.valuesStores.set(field, fieldStore);
    }
    return fieldStore;
  }

  getValueStoreByPos(position: AppDealPosition, field: string, type: ValueStoreInputTypesType = ValueStoreInputTypes.String): ValueStore {
    let stores = this.positionsValuesStores.get(position);
    if (!stores) {
      stores = new Map<string, ValueStore>();
      this.positionsValuesStores.set(position, stores);
    }
    let fieldStore = stores.get(field);
    if (!fieldStore) {
      fieldStore = new ValueStore({
        inputDelay: 700,
        value: position[field],
        onInputChangeDebounced: value => {
          this.updatePosition(position, { [field]: castInput(value, type) });
        },
      });
      stores.set(field, fieldStore);
    }
    return fieldStore;
  }

  getCommentValueStoreByPos(position: AppDealPosition, comment: DealPositionComment): ValueStore {
    let stores = this.positionsValuesStores.get(position);
    if (!stores) {
      stores = new Map<string, ValueStore>();
      this.positionsValuesStores.set(position, stores);
    }
    let fieldStore = stores.get(`comments_${comment.typeCode}`);

    if (!fieldStore) {
      const fieldStoreComment = position?.comments?.find(item => item.typeCode === comment.typeCode);
      fieldStore = new ValueStore({
        inputDelay: 700,
        value: fieldStoreComment?.comment || '',
        onInputChangeDebounced: value => {
          this.updatePosition(position, {
            comments: [{ typeCode: comment.typeCode, comment: value }],
          });
        },
      });
      stores.set('comments', fieldStore);
    }
    return fieldStore;
  }

  setEmpty(): void {
    this.isLoaded = true;
    this.reloadRequired = false;
    this.loadedEpoch++;
  }

  isApproved(): boolean {
    const state = this.deal?.approval?.state;
    return state === DealApprovalStateEnum.Approved || state === DealApprovalStateEnum.SelfApproved;
  }

  isViewOnly(): boolean {
    return !this.isLoaded || !this.deal || !this.deal.editingEnabled || this.isDeleted;
  }

  // Замена артикула у позиции
  replacePositionProduct(positionCode: string, productCode: string): Promise<UpdateQueueItem> {
    const changes = <AppDealUpdates>{
      byCode: {
        [positionCode]: {
          code: positionCode,
          productCode: productCode,
        },
      },
    };
    return this.updateDeal(changes);
  }

  restorePosition(position: { code: string }): Promise<UpdateQueueItem> {
    const changes = {
      byCode: {
        [position.code]: {
          code: position.code,
          isArchive: false,
        },
      },
    };
    return this.updateDeal(changes);
  }

  replaceDeal(ctx: AxiosCallContext, apiDeal: Deal): void {
    const prevPositions = new Map<string, AppDealPosition>();
    this.deal?.positions?.forEach(p => {
      prevPositions.set(p.code, p);
    });
    setClear(this.deal, mapDeal(apiDeal));
    this.deal?.positions?.forEach(newPos => {
      const prevPos = prevPositions.get(newPos.code);
      if (prevPos) {
        const prevStores = this.positionsValuesStores.get(prevPos);

        if (prevStores) {
          prevStores.forEach((value, key) => {
            value.handleModelChange(newPos[key], ctx.startTime);
          });
          this.positionsValuesStores.set(newPos, prevStores);
        }
      }
    });
    if (this.paymentStore) {
      this.paymentStore.setDeal(this, ctx.startTime);
    }
  }

  setSavingResult(ctx: AxiosCallContext, res: DealsItemSaveResponse): void {
    // * позволяет отображать пересчитанные данные сделки, до того как пользователь закончил ввод.
    this.replaceDeal(ctx, res.deal);
    //* этот код фиксил какую то багу, но я не помню какую( но при этом этот код заставлял открывал свернутые позиции при перемещении
    // this.deal?.positions?.forEach(position => {
    //   this.positionClosed[position.code] = !!position.isService;
    // });
    // this.updatesQueue.mresap(u => {
    // deal = mergeDealChanges(deal, u.updates);
    // });
  }

  setReloadRequired(required: boolean): void {
    this.reloadRequired = required;
  }

  loadBillsAndShioments(dealCode?: string): void {
    this.shipmentsListStore
      .loadListForeDeal({
        dealCode: dealCode || this.dealCode,
      })
      .then(() => {
        this.calculateOrderedShipped();
      })
      .catch(e => {
        console.warn(e);
      });
    this.billsStore.loadListForeDeal({
      dealCode: dealCode || this.dealCode,
      enrichCustomers: true,
    });
  }

  setResult(ctx: AxiosCallContext, res: DealsItemResponse): void {
    this.replaceDeal(ctx, res.deal);
    this.lastLoadedRequestTime = ctx.startTime;
    if (!this.isLoaded) {
      this.deal?.positions?.forEach(position => {
        this.positionClosed[position.code] = !!position.isService;
      });
    }
    this.isDeleted = !!res.deal.isDeleted;
    this.isLoaded = true;
    this.isLoading = false;
    this.reloadRequired = false;
    this.loadedEpoch++;
    this.calculateOrderedShipped();

    if (!this.positionsManufacturersStore) {
      this.positionsManufacturersStore = new PositionsManufacturersListStore(this.rootStore);
      if (this.deal) {
        this.positionsManufacturersStore.loadForDeal(this.deal);
      }
    }
  }

  movePositionOfDealManually(startIndex: number, endIndex: number): void {
    const [removed] = this.deal.positions.splice(startIndex, 1);
    this.deal.positions.splice(endIndex, 0, removed);
    this.deal.positionCodes = mapPositionCodes(this.deal.positions);
  }

  movePositionOfDeal(positionCode: string, newPositionForPositionOfDeal: number, currentPositionForPositionOfDeal: number): void {
    const updItem = <UpdateQueueItem>{
      hash: uuidv4(),
      actionType: 'movePosition',
      positionCode,
      updates: {
        lineNumber: newPositionForPositionOfDeal,
      },
    };
    this.movePositionOfDealManually(currentPositionForPositionOfDeal - 1, newPositionForPositionOfDeal - 1);
    updItem.onResolve = () => {};
    this.updatesQueue.push(updItem);
    this.svc.debounceUpdate(this, 1);
  }

  findAgreementByPositionCode(contractCostPositionCode: string, typeProperty = 'code'): [ContractCostPosition, string] {
    for (const contractCostAgreement of this.deal.contractCostAgreements) {
      for (const position of contractCostAgreement.positions) {
        if (position?.[typeProperty] === contractCostPositionCode) {
          return [position, contractCostAgreement.code];
        }
      }
    }
    return [undefined, undefined];
  }

  /**
   * загрузка файлов для сделки (и позиции)
   * @param files
   * @param positionCode если есть positionCode то загружаем файл для позиции
   * @param docTypeCode тип добавляемого документа
   */
  uploadDocuments(files: File[], positionCode: string, docTypeCode: string): void {
    if (!files.length) {
      return;
    }
    const updItem = <UpdateQueueItem>{
      hash: uuidv4(),
      files,
      actionType: 'upload',
      positionCode: positionCode || undefined,
      documentTypeCode: docTypeCode,
    };
    let text = 'Файлы успешно загружены';
    if (files.length === 1) {
      text = 'Файл успешно загружен';
    }
    updItem.onResolve = () => {
      this.snackbarStore.showInfo(text);
    };
    this.updatesQueue.push(updItem);
    this.svc.debounceUpdate(this);
  }

  deleteDocuments(codes: string[]): void {
    if (!codes.length) {
      return;
    }
    const updItem = <UpdateQueueItem>{
      hash: uuidv4(),
      codes,
      actionType: 'deleteFiles',
    };
    let text = 'Файлы успешно удалены';
    if (codes.length === 1) {
      text = 'Файл успешно удален';
    }
    updItem.onResolve = () => this.snackbarStore.showInfo(text);
    this.updatesQueue.push(updItem);
    this.svc.debounceUpdate(this);
  }
  filterDisplayField(key: string, value: any): any {
    if (key === 'validUntil' && value && value.indexOf('2000-') === 0) {
      // Не показываем дату окончания до 2000 года.
      // Это дефолтное значение, означает что дата не указана.
      return '';
    }
    return value;
  }
  getDealCommentValueStore(field: string, type: ValueStoreInputTypesType = ValueStoreInputTypes.String): ValueStore {
    let fieldStore = this.valuesStores.get(field);
    if (!fieldStore) {
      fieldStore = new ValueStore({
        inputDelay: 700,
        value: this.filterDisplayField(field, this.deal.comments.filter(i => i?.typeCode === field)[0]?.comment),
        onInputChangeDebounced: (value: string) => {
          this.apiStore
            .apiClientDeal()
            .dealsItemSave(this.dealCode, {
              code: this.dealCode,
              updates: {
                comments: [
                  {
                    typeCode: field,
                    comment: castInput(value, type) as string,
                  },
                ],
              },
            })
            .catch(r => console.warn('handleCommentChange', r));
        },
      });
      this.valuesStores.set(field, fieldStore);
    }
    return fieldStore;
  }

  /**
   * Внесение изменений (с использованием очереди)
   * @param updates Новые изменения
   */
  updateDeal(updates: AppDealUpdates): Promise<UpdateQueueItem> {
    // кажется это проверка от дублей обновления
    const curHash = hash(updates);
    const prevItem = this.updatesQueue.length ? this.updatesQueue[this.updatesQueue.length - 1] : undefined;
    // Поле "дней предоплаты" проверяется отдельно, т.к. оно может быть изменено бэкендом для подгонки к схеме сделки.
    // Без этого если выставить postpayDays=26, бэкенд сместит на 25, это отобразится на фронте. Если еще раз сместить
    // на 26, то разницы с предыдущим хэшом не будет, сохранение не произойдет и бегунок останется на 26.
    // По-хорошему нужно смотреть на таймстамп актуального состояния сделки, возможно на набор накопленных
    // изменений для сохранения.
    const wasChanged = !prevItem || prevItem.hash !== curHash || typeof updates.postpayDays !== 'undefined';

    if (!wasChanged) {
      return new Promise(() => {
        console.log('ignore updateDeal, hash repeat', curHash, updates);
      });
    }
    // const now = new Date();
    const properties = updates.byCode
      ? [Object.keys(updates.byCode), ...Object.keys(updates.byCode[Object.keys(updates.byCode)[0] || ''] || '')].join()
      : Object.keys(updates).join();
    const updItem = <UpdateQueueItem>{
      // createdAt: now,
      // updatedAt: now,
      actionType: 'save',
      hash: curHash,
      properties,
      updates,
    };
    // тут можно попробовать склеить новое изменение с последним в очереди.
    const promise = new Promise<UpdateQueueItem>((resolve, reject) => {
      updItem.onResolve = resolve;
      updItem.onReject = reject;
    });
    if (prevItem && prevItem.properties === updItem.properties) {
      this.updatesQueue = this.updatesQueue.map(i => (i === prevItem ? updItem : i));
    } else {
      this.updatesQueue.push(updItem);
    }
    this.svc.debounceUpdate(this);
    return promise;
  }

  /**
   * Изменение процента предоплаты, двигает процент предоплаты по всем позициям.
   * @param value
   */
  updateDealPrepayPct(value: number): Promise<UpdateQueueItem> {
    const changes: AppDealUpdates = {
      byCode: {},
      prepayPct: toFloat(value),
    };

    if (value >= 100) {
      changes.postpayDays = 0;
    }

    const positionRangeChanged = {}; // часть какой-то старой логики синхронизации
    this.deal?.positionCodes?.forEach(code => {
      if (!positionRangeChanged[code] || (positionRangeChanged[code] && !positionRangeChanged[code]['prepayPct'])) {
        changes.byCode[code] = {
          code,
          prepayPct: value,
        };

        if (value >= 100) {
          changes.byCode[code]['postpayDays'] = 0;
        }
      }
    });

    return this.updateDeal(changes);
  }

  /**
   * Изменение дней отсрочки, двигает дни отсрочки по всем товарам
   * @param value
   */
  updateDealPostpayDays(value: any): Promise<UpdateQueueItem> {
    const changes: AppDealUpdates = {
      byCode: {},
      postpayDays: toFloat(value),
    };

    const positionRangeChanged = {}; // часть какой-то старой логики синхронизации
    this.deal?.positionCodes?.forEach(code => {
      if (!positionRangeChanged[code] || (positionRangeChanged[code] && !positionRangeChanged[code]['postpayDays'])) {
        changes.byCode[code] = {
          code,
          postpayDays: value,
        };
      }
    });

    return this.updateDeal(changes);
  }

  addPackToPositionComment(position: AppDealPosition, pack: ProductItemPack): Promise<UpdateQueueItem> {
    let packComment = pack.number + ' из ' + pack.cassette;
    const mrcDiscounts = [];
    if (pack.todhText) {
      packComment += ', ' + pack.todhText;
    }
    if (pack.todhDiscountPct) {
      mrcDiscounts.push(pack.todhDiscountPct);
    }
    if (pack.illiquidDefectCode || pack.illiquidRustCode) {
      packComment +=
        ', ' + (pack.illiquidDefectCode ? pack.illiquidDefectCode : '–') + ' ' + (pack.illiquidRustCode ? pack.illiquidRustCode : '–');
    }
    if (pack.illiquidDiscountPct) {
      mrcDiscounts.push(pack.illiquidDiscountPct);
    }
    const mrcUnitCost = toFloat(position.minRetailUnitCost);
    if (mrcDiscounts.length && mrcUnitCost > 0) {
      packComment += ', МРЦ';
      let mrcDiscountSum = 0;
      for (let i = 0; i < mrcDiscounts.length; i++) {
        packComment += '-' + mrcDiscounts[i] + '%';
        mrcDiscountSum += mrcDiscounts[i];
      }
      const discountedUnitMrc = (mrcUnitCost * (100 - mrcDiscountSum)) / 100;
      packComment += '=' + formatNumber02(discountedUnitMrc, '');
    }

    const commentByApproval = (position.comments || []).find(comment => comment.typeCode === 'approval');
    const commentForApproval = generateCommentFromStr(commentByApproval?.comment, packComment);

    if (commentByApproval) {
      commentByApproval.comment = commentForApproval;
    }
    const commentByStore = (position.comments || []).find(comment => comment.typeCode === 'store');
    const commentForStore = generateCommentFromStr(commentByStore?.comment, pack.number + ' из ' + pack.cassette);
    if (commentByStore) {
      commentByStore.comment = commentForStore;
    }

    return this.updatePosition(position, {
      comments: [
        { comment: commentForApproval, typeCode: 'approval' },
        { comment: commentForStore, typeCode: 'store' },
      ],
    });
  }

  updatePositions(changes: AppDealUpdates): Promise<UpdateQueueItem> {
    return this.updateDeal(changes);
  }

  updatePosition(position: AppDealPosition, updates: AppDealPositionUpdates): Promise<UpdateQueueItem> {
    const changes: AppDealUpdates = {
      byCode: {
        [position.code]: { ...updates, code: position.code },
      },
    };
    return this.updateDeal(changes);
  }

  /**
   * включение/выключение услуги у позиции
   * @param positionCode Код позиции сделки
   * @param serviceCode Код услуги
   * @param enabled вкл/выкл
   */
  updatePositionServices(positionCode: string, serviceCode: string, enabled: boolean): Promise<UpdateQueueItem> {
    const services = [];

    this.deal.byCode[positionCode].services.forEach(service => {
      if (service.code === serviceCode) {
        services.push({
          ...service,
          enabled,
        });
      }
    });

    const changes: AppDealUpdates = {
      byCode: {
        [positionCode]: {
          code: positionCode,
          services,
        },
      },
    };
    return this.updateDeal(changes);
  }

  copyServiceToAllPositions(serviceCode: string, enabled: boolean): Promise<UpdateQueueItem> {
    const changes: AppDealUpdates = {
      byCode: {},
    };
    this.deal.positionCodes.forEach(code => {
      changes.byCode[code] = {
        code: code,
        services: [],
      };
      this.deal.byCode[code].services.forEach(service => {
        if (service.code === serviceCode) {
          changes.byCode[code].services.push({
            ...service,
            enabled,
          });
        }
      });
    });
    return this.updateDeal(changes);
  }

  setResultUploadDocument(file: DealDocument): void {
    this.deal.documents.push(file);
  }

  setResultDeleteDocuments(codes: string[]): void {
    this.deal.documents = this.deal.documents.filter(document => !codes.includes(document.code));
  }

  /**
   * @returns boolean Есть изменения, которые нужно сохранить
   */
  hasUpdates(): boolean {
    return this.updatesQueue.length > 0;
  }

  updatePartners({ payer, consignee }: DealPartners): void {
    runInAction(() => {
      setClear(this.deal.partners, { payer, consignee });
    });
    this.reload();
  }

  untieAgreement(): void {
    this.updateDeal(<AppDealUpdates>{ agreementReset: true }).catch(r => console.warn('untieAgreement', r));
  }
  async fastSearhInCatalog(query: string): Promise<CatalogProductsFastSearchResponse> {
    if (query !== '') {
      return this.apiStore
        .apiClientCatalog()
        .catalogProductsFastSearch({
          query: query || '',
          warehouse: this.deal.warehouseCode,
          branchOffice: this.deal.branchOfficeCode,
          limit: 30,
          // stockRequired,
        })
        .then((res): CatalogProductsFastSearchResponse => {
          return res.data;
        });
    } else {
      return Promise.resolve({ products: [], total: 0 });
    }
  }
}

class DealItemService {
  updateDebounceTimeout: NodeJS.Timeout;
  private idleInterval: NodeJS.Timeout;
  // private idleIntervalDelay = 10000;
  private idleIntervalDelay = 1000 * 60 * 5;
  private updateDebounceTimeoutDelay = 600;
  private offlineRequestDelay = 9000;

  createBillFromDeal(store: DealItemStore): void {
    runInAction(() => {
      store.isLoading = true;
    });
    store.apiStore
      .apiClientBill()
      .billsUpdateFromDeal({ dealCode: store.dealCode })
      .then(res => {
        store.routerStore.push(`/app/bills/${res.data.bill.documentNumber}`);
      })
      .catch(e => {
        console.warn('createBillFromDeal', e);
        runInAction(() => {
          store.isLoading = false;
        });
      });
  }

  debounceUpdate(store: DealItemStore, delay: number = undefined): void {
    if (this.idleInterval) {
      this.setupIdleInterval(store);
    }
    this.updateDebounceTimeout = setTimeout(() => {
      this.executeUpdate(store);
    }, delay || this.updateDebounceTimeoutDelay);
  }

  setupIdleInterval(store: DealItemStore): void {
    this.deleteIdleInterval();
    this.idleInterval = setInterval(() => store.reload(true), this.idleIntervalDelay);
  }

  deleteIdleInterval(): void {
    clearInterval(this.idleInterval);
    this.idleInterval = undefined;
  }

  executeUpdate(store: DealItemStore): void {
    if (store.isSaving) {
      // конкурирующий процесс?
      return;
    }
    if (!store.updatesQueue.length) {
      // нет изменений
      return;
    }
    if (this.idleInterval) {
      this.setupIdleInterval(store);
    }
    const upd: UpdateQueueItem = store.updatesQueue[0];
    runInAction(() => {
      store.isSaving = true;
    });
    const actionPromise: any[] = [];

    if (upd.actionType === 'upload') {
      const promises = upd.files.map(file =>
        store.apiStore
          .apiClientDeal()
          .dealsItemDocumentUpload(store.dealCode, upd.documentTypeCode, file, undefined, upd.positionCode)
          .then(({ data }) => {
            store.setResultUploadDocument(data);
          })
      );
      actionPromise.push(...promises);
      // promise = Promise.all(promises).then(response => {
      //   store.setResultUploadDocuments(response.map(({ data }) => data));
      // });
      // .catch(() => {
      //   runInAction(() => {
      //     store.updatesQueue = store.updatesQueue.filter(q => q !== upd);
      //   });
      // });
    } else if (upd.actionType === 'deleteFiles') {
      const promises = upd.codes.map(code =>
        store.apiStore
          .apiClientDeal()
          .dealsItemDocumentDelete(store.dealCode, code)
          .then(() => {
            store.setResultDeleteDocuments(upd.codes);
          })
      );
      actionPromise.push(...promises);
    } else if (upd.actionType === 'save') {
      const promise = store.apiStore
        .apiClientDeal()
        .dealsItemSave(store.dealCode, <DealsItemSaveRequest>{
          code: store.dealCode,
          updates: mapDealUpdates(upd.updates),
        })
        .then((res: AxiosResponse<DealsItemResponse>) => {
          // * если это последнее задание в очереди то актуализируем
          if (store.updatesQueue.filter(i => i !== upd).length === 0) {
            store.setSavingResult(getCallContext(res), res.data);
          }
        });
      actionPromise.push(promise);
    } else if (upd.actionType === 'movePosition') {
      const promise = store.apiStore
        .apiClientDeal()
        .dealsItemPositionsItemMove(store.dealCode, upd.positionCode, { position: upd.updates.lineNumber })
        .then((res: AxiosResponse<DealsItemResponse>) => {
          // * если это  последнее задание в очереди то актуализируем
          if (store.updatesQueue.filter(i => i !== upd).length === 0) {
            store.setSavingResult(getCallContext(res), res.data);
          }
        });
      actionPromise.push(promise);
    } else {
      const promise = new Promise((success, reject) => reject('unknown actionType ' + upd.actionType));
      actionPromise.push(promise);
    }

    Promise.all(actionPromise)
      .then(() => {
        runInAction(() => {
          store.updatesQueue = store.updatesQueue.filter(q => q !== upd);
          store.isSaving = false;
        });
        upd.onResolve(upd);
        this.debounceUpdate(store, 1);
      })
      .catch(error => {
        // * обработка исключения возникшего во время выполнения задачи.
        runInAction(() => {
          store.isSaving = false;
        });
        if (isOfflineError(error)) {
          // * если ошибка сети, то повторяем запрос через заданный интервал, не очищая очередь
          clearTimeout(this.updateDebounceTimeout);
          this.debounceUpdate(store, this.offlineRequestDelay);
        } else {
          if (upd.onReject) {
            upd.onReject(upd);
          } else {
            console.warn(error, upd);
          }

          // * если ошибка в логике приложения то исключаем задачу из очереди и запускаем очередь со следующей задачи
          runInAction(() => {
            store.updatesQueue = store.updatesQueue.filter(q => q !== upd);
          });
          clearTimeout(this.updateDebounceTimeout);
          this.debounceUpdate(store, 1);
        }
        store.setReloadRequired(true);
      });
  }

  load(store: DealItemStore, isIdleRefresh = false): void {
    if (!store.dealCode) {
      store.setEmpty();
      return;
    }

    const requestTime = new Date();
    const idleInterval = this.idleInterval;

    runInAction(() => {
      store.isLoading = true;
      store.error = null;
    });

    store.apiStore
      .apiClientDeal()
      .dealsItem(store.dealCode)
      .then((res: AxiosResponse<DealsItemResponse>) => {
        if (store.ignoreBeforeDate && requestTime.getTime() < store.ignoreBeforeDate.getTime()) {
          return;
        }
        if (store.lastLoadedRequestTime && requestTime.getTime() < store.lastLoadedRequestTime.getTime()) {
          return;
        }
        if (isIdleRefresh) {
          if (store.updatesQueue.length || idleInterval !== this.idleInterval) {
            // * очередь обновления не пуста или перезапущено фоновое обновление
            return;
          }
          store.snackbarStore.showInfo(`Заявка обновлена до актуального состояния`);
        }

        store.setResult(getCallContext(res), res.data);
      })
      .catch(r => {
        runInAction(() => {
          const error = r.response?.data?.error;
          store.error = {
            code: error?.code || 400,
            message: error?.message || 'Не найдено',
            reason: error?.reason === 'deal_not_found' ? 'not_found_document' : error?.reason || 'error',
          };
          store.isLoaded = true;
          store.isLoading = false;
        });
        console.warn('Не удалось загрузить сделку', r);
      });
  }
}
