import React, { FC, useState } from 'react';
import { ContractCostAgreement } from '../../api/marketx';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'src/store/MobxStoreProvider';
import { MxLabel, LabelColor } from '@mx-ui/ui';
import { getMarginalityColorClass } from 'src/views/clients/lib';
import { currencyDefault, formatNumberByUnit, formatPriceCur } from '@mx-ui/helpers';
import { ColorsQuantityPercentAndStockForLabel, getQuantityPercentAndStock } from './AgreementPositionItem/StockLinearProgress';
import { renderPriceCur } from '../../utils/renderFormat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CollapseButton } from '../../views/deals/components/CollapseButton';
import { ChipTitleStyled } from '../../views/deals/DealPosition/DealPositionPrices';
import { ProductLinkBtn } from '../Product/ProductLinkBtn/ProductLinkBtn';
import { ShippedProgress } from '../Bills/BillListItem';
import { PositionWithDocuments, PreviewMenu } from '../UploadFile/PreviewMenu';
import { Link as MxLink } from '@mx-ui/ui';

interface AgreementListItemPositionsProps {
  agreement: ContractCostAgreement;
  documents: Record<string, PositionWithDocuments>;
}

const labelStyle = { fontSize: '12px', display: 'inline', paddingTop: '4px' } as SxProps<Theme>;

/**
 * Позиции соглашения в списке соглашений
 */
export const AgreementListItemPositions: FC<AgreementListItemPositionsProps> = observer(({ agreement, documents }): JSX.Element => {
  const rootStore = useRootStore();
  const { profile } = rootStore.getAuth();
  const plsVisible = profile.features?.canSeeDealPrimeCostMargin;
  const matchesMd = useMediaQuery('(min-width:900px)');

  const [expandAll, setExpandAll] = useState([]);
  const allAccordionIndexes = agreement?.positions?.map((item, index) => `panel${index}`) || [];
  const handleCloseAllPositions = (): void => {
    setExpandAll(prevState => {
      if (prevState.length) {
        return [];
      }
      return allAccordionIndexes;
    });
  };

  const handleAccordionChange = (panelIndex: string): void => {
    setExpandAll(prevState => {
      const position = prevState.indexOf(panelIndex);
      if (position === -1) {
        return [...prevState, panelIndex];
      }
      return prevState.filter((item, index) => index !== position);
    });
  };

  return agreement.positions?.length > 0 ? (
    matchesMd ? (
      <Grid mb={'20px'} container>
        {agreement.positions
          .filter(i => !i.isArchive)
          .map((p, index) => {
            const currency = currencyDefault;
            const unit = p.unitShortName;
            const { color, stock, quantityPercent } = getQuantityPercentAndStock(p.quantity, p.reservation?.usedQuantity);
            const newColor = color === 'yellow' ? 'warningSimpleColor' : color === 'green' ? 'successSimpleColor' : color;
            return (
              <Grid item key={index} xs={12}>
                <Grid container px={2}>
                  <Grid item>
                    <Grid container flexWrap={'nowrap'} alignItems={'center'} spacing={1}>
                      <Grid item>
                        <ChipTitleStyled label={`#${p.lineNumber}`} />
                      </Grid>
                      <Grid item>
                        <Typography component="span" style={{ fontSize: '11px' }}>
                          {`${p?.product?.code || ''} `}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <MxLink href={`/app/product/${encodeURIComponent(p.productCode)}`} fontSize={'14px'}>
                          {p.product.title || ''}
                        </MxLink>
                      </Grid>
                      {documents[agreement.code] ? (
                        <Grid item>
                          <PreviewMenu documents={documents} positionCode={agreement.code} isWithBadge />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item container flexDirection="row" justifyContent="space-between">
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>МРЦ</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={'12px'} color="text.secondary">
                            {renderPriceCur(p?.mrcUnitCost || 0, currency + '/' + unit)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>Цена</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={'12px'} color="text.secondary">
                            {renderPriceCur(p?.calculatedBareUnitCost || 0, currency + '/' + unit)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>Цена с услугами</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={'12px'} color="text.secondary">
                            {renderPriceCur(p?.fullUnitCost || 0, currency + '/' + unit)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>Количество</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize="12px" color="text.secondary">
                            {formatNumberByUnit(p.quantity || 0, p.unitCode) + ' ' + unit}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>Остаток</Typography>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems={'center'}>
                            <Grid item>
                              {p?.isStocksControlDisabled ? (
                                <Typography fontSize="16px" color="text.secondary" title={`Контроль остатков отключен`}>
                                  <span>∞</span>
                                </Typography>
                              ) : (
                                <MxLabel
                                  sx={labelStyle}
                                  title={`${quantityPercent}%`}
                                  color={
                                    ColorsQuantityPercentAndStockForLabel[newColor]
                                      ? (ColorsQuantityPercentAndStockForLabel[color] as LabelColor)
                                      : (newColor as LabelColor)
                                  }
                                >
                                  {formatNumberByUnit(stock || 0, p.unitCode) + ' ' + unit}
                                </MxLabel>
                              )}
                            </Grid>
                            <Grid item ml={1}>
                              <ShippedProgress currentQuantity={stock} baseQuantity={p.quantity} color={'#317E03'} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid px={1} item>
                      <Grid container flexDirection="column" alignItems="flex-end">
                        <Grid item>
                          <Typography fontSize={'12px'}>МаржМРЦ</Typography>
                        </Grid>
                        <Grid item>
                          <MxLabel sx={labelStyle} color={getMarginalityColorClass(p.marginalityPct, true)}>
                            {formatPriceCur(p?.marginalityPct || 0, '%')}
                          </MxLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                    {plsVisible && (
                      <>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>МаржПлС</Typography>
                            </Grid>
                            <Grid item>
                              <MxLabel
                                style={{ fontSize: '12px', display: 'inline', paddingTop: '4px' }}
                                color={getMarginalityColorClass(p.purchaseMarginFullCostPct, true)}
                              >
                                {formatPriceCur(p?.purchaseMarginFullCostPct || 0, '%')}
                              </MxLabel>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid px={1} item>
                          <Grid container flexDirection="column" alignItems="flex-end">
                            <Grid item>
                              <Typography fontSize={'12px'}>МаржЦЗ</Typography>
                            </Grid>
                            <Grid item>
                              <MxLabel
                                style={{ fontSize: '12px', display: 'inline', paddingTop: '4px' }}
                                color={getMarginalityColorClass(p.purchaseMarginFullCostPct, true)}
                              >
                                {formatPriceCur(p?.purchaseMarginFullCostPct || 0, '%')}
                              </MxLabel>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {index !== agreement.positions.length - 1 && <Divider />}
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    ) : (
      <Grid mb={'20px'}>
        <Grid container justifyContent="flex-end" pr={2} mb={1}>
          <CollapseButton color={'#757575'} type={expandAll.length ? 'expand-all' : 'less-all'} onClick={handleCloseAllPositions} />
        </Grid>
        {agreement?.positions
          ?.filter(i => !i.isArchive)
          .map((p, index) => {
            const currency = currencyDefault;
            const unit = p.unitShortName;
            const { color, stock, quantityPercent } = getQuantityPercentAndStock(p.quantity, p.reservation?.usedQuantity);
            const newColor = color === 'yellow' ? 'warningSimpleColor' : color === 'green' ? 'successSimpleColor' : color;
            const hasMarginalityMarker = p?.marginalityPct < 0;
            return (
              <Accordion
                expanded={expandAll.includes(`panel${index}`)}
                onChange={() => handleAccordionChange(`panel${index}`)}
                key={index}
                sx={{
                  margin: '0px !important',
                  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
                  ':before': {
                    display: 'none',
                  },
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderTop: '1px solid #DBDBDB' }}>
                  <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center" sx={{ position: 'relative' }}>
                    <Grid item alignItems="center">
                      <Typography component="span" style={{ fontSize: '11px' }}>
                        {`${p?.product?.code || ''} `}
                      </Typography>
                      <Typography component="span" fontSize={'14px'}>
                        {p.product.title || ''}
                      </Typography>
                      <ProductLinkBtn productCode={p.productCode} />
                    </Grid>
                    {hasMarginalityMarker && (
                      <Grid item sx={{ position: 'absolute', right: '-34px', top: '50%', transform: 'translateY(-50%)' }}>
                        <Box sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#D32F2F' }}></Box>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid mt={1} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>МРЦ</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize={'12px'} color="text.secondary">
                        {renderPriceCur(p?.mrcUnitCost || 0, currency + '/' + unit)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid mt={1} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>Цена</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize={'12px'} color="text.secondary">
                        {renderPriceCur(p?.calculatedBareUnitCost || 0, currency + '/' + unit)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid mt={1} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>Цена с услугами</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize={'12px'} color="text.secondary">
                        {renderPriceCur(p?.fullUnitCost || 0, currency + '/' + unit)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid mt={1} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>Количество</Typography>
                    </Grid>
                    <Grid item>
                      <Typography fontSize="12px" color="text.secondary">
                        {formatNumberByUnit(p.quantity || 0, p.unitCode) + ' ' + unit}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid mt={1} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>Остаток</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems={'center'}>
                        <Grid item>
                          {p?.isStocksControlDisabled ? (
                            <Typography fontSize="16px" color="text.secondary" title={`Контроль остатков отключен`}>
                              <span>∞</span>
                            </Typography>
                          ) : (
                            <MxLabel
                              sx={labelStyle}
                              title={`${quantityPercent}%`}
                              color={
                                ColorsQuantityPercentAndStockForLabel[newColor]
                                  ? (ColorsQuantityPercentAndStockForLabel[color] as LabelColor)
                                  : (newColor as LabelColor)
                              }
                            >
                              {formatNumberByUnit(stock || 0, p.unitCode) + ' ' + unit}
                            </MxLabel>
                          )}
                        </Grid>
                        <Grid item ml={1}>
                          <ShippedProgress currentQuantity={stock} baseQuantity={p.quantity} color={'#317E03'} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid mt={1} container justifyContent="space-between">
                    <Grid item>
                      <Typography fontSize={'12px'}>МаржМРЦ</Typography>
                    </Grid>
                    <Grid item>
                      <MxLabel sx={labelStyle} color={getMarginalityColorClass(p.marginalityPct, true)}>
                        {formatPriceCur(p?.marginalityPct || 0, '%')}
                      </MxLabel>
                    </Grid>
                  </Grid>
                  {plsVisible && (
                    <>
                      <Divider />
                      <Grid mt={1} container justifyContent="space-between">
                        <Grid item>
                          <Typography fontSize={'12px'}>МаржПлС</Typography>
                        </Grid>
                        <Grid item>
                          <MxLabel sx={labelStyle} color={getMarginalityColorClass(p.purchaseMarginFullCostPct, true)}>
                            {formatPriceCur(p?.purchaseMarginFullCostPct || 0, '%')}
                          </MxLabel>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid mt={1} container justifyContent="space-between">
                        <Grid item>
                          <Typography fontSize={'12px'}>МаржЦЗ</Typography>
                        </Grid>
                        <Grid item>
                          <MxLabel sx={labelStyle} color={getMarginalityColorClass(p.purchaseMarginFullCostPct, true)}>
                            {formatPriceCur(p?.purchaseMarginFullCostPct || 0, '%')}
                          </MxLabel>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Grid>
    )
  ) : (
    <Grid item xs={12} container>
      <Grid item xs={12} mb={1}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body2'}>Без позиций</Typography>
      </Grid>
    </Grid>
  );
});
