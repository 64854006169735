import React, { FC, JSX, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppDealPosition } from '../../../slices/AppDeal';
import { DealItemStore } from '../../../store/DealItemStore';
import { toJS } from 'mobx';
import { formatNumberByUnit, formatPrice, toFloat } from '@mx-ui/helpers';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import { CustomIconTabs } from '../../../components/Deals/CustomIconTabs/CustomIconTabs';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import {
  BatchForAlternative,
  CatalogProductsAlternativesNomenclature,
  CatalogProductsAlternativesPositionProduct,
  CatalogProductsAlternativesSegmentationGroup,
} from '../../../api/marketx';
import { ProductIndicatorsByValue } from '../../catalog/Listing/ProductPeculiaritiesIndicators';
import { multicoloredPopoverBg } from '../../clients/lib';
import Box from '@mui/material/Box';
import { AllowedColors } from '@mx-ui/ui';
import Collapse from '@mui/material/Collapse';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Divider from '@mui/material/Divider';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import WorkspacesRoundedIcon from '@mui/icons-material/WorkspacesRounded';
import { DealAlternativeAccordion } from './DealAlternativeProductsMobile';
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import { Link as MxLink } from '@mx-ui/ui';
import { alternativeInTransitText } from './AlternativeProductsTableForCatalog';
export const findQuantityFromList = (
  position: AppDealPosition,
  alternativePosition: CatalogProductsAlternativesPositionProduct,
  unitCode = 'TNE'
): { colorForStock: string; titleForStock: string; unitCode?: string } => {
  const saleQuantity = toFloat(position?.amount);
  const baseQuantity = toFloat(position?.baseQuantity);

  const inStock = toFloat(alternativePosition?.inStock);
  const inStockForSale = toFloat(alternativePosition?.inStockForSale);
  let unitShort = 'т';
  if (saleQuantity && baseQuantity > 0 && saleQuantity !== baseQuantity) {
    unitShort = position?.unit || 'т';
  }
  let colorForStock: AllowedColors = 'green';
  if (saleQuantity > inStock) {
    colorForStock = 'red';
  } else if (saleQuantity > inStockForSale) {
    colorForStock = 'yellow';
  }
  const titleForStock = `есть ${formatNumberByUnit(
    inStockForSale,
    alternativePosition?.stockUnitCode || unitCode,
    alternativePosition?.stockUnitShortName || unitShort
  )}, из ${formatNumberByUnit(
    inStock,
    alternativePosition?.stockUnitCode || unitCode,
    alternativePosition?.stockUnitShortName || unitShort
  )}`;
  return {
    colorForStock,
    titleForStock,
  };
};

interface DealAlternativeProductsProps {
  dealStore: DealItemStore;
  position: AppDealPosition;
  readOnlyList?: boolean;
}
export const DealAlternativeProducts: FC<DealAlternativeProductsProps> = observer(
  ({ dealStore, position, readOnlyList = true }): JSX.Element => {
    const matchesMd = useMediaQuery('(min-width:865px)');
    const [selectedAdditionalTab, setSelectedAdditionalTab] = useState(0);
    const manufacturersStore = dealStore.positionsManufacturersStore;
    const { canViewPurchasePrice, nomenclature, segmentationGroup } = useMemo(() => {
      const mp = toJS(manufacturersStore.getPositionManufacturers(dealStore.deal, position));
      if (manufacturersStore.loading || !manufacturersStore.loaded) {
        return { canViewPurchasePrice: false, nomenclature: null, segmentationGroup: null };
      }
      return {
        canViewPurchasePrice: !!mp.canViewPurchasePrice,
        nomenclature: { ...mp.nomenclature, products: mp.nomenclature.products },
        segmentationGroup: { ...mp.segmentationGroup, products: mp.segmentationGroup.products },
      };
    }, [manufacturersStore, manufacturersStore.loadedEpoch, position.productCode, dealStore.deal]);

    const innerTabsValue = useMemo(() => {
      return [
        {
          label: ``,
          title: 'Группа номенклатуры',
          tabPanelIndex: 0,
          disabled: false,
          icon: (
            <Grid container alignItems={'center'} px={0.7}>
              <Grid item height={'18px'}>
                <WorkspacesRoundedIcon sx={{ fontSize: '18px' }} />
              </Grid>
              <Grid item ml={1}>
                <Typography component={'span'} sx={{ fontSize: '10px', cursor: 'pointer' }}>
                  Номенклатура
                </Typography>
              </Grid>
            </Grid>
          ),
          badgeOn: false,
        },
        {
          label: ``,
          title: 'Группа сегментации',
          tabPanelIndex: 1,
          disabled: false,
          icon: (
            <Grid container alignItems={'center'} px={0.7}>
              <Grid item height={'18px'}>
                <WorkspacesRoundedIcon sx={{ fontSize: '18px' }} />
              </Grid>
              <Grid item ml={1}>
                <Typography component={'span'} sx={{ fontSize: '10px', cursor: 'pointer' }}>
                  Сегментация
                </Typography>
              </Grid>
            </Grid>
          ),
          badgeOn: false,
        },
      ];
    }, []);
    const handleTabChange = useCallback(
      (event: React.ChangeEvent<unknown>, newValue: number) => {
        setSelectedAdditionalTab(newValue);
      },
      [setSelectedAdditionalTab]
    );

    if (!manufacturersStore.loaded || manufacturersStore.loading) {
      return (
        <Typography sx={{ py: 1, px: 2, mb: 1 }} variant="body2" component="div">
          Загрузка
        </Typography>
      );
    }
    return matchesMd ? (
      <TabSelectWrapper>
        <Grid item sx={{ maxWidth: '100%', height: '100%' }} className="withCustomScroll">
          <Grid container maxWidth={'100%'} sx={{ display: 'grid', gridTemplateColumns: 'minmax(310px, 100%)', MozPaddingBottom: '15px' }}>
            <Grid item height={'37px'} pl={1} py={0.5}>
              <CustomIconTabs
                handleToggleButtonChange={handleTabChange}
                toggleButtonValue={selectedAdditionalTab}
                tabsValue={innerTabsValue}
                padding="0 2px"
                sx={{ minHeight: '34px' }}
              />
            </Grid>
            <Grid item minWidth={'800px'}>
              {selectedAdditionalTab === 0 && (
                <DealAlternativeProductTable
                  canViewPurchasePrice={canViewPurchasePrice}
                  dealStore={dealStore}
                  position={position}
                  alternativeObj={nomenclature}
                  readOnlyList={!nomenclature?.products?.length || readOnlyList}
                />
              )}
              {selectedAdditionalTab === 1 && (
                <Grid container>
                  <DealAlternativeProductTable
                    canViewPurchasePrice={canViewPurchasePrice}
                    dealStore={dealStore}
                    position={position}
                    alternativeObj={segmentationGroup}
                    readOnlyList={!segmentationGroup?.products?.length || readOnlyList}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </TabSelectWrapper>
    ) : (
      <Grid item xs={12} sx={{ height: '100%' }} className="withCustomScroll">
        <Grid container>
          <Grid item xs={12} height={'37px'} pl={1} py={0.5}>
            <CustomIconTabs
              handleToggleButtonChange={handleTabChange}
              toggleButtonValue={selectedAdditionalTab}
              tabsValue={innerTabsValue}
              padding="0 2px"
              sx={{ minHeight: '34px' }}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedAdditionalTab === 0 && (
              <DealAlternativeAccordion
                canViewPurchasePrice={canViewPurchasePrice}
                dealStore={dealStore}
                position={position}
                alternativeObj={nomenclature}
                readOnlyList={!nomenclature?.products?.length || readOnlyList}
                manufacturersStore={manufacturersStore}
              />
            )}
            {selectedAdditionalTab === 1 && (
              <DealAlternativeAccordion
                canViewPurchasePrice={canViewPurchasePrice}
                dealStore={dealStore}
                position={position}
                alternativeObj={segmentationGroup}
                readOnlyList={!segmentationGroup?.products?.length || readOnlyList}
                manufacturersStore={manufacturersStore}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

interface DealAlternativeProductTableProps {
  alternativeObj: CatalogProductsAlternativesSegmentationGroup | CatalogProductsAlternativesNomenclature;
  dealStore: DealItemStore;
  position: AppDealPosition;
  readOnlyList: boolean;
  canViewPurchasePrice: boolean;
}
export const DealAlternativeProductTable: FC<DealAlternativeProductTableProps> = observer(
  ({ dealStore, alternativeObj, position, readOnlyList, canViewPurchasePrice }): JSX.Element => {
    return (
      <Grid container>
        <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'} pt={1} px={0.5}>
          <Grid item flex={'0 0 35px'}></Grid>
          <Grid item flex={'0 0 35px'}></Grid>
          <Grid item flex={'0 0 265px'}></Grid>
          <Grid item flex={'1 0 110px'} textAlign={'right'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              Индикаторы
            </Typography>
          </Grid>
          <Grid item flex={'1 0 110px'} textAlign={'right'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              Цена закупки
            </Typography>
          </Grid>
          <Grid item flex={'1 0 110px'} textAlign={'right'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              МРЦ
            </Typography>
          </Grid>
          <Grid item flex={'1 0 110px'} textAlign={'right'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              Срок хранения
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'} py={1} px={0.5}>
          <Grid item flex={'0 0 35px'}></Grid>
          <Grid item flex={'0 0 35px'}></Grid>
          <Grid item flex={'0 0 265px'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              {/* @ts-ignore*/}
              {alternativeObj?.name || alternativeObj?.title || ''}
            </Typography>
          </Grid>
          <Grid item flex={'1 1 110px'} textAlign={'right'}></Grid>
          <Grid item flex={'1 1 110px'} textAlign={'right'}>
            {!!canViewPurchasePrice ? (
              <Typography component="span" variant="body2" fontWeight={500}>
                {`${formatPrice(alternativeObj?.purchasePrice)} ₽`}
              </Typography>
            ) : (
              <VisibilityOffOutlinedIcon />
            )}
          </Grid>
          <Grid item flex={'1 0 110px'} textAlign={'right'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              {`${formatPrice(alternativeObj.minRetailUnitCost)} ₽`}
            </Typography>
          </Grid>
          <Grid item flex={'1 0 110px'} textAlign={'right'}>
            <Typography component="span" variant="body2" fontWeight={500}>
              {alternativeObj.oldShelfLife || ''}
            </Typography>
          </Grid>
        </Grid>
        {alternativeObj?.products.map((product, index) => (
          <React.Fragment key={product?.productCode || index}>
            <DealAlternativeProductItem
              dealStore={dealStore}
              product={product}
              position={position}
              canViewPurchasePrice={canViewPurchasePrice}
              withChangeBtn={!readOnlyList || alternativeObj?.products?.length > 1 || product.productCode !== position.productCode}
            />
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    );
  }
);

interface DealAlternativeProductItemProps {
  product: CatalogProductsAlternativesPositionProduct;
  dealStore: DealItemStore;
  position: AppDealPosition;
  withChangeBtn: boolean;
  canViewPurchasePrice: boolean;
}

export const DealAlternativeProductItem: FC<DealAlternativeProductItemProps> = observer(
  ({ dealStore, product, position, withChangeBtn, canViewPurchasePrice }): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);
    const isViewOnly = dealStore.isViewOnly();
    const handleToggleRowClick = useCallback((): void => {
      setOpen(open => !open);
    }, [setOpen]);
    const { colorForStock, titleForStock } = findQuantityFromList(position, product, position.unitCode);
    const isCurrent = product.productCode === position.productCode;
    const isPositionInList = Boolean(
      dealStore.deal.positions.filter(i => i.productCode === product?.productCode && i.productCode !== position?.product?.code).length
    );
    const handlePositionManufacturerReplace = useCallback((): void => {
      dealStore.replacePositionProduct(position?.code, product?.productCode).catch(r => console.warn('replacePositionProduct', r));
    }, [dealStore, position]);

    const handlePositionAdd = useCallback((): void => {
      dealStore
        .addPosition(
          { ...product, code: product.productCode },
          1,
          position.calculatedBareUnitCost,
          undefined,
          position.unitCode,
          dealStore.deal.warehouseCode
        )
        .catch(r => console.warn('DealAlternativeProductItem addPosition', r));
    }, [dealStore, product, position]);

    const cursorType = product?.batches?.length ? 'pointer' : '';

    return (
      <>
        <Grid
          item
          xs={12}
          container
          alignItems={'center'}
          flexWrap={'nowrap'}
          sx={{ background: theme => (isCurrent ? (theme.palette.mode === 'dark' ? '#090909' : '#EDEEF5') : 'inherit') }}
          px={0.5}
        >
          {/* Замена товара */}
          <Grid item flex={'0 0 35px'} container>
            {withChangeBtn && !isCurrent && !isPositionInList && !isViewOnly && (
              <Button
                variant={'contained'}
                color="secondary"
                title={'Подменить товар'}
                size={'small'}
                onClick={handlePositionManufacturerReplace}
                sx={{ p: '4px!important', minWidth: '20px' }}
              >
                <PublishedWithChangesOutlinedIcon sx={{ fontSize: '20px' }} />
              </Button>
            )}
            {(isCurrent || isPositionInList) && (
              <Box pl={0.5} title={'Добавлен в заявку'}>
                <CheckOutlinedIcon color={'success'} />
              </Box>
            )}
          </Grid>
          {/* Корзина */}
          <Grid item flex={'0 0 35px'} container>
            {withChangeBtn && !isCurrent && !isPositionInList && !isViewOnly && (
              <Button
                variant={'contained'}
                color="secondary"
                title={'Добавить товар'}
                size={'small'}
                onClick={handlePositionAdd}
                sx={{ p: '4px!important', minWidth: '20px' }}
              >
                <AddShoppingCartRoundedIcon sx={{ fontSize: '20px' }} />
              </Button>
            )}
          </Grid>
          <Grid item flex={'0 0 265px'}>
            <Grid container alignItems={'center'}>
              <Grid item>
                <MxLink
                  href={`/app/product/${encodeURIComponent(product?.productCode)}`}
                  color="inherit"
                  variant="caption"
                  target="_blank"
                  title="Перейти в карточку товара"
                >
                  {`${product?.productCode || ''} ${product.productTitle}`}
                </MxLink>
              </Grid>
              <Grid item>
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>
                    <Box
                      display="flex"
                      sx={{
                        borderRadius: 1,
                        mr: 0.5,
                        height: '20px',
                        ...multicoloredPopoverBg(colorForStock),
                      }}
                    >
                      <Typography variant="caption" color="inherit" fontWeight="500">
                        {titleForStock}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    {((product?.inTransitQuantity || product?.distributedQuantity) && (
                      <Grid item>
                        <Box
                          display="flex"
                          sx={{
                            borderRadius: 1,
                            mr: 0.5,
                            height: '20px',
                            backgroundColor: '#D6D6D6',
                            border: '1px solid #242424',
                          }}
                        >
                          <Typography variant="caption" sx={{ color: '#333' }} fontWeight="500">
                            {alternativeInTransitText(
                              product?.inTransitQuantity,
                              product?.distributedQuantity,
                              product?.stockUnitCode,
                              product?.stockUnitShortName
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    )) ||
                      null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            flex={'1 0 110px'}
            minWidth={'110px'}
            onClick={handleToggleRowClick}
            sx={{ cursor: cursorType, height: '100%' }}
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <ProductIndicatorsByValue
              isNew={product?.batches && !!product.isNew}
              isOld={product?.batches && !!product.isOld}
              hasCustody={!!product.hasCustody}
              hasSelfPurchase={!!product.hasSelfPurchase}
              hasTodh={!!product.hasTodhDiscount}
              hasIlliquid={!!product.hasIlliquid}
            />
          </Grid>
          <Grid
            item
            flex={'1 0 110px'}
            minWidth={'110px'}
            onClick={handleToggleRowClick}
            sx={{ cursor: cursorType, height: '100%' }}
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            {!!canViewPurchasePrice ? (
              <Typography component="span" variant="caption" sx={{ cursor: cursorType }}>
                {`${formatPrice(product?.purchasePrice)} ₽`}
              </Typography>
            ) : (
              <VisibilityOffOutlinedIcon />
            )}
          </Grid>
          <Grid
            item
            flex={'1 0 110px'}
            minWidth={'110px'}
            onClick={handleToggleRowClick}
            sx={{ cursor: cursorType, height: '100%' }}
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Typography component="span" variant="caption" sx={{ cursor: cursorType }}>
              {`${formatPrice(product?.minRetailUnitCost)} ₽`}
            </Typography>
          </Grid>
          <Grid
            item
            flex={'1 0 110px'}
            minWidth={'110px'}
            onClick={handleToggleRowClick}
            sx={{ cursor: cursorType, height: '100%' }}
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <Typography component="span" variant="caption" sx={{ cursor: cursorType }}>
              {`${product?.oldShelfLife || '-'}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container alignItems={'center'} flexWrap={'nowrap'} pt={1}>
          <Collapse in={open} unmountOnExit timeout="auto" sx={{ width: '100%' }}>
            <Grid container>
              {product.batches?.map((batch, index) => (
                <React.Fragment key={batch.code}>
                  <DealAlternativeProductBatchesItem batch={batch} canViewPurchasePrice={canViewPurchasePrice} />
                  {index !== product.batches?.length - 1 && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Collapse>
        </Grid>
      </>
    );
  }
);

interface DealAlternativeProductBatchesItemProps {
  batch: BatchForAlternative;
  forDeal?: boolean;
  canViewPurchasePrice?: boolean;
}

export const DealAlternativeProductBatchesItem: FC<DealAlternativeProductBatchesItemProps> = observer(
  ({ batch, forDeal = true, canViewPurchasePrice }): JSX.Element => {
    return (
      <Grid item xs={12} container alignItems={'center'} py={0.5} flexWrap={'nowrap'} px={0.5}>
        {forDeal && <Grid item flex={'0 0 35px'} textAlign={'right'}></Grid>}
        <Grid item flex={'0 0 265px'} textAlign={'right'}>
          <MxLink href={`/app/batches/${batch.code}`} variant="caption" color="inherit" title="Перейти к партии">
            {batch.code}
          </MxLink>
        </Grid>
        <Grid item flex={'1 0 110px'} textAlign={'right'}>
          <ProductIndicatorsByValue
            hasCustody={!!batch.hasCustody}
            hasSelfPurchase={!!batch.hasSelfPurchase}
            hasTodh={!!batch.hasTodhDiscount}
            hasIlliquid={!!batch.hasIlliquid}
          />
        </Grid>
        <Grid item flex={'1 0 110px'} textAlign={'right'}>
          {!!canViewPurchasePrice ? (
            <Typography component="span" variant="caption" sx={{ cursor: 'pointer' }}>
              {`${formatPrice(batch?.purchasePrice)} ₽`}
            </Typography>
          ) : (
            <VisibilityOffOutlinedIcon />
          )}
        </Grid>
        <Grid item flex={'1 0 110px'} textAlign={'right'}></Grid>
        <Grid item flex={'1 0 110px'} textAlign={'right'}>
          <Typography component="span" variant="caption" sx={{ cursor: 'pointer' }}>
            {`${batch?.shelfLife || '-'}`}
          </Typography>
        </Grid>
      </Grid>
    );
  }
);
