import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { v4 as uuidV4 } from 'uuid';
import {
  AuthApiFactory,
  AuthApiInterface,
  CatalogApiFactory,
  CatalogApiInterface,
  CompanyApiFactory,
  CompanyApiInterface,
  DealApiFactory,
  DealApiInterface,
  MyApiFactory,
  MyApiInterface,
  StatApiInterface,
  StatApiFactory,
  UserApiInterface,
  UserApiFactory,
  CustomerApiInterface,
  CustomerApiFactory,
  SystemApiInterface,
  SystemApiFactory,
  FeedbackApiInterface,
  FeedbackApiFactory,
  NotificationApiInterface,
  NotificationApiFactory,
  AddressApiInterface,
  AddressApiFactory,
  PlanApiFactory,
  PlanApiInterface,
  ParticipantApiInterface,
  ParticipantApiFactory,
  EmployeeApiFactory,
  EmployeeApiInterface,
  FaqApiInterface,
  FaqApiFactory,
  TaskApiInterface,
  TaskApiFactory,
  BillApiInterface,
  BillApiFactory,
  PaymentApiInterface,
  PaymentApiFactory,
  CompetitorApiInterface,
  CompetitorApiFactory,
  BatchesApiInterface,
  BatchesApiFactory,
  ShipmentApiInterface,
  ShipmentApiFactory,
  ShipmentreturnApiInterface,
  ShipmentreturnApiFactory,
  DocumentsApiInterface,
  DocumentsApiFactory,
  DocFlowApiInterface,
  DocFlowApiFactory,
  WarehouseApiInterface,
  WarehouseApiFactory,
  RefusalApiFactory,
  RefusalApiInterface,
  FreezeApiInterface,
  FreezeApiFactory,
  SalesinvoiceApiInterface,
  SalesinvoiceApiFactory,
  CorrectionShipmentApiInterface,
  CorrectionShipmentApiFactory,
} from '../../api/marketx';
import { webConfig } from '../../config';
import { ErrorStore } from '../ErrorStore';
import { RootStore } from '../StoreManager';
import * as Sentry from '@sentry/nextjs';
import { Span } from '@sentry/browser';

const CUSTOM_CONFIG_CONTEXT_DATA = 'contextData';

const extractUrlPathRegexp = /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/;
export const extractUrlPath = (url: string): string => {
  const m = extractUrlPathRegexp.exec(url);
  if (m && m[1]) {
    return m[1];
  }
  return '';
};

export class AxiosCallContext {
  startTime: Date;
  url: string;
  urlPath: string;
  traceId: string;

  sentrySpan?: Span;

  constructor(url: string) {
    this.url = url;
    this.urlPath = extractUrlPath(url);
    this.startTime = new Date();
    this.traceId = uuidV4();

    try {
      const transaction = Sentry.getCurrentHub().getScope().getTransaction();
      if (transaction) {
        this.sentrySpan = transaction.startChild({
          op: 'axios',
          tags: {
            path: this.urlPath,
          },
        });
        if (this.sentrySpan && this.sentrySpan.traceId) {
          this.traceId = this.sentrySpan.traceId;
        }
      }
    } catch (e) {
      console.error('start sentry span', e);
    }
  }

  onFulfilled(response: AxiosResponse<unknown>): void {
    if (this.sentrySpan) {
      this.sentrySpan.setStatus('ok');
      const httpCode = response?.status || 0;
      if (httpCode > 0) {
        this.sentrySpan.setHttpStatus(httpCode);
      }
      this.sentrySpan.finish();
    }
  }

  onRejected(error: AxiosError): void {
    if (this.sentrySpan) {
      this.sentrySpan.setStatus('unknown_error');
      const httpCode = parseInt(error?.code || '');
      if (httpCode > 0) {
        this.sentrySpan.setHttpStatus(httpCode);
      }
      this.sentrySpan.finish();
    }
  }
}

interface ApiCollection {
  axios: AxiosInstance;

  apiAddress: AddressApiInterface;
  apiClientAuth: AuthApiInterface;
  apiFeedback: FeedbackApiInterface;
  apiNotification: NotificationApiInterface;
  apiStat: StatApiInterface;
  apiSystem: SystemApiInterface;
  apiUser: UserApiInterface;

  otherApis: Map<() => any, any>;
}

export class ApiStore {
  env = 'prod';
  envs = <Record<string, ApiCollection>>{};

  errorStore: ErrorStore;
  accessToken = undefined;

  constructor(rootStore: RootStore) {
    this.errorStore = rootStore.getError();
  }

  setAccessToken(token: string): void {
    this.accessToken = token;
  }

  initializeEnv(env: string, baseURL: string): void {
    if (this.envs[env]) {
      // already initialized
      return;
    }

    const axiosInstance = axios.create({
      timeout: 25022,
    });

    axiosInstance.interceptors.request.use(
      config => {
        config[CUSTOM_CONFIG_CONTEXT_DATA] = new AxiosCallContext(config.url);
        if (this.accessToken) {
          config.headers.Authorization = `Bearer ${this.accessToken}`;
        }
        return config;
      },
      error => {
        console.warn('api request error', error);
        return Promise.reject(error);
      }
    );

    // подключение обработки ошибок
    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        const ctx = getCallContext(response);
        if (ctx && ctx.onFulfilled) {
          ctx.onFulfilled(response);
        }
        return response;
      },
      (error: AxiosError) => {
        const ctx = getCallContext(error);
        if (ctx && ctx.onRejected) {
          ctx.onRejected(error);
        }
        const isFrontVersionRequest = error.config && error.config.url === '/buildinfo.json';
        const isBackVersionRequest = error.config && error.config.url === '/api/about';
        if (!isFrontVersionRequest && !isBackVersionRequest && !axios.isCancel(error)) {
          this.errorStore.pushAxiosError(error);
        }
        return Promise.reject(error);
      }
    );

    axiosInstance.defaults.baseURL = `${baseURL}`;
    console.log('axiosBaseUrl ' + env, baseURL, `${axiosInstance.defaults.baseURL}`);

    axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
    axiosInstance.defaults.headers.post['Accept'] = 'application/json';

    this.envs[env] = <ApiCollection>{
      axios: axiosInstance,

      apiAddress: AddressApiFactory(null, webConfig.apiUrl, axiosInstance),
      apiClientAuth: AuthApiFactory(null, webConfig.apiUrl, axiosInstance),
      apiFeedback: FeedbackApiFactory(null, webConfig.apiUrl, axiosInstance),
      apiNotification: NotificationApiFactory(null, webConfig.apiUrl, axiosInstance),
      apiStat: StatApiFactory(null, webConfig.apiUrl, axiosInstance),
      apiFaq: FaqApiFactory(null, webConfig.apiUrl, axiosInstance),
      apiTask: TaskApiFactory(null, webConfig.apiUrl, axiosInstance),
      apiSystem: SystemApiFactory(null, webConfig.apiUrl, axiosInstance),
      apiUser: UserApiFactory(null, webConfig.apiUrl, axiosInstance),

      otherApis: new Map<() => any, any>(),
    };
  }

  axios(): AxiosInstance {
    return this.envs[this.env].axios;
  }

  apiClientAuth(): AuthApiInterface {
    return this.envs[this.env].apiClientAuth;
  }
  apiStatApi(): StatApiInterface {
    return this.envs[this.env].apiStat;
  }

  apiClientAddress(): AddressApiInterface {
    return this.envs[this.env].apiAddress;
  }

  apiClientCatalog(): CatalogApiInterface {
    return this.api(CatalogApiFactory);
  }

  apiClientCompany(): CompanyApiInterface {
    return this.api(CompanyApiFactory);
  }
  apiClientCustomer(): CustomerApiInterface {
    return this.api(CustomerApiFactory);
  }
  apiClientParticipant(): ParticipantApiInterface {
    return this.api(ParticipantApiFactory);
  }
  apiFaqList(): FaqApiInterface {
    return this.api(FaqApiFactory);
  }
  apiTask(): TaskApiInterface {
    return this.api(TaskApiFactory);
  }
  apiEmployee(): EmployeeApiInterface {
    return this.api(EmployeeApiFactory);
  }
  apiDocuments(): DocumentsApiInterface {
    return this.api(DocumentsApiFactory);
  }

  apiDocFlow(): DocFlowApiInterface {
    return this.api(DocFlowApiFactory);
  }

  apiClientBatches(): BatchesApiInterface {
    return this.api(BatchesApiFactory);
  }

  apiClientFreeze(): FreezeApiInterface {
    return this.api(FreezeApiFactory);
  }

  apiClientBill(): BillApiInterface {
    return this.api(BillApiFactory);
  }

  apiClientDeal(): DealApiInterface {
    return this.api(DealApiFactory);
  }

  apiClientFeedback(): FeedbackApiInterface {
    return this.envs[this.env].apiFeedback;
  }

  apiClientMy(): MyApiInterface {
    return this.api(MyApiFactory);
  }

  apiClientPayment(): PaymentApiInterface {
    return this.api(PaymentApiFactory);
  }

  apiPlan(): PlanApiInterface {
    return this.api(PlanApiFactory);
  }

  apiClientNotification(): NotificationApiInterface {
    return this.envs[this.env].apiNotification;
  }

  apiClientUser(): UserApiInterface {
    return this.envs[this.env].apiUser;
  }

  apiClientSystem(): SystemApiInterface {
    return this.envs[this.env].apiSystem;
  }
  apiClientCompetitors(): CompetitorApiInterface {
    return this.api(CompetitorApiFactory);
  }
  apiShipments(): ShipmentApiInterface {
    return this.api(ShipmentApiFactory);
  }
  apiSalesInvoice(): SalesinvoiceApiInterface {
    return this.api(SalesinvoiceApiFactory);
  }
  apiWarehousesForFilter(): WarehouseApiInterface {
    return this.api(WarehouseApiFactory);
  }
  apiShipmentreturn(): ShipmentreturnApiInterface {
    return this.api(ShipmentreturnApiFactory);
  }
  apiCorrectionShipment(): CorrectionShipmentApiInterface {
    return this.api(CorrectionShipmentApiFactory);
  }
  apiRefusal(): RefusalApiInterface {
    return this.api(RefusalApiFactory);
  }

  /**
   * Возвращает экземпляр апи-клиента для метода-фабрики.
   * @param factoryMethod
   */
  api<T>(factoryMethod: (configuration?: any, basePath?: string, axios?: AxiosInstance) => T): T {
    let instance = this.envs[this.env].otherApis.get(factoryMethod);
    if (!instance) {
      instance = factoryMethod(null, webConfig.apiUrl, this.envs[this.env].axios);
      this.envs[this.env].otherApis.set(factoryMethod, instance);
    }
    return instance;
  }

  switchEnv(env: string): void {
    this.env = env;
  }
}

export const getCallContext = (r: AxiosResponse | AxiosError): AxiosCallContext => {
  return (r?.config[CUSTOM_CONFIG_CONTEXT_DATA] || {}) as AxiosCallContext;
};
