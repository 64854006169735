import React, { FC, JSX, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { ReservesStore } from '../../../store/ReservesStore';
import { ReserveDetailsV2 } from './ReserveDetailsV2';
import { Divider, Fab, Grid, Typography } from '@mui/material';
import { Product } from '../../../api/marketx';
import LockPersonRoundedIcon from '@mui/icons-material/LockPersonRounded';
import { useRootStore } from '../../../store/MobxStoreProvider';
import { TableRowTitle } from '../../Catalog/Table/CatalogTableRowVirtualizeItemView';
import { Link as MxLink } from '@mx-ui/ui';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import { DialogContainer } from '../../ui/dialogs/DialogContainer';

interface TooltipForReserveListProps {
  icon?: string;
  documentNumber?: string;
  branchOfficeCode?: string;
  reserveStore?: ReservesStore;
  product: Product;
  nomenclatureCodes?: string[];
  warehouseCodes?: string[];
  warehouseCode?: string;
  mustRender?: any;
  isForSoftReserve?: boolean;
  autoReserveEnabled?: boolean;
}

export const styleForCatalogModel = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '94vw',
  boxShadow: 24,
  borderRadius: '15px',
};

export const TooltipForReserveList: FC<TooltipForReserveListProps> = observer(
  ({
    icon = 'catalog',
    reserveStore,
    product,
    documentNumber,
    nomenclatureCodes,
    branchOfficeCode,
    isForSoftReserve,
    warehouseCodes,
    warehouseCode,
    autoReserveEnabled,
    mustRender,
  }): JSX.Element => {
    if (!reserveStore) {
      const rootStore = useRootStore();
      const initReserveStore = useMemo(() => {
        const store = new ReservesStore(rootStore);
        if (warehouseCodes?.length) {
          store.setWarehouseCodes(warehouseCodes);
        }

        if (nomenclatureCodes) {
          store.loadCatalogNomenclatureReserves(nomenclatureCodes, false);
        } else {
          store.loadCatalogProductReserves(product.code, branchOfficeCode, false, isForSoftReserve, undefined, autoReserveEnabled);
        }
        return store;
      }, [rootStore, product.code, autoReserveEnabled, mustRender]);

      return (
        <Grid sx={{ height: '100%', display: 'grid', gridTemplateColumns: 'minmax(300px, 1fr)' }}>
          <Grid
            container
            spacing={0}
            className="withCustomScroll"
            sx={{ maxHeight: '100%', display: 'grid', gridTemplateColumns: 'minmax(320px, 1fr)' }}
            id={`${product.code}ScrollableReserveContainer`}
          >
            <ReserveDetailsV2
              reserveStore={initReserveStore}
              withSkeleton
              productCode={product.code}
              documentNumber={documentNumber}
              isForSoftReserve={isForSoftReserve}
            />
          </Grid>
        </Grid>
      );
    }
    const [open, setOpen] = useState(false);
    const [isLinkVisible, setIsLinkVisible] = useState(false);
    const handleTooltipClose = (): void => {
      setOpen(false);
      reserveStore.resetReserves();
    };
    const handleTooltipOpen = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.stopPropagation();
        setOpen(true);
        reserveStore.loadCatalogProductReserves(product.code, branchOfficeCode, false, isForSoftReserve, warehouseCode);
      },
      [reserveStore, product, warehouseCode, isForSoftReserve]
    );

    if (!open) {
      return (
        <IconButton size="small" onClick={e => handleTooltipOpen(e)}>
          {icon === 'catalog' && <InfoIcon fontSize="small" color={'primary'} />}
          {icon === 'deal' && <LockPersonRoundedIcon fontSize="small" />}
        </IconButton>
      );
    }

    return (
      <Box>
        <IconButton size="small" onClick={handleTooltipOpen}>
          {icon === 'catalog' && <InfoIcon fontSize="small" color={'primary'} />}
          {icon === 'deal' && <LockPersonRoundedIcon fontSize="small" />}
        </IconButton>

        <DialogContainer
          open={open}
          onClose={handleTooltipClose}
          maxWidth={'lg'}
          infiniteScrollId={`${product.code}ScrollableReserveContainer`}
          dialogContent={
            <Box
              onMouseEnter={() => reserveStore.reserves?.length && !isForSoftReserve && setIsLinkVisible(true)}
              onMouseLeave={() => setIsLinkVisible(false)}
            >
              <Grid container pt={4.5}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    width: '100%',
                    backgroundColor: 'background.paper',
                    borderRadius: '15px 15px 0 0',
                    p: 1,
                    zIndex: 1100,
                  }}
                >
                  <Typography fontSize={'12px'} sx={{ pl: '2px' }} color="text.secondary">
                    <TableRowTitle item={product} />
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <ReserveDetailsV2 reserveStore={reserveStore} withSkeleton productCode={product.code} />
                </Grid>
              </Grid>
              {isLinkVisible && (
                <Fab
                  color="primary"
                  size={'small'}
                  sx={{
                    position: 'absolute',
                    right: '60px',
                    bottom: '40px',
                    width: '30px',
                    height: '30px',
                    minHeight: '30px',
                  }}
                  title={'Перейти на страницу резервов'}
                >
                  <MxLink
                    href={`/app/reserves?productCode=${product.code}${
                      warehouseCode
                        ? '&warehouseCodes=' + warehouseCode
                        : reserveStore.warehouseCodes?.length
                        ? '&warehouseCodes=' + reserveStore.warehouseCodes[0]
                        : ''
                    }`}
                    target={'_blank'}
                    color={'#fff'}
                  >
                    <FindInPageOutlinedIcon sx={{ fontSize: '25px', paddingTop: '6px', paddingLeft: '0' }} />
                  </MxLink>
                </Fab>
              )}
            </Box>
          }
        />
      </Box>
    );
  }
);
